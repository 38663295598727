<div class="list-item-user">
  <app-photo size="m" [rounded]="true" [text]="user | userDisplayableName : 'short'"></app-photo>

  <div class="list-item-user__info">
    <span class="text_size-m text_color-01" [title]="user | userDisplayableName">
      {{ user | userDisplayableName }}
    </span>

    <span class="text_size-xs text_color-03 text_truncate" [title]="registrationLabel">
      {{ registrationLabel }}
    </span>
  </div>
</div>
