/**
 * Unions API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface CalculateProductCostDto { 
    unionId?: string;
    duration?: number;
    jobTitleIds?: Array<string>;
    newslettersTier1Count?: number;
    newslettersTier2Count?: number;
    newslettersTier3Count?: number;
    searchBoostRateId?: string;
}

