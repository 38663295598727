import { Component, ChangeDetectionStrategy, Inject, ChangeDetectorRef } from '@angular/core';
import { ResizableBaseComponent } from '@src/app/components/resizable-base-component';
import { POLYMORPHEUS_CONTEXT } from '@tinkoff/ng-polymorpheus';
import { TuiDialogContext } from '@taiga-ui/core';
import { BreakpointObserver } from '@angular/cdk/layout';
import { BreakpointObserverHelperService } from '@src/core/services';

import { LoyaltyProgramResult } from '../../types';

import { BACKGROUND_COLORS, ICON_COLORS } from './constants';

@Component({
  selector: 'lp-result-dialog',
  templateUrl: './result-dialog.component.html',
  styleUrls: ['./result-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResultDialogComponent extends ResizableBaseComponent {
  constructor(
    readonly cdr: ChangeDetectorRef,
    readonly breakpointObserver: BreakpointObserver,
    readonly breakpointObserverHelperService: BreakpointObserverHelperService,
    @Inject(POLYMORPHEUS_CONTEXT)
    private readonly context: TuiDialogContext<void, LoyaltyProgramResult>,
  ) {
    super(cdr, breakpointObserver, breakpointObserverHelperService);
  }

  close() {
    this.context.completeWith();
  }

  get result() {
    return this.context.data;
  }

  get backgroundColor(): string | null {
    return BACKGROUND_COLORS[this.context.data];
  }

  get color(): string | null {
    return ICON_COLORS[this.context.data];
  }
}
