<div class="forward">
  <div class="forward__header">
    <button
      *ngIf="true"
      tuiIconButton
      class="forward__return-button"
      type="button"
      appearance="icon"
      icon="tuiIconArrowLeftLarge"
      size="s"
      [title]="'common.buttons.return' | translate"
      (click)="onClickReturnButton()"
    ></button>
    <div class="forward__title">{{ 'components.forward.labels.forward' | translate }}...</div>
  </div>
  <div class="forward__list">
    <div class="header">
      <tui-textarea  tuiTextfieldSize="m" [(ngModel)]="messageText">
        {{ 'common.fields.writeMessage' | translate }}...
      </tui-textarea>
    </div>
    <app-search-chats
      [chatsFilter]="chatsFilter"
      [chatSelectedId]="selectedChatId"
      (chatSelectedChange)="forwardToChat($event)"
    ></app-search-chats>
  </div>
</div>
