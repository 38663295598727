import { Component, ChangeDetectionStrategy, Input, ElementRef, QueryList, ViewChildren } from '@angular/core';
import { ResizableBaseComponent } from '@src/app/components/resizable-base-component';

import { NavigationService } from '../../../services';
import { LoyaltyProgramGrade } from '../../../models';

@Component({
  selector: 'lp-view-images-carousel',
  templateUrl: './view-images-carousel.component.html',
  styleUrls: ['./view-images-carousel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ViewImagesCarouselComponent extends ResizableBaseComponent {
  @Input() imageIds: string[] = [];
  @Input() showGrade = false;
  @Input() grade?: LoyaltyProgramGrade;
  @Input() productUnionId?: string | null;

  @ViewChildren('preview') previewElements?: QueryList<ElementRef>;

  readonly MAX_IMAGES_LENGTH = 6;
  index = 0;

  navigationService = new NavigationService('vertical', 250, this.cdr);

  ngOnInit() {
    super.ngOnInit();

    this.navigationService.setScrollElement(document.getElementById('images-carousel-preview-scroll'));
  }

  ngOnDestroy() {
    super.ngOnDestroy();

    this.navigationService.destroy();
  }

  changeImageIndex(index: number) {
    this.index = index;
  }

  generatePreviewId(index: number) {
    return `preview_${index}`;
  }

  navigate(delta: number) {
    const newIndex = this.index + delta;

    if (newIndex < 0) {
      this.index = this.imageIds.length - 1;
    } else if (newIndex > this.imageIds.length - 1) {
      this.index = 0;
    } else {
      this.index = newIndex;
    }

    this.checkVerticalImagePosition(this.index);
  }

  checkVerticalImagePosition(index: number) {
    const el = this.previewElements?.find(item => item.nativeElement.id === this.generatePreviewId(index));
    el?.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
  }
}
