import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';
import { isValidPhoneNumber } from 'libphonenumber-js';
import { isValidEmail, isValidUrl } from '@src/utils';
import { Nullable, Optional } from '@src/types/utils';

import { HTMLStructure, ParsedTextType } from './types';

@Component({
  selector: 'app-parse-links',
  templateUrl: './parse-links.component.html',
  styleUrls: ['./parse-links.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ParseLinksComponent {
  @Input() set value(text: Optional<Nullable<string>>) {
    this.preparedHTMLStructure = (text ?? '').split('\n').reduce<HTMLStructure[]>((acc, line, _, endOfLineArray) => {
      line.split(' ').forEach((value, _, spaceArray) => {
        let type: ParsedTextType = 'text';
        let url = '';

        if (isValidEmail(value)) {
          type = 'email';
        } else if (isValidPhoneNumber(value)) {
          type = 'phone';
        } else {
          url = isValidUrl(value);
          if (url) {
            type = 'url';
          }
        }

        acc.push({ type, value, url });

        if (spaceArray.length > 1) {
          acc.push({ type: 'space' });
        }
      });

      if (endOfLineArray.length > 1) {
        acc.push({ type: 'new-line' });
      }

      return acc;
    }, []);
  }

  @Output() externalLink: EventEmitter<string> = new EventEmitter<string>();

  preparedHTMLStructure: HTMLStructure[] = [];
}
