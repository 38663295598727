import { Component, ChangeDetectionStrategy, Input, ChangeDetectorRef, OnChanges, SimpleChanges } from '@angular/core';
import { EMPTY, of, Subscription } from 'rxjs';
import { delay } from 'rxjs/operators';

@Component({
  selector: 'app-skeleton-wrapper',
  templateUrl: './skeleton-wrapper.component.html',
  styleUrls: ['./skeleton-wrapper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SkeletonWrapperComponent implements OnChanges {
  @Input() loading = false;

  debouncedLoading = false;
  private debounce$?: Subscription;

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.loading.currentValue) {
      this.debounce$ = of(EMPTY)
        .pipe(delay(300))
        .subscribe(() => {
          this.debouncedLoading = true;
          this.cdr.markForCheck();
        });
    } else {
      this.debounce$?.unsubscribe();
      this.debouncedLoading = false;
    }

    this.cdr.markForCheck();
  }
}
