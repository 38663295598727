import { Component, ChangeDetectionStrategy, ChangeDetectorRef, Input } from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';
import { BreakpointObserverHelperService } from '@src/core/services';
import { BrandingService } from '@src/app/modules/branding';
import { ResizableBaseComponent } from '@src/app/components/resizable-base-component';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavigationComponent extends ResizableBaseComponent {
  @Input() isOpen: boolean = true;

  isBrand = this.brandingService.isBrand();

  constructor(
    readonly cdr: ChangeDetectorRef,
    readonly breakpointObserver: BreakpointObserver,
    readonly breakpointObserverHelperService: BreakpointObserverHelperService,
    private readonly brandingService: BrandingService,
  ) {
    super(cdr, breakpointObserver, breakpointObserverHelperService);
  }
}
