<div class="message-notification">
  <div *ngIf="content" class="message-notification__message-content message-content">
    <span *ngIf="content._ === 'messageBasicGroupChatCreate'">
      {{ 'components.messageNotification.labels.messageBasicGroupChatCreate' | translate }} «{{ content.title }}»
    </span>

    <span *ngIf="content._ === 'messageSupergroupChatCreate'">
      {{ 'components.messageNotification.labels.messageSupergroupChatCreate' | translate }} «{{ content.title }}»
    </span>

    <span *ngIf="content._ === 'messageChatChangeTitle'">
      <span class="link" [routerLink]="'/chats/' + whoId">{{ whoId | senderFullName | async }}</span>
      {{ 'components.messageNotification.labels.messageChatChangeTitle' | translate }} «{{ content.title }}»
    </span>

    <span *ngIf="content._ === 'messageChatChangePhoto'">
      <span class="link" [routerLink]="'/chats/' + whoId">{{ whoId | senderFullName | async }}</span>
      {{ 'components.messageNotification.labels.messageChatChangePhoto' | translate }}
    </span>

    <span *ngIf="content._ === 'messageChatDeletePhoto'">
      <span class="link" [routerLink]="'/chats/' + whoId">{{ whoId | senderFullName | async }}</span>
      {{ 'components.messageNotification.labels.messageChatDeletePhoto' | translate }}
    </span>

    <span *ngIf="content._ === 'messageChatJoinByLink'">
      <span class="link" [routerLink]="'/chats/' + whoId">{{ whoId | senderFullName | async }}</span>
      {{ 'components.messageNotification.labels.messageChatJoinByLink' | translate }}
    </span>

    <span *ngIf="content._ === 'messageChatJoinByRequest'">
      <span class="link" [routerLink]="'/chats/' + whoId">{{ whoId | senderFullName | async }}</span>
      {{ 'components.messageNotification.labels.messageChatJoinByRequest' | translate }}
    </span>

    <span *ngIf="content._ === 'messagePinMessage'">
      <span class="link" [routerLink]="'/chats/' + whoId">{{ whoId | senderFullName | async }}</span>
      {{ 'components.messageNotification.labels.messagePinMessage' | translate }}
      <span
        *ngIf="pinnedMessage$ | async as pinnedMessage"
        class="link"
        (click)="onClickPinnedMessage(content.messageId)"
      >
        «{{ pinnedMessage | messageNote | async }}»
      </span>
    </span>

    <span *ngIf="content._ === 'messageChatAddMembers'">
      <span *ngFor="let memberId of chatMembersIds" class="link" [routerLink]="'/chats/' + memberId">
        {{ memberId | senderFullName | async }}
      </span>
      {{ 'components.messageNotification.labels.messageChatAddMembers' | translate }}
    </span>

    <span *ngIf="content._ === 'messageChatDeleteMember'">
      <span class="link" [routerLink]="'/chats/' + whoId">{{ whoId | senderFullName | async }}</span>
      {{ 'components.messageNotification.labels.messageChatDeleteMember' | translate }}
      <span *ngFor="let memberId of chatMembersIds" class="link" [routerLink]="'/chats/' + memberId">
        {{ memberId | senderFullName | async }}
      </span>
    </span>
  </div>
</div>
