import {
  Component,
  ChangeDetectionStrategy,
  Input,
  OnChanges,
  SimpleChanges,
  ChangeDetectorRef,
  OnInit,
  OnDestroy,
  Output,
  EventEmitter,
} from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { MessageUnion } from '@airgram/web';
import { TelegramMessengerService } from '@src/app/modules/telegram';

@Component({
  selector: 'telegram-pinned-message-list',
  templateUrl: './pinned-message-list.component.html',
  styleUrls: ['./pinned-message-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PinnedMessageListComponent implements OnInit, OnChanges, OnDestroy {
  @Input() chatId?: number;
  @Output() scrollToMessageId: EventEmitter<number> = new EventEmitter();

  pinnedMessages?: MessageUnion[];
  visibledIndex: number = 0;

  private destroyed$$: Subject<void> = new Subject<void>();

  constructor(private cdr: ChangeDetectorRef, private messengerService: TelegramMessengerService) {}

  ngOnInit(): void {
    this.messengerService
      .updates$
      .pipe(takeUntil(this.destroyed$$))
      .subscribe(update => {
        switch (update._) {
          case 'updateMessageIsPinned':
            this.loadPinnedMessages();
            break;

          default:
            break;
        }
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.chatId) {
      this.loadPinnedMessages();
    }
    this.cdr.markForCheck();
  }

  ngOnDestroy(): void {
    this.destroyed$$.next();
    this.destroyed$$.complete();
  }

  scrollToMessage(messageId: number): void {
    this.nextVisibledIndex();
    this.scrollToMessageId.emit(messageId);
  }

  private loadPinnedMessages() {
    this.pinnedMessages = [];
    if (this.chatId) {
      this.messengerService
        .api
        .loadPinnedMessages(this.chatId)
        .then(pinnedMessages => {
          this.pinnedMessages = pinnedMessages.messages;
          this.visibledIndex = 0;
          this.cdr.markForCheck();
        });
    }
  }

  private nextVisibledIndex() {
    this.visibledIndex++;
    if (!this.pinnedMessages || this.visibledIndex >= this.pinnedMessages.length) {
      this.visibledIndex = 0;
    }
  }
}
