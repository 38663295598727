import { ViewLoyaltyRegion } from '@src/api';
import { Nullable } from '@src/types/utils';

import { SelectItem } from '../../types';

export const convertToRegions = (items: ViewLoyaltyRegion[]): Nullable<ReadonlyArray<SelectItem>> => {
  return items
    .map<SelectItem>(item => ({ id: item.id!, name: item.name }))
    .sort((a, b) => a.name.localeCompare(b.name));
};
