import {
  Component,
  ChangeDetectionStrategy,
  Input,
  ChangeDetectorRef,
  OnDestroy,
  EventEmitter,
  Output,
  SimpleChanges,
  ViewChild,
  OnInit,
} from '@angular/core';
import { BehaviorSubject, of, Subject } from 'rxjs';
import { debounceTime, map, startWith, switchMap, takeUntil, tap } from 'rxjs/operators';
import { TUI_DEFAULT_MATCHER } from '@taiga-ui/cdk';
import { TuiInputComponent } from '@taiga-ui/kit';
import { TranslateService } from '@ngx-translate/core';

import { addOrRemoveItemsToResult } from '../../utils/addOrRemoveItemsToResult';
import { checkAddOrRemoveItemsStatus } from '../../utils/checkAddOrRemoveItemsStatus';
import { SelectAllLinkMode } from '../select-all-link';
import { FilterItem } from '../../types';

import { FilterAccordionContentVariant } from './types';

@Component({
  selector: '[filter-accordion-content]',
  templateUrl: './filter-accordion-content.component.html',
  styleUrls: ['./filter-accordion-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilterAccordionContentComponent implements OnInit, OnDestroy {
  @Input() items: FilterItem[] = [];
  @Input() result: FilterItem[] = [];
  @Input() variant: FilterAccordionContentVariant = 'short';
  @Input() autofocus = false;
  @Output() resultChange = new EventEmitter<FilterItem[]>();

  searching = false;
  searchQuery: string = '';
  addOrRemoveItemsMode: SelectAllLinkMode = 'select-all';
  filteredItems: FilterItem[] = [];

  @ViewChild(TuiInputComponent)
  private readonly inputRef?: TuiInputComponent;
  private readonly searchQuery$ = new BehaviorSubject<string>('');
  private readonly destroyed$$: Subject<void> = new Subject<void>();

  readonly filteredItems$ = this.searchQuery$.pipe(
    tap(() => (this.searching = true)),
    debounceTime(300),
    startWith(''),
    switchMap(search =>
      of(this.items).pipe(
        map(items => {
          return items.filter(({ label, subLabel }) => TUI_DEFAULT_MATCHER(subLabel ?? label, search));
        }),
      ),
    ),
  );

  constructor(readonly cdr: ChangeDetectorRef, private readonly translateService: TranslateService) {
    this.filteredItems$.pipe(takeUntil(this.destroyed$$)).subscribe(items => {
      this.searching = false;
      this.filteredItems = items;
      this.checkAddOrRemoveItemsStatus();
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['result']) {
      this.checkAddOrRemoveItemsStatus();
    }
  }

  ngOnInit() {
    if (this.autofocus) {
      setTimeout(() => this.inputRef?.nativeFocusableElement?.focus(), 150);
    }
  }

  ngOnDestroy() {
    this.destroyed$$.next();
    this.destroyed$$.complete();
  }

  get noDataMessage(): string {
    if (!this.searchQuery) {
      return this.translateService.instant('components.searchUsers.labels.noDictionaryData');
    }

    return this.translateService.instant('components.searchUsers.labels.searchEmptyResult', {
      value: this.searchQuery,
    });
  }

  isChecked(item: FilterItem): boolean {
    return this.result.some(({ id }) => id === item.id);
  }

  onToggleItem(item: FilterItem) {
    const result = this.result.slice();

    const index = result.findIndex(({ id }) => id === item.id);
    if (index !== -1) {
      result.splice(index, 1);
    } else {
      result.push({ ...item });
    }

    this.resultChange.emit(result);
  }

  onChangeSearchQuery(query: string) {
    this.searchQuery$.next(query);
  }

  onAddOrRemoveItemsToResult() {
    this.resultChange.emit(addOrRemoveItemsToResult(this.addOrRemoveItemsMode, this.filteredItems, this.result));
  }

  private checkAddOrRemoveItemsStatus() {
    this.addOrRemoveItemsMode = checkAddOrRemoveItemsStatus(this.filteredItems, this.result);

    this.cdr.markForCheck();
  }
}
