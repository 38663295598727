<form class="view-all" [formGroup]="form">
  <app-skeleton-wrapper [loading]="loading">
    <div class="view-all__questions" formArrayName="questions">
      <div *ngFor="let question of questions.controls; let qIndex = index">
        <div [formGroupName]="qIndex">
          <question [formControl]="question"></question>
        </div>
      </div>
    </div>
  </app-skeleton-wrapper>

  <div *ngIf="!loading" class="view-all__buttons">
    <button
      tuiButton
      class="view-all-button view-all-button_cancel"
      type="button"
      appearance="secondary"
      [size]="tuiElementLargeSize"
      (click)="cancel()"
    >
      {{ 'common.buttons.cancel' | translate }}
    </button>

    <button tuiButton type="button" appearance="primary" [size]="tuiElementLargeSize" (click)="saveReplies()">
      {{ 'components.questionsViewAll.buttons.sendReplies' | translate }}
    </button>
  </div>
</form>

<ng-template #noData>
  <p>{{ 'common.labels.noData' | translate }}</p>
</ng-template>
