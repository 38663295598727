"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AirgramCore = void 0;
var components_1 = require("./components");
var utils_1 = require("./utils");
var getDefaultConfig = function () { return ({
    applicationVersion: '0.1.0',
    databaseDirectory: './db',
    databaseEncryptionKey: '',
    deviceModel: 'UNKNOWN DEVICE',
    // logVerbosityLevel: 2,
    systemLanguageCode: 'en',
    systemVersion: 'UNKNOWN VERSION'
}); };
var tdlibOptions = [
    'useTestDc',
    'databaseDirectory',
    'filesDirectory',
    'useFileDatabase',
    'useChatInfoDatabase',
    'useMessageDatabase',
    'useSecretChats',
    'apiId',
    'apiHash',
    'systemLanguageCode',
    'deviceModel',
    'systemVersion',
    'applicationVersion',
    'enableStorageOptimizer',
    'ignoreFileNames'
];
function createState(starting) {
    var state = __assign({}, starting);
    var getState = function () { return (__assign({}, state)); };
    var setState = function (next) {
        if (typeof next === 'function') {
            state = __assign(__assign({}, state), next(state));
        }
        else {
            state = __assign(__assign({}, state), next);
        }
    };
    return { getState: getState, setState: setState };
}
function isUnwrapped(o) {
    return typeof o !== 'function';
}
function isWrapped(o) {
    return typeof o === 'function';
}
var AirgramCore = /** @class */ (function () {
    function AirgramCore(providerFactory, config) {
        var _this = this;
        this.on = function (predicateTypes) {
            var _a;
            var fns = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                fns[_i - 1] = arguments[_i];
            }
            (_a = _this.composer).on.apply(_a, __spreadArrays([predicateTypes], fns));
        };
        this.config = __assign(__assign({}, getDefaultConfig()), config);
        this.composer = new components_1.Composer();
        this.provider = providerFactory(function (update) { return _this.handleUpdate(update); }, function (message) {
            var error = message instanceof Error ? message : new Error(message);
            _this.handleError(error);
        });
        this.provider.send({ method: 'getOption', params: { name: 'version' } }).catch(function () {
            throw Error('Could not get TDLib version.');
        });
        if (this.config.logVerbosityLevel !== undefined) {
            this.provider.execute({
                method: 'setLogVerbosityLevel',
                params: { newVerbosityLevel: this.config.logVerbosityLevel }
            });
        }
        this.handleError = function (error) {
            throw error;
        };
        this.callApi = this.callApi.bind(this);
        this.emit = this.emit.bind(this);
        this.api = new Proxy({}, {
            get: function (_target, method) {
                if (method === 'toJSON') {
                    return '{}';
                }
                return function (params, options) {
                    if (method.substr(-4) === 'Sync') {
                        return _this.provider.execute({ method: method.substr(0, method.length - 4), params: params });
                    }
                    return _this.callApi({ method: method, params: params }, options);
                };
            }
        });
        this.bootstrapMiddleware();
        setTimeout(function () { return _this.api.getAuthorizationState(); }, 0);
    }
    Object.defineProperty(AirgramCore.prototype, "name", {
        get: function () {
            return this.config.name || 'airgram';
        },
        enumerable: false,
        configurable: true
    });
    AirgramCore.prototype.catch = function (handler) {
        this.handleError = handler;
    };
    AirgramCore.prototype.destroy = function () {
        return this.provider.destroy();
    };
    AirgramCore.prototype.emit = function (update, state) {
        return this.handleUpdate(update, state || {});
    };
    AirgramCore.prototype.use = function () {
        var _a;
        var fns = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            fns[_i] = arguments[_i];
        }
        (_a = this.composer).use.apply(_a, fns);
    };
    AirgramCore.prototype.apiMiddleware = function () {
        var _this = this;
        return components_1.Composer.optional(function (ctx) { return !!ctx.request; }, function (ctx, next) { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, this.provider.send(ctx.request)
                        .then(function (response) { return (ctx.response = response); })
                        .then(next)];
            });
        }); });
    };
    AirgramCore.prototype.bootstrapMiddleware = function () {
        var _this = this;
        var deferred = utils_1.createDeferred();
        this.use(function (ctx, next) { return __awaiter(_this, void 0, void 0, function () {
            var update;
            return __generator(this, function (_a) {
                if (!deferred) {
                    return [2 /*return*/, next()];
                }
                if ('update' in ctx && ctx.update) {
                    if (ctx._ === 'updateAuthorizationState') {
                        update = ctx.update;
                        switch (update.authorizationState._) {
                            case 'authorizationStateWaitTdlibParameters': {
                                this.api.setTdlibParameters(
                                    __assign({ _: 'setTdlibParameters' }, utils_1.pick(this.config, tdlibOptions))
                                ).catch(this.handleError);
                                break;
                            }
                            case 'authorizationStateWaitEncryptionKey': {
                                this.api.checkDatabaseEncryptionKey({
                                    encryptionKey: this.config.databaseEncryptionKey
                                }).catch(this.handleError);
                                break;
                            }
                            default: {
                                setTimeout(function () {
                                    if (deferred) {
                                        deferred.resolve();
                                        deferred = null;
                                    }
                                }, 0);
                            }
                        }
                    }
                    return [2 /*return*/, next()];
                }
                else if (['setTdlibParameters', 'checkDatabaseEncryptionKey'].includes(ctx._)) {
                    return [2 /*return*/, next()];
                }
                return [2 /*return*/, deferred.promise.then(next)];
            });
        }); });
    };
    AirgramCore.prototype.callApi = function (request, options) {
        var _this = this;
        return this.createContext(request.method, { options: options || {}, request: request }).then(function (ctx) { return new Promise(function (resolve, reject) {
            var handler = components_1.Composer.compose([_this.composer.middleware(), _this.apiMiddleware()]);
            return handler(ctx, function () { return __awaiter(_this, void 0, void 0, function () { return __generator(this, function (_a) {
                return [2 /*return*/, resolve(ctx)];
            }); }); }).catch(reject);
        }); });
    };
    AirgramCore.prototype.createContext = function (_, props) {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var ctx, descriptor, defineProperties, _b;
            var _this = this;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        ctx = {};
                        descriptor = {
                            enumerable: true,
                            configurable: false
                        };
                        Object.defineProperty(ctx, 'airgram', __assign(__assign({}, descriptor), { get: function () { return _this; } }));
                        defineProperties = function (obj) { return Object.keys(obj).forEach(function (name) {
                            Object.defineProperty(ctx, name, __assign(__assign({}, descriptor), { value: obj[name], writable: false }));
                        }); };
                        defineProperties(createState(((_a = props === null || props === void 0 ? void 0 : props.options) === null || _a === void 0 ? void 0 : _a.state) || {}));
                        defineProperties({ _: _ });
                        defineProperties(props);
                        _b = defineProperties;
                        return [4 /*yield*/, this.getExtraContext(ctx)];
                    case 1:
                        _b.apply(void 0, [_c.sent()]);
                        return [2 /*return*/, ctx];
                }
            });
        });
    };
    AirgramCore.prototype.getExtraContext = function (ctx) {
        return __awaiter(this, void 0, void 0, function () {
            var context;
            return __generator(this, function (_a) {
                context = this.config.context;
                if (context) {
                    if (isUnwrapped(context)) {
                        return [2 /*return*/, context];
                    }
                    if (isWrapped(context)) {
                        return [2 /*return*/, context(ctx)];
                    }
                }
                return [2 /*return*/, {}];
            });
        });
    };
    AirgramCore.prototype.handleUpdate = function (update, state) {
        var _this = this;
        if (state === void 0) { state = {}; }
        return this.createContext(update._, { update: update, options: { state: state } })
            .then(function (ctx) { return _this.composer.middleware()(ctx, components_1.Composer.noop); });
    };
    return AirgramCore;
}());
exports.AirgramCore = AirgramCore;
