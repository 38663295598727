import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { AttachmentItem } from '@src/models';

@Component({
  selector: 'telegram-attachment-preview',
  templateUrl: './attachment-preview.component.html',
  styleUrls: ['./attachment-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AttachmentPreviewComponent {
  @Input() attachmentPreview?: AttachmentItem[];
}
