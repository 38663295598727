import { AsYouType } from 'libphonenumber-js/core';
import metadata from 'libphonenumber-js/min/metadata';

/**
 * Утилита для приведения номера телефона в обычный вид (код страны может быть любой)
 * @param formattedValue номер телефона форматированном виде (с использованием маски) ('+7 XXX XXX XX-XX')
 * @returns номер телефона в обычном виде ('+7XXXXXXXXXX')
 */
export const normalizePhone = (formattedValue?: string) => {
  if (!formattedValue) {
    return;
  }

  const asYouType = new AsYouType(undefined, metadata);
  asYouType.input(formattedValue);

  return asYouType.getNumber()?.number || formattedValue || '';
};
