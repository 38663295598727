<div class="view-header">
  <span *ngIf="isMobile">{{ 'components.loyaltyViewHeader.labels.title' | translate }}</span>

  <div class="view-header__wrapper">
    <button
      tuiIconButton
      type="button"
      [appearance]="isDesktop ? 'secondary' : 'icon'"
      [size]="isDesktop ? 'l' : 'm'"
      icon="tuiIconChevronLeftLarge"
      (click)="onClickBackButton()"
    ></button>

    <div class="view-header__title">
      <div class="view-header__title_breadcrumbs">
        <ng-container *ngFor="let item of breadcrumbs; let index = index">
          <app-link
            [routerLink]="item.routerLink"
            [queryParams]="item.queryParams"
            [displayText]="item.caption"
          ></app-link>
          <span *ngIf="index < breadcrumbs.length - 1">/</span>
        </ng-container>
      </div>

      <h1 class="view-header__title_subtitle" [title]="title">{{ title }}</h1>
    </div>

    <div class="view-header__action-buttons">
      <ng-container *ngIf="isDesktop">
        <lp-statistics-button
          *ngIf="screenType !== 'medium' && allowedAllStatisticsViewing"
          [screenDimension]="screenDimension"
          (buttonClick)="onMenuClick('statistics')"
        ></lp-statistics-button>

        <button
          *ngIf="isExtraLargeScreen && allowedPublishing"
          tuiButton
          type="button"
          size="l"
          appearance="white-button"
          (click)="action.emit('publish')"
        >
          {{ 'components.loyaltyViewHeader.buttons.publish' | translate }}
        </button>

        <button
          *ngIf="screenType !== 'medium' && allowedEditing"
          tuiButton
          type="button"
          size="l"
          (click)="action.emit('edit')"
        >
          {{ 'common.buttons.edit' | translate }}
        </button>
      </ng-container>

      <tui-hosted-dropdown
        *ngIf="
          isPublishDropdownActionVisible ||
          isEditDropdownActionVisible ||
          isUnPublishDropdownActionVisible ||
          allowedDeleting
        "
        tuiDropdownAlign="left"
        [content]="dropdown"
        [(open)]="showMenu"
      >
        <button
          tuiIconButton
          type="button"
          [appearance]="isDesktop ? 'secondary' : 'flat'"
          [size]="isDesktop ? 'l' : 'm'"
          [icon]="isDesktop ? 'tuiIconMoreVerticalLarge' : 'tuiIconMoreHorizontalLarge'"
        ></button>
      </tui-hosted-dropdown>
    </div>
  </div>
</div>

<ng-template #dropdown>
  <tui-data-list class="view-header__menu-dropdown">
    <button *ngIf="isStatisticsDropdownActionVisible" tuiOption (click)="onMenuClick('statistics')">
      {{ 'common.buttons.statistics' | translate }}
    </button>
    <button *ngIf="isPublishDropdownActionVisible" tuiOption (click)="onMenuClick('publish')">
      {{ 'components.loyaltyViewHeader.buttons.publish' | translate }}
    </button>
    <button *ngIf="isEditDropdownActionVisible" tuiOption (click)="onMenuClick('edit')">
      {{ 'common.buttons.edit' | translate }}
    </button>
    <button *ngIf="isUnPublishDropdownActionVisible" tuiOption (click)="onMenuClick('unpublish')">
      {{ 'components.loyaltyViewHeader.buttons.unpublish' | translate }}
    </button>
    <div
      *ngIf="
        (isPublishDropdownActionVisible || isEditDropdownActionVisible || isUnPublishDropdownActionVisible) &&
        allowedDeleting
      "
      class="view-header__menu-dropdown_divider"
    ></div>
    <button
      tuiOption
      *ngIf="allowedDeleting"
      (click)="onMenuClick('delete')"
      class="view-header__menu-dropdown_delete-btn"
    >
      {{ 'components.loyaltyViewHeader.buttons.deleteAd' | translate }}
    </button>
  </tui-data-list>
</ng-template>
