import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Injector,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { formatDate } from '@angular/common';
import { BreakpointObserver } from '@angular/cdk/layout';
import { FormControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { BehaviorSubject, Observable, lastValueFrom } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { TuiContextWithImplicit, tuiPure, TuiStringHandler } from '@taiga-ui/cdk';
import { TuiDialogService } from '@taiga-ui/core';
import { PolymorpheusComponent } from '@tinkoff/ng-polymorpheus';
import { maskitoGetCountryFromNumber, maskitoPhoneOptionsGenerator } from '@maskito/phone';
import metadata from 'libphonenumber-js/min/metadata';
import { APP_CONFIG, Language } from '@src/core';
import { AUTOCOMPLETE_TYPES } from '@src/constants';
import {
  AlertService,
  BreakpointObserverHelperService,
  JobTitleService,
  RoleService,
  UserService,
} from '@src/core/services';
import { JobTitleUI, UserUI, ContactUI } from '@src/models';
import {
  GetUserContactsForUnionResponseDto,
  ModeratedRegistrationFormRequestDto,
  ModeratedRegistrationFormRequestJobTitleItemDto,
  ModeratedRegistrationFormRequestOrganisationDto,
  RegistrationFormRequestContactDto,
  RegistrationFormRequestDtoWithId,
  RoleView,
  UserRegistrationService,
} from '@src/api';
import { isFileContact } from '@src/utils';
import { ResizableBaseComponent } from '@src/app/components/resizable-base-component';
import { TranslateService } from '@ngx-translate/core';
import { formatPhone, inputPhoneFormValidator } from '@src/app/modules/phone';
import { Nullable, Optional } from '@src/types/utils';
import { DialogConfirmComponent } from '@src/app/shared/dialogs';
import { SessionService } from '@src/app/modules/auth';

import { MONTHS_ARRAY } from '../constants';

import { BirthMonth, FormData, FormDataControls } from './types';

@Component({
  selector: 'app-user-info-moderation',
  templateUrl: './user-info-moderation.component.html',
  styleUrls: ['./user-info-moderation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserInfoModerationComponent extends ResizableBaseComponent implements OnChanges {
  @Input() registrationFormId?: string;
  @Output() saved = new EventEmitter<void>();
  @Output() canceled = new EventEmitter<void>();
  @Output() rejected = new EventEmitter<void>();

  data: UserUI | null = {};

  readonly maxDocFileSize = APP_CONFIG.fileSizeMax.doc;
  readonly autocompleteTypes = AUTOCOMPLETE_TYPES;
  loading: boolean = false;
  jobsForViewing$: BehaviorSubject<JobTitleUI[]> = this.jobTitleService.jobTitles$;
  jobsForEditing$: Observable<JobTitleUI[]> = this.jobTitleService.jobTitles$;
  roles$?: Observable<RoleView[]>;
  rolesForAssociation$?: Observable<RoleView[]>;
  rolesForOrganisation$?: Observable<RoleView[]>;
  form!: UntypedFormGroup;
  authUser$ = this.userService.authUser$;
  currentParentOrganisationId?: string;

  months: BirthMonth[] = MONTHS_ARRAY;

  private readonly confirmRejectDialog = this.dialogService.open<boolean>(
    new PolymorpheusComponent(DialogConfirmComponent, this.injector),
    {
      label: this.translateService.instant('components.userInfoModeration.dialogs.confirmRejectDialogHeader'),
      data: this.translateService.instant('components.userInfoModeration.dialogs.confirmRejectDialogBody'),
      size: 's',
      closeable: false,
    },
  );

  private readonly sendWelcomeMessageDialog = this.dialogService.open<boolean>(
    new PolymorpheusComponent(DialogConfirmComponent, this.injector),
    {
      label: this.translateService.instant('components.userInfoModeration.dialogs.sendWelcomeMessageDialogHeader'),
      data: this.translateService.instant('components.userInfoModeration.dialogs.sendWelcomeMessageDialogBody'),
      size: 's',
      closeable: false,
    },
  );

  constructor(
    readonly cdr: ChangeDetectorRef,
    readonly breakpointObserver: BreakpointObserver,
    readonly breakpointObserverHelperService: BreakpointObserverHelperService,
    private formBuilder: UntypedFormBuilder,
    private jobTitleService: JobTitleService,
    private roleService: RoleService,
    private userService: UserService,
    private alertService: AlertService,
    private readonly translateService: TranslateService,
    private readonly userRegistrationService: UserRegistrationService,
    private readonly sessionService: SessionService,
    @Inject(TuiDialogService) private readonly dialogService: TuiDialogService,
    @Inject(Injector) private readonly injector: Injector,
  ) {
    super(cdr, breakpointObserver, breakpointObserverHelperService);

    this.initForm();
  }

  get organisationControl(): UntypedFormGroup {
    return this.form.get('organisation') as UntypedFormGroup;
  }

  get phoneControl(): UntypedFormControl {
    return this.form.get('phone') as UntypedFormControl;
  }

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    if (changes['registrationFormId']) {
      this.loading = true;
      this.initForm();

      if (this.registrationFormId) {
        const registrationForm: RegistrationFormRequestDtoWithId = await lastValueFrom(
          this.userRegistrationService
            .getRegistrationFormById(this.registrationFormId)
            .pipe(takeUntil(this.destroyed$$)),
        );

        const mappedData = await this.mapApiDataToFormData(registrationForm);
        this.form.patchValue(mappedData);

        if (registrationForm.contacts) {
          this.setContactsForUnion(registrationForm.contacts);
        }

        this.loading = false;
        this.cdr.markForCheck();
      }
    }

    this.rolesForAssociation$ = this.roleService.roles$.pipe(
      map(roles =>
        roles.filter(role => {
          if (!role.name) return false;
          return !['AdminDO', 'ParticipantDO'].includes(role.name); // TODO: строки заменить на RoleCode
        }),
      ),
    );

    this.rolesForOrganisation$ = this.roleService.roles$.pipe(
      map(roles =>
        roles.filter(role => {
          if (!role.name) return false;
          return !['AdminUO', 'ParticipantUO'].includes(role.name); // TODO: строки заменить на RoleCode
        }),
      ),
    );

    this.cdr.markForCheck();
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.currentParentOrganisationId = this.sessionService.currentParentOrganisationId;

    this.jobTitleService.getJobTitles();
    this.roleService.getRoles();
  }

  async onChangePhone(phone: string) {
    this.phoneControl?.patchValue(phone);
  }

  onClickSaveButton(): void {
    this.loading = true;
    this.form.markAllAsTouched();

    if (this.form.invalid) {
      this.alertService.error(this.translateService.instant('common.alerts.errors.fillRequired'));
      this.loading = false;
      return;
    }

    const formData = this.mapFormDataToApiData(this.form.getRawValue());

    this.sendWelcomeMessageDialog.pipe(takeUntil(this.destroyed$$)).subscribe({
      next: res => {
        formData.isSendWelcomeMessage = res;
        this.onSave(formData);
      },
    });
    this.loading = false;
  }

  onClickRejectButton() {
    this.confirmRejectDialog.pipe(takeUntil(this.destroyed$$)).subscribe({
      next: res => {
        if (res) {
          if (this.registrationFormId) {
            this.onReject(this.registrationFormId);
          }
        }
      },
    });
  }

  onClickCancelButton(): void {
    this.canceled.emit();
  }

  onChangeSelectedCommitteeIds(committeeIds: string[]) {
    this.form.patchValue({ committeeIds });
  }

  readonly stringifyJobTitle: TuiStringHandler<any | TuiContextWithImplicit<any>> = item => {
    return this.jobsForViewing$.value.find(jobs => jobs.jobTitleId === item.jobTitleId)?.jobTitleName ?? '';
  };

  @tuiPure
  rolesStringify(items: ReadonlyArray<RoleView>): TuiStringHandler<TuiContextWithImplicit<string[]>> {
    const map = new Map(items.map(({ id, description }) => [id, description] as [string, string]));
    return ({ $implicit }: TuiContextWithImplicit<string[]>) => map.get($implicit[0]) || '';
  }

  @tuiPure
  monthStringify(months: readonly BirthMonth[]): TuiStringHandler<TuiContextWithImplicit<number>> {
    const map = new Map(months.map(({ id, name }) => [id, name] as [number, string]));
    return ({ $implicit }: TuiContextWithImplicit<number>) => this.translateService.instant(map.get($implicit) || ' ');
  }

  private initForm(): void {
    this.form = new UntypedFormGroup(<FormDataControls>{
      id: new UntypedFormControl(undefined),
      parentOrganisationId: new UntypedFormControl(undefined),
      firstName: new UntypedFormControl(undefined, [Validators.required, Validators.maxLength(50)]),
      middleName: new UntypedFormControl(undefined, Validators.maxLength(50)),
      lastName: new UntypedFormControl(undefined, [Validators.required, Validators.maxLength(50)]),
      phone: new UntypedFormControl(undefined, [Validators.required, inputPhoneFormValidator()]),
      email: new UntypedFormControl(undefined, Validators.required),
      birthDate: new UntypedFormControl(undefined),
      birthDateDay: new UntypedFormControl(undefined),
      birthDateMonth: new UntypedFormControl(undefined),
      birthDateYear: new UntypedFormControl(undefined),
      resources: new UntypedFormControl(undefined),
      hobbies: new UntypedFormControl(undefined),
      isFeePaid: new UntypedFormControl(false),
      authorityValidTill: new UntypedFormControl(undefined),
      contacts: new FormControl<ContactUI[]>([], { nonNullable: true }), // TODO: переписать на нормальный тип когда будем избавляться от UntypedFormControl
      curator: new UntypedFormControl(null),
      organisation: this.formBuilder.group({
        organisationId: new UntypedFormControl(undefined, Validators.required),
        jobTitles: new UntypedFormControl([], Validators.required),
        jobTitlesDescription: new UntypedFormControl(''),
        roleIds: new UntypedFormControl({ value: [], disabled: true }, Validators.required),
      }),
      committeeIds: new UntypedFormControl([]),
    });

    this.organisationControl
      .get('organisationId')
      ?.valueChanges.pipe()
      .subscribe(organisationId => {
        const roleControl = this.organisationControl.get('roleIds');

        if (organisationId) {
          this.roles$ =
            organisationId === this.currentParentOrganisationId
              ? this.rolesForAssociation$
              : this.rolesForOrganisation$;

          roleControl?.enable();
        } else {
          roleControl?.disable();
        }

        roleControl?.setValue([]);
        this.cdr.markForCheck();
      });
  }

  private async mapApiDataToFormData(registrationForm: RegistrationFormRequestDtoWithId) {
    const { id, parentOrganisationId, firstName, lastName, middleName, email } = registrationForm;

    let phone = registrationForm.phone;
    if (phone) {
      const countryIsoCode = maskitoGetCountryFromNumber(`+${phone}` || '', metadata);
      const mask = maskitoPhoneOptionsGenerator({
        metadata,
        strict: false,
        countryIsoCode,
      });
      phone = formatPhone(phone, mask) || phone;
    }

    return <FormData>{
      id,
      parentOrganisationId,
      firstName,
      lastName,
      middleName,
      email,
      phone,
    };
  }

  private mapFormDataToApiData(formData: FormData): ModeratedRegistrationFormRequestDto {
    const {
      id,
      parentOrganisationId,
      firstName,
      middleName,
      lastName,
      email,
      birthDateDay,
      resources,
      hobbies,
      isFeePaid,
      curator,
      organisation,
      committeeIds,
    } = formData;

    const birthDateMonth = formData.birthDateMonth;
    const birthDateYear = formData.birthDateYear ?? 2999;
    const birthDate =
      birthDateDay && birthDateMonth ? new Date(birthDateYear, birthDateMonth - 1, birthDateDay, 0, 0, 0) : null;

    const authorityValidTill = formData.authorityValidTill
      ? new Date(formData.authorityValidTill.year, formData.authorityValidTill.month, formData.authorityValidTill.day)
      : undefined;

    const jobTitleItems = organisation.jobTitles?.map(({ jobTitleName, jobTitleId }) => {
      return <ModeratedRegistrationFormRequestJobTitleItemDto>{
        jobTitleId,
        jobTitleDescription: jobTitleName,
      };
    });

    const organisationData: ModeratedRegistrationFormRequestOrganisationDto = {
      organisationId: organisation.organisationId,
      userRoleId: organisation.roleIds?.[0],
      jobTitles: {
        jobTitleDescription: organisation.jobTitlesDescription,
        items: jobTitleItems,
      },
    };

    const contacts: RegistrationFormRequestContactDto[] = formData.contacts.map((contact: ContactUI) => {
      return {
        contactTypeId: contact.contactTypeId,
        value: contact.contact,
        description: contact.description,
        isRequired: contact.isRequired,
      };
    });

    return {
      id,
      parentOrganisationId,
      firstName,
      middleName,
      lastName,
      phone: formData.phone,
      email,
      birthDate: birthDate ? formatDate(birthDate, 'yyyy-MM-dd', Language.EN) : undefined,
      organisation: organisationData,
      committeeIds,

      resources,
      hobbies,
      isFeePaid: isFeePaid !== null ? isFeePaid : undefined,
      authorityValidTill: authorityValidTill ? formatDate(authorityValidTill, 'yyyy-MM-dd', Language.EN) : undefined,

      curatorId: curator?.id,
      contacts,
    };
  }

  private normalizeContacts(contacts: ContactUI[]) {
    return contacts.map((contact: ContactUI) => {
      contact.contact = contact.value;

      if (isFileContact(contact.contactTypeId) && contact.contact) {
        contact.oldDocument = {
          id: contact.contact,
          name: contact.description ?? this.translateService.instant('components.userInfo.labels.file'),
        };
      }

      return contact;
    });
  }

  private setContactsForUnion(contactsForUnion: Optional<Nullable<Array<GetUserContactsForUnionResponseDto>>>) {
    if (contactsForUnion) {
      this.form.patchValue({ contacts: this.normalizeContacts(contactsForUnion) });
    }
  }

  private onReject(registrationForm: string) {
    this.userRegistrationService
      .rejectRegistrationForm(registrationForm)
      .pipe()
      .subscribe(() => {
        this.rejected.emit();
      });
  }

  private onSave(data: ModeratedRegistrationFormRequestDto) {
    this.userRegistrationService
      .acceptRegistrationForm(data)
      .pipe()
      .subscribe(() => {
        if (data.isSendWelcomeMessage) {
          this.alertService.success(
            this.translateService.instant('components.userInfoModeration.alerts.success.invitationSent'),
          );
        }
        this.saved.emit();
      });
  }
}
