<div class="list-item-user">
  <app-photo
    size="m"
    [rounded]="true"
    [photoId]="user.photoId"
    [text]="user | userDisplayableName : 'short'"
  ></app-photo>

  <div class="list-item-user__info">
    <div class="list-item-user__title">
      <span class="list-item-user__text text_size-m text_color-01" [title]="user | userDisplayableName">
        {{ user | userDisplayableName }}
      </span>

      <ng-container *ngIf="birthDateContent">
        <ng-container *ngTemplateOutlet="birthDateContent; context: { $implicit: user.birthDate }"></ng-container>
      </ng-container>
    </div>

    <span class="list-item-user__text text_size-xs text_color-03 text_truncate" [title]="user.organisationName">
      {{ user.organisationName }}
    </span>

    <span class="list-item-user__text text_size-xs text_color-03 text_truncate" [title]="user.jobTitlesDescription">
      {{ user.jobTitlesDescription }}
    </span>
  </div>
</div>
