import { Component, ChangeDetectionStrategy } from '@angular/core';
import { ResizableBaseComponent } from '@src/app/components/resizable-base-component';

@Component({
  selector: 'app-support-page',
  templateUrl: './support-page.component.html',
  styleUrls: ['./support-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SupportPageComponent extends ResizableBaseComponent {}
