import { environment } from '@src/environments/environment';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';

import { NavigationItem, NavigationTree } from './types';

export const NAVIGATION_ITEMS: NavigationItem[] = [
  {
    id: 'menu.info',
    icon: 'menu',
    title: marker('components.navigation.constants.navigationItems.infoTitle'),
    link: '/info',
    permissions: ['pageInfoViewing'],
    mobileOnly: true,
  },
  {
    id: 'menu.association',
    icon: 'landmark',
    title: marker('components.navigation.constants.navigationItems.associationTitle'),
    link: '/association',
    permissions: ['pageAssociationViewing'],
  },
  {
    id: 'menu.organisations',
    icon: 'building-2',
    title: marker('components.navigation.constants.navigationItems.organisationsTitle'),
    link: '/association-organisations/null',
    permissions: ['pageAssociationOrganisationsViewing'],
  },
  {
    id: 'menu.users',
    icon: 'users',
    title: marker('components.navigation.constants.navigationItems.usersTitle'),
    link: '/association-users/null',
    permissions: ['pageAssociationUsersViewing'],
  },
  {
    id: 'menu.committees',
    icon: 'network',
    title: marker('components.navigation.constants.navigationItems.committeesTitle'),
    link: '/committees/null',
    permissions: ['pageCommitteesViewing'],
  },
  {
    id: 'menu.chats',
    title: marker('components.navigation.constants.navigationItems.chatsTitle'),
    icon: 'send',
    link: '/chats/null',
    permissions: ['pageChatsViewing'],
  },
  {
    id: 'menu.loyalty',
    title: marker('components.navigation.constants.navigationItems.loyaltyTitle'),
    icon: 'book',
    link: '/loyalty',
    permissions: ['pageLoyaltyViewing'],
  },
  {
    id: 'menu.events',
    title: marker('components.navigation.constants.navigationItems.eventsTitle'),
    icon: 'calendar',
    link: '/events/null',
    permissions: ['pageEventsViewing'],
  },
  {
    id: 'menu.polls',
    title: marker('components.navigation.constants.navigationItems.pollsTitle'),
    icon: 'bar-chart-2',
    link: '/polls/null',
    permissions: ['pagePollsViewing'],
  },
  {
    id: 'menu.mailing',
    title: marker('components.navigation.constants.navigationItems.newsletterTitle'),
    icon: 'mail',
    link: '/newsletter',
    permissions: ['pageNewsletterViewing'],
  },
  {
    id: 'menu.newsFeed',
    title: marker('components.navigation.constants.navigationItems.newsFeedTitle'),
    icon: 'radio',
    link: '/news-feed/null',
    permissions: ['pageNewsFeedViewing'],
  },
  // TODO: временное решение для прода. пока не показываем КЦ. удалить когда доделаем КЦ
  !environment.production
    ? {
        id: 'menu.cooperationChains',
        title: marker('components.navigation.constants.navigationItems.cooperationChainsTitle'),
        description: marker('components.navigation.constants.navigationItems.cooperationChainsDescription'),
        icon: 'square-stack',
        link: '/cooperation-chains',
        permissions: ['cooperationChainsViewing'],
      }
    : ({} as NavigationItem),
  {
    id: 'menu.settings',
    title: marker('components.navigation.constants.navigationItems.settingsTitle'),
    description: marker('components.navigation.constants.navigationItems.settingsDescription'),
    icon: 'settings',
    link: '/settings',
    permissions: ['pageSettingsViewing'],
  },
  {
    id: 'menu.organisation',
    title: marker('components.navigation.constants.navigationItems.organisationTitle'),
    description: marker('components.navigation.constants.navigationItems.organisationDescription'),
    icon: 'building',
    link: '/organisation',
    permissions: ['pageOrganisationViewing'],
  },
  {
    id: 'menu.profile',
    title: marker('components.navigation.constants.navigationItems.profileTitle'),
    description: marker('components.navigation.constants.navigationItems.profileDescription'),
    icon: 'user',
    link: '/settings/profile',
    permissions: ['pageProfileViewing'],
  },
  {
    id: 'menu.folders',
    title: marker('components.navigation.constants.navigationItems.foldersTitle'),
    description: marker('components.navigation.constants.navigationItems.foldersDescription'),
    icon: 'folders',
    link: '/settings/folders',
    permissions: ['pageSettingsFoldersViewing'],
  },
  {
    id: 'menu.preferences',
    title: marker('components.navigation.constants.navigationItems.preferencesTitle'),
    icon: 'table',
    link: '/settings/preferences',
    permissions: ['pageSettingsPreferencesViewing'],
  },
  {
    id: 'menu.notifications',
    title: marker('components.navigation.constants.navigationItems.notificationsTitle'),
    icon: 'bell-ring',
    link: 'notifications',
    permissions: ['pageSettingsNotificationsViewing'],
  },
  {
    id: 'menu.administration',
    title: marker('components.navigation.constants.navigationItems.administrationTitle'),
    description: marker('components.navigation.constants.navigationItems.administrationDescription'),
    icon: 'shield-check',
    link: '/settings/administration',
    permissions: ['pageAdministrationViewing'],
  },
  {
    id: 'menu.language',
    title: marker('components.navigation.constants.navigationItems.languageTitle'),
    icon: 'languages',
    link: 'language',
  },
  {
    id: 'menu.theme',
    title: marker('components.navigation.constants.navigationItems.themeTitle'),
    icon: 'sun',
    link: 'theme',
  },
  {
    id: 'menu.version',
    title: marker('components.navigation.constants.navigationItems.versionTitle'),
    description: environment.appVersion,
    icon: 'hash',
    link: '/settings/changelog',
  },
  {
    id: 'menu.support',
    title: marker('components.navigation.constants.navigationItems.supportTitle'),
    description: marker('components.navigation.constants.navigationItems.supportDescription'),
    icon: 'help-circle',
    link: 'support',
  },
  {
    id: 'menu.licenses',
    title: marker('components.navigation.constants.navigationItems.licensesTitle'),
    icon: 'copyright',
    link: '/settings/licenses',
  },
  {
    id: 'menu.logout',
    title: marker('components.navigation.constants.navigationItems.logoutTitle'),
    description: marker('components.navigation.constants.navigationItems.logoutDescription'),
    icon: 'log-out',
  },
  {
    id: 'menu.curator',
    title: marker('components.navigation.constants.navigationItems.curatorTitle'),
    icon: 'user',
  },
  {
    id: 'menu.cc-suppliers',
    title: marker('components.navigation.constants.navigationItems.CcSuppliersTitle'),
    icon: 'table',
    link: '/cooperation-chains/suppliers',
    permissions: ['cooperationChainsViewing'],
  },
  {
    id: 'menu.cc-configuration',
    title: marker('components.navigation.constants.navigationItems.CcConfigurationTitle'),
    icon: 'table',
    link: '/cooperation-chains/configuration',
    permissions: ['cooperationChainsViewing'],
  },
  {
    id: 'menu.cc-analysis',
    title: marker('components.navigation.constants.navigationItems.CcAnalysisTitle'),
    icon: 'table',
    link: '/cooperation-chains/analysis',
    permissions: ['cooperationChainsViewing'],
  },
  {
    id: 'menu.cc-dictionaries',
    title: marker('components.navigation.constants.navigationItems.CcDictionariesTitle'),
    icon: 'table',
    link: '/cooperation-chains/dictionaries',
    permissions: ['cooperationChainsViewing'],
  },
  {
    id: 'menu.cc-configuration-dictionaries',
    title: marker('components.navigation.constants.navigationItems.CcConfigurationDictionariesTitle'),
    icon: 'settings',
    link: '/cooperation-chains/configuration-dictionaries',
    permissions: ['cooperationChainsViewing'],
  },

  {
    id: 'menu.administrationNewUsers',
    icon: 'users',
    title: marker('components.administration.labels.menuNewUsers'),
    link: '/settings/administration/new-users',
    permissions: ['pageAdministrationViewing'],
  },
  {
    id: 'menu.administrationModerationUsers',
    icon: 'users',
    title: marker('components.administration.labels.menuModerationUsers'),
    link: '/settings/administration/moderation-users',
    permissions: ['pageAdministrationViewing'],
  },
  {
    id: 'menu.administrationNewAssociation',
    icon: 'circle-plus',
    title: marker('components.administration.labels.menuNewAssociation'),
    link: '/settings/administration/new-association',
    permissions: ['associationCreating'],
  },
  {
    id: 'menu.administrationCustomNames',
    icon: 'file-pen-line',
    title: marker('components.administration.labels.menuCustomNames'),
    link: '/settings/administration/custom-names',
    permissions: ['pageAdministrationViewing'],
  },
  {
    id: 'menu.administrationSelectJobTitles',
    icon: 'circle-check',
    title: marker('components.administration.labels.menuSelectJobTitles'),
    link: '/settings/administration/select-job-titles',
    permissions: ['pageAdministrationViewing'],
  },
  {
    id: 'menu.administrationSelectBusinessTypes',
    icon: 'circle-check',
    title: marker('components.administration.labels.menuSelectBusinessTypes'),
    link: '/settings/administration/select-business-types',
    permissions: ['pageAdministrationViewing'],
  },
  {
    id: 'menu.administrationImport',
    icon: 'upload',
    title: marker('components.administration.labels.menuImport'),
    link: '/settings/administration/import',
    permissions: ['importViewing'],
  },
  {
    id: 'menu.administrationMail',
    icon: 'mail',
    title: marker('components.administration.labels.menuMail'),
    link: '/settings/administration/mail',
    permissions: ['pageAdministrationViewing'],
  },
  {
    id: 'menu.administrationReports',
    icon: 'file',
    title: marker('components.administration.labels.menuReports'),
    link: '/settings/administration/reports',
    permissions: ['pageAdministrationViewing'],
  },
  {
    id: 'menu.administrationStandardUserInfoFields',
    icon: 'user-cog',
    title: marker('components.administration.labels.menuStandardUserInfoFields'),
    link: '/settings/administration/standard-user-info-fields',
    permissions: ['pageAdministrationViewing'],
  },
];

export const DESKTOP_NAVIGATION: NavigationTree[] = [
  { itemName: 'menu.association' },
  { itemName: 'menu.organisations' },
  { itemName: 'menu.users' },
  { itemName: 'menu.committees' },
  { itemName: 'menu.chats' },
  { itemName: 'menu.loyalty' },
  { itemName: 'menu.events' },
  { itemName: 'menu.polls' },
  { itemName: 'menu.mailing' },
  { itemName: 'menu.newsFeed' },
  { itemName: 'menu.cooperationChains' },
  { itemName: 'menu.settings' },
  { itemName: 'menu.curator' },
];

export const MOBILE_NAVIGATION: NavigationTree[] = [
  { itemName: 'menu.info' },
  { itemName: 'menu.chats' },
  { itemName: 'menu.events' },
  { itemName: 'menu.polls' },
  { itemName: 'menu.newsFeed' },
];

export const INFO_NAVIGATION: NavigationTree[] = [
  { itemName: 'menu.association' },
  { itemName: 'menu.organisations' },
  { itemName: 'menu.users' },
  { itemName: 'menu.loyalty' },
  { itemName: 'menu.committees' },
  { itemName: 'menu.mailing' },
  { itemName: 'menu.cooperationChains' },
  { itemName: 'menu.settings' },
  { itemName: 'menu.curator' },
];

export const SETTINGS_NAVIGATION: NavigationTree[] = [
  { itemName: 'menu.profile' },
  { itemName: 'menu.folders' },
  { itemName: 'menu.preferences' },
  { itemName: 'menu.mail' },
  { itemName: 'menu.notifications' },
  { itemName: 'menu.administration' },
  { itemName: 'menu.language' },
  { itemName: 'menu.theme' },
  { itemName: 'menu.version' },
  { itemName: 'menu.support' },
  { itemName: 'menu.licenses' },
  { itemName: 'menu.logout' },
];

export const ONLY_LP_SETTINGS_NAVIGATION: NavigationTree[] = [
  { itemName: 'menu.loyalty' },
  { itemName: 'menu.language' },
  { itemName: 'menu.theme' },
  { itemName: 'menu.support' },
  { itemName: 'menu.logout' },
];

export const COOPERATION_CHAINS_NAVIGATION: NavigationTree[] = [
  { itemName: 'menu.cc-suppliers' },
  { itemName: 'menu.cc-configuration' },
  { itemName: 'menu.cc-analysis' },
  { itemName: 'menu.cc-dictionaries' },
  { itemName: 'menu.cc-configuration-dictionaries' },
];

export const ADMINISTRATION_NAVIGATION: NavigationTree[] = [
  { itemName: 'menu.administrationNewUsers' },
  { itemName: 'menu.administrationModerationUsers' },
  { itemName: 'menu.administrationNewAssociation' },
  { itemName: 'menu.administrationCustomNames' },
  { itemName: 'menu.administrationSelectJobTitles' },
  { itemName: 'menu.administrationSelectBusinessTypes' },
  { itemName: 'menu.administrationImport' },
  { itemName: 'menu.administrationMail' },
  { itemName: 'menu.administrationReports' },
  { itemName: 'menu.administrationStandardUserInfoFields' },
];
