import { UsersSearchParameters } from '@src/api';

export const DEFAULT_USERS_SEARCH_PARAMS: UsersSearchParameters = {
  name: '',
  jobTitleIds: [],
  committeeIds: [],
  organisationIds: [],
  organisationTypeIds: [],
  searchForExactMatchName: false,
  searchForRegistredOnly: undefined,
};
