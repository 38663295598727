<div class="many-tabs-dialog">
  <div class="many-tabs-dialog__title">{{ 'components.manyTabsDialog.labels.title' | translate }}</div>

  <div class="many-tabs-dialog__description">
    {{ 'components.manyTabsDialog.labels.descriptionPart1' | translate }}
    <br />
    {{ 'components.manyTabsDialog.labels.descriptionPart2' | translate }}
  </div>

  <button tuiButton class="many-tabs-dialog__button" (click)="reload()">
    {{ 'components.manyTabsDialog.buttons.reload' | translate }}
  </button>
</div>
