<ng-container *ngIf="isActiveTab; else tmpTooManyTabs">
  <ng-container *ngIf="isLogged !== null || env.isBot; else tmpLoading">
    <ng-container *ngIf="isLogged || env.isBot; else tmpLoginToTelegram">
      <ng-content></ng-content>
    </ng-container>
  </ng-container>
</ng-container>

<ng-template #tmpLoading>
  <div class="position-by-center">
    <tui-loader size="l" [showLoader]="true" [inheritColor]="true" [overlay]="true" style="width: 100%"></tui-loader>
  </div>
</ng-template>

<ng-template #tmpLoginToTelegram>
  <div class="position-by-center">
    <p>{{ infoText }}</p>

    <button tuiButton type="button" appearance="primary" size="m" (click)="showLoginToTelegram(tmpTelegramLogin)">
      {{ 'components.telegramCheckAuth.buttons.connectTelegram' | translate }}
    </button>
  </div>
</ng-template>

<ng-template #tmpTelegramLogin let-observer>
  <div class="telegram-login">
    <telegram-login
      [showLogo]="false"
      [showTermOfThePrivacy]="false"
      [title]="'components.telegramCheckAuth.labels.telegramLogin' | translate"
      (onSuccess)="onSuccess(observer)"
      (onLoading)="loading = $event"
    ></telegram-login>

    <button
      tuiIconButton
      type="button"
      appearance="icon"
      icon="tuiIconCloseLarge"
      size="xs"
      class="telegram-login__close-button"
      (click)="observer.complete()"
      [disabled]="loading"
    ></button>
  </div>
</ng-template>

<ng-template #tmpTooManyTabs>
  <app-many-tabs-dialog class="position-by-center"></app-many-tabs-dialog>
</ng-template>
