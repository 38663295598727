import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppService } from '@src/api';

import { CaptchaComponent } from './components';

@NgModule({
  declarations: [CaptchaComponent],
  imports: [CommonModule],
  providers: [AppService],
  exports: [CaptchaComponent],
})
export class CaptchaModule {}
