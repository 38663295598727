<div class="user">
  <div class="block block_first">
    <div class="user__photo">
      <tui-avatar
        [autoColor]="!chat?.userPhotoPath"
        [rounded]="true"
        size="l"
        [avatarUrl]="chat?.userPhotoPath || '' | safe : 'url'"
        [text]="chat && chat.title ? chat.title : ''"
      ></tui-avatar>
    </div>
    <app-link
      class="user__name"
      [routerLink]="'/association-users/' + userUnions?.id"
      [displayText]="chat?.title"
    ></app-link>
    <div class="user__status">{{ userTelegram?.status | userStatus : true }}</div>
  </div>
  <div *ngIf="userUnions?.organisationJobTitles?.length" class="user__info block block_info">
    <mat-nav-list
      *ngFor="let organisationJobTitle of userUnions?.organisationJobTitles | jobTitles"
      class="info__organisation"
    >
      <mat-list-item [routerLink]="'/organisation/' + organisationJobTitle.organisationId">
        <mat-icon matListIcon class="icon">business</mat-icon>
        <div matLine class="organisation">{{ organisationJobTitle | orgJobTitleDisplayableName }}</div>
      </mat-list-item>
    </mat-nav-list>
  </div>
  <div class="block">
    <mat-nav-list class="info__notifications">
      <mat-list-item (click)="onChangeNotificationsToggleValue()">
        <mat-slide-toggle
          matListIcon
          color="primary"
          [checked]="!!chat && chat.notificationSettings.muteFor === 0"
          [disabled]="true"
        ></mat-slide-toggle>
        <div matLine>{{ 'common.labels.notifications' | translate }}</div>
      </mat-list-item>
    </mat-nav-list>
  </div>
</div>
