import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  Output,
} from '@angular/core';
import { interval, Subject, Subscription, throwError } from 'rxjs';
import { catchError, take, takeUntil } from 'rxjs/operators';
import { TUI_NUMBER_FORMAT } from '@taiga-ui/core';
import { TranslateService } from '@ngx-translate/core';
import { TokenService, TokenModel } from '@src/api';
import { AlertService } from '@src/core/services';
import { CaptchaService } from '@src/app/modules/captcha';
import { EnvService } from '@src/app/modules/env';
import { inputPhoneValidator } from '@src/app/modules/phone';
import { environment } from '@src/environments/environment';

import { SEND_EMAIL_ATTEMPT_COUNTDOWN_FROM } from './constants';
import { LoginStep, ResponseServiceId } from './types';

@Component({
  selector: 'app-login-enter-by-code',
  templateUrl: './enter-by-code.component.html',
  styleUrls: ['./enter-by-code.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: TUI_NUMBER_FORMAT,
      useValue: { thousandSeparator: '' },
    },
  ],
})
export class EnterByCodeComponent implements OnDestroy {
  /** Наименование заголовка */
  @Input() title?: string = 'Добро пожаловать';

  /** id ассоциации, в которую логинимся. если не указать, бэк выберет сам в какую */
  @Input() associationId?: string;

  /** признак брендового объединения */
  @Input() isBrand?: boolean;

  /** Текущий шаг логина */
  step: LoginStep = 'phone';

  /** Флаг выполнения операции */
  loading = false;

  @Output() onLoading: EventEmitter<boolean> = new EventEmitter();

  /** Номер телефона */
  @Input() phoneNumber = '';
  @Output() phoneNumberChange: EventEmitter<string> = new EventEmitter();

  /** Флаг, что телефон не прошел формат */
  phoneInvalid = false;

  /** Код полученный на почту */
  code: string = '';

  /** Флаг, что код не прошел проверку */
  codeInvalid = false;

  /** Флаг, что всплывающая подсказка видна */
  codeHintVisible = false;

  /** Флаг, что обратный отсчет начался */
  isCountdownStarted = false;

  /** Текущее значение обратного отсчета */
  countdownValue = 0;

  /** Почта, которую получаем с ответом на запрос по телефону */
  maskedEmail?: string;

  /** Текст ответа на запрос кода по телефону */
  responseText?: string;

  /** Идентификатор службы отправки сообщения */
  responseServiceId?: ResponseServiceId;

  /** Короткий код аутентификации */
  shortCode?: number;

  /** Применяем полученный токен */
  @Output() onSuccess: EventEmitter<TokenModel> = new EventEmitter();

  private destroyed$$: Subject<void> = new Subject<void>();

  private countdown$?: Subscription;

  constructor(
    private cdr: ChangeDetectorRef,
    private tokenService: TokenService,
    private alertService: AlertService,
    private readonly captchaService: CaptchaService,
    private readonly env: EnvService,
    private readonly translateService: TranslateService,
  ) {
    this.captchaService.captchaValid$?.pipe(takeUntil(this.destroyed$$)).subscribe(res => {
      this.setLoading(false);
      if (res) {
        this.sendPhone();
      } else {
        this.alertService.error(this.translateService.instant('components.enterByCode.alerts.errors.captchaInvalid'));
      }
    });
  }

  get isCordova() {
    return this.env.isCordova;
  }

  ngOnDestroy(): void {
    this.destroyed$$.next();
    this.destroyed$$.complete();

    this.resetCountdown();
  }

  onPhoneNumberChange(phoneNumber: string) {
    this.phoneNumberChange.emit(phoneNumber);
  }

  validateCaptcha() {
    this.phoneInvalid = !inputPhoneValidator(this.phoneNumber);
    if (this.phoneInvalid) return;

    this.setLoading(true);

    if (!this.env.isCordova && environment.production) {
      this.captchaService.initCaptcha();
    } else {
      this.sendPhone();
    }
  }

  sendPhone(): void {
    this.setLoading(true);

    this.tokenService
      .sendVerifyCode(false, true, { phone: this.getNormalizedPhoneNumber(), associationId: this.associationId })
      .pipe(
        catchError(err => {
          this.alertService.error(err);
          return throwError(err);
        }),
        takeUntil(this.destroyed$$),
      )
      .subscribe({
        next: ({ masked, text, serviceId, shortCode }) => {
          this.step = 'code';
          this.responseServiceId = serviceId as ResponseServiceId;
          this.maskedEmail = masked;
          this.responseText = text;
          this.shortCode = shortCode;
          this.countdown();
          this.setLoading(false);
          this.cdr.markForCheck();
        },
        error: () => {
          this.setLoading(false);
          this.cdr.markForCheck();
        },
      });
  }

  sendCode(): void {
    if (!this.code) {
      return;
    }

    this.setLoading(true);

    this.tokenService
      .getTokenByCode({
        phone: this.getNormalizedPhoneNumber(),
        code: this.code,
        associationId: this.associationId,
        shortCode: this.shortCode,
      })
      .pipe(
        catchError(err => {
          this.alertService.error(err);
          return throwError(err);
        }),
        takeUntil(this.destroyed$$),
      )
      .subscribe({
        next: token => {
          this.onSuccess.emit(token);
        },
        error: () => {
          this.codeInvalid = true;
          this.setLoading(false);

          this.cdr.markForCheck();
        },
      });
  }

  onClickEditButton(): void {
    this.code = '';
    this.codeInvalid = false;

    this.resetCountdown();
    this.step = 'phone';
  }

  private setLoading(loading: boolean) {
    this.loading = loading;
    this.onLoading.emit(loading);
  }

  private getNormalizedPhoneNumber() {
    return this.phoneNumber.slice(1);
  }

  private resetCountdown(): void {
    this.countdown$?.unsubscribe();
  }

  /**
   * Таймер обратного отсчета, когда можно будет отправить код повторно
   * */
  private countdown(): void {
    this.resetCountdown();
    this.countdownValue = SEND_EMAIL_ATTEMPT_COUNTDOWN_FROM;
    this.isCountdownStarted = true;

    this.countdown$ = interval(1000)
      .pipe(take(SEND_EMAIL_ATTEMPT_COUNTDOWN_FROM))
      .subscribe({
        next: () => {
          this.countdownValue -= 1;
          this.cdr.markForCheck();
        },
        complete: () => {
          this.isCountdownStarted = false;
          this.cdr.markForCheck();
        },
      });
  }

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent): void {
    if (event.key === 'Enter' && !this.loading) {
      switch (this.step) {
        case 'phone':
          this.validateCaptcha();
          break;

        case 'code':
          this.sendCode();
          break;

        default:
          break;
      }
    }
  }
}
