import { Injectable } from '@angular/core';
import { EnvService } from '@src/app/modules/env';
import { lastValueFrom, Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { OrganisationShortInfoUI } from '@src/app/modules/branding';
import { AppService as ApiAppService } from '@src/api';
import { reload } from '@src/utils';

import { APP_CONFIG } from '../config';

const MOBILE_CONFIGS = APP_CONFIG.mobile;

@Injectable({
  providedIn: 'root',
})
export class AppService {
  constructor(private readonly getService: ApiAppService, private readonly env: EnvService) {}

  async isCurrentVersionUnsupported(): Promise<boolean> {
    const versionLength = 3;
    const supportedVersion = await lastValueFrom(this.getVersion());
    const clientVersion = require('../../../package.json').version;

    const minimalVersion = this.splitVersion(supportedVersion);
    const currentVersion = this.splitVersion(clientVersion);

    if (minimalVersion.length !== versionLength || currentVersion.length !== versionLength) {
      // TODO: localization console перевести
      throw new Error('Версия приложения содержит недопустимое количество символов, обратитесь к Администратору');
    }

    let index: number = 0;
    while (currentVersion[index] === minimalVersion[index] && index < versionLength) index += 1;

    return currentVersion[index] < minimalVersion[index];
  }

  updateApplicationVersion(brandData?: OrganisationShortInfoUI | null) {
    let link: string | undefined;

    if (brandData) {
      if (this.env.isIosWithCordova) {
        link = brandData.appStoreLink;
      }
      if (this.env.isAndroidWithCordova) {
        link = brandData.googlePlayLink;
      }
    } else {
      if (this.env.isIosWithCordova) {
        link = MOBILE_CONFIGS.ios.downloadLink;
      }
      if (this.env.isAndroidWithCordova) {
        link = MOBILE_CONFIGS.android.downloadLink;
      }
    }

    if (link) {
      window.open(link, '_blank');
    } else {
      reload();
    }
  }

  private getVersion(): Observable<string> {
    return this.getService.version().pipe(
      catchError(err => {
        // TODO show error notification
        return throwError(err);
      }),
    );
  }

  private splitVersion(version: string) {
    return version.split('.').map(v => {
      const value = Number(v);

      if (Number.isNaN(value)) {
        // TODO: localization console перевести
        throw new Error('Версия приложения содержит недопустимые символы, обратитесь к Администратору');
      }

      return value;
    });
  }
}
