import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import { MessageModel, MessageGroup } from '@src/models';
import { TranslateService } from '@ngx-translate/core';

import { TimestampToDatePipe } from './timestamp-to-date.pipe';

@Pipe({
  name: 'messagesGrouping',
})
export class MessagesGroupingPipe implements PipeTransform {
  constructor(
    private timestampToDatePipe: TimestampToDatePipe,
    private datePipe: DatePipe,
    private readonly translateService: TranslateService,
  ) {}

  transform(messages?: MessageModel[]): MessageGroup[] {
    if (!messages) return [];

    const messageGroups: MessageGroup[] = [];
    const currentYear = new Date().getFullYear();

    messages.forEach(message => {
      let messageGroupDate: string | null;
      const messageDate = this.timestampToDatePipe.transform(message.date, 'date') as Date;
      if (messageDate.getFullYear() === currentYear) {
        messageGroupDate = this.datePipe.transform(
          messageDate,
          'dd MMMM',
          undefined,
          this.translateService.currentLang,
        );
      } else {
        messageGroupDate = this.datePipe.transform(
          messageDate,
          'dd MMMM y',
          undefined,
          this.translateService.currentLang,
        );
      }

      if (messageGroupDate) {
        const findMessageGroup = messageGroups.find(messageGroup => messageGroup.date === messageGroupDate);
        if (!findMessageGroup) {
          messageGroups.push({
            date: messageGroupDate,
            messages: [message],
          });
        } else {
          findMessageGroup.messages?.push(message);
        }
      }
    });

    return messageGroups;
  }
}
