import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { lastValueFrom, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { UserService } from '@src/core/services';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProfileComponent implements OnInit, OnDestroy {
  userId?: string;
  organisationId?: string;

  private destroyed$$: Subject<void> = new Subject<void>();

  constructor(private cdr: ChangeDetectorRef, private route: ActivatedRoute, private userService: UserService) {}

  ngOnInit(): void {
    if (!this.organisationId) {
      this.route.paramMap.pipe(takeUntil(this.destroyed$$)).subscribe(async params => {
        this.userId = params.get('id') ?? undefined;
        if (!this.userId) {
          this.userService.getAuthorizedUser().then(res => {
            this.userId = res?.id;
            this.organisationId = res?.organisationId;
          });
        } else {
          const users = await lastValueFrom(this.userService.getUsersData([this.userId]));
          if (users.length) {
            this.organisationId = users[0].organisationId;
          }
        }
      });
    }
  }

  ngOnDestroy(): void {
    this.destroyed$$.next();
    this.destroyed$$.complete();
  }
}
