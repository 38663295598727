import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { FormsModule } from '@angular/forms';
import { TuiButtonModule, TuiTextfieldControllerModule } from '@taiga-ui/core';
import { TuiInputModule, TuiTextareaModule } from '@taiga-ui/kit';
import { ListModule } from '@src/app/shared/list';
import { SearchChatsModule } from '@src/app/shared/search-chats';
import { TranslateModule } from '@ngx-translate/core';

import { ForwardComponent } from './forward.component';

@NgModule({
  declarations: [ForwardComponent],
  imports: [
    CommonModule,
    FormsModule,
    ListModule,
    MatButtonModule,
    MatIconModule,
    SearchChatsModule,
    TuiButtonModule,
    TuiInputModule,
    TuiTextareaModule,
    TuiTextfieldControllerModule,
    TranslateModule,
  ],
  exports: [ForwardComponent],
})
export class ForwardModule {}
