<div class="organisation-committees">
  <div class="organisation-committees__content content">
    <div class="content__header">
      <h1 class="content__title">{{ 'menu.committees' | customNames }}</h1>
    </div>
    <div class="content__list">
      <app-list
        listType="committees"
        [items]="committees$ | async"
        [loading]="loading"
        (selectedIdChange)="onSelectedCommitteeIdChange($event)"
      ></app-list>
    </div>
  </div>
</div>
