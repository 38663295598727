/**
 * Unions API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ReplyDto { 
    /**
     * ID опроса.
     */
    pollId?: string;
    /**
     * ID вопроса.
     */
    questionId: string;
    /**
     * ID выбраного ответа.
     */
    answerId?: string;
    /**
     * Текст альтернативного ответа.
     */
    answerText?: string;
    /**
     * Описание файла.
     */
    text?: string;
    /**
     * Файл.
     */
    file?: string;
    /**
     * Имя файла.
     */
    fileName?: string;
    /**
     * ID файла ответа (должен быть сохранен заранее под пользователем, проходящим опрос).
     */
    fileId?: string;
}

