import { DynamicPermissionsType, RoleCode } from './types';

/**
 * Кол-во секунд для автоматической перезагрузки страницы,
 * если при выходе из приложения что-то пошло не так
 */
export const AUTO_RELOAD_PAGE_TIMER = 5000;

export const ROLE_PERMISSIONS_DEFAULT: Record<RoleCode, readonly string[]> = {
  SysOp: [
    // ----------PAGE----------
    'pageAdministrationViewing',
    'pageAssociationViewing',
    'pageAssociationOrganisationsViewing',
    'pageAssociationUsersViewing',
    'pageNewsFeedViewing',
    'pageChatsViewing',
    'pageCommitteesViewing',
    'pageSubCommitteesViewing',
    'pageEventsViewing',
    'pageInfoViewing',
    'pageOrganisationViewing',
    'pagePollsViewing',
    'pageNewsletterViewing',
    'pageProfileViewing',
    'pageSettingsViewing',
    'pageSettingsFoldersViewing',
    'pageSettingsPreferencesViewing',
    'pageSettingsNotificationsViewing',
    'pageLoyaltyViewing',
    'pageBirthdaysViewing',
    'pageCooperationChainsViewing',

    // ----------ASSOCIATION----------
    'associationCreating',
    'associationEditing',
    'associationChannelSpecialFieldsViewing',
    'associationChannelAutoSubscribing',

    // ----------CHATS----------
    'chatsCreating',
    'chatInviteMembersViewing',

    // ----------COMMITTEES----------
    'committeeCreating',
    'committeeDeleting',
    'committeeEditing',
    'committeeSpecialFieldsForAssociationEditing',

    'subCommitteeCreating',
    'subCommitteeDeleting',
    'subCommitteeEditing',
    'subCommitteeSpecialFieldsForAssociationViewing',
    'subCommitteeSpecialFieldsForAssociationEditing',

    'committeeChatsCreating',
    'committeeChatsEditing',

    'committeeMembersViewing',
    'committeeMembersAdding',
    'committeeMembersDeleting',
    'committeeConfigsViewing',

    // ----------EVENTS----------
    'eventCreating',
    'eventEditing',
    'eventReportViewing',
    'eventMembersViewing',
    'eventsAllViewing',

    'eventChatCreating',

    // ----------IMPORT----------
    'importViewing',

    // ----------ORGANISATIONS---------
    'organisationCreating',
    'organisationDeleting',
    'organisationEditing',
    'organisationSpecialFieldsViewing',
    'organisationSpecialFieldsEditing',
    'organisationSpecialFieldsForAssociationViewing',
    'organisationSpecialFieldsForAssociationEditing',
    'organisationConfigsViewing',

    'organisationChannelCreating',
    'organisationChannelEditing',
    'organisationChannelSubscribing',

    'organisationEmployeeViewing',
    'organisationEmployeeCreating',
    'organisationEmployeeEditing',
    'organisationEmployeeDeleting',
    'organisationEmployeePhoneFieldViewing',
    'organisationEmployeeEmailFieldViewing',
    'organisationEmployeeRoleFieldViewing',
    'organisationEmployeeSpecialFieldsViewing',
    'organisationEmployeeSpecialFieldsEditing',
    'organisationEmployeeSpecialFieldsForAssociationViewing',
    'organisationEmployeeSpecialFieldsForAssociationEditing',
    'organisationEmployeeJobTitleFieldsEditing',
    'organisationEmployeeJobTitlesForAdminOnlyEditing',

    // ----------POLLS----------
    'pollCreating',
    'pollEditing',
    'pollMembersViewing',
    'pollStatisticsViewing',
    'pollsAllViewing',

    // ----------NEWSLETTERS----------
    'newsletterCreating',
    'newsletterEditing',

    // ----------PREFERENCES----------
    'preferencesEditing',

    // ----------LOYALTY PROGRAM----------
    'loyaltyProgramCreating',
    'loyaltyProgramEditing',
    'loyaltyProgramDeleting',
    'loyaltyProgramPublishing',
    'loyaltyProgramUnpublishing',
    'loyaltyProgramOwnProductsViewing',
    'loyaltyProgramAllStatisticsViewing',
    'loyaltyProgramUnionListViewing',

    // ----------GROUPS----------
    'synchronizeWithTelegram',

    // ----------CONTACTS----------
    'contactsTypeForAdminViewing',
    'contactsCreating',
    'contactsEditing',
    'contactsDeleting',
    'contactsRequireValueEditing',

    // ----------USERS LIST----------
    'usersListSortByEdited',

    // ----------USERS----------
    'userChatButtonViewing',
  ],
  AdminUO: [
    // ----------PAGE----------
    'pageAdministrationViewing',
    'pageAssociationViewing',
    'pageAssociationOrganisationsViewing',
    'pageAssociationUsersViewing',
    'pageNewsFeedViewing',
    'pageChatsViewing',
    'pageCommitteesViewing',
    'pageSubCommitteesViewing',
    'pageEventsViewing',
    'pageInfoViewing',
    'pageOrganisationViewing',
    'pagePollsViewing',
    'pageNewsletterViewing',
    'pageProfileViewing',
    'pageSettingsViewing',
    'pageSettingsFoldersViewing',
    'pageSettingsPreferencesViewing',
    'pageSettingsNotificationsViewing',
    'pageLoyaltyViewing',
    'pageBirthdaysViewing',
    'pageCooperationChainsViewing',

    // ----------ASSOCIATION----------
    'associationEditing',
    'associationChannelSpecialFieldsViewing',
    'associationChannelAutoSubscribing',

    // ----------CHATS----------
    'chatsCreating',
    'chatInviteMembersViewing',

    // ----------COMMITTEES----------
    'committeeCreating',
    'committeeDeleting',
    'committeeEditing',
    'committeeSpecialFieldsForAssociationEditing',

    'subCommitteeCreating',
    'subCommitteeDeleting',
    'subCommitteeEditing',
    'subCommitteeSpecialFieldsForAssociationViewing',
    'subCommitteeSpecialFieldsForAssociationEditing',

    'committeeChatsCreating',
    'committeeChatsEditing',

    'committeeMembersViewing',
    'committeeMembersAdding',
    'committeeMembersDeleting',
    'committeeConfigsViewing',

    // ----------EVENTS----------
    'eventCreating',
    'eventEditing',
    'eventReportViewing',
    'eventMembersViewing',
    'eventsAllViewing',

    'eventChatCreating',

    // ----------IMPORT----------
    'importViewing',

    // ----------ORGANISATIONS----------
    'organisationCreating',
    'organisationDeleting',
    'organisationEditing',
    'organisationSpecialFieldsViewing',
    'organisationSpecialFieldsEditing',
    'organisationSpecialFieldsForAssociationViewing',
    'organisationSpecialFieldsForAssociationEditing',
    'organisationConfigsViewing',

    'organisationChannelCreating',
    'organisationChannelEditing',
    'organisationChannelSubscribing',

    'organisationEmployeeViewing',
    'organisationEmployeeCreating',
    'organisationEmployeeEditing',
    'organisationEmployeeDeleting',
    'organisationEmployeePhoneFieldViewing',
    'organisationEmployeeEmailFieldViewing',
    'organisationEmployeeRoleFieldViewing',
    'organisationEmployeeSpecialFieldsViewing',
    'organisationEmployeeSpecialFieldsEditing',
    'organisationEmployeeSpecialFieldsForAssociationViewing',
    'organisationEmployeeSpecialFieldsForAssociationEditing',
    'organisationEmployeeJobTitleFieldsEditing',
    'organisationEmployeeJobTitlesForAdminOnlyEditing',

    // ----------POLLS----------
    'pollCreating',
    'pollEditing',
    'pollMembersViewing',
    'pollStatisticsViewing',
    'pollsAllViewing',

    // ----------NEWSLETTERS----------
    'newsletterCreating',
    'newsletterEditing',

    // ----------PREFERENCES----------
    'preferencesEditing',

    // ----------LOYALTY PROGRAM----------
    'loyaltyProgramCreating',
    'loyaltyProgramEditing',
    'loyaltyProgramDeleting',
    'loyaltyProgramPublishing',
    'loyaltyProgramUnpublishing',
    'loyaltyProgramOwnProductsViewing',
    'loyaltyProgramAllStatisticsViewing',
    'loyaltyProgramUnionListViewing',

    // ----------GROUPS----------
    'synchronizeWithTelegram',

    // ----------CONTACTS----------
    'contactsTypeForAdminViewing',
    'contactsCreating',
    'contactsEditing',
    'contactsDeleting',
    'contactsRequireValueEditing',

    // ----------USERS LIST----------
    'usersListSortByEdited',

    // ----------USERS----------
    'userChatButtonViewing',
  ],
  AdminDO: [
    // ----------PAGE----------
    'pageAssociationViewing',
    'pageAssociationOrganisationsViewing',
    'pageAssociationUsersViewing',
    'pageNewsFeedViewing',
    'pageChatsViewing',
    'pageCommitteesViewing',
    'pageSubCommitteesViewing',
    'pageEventsViewing',
    'pageInfoViewing',
    'pageOrganisationViewing',
    'pagePollsViewing',
    'pageNewsletterViewing',
    'pageProfileViewing',
    'pageSettingsViewing',
    'pageSettingsFoldersViewing',
    'pageSettingsNotificationsViewing',
    'pageLoyaltyViewing',
    'pageBirthdaysViewing',
    'pageCooperationChainsViewing',

    'associationChannelAutoSubscribing',

    // ----------COMMITTEE----------
    'committeeMembersViewing',

    // ----------EVENTS----------
    // TODO: временное решение
    'eventCreating',
    'eventHideFieldsEditing',
    'eventMembersViewing',
    'onlyYourEventEditing',

    'eventChatCreating',

    // ----------ORGANISATIONS----------
    'onlyYourOrganisationEditing',
    'onlyYourOrganisationSpecialFieldsViewing',
    'onlyYourOrganisationSpecialFieldsEditing',
    'onlyYourOrganisationSpecialFieldsForAssociationViewing',

    'onlyYourOrganisationChannelCreating',
    'onlyYourOrganisationChannelEditing',
    'organisationChannelSubscribing',

    'organisationEmployeeViewing',
    'onlyYourOrganisationEmployeeEditing',
    'onlyYourOrganisationEmployeeDeleting',
    'onlyYourOrganisationEmployeeSpecialFieldsViewing',
    'onlyYourOrganisationEmployeeSpecialFieldsEditing',
    'onlyYourOrganisationEmployeeJobTitleFieldsEditing',

    // ----------LOYALTY PROGRAM----------
    'loyaltyProgramCreating',
    'loyaltyProgramOwnProductsViewing',
    'loyaltyProgramUnionListViewing',

    'loyaltyProgramOnlyYourProductAllStatisticsViewing',

    // ----------CONTACTS----------
    'onlyYourOrganisationContactsCreating',
    'onlyYourOrganisationContactsEditing',
    'onlyYourOrganisationContactsDeleting',

    'onlyYourOrganisationEmployeeContactsCreating',
    'onlyYourOrganisationEmployeeContactsEditing',
    'onlyYourOrganisationEmployeeContactsDeleting',

    // ----------USERS LIST----------
    'usersListSortByEdited',

    // ----------USERS----------
    'onlyYourJobTitleFieldsEditing',

    'userChatButtonViewing',
  ],
  ParticipantUO: [
    // ----------PAGE----------
    'pageAssociationViewing',
    'pageAssociationOrganisationsViewing',
    'pageAssociationUsersViewing',
    'pageNewsFeedViewing',
    'pageChatsViewing',
    'pageCommitteesViewing',
    'pageSubCommitteesViewing',
    'pageEventsViewing',
    'pageInfoViewing',
    'pageOrganisationViewing',
    'pagePollsViewing',
    'pageNewsletterViewing',
    'pageProfileViewing',
    'pageSettingsViewing',
    'pageSettingsFoldersViewing',
    'pageSettingsNotificationsViewing',
    'pageLoyaltyViewing',
    'pageBirthdaysViewing',

    // ----------SETTINGS----------
    'associationChannelAutoSubscribing',

    // ----------COMMITTEE----------
    'committeeMembersViewing',

    // ----------EVENT----------
    'eventCreating',
    'eventMembersViewing',
    'onlyYourEventEditing',

    'eventChatCreating',

    // ----------ORGANISATION----------
    'organisationChannelSubscribing',

    'organisationEmployeeViewing',

    // ----------POLL----------
    'pollCreating',
    'onlyYourPollEditing',
    'onlyYourPollStatisticsViewing',

    // ----------USERS----------
    'onlyYourJobTitleFieldsEditing',

    'userChatButtonViewing',
  ],
  ParticipantDO: [
    // ----------PAGE----------
    'pageAssociationViewing',
    'pageAssociationOrganisationsViewing',
    'pageAssociationUsersViewing',
    'pageNewsFeedViewing',
    'pageChatsViewing',
    'pageCommitteesViewing',
    'pageSubCommitteesViewing',
    'pageEventsViewing',
    'pageInfoViewing',
    'pageOrganisationViewing',
    'pagePollsViewing',
    'pageNewsletterViewing',
    'pageProfileViewing',
    'pageSettingsViewing',
    'pageSettingsFoldersViewing',
    'pageSettingsNotificationsViewing',
    'pageLoyaltyViewing',
    'pageBirthdaysViewing',

    // ----------ASSOCIATION----------
    'associationChannelAutoSubscribing',

    // ----------COMMITTEE----------
    'committeeMembersViewing',

    // ----------EVENTS----------
    'eventMembersViewing',

    // ----------ORGANISATIONS----------
    'onlyYourOrganisationSpecialFieldsViewing',

    'organisationChannelSubscribing',

    'organisationEmployeeViewing',

    // ----------USERS----------
    'onlyYourJobTitleFieldsEditing',

    'userChatButtonViewing',
  ],
  Spectator: [
    // ----------PAGE----------
    'pageAssociationViewing',
    'pageAssociationOrganisationsViewing',
    'pageNewsFeedViewing',
    'pageChatsViewing',
    'pageCommitteesViewing',
    'pageSubCommitteesViewing',
    'pageEventsViewing',
    'pageInfoViewing',
    'pageOrganisationViewing',
    'pagePollsViewing',
    'pageNewsletterViewing',
    'pageProfileViewing',
    'pageSettingsViewing',
    'pageSettingsFoldersViewing',
    'pageSettingsNotificationsViewing',
    'pageLoyaltyViewing',

    // ----------ORGANISATIONS----------
    'onlyYourOrganisationEmployeeViewing',

    // ----------USERS----------
    'onlyYourJobTitleFieldsEditing',

    'userChatButtonViewing',
  ],
} as const;

export const DYNAMIC_PERMISSIONS: DynamicPermissionsType = {
  showAllUsersPhonesAndEmails: ['organisationEmployeePhoneFieldViewing', 'organisationEmployeeEmailFieldViewing'],
  disableMessengerLoginPermissions: [
    'committeeChatsCreating',

    'organisationChannelCreating',
    'onlyYourOrganisationChannelCreating',

    'eventChatCreating',

    'chatsCreating',

    'userChatButtonViewing',

    'synchronizeWithTelegram',
  ],
  showOnlyLoyaltyProgram: [
    'pageSettingsViewing',
    'pageSettingsNotificationsViewing',
    'pageLoyaltyViewing',

    'loyaltyProgramCreating',
    'loyaltyProgramEditing',
    'loyaltyProgramDeleting',
    'loyaltyProgramPublishing',
    'loyaltyProgramUnpublishing',
    'loyaltyProgramOwnProductsViewing',
    'loyaltyProgramAllStatisticsViewing',
    'loyaltyProgramUnionListViewing',
  ],
};
