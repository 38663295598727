import { Injectable } from '@angular/core';
import { Observable, catchError, map, of } from 'rxjs';
import { ReportsService as ApiReportsService, GetReportForUnionResultDto } from '@src/api';
import { AlertService } from '@src/core/services';

import { EntityTypeCode, GetReportForUnionResultDtoUI, ReportType } from '../types';

@Injectable({
  providedIn: 'root',
})
export class ReportsService {
  constructor(private readonly reportService: ApiReportsService, private readonly alertService: AlertService) {}

  /**
   * Получить список отчётов
   * @param {ReportType[]} reportType {@link ReportType} - Тип отчёта
   */
  getReports(reportType: ReportType): Observable<Array<GetReportForUnionResultDtoUI>> {
    let observable: Observable<Array<GetReportForUnionResultDto>>;

    switch (reportType) {
      case 'association':
        observable = this.reportService.getReportsForUnion(EntityTypeCode.Association);
        break;

      case 'committees':
        observable = this.reportService.getReportsForUnion(EntityTypeCode.Committees);
        break;

      case 'events':
        observable = this.reportService.getReportsForUnion(EntityTypeCode.Events);
        break;

      case 'newsletters':
        observable = this.reportService.getReportsForUnion(EntityTypeCode.Newsletters);
        break;

      case 'organisations':
        observable = this.reportService.getReportsForUnion(EntityTypeCode.Organisations);
        break;

      case 'polls':
        observable = this.reportService.getReportsForUnion(EntityTypeCode.Polls);
        break;

      case 'users':
        observable = this.reportService.getReportsForUnion(EntityTypeCode.Users);
        break;

      default:
        observable = of([]);
        break;
    }

    return observable.pipe(
      catchError(err => {
        this.alertService.error(err);
        throw new Error('Возникла ошибка при получении отчётов');
      }),
      map(reports => reports.map(report => this.normalizeData(report))),
    );
  }

  private normalizeData(report: GetReportForUnionResultDto): GetReportForUnionResultDtoUI {
    const newReport: GetReportForUnionResultDtoUI = report;
    if (newReport.reportInputData) {
      try {
        newReport.reportData = JSON.parse(newReport.reportInputData)?.reportData;
      } catch (error) {
        throw new Error(`Сервер прислал неверный JSON в поле reportInputData для отчёта id = ${newReport.id}`);
      }
    }

    return newReport;
  }

  /**
   * Запуск формирования отчёта
   * @param {string} reportId - id отчёта
   * @param {Object} reportData - дополнительные параметры
   */
  buildReport(reportId: string, reportData?: Object) {
    return this.reportService.buildReport(reportId, reportData).pipe(
      catchError(err => {
        this.alertService.error(err);
        throw new Error('Возникла ошибка при запуске отчёта');
      }),
    );
  }

  /**
   * Получить результат отчёта
   * @param {string} reportResultId - id результата отчёта
   */
  getReportResult(reportResultId: string, sendToBot?: boolean) {
    return this.reportService.getReportResult(reportResultId, sendToBot).pipe(
      catchError(err => {
        this.alertService.error(err);
        throw new Error('Возникла ошибка при получении результата отчёта');
      }),
    );
  }
}
