import { environment } from 'src/environments/environment';

export const enum Language {
  RU = 'ru',
  EN = 'en',
}

export const APP_CONFIG = {
  baseUrl: !!environment.baseUrl
    ? environment.baseUrl
    : `${window.location.protocol.replace('app:', 'https:')}//${window.location.host}`, // TODO: hack для мобильных приложений и копирования внутренних ссылок в приложении
  localizations: [Language.RU, Language.EN],
  defaultLocale: Language.RU,
  mobile: {
    ios: {
      downloadLink: 'https://apps.apple.com/ru/app/unions/id1633619446',
    },
    android: {
      downloadLink: 'https://play.google.com/store/apps/details?id=me.unions.app',
    },
  },
  loadingLimit: {
    chats: 1000,
    messages: 30,
    chatMembers: 200,
    organisations: 50,
    users: 50,
  },
  dictionaries: {
    externalTypeIdIcon: 'tuiIconFolderLarge',
    noNameGroup: '( Без названия )',
  },
  fileSizeMax: {
    image: 50 * 1000 * 1000, // 50 Мб
    doc: 50 * 1000 * 1000, // 50 Мб
  },
  fileTypes: {
    audio: ['.m4a', '.mp3', '.wav'],
    video: ['.avi', '.avchd', '.flv', '.mov', '.mp4'],
    image: ['.gif', '.jpg', '.jpeg', '.png', '.tiff', '.tif', '.svg'],
    textDocument: ['.doc', '.docx', '.htm', '.html', '.pdf', '.txt', '.xls', '.xlsx'],
    excelDocument: ['.xls', '.xlsx'],
  },
  bots: {
    botFather: {
      id: 93372553,
      username: 'BotFather',
    },
    main: environment.bots.main,
  },
  folders: {
    unions: {
      title: 'Unions',
      iconName: 'Work',
    },
    notUnions: {
      title: 'Без unions',
      iconName: 'Custom',
    },
  },
  searchMinLength: 2,
};
