import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  TemplateRef,
} from '@angular/core';
import { Nullable, Optional } from '@src/types/utils';

import { ListMode, ListType } from './types';

@Component({
  selector: 'app-new-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ListComponent implements OnChanges {
  @Input() mode: ListMode = 'single';
  @Input() items?: Nullable<Array<ListType>>;
  @Input() itemContentTmp?: TemplateRef<ListType>;
  @Input() selectedId?: string;
  @Output() selectedIdChange = new EventEmitter<Optional<string>>();

  loading = true;

  constructor(private readonly cdr: ChangeDetectorRef) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['items']) {
      this.loading = this.items === null;
    }
    this.cdr.markForCheck();
  }

  onToggleItem(item: ListType) {
    this.selectedId = item.id;
    this.selectedIdChange.emit(this.selectedId);
  }
}
