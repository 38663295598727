import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { FilePartUnion, FormattedTextUnion, MessageContentUnion } from '@airgram/web';
import { TelegramMessengerService } from '@src/app/modules/telegram';
import { TranslateService } from '@ngx-translate/core';

import { FileSizePipe } from './file-size.pipe';

@Pipe({
  name: 'message',
})
export class MessagePipe implements PipeTransform {
  constructor(
    private messengerService: TelegramMessengerService,
    private domSanitizer: DomSanitizer,
    private fileSizePipe: FileSizePipe,
    private readonly translateService: TranslateService,
  ) {}

  async transform(message: MessageContentUnion | undefined, returnType: 'text' | 'html'): Promise<SafeHtml> {
    let normalizeMessage = '';
    let mimeType: string;
    let filePart: FilePartUnion;
    let arrBytes: string;
    let fileUrl: string;
    let thumbnailUrl: string = '';
    if (!message) return normalizeMessage;

    switch (message._) {
      case 'messageText':
        if (returnType === 'text') normalizeMessage = message.text.text;
        else normalizeMessage = this.formattedTextToHtml(message.text);
        break;

      case 'messageAudio':
        if (returnType === 'text') {
          normalizeMessage =
            this.translateService.instant('components.telegramMessageNote.labels.messageAudio') +
            (message.caption.text ? ', ' + message.caption.text : '');
          break;
        }

        const audio = message.audio.audio;
        mimeType = message.audio.mimeType;

        if (!audio.local.path) {
          await this.messengerService.api.downloadFile(audio.id);
        }

        filePart = await this.messengerService.api.readFilePart(audio.id);
        arrBytes = filePart.data;
        fileUrl = window.URL.createObjectURL(new Blob([arrBytes]));

        normalizeMessage = `<audio tuiMedia controls><source src="${fileUrl}" type="${mimeType}"> </audio>`;
        normalizeMessage += message.caption.text ? `<div>${this.formattedTextToHtml(message.caption)}</div>` : '';
        break;

      case 'messageVoiceNote':
        if (returnType === 'text') {
          normalizeMessage =
            this.translateService.instant('components.telegramMessageNote.labels.messageVoiceNote') +
            (message.caption.text ? ', ' + message.caption.text : '');
          break;
        }

        const voiceNote = message.voiceNote.voice;
        mimeType = message.voiceNote.mimeType;

        if (!voiceNote.local.path) {
          await this.messengerService.api.downloadFile(voiceNote.id);
        }

        filePart = await this.messengerService.api.readFilePart(voiceNote.id);
        arrBytes = filePart.data;
        fileUrl = window.URL.createObjectURL(new Blob([arrBytes]));

        normalizeMessage = `<audio tuiMedia controls><source src="${fileUrl}" type="${mimeType}"> </audio>`;
        normalizeMessage += message.caption.text ? `<div>${this.formattedTextToHtml(message.caption)}</div>` : '';
        break;

      case 'messageVideo':
        if (returnType === 'text') {
          normalizeMessage =
            this.translateService.instant('components.telegramMessageNote.labels.messageVideo') +
            (message.caption.text ? ', ' + message.caption.text : '');
          break;
        }

        const video = message.video.video;
        mimeType = message.video.mimeType;

        if (!video.local.path) {
          await this.messengerService.api.downloadFile(video.id);
        }

        filePart = await this.messengerService.api.readFilePart(video.id);
        arrBytes = filePart.data;
        fileUrl = window.URL.createObjectURL(new Blob([arrBytes]));

        normalizeMessage = `<video tuiMedia controls width="410"><source src="${fileUrl}" type="${mimeType}"> </video>`;
        normalizeMessage += message.caption.text ? `<div>${this.formattedTextToHtml(message.caption)}</div>` : '';
        break;

      case 'messageVideoNote':
        if (returnType === 'text') {
          normalizeMessage = this.translateService.instant('components.telegramMessageNote.labels.messageVideoNote');
          break;
        }

        const videoNote = message.videoNote.video;

        if (!videoNote.local.path) {
          await this.messengerService.api.downloadFile(videoNote.id);
        }

        filePart = await this.messengerService.api.readFilePart(videoNote.id);
        arrBytes = filePart.data;
        fileUrl = window.URL.createObjectURL(new Blob([arrBytes]));

        normalizeMessage = `<video tuiMedia controls width="200" height="200" autoplay="" loop="" playsinline=""><source src="${fileUrl}" type="video/mp4"> </video>`;
        break;

      case 'messageAnimation':
        if (returnType === 'text') {
          normalizeMessage = 'GIF';
          break;
        }

        const animation = message.animation.animation;
        mimeType = message.animation.mimeType;

        if (!animation.local.path) {
          await this.messengerService.api.downloadFile(animation.id);
        }

        filePart = await this.messengerService.api.readFilePart(animation.id);
        arrBytes = filePart.data;
        fileUrl = window.URL.createObjectURL(new Blob([arrBytes]));

        normalizeMessage = `<video tuiMedia width="${
          message.animation.width < 220 ? message.animation.width : 220
        }" autoplay="" loop="" playsinline=""><source src="${fileUrl}" type="${mimeType}"> </video>`;
        normalizeMessage += message.caption.text ? `<div>${this.formattedTextToHtml(message.caption)}</div>` : '';
        break;

      case 'messagePhoto':
        if (returnType === 'text') {
          normalizeMessage =
            this.translateService.instant('components.telegramMessageNote.labels.messagePhoto') +
            (message.caption.text ? ', ' + message.caption.text : '');
          break;
        }

        const photo = message.photo.sizes[0].photo;

        if (!photo.local.path) {
          await this.messengerService.api.downloadFile(photo.id);
        }

        filePart = await this.messengerService.api.readFilePart(photo.id);
        arrBytes = filePart.data;
        fileUrl = window.URL.createObjectURL(new Blob([arrBytes]));

        normalizeMessage = `<img src="${fileUrl}" width="220">`;
        normalizeMessage += message.caption.text ? `<div>${this.formattedTextToHtml(message.caption)}</div>` : '';
        break;

      case 'messageSticker':
        if (returnType === 'text') {
          normalizeMessage =
            this.translateService.instant('components.telegramMessageNote.labels.messageSticker') +
            (message.sticker.emoji ? ' ' + message.sticker.emoji : '');
          break;
        }

        const sticker = message.sticker.sticker;

        if (!sticker.local.path) {
          await this.messengerService.api.downloadFile(sticker.id);
        }

        filePart = await this.messengerService.api.readFilePart(sticker.id);
        arrBytes = filePart.data;
        fileUrl = window.URL.createObjectURL(new Blob([arrBytes]));

        if (!message.sticker.isAnimated) {
          normalizeMessage = `<img src="${fileUrl}" width="${
            message.sticker.width < 220 ? message.sticker.width : 220
          }" height="auto">`;
        } else {
          normalizeMessage = '[isAnimatedMessageSticker]';
          // TODO:
          // normalizeMessage = `<canvas id="canvasSticker" width="208" height="208" style="width: 208px; height: 208px;"></canvas>`;

          // const base64 = window.btoa(fileUrl);
          // const canvas = document.getElementById('canvasSticker');
          // const canvas = document.querySelector('canvas');
          // const $ = canvas?.getContext('2d');
          // const img = new Image();
          // img.onload = () => $?.drawImage(img, 0, 0);
          // img.src = 'data:image/webp;base64,' + base64;
          // img.src = fileUrl;

          // normalizeMessage = `
          //   <picture>
          //     <source type="image/webp" srcset="${fileUrl}">
          //     <img src="${img.src}" width="${message.sticker.width < 220 ? message.sticker.width : 220}" height="auto" alt="isAnimatedMessageSticker">
          //   </picture>
          // `;
        }
        break;

      case 'messageDocument':
        if (returnType === 'text') {
          normalizeMessage =
            this.translateService.instant('components.telegramMessageNote.labels.messageDocument') +
            (message.caption.text ? ', ' + message.caption.text : '');
          break;
        }

        const documentFile = message.document.document;
        mimeType = message.document.mimeType;

        if (!documentFile.local.path) {
          await this.messengerService.api.downloadFile(documentFile.id);
        }

        filePart = await this.messengerService.api.readFilePart(documentFile.id);
        arrBytes = filePart.data;
        fileUrl = window.URL.createObjectURL(new Blob([arrBytes]));

        const thumbnail = message.document.thumbnail?.file;
        if (thumbnail) {
          if (!thumbnail.local.path) {
            await this.messengerService.api.downloadFile(thumbnail.id);
          }
          filePart = await this.messengerService.api.readFilePart(thumbnail.id);
          arrBytes = filePart.data;
          thumbnailUrl = window.URL.createObjectURL(new Blob([arrBytes]));
        }

        const fileName = message.document.fileName;
        const fileSize = this.fileSizePipe.transform(documentFile.size);

        normalizeMessage = `<div style="
                              display: grid;
                              grid-template-columns: min-content auto;
                              gap: 10px;
                              align-items: center;
                            ">`;
        normalizeMessage += ' <div>';
        normalizeMessage += `  <a href="${fileUrl}" download="${fileName}" title="${this.translateService.instant(
          'common.buttons.download',
        )}">`;
        normalizeMessage += `   <img src="${
          thumbnailUrl ? thumbnailUrl : 'assets/images/icon-doc.svg'
        }" width="54" height="54">`;
        normalizeMessage += '  </a>';
        normalizeMessage += ' </div>';
        normalizeMessage += ' <div style="overflow: hidden;">';
        normalizeMessage += `  <div style="font-weight: 500;">${fileName}</div>`;
        normalizeMessage += `  <div style="color: var(--unions-color-support-22);">${fileSize}</div>`;
        normalizeMessage += ' </div>';
        normalizeMessage += '</div>';
        normalizeMessage += message.caption.text ? `<div>${this.formattedTextToHtml(message.caption)}</div>` : '';
        break;

      case 'messagePinMessage':
        normalizeMessage =
          this.translateService.instant('components.telegramMessageNote.labels.messagePin') + message.messageId;
        break;

      case 'messageContactRegistered':
        normalizeMessage = this.translateService.instant(
          'components.telegramMessageNote.labels.messageContactRegistered',
        );
        break;

      case 'messageChatAddMembers':
        // TODO:
        normalizeMessage = '';
        await Promise.all(
          message.memberUserIds.map(async memberUserId => {
            const userName = await this.messengerService.getUserFullName(memberUserId);
            normalizeMessage += `[${userName} ${this.translateService.instant(
              'components.telegramMessageNote.labels.messageChatAddMembers',
            )}]`;
          }),
        );
        break;

      case 'messageCall':
        switch (message.discardReason._) {
          case 'callDiscardReasonHungUp':
            normalizeMessage = '<div style="font-weight: 500;">';
            normalizeMessage += message.isVideo
              ? this.translateService.instant('components.telegramMessageNote.labels.videoCallDiscardReasonHungUp')
              : this.translateService.instant('components.telegramMessageNote.labels.voiceCallDiscardReasonHungUp');
            normalizeMessage += '</div>';
            normalizeMessage += `<div>${message.duration} ${this.translateService.instant(
              'common.labels.secondsShort',
            )}</div>`;
            break;

          case 'callDiscardReasonMissed':
            normalizeMessage = '<div style="font-weight: 500;">';
            normalizeMessage += message.isVideo
              ? this.translateService.instant('components.telegramMessageNote.labels.videoCallDiscardReasonMissed')
              : this.translateService.instant('components.telegramMessageNote.labels.voiceCallDiscardReasonMissed');
            normalizeMessage += '</div>';
            break;

          case 'callDiscardReasonDeclined':
            normalizeMessage = '<div style="font-weight: 500;">';
            normalizeMessage += message.isVideo
              ? this.translateService.instant('components.telegramMessageNote.labels.videoCallDiscardReasonDeclined')
              : this.translateService.instant('components.telegramMessageNote.labels.voiceCallDiscardReasonDeclined');
            normalizeMessage += '</div>';
            break;

          case 'callDiscardReasonDisconnected':
            normalizeMessage = '<div style="font-weight: 500;">';
            normalizeMessage += message.isVideo
              ? this.translateService.instant(
                  'components.telegramMessageNote.labels.videoCallDiscardReasonDisconnected',
                )
              : this.translateService.instant(
                  'components.telegramMessageNote.labels.voiceCallDiscardReasonDisconnected',
                );
            normalizeMessage += '</div>';
            break;

          case 'callDiscardReasonEmpty':
            // TODO:
            break;

          default:
            break;
        }

        break;

      default:
        normalizeMessage = `[${message._}]`;
        break;
    }

    return returnType === 'text' ? normalizeMessage : this.domSanitizer.bypassSecurityTrustHtml(normalizeMessage);
  }

  private formattedTextToHtml(formattedText: FormattedTextUnion) {
    const text = formattedText.text;
    let textHtml = '';
    let start = 0;
    let offset = 0;
    let length = 0;
    let end = 0;

    formattedText.entities.forEach(entitie => {
      offset = entitie.offset;
      length = entitie.length;
      end = offset + length;
      let substrHtml = '';
      const substr = text.slice(offset, end);

      switch (entitie.type._) {
        case 'textEntityTypeBotCommand':
          substrHtml = `<a style="color: rgb(79,174,78); text-decoration: none; word-break: break-word; cursor: pointer; unicode-bidi: plaintext;">${substr}</a>`;
          break;
      }

      textHtml += text.slice(start, offset) + substrHtml;

      start = end;
    });

    textHtml += text.slice(start, text.length);

    textHtml = this.replaceEnterToBr(textHtml);

    return textHtml;
  }

  private replaceEnterToBr(str: string): string {
    return str.replace(/\n/g, '<br>');
  }
}
