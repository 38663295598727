import { formatDate } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { GetActualRegistrationFormsForAssociationResponseDto } from '@src/api';
import { Language } from '@src/core';

@Component({
  selector: 'new-list-item-moderation-user',
  templateUrl: './list-item-moderation-user.component.html',
  styleUrls: ['./list-item-moderation-user.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ListItemModerationUserComponent implements OnChanges {
  @Input() user?: GetActualRegistrationFormsForAssociationResponseDto;

  registrationLabel?: string;

  constructor(private readonly translateService: TranslateService, private readonly cdr: ChangeDetectorRef) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.user) {
      const registrationDate = this.user?.dateRegistered
        ? formatDate(this.user.dateRegistered, 'dd.MM.yyyy', Language.EN)
        : undefined;

      if (registrationDate) {
        this.registrationLabel = this.translateService.instant(
          'components.listItemRegistrationUsers.labels.registrationDate',
          {
            value: registrationDate,
          },
        );
      }
    }

    this.cdr.markForCheck();
  }
}
