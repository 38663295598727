import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

import { LegendItemSize, LegendItemType, LegendDirection } from './types';

/**
 * Компонент для отображения легенды
 * @param items {@link LegendItemType} - Массив значений
 * @param size {@link LegendItemSize} - Размер
 * @param direction {@link LegendItemSize} - Ориентация
 */
@Component({
  selector: 'app-legend',
  templateUrl: './legend.component.html',
  styleUrls: ['./legend.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LegendComponent {
  @Input() items: LegendItemType[] = [];
  @Input() size: LegendItemSize = 's';
  @Input() direction: LegendDirection = 'row';
}
