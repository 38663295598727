import { marker } from '@biesbjerg/ngx-translate-extract-marker';

import { FilterStore, FilterParams } from './types';

export const DEFAULT_FILTER_STORE: Readonly<FilterStore> = {
  jobs: { title: marker('components.searchUsers.constants.jobs'), type: 'checkbox', items: [], result: [] },
  businessTypes: {
    title: marker('components.searchUsers.constants.businessTypes'),
    type: 'checkbox',
    items: [],
    result: [],
  },
  organizations: {
    title: marker('components.searchUsers.constants.organization'),
    type: 'checkbox',
    items: [],
    result: [],
    variant: 'without-avatar',
  },
  committees: {
    title: marker('components.searchUsers.constants.committees'),
    type: 'checkbox',
    items: [],
    result: [],
    variant: 'full',
  },
  contacts: {
    title: marker('components.searchUsers.constants.contacts'),
    type: 'field',
    items: [],
    result: [],
  },
};

export const DEFAULT_FILTER_PARAMS: Readonly<FilterParams> = {
  jobs: [],
  businessTypes: [],
  organizations: [],
  committees: [],
  contacts: [],
};
