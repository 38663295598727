import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { TuiSizeL, TuiSizeM, TuiSizeS } from '@taiga-ui/core';
import { ViewMode } from '@src/models';

import { FieldTypeName } from './types';
import { FIELD_TYPES } from './constants';

@Component({
  selector: 'app-field',
  templateUrl: './field.component.html',
  styleUrls: ['./field.component.scss', './styles/field-content.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FieldComponent {
  /**
   * @deprecated Используете {typeName} свойство
   */
  @Input() set typeId(id: number | undefined) {
    this.typeName = Object.entries(FIELD_TYPES).find(([_, fieldType]) => fieldType.id === id)?.[0] as FieldTypeName;
  }

  @Input() typeName?: FieldTypeName;

  @Input() mode?: ViewMode = 'view';
  @Input() name?: string;
  @Input() value?: any;
  @Input() userFullName?: string;
  @Input() size: TuiSizeS | TuiSizeM | TuiSizeL = 'l';

  @Input() noUnderline?: boolean = false;
}
