import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Subject, throwError } from 'rxjs';
import { catchError, map, takeUntil } from 'rxjs/operators';
import { OrganisationService } from '@src/api';
import { getImageSrc, logger } from '@src/utils';
import { StorageService } from '@src/app/modules/auth';
import { StorageKeys } from '@src/constants/storage';
import { AlertService } from '@src/core/services';

import { OrganisationShortInfoUI } from '../types';
import { UNIONS_DOMAINS } from '../constants';

@Injectable({
  providedIn: 'root',
})
export class BrandingService {
  readonly data$: BehaviorSubject<OrganisationShortInfoUI | null | undefined> = new BehaviorSubject<
    OrganisationShortInfoUI | null | undefined
  >(null);

  private codeName?: string | null;
  private destroyed$$: Subject<void> = new Subject<void>();

  constructor(
    private readonly router: Router,
    private readonly organisationService: OrganisationService,
    private readonly storageService: StorageService,
    private readonly alertService: AlertService,
  ) {
    logger('BrandingService constructor');

    this.init();
  }

  ngOnDestroy(): void {
    this.destroyed$$.next();
    this.destroyed$$.complete();
  }

  async init() {
    // Новая логика получения бренда
    const domains = window.location.hostname.split('.');
    if (domains.length === 3 && !UNIONS_DOMAINS.includes(domains[0])) {
      const codeName = domains[0];
      this.codeName = codeName;
    }
  }

  getBrandData(codeName = this.codeName): void {
    if (!codeName) {
      // TODO: localization console перевести
      this.alertService.error('Не указан codeName для бренда');
      throw new Error('Не указан codeName для бренда');
    }

    this.codeName = codeName;
    this.writeCodeNameToStorage(codeName);

    this.organisationService
      .organisationShortInfo(codeName)
      .pipe(
        catchError(err => {
          this.clearAllData();

          this.alertService.error('Бренд не найден');
          this.router.navigate(['not-found']);

          // TODO handle error
          return throwError(err);
        }),
        map(data => this.normalizeData(data)),
        takeUntil(this.destroyed$$),
      )
      .subscribe(organisationShortInfo => {
        this.data$.next(organisationShortInfo);
      });
  }

  clearAllData() {
    this.codeName = undefined;
    this.data$.next(undefined);
    this.removeCodeNameFromStorage();
  }

  isBrand(): boolean {
    return !!this.getCodeName();
  }

  getCodeName(): string | null {
    return this.codeName || this.readCodeNameFromStorage();
  }

  private readCodeNameFromStorage(): string | null {
    return this.storageService.getItem(StorageKeys.CodeName);
  }

  private writeCodeNameToStorage(codeName: string): void {
    this.storageService.setItem(StorageKeys.CodeName, codeName);
  }

  private removeCodeNameFromStorage(): void {
    this.storageService.removeItem(StorageKeys.CodeName);
  }

  private normalizeData(data: OrganisationShortInfoUI) {
    data.logoSrc = getImageSrc(data.photoId, undefined, 'fullSize');

    return data;
  }
}
