<app-poll-info-view
  *ngIf="mode === 'view'"
  [data]="data$ | async"
  [documentsList]="documents$ | async"
  [membersList]="membersList$ | async"
  [allowEditing]="allowEditing$ | async"
  [allowMembersViewing]="allowMembersViewing$ | async"
  [allowStatisticsViewing]="allowStatisticsViewing$ | async"
  [externalLoading]="loading"
  [recallButtonLoading]="recallButtonLoading"
  (startEditing)="onStartEditing()"
  (startPoll)="startPoll()"
  (copyPoll)="onCopyPoll()"
  (declinePoll)="declinePoll()"
  (deletePoll)="deletePoll($event)"
  (statistics)="getStatistics()"
  (recallMembers)="resendNotifications($event)"
></app-poll-info-view>

<app-poll-info-edit
  *ngIf="mode === 'create' || mode === 'edit'"
  [mode]="mode"
  [data]="data$ | async"
  [oldDocuments]="tuiFiles$ | async"
  [membersList]="membersList$ | async"
  [externalLoading]="loading"
  (saved)="onSaveData($event)"
  (canceled)="onCancel()"
></app-poll-info-edit>

<ng-container *ngIf="mode === 'execute'">
  <app-questions
    *tuiLet="data$ | async as data"
    [mode]="!!data?.displayStepByStep ? 'step-by-step' : 'all'"
    [pollData]="data"
    [documentsList]="documents$ | async"
    (pollStopped)="stopPoll()"
    (pollFinished)="!!data?.completionText ? showFinalSpeech() : stopPoll()"
  ></app-questions>
</ng-container>

<poll-final-speech
  *ngIf="mode === 'speech'"
  [text]="(data$ | async)?.completionText || ''"
  (close)="stopPoll()"
></poll-final-speech>

<ng-template #confirmEditDialogTemplate let-observer>
  <tui-checkbox-labeled class="tui-space_bottom-3 margin-auto" size="m" [(ngModel)]="notifyEveryone">
    {{ 'components.pollInfo.dialogs.notifyEveryoneLabel' | translate }}
  </tui-checkbox-labeled>
  <button tuiButton type="button" size="s" class="tui-space_top-3" (click)="observer.complete()">
    {{ 'components.pollInfo.buttons.confirm' | translate }}
  </button>
</ng-template>
