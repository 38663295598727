import { maskitoTransform } from '@maskito/core';

import { PHONE_MASK_OPTIONS } from '../constants';

/**
 * Утилита для форматирования номера телефона по маске
 * @param value номер телефона ('+7XXXXXXXXXX')
 * @param mask маска
 * @returns номер телефона в обычном виде ('+7 XXX XXX XX-XX')
 */
export const formatPhone = (value?: string | null, mask = PHONE_MASK_OPTIONS) => {
  if (!value) {
    return;
  }

  return maskitoTransform(value ?? '', mask);
};
