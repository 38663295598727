// keep it equal with variables in _variables.scss
export enum BreakpointsRules {
  xsBreakpoint = '(max-width: 576px)',
  smBreakpoint = '(min-width: 576px)',
  mdBreakpoint = '(min-width: 768px)',
  lgBreakpoint = '(min-width: 1024px)',
  xlBreakpoint = '(min-width: 1280px)',
  xxlBreakpoint = '(min-width: 1920px)',
}

export type ScreenTypes = 'extra-small' | 'small' | 'medium' | 'large' | 'extra-large' | 'double-extra-large';

export type ScreenDimension = 'mobile' | 'desktop';
