import { Inject, Injectable, Injector, OnDestroy } from '@angular/core';
import { AuthorizationStateUnion } from '@airgram/core';
import { UpdateUnion, UserUnion } from '@airgram/web';
import { BehaviorSubject, interval, Subject, Subscription, timer } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { TuiDialogService } from '@taiga-ui/core';
import { PolymorpheusComponent } from '@tinkoff/ng-polymorpheus';
import { TranslateService } from '@ngx-translate/core';
import { logger, pause, reload } from '@src/utils';
import { AlertService } from '@src/core/services';
import { DialogConfirmCommonComponent, ManyTabsDialogComponent } from '@src/app/shared/dialogs';
import { StorageKeys } from '@src/constants/storage';

import { AirgramApiWrapper } from '../api/airgramApiWrapper';
import { DialogConfirmLoginComponent } from '../ui/dialog-confirm-login/dialog-confirm-login.component';
import {
  BOT_MESSAGES,
  GET_TOKEN_COMMAND,
  MAIN_BOT,
  SHOW_INTERRUPT_SESSION_TIMER,
  CHECK_MAIN_BOT_STATUS_INTERVAL,
  CHECK_MAIN_BOT_STATUS_TIMEOUT,
  BOT_TOKEN_SENDING_TIMEOUT,
} from '../constants';
import { CheckMainBotStatus } from '../types';
import { htmlTagsReplace } from '../ui/chat/utils/htmlTagsReplace';
import { AuthService, StorageService } from '../../auth';
import { EnterType } from '../../login/login.model';

import { TelegramErrorHandlerService } from './telegram-error-handler.service';
import { AirgramFactoryService } from './airgram-factory.service';

@Injectable({
  providedIn: 'root',
})
export class TelegramAuthService implements OnDestroy {
  /** Поток, где получаем текущий статус авторизации в Telegram */
  readonly authState$: Subject<AuthorizationStateUnion> = new Subject();

  /** Поток, где получаем статус основного бота приложения */
  readonly checkMainBotStatus$: BehaviorSubject<CheckMainBotStatus | null> =
    new BehaviorSubject<CheckMainBotStatus | null>(null);

  /** Поток, где получаем результат работы */
  readonly success$: Subject<string | null> = new Subject();

  /** Поток, где получаем все события */
  readonly updates$: Subject<UpdateUnion> = new Subject();

  /** Поток, где получаем флаг, что пользователь авторизован */
  readonly isLogged$: BehaviorSubject<boolean | null> = new BehaviorSubject<boolean | null>(null);

  /** Поток, где получаем флаг, что текущая вкладка является активной */
  readonly isActiveTab$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  timeoutSubscription$: Subscription = new Subscription();

  /** Флаг запрещающие отсылать команду боту, для получения токена */
  private isPreventGetTokenFromBot = false;

  private airgram!: AirgramApiWrapper;

  private currentTgUser?: UserUnion;

  private mainBot = MAIN_BOT;

  /** Поток, где проверяем разблокирован ли основной бот */
  private checkMainBotStatusInterval$?: Subscription;

  private destroyed$$: Subject<void> = new Subject<void>();

  private readonly manyTabsDialog = this.dialogService.open<boolean>(
    new PolymorpheusComponent(ManyTabsDialogComponent, this.injector),
    {
      closeable: true,
      dismissible: true,
      size: 'auto',
    },
  );

  constructor(
    private airgramFactory: AirgramFactoryService,
    private errorService: TelegramErrorHandlerService,
    private readonly alertService: AlertService,
    private readonly translateService: TranslateService,
    private readonly storage: StorageService,
    private readonly authService: AuthService,
    @Inject(TuiDialogService) private readonly dialogService: TuiDialogService,
    @Inject(Injector) private readonly injector: Injector,
  ) {
    logger('TelegramAuthService constructor');

    this.updates$.pipe(takeUntil(this.destroyed$$)).subscribe(update => {
      if (update._ === 'updateAuthorizationState') {
        const { authorizationState } = update;

        logger('TelegramAuthService', authorizationState);

        switch (authorizationState._) {
          case 'authorizationStateReady':
            this.authState$.next(authorizationState);
            this.airgram?.getMe().then(user => {
              this.isLogged$.next(true);

              this.currentTgUser = user;

              if (!this.isPreventGetTokenFromBot) {
                this.getTokenFromBot().then();
              } else {
                this.success$.next(null);
              }
            });
            break;

          case 'authorizationStateLoggingOut':
            this.isLogged$.next(false);

            pause(SHOW_INTERRUPT_SESSION_TIMER).then(() => {
              this.airgram?.getAuthorizationState().then(state => {
                if (state._ === 'authorizationStateLoggingOut') {
                  this.authState$.next(state);
                }
              });
            });
            break;

          default:
            if (
              [
                'authorizationStateWaitOtherDeviceConfirmation',
                'authorizationStateWaitPhoneNumber',
                'authorizationStateWaitCode',
                'authorizationStateWaitPassword',
              ].includes(authorizationState._)
            ) {
              this.isLogged$.next(false);
            }

            this.authState$.next(authorizationState);

            break;
        }
      }

      if (
        update._ === 'updateNewMessage' &&
        update.message.senderId._ === 'messageSenderUser' &&
        update.message.senderId.userId === this.mainBot?.id &&
        update.message.content._ === 'messageText' &&
        !this.isPreventGetTokenFromBot
      ) {
        const messageText = update.message.content.text.text;
        const messageTextToLowerCase = messageText.toLowerCase();

        if (messageTextToLowerCase.endsWith(BOT_MESSAGES.notRegistered.toLowerCase())) {
          if (this.currentTgUser) {
            this.dialogService
              .open<boolean>(new PolymorpheusComponent(DialogConfirmCommonComponent, this.injector), {
                label: 'Передать контакт боту?',
                data: 'Для продолжения необходимо передать контакт боту или использовать другой способ входа. Передать контакт боту?',
                closeable: false,
                dismissible: false,
                size: 's',
              })
              .subscribe(confirm => {
                if (confirm && this.currentTgUser) {
                  this.airgram?.sendMessageContact(
                    this.mainBot.id,
                    {
                      _: 'contact',
                      userId: this.currentTgUser.id,
                      firstName: this.currentTgUser.firstName,
                      lastName: this.currentTgUser.lastName,
                      phoneNumber: this.currentTgUser.phoneNumber,
                      vcard: '',
                    },
                    update.message.id,
                  );
                } else {
                  this.logout();
                }
              });
          }
        } else if (messageTextToLowerCase === BOT_MESSAGES.registered.toLowerCase()) {
          this.airgram?.deleteMessages(this.mainBot.id, [update.message.id]).then(() => {
            this.sendTokenCommandBot();
          });
        } else if (messageTextToLowerCase.includes(BOT_MESSAGES.phoneConfirmed.toLowerCase())) {
          this.sendTokenCommandBot();
        } else if (messageTextToLowerCase.includes(BOT_MESSAGES.phoneNotConfirmed.toLowerCase())) {
          this.alertService.error(
            this.translateService.instant('components.telegram.alerts.errors.userNotRegistered'),
            {
              autoClose: false,
            },
          );
          this.logout();
        } else if (messageTextToLowerCase.startsWith(BOT_MESSAGES.userNotAdded.toLowerCase())) {
          this.alertService.error(this.translateService.instant('components.telegram.alerts.errors.userNotFound'), {
            autoClose: false,
          });
          this.logout();
        } else {
          const startForToken = 'NewToken: ';
          if (messageText.toLowerCase().startsWith(startForToken.toLowerCase())) {
            this.removeTimeout();

            const accessToken = messageText.replace(startForToken, '');
            this.airgram?.deleteMessages(this.mainBot.id, [update.message.id]).then(() => {
              logger('TelegramAuthService get token from Telegram bot');

              this.success$.next(accessToken);
            });
          }
        }
      }
    });
  }

  ngOnDestroy(): void {
    this.destroyed$$.next();
    this.destroyed$$.complete();

    this.checkMainBotStatusInterval$?.unsubscribe();
  }

  isLogged(): boolean | null {
    return this.isLogged$.value;
  }

  isActiveTab(): boolean {
    return this.isActiveTab$.value;
  }

  get api(): AirgramApiWrapper {
    return this.airgram;
  }

  preventGetTokenFromBot() {
    this.isPreventGetTokenFromBot = true;
  }

  allowGetTokenFromBot() {
    this.isPreventGetTokenFromBot = false;
  }

  setMainBot(id: number, username: string) {
    this.mainBot = { id, username };
  }

  getMainBot() {
    return this.mainBot;
  }

  /**
   * Окно оповещения о необходимости авторизации в Telegram.
   * @param successCallback Действие, которые выполняется после успешной авторизации в Telegram
   */
  viewConfirmLoginTelegram(successCallback?: () => void) {
    this.dialogService
      .open<boolean>(new PolymorpheusComponent(DialogConfirmLoginComponent, this.injector), {
        label: this.translateService.instant('common.dialogs.attentionHeader'),
        size: 's',
        closeable: true,
      })
      .pipe(takeUntil(this.destroyed$$))
      .subscribe({
        next: res => {
          if (res) {
            successCallback?.();
          }
        },
      });
  }

  /**
   * Окно оповещения о том, что открыта другая вкладка.
   */
  viewManyTabsDialog() {
    this.manyTabsDialog.pipe(takeUntil(this.destroyed$$)).subscribe();
  }

  /**
   * Начало авторизации в Telegram.
   * Здесь создаем новый инстанс и добавляем слушателей.
   */
  async authenticate(): Promise<void> {
    const enterType = this.storage.getItem(StorageKeys.EnterType);

    if (enterType === EnterType.Email) {
      this.isLogged$.next(false);
      return;
    }

    this.airgram = this.airgramFactory.createInstance();
    this.airgram.use(this.updates$, this.errorService.handle);
  }

  logout() {
    return this.airgram.logout();
  }

  requestQrCode() {
    return this.airgram.requestQrCodeAuthentication();
  }

  setPhoneNumber(phoneNumber: string) {
    return this.airgram.setAuthenticationPhoneNumber(phoneNumber);
  }

  checkCode(code: string | number) {
    return this.airgram.checkAuthenticationCode(code.toString());
  }

  checkPassword(password: string) {
    return this.airgram.checkAuthenticationPassword(password);
  }

  /**
   * Это метод принудительной очистки данных сеанса
   */
  interruptSession() {
    this.airgram.logout();

    window.indexedDB.deleteDatabase('/tdlib/dbfs');
    window.indexedDB.deleteDatabase('tdlib');
    reload();
  }

  setActiveTab(isActive: boolean) {
    this.isActiveTab$.next(isActive);
  }

  private async getTokenFromBot() {
    logger('TelegramAuthService get token from bot');

    if (await this.isMainBotBlocked()) {
      this.dialogService
        .open<boolean>(new PolymorpheusComponent(DialogConfirmCommonComponent, this.injector), {
          label: 'Разблокировать бота?',
          data: 'Разблокировать бота?',
          closeable: false,
          dismissible: false,
          size: 's',
        })
        .subscribe(confirm => {
          if (confirm) {
            this.setTimeout(BOT_TOKEN_SENDING_TIMEOUT);
            return this.sendStartCommandBot();
          } else {
            this.logout();
            return new Promise<void>(resolve => resolve());
          }
        });
    } else {
      this.setTimeout(BOT_TOKEN_SENDING_TIMEOUT);
      return this.sendStartCommandBot();
    }
  }

  /**
   * Определяем, заблокирован ли основной бот
   */
  private async isMainBotBlocked() {
    const blockedChats = await this.airgram.getBlockedMessageSenders(0);
    return Boolean(
      blockedChats.senders.find(sender => {
        return sender._ === 'messageSenderUser' && sender.userId === this.mainBot.id;
      }),
    );
  }

  /**
   * TODO: удалить, перестали использовать
   * Ждем, пока пользователь не разблокирует основного бота
   */
  private waitUntilMainBotUnlock() {
    return new Promise<void>(resolve => {
      this.checkMainBotStatusInterval$ = interval(CHECK_MAIN_BOT_STATUS_INTERVAL)
        .pipe(take(CHECK_MAIN_BOT_STATUS_TIMEOUT))
        .subscribe({
          next: () => {
            this.isMainBotBlocked().then(result => {
              logger('TelegramAuthService is main bot locked:', result);

              this.checkMainBotStatus$.next('pending');

              if (!result) {
                this.checkMainBotStatusInterval$?.unsubscribe();
                resolve();
              }
            });
          },
          complete: () => {
            this.checkMainBotStatus$.next('timeout');
            // eslint-disable-next-line no-console
            console.error('TelegramAuthService wait until main bot unlock timeout');
            resolve();
          },
        });
    });
  }

  private async sendStartCommandBot() {
    await this.airgram.searchPublicChat(this.mainBot.username);
    await this.airgram.openChat(this.mainBot.id);
    await this.airgram.toggleMessageSenderIsBlocked({ _: 'messageSenderUser', userId: this.mainBot.id }, false);
    // TODO: добавить проверку и отправлять только если пользователь не подписан на бота
    await this.airgram.sendBotStartMessage(this.mainBot.id, this.mainBot.id);
  }

  private async sendTokenCommandBot(): Promise<void> {
    const parsedTokenCommand = await this.airgram.parseTextEntities(htmlTagsReplace(GET_TOKEN_COMMAND, false), {
      _: 'textParseModeHTML',
    });

    this.airgram.sendMessageText(this.mainBot.id, parsedTokenCommand);
  }

  private setTimeout(ms: number) {
    if (this.storage.getItem(StorageKeys.EnterType) !== EnterType.Messenger) return;

    this.timeoutSubscription$ = timer(ms).subscribe(() => {
      if (this.authService.isLogged()) {
        reload();
      } else {
        this.alertService.error(this.translateService.instant('components.telegramAuth.alerts.errors.loginTimeout'));
        pause(1000).then(() => this.interruptSession()); // TODO: Заменить на разблокировку бота (toggleMessageSenderIsBlocked не работает) + перезапуск сервиса бота
      }
    });
  }

  private removeTimeout() {
    this.timeoutSubscription$.unsubscribe();
  }
}
