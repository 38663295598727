import { ViewLoyaltyCategoryGroup } from '@src/api';

import { GroupItem, SelectItem } from '../../types';

export const convertToCategories = (items: ViewLoyaltyCategoryGroup[]): ReadonlyArray<GroupItem> => {
  return items.reduce<GroupItem[]>((acc, item) => {
    acc.push({
      id: item.id!,
      imageId: item.iconId,
      name: item.name,
      items:
        item.loyaltyCategories?.map<SelectItem>(subItem => ({
          id: subItem.id!,
          imageId: subItem.iconId,
          name: subItem.name,
        })) ?? [],
    });

    return acc;
  }, []);
};
