/**
 * Unions API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Отрасль для объединения.
 */
export interface BusinessTypeForUnionView { 
    /**
     * ID отрасли из табл BusinessTypes.
     */
    businessTypeId?: string;
    /**
     * ID организации. Определяется по пользователю.
     */
    parentOrganisationId?: string;
    /**
     * 
     */
    description?: string;
    /**
     * 
     */
    sortOrder?: number;
    /**
     * ID отрасли для объединения.
     */
    id?: string;
    /**
     * 
     */
    businessTypeName?: string;
}

