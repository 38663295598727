import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Subject, throwError } from 'rxjs';
import { catchError, map, takeUntil } from 'rxjs/operators';
import { OPFTypesService, OPFType } from '@src/api';
import { AlertService } from '@src/core/services/alert.service';

@Injectable({
  providedIn: 'root',
})
export class OpfTypeService implements OnDestroy {
  opfType$: BehaviorSubject<OPFType>;
  opfTypes$: BehaviorSubject<OPFType[]>;

  private destroyed$$: Subject<void> = new Subject<void>();

  constructor(private opfTypesService: OPFTypesService, private alertService: AlertService) {
    this.opfType$ = new BehaviorSubject({});
    this.opfTypes$ = new BehaviorSubject([{}]);
  }

  ngOnDestroy(): void {
    this.destroyed$$.next();
    this.destroyed$$.complete();
  }

  getOPFType(id: number): void {
    this.opfTypesService
      .getOPFTypesList()
      .pipe(
        catchError(err => {
          this.alertService.error(err, { autoClose: 30000 });
          return throwError(err);
        }),
        takeUntil(this.destroyed$$),
      )
      .subscribe(opfTypes => {
        const findOpfType = opfTypes.find(opfType => opfType.id === id);
        if (findOpfType) this.opfType$.next(findOpfType);
      });
  }

  getOPFTypes(): void {
    this.opfTypesService
      .getOPFTypesList()
      .pipe(
        catchError(err => {
          this.alertService.error(err, { autoClose: 30000 });
          return throwError(err);
        }),
        map(opfTypes => this.sortingOPFTypes(opfTypes)),
        takeUntil(this.destroyed$$),
      )
      .subscribe(opfTypes => this.opfTypes$.next(opfTypes));
  }

  resetOPFType(): void {
    this.opfType$.next({});
  }

  resetOPFTypes(): void {
    this.opfTypes$.next([{}]);
  }

  resetAll(): void {
    this.resetOPFType();
    this.resetOPFTypes();
  }

  private sortingOPFTypes(opfTypes: OPFType[]): OPFType[] {
    opfTypes.sort((opfType1, opfType2) => {
      const opfTypeName1 = opfType1.name?.toLowerCase();
      const opfTypeName2 = opfType2.name?.toLowerCase();

      if (!opfTypeName1) return 1;
      if (!opfTypeName2) return -1;

      if (opfTypeName1 < opfTypeName2) return -1;
      if (opfTypeName1 > opfTypeName2) return 1;
      return 0;
    });

    return opfTypes;
  }
}
