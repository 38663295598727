/**
 * Unions API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Параметры для редактирования мероприятия.
 */
export interface EventEdit { 
    /**
     * Идентификатор создателя.
     */
    createdBy?: string;
    /**
     * Дата начала.
     */
    dateStart?: string;
    /**
     * Дата окончания.
     */
    dateEnd?: string;
    /**
     * адрес
     */
    address?: string;
    /**
     * Лицо для связи (telegramId).
     */
    contactPerson?: number;
    /**
     * Тема.
     */
    subject?: string;
    /**
     * Описание.
     */
    description?: string;
    /**
     * Ссылка на документы.
     */
    docsLink?: string;
    /**
     * Статус.
     */
    status?: number;
    /**
     * Мероприятие верхнего уровня.
     */
    parentId?: string;
    /**
     * Идентификатор организации, создавшей мероприятие.
     */
    organisationId?: string;
    /**
     * Ссылка на online-трансляцию.
     */
    onlineLink?: string;
    /**
     * Идентификатор бота.
     */
    botId?: number;
    /**
     * Идентификатор канала.
     */
    channelId?: number;
    /**
     * Идентификатор типа мероприятия.
     */
    eventTypeId?: number;
    /**
     * Признак видимости мероприятия всем.
     */
    isVisibleToAll?: boolean;
    /**
     * Признак доступного всем мероприятия.
     */
    isFreeToRegister?: boolean;
    /**
     * ID комитета.
     */
    committeeId?: string;
    /**
     * Лицо для связи (внутренний ID).
     */
    contactPersonId?: string;
    /**
     * Идентификатор мероприятия
     */
    id?: string;
    /**
     * массив из id учатников для дальнейшей рассылки (telegramId)
     */
    sendTo?: Array<string>;
    /**
     * Идентификатор фотографии мероприятия.
     */
    photoId?: string;
    /**
     * Текст приглашения к чату.
     */
    inviteText?: string;
    /**
     * Признак сброса участия уже зарегистрировавшихся участников, чтоб зарегистрироваться вновь
     */
    resetEvent?: boolean;
    /**
     * Отправка уведомлений об изменениях мероприятия всем пользователям.
     */
    notifyEveryone?: boolean;
}

