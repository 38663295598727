/**
 * Unions API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { LabelItemForUnionView } from './labelItemForUnionView';
import { LabelItemDefaultView } from './labelItemDefaultView';


/**
 * 
 */
export interface LabelItemsView { 
    /**
     * 
     */
    defaults?: Array<LabelItemDefaultView>;
    /**
     * 
     */
    customs?: Array<LabelItemForUnionView>;
}

