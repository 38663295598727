import { Component, ChangeDetectionStrategy, ChangeDetectorRef, Inject, Injector } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { BreakpointObserver } from '@angular/cdk/layout';
import { takeUntil } from 'rxjs/operators';
import { PolymorpheusComponent } from '@tinkoff/ng-polymorpheus';
import { TuiDialogService } from '@taiga-ui/core';
import { MailView } from '@src/api';
import { BreakpointObserverHelperService, UserService, MailsService, AlertService } from '@src/core/services';
import { DialogConfirmComponent } from '@src/app/shared/dialogs';
import { ResizableBaseComponent } from '@src/app/components';
import { BadgeColors } from '@src/app/components/badge';
import { TranslateService } from '@ngx-translate/core';

import { MailSettingsStatus } from './types';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-mail',
  templateUrl: './mail.component.html',
  styleUrls: ['./mail.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MailComponent extends ResizableBaseComponent {
  loading: boolean = false;
  testMailLoading: boolean = false;
  organisationId?: string;
  data?: MailView;

  private readonly confirmDialog = this.dialogService.open<boolean>(
    new PolymorpheusComponent(DialogConfirmComponent, this.injector),
    {
      label: this.translateService.instant('components.mail.dialogs.settingsDeletionHeader'),
      size: 's',
      closeable: false,
    },
  );

  form = new UntypedFormGroup({
    email: new UntypedFormControl(null, Validators.required),
    password: new UntypedFormControl(null, Validators.required),
    smtp: new UntypedFormControl(null, Validators.required),
    smtpPort: new UntypedFormControl(null, [Validators.required, Validators.min(1), Validators.max(65535)]),
    useSSL: new UntypedFormControl(false),
    ntlmUser: new UntypedFormControl(null),
    ntlmDomain: new UntypedFormControl(null),
    proxyHost: new UntypedFormControl(null),
    proxyPort: new UntypedFormControl(null, [Validators.min(1), Validators.max(65535)]),
    proxyLogin: new UntypedFormControl(null),
    proxyPassword: new UntypedFormControl(null),
    proxyDomain: new UntypedFormControl(null),
    status: new UntypedFormControl(null),
  });

  constructor(
    readonly cdr: ChangeDetectorRef,
    readonly breakpointObserver: BreakpointObserver,
    readonly breakpointObserverHelperService: BreakpointObserverHelperService,
    private userService: UserService,
    private mailsService: MailsService,
    private readonly alertService: AlertService,
    private readonly translateService: TranslateService,
    @Inject(TuiDialogService) private readonly dialogService: TuiDialogService,
    @Inject(Injector) private readonly injector: Injector,
  ) {
    super(cdr, breakpointObserver, breakpointObserverHelperService);
  }

  get statusOptions() {
    switch (this.form.get('status')?.value) {
      case MailSettingsStatus.Ok:
        return {
          value: this.translateService.instant('components.mail.labels.statusOk'),
          color: BadgeColors.Green,
        };
        break;

      case MailSettingsStatus.Error:
        return {
          value: this.translateService.instant('components.mail.labels.statusError'),
          color: BadgeColors.Red,
        };
        break;

      default:
        return {
          value: this.translateService.instant('components.mail.labels.statusDefault'),
          color: BadgeColors.Red,
        };
        break;
    }
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.userService.getAuthorizedUser().then(res => {
      this.organisationId = res?.organisationId;
      if (res?.organisationId) {
        this.getData(res.organisationId);
      }
    });
  }

  getFormControl(name: string): UntypedFormControl {
    return this.form.get(name) as UntypedFormControl;
  }

  isRequiredFormControl(name: string): boolean {
    return this.getFormControl(name).hasValidator(Validators.required);
  }

  getRequiredSign(name: string): string {
    return this.isRequiredFormControl(name) ? ' *' : '';
  }

  getData(organisationId: string): void {
    this.loading = true;
    this.form.reset({ useSSL: false });
    this.mailsService
      .getMail(organisationId)
      .pipe(takeUntil(this.destroyed$$))
      .subscribe(res => {
        this.data = res;
        if (res) {
          this.form.reset(this.data);
        }

        this.loading = false;
        this.cdr.markForCheck();
      });
  }

  onClickSaveButton(): void {
    this.form.markAllAsTouched();

    if (this.form.invalid) {
      this.alertService.error(this.translateService.instant('common.alerts.errors.fillRequired'));
      return;
    }

    const data = this.form.value;
    const params = Object.assign({}, data, {
      id: this.data?.id,
      organisationId: this.data?.organisationId || this.organisationId,
    });

    if (!params.id) {
      delete params.id;
    }

    this.loading = true;
    const requestObs = !params.id ? this.mailsService.addMail(params) : this.mailsService.editMail(params);
    requestObs.pipe(takeUntil(this.destroyed$$)).subscribe(
      res => {
        this.data = data;

        if (this.data && res?.id) {
          this.data.id = res.id;
        }

        this.loading = false;
        this.cdr.markForCheck();
        this.alertService.success(this.translateService.instant('common.alerts.successes.saveData'));
      },
      () => {
        this.loading = false;
        this.cdr.markForCheck();
      },
    );
  }

  onClickSendTestMail(): void {
    this.mailsService.sendTestMail(value => {
      this.testMailLoading = value;
      this.cdr.markForCheck();
    });
  }

  onClickDeleteButton(): void {
    const mailId = this.data?.id;
    if (!mailId) return;

    this.confirmDialog.pipe(takeUntil(this.destroyed$$)).subscribe({
      next: async res => {
        if (res) {
          await lastValueFrom(this.mailsService.deleteMail(mailId)).then(() => {
            if (this.organisationId) {
              this.getData(this.organisationId);
            }
          });
        }
      },
    });
  }

  onClickCancelButton(): void {
    if (this.organisationId) {
      this.getData(this.organisationId);
    }
  }
}
