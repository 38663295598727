import { Pipe, PipeTransform } from '@angular/core';
import { MessageSenderUnion } from '@airgram/web';
import { from, Observable, of } from 'rxjs';
import { TelegramMessengerService } from '@src/app/modules/telegram';
import { UserService } from '@src/core/services';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'senderFullName',
})
export class SenderFullNamePipe implements PipeTransform {
  constructor(
    private userService: UserService,
    private messengerService: TelegramMessengerService,
    private readonly translateService: TranslateService,
  ) {}

  transform(senderId?: number | MessageSenderUnion): Observable<string> {
    if (!senderId) return of(this.translateService.instant('common.labels.user'));

    if (typeof senderId !== 'number') {
      senderId = senderId._ === 'messageSenderChat' ? senderId.chatId : senderId.userId;
    }

    if (senderId > 0) {
      return from(this.userService.getUserFullName(senderId));
    }

    return from(this.messengerService.api.getChatTitle(senderId));
  }
}
