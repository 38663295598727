<div class="media-recorder" [@appearWrapper]>
  <button
    *ngIf="!isVoiceRecording || !isRecordingAvailable; else activeRecordingButtons"
    tuiIconButton
    class="media-recorder__button"
    type="button"
    appearance="icon"
    icon="tuiIconMicLarge"
    size="s"
    [title]="'components.mediaRecorder.buttons.record' | translate"
    [@appear]
    (click)="startRecording()"
  ></button>
  <ng-template #activeRecordingButtons>
    <button
      tuiButton
      class="media-recorder__button media-recorder__button_pulse"
      type="button"
      appearance="custom"
      shape="rounded"
      size="s"
      [title]="'common.buttons.send' | translate"
      [@appear]
      (click)="emitRecord()"
    >
      <lucide-icon name="send"></lucide-icon>
    </button>
    <button
      tuiIconButton
      class="media-recorder__button"
      type="button"
      appearance="custom"
      icon="tuiIconXLarge"
      size="s"
      [title]="'common.buttons.cancel' | translate"
      [@appear]
      (click)="stopRecording()"
    ></button>
  </ng-template>
</div>
