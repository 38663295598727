import { Component, ChangeDetectionStrategy, Input, OnChanges, SimpleChanges } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { GroupedListItem } from '@src/app/shared/list/model/groups.model';
import { getGroups } from '@src/app/shared/list/utils/getGroups';
import { UserUI } from '@src/models';

import { START_PAGE_SIZE, START_PAGE } from '../../constants';

@Component({
  selector: 'users-table-view',
  templateUrl: './users-table-view.component.html',
  styleUrls: ['./users-table-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UsersTableViewComponent implements OnChanges {
  @Input() users?: UserUI[] | null;
  usersFiltered?: UserUI[];
  groups: GroupedListItem[] = [];

  activeTabIndex = 0;

  readonly columns = ['user', 'role', 'comment'];
  readonly pageSize$ = new BehaviorSubject(START_PAGE_SIZE);
  readonly currentPage$ = new BehaviorSubject(START_PAGE);

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.users) {
      if (this.users) {
        this.groups = getGroups(this.users || [], 'users').filter(group => !!group.items.length);
        this.usersFiltered = this.groups[this.activeTabIndex].items as UserUI[];
      }
    }
  }

  setActiveTabIndex(index: number) {
    this.usersFiltered = this.groups[index].items as UserUI[];
    this.onChangePageSize(START_PAGE_SIZE);
    this.onChangeCurrentPage(START_PAGE);
  }

  onChangePageSize(pageSize: number): void {
    this.pageSize$.next(pageSize);
  }

  onChangeCurrentPage(currentPage: number): void {
    this.currentPage$.next(currentPage);
  }
}
