/**
 * Unions API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Редактировать письмо рассылки.
 */
export interface NewsletterEdit { 
    /**
     * ID шаблона рассылки.
     */
    newsletterTemplateId?: string;
    /**
     * Заголовок рассылки.
     */
    title?: string;
    /**
     * Основной текст рассылки.
     */
    body?: string;
    /**
     * Дата отложенной рассылки.
     */
    sendOnDate?: string;
    /**
     * Опция показа кнопки подтверждения получения.
     */
    showReceiptConfirm?: boolean;
    /**
     * Контактное лицо.
     */
    contactPersonId?: string;
    /**
     * Массив пользователей для рассылки.
     */
    sendTo?: Array<string>;
    /**
     * ID записи.
     */
    id?: string;
}

