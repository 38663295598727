import { Inject, Injectable, Injector } from '@angular/core';
import { TuiDialogService } from '@taiga-ui/core';
import { PolymorpheusComponent } from '@tinkoff/ng-polymorpheus';
import { Observable } from 'rxjs';
import { BusinessTypeUI, JobTitleUI } from 'src/models';

import { BusinessTypesDialogComponent } from '../components/business-types-dialog/business-types-dialog.component';
import { JobTitlesDialogComponent } from '../components/job-titles-dialog/job-titles-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class DictionaryService {
  constructor(
    @Inject(TuiDialogService) private readonly dialogService: TuiDialogService,
    @Inject(Injector) private readonly injector: Injector,
  ) {}

  showBusinessTypes(): Observable<BusinessTypeUI[]> {
    return this.dialogService.open<BusinessTypeUI[]>(new PolymorpheusComponent(BusinessTypesDialogComponent, this.injector), {
      size: 'page',
      closeable: true,
      dismissible: true,
    });
  }

  showJobTitles(): Observable<JobTitleUI[]> {
    return this.dialogService.open<JobTitleUI[]>(new PolymorpheusComponent(JobTitlesDialogComponent, this.injector), {
      size: 'page',
      closeable: true,
      dismissible: true,
    });
  }
}
