import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { TuiOptionComponent } from '@taiga-ui/core';
import { EMPTY_QUERY, tuiPure } from '@taiga-ui/cdk';
import { AttachmentItems, ChatMemberModel, ChatModel } from 'src/models';
import { GetChatMembersService } from '@src/core/services';
import { TelegramMessengerService } from '@src/app/modules/telegram';
import { TranslateService } from '@ngx-translate/core';
import { lastValueFrom } from 'rxjs';
import { TuiEditorComponent, TuiEditorTool } from '@tinkoff/tui-editor';

import { getWordAt } from '../../utils/getWordAt';
import { trimMessage } from '../../utils/trimMessage';
import { RAISE_CHAT_MEMBERS_DROPDOWN_AT } from '../../constants';
import { htmlTagsReplace } from '../../utils/htmlTagsReplace';

/**
 * Компонент, где находится поле ввода сообщения и все сопутствующие контролы:
 * - блок с текстом, когда отвечаем на сообщение
 * - блок с текстом, когда редактируем сообщение
 * - выбор файла (документа) для вложения в сообщение
 * - запись аудио
 * - кнопка отправления сообщения
 * - кнопка сохранения отредактированного сообщения
 * - список пользователей, который отображаем по символу @
 *
 * После символа @ можно указывать данные и фильтровать по ним список пользователей.
 * Поиск будет производиться по логину и ФИО пользователя и при выборе подставляться в поле ввода сообщения.
 */
@Component({
  selector: 'telegram-conversation-box-input',
  templateUrl: './conversation-box-input.component.html',
  styleUrls: ['./conversation-box-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConversationBoxInputComponent implements OnInit {
  @Input() allowAttachment = false;

  @Input() chat?: ChatModel;

  @Input() replyToMessageId = 0;
  @Output() replyToMessageIdChange = new EventEmitter<number>();

  @Input() editMessageId = 0;
  @Output() editMessageIdChange = new EventEmitter<number>();

  @Input() newMessage = '';
  @Output() newMessageChange = new EventEmitter<string>();

  @ViewChild(TuiEditorComponent) private textarea?: TuiEditorComponent;

  @ViewChildren(TuiOptionComponent, { read: ElementRef })
  private options: QueryList<ElementRef<HTMLButtonElement>> = EMPTY_QUERY;

  /** Список доступных пользователей */
  private chatMembers: ChatMemberModel[] = [];

  /** Список отфильтрованных пользователей */
  private filteredChatMembers: ChatMemberModel[] = [];

  /** Флаг, что выпадающий список пользователей видим */
  isChatMembersDropdownVisible = false;

  readonly toolbarTools = [
    TuiEditorTool.Undo,
    TuiEditorTool.Italic,
    TuiEditorTool.Strikethrough,
    TuiEditorTool.Underline,
    TuiEditorTool.Bold,
  ];

  constructor(
    private cdr: ChangeDetectorRef,
    private telegramMessengerService: TelegramMessengerService,
    private getChatMembersService: GetChatMembersService,
    private readonly translateService: TranslateService,
  ) {}

  ngOnInit(): void {
    if (this.chat?.id) {
      this.getChatMembersService.getChatMembers(this.chat.id).then(members => {
        this.chatMembers = members;
      });
    }
  }

  setTextareaFocus(): void {
    setTimeout(() => this.textarea?.nativeFocusableElement?.focus());
  }

  /**
   * Очищаем данные по сообщению на которое хотели ответить
   */
  deleteReplyMessage(): void {
    this.replyToMessageId = 0;
    this.replyToMessageIdChange.emit(0);
  }

  /**
   * Очищаем данные по новому сообщению
   */
  deleteMessage(): void {
    this.newMessage = '';
    this.newMessageChange.emit('');
  }

  /**
   * Очищаем данные по редактируемому сообщению
   */
  deleteEditMessage(): void {
    this.editMessageId = 0;
    this.editMessageIdChange.emit(0);

    this.deleteMessage();
  }

  /**
   * Отправляем сообщение
   */
  async sendMessage(): Promise<void> {
    if (!this.chat?.id || !this.newMessage || !trimMessage(this.newMessage) || this.isChatMembersDropdownVisible) {
      return;
    }

    this.telegramMessengerService.api.viewMessages(this.chat.id, this.chat?.lastMessage?.id).then();

    const parsedText = await this.telegramMessengerService.api.parseTextEntities(
      htmlTagsReplace(this.newMessage, false),
      {
        _: 'textParseModeHTML',
      },
    );

    this.telegramMessengerService.api.sendMessageText(this.chat.id, parsedText, this.replyToMessageId).then(() => {
      this.deleteMessage();
      this.deleteReplyMessage();

      this.setTextareaFocus();
      this.cdr.markForCheck();
    });
  }

  /**
   * Редактируем сообщение
   */
  async editMessage(): Promise<void> {
    if (!this.chat?.id || !this.newMessage || !trimMessage(this.newMessage) || this.isChatMembersDropdownVisible) {
      return;
    }

    const parsedText = await this.telegramMessengerService.api.parseTextEntities(
      htmlTagsReplace(this.newMessage, false),
      {
        _: 'textParseModeHTML',
      },
    );

    this.telegramMessengerService.api.editMessageText(this.chat.id, parsedText, this.editMessageId).then(() => {
      this.deleteEditMessage();

      this.setTextareaFocus();
      this.cdr.markForCheck();
    });
  }

  /**
   * Отправляем вложенный файл (документ)
   */
  sendAttachments(items: AttachmentItems): void {
    if (!this.chat?.id) {
      return;
    }

    this.telegramMessengerService.api.viewMessages(this.chat.id, this.chat?.lastMessage?.id).then();

    lastValueFrom(
      this.telegramMessengerService.sendMessagesWithAttachment(this.chat.id, items, this.replyToMessageId),
    ).then(() => {
      this.deleteMessage();
      this.deleteReplyMessage();

      this.setTextareaFocus();
      this.cdr.markForCheck();
    });
  }

  /**
   * Отправляем голосовое сообщение
   */
  sendVoiceRecording(record: Blob): void {
    if (!this.chat?.id) {
      return;
    }

    lastValueFrom(
      this.telegramMessengerService.api.sendVoiceNoteMessage(this.chat?.id, record, this.replyToMessageId),
    ).then(() => {
      this.deleteMessage();
      this.deleteReplyMessage();

      this.setTextareaFocus();
      this.cdr.markForCheck();
    });
  }

  /**
   * Событие выбора пользователя, чтобы подставить его в текст сообщения
   */
  selectMember(member: ChatMemberModel): void {
    if (!this.textarea?.nativeFocusableElement || !member.username) {
      return;
    }

    this.isChatMembersDropdownVisible = false;

    const selection = window.getSelection();
    const word = getWordAt(selection);

    const lastIndex = this.newMessage.lastIndexOf(RAISE_CHAT_MEMBERS_DROPDOWN_AT) + 1;

    const newMessage =
      this.newMessage.slice(0, lastIndex) + member.username + this.newMessage.slice(lastIndex + word.length - 1);

    this.newMessage = newMessage;

    this.setTextareaFocus();
    this.cdr.markForCheck();
  }

  filterChatMembers(): ChatMemberModel[] {
    const selection = window.getSelection();
    const word = getWordAt(selection);
    if (word) {
      this.filteredChatMembers = this.getFilteredItems(this.chatMembers, word.slice(1));
    }

    return this.filteredChatMembers;
  }

  @tuiPure
  private getFilteredItems(items: ChatMemberModel[], search: string): ChatMemberModel[] {
    return items.filter(({ username, firstName, lastName, middleName }) => {
      return [username, firstName, lastName, middleName].some(item =>
        item?.toLowerCase().startsWith(search.toLowerCase()),
      );
    });
  }

  onKeydown(event: KeyboardEvent): void {
    if (event.key === 'Enter') {
      if (!event.shiftKey || this.isChatMembersDropdownVisible) {
        event.stopPropagation();
        event.preventDefault();

        if (!this.isChatMembersDropdownVisible) {
          this.editMessageId ? this.editMessage() : this.sendMessage();
        }

        return;
      }
    }

    let el;
    if (event.key === 'ArrowUp') {
      el = this.options?.first;
    } else if (event.key === 'ArrowDown') {
      el = this.options?.last;
    }

    if (el) {
      event.preventDefault();
      el.nativeElement.focus();
    }
  }

  onNewMessageChange() {
    this.isChatMembersDropdownVisible = this.getIsChatMembersDropdownVisible();
  }

  onTextareaFocusedChange(isFocused: boolean) {
    if (isFocused) {
      this.isChatMembersDropdownVisible = this.getIsChatMembersDropdownVisible();
    } else {
      this.isChatMembersDropdownVisible = false;
    }
  }

  get isFormatTextToolbarVisible(): boolean {
    return !!window.getSelection()?.toString().trim();
  }

  get focused(): true | null {
    return !!this.options.length || null;
  }

  getMemberDisplayableName({ username, tgFirstName, tgLastName }: ChatMemberModel): string {
    if (username) {
      return `@${username}`;
    }

    return tgFirstName || tgLastName || this.translateService.instant('common.labels.noData');
  }

  private getIsChatMembersDropdownVisible() {
    const selection = window.getSelection();
    const text = this.textarea?.nativeFocusableElement?.firstChild?.textContent;
    if (text) {
      const word = getWordAt(selection);

      if (word.startsWith(RAISE_CHAT_MEMBERS_DROPDOWN_AT)) {
        if (word === RAISE_CHAT_MEMBERS_DROPDOWN_AT) {
          return true;
        } else {
          return !this.chatMembers.find(item => item.username === word.slice(1));
        }
      }
    }

    return false;
  }
}
