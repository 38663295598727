<div class="field-wrapper">
  <ng-container [ngSwitch]="typeName">
    <app-field-email
      *ngSwitchCase="'email'"
      [mode]="mode"
      [name]="name"
      [value]="value"
      [size]="size"
    ></app-field-email>
    <app-field-file
      *ngSwitchCase="'file'"
      [mode]="mode"
      [fileName]="name"
      [fileId]="value"
      [size]="size"
    ></app-field-file>
    <app-field-file
      *ngSwitchCase="'file-for-admin'"
      [mode]="mode"
      [fileName]="name"
      [fileId]="value"
      [size]="size"
    ></app-field-file>
    <app-field-file
      *ngSwitchCase="'file-for-admin-and-user'"
      [mode]="mode"
      [fileName]="name"
      [fileId]="value"
      [size]="size"
    ></app-field-file>
    <app-field-files-list
      *ngSwitchCase="'files-list'"
      [name]="name"
      [mode]="mode"
      [files]="value"
      [size]="size"
    ></app-field-files-list>
    <app-field-messenger
      *ngSwitchCase="'messenger'"
      [mode]="mode"
      [name]="name"
      [value]="value"
      [size]="size"
    ></app-field-messenger>
    <app-field-phone
      *ngSwitchCase="'phone'"
      [mode]="mode"
      [name]="name"
      [value]="value"
      [size]="size"
    ></app-field-phone>
    <app-field-social-network
      *ngSwitchCase="'social-network'"
      [mode]="mode"
      [name]="name"
      [value]="value"
      [size]="size"
    ></app-field-social-network>
    <app-field-text *ngSwitchCase="'text'" [mode]="mode" [name]="name" [value]="value" [size]="size"></app-field-text>
    <app-field-text
      *ngSwitchCase="'text-for-admin'"
      [mode]="mode"
      [name]="name"
      [value]="value"
      [size]="size"
    ></app-field-text>
    <app-field-text
      *ngSwitchCase="'text-for-admin-and-user'"
      [mode]="mode"
      [name]="name"
      [value]="value"
      [size]="size"
    ></app-field-text>
    <app-field-user
      *ngSwitchCase="'user'"
      [mode]="mode"
      [name]="name"
      [userId]="value"
      [userFullName]="userFullName"
      [size]="size"
    ></app-field-user>
    <app-field-web-document
      *ngSwitchCase="'web-document'"
      [mode]="mode"
      [name]="name"
      [value]="value"
      [size]="size"
    ></app-field-web-document>
    <app-field-web-document
      *ngSwitchCase="'web-document-for-admin'"
      [mode]="mode"
      [name]="name"
      [value]="value"
      [size]="size"
    ></app-field-web-document>
    <app-field-web-document
      *ngSwitchCase="'web-document-for-admin-and-user'"
      [mode]="mode"
      [name]="name"
      [value]="value"
      [size]="size"
    ></app-field-web-document>
    <app-field-web-page
      *ngSwitchCase="'web-page'"
      [mode]="mode"
      [name]="name"
      [value]="value"
      [size]="size"
    ></app-field-web-page>
    <app-field-organisation
      *ngSwitchCase="'organisation'"
      [mode]="mode"
      [name]="name"
      [organisationId]="value"
      [size]="size"
    ></app-field-organisation>
    <app-field-parse-links
      *ngSwitchCase="'parse-links'"
      [mode]="mode"
      [name]="name"
      [value]="value"
      [size]="size"
    ></app-field-parse-links>
    <app-field-committee
      *ngSwitchCase="'committee'"
      [mode]="mode"
      [name]="name"
      [committeeId]="value"
      [size]="size"
    ></app-field-committee>

    <!-- TODO: Ниже компоненты с новым дизайном -->
    <!-- Однострочный текст -->
    <field-new-text
      *ngSwitchCase="'new-text'"
      [mode]="mode"
      [name]="name"
      [value]="value"
      [size]="size"
    ></field-new-text>

    <!-- Пользователь -->
    <field-new-user
      *ngSwitchCase="'new-user'"
      [mode]="mode"
      [name]="name"
      [userId]="value"
      [userFullName]="userFullName"
      [size]="size"
    ></field-new-user>
  </ng-container>
</div>
