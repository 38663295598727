export const environment = {
  emulateBotEnv: {
    enabled: false,
    key: '',
  },
  appVersion: `v ${require('../../package.json').version} (beta)`,
  production: true,
  baseUrl: 'https://lk.unions.me',
  bots: {
    main: {
      id: 5310729994,
      username: 'UnionsAppBot',
    },
  },
};
