export const isValidUrl = (text: string): string => {
  if (text.indexOf(' ') !== -1) {
    return '';
  }

  let url;
  let protocol = '';

  try {
    url = new URL(text);
  } catch {
    try {
      protocol = 'https://';
      url = new URL(`${protocol}/${text}`);
    } catch {
      return '';
    }
  }

  const lastIndex = url.host.lastIndexOf('.');
  if (lastIndex < 0) {
    return '';
  }

  const domain = url.host.substring(url.host.lastIndexOf('.') + 1);
  if (new RegExp('^[a-zA-Z]{2,}$').test(domain)) {
    return `${protocol}${text}`;
  }

  return '';
};
