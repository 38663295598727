/**
 * Unions API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Подписки.
 */
export interface CommitteeSubscriptionView { 
    /**
     * Название.
     */
    name?: string;
    /**
     * Описание.
     */
    description?: string;
    /**
     * Канал или хэштэг.
     */
    codeName?: string;
    /**
     * ID комитета.
     */
    committeeId?: string;
    /**
     * Порядок сортировки.
     */
    sortOrder?: number;
    /**
     * Предварительно созданый чат chatType = 2 (Канал), который надо привязать к подписке.
     */
    chatId?: number;
    /**
     * 
     */
    isDefault?: boolean;
    /**
     * ID владельца канала.
     */
    ownerId?: string;
    /**
     * 
     */
    linkOrgStructure?: string;
    /**
     * 
     */
    linkOrgManagement?: string;
    /**
     * 
     */
    linkOrgDocuments?: string;
    /**
     * ИД
     */
    id?: string;
    /**
     * Иденитфикатор фотографии.
     */
    photoId?: string;
    /**
     * ID типа чата ChatTypes.
     */
    chatTypeId?: number;
}

