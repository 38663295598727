import {
  FileInfoBase,
  GetStatisticsByContactsListResponseDto,
  GetStatisticsByContactsListResponseEntries,
  LoyaltyDiscountsListByPeriod,
} from '@src/api';
import { TuiDay } from '@taiga-ui/cdk';

import { LoyaltyProgramProduct } from '../models';

export enum LoyaltyStatus {
  /** Удален */
  Deleted = 0,

  /** опубликован */
  Published = 1,

  /** на проверке */
  Review = 2,

  /** проверка отклонена */
  Declined = 3,

  /** архив (снято с публикации) */
  Archive = 4,
}

export enum LoyaltyRating {
  /** непримечательное */
  Usual = 0,

  /** популярное */
  Popular = 1,

  /** заметное */
  Notable = 2,

  /** бестселлер */
  Bestseller = 3,
}

export type QrCodeImageFile = {
  file: File;

  index: number;
};

/** Мапа изображений, где в качестве индекса выступаем индекс массива группы со скидками */
export type QrCodeImageIndexId = Array<string | undefined>;

export interface UpdateQrCodeImagesResult {
  /** Список идентификаторов изображений, которые нужно удалить */
  qrCodeImageIdsToDelete: string[];

  /** Мапа изображений */
  qrCodeImages: QrCodeImageIndexId;

  /** Список идентификаторов изображений которые только что создали */
  newQrCodeImages: FileInfoBase[];
}

export type NavigationType = 'horizontal' | 'vertical';

export type NavButtonScrollType = 'forward-bottom' | 'backward-top';

export type LoyaltyProgramProductWithPagingResult = {
  items: readonly LoyaltyProgramProduct[] | null;

  hasMore: boolean;
};

export interface LoyaltyStatisticsSummaryParams {
  /** Id продукта */
  productId?: string;

  /** Id внешней ассоциации */
  unionId?: string;

  /** Дата начала статистики */
  dateFrom?: TuiDay;

  /** Дата окончания статистики */
  dateTo?: TuiDay;
}

export interface LoyaltyStatisticsBarParams {
  /** Id продукта */
  productId?: string;

  /** Id внешней ассоциации */
  unionId?: string;

  /** Дата начала статистики */
  dateFrom?: TuiDay;

  /** Дата окончания статистики */
  dateTo?: TuiDay;
}

export interface LoyaltyStatisticsProductsParams {
  /** Id продукта */
  productId?: string;

  /** Id внешней ассоциации */
  unionId?: string;

  /** Дата начала статистики */
  dateFrom?: TuiDay;

  /** Дата окончания статистики */
  dateTo?: TuiDay;
}

export interface LoyaltyStatisticsDiscountsParams {
  /** Id продукта */
  productId?: string;

  /** Id внешней ассоциации */
  unionId?: string;

  /** Дата начала статистики */
  dateFrom?: TuiDay;

  /** Дата окончания статистики */
  dateTo?: TuiDay;
}

export interface LoyaltyStatisticsContactsParams {
  /** Id продукта */
  productId?: string;

  /** Id внешней ассоциации */
  unionId?: string;

  /** Дата начала статистики */
  dateFrom?: TuiDay;

  /** Дата окончания статистики */
  dateTo?: TuiDay;
}

export interface LoyaltyDiscountsListByPeriodUI extends LoyaltyDiscountsListByPeriod {
  discount?: string;
}

export interface GetStatisticsByContactsListResponseEntriesUI extends GetStatisticsByContactsListResponseEntries {
  fio?: string;
  email?: string;
  phone?: string;
  discount?: string;
}

export interface GetStatisticsByContactsListResponseDtoUI extends GetStatisticsByContactsListResponseDto {
  entries?: Array<GetStatisticsByContactsListResponseEntriesUI>;
}
