import {
  CommitteeView,
  EditUserWithOrganisationUpdate,
  OrganisationJobTitle,
  UserProfileWithJobTitlesFull,
  UserProfileWithJobTitles,
  JobTitleView,
  OrganisationRolesJobTitles,
  UserProfileWithJobTitlesIEnumerableApiResponse,
} from '@src/api';

import { ContactUI } from './contact.model';

export interface UserResponseUI extends UserProfileWithJobTitlesIEnumerableApiResponse {
  result?: UserUI[];
}

export interface UserUI extends UserProfileWithJobTitlesFull, UserProfileWithJobTitles, EditUserWithOrganisationUpdate {
  fullName?: string;
  photo?: File;
  photoPath?: string;
  committees?: CommitteeView[];
  parentOrganisationId?: string;
  organisationId?: string;
  roles?: string[];
  permissions?: string[];
  rolesWithPermissions?: Record<string, string[]>;
  permissionsForOrganisations?: Record<string, string[]>;

  paymentDetailsId?: string; // TODO: delete when backend type fix
  paymentDetails?: File;
  deletePaymentDetailsId?: string | null;

  curatorId?: string;
  curatorFullName?: string;
  isFirstSignIn?: boolean;

  contacts?: ContactUI[];
}

export interface OrganisationJobTitleUI extends OrganisationJobTitle {
  jobTitles?: string[];
  jobTitlesFull?: JobTitleView[];
}

export interface OrganisationRolesJobTitlesFormData extends OrganisationRolesJobTitles {
  organisationName?: string;
  jobTitlesIds?: string[];
  jobTitlesDescription?: string;
}

export interface InviteUserUI extends UserUI {
  inviteStatus?: number | undefined;
}

export type UserSortType = 'byFullName' | 'byOrganisation' | 'byRegistrationDate';
