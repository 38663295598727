import { Multimedia } from '@src/models';

/**
 * Утилита для приведения типа File к Типу Multimedia
 *
 * @param {File} file Файл
 */
export const convertFileToMultimedia = (file: File): Multimedia => {
  return {
    file,
    source: { src: window.URL.createObjectURL(file) },
  };
};
