<div
  *ngIf="message"
  class="message-wrapper"
  [class.message-wrapper_system-message]="isSystemMessage"
  [class.message-wrapper_self-message]="message.isOutgoing"
  [id]="'message-wrapper_' + message.id"
>
  <div class="message">
    <ng-container *ngIf="isSystemMessage && message._ !== 'sponsoredMessage'">
      <app-message-notification
        [message]="message"
        (gotoPinnedMessage)="onGotoMessage($event)"
      ></app-message-notification>
    </ng-container>

    <ng-container *ngIf="!isSystemMessage">
      <ng-container [ngSwitch]="message.content._">
        <p *ngIf="!message.isOutgoing" class="message__sender">{{ message.senderId | senderFullName | async }}</p>
        <ng-container *ngIf="message._ !== 'sponsoredMessage' && message.replyToMessageId">
          <app-message-reply
            [chatId]="message.replyInChatId"
            [messageId]="message.replyToMessageId"
            (messageClicked)="onGotoMessage($event)"
          ></app-message-reply>
        </ng-container>
        <div class="message__body">
          <!-- TODO implement common message-formatted-text based on text / caption (instead of using ngTemplateOutlet) -->
          <app-message-animation *ngSwitchCase="'messageAnimation'" [message]="message.content">
            <ng-container *ngTemplateOutlet="formattedText; context: { $implicit: message.content }"></ng-container>
          </app-message-animation>

          <app-message-audio *ngSwitchCase="'messageAudio'" [message]="message.content">
            <ng-container *ngTemplateOutlet="formattedText; context: { $implicit: message.content }"></ng-container>
          </app-message-audio>

          <app-message-document *ngSwitchCase="'messageDocument'" [message]="message.content">
            <ng-container *ngTemplateOutlet="formattedText; context: { $implicit: message.content }"></ng-container>
          </app-message-document>

          <app-message-photo *ngSwitchCase="'messagePhoto'" [message]="message.content">
            <ng-container *ngTemplateOutlet="formattedText; context: { $implicit: message.content }"></ng-container>
          </app-message-photo>

          <app-message-sticker *ngSwitchCase="'messageSticker'" [message]="message.content"></app-message-sticker>

          <app-message-text *ngSwitchCase="'messageText'" [message]="message.content">
            <ng-container *ngTemplateOutlet="formattedText; context: { $implicit: message.content }"></ng-container>
          </app-message-text>

          <app-message-video *ngSwitchCase="'messageVideo'" [message]="message.content">
            <ng-container *ngTemplateOutlet="formattedText; context: { $implicit: message.content }"></ng-container>
          </app-message-video>

          <app-message-video-note
            *ngSwitchCase="'messageVideoNote'"
            [message]="message.content"
          ></app-message-video-note>

          <app-message-voice-note *ngSwitchCase="'messageVoiceNote'" [message]="message.content">
            <ng-container *ngTemplateOutlet="formattedText; context: { $implicit: message.content }"></ng-container>
          </app-message-voice-note>

          <span *ngSwitchDefault class="message__text">{{ message | messageNote | async }}</span>

          <ng-template #formattedText let-content>
            <app-message-formatted-text
              [formattedText]="content.caption || content.text"
              (sendCommandText)="emitBotTextCommand($event)"
              (clickUsername)="emitClickUsername($event)"
              (clickUserId)="emitClickUserId($event)"
            ></app-message-formatted-text>
          </ng-template>

          <div class="message__meta">
            <span *ngIf="message._ === 'sponsoredMessage'" class="message__label">
              {{ 'components.message.labels.sponsoredMessage' | translate }}
            </span>
            <lucide-icon *ngIf="message.isPinned" class="message__pin" name="pin"></lucide-icon>
            <span *ngIf="message.date" class="message__date">
              {{ message.editDate ? ('components.message.labels.edited' | translate) : '' }}
              {{ (message.editDate ? message.editDate : message.date) | timestampToDate : 'date' | date : 'HH:mm' }}
            </span>
            <mat-icon
              *ngIf="message.id && message.isOutgoing && lastReadOutboxMessageId !== undefined"
              class="message__status"
              [class.message__status_send]="message.id > lastReadOutboxMessageId"
              [class.message__status_read]="message.id <= lastReadOutboxMessageId"
            >
              {{ message.id > lastReadOutboxMessageId ? 'done' : 'done_all' }}
            </mat-icon>
          </div>
          <div *ngIf="message._ === 'sponsoredMessage'" class="message__button">
            <button mat-stroked-button class="button" color="primary" (click)="onClickSponsoredButton(message)">
              {{
                message.sponsorChatId > 0
                  ? ('common.buttons.goTo' | translate)
                  : ('components.message.buttons.openChannel' | translate)
              }}
            </button>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </div>
  <div *ngIf="message.replyMarkup && message.replyMarkup._ === 'replyMarkupInlineKeyboard'" class="message-buttons">
    <div class="message-buttons__row" *ngFor="let replyMarkupRow of message.replyMarkup.rows">
      <button
        *ngFor="let rowButton of replyMarkupRow"
        mat-button
        class="message-buttons__button"
        [title]="rowButton.text"
        (click)="message.id ? emitBotCommand({ messageId: message.id, type: rowButton.type }) : ''"
      >
        {{ rowButton.text }}
      </button>
    </div>
  </div>
</div>
