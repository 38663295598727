import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Injector,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';
import { BehaviorSubject, lastValueFrom } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TuiDialogService } from '@taiga-ui/core';
import { PolymorpheusComponent } from '@tinkoff/ng-polymorpheus';
import { ResizableBaseComponent } from '@src/app/components/resizable-base-component';
import { DialogConfirmComponent } from '@src/app/shared/dialogs';
import { BreakpointObserverHelperService, UserService, ERROR_TEXT_FOR_DELETE_USER } from '@src/core/services';
import { ViewMode, UserUI } from '@src/models';
import { UserProfileWithJobTitles } from '@src/api';
import { ObjectId } from '@src/types/id';
import { TranslateService } from '@ngx-translate/core';
import { PermissionService } from '@src/app/modules/organisations/services';

@Component({
  selector: 'app-organisation-personal',
  templateUrl: './organisation-personal.component.html',
  styleUrls: ['./organisation-personal.component.scss', '../styles/organisation-content.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrganisationPersonalComponent extends ResizableBaseComponent implements OnChanges {
  @Input() title?: string;
  @Input() organisationId?: string | null;
  @Input() selectedUserId: ObjectId;
  @Output() selectedUserIdChange = new EventEmitter<ObjectId>();

  users$: BehaviorSubject<UserProfileWithJobTitles[] | null> = this.userService.membersOfOrganisation$;

  allowEditing$ = this.permissionService.allowUserEditing$;

  userInfoMode: ViewMode = 'view';
  loading = false;

  private readonly confirmDialog = this.dialogService.open<boolean>(
    new PolymorpheusComponent(DialogConfirmComponent, this.injector),
    {
      label: this.translateService.instant('common.dialogs.userDeleteHeader'),
      size: 's',
      closeable: false,
    },
  );

  constructor(
    readonly cdr: ChangeDetectorRef,
    readonly breakpointObserver: BreakpointObserver,
    readonly breakpointObserverHelperService: BreakpointObserverHelperService,
    private userService: UserService,
    private readonly translateService: TranslateService,
    private readonly permissionService: PermissionService,
    @Inject(TuiDialogService) private readonly dialogService: TuiDialogService,
    @Inject(Injector) private readonly injector: Injector,
  ) {
    super(cdr, breakpointObserver, breakpointObserverHelperService);
  }

  get showFullPage(): boolean {
    return (!this.selectedUserId && this.userInfoMode !== 'create') || this.isDoubleExtraLargeScreen;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.organisationId) {
      this.getData();
    }
  }

  changeSelectedUserId(userId?: ObjectId) {
    this.selectedUserId = userId;
    this.selectedUserIdChange.emit(userId);
  }

  onClickAddButton(): void {
    this.changeSelectedUserId();
    this.userInfoMode = 'create';
    this.cdr.detectChanges();
  }

  onClickReturnButton(): void {
    this.changeSelectedUserId();
    this.cdr.detectChanges();
  }

  onChangeSelectedUserId(userId?: ObjectId): void {
    this.changeSelectedUserId(userId);
    this.userInfoMode = 'view';
    this.cdr.detectChanges(); // TODO: not work change edit => view
  }

  onCancelEditingUser(): void {
    this.userInfoMode = 'view';
  }

  onSaveEditingUser(): void {
    this.userInfoMode = 'view';
    this.getData();
  }

  onClickDeleteButton(user?: UserUI): void {
    const id = user?.id;
    if (!user?.organisations || !id) return;

    this.confirmDialog.pipe(takeUntil(this.destroyed$$)).subscribe({
      next: res => {
        if (res) {
          lastValueFrom(this.userService.editUser(user))
            .catch(err => {
              if (err.status === 492 || err === ERROR_TEXT_FOR_DELETE_USER) {
                lastValueFrom(this.userService.deleteUser(id)).then(() => {
                  this.getData();
                });
              }
            })
            .then(() => {
              this.changeSelectedUserId();
              this.getData();
            });
        }
      },
    });
  }

  private getData(): void {
    this.userService.resetMembersOfOrganisation();
    if (this.organisationId) {
      this.userService.getUsersByOrganisationId(this.organisationId, value => {
        this.loading = value;
        this.cdr.markForCheck();
      });
    }

    this.cdr.markForCheck();
  }
}
