import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { TuiContextWithImplicit, tuiPure, TuiStringHandler } from '@taiga-ui/cdk';
import { TranslateService } from '@ngx-translate/core';
import { ResizableBaseComponent } from '@src/app/components';
// TODO перенести во внутрь модуля
import { FIELD_TYPES, FieldType } from '@src/app/shared/field';
import { BreakpointObserver } from '@angular/cdk/layout';
import { BreakpointObserverHelperService } from '@src/core/services';

@Component({
  selector: 'contacts-type',
  templateUrl: './contacts-type.component.html',
  styleUrls: ['./contacts-type.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContactsTypeComponent extends ResizableBaseComponent {
  @Input() loading: boolean = false;
  contactTypes: FieldType[] = Object.values(FIELD_TYPES).filter(fieldType => fieldType.forContacts);

  constructor(
    readonly cdr: ChangeDetectorRef,
    readonly breakpointObserver: BreakpointObserver,
    readonly breakpointObserverHelperService: BreakpointObserverHelperService,
    private readonly translateService: TranslateService,
  ) {
    super(cdr, breakpointObserver, breakpointObserverHelperService);
  }

  @tuiPure
  contactTypesStringify(items: ReadonlyArray<FieldType>): TuiStringHandler<TuiContextWithImplicit<number>> {
    const map = new Map(items.map(({ id, description }) => [id, description] as [number, string]));

    return ({ $implicit }: TuiContextWithImplicit<number>) => this.translateService.instant(map.get($implicit) || ' ');
  }
}
