import { Component, OnInit, ChangeDetectionStrategy, Input, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { MessageContentUnion } from '@airgram/core/types/outputs';
import { MessageVoiceNote } from '@airgram/core/types/outputs/MessageContent';
import { FilePart } from '@airgram/web';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FileReaderService } from '@src/core/services';
import { MultimediaSource } from '@src/models';

@Component({
  selector: 'app-message-voice-note',
  templateUrl: './message-voice-note.component.html',
  styleUrls: ['../styles/message-content.scss', './message-voice-note.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MessageVoiceNoteComponent implements OnInit, OnDestroy {
  @Input() set message(mess: MessageContentUnion | undefined) {
    this.messageVoiceNote = mess as MessageVoiceNote;
  }

  messageVoiceNote?: MessageVoiceNote;
  messageVoiceSource?: MultimediaSource;

  private destroyed$$: Subject<void>;

  constructor(private cdr: ChangeDetectorRef, private fileReaderService: FileReaderService) {
    this.destroyed$$ = new Subject<void>();
  }

  ngOnInit(): void {
    this.initVoice();
  }

  ngOnDestroy(): void {
    this.destroyed$$.next();
    this.destroyed$$.complete();
  }

  private initVoice(): void {
    if (!this.messageVoiceNote) return;

    this.fileReaderService
      .getFilePart(this.messageVoiceNote?.voiceNote?.voice)
      .pipe(takeUntil(this.destroyed$$))
      .subscribe(filePart => this.setVoiceSource(filePart));
  }

  private setVoiceSource(filePart?: FilePart): void {
    this.messageVoiceSource = { src: this.fileReaderService.getFileSource(filePart) };
    this.cdr.markForCheck();
  }
}
