import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { TuiAvatarModule } from '@taiga-ui/kit';
import { TuiButtonModule, TuiLoaderModule, TuiScrollbarModule } from '@taiga-ui/core';
import { SharedModule } from '@src/app/shared';

import { ModernSearchUsersModule } from '../search-users';

import { UsersListComponent } from './users-list.component';

@NgModule({
  declarations: [UsersListComponent],
  imports: [
    CommonModule,
    MatButtonModule,
    MatGridListModule,
    MatIconModule,
    MatListModule,
    ModernSearchUsersModule,
    SharedModule,
    TuiAvatarModule,
    TuiButtonModule,
    TuiLoaderModule,
    TuiScrollbarModule,
  ],
  exports: [UsersListComponent],
})
export class UsersListModule {}
