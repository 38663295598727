<div *ngIf="value" class="question">
  <div class="question__answer-control">
    <div *ngIf="value.photoId">
      <app-photo mode="view" width="auto" height="auto" [photoId]="value.photoId"></app-photo>
    </div>

    <h3 class="text_size-m">
      <app-parse-links [value]="value.text"></app-parse-links>
    </h3>

    <ng-container [ngSwitch]="value.answerType?.type">
      <tui-input *ngSwitchCase="'text'" [tuiTextfieldSize]="'m'" [(ngModel)]="value.altAnswer">
        {{ 'components.question.fields.answer' | translate }}
      </tui-input>

      <tui-textarea
        *ngSwitchCase="'area'"
        [expandable]="true"
        [rows]="10"
        [tuiTextfieldSize]="'m'"
        [(ngModel)]="value.altAnswer"
      >
        {{ 'components.question.fields.answer' | translate }}
      </tui-textarea>

      <tui-select *ngSwitchCase="'select'" [(ngModel)]="value.altAnswer"></tui-select>

      <ng-container *ngSwitchCase="'file'">
        <tui-input-files
          *ngIf="!value.file"
          class="size_m"
          [(ngModel)]="value.file"
          [tuiTextfieldSize]="'m'"
          [size]="'m'"
        ></tui-input-files>
        <tui-files *ngIf="!!value.file">
          <tui-file [file]="value.file" (removed)="resetFile()"></tui-file>
        </tui-files>
      </ng-container>

      <div tuiGroup *ngSwitchCase="'date'">
        <tui-input-date [(ngModel)]="value.date" [tuiTextfieldSize]="'m'">
          {{ 'components.question.fields.selectDate' | translate }}
        </tui-input-date>

        <tui-input-time [(ngModel)]="value.time" [tuiTextfieldSize]="'m'" [items]="timeItems">
          {{ 'components.question.fields.selectTime' | translate }}
        </tui-input-time>
      </div>

      <div *ngSwitchCase="'radio'" class="answer">
        <ng-container *ngFor="let answer of value.answers">
          <tui-radio-block [size]="'s'" [(ngModel)]="value.answerOption" [item]="answer">
            {{ answer.textValue }}
          </tui-radio-block>
        </ng-container>
        <tui-input *ngIf="value.answerOption?.isAlternative" [tuiTextfieldSize]="'m'" [(ngModel)]="value.altAnswer">
          {{ 'components.question.fields.yourAnswer' | translate }}
        </tui-input>
      </div>

      <div *ngSwitchCase="'check'" class="answer">
        <tui-data-list>
          <tui-opt-group tuiMultiSelectGroup [(ngModel)]="value.answerOption">
            <button *ngFor="let answer of value.answers" tuiOption [size]="'m'" [value]="answer">
              {{ answer.textValue }}
            </button>
          </tui-opt-group>
        </tui-data-list>
        <tui-input
          *ngIf="isAlternativeSelected(value.answerOption)"
          [tuiTextfieldSize]="'m'"
          [(ngModel)]="value.altAnswer"
        >
          {{ 'components.question.fields.yourAnswer' | translate }}
        </tui-input>
      </div>

      <span *ngSwitchDefault>
        {{ 'components.question.labels.unknownResponseType' | translate }}
      </span>
    </ng-container>
  </div>
</div>
