import { environment } from '@src/environments/environment';

export const UNIONS_DOMAINS = environment.production
  ? ['lk', 'beta', 'test-unions', 'linux', 'demo-unions']
  : [
      'dev-unions',
      'test-unions',
      'test-unions-1',
      'test-unions-2',
      'test-unions-3',
      'test-unions-4',
      'linux',
      'demo-unions',
      'localhost',
      'ad',
      'ad-target',
    ];
