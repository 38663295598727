import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-whats-app-link',
  templateUrl: './whats-app-link.component.html',
  styleUrls: ['./whats-app-link.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WhatsAppLinkComponent {
  @Input() value?: string;
}
