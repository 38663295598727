import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { TuiSizeL, TuiSizeM, TuiSizeS } from '@taiga-ui/core';
import { parsePhoneNumber } from 'libphonenumber-js';
import { ViewMode } from '@src/models';
import { Nullable, Optional } from '@src/types/utils';

@Component({
  selector: 'app-field-phone',
  templateUrl: './field-phone.component.html',
  styleUrls: ['./field-phone.component.scss', '../styles/field-content.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FieldPhoneComponent {
  @Input() mode?: ViewMode = 'view';
  @Input() name?: string;
  @Input() set value(_value: Optional<Nullable<string>>) {
    this.text = _value;
    if (_value) {
      try {
        const phoneNumber = parsePhoneNumber(_value);
        if (phoneNumber.number) {
          this.phoneNumber = phoneNumber.number;
        }
      } catch (error) {}
    }
  }
  @Input() size: TuiSizeS | TuiSizeM | TuiSizeL = 'l';

  text: Optional<Nullable<string>>;
  phoneNumber: Optional<Nullable<string>>;
}
