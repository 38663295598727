<div class="message-video-record">
  <div class="message-video-record__message-content message-content">
    <div class="message-video-record__video-record-wrapper" [class.tui-skeleton]="!videoRecord.src">
      <video
        #videoRecord
        tuiMedia
        controls
        width="200"
        height="200"
        autoplay=""
        loop=""
        playsinline=""
        [src]="messageVideoNoteSource?.src | safe: 'url'"
      ></video>
    </div>
  </div>
</div>
