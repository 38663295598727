import { Component, OnInit, ChangeDetectionStrategy, Input, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { MessageContentUnion } from '@airgram/core/types/outputs';
import { MessageVideoNote } from '@airgram/core/types/outputs/MessageContent';
import { FilePart } from '@airgram/web';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FileReaderService } from '@src/core/services';
import { MultimediaSource } from '@src/models';

@Component({
  selector: 'app-message-video-note',
  templateUrl: './message-video-note.component.html',
  styleUrls: ['../styles/message-content.scss', './message-video-note.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MessageVideoNoteComponent implements OnInit, OnDestroy {
  @Input() set message(mess: MessageContentUnion | undefined) {
    this.messageVideoNote = mess as MessageVideoNote;
  }

  messageVideoNote?: MessageVideoNote;
  messageVideoNoteSource?: MultimediaSource;

  private destroyed$$: Subject<void>;

  constructor(private cdr: ChangeDetectorRef, private fileReaderService: FileReaderService) {
    this.destroyed$$ = new Subject<void>();
  }

  ngOnInit(): void {
    this.initVideoRecord();
  }

  ngOnDestroy(): void {
    this.destroyed$$.next();
    this.destroyed$$.complete();
  }

  private initVideoRecord(): void {
    if (!this.messageVideoNote) return;

    this.fileReaderService
      .getFilePart(this.messageVideoNote?.videoNote?.video)
      .pipe(takeUntil(this.destroyed$$))
      .subscribe(filePart => this.setVideoSource(filePart));
  }

  private setVideoSource(filePart?: FilePart): void {
    this.messageVideoNoteSource = { src: this.fileReaderService.getFileSource(filePart) };
    this.cdr.markForCheck();
  }
}
