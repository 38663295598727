import { CdkDragDrop } from '@angular/cdk/drag-drop';
import {
  Component,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { BusinessTypeUI, JobTitleUI } from '@src/models';

@Component({
  selector: 'app-accordion-selection',
  templateUrl: './accordion-selection.component.html',
  styleUrls: ['./accordion-selection.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccordionSelectionComponent implements OnChanges {
  @Input() title?: string;
  @Input() description?: string;
  @Input() items?: JobTitleUI[];
  @Input() searchItems?: JobTitleUI[];
  @Input() actionForAdmin: boolean = false;
  @Input() reorderButton: boolean = false;
  @Output() itemsChange: EventEmitter<JobTitleUI[]> = new EventEmitter();

  open: boolean = false;
  loading: boolean = false;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.items) {
      this.open = !!this.items;
      this.loading = !this.items;
    }
  }

  toggleVisibleItem(item: JobTitleUI | BusinessTypeUI) {
    item.isVisible = !item.isVisible;
    this.itemsChange.emit();
  }

  toggleIsAdminOnly(item: JobTitleUI) {
    item.isAdminOnly = !item.isAdminOnly;
    this.itemsChange.emit();
  }

  onDropVisibleItems(event: CdkDragDrop<string[]>) {
    if (!this.items) return;
    this.moveItem(this.items, event.previousIndex, event.currentIndex, this.searchItems);

    this.itemsChange.emit();
  }

  private moveItem(items: JobTitleUI[], previousIndex: number, currentIndex: number, searchItems?: JobTitleUI[]) {
    items.forEach(item => (item.sortOrder = (item.sortOrder ?? 0) * 10));

    if (searchItems) {
      const item = items.find(item => item.id === searchItems[previousIndex].id);

      if (item) {
        if (previousIndex < currentIndex) {
          item.sortOrder = (searchItems[currentIndex].sortOrder ?? 0) + 1;
        } else {
          item.sortOrder = (searchItems[currentIndex].sortOrder ?? 0) - 1;
        }
      }
      return;
    }

    if (previousIndex < currentIndex) {
      items[previousIndex].sortOrder = currentIndex * 10 + 1;
    } else {
      items[previousIndex].sortOrder = currentIndex * 10 - 1;
    }
  }
}
