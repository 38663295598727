import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { RouterModule } from '@angular/router';
import { TuiButtonModule } from '@taiga-ui/core';
import { TranslateModule } from '@ngx-translate/core';
import { IconModule } from '@src/app/modules/icon';

import { LoadedDirective, HiddenDirective } from './directives';
import {
  ActionStatusPipe,
  CalendarPipe,
  DatePeriodPipe,
  DateToTuiDateTimePipe,
  FileSizePipe,
  GetCommitteePathPipe,
  GetImageSrcPipe,
  InviteStatusPipe,
  JobTitlesPipe,
  MessagePipe,
  MessageNotePipe,
  MessagesGroupingPipe,
  NumeralPluralsPipe,
  SafePipe,
  SenderFullNamePipe,
  StringToDatePipe,
  TimestampToDatePipe,
  UrlPipe,
  UserStatusPipe,
  UserDisplayableNamePipe,
  OrgJobTitleDisplayableNamePipe,
} from './pipes';

@NgModule({
  declarations: [
    ActionStatusPipe,
    MessagePipe,
    MessageNotePipe,
    SenderFullNamePipe,
    CalendarPipe,
    DatePeriodPipe,
    DateToTuiDateTimePipe,
    FileSizePipe,
    GetCommitteePathPipe,
    GetImageSrcPipe,
    InviteStatusPipe,
    JobTitlesPipe,
    MessagesGroupingPipe,
    NumeralPluralsPipe,
    SafePipe,
    StringToDatePipe,
    TimestampToDatePipe,
    UrlPipe,
    UserStatusPipe,
    UserDisplayableNamePipe,
    OrgJobTitleDisplayableNamePipe,
    LoadedDirective,
    HiddenDirective,
  ],
  imports: [CommonModule, TuiButtonModule, RouterModule, IconModule],
  exports: [
    ActionStatusPipe,
    CalendarPipe,
    DatePeriodPipe,
    DateToTuiDateTimePipe,
    FileSizePipe,
    GetCommitteePathPipe,
    GetImageSrcPipe,
    InviteStatusPipe,
    JobTitlesPipe,
    MessagePipe,
    MessageNotePipe,
    MessagesGroupingPipe,
    NumeralPluralsPipe,
    SafePipe,
    SenderFullNamePipe,
    StringToDatePipe,
    TimestampToDatePipe,
    UrlPipe,
    UserStatusPipe,
    UserDisplayableNamePipe,
    OrgJobTitleDisplayableNamePipe,
    TranslateModule,
    LoadedDirective,
    HiddenDirective,
  ],
  providers: [
    DatePipe,
    DateToTuiDateTimePipe,
    JobTitlesPipe,
    NumeralPluralsPipe,
    TimestampToDatePipe,
    FileSizePipe,
    UrlPipe,
  ],
})
export class SharedModule {}
