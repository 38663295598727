import { Injectable } from '@angular/core';
import { of, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { InvitesService as ApiInvitesService } from '@src/api';
import { AlertService } from '@src/core/services/alert.service';

@Injectable({
  providedIn: 'root',
})
export class InvitesService {
  constructor(private invitesService: ApiInvitesService, private readonly alertService: AlertService) {}

  getInvate(inviteId: string) {
    return this.invitesService.invite(inviteId).pipe(
      catchError(err => {
        // TODO show error notification
        return throwError(err);
      }),
    );
  }

  getInvitesForCurrentUser() {
    return this.invitesService.invites().pipe(
      catchError(err => {
        // TODO show error notification
        return throwError(err);
      }),
    );
  }

  addInvite(chatId: number, chatTitle: string, userIds: string[], organisationId: string, inviteCode: string) {
    return this.invitesService.addInvite({ chatId, chatTitle, userIds, organisationId, inviteCode }).pipe(
      catchError(err => {
        // TODO show error notification
        return throwError(err);
      }),
    );
  }

  getInvitesByChatId(chatId: number) {
    return this.invitesService.inviteByChatId(chatId).pipe(
      catchError(err => {
        this.alertService.error('Ошибка загрузки приглашений чата');
        return of(null);
      }),
    );
  }

  /**
   * Обновить статус инвайта.
   * @param chatId id чата.
   * @param isAccepted Статус; если TRUE, то проставить inviteStatus = 2 (принял). если FALSE - inviteStatus = 3 (отклонил)
   * @return Success
   */
  updateStatus(chatId: number, isAccepted: boolean) {
    return this.invitesService.updateStatus({ chatId, isAccepted }).pipe(
      catchError(err => {
        // TODO show error notification
        return throwError(err);
      }),
    );
  }
}
