<div class="photo-edit" [class.tui-skeleton]="loading" [class.tui-skeleton_rounded]="loading">
  <tui-avatar
    class="image"
    [size]="size"
    [ngStyle]="{ width, height }"
    [rounded]="rounded"
    [avatarUrl]="getPhotoUrl() | safe : 'url'"
    [text]="text || ''"
    (click)="onClickPhoto($event)"
  >
    <div class="file-input">
      <input #photoFileInput type="file" accept="image/png, image/jpeg" (change)="photoFileInputChange($event)" />
    </div>
  </tui-avatar>

  <lucide-icon
    *ngIf="getPhotoUrl()"
    name="x"
    class="delete-button"
    title="Удалить"
    (click)="onPhotoDelete()"
  ></lucide-icon>
</div>
