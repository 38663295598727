import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { TuiSizeL, TuiSizeM, TuiSizeS } from '@taiga-ui/core';
import { ViewMode } from '@src/models';

@Component({
  selector: 'field-new-text',
  templateUrl: './field-new-text.component.html',
  styleUrls: ['./field-new-text.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FieldNewTextComponent {
  @Input() mode?: ViewMode = 'view';
  @Input() name?: string;
  @Input() value?: string | null;
  @Input() size: TuiSizeS | TuiSizeM | TuiSizeL = 'l';
}
