/**
 * Unions API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { UserJobTitlesForOrganisation } from './userJobTitlesForOrganisation';


/**
 * Данные по организации и долдностям в ней.
 */
export interface OrganisationWJobTitles { 
    /**
     * ID организации.
     */
    organisationId?: string;
    /**
     * Наименование организации.
     */
    organisationName?: string;
    /**
     * Должности в организации массивом.
     */
    jobTitles?: Array<string>;
    /**
     * Должности в организации.
     */
    userJobTitlesForOrganisation?: Array<UserJobTitlesForOrganisation>;
    /**
     * Организация по-умолчанию.
     */
    isDefault?: boolean;
}

