import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';
import { MessageContentUnion } from '@airgram/web';
import { TelegramMessengerService } from '@src/app/modules/telegram';
import { MessageModel, SponsoredMessageUI } from '@src/models';

@Component({
  selector: 'app-message-notification',
  templateUrl: './message-notification.component.html',
  styleUrls: ['../styles/message-content.scss', './message-notification.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MessageNotificationComponent {
  @Input() set message(mess: MessageModel | undefined) {
    this.content = mess?.content;

    const senderId = mess?.senderId;
    this.whoId = senderId?._ === 'messageSenderUser' ? senderId.userId : senderId?.chatId;

    if (this.content?._ === 'messageChatAddMembers') {
      this.chatMembersIds = this.content.memberUserIds;
    }

    if (this.content?._ === 'messageChatDeleteMember') {
      this.chatMembersIds = [this.content.userId];
    }

    if (this.content?._ === 'messagePinMessage' && mess) {
      this.pinnedMessage$ = this.messengerService.api.getMessage(mess.chatId, this.content.messageId);
    }
  }

  @Output() gotoPinnedMessage: EventEmitter<number>;

  content?: MessageContentUnion;
  whoId?: number;
  chatMembersIds?: number[];
  pinnedMessage$?: Promise<MessageModel | SponsoredMessageUI>;

  constructor(private messengerService: TelegramMessengerService) {
    this.gotoPinnedMessage = new EventEmitter();
  }

  onClickPinnedMessage(messageId: number) {
    this.gotoPinnedMessage.emit(messageId);
  }
}
