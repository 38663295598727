import { FormattedTextUnion } from '@airgram/web';

import { TextFormatter } from './text-formatter';

export class TextFormatterHtml {
  /**
   * Преобразовывает FormattedTextUnion в Html
   *
   * @param formattedText структура, которую будем парсить
   */
  static parse(formattedText?: FormattedTextUnion): string {
    if (!formattedText) {
      return '';
    }

    const parsedFormattedMessage = TextFormatter.parse(formattedText);

    let parsedHtml = '';

    parsedFormattedMessage.map(formattedMessage => {
      let renderedContent = formattedMessage.text;

      formattedMessage.joinedTypes.split(' ').forEach(type => {
        switch (type) {
          case 'textEntityTypeBold':
            renderedContent = `<b>${renderedContent}</b>`;
            break;
          case 'textEntityTypeItalic':
            renderedContent = `<i>${renderedContent}</i>`;
            break;
          case 'textEntityTypeUnderline':
            renderedContent = `<u>${renderedContent}</u>`;
            break;
          case 'textEntityTypeStrikethrough':
            renderedContent = `<s>${renderedContent}</s>`;
            break;
          default:
            break;
        }
      });

      parsedHtml += renderedContent;
    });

    return parsedHtml;
  }
}
