import { animate, group, style } from '@angular/animations';

export const TRANSLATE_TOP = [
  style({ transform: 'translateY(50px)', opacity: 0 }),
  group([
    animate(
      '0.3s 0.1s ease',
      style({
        transform: 'translateY(0)',
      }),
    ),
    animate('0.3s ease', style({ opacity: 1 })),
  ]),
];
