import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatListModule } from '@angular/material/list';
import {
  TuiAvatarModule,
  TuiInputModule,
  TuiInputCountModule,
  TuiInputDateModule,
  TuiInputNumberModule,
} from '@taiga-ui/kit';
import {
  TuiButtonModule,
  TuiGroupModule,
  TuiHostedDropdownModule,
  TuiLoaderModule,
  TuiTextfieldControllerModule,
  TuiScrollbarModule,
  TuiHintModule,
  TuiDropdownModule,
} from '@taiga-ui/core';
import { NgxPermissionsModule } from 'ngx-permissions';
import { NgxScrollPositionRestorationModule } from 'ngx-scroll-position-restoration';
import { GroupInfoModule } from '@src/app/modules/group-info';
import { ListModule } from '@src/app/shared/list';
import { OrganisationInfoModule } from '@src/app/modules/organisation-info';
import { SearchGroupsModule } from '@src/app/shared/search-groups';
import { UserInfoModule } from '@src/app/modules/user-info';
import { SharedModule } from '@src/app/shared';
import { ComponentsModule } from '@src/app/components';
import { CustomNamesTabsModule } from '@src/app/modules/custom-name-tabs';

import { OrganisationRouting } from './organisation.routing';
import { OrganisationComponent } from './organisation.component';
import { OrganisationChannelsComponent } from './organisation-channels/organisation-channels.component';
import { OrganisationPersonalComponent } from './organisation-personal/organisation-personal.component';
import { OrganisationCommitteesComponent } from './organisation-committees/organisation-committees.component';
import { OrganisationConfigsComponent } from './organisation-configs/organisation-configs.component';

@NgModule({
  declarations: [
    OrganisationChannelsComponent,
    OrganisationComponent,
    OrganisationPersonalComponent,
    OrganisationCommitteesComponent,
    OrganisationConfigsComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    GroupInfoModule,
    ListModule,
    MatButtonModule,
    MatIconModule,
    MatInputModule,
    MatFormFieldModule,
    MatListModule,
    NgxPermissionsModule,
    OrganisationInfoModule,
    OrganisationRouting,
    ReactiveFormsModule,
    RouterModule,
    SearchGroupsModule,
    SharedModule,
    TuiAvatarModule,
    TuiButtonModule,
    TuiGroupModule,
    TuiInputModule,
    TuiInputCountModule,
    TuiInputDateModule,
    TuiHintModule,
    TuiHostedDropdownModule,
    TuiInputNumberModule,
    TuiLoaderModule,
    TuiScrollbarModule,
    TuiTextfieldControllerModule,
    UserInfoModule,
    TuiDropdownModule,
    ComponentsModule,
    CustomNamesTabsModule,
    NgxScrollPositionRestorationModule,
  ],
  exports: [OrganisationComponent],
})
export class OrganisationModule {}
