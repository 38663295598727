import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { UserUnion } from '@airgram/web';
import { ChatModel } from '@src/models';
import { TelegramMessengerService } from '@src/app/modules/telegram';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'telegram-bot',
  templateUrl: './bot.component.html',
  styleUrls: ['./bot.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BotComponent {
  @Input() bot?: UserUnion;
  @Input() chat?: ChatModel;
  organisation: any;
  botStatus: string = this.translateService.instant('common.labels.bot');

  constructor(
    private cdr: ChangeDetectorRef,
    private messengerService: TelegramMessengerService,
    private readonly translateService: TranslateService,
  ) {}

  onChangeNotificationsToggleValue() {
    if (this.chat?.id) {
      this.messengerService.api
        .setChatNotificationSettings(this.chat.id, this.chat.notificationSettings.muteFor !== 0)
        .then(() => this.cdr.markForCheck());
    }
  }
}
