<mat-list-option
  class="list-item list-item-polls"
  [class.list-item-polls_invitation]="isInvitation()"
  [class.list-item-polls_created]="data?.createdBy === authUserId"
  [value]="data?.id"
  [selected]="!loading && selectedId === data?.id"
>
  <div
    matListIcon
    class="list-item__image list-item__image_large"
    [class.tui-skeleton]="loading"
    [class.tui-skeleton_rounded]="loading"
  >
    <tui-avatar
      size="m"
      [autoColor]="!data?.photoId"
      [rounded]="true"
      [avatarUrl]="data?.photoId | getImageSrc"
      [text]="data?.titleText || ''"
    ></tui-avatar>
  </div>
  <div class="list-item__text-box">
    <div class="header">
      <p
        class="list-item__text list-item__text_grey"
        [class.tui-skeleton]="loading"
        [class.tui-skeleton_text]="loading"
      >
        {{ data?.dateStart | stringToDate | datePeriod : (data?.dateEnd | stringToDate) : false }}
      </p>
      <app-badge
        *ngIf="badgeOptions"
        [size]="badgeOptions.size"
        [color]="badgeOptions.color"
        [value]="badgeOptions.value"
      ></app-badge>
    </div>
    <h3
      class="list-item__title list-item__title_line list-item__title_line_3"
      [title]="data?.titleText"
      [class.tui-skeleton]="loading"
      [class.tui-skeleton_text]="loading"
    >
      {{ data?.titleText }}
    </h3>
    <p
      *ngIf="data?.descriptionText"
      class="list-item__text list-item__text_grey"
      [title]="data?.descriptionText"
      [class.tui-skeleton]="loading"
      [class.tui-skeleton_text]="loading"
    >
      {{ data?.descriptionText }}
    </p>
  </div>
</mat-list-option>
