/**
 * Unions API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 */
export interface UpdateStatus { 
    /**
     * ID группового чата.
     */
    chatId?: number;
    /**
     * Статус; если TRUE, то проставить inviteStatus = 2 (принял). если FALSE - inviteStatus = 3 (отклонил)
     */
    isAccepted?: boolean;
}

