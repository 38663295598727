import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { LabelItemDefaultView, LabelItemForUnionView } from '@src/api';

import { NavigationItemNames } from '../navigation';

export enum VisibilityEnum {
  'INVISIBLE' = 'nobody',
  'ADMINISTRATORS' = 'adminOnly',
  'ALL' = 'all',
}
export const VisibilityNames = {
  [VisibilityEnum.INVISIBLE]: marker('components.customName.constants.visibilityOptions.invisible'),
  [VisibilityEnum.ADMINISTRATORS]: marker('components.customName.constants.visibilityOptions.administrators'),
  [VisibilityEnum.ALL]: marker('components.customName.constants.visibilityOptions.all'),
};

export type ObjectType = 'organisation' | 'committee' | 'navigation';

export type CustomNamesItem = LabelItemDefaultView & LabelItemForUnionView & { defaultTitle?: string };

export type CodeName =
  | NavigationItemNames
  | 'field.jobTitle'
  | 'field.committeeManager'
  | 'label.committeeMembers'
  | 'label.organisationMembers'
  | 'label.userProfileAttachment';
