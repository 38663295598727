<div class="users-tags-wrapper">
  <button
    *ngIf="items.length > 0"
    class="users-tags__close-btn users-tags__remove-all-btn"
    (click)="removeAllItems.emit()"
  >
    <tui-svg src="tuiIconCloseLarge"></tui-svg>
  </button>

  <tui-scrollbar class="users-tags">
    <div class="users-tags__list">
      <div class="users-tags__item" *ngFor="let item of items" [title]="item.fullName">
        <span class="tui-text_body-s-2">
          {{ item.fullName }}
        </span>

        <button class="users-tags__close-btn" (click)="removeItem.emit(item)">
          <tui-svg src="tuiIconCloseLarge"></tui-svg>
        </button>
      </div>
    </div>
  </tui-scrollbar>
</div>
