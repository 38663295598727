import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { OrganisationUI } from '@src/models';

@Component({
  selector: 'render-organisation-row',
  templateUrl: './render-organisation-row.component.html',
  styleUrls: ['./render-organisation-row.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RenderOrganisationRowComponent {
  @Input() organisation?: OrganisationUI;
}
