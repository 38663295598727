import { Injectable } from '@angular/core';
import { ChatMember } from '@airgram/web';
import { TelegramMessengerService } from '@src/app/modules/telegram';
import { AlertService, InvitesService, UserService } from '@src/core/services';
import { ChatModel, GroupUI, UserUI } from '@src/models';
import { pause } from '@src/utils';
import { TranslateService } from '@ngx-translate/core';
import { lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class GroupInfoService {
  constructor(
    private messengerService: TelegramMessengerService,
    private userService: UserService,
    private invatesService: InvitesService,
    private readonly alertService: AlertService,
    private readonly translateService: TranslateService,
  ) {}

  async createGroup(data: GroupUI): Promise<GroupUI | undefined> {
    if (!data.name) return;

    const chat = await this.messengerService.createNewSupergroupChat(data.name, data.isChannel, data.description);
    if (!chat) return;

    const supergroupId = chat.type?._ === 'chatTypeSupergroup' ? chat.type.supergroupId : undefined; // TODO: add other type

    if (!supergroupId) return;

    const supergroupFullInfo = await this.messengerService.api.getSupergroupFullInfo(supergroupId);
    data.inviteLink = supergroupFullInfo.inviteLink?.inviteLink;
    data.chatId = chat.id;
    data.type = chat.type;

    if (data.photo) {
      await this.messengerService.api.setChatPhoto(chat.id, data.photo);
    }

    if (data.members?.length) {
      await this.addMembers(chat, data.members, data.organisationId);
    }

    return data;
  }

  async editGroup(data: GroupUI): Promise<void> {
    if (!data.name) return;
    if (!data.chatId) return;

    await this.messengerService.api.openChat(data.chatId);
    await this.messengerService.api.setChatTitle(data.chatId, data.name);
    await this.messengerService.api.setChatDescription(data.chatId, data.description);
    if (data.photo) {
      await this.messengerService.api.setChatPhoto(data.chatId, data.photo);
    }
  }

  async deleteGroup(chatId: number): Promise<boolean> {
    const chat = await this.messengerService.api.getChat(chatId);
    if (chat.type._ === 'chatTypeBasicGroup') {
      await this.messengerService.api.deleteChat(chatId);
      return true;
    } else if (chat.type._ === 'chatTypeSupergroup') {
      const superGroup = await this.messengerService.api.getSupergroupFullInfo(chat.type.supergroupId);
      if (superGroup.memberCount < 1000) {
        await this.messengerService.api.deleteChat(chatId);
        return true;
      } else {
        this.alertService.warning(
          this.translateService.instant('components.groupInfo.alerts.warnings.tooManyMembers'),
          {
            label: this.translateService.instant('components.groupInfo.dialogs.alertHeader'),
            autoClose: false,
          },
        );
      }
    }
    return false;
  }

  async addMembers(chat: ChatModel, users: UserUI[], organisationId?: string) {
    let usersTelegramIds: number[] = [];
    let membersTelegramIds: number[] = [];
    let inviteLink: string | undefined;

    if (users?.length) {
      usersTelegramIds = users.map(user => user.telegramId).filter(id => Boolean(id)) as number[];
      usersTelegramIds = usersTelegramIds.filter(
        userTelegramId => userTelegramId !== this.userService.authUser$.value?.telegramId,
      );
    }

    if (usersTelegramIds.length) {
      if (chat.type._ === 'chatTypeSupergroup') {
        const bundlesIds: number[][] = [];
        const tempMembersIds = [...usersTelegramIds];
        let bundleSize = 20;
        if (chat.type.isChannel) {
          bundleSize = 100;
        }

        while (tempMembersIds.length) bundlesIds.push(tempMembersIds.splice(0, bundleSize));

        for (const bundleIds of bundlesIds) {
          const res = await this.messengerService.api.addChatMembers(chat.id, bundleIds);
          if (!res) {
            await this.addChatMembersOneAtTime(chat.id, bundleIds);
          }
          // TODO: реализовать обновление списка участников
          await pause(300);
        }
      } else if (chat.type._ === 'chatTypeBasicGroup') {
        await this.addChatMembersOneAtTime(chat.id, usersTelegramIds);
      }
    }

    if (!organisationId) {
      organisationId = this.userService.authUser$.value?.organisationId;

      if (!organisationId) return;
    }

    if (chat.type._ === 'chatTypeBasicGroup') {
      const basicGroup = await this.messengerService.api.getBasicGroupFullInfo(chat.type.basicGroupId);
      membersTelegramIds = this.getTelegramIdMembers(basicGroup.members);
      inviteLink = basicGroup.inviteLink?.inviteLink;
    }

    if (chat.type._ === 'chatTypeSupergroup') {
      const superGroup = await this.messengerService.api.getSupergroupFullInfo(chat.type.supergroupId);
      inviteLink = superGroup.inviteLink?.inviteLink;
      const superGroupMembers = await this.messengerService.api.getSupergroupMembers(chat.type.supergroupId);
      membersTelegramIds = this.getTelegramIdMembers(superGroupMembers.members);

      const limit = 200;
      if (superGroup.memberCount > limit) {
        for (let i = 1; i < Math.ceil(superGroup.memberCount / limit); i++) {
          const updatedSupergroupMembers = await this.messengerService.api.getSupergroupMembers(
            chat.type.supergroupId,
            limit * i,
            limit,
          );
          membersTelegramIds = [
            ...new Set(membersTelegramIds.concat(...this.getTelegramIdMembers(updatedSupergroupMembers.members))),
          ];
        }
      }
    }

    const invateUsersIds = users
      .filter(
        user =>
          !user.telegramId ||
          (!membersTelegramIds.includes(user.telegramId) && user.id !== this.userService.authUser$.value?.id),
      )
      .map(user => user.id)
      .filter(id => id !== undefined) as string[];

    if (inviteLink) {
      await lastValueFrom(
        this.invatesService.addInvite(chat.id, chat.title, invateUsersIds, organisationId, inviteLink),
      );
    }

    return;
  }

  async getInviteLink(chatId: number): Promise<string | undefined> {
    let inviteLink: string | undefined;
    const chatAdministrators = await this.messengerService.api.getChatAdministrators(chatId);

    if (
      chatAdministrators.find(
        chatAdmin => chatAdmin.isOwner && chatAdmin.userId === this.userService.authUser$.value?.telegramId,
      )
    ) {
      const chat = await this.messengerService.api.getChat(chatId);
      if (chat.type._ === 'chatTypeBasicGroup') {
        const basicGroup = await this.messengerService.api.getBasicGroupFullInfo(chat.type.basicGroupId);
        inviteLink = basicGroup.inviteLink?.inviteLink;
      } else if (chat.type._ === 'chatTypeSupergroup') {
        const superGroup = await this.messengerService.api.getSupergroupFullInfo(chat.type.supergroupId);
        inviteLink = superGroup.inviteLink?.inviteLink;
      }
    }

    return inviteLink;
  }

  private getTelegramIdMembers(members: ChatMember[]): number[] {
    const membersIds: number[] = [];
    for (const member of members) {
      const userTelegramId =
        member.memberId._ === 'messageSenderUser' ? member.memberId.userId : member.memberId.chatId;
      membersIds.push(userTelegramId);
    }

    return membersIds;
  }

  private async addChatMembersOneAtTime(chatId: number, usersTelegramIds: number[]) {
    for (const userTelegramId of usersTelegramIds) {
      await this.messengerService.api.addChatMember(chatId, userTelegramId, 100);
      // TODO: реализовать обновление списка участников
      await pause(300);
    }
  }
}
