import { LoyaltyProgramGrade, LoyaltyProgramStatus } from '../models';

import { LoyaltyProgramProductWithPagingResult, LoyaltyRating, LoyaltyStatus } from './types';

export const LOYALTY_STATUSES_MAP: Record<LoyaltyStatus, LoyaltyProgramStatus> = {
  [LoyaltyStatus.Deleted]: 'deleted',
  [LoyaltyStatus.Published]: 'published',
  [LoyaltyStatus.Review]: 'review',
  [LoyaltyStatus.Declined]: 'declined',
  [LoyaltyStatus.Archive]: 'archive',
} as const;

export const LOYALTY_GRADES_MAP: Record<LoyaltyRating, LoyaltyProgramGrade> = {
  [LoyaltyRating.Usual]: 'usual',
  [LoyaltyRating.Popular]: 'popular',
  [LoyaltyRating.Notable]: 'notable',
  [LoyaltyRating.Bestseller]: 'bestseller',
} as const;

export const DEFAULT_ITEMS_WITH_PAGING: LoyaltyProgramProductWithPagingResult = {
  items: null,
  hasMore: false,
} as const;

// Кол-во элементов на странице для пагинации
export const ITEMS_PER_PAGE_BY_DEFAULT = 20;
