import { SelectAllLinkMode } from '../components/select-all-link';
import { ItemWithId } from '../types';

export const addOrRemoveItemsToResult = <T extends ItemWithId>(
  mode: SelectAllLinkMode,
  items: T[],
  result: T[],
): T[] => {
  if (mode === 'select-all') {
    return result.concat(items.filter(item => !result.some(resultItem => resultItem.id === item.id)));
  }

  return result.filter(resultItem => !items.some(item => item.id === resultItem.id));
};
