import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Injector,
  Input,
  Output,
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { BreakpointObserver } from '@angular/cdk/layout';
import { takeUntil } from 'rxjs';
import { TuiDialogService } from '@taiga-ui/core';
import { PolymorpheusComponent } from '@tinkoff/ng-polymorpheus';
import { TranslateService } from '@ngx-translate/core';
import { ResizableBaseComponent } from '@src/app/components';
import { BreakpointObserverHelperService, OrganisationService, UserService } from '@src/core/services';
import { CustomNamesService } from '@src/app/modules/custom-name-tabs';
import { EnvService } from '@src/app/modules/env';
import { LogoutService } from '@src/app/modules/auth';
import { DialogConfirmComponent } from '@src/app/shared/dialogs';
import { Optional } from '@src/types/utils';

import { NavigationItem, NavigationMode, NavigationTree } from '../types';
import { NAVIGATION_ITEMS } from '../constants';

@Component({
  selector: 'app-navigation-list',
  templateUrl: './navigation-list.component.html',
  styleUrls: ['./navigation-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavigationListComponent extends ResizableBaseComponent {
  @Input() isOpen?: boolean = true;
  @Input() descriptionVisible: boolean = false;
  @Input() navTree: NavigationTree[] = [];
  @Input() mode?: NavigationMode = 'desktop';
  @Input() selectedItem?: NavigationItem;
  @Output() selectedItemChange = new EventEmitter<Optional<NavigationItem>>();

  items?: NavigationItem[];

  readonly parentOrganisations$ = this.organisationService.parentOrganisations$;
  readonly authUser$ = this.userService.authUser$;
  readonly allCustomNames$ = this.customNamesService.allCustomNames$;

  private readonly confirmDialog = this.dialogService.open<boolean>(
    new PolymorpheusComponent(DialogConfirmComponent, this.injector),
    {
      label: this.translateService.instant('common.dialogs.logOutHeader'),
      size: 's',
      closeable: false,
    },
  );

  constructor(
    readonly cdr: ChangeDetectorRef,
    readonly breakpointObserver: BreakpointObserver,
    readonly breakpointObserverHelperService: BreakpointObserverHelperService,
    private readonly organisationService: OrganisationService,
    private readonly userService: UserService,
    private readonly customNamesService: CustomNamesService,
    private readonly logoutService: LogoutService,
    private readonly env: EnvService,
    private readonly translateService: TranslateService,
    private readonly router: Router,
    @Inject(TuiDialogService) private readonly dialogService: TuiDialogService,
    @Inject(Injector) private readonly injector: Injector,
  ) {
    super(cdr, breakpointObserver, breakpointObserverHelperService);

    this.router.events.pipe(takeUntil(this.destroyed$$)).subscribe(routerEvent => {
      if (routerEvent instanceof NavigationEnd) {
        this.setSelectedItem();
        this.cdr.markForCheck();
      }
    });
  }

  protected afterChangeScreenType() {
    this.initNavigation();
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.allCustomNames$.pipe(takeUntil(this.destroyed$$)).subscribe(allCustomNames => {
      if (!allCustomNames?.length) return;

      this.initNavigation();
    });

    this.parentOrganisations$.pipe(takeUntil(this.destroyed$$)).subscribe(() => {
      this.customNamesService.getData();
    });

    this.cdr.markForCheck();
  }

  private initNavigation(): void {
    this.items = NAVIGATION_ITEMS.filter(
      navItem => !!this.navTree.find(navTreeItem => navTreeItem.itemName === navItem.id),
    )
      .filter(navItem => {
        if (navItem.id === 'menu.curator') {
          return !!this.authUser$.value?.curatorId;
        }

        if (navItem.id === 'menu.folders' && this.env.isBot) {
          return false;
        }

        return true;
      })
      .map(navItem => {
        navItem.title = this.customNamesService.getItem(navItem.id)?.title ?? navItem.title;

        if (navItem.id === 'menu.curator') {
          navItem.action = () => this.onClickCuratorButton();
        }

        if (navItem.id === 'menu.logout') {
          navItem.action = () => this.onClickExitButton();
        }

        return navItem;
      });

    this.setSelectedItem();

    this.cdr.markForCheck();
  }

  private onClickCuratorButton(): void {
    this.userService.communicationWithCurator();
  }

  private onClickExitButton(): void {
    this.confirmDialog.pipe(takeUntil(this.destroyed$$)).subscribe({
      next: res => {
        if (res) {
          this.logoutService.logout();
        }
      },
    });
  }

  private setSelectedItem() {
    this.selectedItem = this.items?.find(item => item.link && this.router.url?.includes(item.link));
    this.selectedItemChange.emit(this.selectedItem);
  }
}
