import { Component, Input } from '@angular/core';
import { ChatModel } from '@src/models';
import { ObjectId } from '@src/types/id';

import { ItemType } from '../model/item.model';

@Component({
  selector: 'app-list-item-chats',
  templateUrl: './list-item-chats.component.html',
  styleUrls: ['../styles/list-item.component.scss', './list-item-chats.component.scss'],
})
export class ListItemChatsComponent {
  @Input() set item(data: ItemType | undefined) {
    this.data = data as ChatModel;
  }

  @Input() selectedId: ObjectId;
  @Input() loading?: boolean;

  data?: ChatModel;
}
