import { APP_CONFIG } from '@src/core';
import { GetImageType } from '@src/models/images';

/**
 * Получение ссылки на изображение
 * @param id идентификатор изображения
 * @param alt альтернативный путь
 * @param size размер изображения
 * @param unionId идентификатор-признак ассоциации из сторонней БД
 */
export const getImageSrc = (
  id?: string,
  alt = '',
  size: GetImageType = 'thumbnail',
  unionId?: string | null,
): string => {
  const apiUrl = size === 'fullSize' ? '/api/Photo/fullSize' : '/api/Photo/thumbnail';
  const unionQueryParam = unionId ? `?unionId=${unionId}` : '';
  return id ? `${APP_CONFIG.baseUrl}${apiUrl}/${id}${unionQueryParam}` : alt;
};
