import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { AbstractTuiControl } from '@taiga-ui/cdk';
import { ContactUI } from '@src/models';
import { Nullable, Optional } from '@src/types/utils';

@Component({
  selector: 'app-contacts',
  templateUrl: './contacts.component.html',
  styleUrls: ['./contacts.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContactsComponent extends AbstractTuiControl<Array<ContactUI>> {
  @Input() loading = false;
  @Input() allowCreating: Optional<Nullable<boolean>> = false;
  @Input() allowEditing: Optional<Nullable<boolean>> = false;
  @Input() allowDeleting: Optional<Nullable<boolean>> = false;
  @Input() allowFilesDeleting: Optional<Nullable<boolean>> = true;
  @Input() isModeration: Optional<Nullable<boolean>> = false;
  @Input() showFieldCounter: Optional<Nullable<boolean>> = true; // TODO: Убрать и использовать allowEditing
  @Input() showFieldSeparator: Optional<Nullable<boolean>> = true; // TODO: Убрать и использовать allowEditing
  @Input() validateForm: Optional<Nullable<boolean>> = true;
  @Input() set acceptFiles(value: Optional<Array<string>>) {
    this.accept = value ? value.join(',') : '';
  }

  accept = '';

  get focused(): boolean {
    return false;
  }

  protected getFallbackValue(): Array<ContactUI> {
    return [];
  }
}
