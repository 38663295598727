<div class="custom-names-tabs" [formGroup]="form">
  <div class="custom-names-tabs__header">
    <tui-tabs *ngIf="localizations.length > 1" class="tabs">
      <button
        *ngFor="let localization of localizations"
        class="tabs__item"
        tuiTab
        (click)="onClickTabButton(localization)"
      >
        {{ getLanguage(localization) }}
      </button>
    </tui-tabs>

    <button
      tuiButton
      class="custom-names-tabs__button"
      type="button"
      appearance="primary"
      size="m"
      (click)="onClickSaveButton()"
    >
      {{ 'common.buttons.save' | translate }}
    </button>
  </div>

  <ng-container *ngIf="isMenuItems">
    <div class="custom-names-tabs__items-header">
      {{ 'components.customName.labels.menuTitle' | translate }}
    </div>

    <div class="custom-names-tabs__wrapper">
      <ng-container *ngFor="let menuItem of menuItems">
        <app-custom-name
          [label]="menuItem?.defaultTitle || ''"
          [formControlName]="menuItem.codeName!"
          [showVisibilityOption]="true"
          (visibilityOptionChange)="onMenuItemVisibilityOptionChange(menuItem)"
        ></app-custom-name>
      </ng-container>
    </div>
  </ng-container>

  <ng-container *ngIf="isNonMenuItems">
    <div class="custom-names-tabs__items-header">
      {{ 'components.customName.labels.otherHeadingsTitle' | translate }}
    </div>

    <div class="custom-names-tabs__wrapper">
      <ng-container *ngFor="let menuItem of nonMenuItems">
        <app-custom-name
          [label]="menuItem?.defaultTitle || ''"
          [formControlName]="menuItem.codeName!"
        ></app-custom-name>
      </ng-container>
    </div>
  </ng-container>
</div>
