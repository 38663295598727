<div class="message-voice">
  <div class="message-voice__message-content message-content">
    <div class="message-voice__voice-wrapper" [class.tui-skeleton]="!voice.src">
      <audio
        #voice
        tuiMedia
        controls
        [src]="messageVoiceSource?.src | safe: 'url'"
        [title]="messageVoiceNote?.caption?.text || ('components.messageVoiceNote.labels.voiceNote' | translate)"
      ></audio>
    </div>
    <ng-content></ng-content>
  </div>
</div>
