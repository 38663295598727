import { Injectable, OnDestroy } from '@angular/core';
import { catchError, map, takeUntil } from 'rxjs/operators';
import { Subject, throwError } from 'rxjs';
import { MailAdd, MailsService as ApiMailsService } from '@src/api';
import { AlertService } from '@src/core/services';

@Injectable({
  providedIn: 'root',
})
export class MailsService implements OnDestroy {
  private destroyed$$: Subject<void> = new Subject<void>();

  constructor(private mailsService: ApiMailsService, private readonly alertService: AlertService) {}

  ngOnDestroy(): void {
    this.destroyed$$.next();
    this.destroyed$$.complete();
  }

  sendSupportEmail(message: string, email: string, subject?: string, files?: File[]) {
    return this.mailsService.sendSupportMail(message, email, subject, files).pipe(
      catchError(err => {
        this.alertService.error(err);

        return throwError(err);
      }),
      takeUntil(this.destroyed$$),
    );
  }

  addMail(body?: MailAdd) {
    return this.mailsService.addMail(body).pipe(
      catchError(err => {
        this.alertService.error(err);

        return throwError(err);
      }),
      takeUntil(this.destroyed$$),
    );
  }

  editMail(body?: MailAdd) {
    return this.mailsService.editMail(body).pipe(
      catchError(err => {
        this.alertService.error(err);

        return throwError(err);
      }),
      takeUntil(this.destroyed$$),
    );
  }

  deleteMail(mailId: string) {
    return this.mailsService.deleteMail(mailId).pipe(
      catchError(err => {
        this.alertService.error(err);

        return throwError(err);
      }),
      takeUntil(this.destroyed$$),
    );
  }

  getMail(organisationId: string, email?: string) {
    return this.mailsService.getMail(organisationId, email).pipe(
      catchError(err => {
        this.alertService.error(err);

        return throwError(err);
      }),
      map(v => v[0]),
      takeUntil(this.destroyed$$),
    );
  }

  sendTestMail(callback?: (value: boolean) => void) {
    callback?.(true);

    return this.mailsService
      .sendTestMail()
      .pipe(
        catchError(err => {
          this.alertService.error(err);

          callback?.(false);
          return throwError(err);
        }),
        takeUntil(this.destroyed$$),
      )
      .subscribe((res: any) => {
        //TODO: Убрать any после исправления типа ответа на string
        this.alertService.success(res);
        callback?.(false);
      });
  }
}
