import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, lastValueFrom, Subject, throwError } from 'rxjs';
import { catchError, takeUntil } from 'rxjs/operators';
import { NgxPermissionsService } from 'ngx-permissions';
import { RoleService as ApiRoleService, RoleView } from '@src/api';
import { AlertService } from '@src/core/services/alert.service';

@Injectable({
  providedIn: 'root',
})
export class RoleService implements OnDestroy {
  roles$: BehaviorSubject<RoleView[]>;

  private destroyed$$: Subject<void> = new Subject<void>();

  constructor(
    private roleService: ApiRoleService,
    private ngxPermissionsService: NgxPermissionsService,
    private alertService: AlertService,
  ) {
    this.roles$ = new BehaviorSubject([{}]);
  }

  ngOnDestroy(): void {
    this.destroyed$$.next();
    this.destroyed$$.complete();
  }

  getUserRolesData(userId: string, organisationId: string): Promise<RoleView[]> {
    const permissions = this.ngxPermissionsService.getPermissions();
    if (
      'organisationEmployeeSpecialFieldsViewing' in permissions ||
      'onlyYourOrganisationEmployeeSpecialFieldsViewing' in permissions
    ) {
      return lastValueFrom(this.roleService.getUserRole(userId, organisationId))
        .catch(() => [])
        .then();
    }
    return new Promise(resolve => resolve([]));
  }

  getRoles(): void {
    const permissions = this.ngxPermissionsService.getPermissions();
    if (
      !('organisationEmployeeSpecialFieldsViewing' in permissions) &&
      !('onlyYourOrganisationEmployeeSpecialFieldsViewing' in permissions)
    ) {
      this.roles$.next([]);
      return;
    }

    this.roleService
      .getRoles()
      .pipe(
        catchError(err => {
          // TODO: Вернуть отображение ошибки после решения задачи #9960
          // this.alertService.error(err, { autoClose: 30000 });
          return throwError(err);
        }),
        takeUntil(this.destroyed$$),
      )
      .subscribe(roles => this.roles$.next(roles));
  }

  resetRoles(): void {
    this.roles$.next([{}]);
  }

  resetAll(): void {
    this.resetRoles();
  }
}
