import { Pipe, PipeTransform } from '@angular/core';
import { TuiDay, TuiTime } from '@taiga-ui/cdk';

@Pipe({
  name: 'dateToTuiDateTime',
})
export class DateToTuiDateTimePipe implements PipeTransform {
  transform(date: Date | string | undefined, format: 'datetime' | 'date' | 'time'): [TuiDay, TuiTime] | TuiDay | TuiTime | undefined {
    if (!date) return;
    if (!format) throw new Error('Format not specified');

    date = new Date(date);
    const tuiDay = new TuiDay(date.getFullYear(), date.getMonth(), date.getDate());
    const tuiTime = new TuiTime(date.getHours(), date.getMinutes());

    switch (format) {
      case 'datetime':
        return [tuiDay, tuiTime];

      case 'date':
        return tuiDay;

      case 'time':
        return tuiTime;

      default:
        throw new Error(`Invalid format: ${format}`);
    }
  }
}
