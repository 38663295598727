import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TuiButtonModule, TuiDataListModule, TuiGroupModule, TuiTextfieldControllerModule } from '@taiga-ui/core';
import {
  TuiInputDateModule,
  TuiInputModule,
  TuiInputNumberModule,
  TuiInputTimeModule,
  TuiInputYearModule,
  TuiSelectModule,
  TuiTextareaModule,
} from '@taiga-ui/kit';
import { TranslateModule } from '@ngx-translate/core';
import { FieldModule } from '@src/app/shared/field';
import { ComponentsModule } from '@src/app/components';
import { TuiAutoFocusModule } from '@taiga-ui/cdk';
import { PhoneModule } from '@src/app/modules/phone';
import { ContactsModule } from '@src/app/modules/contacts';
import { RouterModule } from '@angular/router';
import { LogoModule } from '@src/app/modules/branding/modules';
import { LoginModule } from '@src/app/modules/login';
import { SharedModule } from '@src/app/shared';

import { RegistrationComponent } from './registration.component';
import { RegistrationFormComponent } from './components';

@NgModule({
  declarations: [RegistrationComponent, RegistrationFormComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    FieldModule,
    ComponentsModule,
    TuiButtonModule,
    TuiInputModule,
    TuiTextfieldControllerModule,
    TuiTextareaModule,
    TuiInputYearModule,
    TuiInputDateModule,
    TuiInputTimeModule,
    TuiInputNumberModule,
    TuiDataListModule,
    TuiSelectModule,
    TuiAutoFocusModule,
    PhoneModule,
    ContactsModule,
    TuiGroupModule,
    RouterModule,
    LogoModule,
    LoginModule,
    SharedModule,
  ],
  exports: [RegistrationComponent],
})
export class RegistrationModule {}
