<tui-accordion class="accordion-levels" [closeOthers]="false" [rounded]="!!rounded">
  <tui-accordion-item
    *ngFor="let item of items; let i = index"
    appTestingId="Accordion.Item.{{ i }}"
    [class.selected]="!multiple && item.id && item.id === selectedIds[0]"
    [class.dictionary]="type === 'dictionary'"
    [class.borders]="!item.parentId && type === 'dictionary' && border"
    [class.border-top]="item.parentId"
    [borders]="null"
    [showArrow]="!!item.children?.length"
    [open]="isOpen(item?.id)"
    [disableHover]="!!disableHover"
  >
    <div class="item" [class.disabled]="isDisabled(item.id)" (click)="onClickItem($event, item.id)">
      <tui-checkbox
        *ngIf="multiple"
        [ngModel]="isSelected(item.id)"
        (ngModelChange)="onChecked($event, item.id)"
      ></tui-checkbox>
      <ng-container [ngSwitch]="type">
        <committee-item *ngSwitchCase="'committee'" [item]="item" class="item"></committee-item>
        <dictionary-item *ngSwitchCase="'dictionary'" [item]="item" class="item"></dictionary-item>
      </ng-container>
    </div>

    <ng-template *ngIf="!!item.children?.length" tuiAccordionItemContent>
      <app-accordion-levels
        [rounded]="false"
        [border]="false"
        [disableChangeSelection]="disableChangeSelection"
        [type]="type"
        [multiple]="multiple"
        [singleSelect]="singleSelect"
        [items]="item.children"
        [disableHover]="!!disableHover"
        [disableIds]="disableIds"
        [(selectedIds)]="selectedIds"
        (selectedIdsChange)="onEmitSelectedIds()"
      ></app-accordion-levels>
    </ng-template>
  </tui-accordion-item>
</tui-accordion>
