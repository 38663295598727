<div class="administration">
  <div class="administration__header">
    <button
      *ngIf="!showFullPage && selectedItem"
      tuiIconButton
      class="administration__return-button"
      type="button"
      appearance="icon"
      icon="tuiIconArrowLeftLarge"
      size="s"
      [title]="'common.buttons.return' | translate"
      (click)="onClickReturnButton()"
    ></button>

    <h1 class="administration__title">
      {{
        selectedItem && !showFullPage
          ? (selectedItem.title | translate)
          : ('components.navigation.constants.navigationItems.administrationTitle' | translate)
      }}
    </h1>

    <app-hint
      *ngIf="selectedItem && !showFullPage && selectedItem?.hint"
      class="hint"
      [value]="selectedItem.hint ?? ''"
    ></app-hint>
  </div>

  <tui-scrollbar [hide]="!showFullPage" class="administration__list">
    <app-navigation-list
      [navTree]="navTree"
      [(selectedItem)]="selectedItem"
      [descriptionVisible]="true"
    ></app-navigation-list>
  </tui-scrollbar>

  <div class="administration__content">
    <div class="body">
      <div *ngIf="showFullPage && !!selectedItem?.title" class="body__header">
        <h1 class="body__title">{{ selectedItem?.title ?? '' | translate }}</h1>
        <app-hint *ngIf="selectedItem?.hint" class="hint" [value]="selectedItem?.hint"></app-hint>
      </div>

      <tui-scrollbar class="body__content">
        <router-outlet></router-outlet>
      </tui-scrollbar>
    </div>
  </div>
</div>
