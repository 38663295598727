import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Injector,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { BreakpointObserver } from '@angular/cdk/layout';
import { BehaviorSubject, lastValueFrom } from 'rxjs';
import { TuiContextWithImplicit, tuiPure, TuiStringHandler } from '@taiga-ui/cdk';
import { tuiCreateTimePeriods } from '@taiga-ui/kit';
import { TuiDialogService } from '@taiga-ui/core';
import { PolymorpheusComponent } from '@tinkoff/ng-polymorpheus';
import { EnvService } from '@src/app/modules/env';
import { TelegramMessengerService } from '@src/app/modules/telegram';
import { AUTOCOMPLETE_TYPES, DECISION_TYPE_CODE, EVENT_TYPES } from '@src/constants';
import { APP_CONFIG } from '@src/core';
import { AlertService, BreakpointObserverHelperService, UserService } from '@src/core/services';
import { EventUI, ViewMode, UserUI, CommitteeUI } from '@src/models';
import { DocumentView } from '@src/api';
import { DialogConfirmComponent } from '@src/app/shared/dialogs';
import { ResizableBaseComponent } from '@src/app/components/resizable-base-component';
import { ObjectId } from '@src/types/id';
import { TranslateService } from '@ngx-translate/core';

import { EventType, FormData, FormDataControls } from './types';

@Component({
  selector: 'app-event-info-view',
  templateUrl: './event-info-view.component.html',
  styleUrls: ['./event-info-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EventInfoViewComponent extends ResizableBaseComponent implements OnChanges {
  @Input() mode: ViewMode = 'view';
  @Input() data?: EventUI | null;
  @Input() documentsList?: DocumentView[] | null;
  @Input() membersList?: UserUI[] | null;
  @Input() allowEditing?: boolean | null = false;
  @Input() externalLoading: boolean = true;
  @Input() recallButtonLoading = false;
  @Input() allowReportViewing?: boolean | null = false;
  @Output() getMembersFile: EventEmitter<string> = new EventEmitter();
  @Output() startEditing: EventEmitter<void> = new EventEmitter();
  @Output() copyEvent: EventEmitter<void> = new EventEmitter();
  @Output() eventDeleted: EventEmitter<string> = new EventEmitter();
  @Output() deactivated: EventEmitter<string> = new EventEmitter();
  @Output() accept: EventEmitter<string> = new EventEmitter();
  @Output() decline: EventEmitter<string> = new EventEmitter();
  @Output() toCalendar: EventEmitter<EventUI> = new EventEmitter();
  @Output() recallMembers: EventEmitter<string[]> = new EventEmitter();
  @Output() startReport: EventEmitter<EventUI> = new EventEmitter();

  infoForm: UntypedFormGroup;
  innerLoading: boolean = true;
  authUser$: BehaviorSubject<UserUI | undefined> = this.userService.authUser$;
  allowGoToChat: boolean = false;
  chatButtonLoading: boolean = false;
  memberIdsToRecall: string[] = [];
  additionalDocs?: DocumentView[];
  program?: DocumentView;

  readonly timeItems = tuiCreateTimePeriods(0, 24, [0, 15, 30, 45]);
  readonly maxDocFileSize = APP_CONFIG.fileSizeMax.doc;
  readonly eventTypes: EventType[] = EVENT_TYPES;
  readonly autocompleteTypes = AUTOCOMPLETE_TYPES;

  private readonly confirmSubscriptionDialog = this.dialogService.open<boolean>(
    new PolymorpheusComponent(DialogConfirmComponent, this.injector),
    {
      label: this.translateService.instant('components.eventInfo.dialogs.chatSubscribeHeader'),
      size: 's',
      closeable: false,
    },
  );

  constructor(
    readonly cdr: ChangeDetectorRef,
    readonly breakpointObserver: BreakpointObserver,
    readonly breakpointObserverHelperService: BreakpointObserverHelperService,
    private userService: UserService,
    private router: Router,
    private env: EnvService,
    private messengerService: TelegramMessengerService,
    private readonly translateService: TranslateService,
    private readonly alertService: AlertService,
    @Inject(TuiDialogService) private readonly dialogService: TuiDialogService,
    @Inject(Injector) private readonly injector: Injector,
  ) {
    super(cdr, breakpointObserver, breakpointObserverHelperService);

    this.infoForm = new UntypedFormGroup(<FormDataControls>{
      subject: new UntypedFormControl('', Validators.required),
      description: new UntypedFormControl(''),
      dateStart: new UntypedFormControl('', Validators.required),
      dateEnd: new UntypedFormControl('', Validators.required),
      address: new UntypedFormControl(''),
      onlineLink: new UntypedFormControl(''),
      eventTypeId: new UntypedFormControl('', Validators.required),
      participants: new UntypedFormControl([]),
      botId: new UntypedFormControl(),
      groupChatId: new UntypedFormControl(),
      groupInviteCode: new UntypedFormControl(),
      channelId: new UntypedFormControl(),
      committeeId: new UntypedFormControl(),
      createGroupChat: new UntypedFormControl(false),
      isVisibleToAll: new UntypedFormControl(false),
      contactPersonId: new UntypedFormControl(''),

      // TODO: these fields are not in the API
      program: new UntypedFormControl(),
      docs: new UntypedFormControl(''),
      // documentsIds: new FormControl(),
    });
  }

  get loading() {
    return this.externalLoading || this.innerLoading;
  }

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    if (changes.data) {
      this.innerLoading = true;
      if (this.data) {
        const mappedData = this.mapEventUIToFormData(this.data);
        this.infoForm.patchValue(mappedData);
        this.innerLoading = false;
      } else {
        this.infoForm.reset();
        this.innerLoading = false;
      }
    }

    if (changes.membersList && this.membersList) {
      this.memberIdsToRecall = this.membersList
        .filter(member => member.decisionTypeCode === DECISION_TYPE_CODE.Sended)
        .map(member => member.id) as string[];
    }

    if (changes.data || changes.membersList) {
      const authUser = this.authUser$.value;
      if (!authUser || !authUser.id || !this.data || !this.data.groupChatId || !this.data.groupInviteCode) {
        this.allowGoToChat = false;
        return;
      }

      const chatInviteCorrect = this.env.isLoggedMessenger()
        ? Boolean(await this.messengerService.api.checkChatInviteLink(this.data.groupInviteCode))
        : true; // Разрешаем переход во внешнее приложение

      this.allowGoToChat =
        chatInviteCorrect &&
        (this.data.createdBy === authUser.id ||
          (!!this.membersList && this.membersList.findIndex(member => member.id === authUser.id) > -1));
    }

    if (changes.documentsList) {
      this.additionalDocs = this.documentsList?.filter(doc => doc.fileType === 14); // 14     Приложение к мероприятию
      this.program = this.documentsList?.find(doc => doc.fileType === 5); // 5      Программа
    }

    this.cdr.markForCheck();
  }

  onClickRecallButton(): void {
    if (!this.memberIdsToRecall) return;

    this.recallMembers.emit(this.memberIdsToRecall);
  }

  onClickMembersFileButton(): void {
    if (!this.data) return;

    this.getMembersFile.emit(this.data.id);
  }

  onSelectedUserIdChange(userId?: ObjectId): void {
    this.router.navigate(['association-users', userId]);
  }

  onClickEditButton(): void {
    this.startEditing.emit();
  }

  onClickCopyButton(): void {
    this.copyEvent.emit();
  }

  onClickDeleteButton(): void {
    if (!this.data) return;

    this.eventDeleted.emit(this.data.id);
  }

  onClickDeactivateButton(): void {
    if (!this.data) return;

    this.deactivated.emit(this.data.id);
  }

  onClickAcceptButton(): void {
    if (!this.data) return;

    this.accept.emit(this.data.id);
  }

  onClickDeclineButton(): void {
    if (!this.data) return;

    this.decline.emit(this.data.id);
  }

  onClickToCalendarButton(): void {
    if (!this.data) return;

    this.toCalendar.emit(this.data);
  }

  onClickReportButton(): void {
    if (!this.data?.id) {
      return;
    }

    this.startReport.emit(this.data);
  }

  async onClickToChatButton(): Promise<void> {
    if (!this.data?.groupInviteCode) {
      this.alertService.error(this.translateService.instant('common.alerts.errors.noInviteCode'));
      return;
    }

    this.chatButtonLoading = true;

    if (this.env.isBot || !this.env.isLoggedMessenger()) {
      this.env.openLink(this.data.groupInviteCode);
    } else {
      if (!this.data?.groupChatId) {
        this.alertService.error(this.translateService.instant('common.alerts.errors.noChatId'));
        return;
      }

      const allChats = await this.messengerService.getAllChatIds(true);

      if (allChats.indexOf(this.data.groupChatId) === -1) {
        const res = await lastValueFrom(this.confirmSubscriptionDialog);
        if (!res) {
          this.chatButtonLoading = false;
          return;
        }

        await this.messengerService.api.joinChatByInviteLink(this.data.groupInviteCode);
      }

      this.router.navigate(['/chats', this.data?.groupChatId]);
    }

    this.chatButtonLoading = false;
  }

  @tuiPure
  eventTypesStringify(items: ReadonlyArray<EventType>): TuiStringHandler<TuiContextWithImplicit<number>> {
    const map = new Map(items.map(({ id, name }) => [id, name] as [number, string]));

    return ({ $implicit }: TuiContextWithImplicit<number>) => this.translateService.instant(map.get($implicit) || ' ');
  }

  @tuiPure
  committeesStringify(items: ReadonlyArray<CommitteeUI>): TuiStringHandler<TuiContextWithImplicit<string>> {
    const map = new Map(items.map(({ id, name }) => [id, name] as [string, string]));

    return ({ $implicit }: TuiContextWithImplicit<string>) => map.get($implicit) || '';
  }

  private mapEventUIToFormData(event: EventUI) {
    const {
      subject,
      description,
      dateStart,
      dateEnd,
      address,
      onlineLink,
      eventTypeId,
      botId,
      groupChatId,
      groupInviteCode,
      channelId,
      committeeId,
      isVisibleToAll,
      participants,
      contactPersonId,
    } = event;
    // TODO: modify documentsIds
    // TODO: add fields: documentsIds

    const dateStartNormalize = dateStart ? new Date(dateStart) : undefined; // TODO: delete after fix nswag DateTime format
    const dateEndNormalize = dateEnd ? new Date(dateEnd) : undefined; // TODO: delete after fix nswag DateTime format

    return <FormData>{
      subject,
      description,
      dateStart: dateStartNormalize,
      dateEnd: dateEndNormalize,
      address,
      onlineLink,
      eventTypeId,
      botId,
      groupChatId,
      groupInviteCode,
      channelId,
      committeeId,
      isVisibleToAll: isVisibleToAll ?? false,
      participants,
      contactPersonId,
    };
  }

  getLinkToClipboard() {
    return `${APP_CONFIG.baseUrl}/events/${this.data?.id}`;
  }

  get isAcceptButtonEnabled() {
    return (
      this.data &&
      this.data.status !== 5 &&
      this.data.decisionTypeCode !== undefined &&
      (this.data.decisionTypeCode !== null || this.data.isVisibleToAll) &&
      ![2, 4].includes(this.data.decisionTypeCode)
    );
  }

  get isDeclineButtonEnabled() {
    return (
      this.data &&
      this.data.status !== 5 &&
      this.data.decisionTypeCode !== undefined &&
      this.data.decisionTypeCode !== null &&
      ![3, 4].includes(this.data.decisionTypeCode)
    );
  }
}
