import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, Subject, throwError } from 'rxjs';
import { catchError, map, takeUntil } from 'rxjs/operators';
import { ChatsService as ApiChatsService, ChatView, Id } from '@src/api';

@Injectable({
  providedIn: 'root',
})
export class ChatsService implements OnDestroy {
  chats$: BehaviorSubject<ChatView[] | null> = new BehaviorSubject<ChatView[] | null>(null);
  chatsIds$: BehaviorSubject<number[]> = new BehaviorSubject<number[]>([]);

  private destroyed$$: Subject<void> = new Subject<void>();

  constructor(private chatsService: ApiChatsService) {
    this.chats$.pipe(takeUntil(this.destroyed$$)).subscribe(chats => {
      const chatsIds = chats?.map(chat => chat.chatId!);
      this.chatsIds$.next(chatsIds ?? []);
    });
  }

  ngOnDestroy(): void {
    this.destroyed$$.next();
    this.destroyed$$.complete();
  }

  addChat(chatId: number, chatType: number, description: string): Observable<Id> {
    return this.chatsService.addChat({ chatId, chatType, description }).pipe(
      catchError(err => {
        // TODO show error notification
        return throwError(err);
      }),
    );
  }

  getChats(chatsIds: number[]): void {
    this.chatsService
      .getChats(chatsIds)
      .pipe(
        catchError(err => {
          // TODO show error notification
          return throwError(err);
        }),
        takeUntil(this.destroyed$$),
      )
      .subscribe(chats => this.chats$.next(chats));
  }

  getChatsData(chatsIds: number[]): Observable<ChatView[]> {
    return this.chatsService.getChats(chatsIds).pipe(
      catchError(err => {
        // TODO show error notification
        return throwError(err);
      }),
      takeUntil(this.destroyed$$),
    );
  }

  getChatsIdsData(chatsIds: number[]): Observable<number[]> {
    return this.chatsService.getChats(chatsIds).pipe(
      catchError(err => {
        // TODO show error notification
        return throwError(err);
      }),
      map(chats => chats.map(chat => chat.chatId).filter(id => id !== undefined) as number[]),
    );
  }

  getInternalChatIds(chatId: number[]): Observable<string[]> {
    return this.chatsService.getChats(chatId).pipe(
      catchError(err => {
        // TODO show error notification
        return throwError(err);
      }),
      map(chats => chats.map(chat => chat.id).filter(id => id !== undefined) as string[]),
    );
  }

  deleteChat(internalChatId: string): Observable<Id> {
    return this.chatsService.deleteChat(internalChatId).pipe(
      catchError(err => {
        // TODO show error notification
        return throwError(err);
      }),
    );
  }

  resetChats(): void {
    this.chats$.next(null);
  }

  resetAll(): void {
    this.resetChats();
  }
}
