/**
 * Утилита, которая возвращает слово находящееся в границе каретки.
 * @param textarea ссылка на элемент ввода текста
 */
export const getWordAt = (selection: Selection | null): string => {
  if (!selection) {
    return '';
  }

  let value = '';

  // nodeType === 1 - Object
  if (selection.anchorNode?.nodeType === 1) {
    value = selection.anchorNode.firstChild?.nodeValue ?? '';
  } else {
    value = selection.anchorNode?.nodeValue ?? '';
  }

  if (!(value && selection.rangeCount === 1)) {
    return '';
  }

  if (value.substring(selection.anchorOffset - 1, selection.anchorOffset).trim() === '') {
    return '';
  }

  const left = value.slice(0, selection.anchorOffset + 1).search(/\S+\s*$/);
  const right = value.slice(selection.anchorOffset).search(/\s/);

  const result = right < 0 ? value.slice(left) : value.slice(left, right + selection.anchorOffset);

  const blocks = result.split('\n');
  return blocks[blocks.length - 1].trim();
};
