import { Component, ChangeDetectionStrategy, Input, OnChanges, ChangeDetectorRef, Output, EventEmitter } from '@angular/core';
import { TelegramMessengerService } from '@src/app/modules/telegram';
import { MessageModel } from '@src/models';

@Component({
  selector: 'app-message-reply',
  templateUrl: './message-reply.component.html',
  styleUrls: ['./message-reply.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MessageReplyComponent implements OnChanges {
  @Input() chatId?: number;
  @Input() messageId?: number;
  @Output() messageClicked: EventEmitter<number>;
  message?: MessageModel;

  constructor(private cdr: ChangeDetectorRef, private messengerService: TelegramMessengerService) {
    this.messageClicked = new EventEmitter();
  }

  async ngOnChanges(): Promise<void> {
    if (this.messageId && this.chatId) {
      this.message = await this.messengerService.api.getMessage(this.chatId, this.messageId);
      this.cdr.markForCheck();
    }
  }

  onClickMessage(messageId?: number) {
    if (!messageId) return;

    this.messageClicked.emit(messageId);
  }
}
