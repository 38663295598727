/**
 * Unions API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Приглашения.
 */
export interface InviteView { 
    /**
     * ID группового чата.
     */
    chatId?: number;
    /**
     * 
     */
    chatTitle?: string;
    /**
     * ID организации/ассоциации группового чата.
     */
    organisationId?: string;
    /**
     * 
     */
    inviteCode?: string;
    /**
     * 
     */
    id?: string;
    /**
     * 
     */
    userId?: string;
    /**
     * 
     */
    inviteStatus?: number;
}

