import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { UserUI } from '@src/models';

@Component({
  selector: 'cell-user',
  templateUrl: './cell-user.component.html',
  styleUrls: ['./cell-user.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CellUserComponent {
  @Input() user?: UserUI;
  @Input() statusVisible = false;

  get defaultOrganisationJobTitleDescription(): string | undefined {
    return this.user?.jobTitlesInOrganisation?.find(({ isDefault }) => isDefault)?.jobTitles?.[0];
  }
}
