<div class="render-user-row">
  <tui-avatar
    size="m"
    [autoColor]="true"
    [rounded]="true"
    [avatarUrl]="getAvatarUrl()"
    [text]="user | userDisplayableName : 'short'"
  ></tui-avatar>

  <div class="render-user-row__info">
    <div class="render-user-row__title">
      <span class="render-user-row__text tui-text_body-m" [title]="user.fullName">{{ user.fullName }}</span>

      <ng-container *ngIf="statusContent">
        <ng-container *ngTemplateOutlet="statusContent; context: { $implicit: user }"></ng-container>
      </ng-container>
    </div>

    <ng-container *ngFor="let organisationJobTitle of user.jobTitlesInOrganisation">
      <span
        class="render-user-row__text tui-text_body-s"
        [style.color]="'var(--tui-text-03)'"
        [title]="organisationJobTitle | orgJobTitleDisplayableName"
      >
        {{ organisationJobTitle | orgJobTitleDisplayableName }}
      </span>
    </ng-container>
  </div>
</div>
