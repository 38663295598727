import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TuiHintModule, TuiLoaderModule, TuiSvgModule, TuiTooltipModule } from '@taiga-ui/core';

import { SharedModule } from '../../shared/shared.module';

import { HintComponent } from './hint.component';

@NgModule({
  declarations: [HintComponent],
  imports: [CommonModule, TuiHintModule, TuiLoaderModule, TuiSvgModule, TuiTooltipModule, SharedModule],
  exports: [HintComponent],
})
export class HintModule {}
