<div class="search-groups">
  <div class="search-groups__header">
    <div class="search-groups__title">
      {{
        isChannel
          ? ('components.searchGroups.labels.selectChannel' | translate)
          : ('components.searchGroups.labels.selectGroup' | translate)
      }}
    </div>
  </div>

  <div class="search-groups__list">
    <div class="header">
      <app-search [(searchQuery)]="searchQuery" (searchQueryChange)="searchQueryChangeHandler($event)"></app-search>
    </div>

    <app-list
      listType="chats"
      [items]="searchGroups"
      [(selectedId)]="addGroupSelectedId"
      (selectedIdChange)="changeSelectedIdAddGroup($event)"
    ></app-list>
  </div>
</div>

<ng-template #confirmAttachDialog let-observer>
  <p>
    {{
      isChannel
        ? ('components.searchGroups.dialogs.channelAttachLabel' | translate)
        : ('components.searchGroups.dialogs.groupAttachLabel' | translate)
    }}?
  </p>
  <button tuiButton type="button" size="m" class="tui-space_right-3" (click)="attachGroup(observer)">
    {{ 'common.buttons.yes' | translate }}
  </button>
  <button tuiButton type="button" size="m" appearance="secondary" (click)="observer.complete()">
    {{ 'common.buttons.no' | translate }}
  </button>
</ng-template>
