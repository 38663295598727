<div class="message-sticker">
  <div class="message-sticker__message-content message-content">
    <div
      *ngIf="!messageSticker?.sticker?.isAnimated; else animatedSticker"
      class="message-sticker__sticker-wrapper"
      [class.tui-skeleton]="!sticker.src"
    >
      <img
        #sticker
        [width]="(messageSticker?.sticker?.width || 0) < 220 ? messageSticker?.sticker?.width : 220"
        [src]="messageStickerSource?.src | safe: 'url'"
        [alt]="messageSticker?.sticker?.emoji"
      />
    </div>
    <ng-template #animatedSticker>
      <div class="message-sticker__sticker-wrapper">
        <!-- TODO implement animatedSticker -->
        <canvas #canvasSticker class="message-sticker__canvas" width="208" height="208"></canvas>
      </div>
    </ng-template>
  </div>
</div>
