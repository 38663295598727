import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'app-logo',
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LogoComponent implements OnInit {
  @Input() loading?: boolean = false;
  @Input() src?: string;
  @Input() width?: string | number;
  @Input() height?: string | number;

  constructor() {}

  ngOnInit(): void {}
}
