import { APP_CONFIG } from '@src/core';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';

import { DownloadLinksComponentOptions } from './types';

export const DEFAULT_DOWNLOAD_LINKS: DownloadLinksComponentOptions = {
  logoSrc: '../assets/images/logo.svg',
  title: marker('components.loginDownloadLinks.constants.defaultDownloadLinks.title'),
  description: marker('components.loginDownloadLinks.constants.defaultDownloadLinks.description'),
  androidDownloadLink: APP_CONFIG.mobile.android.downloadLink,
  iosDownloadLink: APP_CONFIG.mobile.ios.downloadLink,
} as const;
