import { Component, ChangeDetectorRef, ChangeDetectionStrategy, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { CommitteeService } from '@src/core/services';
import {ObjectId} from '@src/types/id';

@Component({
  selector: 'app-organisation-committees',
  templateUrl: './organisation-committees.component.html',
  styleUrls: ['./organisation-committees.component.scss', '../styles/organisation-content.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrganisationCommitteesComponent implements OnChanges {
  @Input() organisationId?: string;
  committees$ = this.committeeService.committeesForOrganisation$;

  loading = false;

  constructor(private router: Router, private committeeService: CommitteeService, private cdr: ChangeDetectorRef) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.organisationId) {
      this.committeeService.resetCommitteesForOrganisation();
      if (this.organisationId) {
        this.committeeService.getCommitteesByOrganisationId(this.organisationId, value => {
          this.loading = value;
          this.cdr.markForCheck();
        });
      }
    }
  }

  onSelectedCommitteeIdChange(committeeId?: ObjectId) {
    this.router.navigate(['committees', committeeId]);
  }
}
