import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { OrganisationShortInfoUI } from '@src/app/modules/branding';

import { BrandingService } from '../../services/branding.service';

@Component({
  selector: 'app-branding-logo',
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LogoComponent {
  @Input() loading?: boolean;
  @Input() width?: string | number;
  @Input() height?: string | number;

  brandingData$ = this.brandingService.data$;

  constructor(private brandingService: BrandingService) {}

  getLogoSrc(data?: OrganisationShortInfoUI | null) {
    if (!!data && this.brandingService.isBrand()) {
      return data.logoSrc;
    }

    return undefined;
  }
}
