import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Injector,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { Router } from '@angular/router';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { BehaviorSubject, lastValueFrom, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TuiSizeXXL, TuiSizeXL, TuiSizeL } from '@taiga-ui/core';
import { TuiDialogService } from '@taiga-ui/core';
import { PolymorpheusComponent } from '@tinkoff/ng-polymorpheus';
import { ChatMemberUnion } from '@airgram/web';
import { TranslateService } from '@ngx-translate/core';
import { EnvService } from '@src/app/modules/env';
import { AlertService, BreakpointObserverHelperService, UserService } from '@src/core/services';
import { ScreenTypes, GroupUI, ViewMode, UserUI, Multimedia } from '@src/models';
import { DialogConfirmComponent } from '@src/app/shared/dialogs';
import { CommitteeUserSubscriptionsService, SubscriptionForUsers, SubscriptionsForUsersService } from '@src/api';

// TODO: вынести в types.ts
interface FormData {
  id: string;
  name: string;
  description: string;
  members: UserUI[];
}

type FormDataControls = { [key in keyof FormData]: AbstractControl };

@Component({
  selector: 'app-group-info-view',
  templateUrl: './group-info-view.component.html',
  styleUrls: ['./group-info-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GroupInfoViewComponent implements OnInit, OnChanges, OnDestroy {
  @Input() mode: ViewMode = 'view';
  @Input() data?: GroupUI;
  @Input() members?: ChatMemberUnion[];
  @Input() isChannel?: boolean;
  @Input() memberCount?: number;
  @Input() loading: boolean = false;
  @Input() membersLoading: boolean = false;
  @Input() allowEditing?: boolean | null = false;
  @Input() allowDeleting?: boolean | null = false;
  @Input() allowSubscribing?: boolean | null = false;
  @Input() allowSpecialFieldsViewing?: boolean | null = false;
  @Output() startEditing: EventEmitter<void> = new EventEmitter();
  @Output() saved: EventEmitter<GroupUI> = new EventEmitter();
  @Output() canceled: EventEmitter<void> = new EventEmitter();
  @Output() deleted: EventEmitter<void> = new EventEmitter();
  @Output() deletePhoto: EventEmitter<void> = new EventEmitter();
  @Output() subscribed: EventEmitter<void> = new EventEmitter();
  @Output() unsubscribed: EventEmitter<void> = new EventEmitter();
  @Output() updateMembers: EventEmitter<void> = new EventEmitter();

  infoForm: UntypedFormGroup;
  newPhoto?: Multimedia;
  authUser$: BehaviorSubject<UserUI | undefined> = this.userService.authUser$;
  synchronizing = false;

  // TODO: вынести в константу
  readonly DESCRIPTION_LIMITS = 255;

  private screenType: ScreenTypes = 'extra-large';
  private destroyed$$: Subject<void> = new Subject<void>();
  private readonly confirmCancelEditingDialog = this.dialogService.open<boolean>(
    new PolymorpheusComponent(DialogConfirmComponent, this.injector),
    {
      label: this.translateService.instant('common.dialogs.undoEditHeader'),
      size: 's',
      closeable: false,
    },
  );

  constructor(
    private cdr: ChangeDetectorRef,
    private breakpointObserver: BreakpointObserver,
    private breakpointObserverHelperService: BreakpointObserverHelperService,
    private router: Router,
    private userService: UserService,
    private alertService: AlertService,
    private readonly translateService: TranslateService,
    public readonly env: EnvService,
    @Inject(TuiDialogService) private readonly dialogService: TuiDialogService,
    @Inject(Injector) private readonly injector: Injector,
    private readonly subscriptionsForUsersService: SubscriptionsForUsersService,
    private readonly committeeUserSubscriptionsService: CommitteeUserSubscriptionsService,
  ) {
    this.infoForm = new UntypedFormGroup(<FormDataControls>{
      id: new UntypedFormControl(undefined),
      name: new UntypedFormControl('', [Validators.required, Validators.maxLength(255)]),
      description: new UntypedFormControl('', Validators.maxLength(255)),
      isDefault: new UntypedFormControl(false),
      members: new UntypedFormControl(null),
    });
  }

  get tuiElementDoubleExtraLargeSize(): TuiSizeXXL | TuiSizeXL {
    return this.screenTypesLargeSet.includes(this.screenType) ? 'xxl' : 'xl';
  }

  get tuiElementLargeSize(): TuiSizeL {
    return this.screenTypesLargeSet.includes(this.screenType) ? 'l' : 'm';
  }

  get screenTypesLargeSet(): ScreenTypes[] {
    return this.breakpointObserverHelperService.getScreenTypesBiggerThanTarget('large');
  }

  get isLoggedMessenger(): boolean {
    return this.env.isLoggedMessenger();
  }

  get isActiveTab(): boolean {
    return this.env.isActiveTab();
  }

  get isLoadedAllChatsMessenger(): boolean {
    return this.env.isLoadedAllChatsMessenger();
  }

  ngOnInit(): void {
    this.breakpointObserver
      .observe(this.breakpointObserverHelperService.breakpointsSet)
      .pipe(takeUntil(this.destroyed$$))
      .subscribe((state: BreakpointState) => {
        this.screenType = this.breakpointObserverHelperService.getScreenType(state);
        this.cdr.markForCheck();
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.data) {
      this.newPhoto = undefined;

      if (this.data) {
        this.setFormData();
      } else {
        this.infoForm.reset();
      }
    }

    this.cdr.markForCheck();
  }

  ngOnDestroy(): void {
    this.destroyed$$.next();
    this.destroyed$$.complete();
  }

  getMembers() {
    this.updateMembers.emit();
  }

  onUserIdChange(userId: string) {
    this.router.navigate(['association-users', userId]);
  }

  onClickEditButton(): void {
    if (!this.isActiveTab) {
      this.env.viewManyTabsDialog();
      return;
    }

    if (!this.isLoggedMessenger) {
      this.env.viewConfirmLoginMessenger(() => this.startEditing.emit());
      return;
    }

    this.startEditing.emit();
  }

  onClickSaveButton(): void {
    this.infoForm.markAllAsTouched();

    if (this.infoForm.invalid) {
      this.alertService.error(this.translateService.instant('common.alerts.errors.fillRequired'));
      return;
    }

    // TODO: вынести в отдельный метод mapFormDataToGroupUI
    const { members } = this.infoForm.value as FormData;

    const membersTelegramIds = members?.map(member => member.telegramId).filter(id => !!id) as number[];
    const membersIds = members?.map(member => member.id).filter(id => !!id) as string[];
    const unregistredMembersIds = members
      ?.filter(member => !member.telegramId)
      .map(member => member.id)
      .filter(id => id !== undefined) as string[];

    this.data = {
      ...this.data,
      ...this.infoForm.value,
      ...{ photo: this.newPhoto?.file },
      ...{ chatTypeId: this.data?.isChannel ? 2 : 1 },
      ...{ membersTelegramIds, membersIds, unregistredMembersIds },
    };

    this.saved.emit(this.data);
  }

  onClickCancelButton(): void {
    this.confirmCancelEditingDialog.pipe(takeUntil(this.destroyed$$)).subscribe({
      next: res => {
        if (res) {
          this.setFormData();
          this.canceled.emit();
        }
      },
    });
  }

  onPhotoChange(newPhoto?: Multimedia): void {
    this.newPhoto = newPhoto;
    this.cdr.markForCheck();
  }

  onPhotoDelete(): void {
    this.newPhoto = undefined;
    this.deletePhoto.emit();
    this.cdr.markForCheck();
  }

  onClickSubscribeButton(): void {
    if (!this.isLoggedMessenger && this.data?.codeName) {
      this.env.openLink(this.data?.codeName);
      return;
    }

    if (!this.isActiveTab) {
      this.env.viewManyTabsDialog();
      return;
    }

    this.subscribed.emit();
  }

  onClickUnsubscribeButton(): void {
    if (!this.isLoggedMessenger && this.data?.codeName) {
      this.env.openLink(this.data?.codeName);
      return;
    }

    if (!this.isActiveTab) {
      this.env.viewManyTabsDialog();
      return;
    }

    this.unsubscribed.emit();
  }

  onClickChatButton(chat: GroupUI): void {
    if (!chat?.codeName || !chat?.id || !chat?.chatId) {
      return;
    }

    if (this.env.isBot || !this.isLoggedMessenger) {
      this.env.openLink(chat.codeName);
    } else if (!this.isActiveTab) {
      this.env.viewManyTabsDialog();
    } else if (this.isLoadedAllChatsMessenger) {
      this.env.gotoChat(chat.chatId);
    }
  }

  onClickDeleteButton(): void {
    if (!this.isActiveTab) {
      this.env.viewManyTabsDialog();
      return;
    }

    this.deleted.emit();
  }

  get isSynchronizeButtonVisible() {
    if (this.data?.ownerId && this.authUser$.value?.id) {
      return this.data.ownerId === this.authUser$.value.id || this.data.active;
    }

    return false;
  }

  onClickSynchronizeButton() {
    if (!this.isActiveTab) {
      this.env.viewManyTabsDialog();
      return;
    }

    if (!this.isLoggedMessenger) {
      this.env.viewConfirmLoginMessenger(() => this.synchronizeUsers());
      return;
    }

    this.synchronizeUsers();
  }

  private async synchronizeUsers() {
    if (this.data?.id && this.members) {
      this.synchronizing = true;

      const telegramIds = this.members.reduce<number[]>((acc, item) => {
        if (item.memberId._ === 'messageSenderUser') {
          acc.push(item.memberId.userId);
        }
        return acc;
      }, []);

      try {
        const users = await lastValueFrom(this.userService.getUsersData(telegramIds));

        const options: SubscriptionForUsers = {
          subscriptionId: this.data?.id,
          userIds: users.map(item => item.id!),
        };

        let result;

        if (this.isCommittee) {
          result = await lastValueFrom(
            this.committeeUserSubscriptionsService.addCommitteeSubscriptionsForUsers(options),
          );
        } else {
          result = await lastValueFrom(this.subscriptionsForUsersService.addSubscriptionForUsers(options));
        }

        this.alertService.success(`Синхронизация выполнена успешно!\n${result}`);
      } finally {
        this.synchronizing = false;
        this.cdr.markForCheck();
      }
    }
  }

  private get isCommittee() {
    return !!this.data?.committeeId;
  }

  private setFormData(): void {
    if (!this.data) return;

    const { id, name, description, isDefault } = this.data;
    this.infoForm.patchValue({ id, name, description, isDefault: !!isDefault });
  }
}
