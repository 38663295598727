import { Pipe, PipeTransform } from '@angular/core';
import { maskitoTransform } from '@maskito/core';

import { PHONE_MASK_OPTIONS } from '../constants';

@Pipe({
  name: 'formatPhone',
})
export class FormatPhonePipe implements PipeTransform {
  transform(value?: string | null, mask = PHONE_MASK_OPTIONS): string {
    return maskitoTransform(value ?? '', mask);
  }
}
