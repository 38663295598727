import { Component, ChangeDetectionStrategy, Input, EventEmitter, Output, ChangeDetectorRef } from '@angular/core';
import { ResizableBaseComponent } from '@src/app/components';
import { TranslateService } from '@ngx-translate/core';
import { BreakpointObserver } from '@angular/cdk/layout';
import { BreakpointObserverHelperService } from '@src/core/services';

import { LoyaltyProgramProduct } from '../../../models';

@Component({
  selector: 'lp-view-info-block',
  templateUrl: './view-info-block.component.html',
  styleUrls: ['./view-info-block.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ViewInfoBlockComponent extends ResizableBaseComponent {
  @Input() product?: LoyaltyProgramProduct;
  @Output() getDiscount = new EventEmitter();
  @Output() externalLink: EventEmitter<string> = new EventEmitter<string>();

  constructor(
    readonly cdr: ChangeDetectorRef,
    readonly breakpointObserver: BreakpointObserver,
    readonly breakpointObserverHelperService: BreakpointObserverHelperService,
    private readonly translateService: TranslateService,
  ) {
    super(cdr, breakpointObserver, breakpointObserverHelperService);
  }

  get title() {
    if (this.product?.isNew) {
      return this.translateService.instant('components.loyaltyViewInfoBlock.labels.statusNew');
    }

    switch (this.product?.status) {
      case 'archive':
        return this.translateService.instant('components.loyaltyViewInfoBlock.labels.statusArchive');
      case 'review':
        return this.translateService.instant('components.loyaltyViewInfoBlock.labels.statusReview');
      default:
        return this.translateService.instant('components.loyaltyViewInfoBlock.labels.statusDefault');
    }
  }

  get buttonLabel() {
    if (this.product?.terms?.length === undefined) return '';

    return this.product?.terms?.length > 1
      ? this.translateService.instant('components.loyaltyViewInfoBlock.buttons.selectDiscount')
      : this.translateService.instant('components.loyaltyViewInfoBlock.buttons.getDiscount');
  }
}
