import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { BreakpointObserver } from '@angular/cdk/layout';
import { Router } from '@angular/router';
import { DECISION_TYPE_CODE } from '@src/constants';
import { BreakpointObserverHelperService } from '@src/core/services';
import { AnswerType, UserUI, ViewMode } from '@src/models';
import { DocumentView, PollFullView } from '@src/api';
import { ResizableBaseComponent } from '@src/app/components/resizable-base-component';
import { ObjectId } from '@src/types/id';
import { APP_CONFIG } from '@src/core';

import { ANSWER_TYPES } from '../constants';

interface FormData {
  titleText: string;
  descriptionText: string;
  pollDuration: number;
  dateStart: Date;
  dateEnd: Date;
  committeeId: string;
}

type FormDataControls = { [key in keyof FormData]: AbstractControl };

@Component({
  selector: 'app-poll-info-view',
  templateUrl: './poll-info-view.component.html',
  styleUrls: ['./poll-info-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PollInfoViewComponent extends ResizableBaseComponent implements OnChanges {
  @Input() mode: ViewMode = 'view';
  @Input() data?: PollFullView | null;
  @Input() documentsList?: DocumentView[] | null;
  @Input() membersList?: UserUI[] | null;
  @Input() allowEditing?: boolean | null = false;
  @Input() allowMembersViewing?: boolean | null = false;
  @Input() allowStatisticsViewing?: boolean | null = false;
  @Input() externalLoading: boolean = true;
  @Input() recallButtonLoading: boolean = false;
  @Output() startEditing: EventEmitter<void> = new EventEmitter();
  @Output() startPoll: EventEmitter<void> = new EventEmitter();
  @Output() copyPoll: EventEmitter<void> = new EventEmitter();
  @Output() declinePoll: EventEmitter<void> = new EventEmitter();
  @Output() deletePoll: EventEmitter<string> = new EventEmitter();
  @Output() statistics: EventEmitter<void> = new EventEmitter();
  @Output() recallMembers: EventEmitter<string[]> = new EventEmitter();

  infoForm!: UntypedFormGroup;
  innerLoading: boolean = true;
  answerTypes: AnswerType[] = ANSWER_TYPES;
  memberIdsToRecall: string[] = [];
  readonly decisionTypeCode = DECISION_TYPE_CODE;

  constructor(
    readonly cdr: ChangeDetectorRef,
    readonly breakpointObserver: BreakpointObserver,
    readonly breakpointObserverHelperService: BreakpointObserverHelperService,
    private router: Router,
  ) {
    super(cdr, breakpointObserver, breakpointObserverHelperService);

    this.infoForm = new UntypedFormGroup(<FormDataControls>{
      titleText: new UntypedFormControl('', Validators.required),
      descriptionText: new UntypedFormControl('', Validators.required),
      dateStart: new UntypedFormControl('', Validators.required),
      dateEnd: new UntypedFormControl('', Validators.required),
      pollDuration: new UntypedFormControl(null),
      committeeId: new UntypedFormControl(),
    });
  }

  get loading() {
    return this.externalLoading || this.innerLoading;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.data) {
      this.innerLoading = true;
      if (this.data) {
        const mappedData = this.mapPollUIToFormData(this.data);
        this.infoForm.patchValue(mappedData);
        this.innerLoading = false;
      } else {
        this.infoForm.reset();
        this.innerLoading = false;
      }
    }

    if (changes.mode) {
      if (this.mode === 'create') {
        this.innerLoading = false;
      }
    }

    if (changes.membersList && this.membersList) {
      this.memberIdsToRecall = this.membersList
        .filter(member => member.decisionTypeCode === DECISION_TYPE_CODE.Sended)
        .map(member => member.id) as string[];
    }

    this.cdr.markForCheck();
  }

  onClickRecallButton(): void {
    if (!this.memberIdsToRecall) return;

    this.recallMembers.emit(this.memberIdsToRecall);
  }

  onClickEditButton(): void {
    this.startEditing.emit();
  }

  onClickCopyButton(): void {
    this.copyPoll.emit();
  }

  onClickStartButton(): void {
    this.startPoll.emit();
  }

  onClickDeclineButton(): void {
    this.declinePoll.emit();
  }

  onClickDeleteButton(): void {
    if (!this.data) return;

    this.deletePoll.emit(this.data.id);
  }

  onSelectedUserIdChange(userId?: ObjectId): void {
    this.router.navigate(['association-users', userId]);
  }

  getStats() {
    this.statistics.emit();
  }

  private mapPollUIToFormData(poll: PollFullView) {
    const { titleText, descriptionText, pollDuration, dateStart, dateEnd, committeeId } = poll;

    const dateStartNormalize = dateStart ? new Date(dateStart) : undefined; // TODO: delete after fix nswag DateTime format
    const dateEndNormalize = dateEnd ? new Date(dateEnd) : undefined; // TODO: delete after fix nswag DateTime format

    return <FormData>{
      titleText,
      descriptionText,
      pollDuration,
      dateStart: dateStartNormalize,
      dateEnd: dateEndNormalize,
      committeeId,
    };
  }

  getLinkToClipboard() {
    return `${APP_CONFIG.baseUrl}/polls/${this.data?.id}`;
  }
}
