/**
 * Unions API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Роль пользователя с наименованием.
 */
export interface ParticipantOfEventWRoleName { 
    /**
     * ID пользователя.
     */
    userId: string;
    /**
     * ID роли участника мероприятия.
     */
    participantRoleId?: string;
    /**
     * Комметарий к участнику.
     */
    participantComment?: string;
    /**
     * Наименование роли участника мероприятия.
     */
    participantRoleName?: string;
}

