/**
 * Unions API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { UserRepliesWithAlternates } from './userRepliesWithAlternates';


/**
 * Ответы пользователя на опрос.
 */
export interface UserReplies { 
    /**
     * ID опроса.
     */
    pollId?: string;
    /**
     * Варианты ответов (массив для множественного выбора).
     */
    repliesWithAlternates?: Array<UserRepliesWithAlternates>;
}

