import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Self,
  ViewChild,
} from '@angular/core';
import { NgControl } from '@angular/forms';
import { BehaviorSubject, map, startWith, Subject, switchMap } from 'rxjs';
import { AbstractTuiControl, TUI_DEFAULT_MATCHER, TuiIdentityMatcher, TuiStringHandler } from '@taiga-ui/cdk';
import { TuiSizeL, TuiSizeS } from '@taiga-ui/core';
import { TuiMultiSelectComponent, TuiSelectComponent } from '@taiga-ui/kit';
import { OrganisationUI } from '@src/models';
import { Nullable } from '@src/types/utils';
import { OrganisationService } from '@src/core/services';

import { OrganisationInputValue } from '../../types';

@Component({
  selector: 'app-organisation-input',
  templateUrl: './organisation-input.component.html',
  styleUrls: ['./organisation-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrganisationInputComponent
  extends AbstractTuiControl<OrganisationInputValue>
  implements OnInit, OnDestroy
{
  /**
   * Режим работы:
   * - false - выбор одной записи
   * - true - выбор нескольких записей
   * */
  @Input() multiple = false;

  @Input() textfieldSize: TuiSizeL | TuiSizeS = 'm';
  @Input() textfieldCleaner = true;

  @ViewChild(TuiSelectComponent)
  private readonly singleInputRef?: TuiSelectComponent<OrganisationUI>;

  @ViewChild(TuiMultiSelectComponent)
  private readonly multipleInputRef?: TuiMultiSelectComponent<OrganisationUI[]>;

  private readonly destroyed$$ = new Subject<void>();

  searchQuery = '';
  readonly search$ = new BehaviorSubject<string>('');

  readonly items$ = this.organisationService.organisationList$;
  readonly stringify: TuiStringHandler<string> = id => this.items$.value?.find(item => item.id === id)?.shortName ?? '';
  readonly matcher: TuiIdentityMatcher<string> = (id1, id2) => id1 === id2;

  readonly filteredItems$ = this.search$.pipe(
    startWith(''),
    switchMap(search =>
      this.items$.pipe(
        map(items => {
          if (!!items) {
            return items.filter(({ fullName }) => TUI_DEFAULT_MATCHER(fullName, search));
          }

          return items;
        }),
      ),
    ),
  );

  constructor(
    @Optional()
    @Self()
    @Inject(NgControl)
    control: NgControl | null,
    @Inject(ChangeDetectorRef) cdr: ChangeDetectorRef,
    private organisationService: OrganisationService,
  ) {
    super(control, cdr);
  }

  get focused(): boolean {
    if (!this.multiple && this.singleInputRef) {
      return this.singleInputRef.focused;
    }

    if (this.multiple && this.multipleInputRef) {
      return this.multipleInputRef.focused;
    }

    return false;
  }

  protected getFallbackValue(): OrganisationInputValue {
    // TODO: this.mode === undefined. Возможно this.mode не успевает подтянуться
    if (!this.multiple) {
      return undefined;
    }

    return [];
  }

  ngOnInit(): void {
    this.getItems();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();

    this.destroyed$$.next();
    this.destroyed$$.complete();
  }

  setValue(id: string) {
    this.value = id;
  }

  getValue() {
    if (!this.multiple) {
      return this.value ? this.value : null;
    }

    return this.value;
  }

  onSearchChange(search: Nullable<string>) {
    this.searchQuery = search || '';
    this.search$.next(this.searchQuery);
    this.cdr.markForCheck();
  }

  private getItems() {
    this.organisationService.getOrganisationList();
  }
}
