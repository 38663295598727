import { Injectable } from '@angular/core';
import { from, Observable, of, Subject } from 'rxjs';
import { concatMap, takeUntil } from 'rxjs/operators';
import { File } from '@airgram/core/types/outputs';
import { FilePart } from '@airgram/web';
import { TelegramMessengerService } from '@src/app/modules/telegram';

@Injectable({
  providedIn: 'root',
})
export class FileReaderService {
  updateFile$: Subject<File | undefined> = new Subject();

  private destroyed$$: Subject<void> = new Subject<void>();

  constructor(private messengerService: TelegramMessengerService) {
    this.messengerService.updates$.pipe(takeUntil(this.destroyed$$)).subscribe(async update => {
      switch (update._) {
        case 'updateFile':
          const { file } = update;
          this.updateFile$?.next(file);
          break;

        default:
          break;
      }
    });
  }

  getFilePart(file?: File): Observable<FilePart | undefined> {
    if (!file) return of(undefined);

    if (!file?.local?.path || file.local.downloadedSize !== file.expectedSize) {
      return from(this.messengerService.api.downloadFile(file.id, 1, file?.local.downloadedSize)).pipe(
        concatMap(downloadedFile => {
          return from(this.messengerService.api.readFilePart(downloadedFile.id));
        }),
      );
    }

    return from(this.messengerService.api.readFilePart(file.id));
  }

  async downloadFile(fileId?: number, priority?: number) {
    if (!fileId) return;
    return this.messengerService.api.downloadFile(fileId, priority);
  }

  getFileSource(filePart?: FilePart): string {
    if (!filePart?.data) {
      return '';
    }

    // TODO remove 'as any' (requires Airgram API update)
    return window.URL?.createObjectURL(filePart.data as any);
  }
}
