<form class="mail-form" [formGroup]="form">
  <div class="fields">
    <div class="fields-block">
      <app-badge
        *ngIf="!!data"
        class="field"
        [class.tui-skeleton]="loading"
        [class.tui-skeleton_text]="loading"
        [value]="statusOptions.value"
        [color]="statusOptions.color"
      ></app-badge>

      <app-label [label]="'Email' + getRequiredSign('email')">
        <tui-input
          class="field"
          [class.tui-skeleton]="loading"
          [class.tui-skeleton_text]="loading"
          formControlName="email"
          tuiAutoFocus
          [tuiTextfieldSize]="tuiElementLargeSize"
          [tuiTextfieldLabelOutside]="true"
        ></tui-input>
      </app-label>

      <app-label [label]="('components.mail.fields.password' | translate) + getRequiredSign('password')">
        <tui-input
          class="field"
          [class.tui-skeleton]="loading"
          [class.tui-skeleton_text]="loading"
          formControlName="password"
          [tuiTextfieldSize]="tuiElementLargeSize"
          [tuiTextfieldLabelOutside]="true"
        ></tui-input>
      </app-label>
    </div>

    <div class="fields-block">
      <app-label [label]="'SMTP' + getRequiredSign('smtp')">
        <tui-input
          class="field"
          [class.tui-skeleton]="loading"
          [class.tui-skeleton_text]="loading"
          formControlName="smtp"
          [tuiTextfieldSize]="tuiElementLargeSize"
          [tuiTextfieldLabelOutside]="true"
        ></tui-input>
      </app-label>

      <app-label [label]="('components.mail.fields.smtpPort' | translate) + getRequiredSign('smtpPort')">
        <tui-input-number
          class="field"
          [class.tui-skeleton]="loading"
          [class.tui-skeleton_text]="loading"
          formControlName="smtpPort"
          [tuiTextfieldSize]="tuiElementLargeSize"
          [tuiTextfieldLabelOutside]="true"
          [decimal]="'never'"
        ></tui-input-number>
      </app-label>
    </div>

    <div class="fields-block">
      <app-label [label]="('components.mail.fields.userName' | translate) + getRequiredSign('ntlmUser')">
        <tui-input
          class="field"
          [class.tui-skeleton]="loading"
          [class.tui-skeleton_text]="loading"
          formControlName="ntlmUser"
          [tuiTextfieldSize]="tuiElementLargeSize"
          [tuiTextfieldLabelOutside]="true"
        ></tui-input>
      </app-label>

      <app-label [label]="('components.mail.fields.domain' | translate) + getRequiredSign('ntlmDomain')">
        <tui-input
          class="field"
          [class.tui-skeleton]="loading"
          [class.tui-skeleton_text]="loading"
          formControlName="ntlmDomain"
          [tuiTextfieldSize]="tuiElementLargeSize"
          [tuiTextfieldLabelOutside]="true"
        ></tui-input>
      </app-label>

      <div
        class="field toggle"
        [class.tui-skeleton]="loading"
        [class.tui-skeleton_text]="loading"
        [ngClass]="'toggle-size_' + tuiElementLargeSize"
      >
        {{ ('components.mail.fields.useSSL' | translate) + getRequiredSign('useSSL') }}
        <app-toggle formControlName="useSSL" [size]="tuiElementLargeSize"></app-toggle>
      </div>
    </div>
  </div>

  <div class="block-title">{{ 'components.mail.labels.proxySettings' | translate }}</div>
  <div class="fields">
    <div class="fields-block">
      <app-label [label]="('components.mail.fields.server' | translate) + getRequiredSign('proxyHost')">
        <tui-input
          class="field"
          [class.tui-skeleton]="loading"
          [class.tui-skeleton_text]="loading"
          formControlName="proxyHost"
          [tuiTextfieldSize]="tuiElementLargeSize"
          [tuiTextfieldLabelOutside]="true"
        ></tui-input>
      </app-label>

      <app-label [label]="('components.mail.fields.port' | translate) + getRequiredSign('proxyPort')">
        <tui-input-number
          class="field"
          [class.tui-skeleton]="loading"
          [class.tui-skeleton_text]="loading"
          formControlName="proxyPort"
          [tuiTextfieldSize]="tuiElementLargeSize"
          [tuiTextfieldLabelOutside]="true"
          [decimal]="'never'"
        ></tui-input-number>
      </app-label>

      <app-label [label]="('components.mail.fields.login' | translate) + getRequiredSign('proxyLogin')">
        <tui-input
          class="field"
          [class.tui-skeleton]="loading"
          [class.tui-skeleton_text]="loading"
          formControlName="proxyLogin"
          [tuiTextfieldSize]="tuiElementLargeSize"
          [tuiTextfieldLabelOutside]="true"
        ></tui-input>
      </app-label>

      <app-label [label]="('components.mail.fields.password' | translate) + getRequiredSign('proxyPassword')">
        <tui-input
          class="field"
          [class.tui-skeleton]="loading"
          [class.tui-skeleton_text]="loading"
          formControlName="proxyPassword"
          [tuiTextfieldSize]="tuiElementLargeSize"
          [tuiTextfieldLabelOutside]="true"
        ></tui-input>
      </app-label>

      <app-label [label]="('components.mail.fields.domain' | translate) + getRequiredSign('proxyDomain')">
        <tui-input
          class="field"
          [class.tui-skeleton]="loading"
          [class.tui-skeleton_text]="loading"
          formControlName="proxyDomain"
          [tuiTextfieldSize]="tuiElementLargeSize"
          [tuiTextfieldLabelOutside]="true"
        ></tui-input>
      </app-label>
    </div>
  </div>

  <div *ngIf="!loading" class="actions">
    <button
      *ngIf="!!data"
      tuiButton
      class="action-button"
      type="button"
      appearance="outline-primary"
      [showLoader]="testMailLoading"
      [disabled]="testMailLoading"
      [size]="tuiElementLargeSize"
      (click)="onClickSendTestMail()"
      icon="tuiIconMailLarge"
    >
      {{ 'components.mail.buttons.sendTestLetter' | translate }}
    </button>

    <button
      tuiButton
      class="action-button"
      type="button"
      appearance="primary"
      [size]="tuiElementLargeSize"
      (click)="onClickSaveButton()"
    >
      {{ 'common.buttons.save' | translate }}
    </button>

    <button
      *ngIf="form.dirty"
      tuiButton
      class="action-button space-up"
      type="button"
      appearance="outline-primary"
      [size]="tuiElementLargeSize"
      (click)="onClickCancelButton()"
    >
      {{ 'common.buttons.cancelChanges' | translate }}
    </button>

    <button
      *ngIf="!!data"
      tuiButton
      class="action-button"
      [class.space-up]="!form.dirty"
      type="button"
      appearance="outline-accent"
      [size]="tuiElementLargeSize"
      (click)="onClickDeleteButton()"
    >
      {{ 'common.buttons.delete' | translate }}
    </button>
  </div>
</form>
