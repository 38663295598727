import { Nullable } from '@src/types/utils';
import { fromEvent, Subject } from 'rxjs';
import { debounceTime, map, takeUntil } from 'rxjs/operators';
import { ChangeDetectorRef } from '@angular/core';

import { NavigationType, NavButtonScrollType } from './types';

export class NavigationService {
  private showScrollLeftTopButton = false;
  private showScrollRightBottomButton = false;

  private scrollElement: Nullable<HTMLElement> = null;

  private destroyed$$: Subject<void> = new Subject<void>();

  constructor(
    private readonly type: NavigationType,
    private readonly scrollStep: number,
    private readonly cdr: ChangeDetectorRef,
  ) {}

  destroy(): void {
    this.destroyed$$.next();
    this.destroyed$$.complete();
  }

  setScrollElement(scrollElement: Nullable<HTMLElement>) {
    this.scrollElement = scrollElement;

    if (this.scrollElement) {
      setTimeout(() => {
        const { scrollWidth, clientHeight, scrollLeft, scrollTop, clientWidth, scrollHeight } = this.scrollElement!;

        if (this.type === 'horizontal') {
          this.showScrollRightBottomButton = scrollLeft + clientWidth < scrollWidth;
        } else {
          this.showScrollRightBottomButton = scrollTop + clientHeight < scrollHeight;
        }

        this.cdr.markForCheck();
      }, 0);

      fromEvent<Event>(this.scrollElement, 'scroll')
        .pipe(
          debounceTime(100),
          map(event => {
            if (event.target) {
              const { scrollWidth, clientHeight, scrollLeft, scrollTop, clientWidth, scrollHeight } =
                event.target as HTMLElement;

              return {
                showLeftTopButton: this.type === 'horizontal' ? scrollLeft > 0 : scrollTop > 0,
                showRightBottomButton:
                  this.type === 'horizontal'
                    ? scrollLeft + clientWidth < scrollWidth
                    : scrollTop + clientHeight < scrollHeight,
              };
            }

            return null;
          }),
          takeUntil(this.destroyed$$),
        )
        .subscribe(result => {
          if (result) {
            if (
              this.showScrollLeftTopButton !== result.showLeftTopButton ||
              this.showScrollRightBottomButton !== result.showRightBottomButton
            ) {
              this.showScrollLeftTopButton = result.showLeftTopButton;
              this.showScrollRightBottomButton = result.showRightBottomButton;
              this.cdr.markForCheck();
            }
          }
        });
    }
  }

  scrollPanel(type: NavButtonScrollType) {
    if (this.scrollElement) {
      const { scrollWidth, scrollHeight, scrollLeft, scrollTop } = this.scrollElement;

      const size = this.type === 'horizontal' ? scrollWidth : scrollHeight;
      const scrollSize = this.type === 'horizontal' ? scrollLeft : scrollTop;

      const position =
        type === 'forward-bottom'
          ? Math.min(size, scrollSize + this.scrollStep)
          : Math.max(0, scrollSize - this.scrollStep);

      this.scrollElement.scrollTo({
        behavior: 'smooth',
        left: this.type === 'horizontal' ? position : undefined,
        top: this.type === 'vertical' ? position : undefined,
      });
    }
  }

  get isShowScrollLeftTopButton() {
    return this.showScrollLeftTopButton;
  }

  get isShowScrollRightBottomButton() {
    return this.showScrollRightBottomButton;
  }
}
