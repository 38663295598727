import {
  Component,
  OnChanges,
  ChangeDetectionStrategy,
  Input,
  SimpleChanges,
  Output,
  EventEmitter,
  OnInit,
  HostListener,
  ViewChildren,
  QueryList,
} from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MaskitoOptions } from '@maskito/core';

import { ItemForm } from './types';
import { TuiInputComponent } from '@taiga-ui/kit';
import { EMPTY_QUERY } from '@taiga-ui/cdk';

@Component({
  selector: 'app-input-code',
  templateUrl: './input-code.component.html',
  styleUrls: ['./input-code.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputCodeComponent implements OnChanges, OnInit {
  /** Длина кода */
  @Input() codeLength: number = 1;

  /** Флаг, что код не прошел проверку */
  @Input() codeInvalid: boolean = false;
  @Output() codeInvalidChange: EventEmitter<boolean> = new EventEmitter();

  /** Код */
  @Input() code: string = '';
  @Output() codeChange: EventEmitter<string> = new EventEmitter();

  /** Параметр для автоматической отправки кода */
  @Output() codeIsReady: EventEmitter<void> = new EventEmitter();

  @ViewChildren(TuiInputComponent, { read: TuiInputComponent })
  private codeInputs: QueryList<TuiInputComponent> = EMPTY_QUERY;

  /** Маска для ввода значения кода */
  readonly CODE_MASK_OPTIONS: MaskitoOptions = {
    mask: [/\d/],
  };

  form: UntypedFormGroup;
  currentItemIndex: number = 0;

  constructor(private formBuilder: UntypedFormBuilder) {
    this.form = this.formBuilder.group({
      items: this.formBuilder.array([]),
    });
  }

  get items(): UntypedFormArray {
    return this.form.get('items') as UntypedFormArray;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['codeLength']) {
      for (let i = 0; i < this.codeLength; i++) {
        this.items.push(this.addItem());
      }
    }

    if (changes['codeInvalid']) {
      this.form.markAllAsTouched();

      setTimeout(() => {
        this.clearItemsValue();
        this.setFocus(0);
      }, 300);
    }
  }

  ngOnInit(): void {
    this.items.valueChanges.subscribe((values: ItemForm[]) => {
      this.setCodeInvalid(false);
      this.code = values.map(item => item.value).join('');
      this.codeChange.emit(this.code);
    });

    setTimeout(() => {
      this.setFocus(0);
    }, 100);
  }

  onChangeItem(indexItem: number) {
    if (indexItem === this.items.length - 1) {
      if (this.code.length === this.items.length) {
        this.codeIsReady.emit();
      }
    } else {
      // Ставим фокус на следующее поле
      this.setFocus(indexItem + 1);
    }
  }

  private setFocus(index: number) {
    this.codeInputs.get(index)?.nativeFocusableElement?.focus();
    this.currentItemIndex = index;
  }

  private addItem(): UntypedFormGroup {
    return this.formBuilder.group({ value: [''] });
  }

  private clearItemsValue() {
    this.items.controls.forEach(item => {
      item.setValue({ value: '' });
    });
  }

  private clearItemValue(index: number) {
    this.items.controls[index].setValue({ value: '' });
  }

  private setCodeInvalid(value: boolean) {
    this.codeInvalid = value;
    this.codeInvalidChange.emit(value);
  }

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent): void {
    if (event.key === 'Backspace' && this.currentItemIndex > 0) {
      this.clearItemValue(this.currentItemIndex - 1);
      this.setFocus(this.currentItemIndex - 1);
    }
  }
}
