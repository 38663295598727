/**
 * Unions API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AddLoyaltyCategory } from './addLoyaltyCategory';


/**
 * Добавить запись в справочник ГРУПП категорий продуктов - первый уровень
 */
export interface AddLoyaltyCategoryGroupWithCatrgories { 
    /**
     * Наименование.
     */
    name: string;
    /**
     * Описание.
     */
    description?: string;
    /**
     * ID фотографии/иконки.
     */
    iconId?: string;
    /**
     * Сортировка.
     */
    sortOrder?: number;
    /**
     * справочник категорий продуктов - второй уровень
     */
    loyaltyCategories?: Array<AddLoyaltyCategory>;
}

