<div class="settings">
  <div class="settings__header">
    <button
      *ngIf="!showFullPage && selectedItem"
      tuiIconButton
      class="settings__return-button"
      type="button"
      appearance="icon"
      icon="tuiIconArrowLeftLarge"
      size="s"
      [title]="'common.buttons.return' | translate"
      (click)="onClickReturnButton()"
    ></button>

    <h1 class="settings__title">
      {{
        selectedItem && !showFullPage
          ? (selectedItem.title | translate)
          : ('components.navigation.constants.navigationItems.settingsTitle' | translate)
      }}
    </h1>

    <app-hint
      *ngIf="selectedItem && !showFullPage && selectedItem?.hint"
      class="hint"
      [value]="selectedItem.hint ?? ''"
    ></app-hint>
  </div>

  <tui-scrollbar [hide]="!showFullPage" class="settings__list">
    <app-navigation-list
      [navTree]="navTree"
      [(selectedItem)]="selectedItem"
      [descriptionVisible]="true"
    ></app-navigation-list>
  </tui-scrollbar>

  <!-- TODO: refactoring block -->
  <div class="settings__content">
    <ng-container *ngIf="selectedItem?.id !== 'menu.administration'; else administrationTmp">
      <div class="body">
        <div *ngIf="showFullPage && !!selectedItem?.title" class="body__header">
          <h1 class="body__title">{{ selectedItem?.title ?? '' | translate }}</h1>
          <app-hint *ngIf="selectedItem?.hint" class="hint" [value]="selectedItem?.hint"></app-hint>
        </div>

        <tui-scrollbar class="body__content">
          <router-outlet></router-outlet>
        </tui-scrollbar>
      </div>
    </ng-container>

    <ng-template #administrationTmp>
      <router-outlet></router-outlet>
    </ng-template>
  </div>
</div>
