import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';
import { takeUntil } from 'rxjs';
import { OrganisationView } from '@src/api';
import { BreakpointObserverHelperService, OrganisationService, UserService } from '@src/core/services';
import { SessionService } from '@src/app/modules/auth';
import { reload } from '@src/utils';
import { ResizableBaseComponent } from '@src/app/components';

import { NavigationMode } from '../types';

@Component({
  selector: 'association-menu',
  templateUrl: './association-menu.component.html',
  styleUrls: ['./association-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AssociationMenuComponent extends ResizableBaseComponent {
  @Input() isOpen?: boolean = true;
  @Input() mode?: NavigationMode;

  currentParentOrganisation?: OrganisationView;

  readonly authUser$ = this.userService.authUser$;
  readonly parentOrganisations$ = this.organisationService.parentOrganisations$;

  constructor(
    readonly cdr: ChangeDetectorRef,
    readonly breakpointObserver: BreakpointObserver,
    readonly breakpointObserverHelperService: BreakpointObserverHelperService,
    private readonly userService: UserService,
    private readonly organisationService: OrganisationService,
    private readonly sessionService: SessionService,
  ) {
    super(cdr, breakpointObserver, breakpointObserverHelperService);
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.authUser$.pipe(takeUntil(this.destroyed$$)).subscribe(user => {
      if (!user?.parentOrganisationId) return;

      this.currentParentOrganisation = this.parentOrganisations$.value.find(
        parentOrganisation => parentOrganisation.id === user.parentOrganisationId,
      );

      this.cdr.markForCheck();
    });

    this.parentOrganisations$.pipe(takeUntil(this.destroyed$$)).subscribe(parentOrganisations => {
      if (!parentOrganisations.length) return;

      this.currentParentOrganisation = parentOrganisations.find(
        parentOrganisation => parentOrganisation.id === this.authUser$.value?.parentOrganisationId,
      );
      this.cdr.markForCheck();
    });

    this.userService.getAuthorizedUser();
    this.organisationService.getParentOrganisation(true);
  }

  changeCurrentParentOrganisation(selectedParentOrganisation: OrganisationView): void {
    if (selectedParentOrganisation.id) {
      this.sessionService.changeToken(selectedParentOrganisation.id).subscribe(() => {
        this.currentParentOrganisation = selectedParentOrganisation;
        reload();
      });
    }
  }
}
