import { GetReportForUnionResultDto } from '@src/api';

export type ReportType = 'association' | 'users' | 'organisations' | 'committees' | 'events' | 'polls' | 'newsletters';

export enum EntityTypeCode {
  /** 0 - association - Ассоциация - 0 */
  Association = 0,

  /** 1 - users - Сотрудник - 10 */
  Users = 1,

  /** 2 - organisations - Организация - 20 */
  Organisations = 2,

  /** 3 - committees - Комитет - 30 */
  Committees = 3,

  /** 4 - events - Мероприятие - 40 */
  Events = 4,

  /** 5 - polls - Опрос - 50 */
  Polls = 5,

  /** 6 - newsletters - Рассылка - 60 */
  Newsletters = 6,
}

export type GetReportForUnionResultDtoUI = GetReportForUnionResultDto & {
  /** Данные для запуска отчета */
  reportData?: Object;

  /** Данные, которые нужно передать для запуска отчета, но не нужно отображать на форме */
  hiddenReportData?: Object;
};
