import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { lastValueFrom, Subject, throwError } from 'rxjs';
import { catchError, takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { PolymorpheusContent } from '@tinkoff/ng-polymorpheus';
import { TUI_NUMBER_FORMAT, TuiDialogContext, TuiDialogService } from '@taiga-ui/core';
import { TuiSheetOptions } from '@taiga-ui/addon-mobile';
import { TokenService, TokenModel } from '@src/api';
import { SessionService, StorageService } from '@src/app/modules/auth';
import { TelegramWebAppService } from '@src/app/modules/telegram';
import { EnvService } from '@src/app/modules/env';
import { environment } from '@src/environments/environment';
import { AlertService } from '@src/core/services';
import { UserUI } from '@src/models';
import { ActivatedRoute } from '@angular/router';
import { StorageKeys } from '@src/constants/storage';

import { OrganisationShortInfoUI } from '../branding';

import { convertBrandingDataToDownloadLinks } from './utils/convertBrandingDataToDownloadLinks';
import { DownloadLinksComponentOptions } from './components/download-links/types';
import { DEFAULT_DOWNLOAD_LINKS } from './components/download-links/constants';
import { EnterType, LoginEnterType } from './login.model';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: TUI_NUMBER_FORMAT,
      useValue: { thousandSeparator: '' },
    },
  ],
})
export class LoginComponent implements OnInit, OnDestroy {
  /** Данные по брендированию */
  brandingData?: OrganisationShortInfoUI | null;

  @ViewChild('tmpWelcomeMessages') welcomeMessagesDialog?: PolymorpheusContent<TuiDialogContext>;

  /** Тип входа в приложение */
  enterType: LoginEnterType = this.env.isBot ? EnterType.Bot : EnterType.Email;

  /** Номер телефона */
  phoneNumber = '';

  /** Открыта политика конфиденциальности */
  openPolicy = false;

  /** Номер версии приложения */
  readonly APP_VERSION = environment.appVersion;

  /** Флаг отображения шторки скачивания приложения, если зашли под мобильной платформой */
  appSheetOpen = false;

  authUser?: UserUI;
  loading = false;

  readonly appSheetOptions: Partial<TuiSheetOptions> = {
    overlay: true,
  };

  downloadLinksOptions?: DownloadLinksComponentOptions = DEFAULT_DOWNLOAD_LINKS;

  readonly EnterType = EnterType;

  private destroyed$$: Subject<void> = new Subject<void>();

  constructor(
    private cdr: ChangeDetectorRef,
    private session: SessionService,
    private tokenService: TokenService,
    private telegramWebAppService: TelegramWebAppService,
    private readonly alertService: AlertService,
    private readonly storageService: StorageService,
    private readonly translateService: TranslateService,
    public readonly env: EnvService,
    private readonly route: ActivatedRoute,
    @Inject(TuiDialogService) private readonly dialogService: TuiDialogService,
  ) {}

  async ngOnInit(): Promise<void> {
    this.storageService.setItem(StorageKeys.EnterType, EnterType.Email);

    if (this.session.isBrand) {
      this.brandingData = this.session.brandingData;

      if (this.brandingData) {
        this.downloadLinksOptions = convertBrandingDataToDownloadLinks(this.brandingData);
      } else {
        this.downloadLinksOptions = undefined;
      }
    }

    this.appSheetOpen =
      (this.env.isAndroidWithoutCordova && !!this.downloadLinksOptions?.androidDownloadLink) ||
      (this.env.isIosWithoutCordova && !!this.downloadLinksOptions?.iosDownloadLink);

    if (this.telegramWebAppService.isRunAsTelegramWebApp()) {
      // Работаем из под Telegram бота
      const token = await this.getTokenForTgWebApp(this.brandingData?.id);
      this.onApplyToken(token);
    } else {
      this.enterType = EnterType.Email;
    }

    this.route.queryParamMap
      .pipe(takeUntil(this.destroyed$$))
      .subscribe(query => {
        this.openPolicy = query.get('page') === 'policy';
        this.cdr.markForCheck();
      })
      .unsubscribe();

    this.cdr.markForCheck();
  }

  ngOnDestroy(): void {
    this.destroyed$$.next();
    this.destroyed$$.complete();
  }

  async getTokenForTgWebApp(associationId?: string) {
    // TODO: возможно нужно обращение к api вынести в TelegramWebAppService. тут оставить только подписку и обработку токена
    return lastValueFrom(
      this.tokenService.getTokenForTApp({ initData: this.telegramWebAppService.getData(), associationId }).pipe(
        catchError(errorMessage => {
          this.alertService.error(errorMessage, {
            label: this.translateService.instant('components.login.alerts.errors.tokenInvalid'),
            autoClose: false,
          });
          return throwError(errorMessage);
        }),
        takeUntil(this.destroyed$$),
      ),
    );
  }

  onApplyToken(token: TokenModel | null) {
    if (token) {
      this.session.createSession(token, true).then(user => this.onCreateSession(user));
    }
  }

  onCreateSession(user: UserUI): void {
    // TODO: зачем нам нужна переменная this.authUser ?
    this.authUser = user;
    if (user.isFirstSignIn && !user.permissions?.includes('showOnlyLoyaltyProgram') && this.welcomeMessagesDialog) {
      this.dialogService
        .open(this.welcomeMessagesDialog, {
          closeable: false,
          dismissible: false,
          size: 'm',
        })
        .subscribe();
    }
  }

  onLoading(loading: boolean) {
    this.loading = loading;
    this.cdr.markForCheck();
  }
}
