<div class="moderation-users">
  <div class="moderation-users__content content">
    <div class="content__header">
      <button
        *ngIf="!showFullPage && selectedUserId"
        tuiIconButton
        class="content__return-button"
        type="button"
        appearance="icon"
        icon="tuiIconArrowLeftLarge"
        size="s"
        [title]="'common.buttons.return' | translate"
        (click)="onClickReturnButton()"
      ></button>
      <ng-container *ngIf="showFullPage">
        <h1 class="content__title">{{ 'components.moderationUsers.labels.moderationUsersList' | translate }}</h1>
      </ng-container>
    </div>

    <div [hide]="!showFullPage" class="content__list">
      <app-new-list
        [items]="moderationUsers$ | async"
        [itemContentTmp]="userTmp"
        [(selectedId)]="selectedUserId"
        (selectedIdChange)="onChangeSelectedUserId($event)"
      ></app-new-list>
    </div>

    <tui-scrollbar *ngIf="selectedUserId" class="content__content">
      <app-user-info-moderation
        [registrationFormId]="selectedUserId"
        (canceled)="reload()"
        (saved)="reload()"
        (rejected)="reload()"
      ></app-user-info-moderation>
    </tui-scrollbar>
  </div>
</div>

<ng-template #userTmp let-item>
  <new-list-item-moderation-user [user]="item"></new-list-item-moderation-user>
</ng-template>
