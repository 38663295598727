import { OrganisationJobTitleUI } from '@src/models';

export const normalizeJobTitles = (organisationJobTitles?: OrganisationJobTitleUI[]): OrganisationJobTitleUI[] => {
  const normalizeJobTitles: OrganisationJobTitleUI[] = [];

  organisationJobTitles?.forEach(organisationJobTitle => {
    if (organisationJobTitle.jobTitleDescription) {
      const findNormalizeJobTitle = normalizeJobTitles.find(
        normalizeJobTitle => normalizeJobTitle.organisationId === organisationJobTitle.organisationId,
      );
      if (findNormalizeJobTitle) {
        findNormalizeJobTitle.jobTitles?.push(organisationJobTitle.jobTitleDescription);
        findNormalizeJobTitle.jobTitlesFull?.push({
          id: organisationJobTitle.jobTitleId,
          description: organisationJobTitle.jobTitleDescription,
        });
        return;
      } else if (organisationJobTitle.jobTitleDescription) {
        if (!organisationJobTitle.jobTitles) {
          organisationJobTitle.jobTitles = [];
        }
        if (!organisationJobTitle.jobTitlesFull) {
          organisationJobTitle.jobTitlesFull = [];
        }
        organisationJobTitle.jobTitles.push(organisationJobTitle.jobTitleDescription);
        organisationJobTitle.jobTitlesFull.push({
          id: organisationJobTitle.jobTitleId,
          description: organisationJobTitle.jobTitleDescription,
        });
      }
    }
    normalizeJobTitles.push(organisationJobTitle);
  });

  return normalizeJobTitles;
};
