import { Inject, Injectable } from '@angular/core';
import { catchError, map, throwError } from 'rxjs';
import { ParticipantRolesForUnionAdd, ParticipantRolesForUnionService } from '@src/api';
import { AlertService } from '@src/core/services';

@Injectable({
  providedIn: 'root',
})
export class RolesService {
  constructor(
    @Inject(ParticipantRolesForUnionService) private readonly roleService: ParticipantRolesForUnionService,
    @Inject(AlertService) private readonly alertService: AlertService,
  ) {}

  searchRoles(searchText: string) {
    return this.roleService.searchForPartisipantRoles(searchText).pipe(
      catchError(err => {
        this.alertService.error(err);
        return throwError(() => err);
      }),
      map(roles => roles.sort((a, b) => a.name.localeCompare(b.name))),
    );
  }

  createRole(data: ParticipantRolesForUnionAdd) {
    return this.roleService.addParticipantRoleForUnion(data).pipe(
      catchError(err => {
        this.alertService.error(err);
        return throwError(() => err);
      }),
    );
  }
}
