import { APP_CONFIG } from '@src/core';

/**
 * Утилита для проверки длины поисковой строки.
 * Если длина поисковой строки меньше порогового значения из настроек, то поиск не производится.
 *
 * @param text строка поиска
 */
export const preventSearchString = (text: string | undefined): boolean => {
  return (text || '').length > 0 && (text || '').length < APP_CONFIG.searchMinLength;
};
