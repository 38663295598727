import {
  Component,
  ChangeDetectionStrategy,
  ViewChild,
  ElementRef,
  Inject,
  ChangeDetectorRef,
  HostListener,
  Output,
  EventEmitter,
} from '@angular/core';
import { Observer } from 'rxjs';
import { PolymorpheusContent } from '@tinkoff/ng-polymorpheus';
import { TuiDialogContext, TuiDialogService } from '@taiga-ui/core';
import { NumeralPluralsPipe } from '@src/app/shared/pipes';
import {
  AttachmentItem,
  AttachmentItems,
  AttachmentItemTypes,
  AttachmentMenuIcons,
  DocumentTypes,
  MenuItem,
} from '@src/models';
import { APP_CONFIG } from '@src/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'telegram-attachment',
  templateUrl: './attachment.component.html',
  styleUrls: ['./attachment.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AttachmentComponent {
  @ViewChild('fileInput') public fileInput?: ElementRef;
  @ViewChild('confirmAttachmentDialog') confirmAttachmentDialog?: PolymorpheusContent<TuiDialogContext>;
  @Output() attachmentConfirmed: EventEmitter<AttachmentItems>;

  attachmentMenuOpened: boolean = false;
  attachmentMenuItems: MenuItem<number, AttachmentMenuIcons>[];
  currentAttachment?: File[];
  currentAttachmentPreview?: AttachmentItem[];
  currentAttachmentCaption?: string;

  constructor(
    private cdr: ChangeDetectorRef,
    private numeralPluralsPipe: NumeralPluralsPipe,
    private readonly translateService: TranslateService,
    @Inject(TuiDialogService) private readonly dialogService: TuiDialogService,
  ) {
    this.attachmentConfirmed = new EventEmitter<AttachmentItems>();
    this.attachmentMenuItems = [
      {
        id: 1,
        title: this.translateService.instant('components.telegramAttachment.labels.photoOrVideo'),
        icon: 'photo',
        action: () => this.chooseAttachment([...APP_CONFIG.fileTypes.image, ...APP_CONFIG.fileTypes.video]),
      },
      {
        id: 1,
        title: this.translateService.instant('common.labels.audio'),
        icon: 'audiotrack',
        action: () => this.chooseAttachment(APP_CONFIG.fileTypes.audio),
      },
      {
        id: 2,
        title: this.translateService.instant('common.labels.file'),
        icon: 'insert_drive_file',
        action: () => this.chooseAttachment(),
      },
    ];
  }

  @HostListener('change', ['$event.target.files'])
  fileChangeEvent(files: FileList): void {
    this.currentAttachment = Array.from(files);
    if (this.fileInput?.nativeElement) {
      this.fileInput.nativeElement.value = null;
    }

    if (this.confirmAttachmentDialog && this.currentAttachment?.length) {
      this.initAttachmentPreview();
      this.dialogService
        .open(this.confirmAttachmentDialog, {
          label: this.numeralPluralsPipe.transform(this.currentAttachment.length, [
            this.translateService.instant('components.telegramAttachment.labels.attachOne'),
            this.translateService.instant('components.telegramAttachment.labels.attachFew'),
            this.translateService.instant('components.telegramAttachment.labels.attachMany'),
          ]),
          closeable: true,
        })
        .subscribe();
    }
  }

  onClickAttachmentMenuItem(menuItem: MenuItem): void {
    this.attachmentMenuOpened = false;
    menuItem.action?.();
  }

  onClickConfirmAttachment(observer: Observer<void>): void {
    if (!this.currentAttachmentPreview) return;

    let attachments: AttachmentItems = { attachments: this.currentAttachmentPreview };
    if (this.currentAttachmentCaption) {
      attachments = { ...attachments, caption: this.currentAttachmentCaption };
    }
    this.attachmentConfirmed.emit(attachments);

    this.currentAttachmentPreview = undefined;
    this.currentAttachmentCaption = undefined;
    observer.complete();
  }

  private chooseAttachment(documentTypes?: DocumentTypes[]): void {
    const inputElement = this.fileInput?.nativeElement;
    if (!inputElement) return;

    inputElement.accept = documentTypes;
    inputElement.click();
  }

  private initAttachmentPreview(): void {
    this.currentAttachmentPreview = [];
    this.currentAttachment?.map(attachment => {
      const reader: FileReader = new FileReader();
      reader.readAsDataURL(attachment);

      reader.onload = (event: ProgressEvent<FileReader>) => {
        if (typeof event.target?.result === 'string') {
          const attachmentItem: AttachmentItem = {
            file: attachment,
            path: event.target.result,
            type: this.getAttachmentItemType(attachment),
          };
          if (this.currentAttachmentPreview) {
            this.currentAttachmentPreview = this.currentAttachmentPreview.concat([attachmentItem]);
          }
          this.cdr.markForCheck();
        }
      };
    });
  }

  private getAttachmentItemType(file: File): AttachmentItemTypes | undefined {
    const attachmentItemTypes: AttachmentItemTypes[] = ['application', 'audio', 'image', 'text', 'video'];
    for (const type of attachmentItemTypes) {
      if (file.type.match(new RegExp(`\^${type}\/*`))) {
        return type;
      }
    }

    return undefined;
  }
}
