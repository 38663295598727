import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: '[filter-accordion-title]',
  templateUrl: './filter-accordion-title.component.html',
  styleUrls: ['./filter-accordion-title.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilterAccordionTitleComponent {
  @Input() title = '';
  @Input() count = 0;
}
