<div *ngIf="mode === 'view'" class="field field_size_{{ size ? size : 'l' }}">
  <div class="field__name" [title]="'components.field.constants.fieldTypes.organisation' | translate">
    {{ 'components.field.constants.fieldTypes.organisation' | translate }} &nbsp;
  </div>
  <div class="field__value">
    <app-link
      *ngIf="organisation$ | async as organisation; else loading"
      [routerLink]="'/organisation/' + organisation.id"
      [displayText]="organisation.shortName"
    ></app-link>
    <ng-template #loading>
      <span>{{ 'common.labels.loading' | translate }}...</span>
    </ng-template>
  </div>
</div>
