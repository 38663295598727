<div *ngIf="mode === 'view'" class="field field_size_{{ size ? size : 'l' }}">
  <div class="field__name" [class.display-none]="!name" [title]="name">{{ name }} &nbsp;</div>
  <div class="field__value" [class.no-underline]="noUnderline">
    <app-link
      *ngIf="user; else empty"
      [routerLink]="'/association-users/' + user.id"
      [displayText]="user.fullName"
    ></app-link>
  </div>
</div>

<div *ngIf="mode === 'preview'">
  <app-link
    *ngIf="user; else empty"
    icon="tuiIconMail"
    iconAlign="right"
    [routerLink]="'/association-users/' + user.id"
    [pseudo]="true"
    [displayText]="user.fullName"
  ></app-link>
</div>

<ng-template #empty>
  <div class="tui-skeleton tui-skeleton_text empty">&nbsp;</div>
</ng-template>
