import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import {
  TuiDataListModule,
  TuiHostedDropdownModule,
  TuiPrimitiveTextfieldModule,
  // TuiSvgModule,
  TuiTextfieldControllerModule,
  TuiDropdownModule,
  TuiButtonModule,
} from '@taiga-ui/core';
import { TuiTabsModule } from '@taiga-ui/kit';
import { TuiActiveZoneModule } from '@taiga-ui/cdk';
import { ComponentsModule } from '@src/app/components';
import { SharedModule } from '@src/app/shared';
import { IconModule } from '@src/app/modules/icon';

import { CustomNamesTabsComponent } from './custom-names-tabs.component';
import { CustomNameComponent } from './components/custom-name/custom-name.component';
import { CustomNamesPipe } from './pipes/custom-names.pipe';

@NgModule({
  declarations: [CustomNamesTabsComponent, CustomNameComponent, CustomNamesPipe],
  imports: [
    CommonModule,
    ComponentsModule,
    ReactiveFormsModule,
    SharedModule,
    TuiHostedDropdownModule,
    TuiPrimitiveTextfieldModule,
    TuiTextfieldControllerModule,
    TuiDropdownModule,
    // TuiSvgModule,
    TuiDataListModule,
    TuiTabsModule,
    TuiButtonModule,
    TuiActiveZoneModule,
    IconModule,
  ],
  providers: [CustomNamesPipe],
  exports: [CustomNamesTabsComponent, CustomNamesPipe],
})
export class CustomNamesTabsModule {}
