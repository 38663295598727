import {
  Component,
  ChangeDetectionStrategy,
  Input,
  OnChanges,
  SimpleChanges,
  Output,
  EventEmitter,
  ChangeDetectorRef,
} from '@angular/core';
import { BotCommand, MessageModel, SponsoredMessageUI } from '@src/models';

@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class MessageComponent implements OnChanges {
  @Input() message?: MessageModel | SponsoredMessageUI;
  @Input() lastReadOutboxMessageId?: number;
  @Output() botCommandClicked: EventEmitter<BotCommand>;
  @Output() sendBotTextCommand: EventEmitter<string>;
  @Output() gotoMessage: EventEmitter<number>;
  @Output() sponsoredButtonClicked: EventEmitter<SponsoredMessageUI>;

  /**
   * Нажатие на логин пользователя в сообщении
   */
  @Output() clickUsername: EventEmitter<string> = new EventEmitter();

  /**
   * Нажатие на имя пользователя, у которого нет {username}, в этом случае передаем его {userId}
   */
  @Output() clickUserId: EventEmitter<number> = new EventEmitter();

  constructor(private cdr: ChangeDetectorRef) {
    this.botCommandClicked = new EventEmitter<BotCommand>();
    this.sendBotTextCommand = new EventEmitter<string>();
    this.gotoMessage = new EventEmitter<number>();
    this.sponsoredButtonClicked = new EventEmitter<SponsoredMessageUI>();
  }

  get isSystemMessage(): boolean {
    const contentType = this.message?.content?._;
    return contentType
      ? [
        'messageBasicGroupChatCreate',
        'messageSupergroupChatCreate',
        'messageChatChangeTitle',
        'messageChatChangePhoto',
        'messageChatDeletePhoto',
        'messageChatAddMembers',
        'messageChatJoinByLink',
        'messageChatJoinByRequest',
        'messageChatDeleteMember',
        'messagePinMessage',
      ].indexOf(contentType) > -1
      : false;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.message?.currentValue) {
      setTimeout(() => this.cdr.markForCheck(), 0);
    }
  }

  emitBotCommand(botCommand: BotCommand): void {
    botCommand.type._ = (botCommand.type as any)['@type']; // TODO: airgram bug type
    this.botCommandClicked.emit(botCommand);
  }

  emitBotTextCommand(textCommand: string): void {
    this.sendBotTextCommand.emit(textCommand);
  }

  emitClickUsername(username: string): void {
    this.clickUsername.emit(username);
  }

  emitClickUserId(userId: number): void {
    this.clickUserId.emit(userId);
  }

  onGotoMessage(id: number): void {
    this.gotoMessage.emit(id);
  }

  onClickSponsoredButton(message: SponsoredMessageUI): void {
    this.sponsoredButtonClicked.emit(message);
  }
}
