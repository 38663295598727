export type AttachmentItemTypes = 'application' | 'audio' | 'image' | 'text' | 'video';
export type AttachmentMenuIcons = 'audiotrack' | 'insert_drive_file' | 'photo';

export interface AttachmentItem {
  file: File;
  path: string;
  type?: AttachmentItemTypes;
}

export interface AttachmentItems {
  attachments: AttachmentItem[];
  caption?: string;
}
