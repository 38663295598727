import { Component, Input } from '@angular/core';
import { CommitteeUI } from '@src/models';

@Component({
  selector: 'committee-item',
  templateUrl: './committee-item.component.html',
  styleUrls: ['../../accordion-levels.component.scss', './committee-item.component.scss'],
})
export class CommitteeItemComponent {
  @Input() item?: CommitteeUI;

  constructor() {}
}
