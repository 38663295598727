import { Component, ChangeDetectionStrategy, ChangeDetectorRef, Input, EventEmitter, Output } from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';
import { BreakpointObserverHelperService } from '@src/core/services';
import { ResizableBaseComponent } from '@src/app/components/resizable-base-component';
import { LoyaltyProgramProductTerms } from '@src/app/modules/loyalty-program/models';

@Component({
  selector: 'lp-view-discount-group-row',
  templateUrl: './discount-group-row.component.html',
  styleUrls: ['./discount-group-row.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DiscountGroupRowComponent extends ResizableBaseComponent {
  @Input() discountGroup?: LoyaltyProgramProductTerms;
  @Output() getDiscount = new EventEmitter<string>();

  constructor(
    readonly cdr: ChangeDetectorRef,
    readonly breakpointObserver: BreakpointObserver,
    readonly breakpointObserverHelperService: BreakpointObserverHelperService,
  ) {
    super(cdr, breakpointObserver, breakpointObserverHelperService);
  }
}
