import { Injectable } from '@angular/core';
import { formatDate } from '@angular/common';
import { catchError, lastValueFrom, throwError } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { UserReplies, UserRepliesWithAlternates } from '@src/api';
import { AlertService, DocumentService, PollService } from '@src/core/services';
import { AnswerOptionWithAlternatesFormType, QuestionFormType } from '@src/models';
import { Language } from '@src/core';

@Injectable({
  providedIn: 'root',
})
export class QuestionsService {
  constructor(
    private readonly pollService: PollService,
    private readonly documentService: DocumentService,
    private readonly alertService: AlertService,
    private readonly translateService: TranslateService,
  ) {}

  async saveReplies(pollId: string, userAnswers: QuestionFormType[] = []) {
    const isAllReplies = !userAnswers?.find(
      question => !question.altAnswer && !question.file && !question.date && !question.answerOption,
    );

    if (!isAllReplies) {
      this.alertService.error(this.translateService.instant('components.questionsService.alerts.errors.fillReplies'));
      return;
    }

    const files = await this.upload(userAnswers);

    // TODO: сделать рефакторинг. очень сложный код
    const repliesWithAlternates: UserRepliesWithAlternates[] =
      userAnswers?.map(question => {
        const isMultipleAnswer = question.answerType?.id === 3; // checkbox
        const answer = isMultipleAnswer
          ? (question.answerOption as AnswerOptionWithAlternatesFormType[])
          : [question.answerOption];

        if (question.answerType?.type === 'date' && question.date && question.time) {
          const dateTime = new Date(
            question.date.year,
            question.date.month,
            question.date.day,
            question.time?.hours ?? 0,
            question.time?.minutes ?? 0,
          );
          question.altAnswer = formatDate(dateTime, 'dd.MM.yyyy HH:mm', Language.RU);
        }

        const answerOptions =
          answer?.map(option => {
            return {
              answerOptionId: option?.id ?? undefined,
              altAnswer: !isMultipleAnswer || option?.isAlternative ? question.altAnswer : undefined,
              fileId:
                question?.file && files ? files.find(item => item.fileName === question.file?.name)?.id : undefined,
            };
          }) || [];

        return {
          questionId: question.id,
          answerOptions,
        };
      }) || [];

    const reply: UserReplies = {
      pollId,
      repliesWithAlternates,
    };

    return this.pollService.saveReplies(reply).pipe(
      catchError(err => {
        this.alertService.error(err.error);
        return throwError(() => err);
      }),
    );
  }

  private async upload(data: QuestionFormType[]) {
    const files = data
      .map(question => {
        return question.file;
      })
      .filter(file => !!file);
    if (!files.length) return null;
    return (await this.uploadDocuments(files as File[], 10))?.files;
  }

  private uploadDocuments(docs: File[], documentType?: number) {
    if (!docs || !documentType) return;

    return lastValueFrom(this.documentService.addDocuments(docs, undefined, documentType));
  }
}
