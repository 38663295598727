<div class="theme-switcher">
  <div class="group">
    <img
      class="group__switcher"
      src="../../../assets/images/light-theme.svg"
      [class.group__switcher_active]="!(isDark$ | async)"
      (click)="onThemeChange(false)"
    />
    <div class="group__label" [class.group__label_active]="!(isDark$ | async)">
      {{ 'components.themeSwitcher.labels.lightTheme' | translate }}
    </div>
  </div>

  <div class="group">
    <img
      class="group__switcher"
      src="../../../assets/images/dark-theme.svg"
      [class.group__switcher_active]="isDark$ | async"
      (click)="onThemeChange(true)"
    />
    <div class="group__label" [class.group__label_active]="isDark$ | async">
      {{ 'components.themeSwitcher.labels.darkTheme' | translate }}
    </div>
  </div>
</div>
