import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject, zip } from 'rxjs';
import {
  ApiResponse,
  ChatFilterInfoUnion,
  ChatListUnion,
  ChatLocationInputUnion,
  isError,
  MessageUnion,
  SendMessageParams,
} from '@airgram/web';
import { AttachmentItems } from '@src/models';
import { map, takeUntil } from 'rxjs/operators';
import { AlertService } from '@src/core/services';
import { trace } from '@src/utils';
import { TranslateService } from '@ngx-translate/core';

import { TELEGRAM_LIMITS } from '../constants';
import { LoadAllChatsIdsStatus } from '../types';

import { TelegramAuthService } from './telegram-auth.service';

type GetAllChatsIds = {
  resolve: (value: number[]) => void;
  reject: (err: Error) => void;
};

@Injectable({
  providedIn: 'root',
})
export class TelegramMessengerService {
  allChatsIds$: BehaviorSubject<number[]> = new BehaviorSubject<number[]>([]);
  allChatsIdsStatus$: BehaviorSubject<LoadAllChatsIdsStatus | null> = new BehaviorSubject<LoadAllChatsIdsStatus | null>(
    null,
  );
  loadingChats$ = this.allChatsIdsStatus$.pipe(map(status => status !== 'ready'));
  chatFilters$: BehaviorSubject<ChatFilterInfoUnion[] | undefined> = new BehaviorSubject<
    ChatFilterInfoUnion[] | undefined
  >(undefined);

  public limits = TELEGRAM_LIMITS;
  private allChatsIdsStatus: LoadAllChatsIdsStatus | null = null;
  private subscribers: GetAllChatsIds[] = [];
  private allChatsIds: number[] = [];
  private destroyed$$: Subject<void> = new Subject<void>();

  constructor(
    private telegramAuth: TelegramAuthService,
    private readonly alertService: AlertService,
    private readonly translateService: TranslateService,
  ) {
    this.telegramAuth.isLogged$.pipe(takeUntil(this.destroyed$$)).subscribe(isLogged => {
      if (isLogged) {
        this.init();
      }
    });

    this.telegramAuth.updates$.pipe(takeUntil(this.destroyed$$)).subscribe(update => {
      if (update._ === 'updateChatFilters') {
        this.chatFilters$.next(update.chatFilters);
      }
      if (update._ === 'updateNewChat') {
        const newChatId = update.chat.id;

        this.addChatId(newChatId);
      }
    });
  }

  get api() {
    return this.telegramAuth.api;
  }

  get updates$() {
    return this.telegramAuth.updates$;
  }

  private getChatIds(chatList: ChatListUnion): Promise<number[]> {
    return this.api.getChats(chatList).then(({ response }) => {
      if (isError(response)) {
        throw new Error(response.message);
      }

      return response.chatIds;
    });
  }

  async createNewSupergroupChat(
    title: string,
    isChannel: boolean,
    description?: string,
    location?: ChatLocationInputUnion,
  ) {
    return this.api.createNewSupergroupChat(title, isChannel, description, location).catch(() => undefined);
  }

  private async init() {
    const loadAllChatsDetailsTrace = trace('TelegramMessengerService - loadAllChatIds');
    this.allChatsIdsStatus = 'fetching';
    this.allChatsIdsStatus$.next(this.allChatsIdsStatus);

    try {
      const [allMainChatsIds, allArchiveChatsIds] = await Promise.all([
        this.getChatIds({ _: 'chatListMain' }),
        this.getChatIds({ _: 'chatListArchive' }),
      ]);
      const allChatsIds = [...allMainChatsIds, ...allArchiveChatsIds];

      this.allChatsIds$.next(allChatsIds);
      this.allChatsIds = allChatsIds;

      this.allChatsIdsStatus = 'ready';
      this.allChatsIdsStatus$.next(this.allChatsIdsStatus);

      this.subscribers.forEach(({ resolve }) => {
        resolve(allChatsIds);
      });
    } catch (err) {
      this.alertService.error(
        this.translateService.instant('components.telegram.alerts.errors.getChatIds') + ': ' + (err as Error).message,
      );
      this.allChatsIdsStatus = 'error';
      this.allChatsIdsStatus$.next(this.allChatsIdsStatus);

      this.subscribers.forEach(({ reject }) => {
        reject(err as Error);
      });
    } finally {
      this.subscribers = [];
      loadAllChatsDetailsTrace();
    }
  }

  async getAllChatIds(force?: boolean): Promise<number[]> {
    if (this.allChatsIdsStatus === 'ready') {
      if (force) {
        // Перечитываем чаты еще раз
        this.init();
      } else {
        return this.allChatsIds;
      }
    }

    return new Promise<number[]>((resolve, reject) => {
      this.subscribers.push({ resolve, reject });
    });
  }

  async getUserFullName(userId: number) {
    const user = await this.api.getUser(userId);
    return user.lastName || user.firstName ? [user.lastName, user.firstName].join(' ') : user.username;
  }

  // TODO implement sending message album
  sendMessagesWithAttachment(
    chatId: number,
    attachmentItems: AttachmentItems,
    replyToMessageId: number = 0,
  ): Observable<ApiResponse<SendMessageParams, MessageUnion> | ApiResponse<SendMessageParams, MessageUnion>[]> {
    if (attachmentItems.attachments.length > 1) {
      return zip(
        ...attachmentItems.attachments.map((attachment, index) =>
          this.api.sendMessageWithAttachment(
            chatId,
            attachment,
            replyToMessageId,
            index ? undefined : attachmentItems.caption,
          ),
        ),
      );
    }

    return this.api.sendMessageWithAttachment(
      chatId,
      attachmentItems.attachments[0],
      replyToMessageId,
      attachmentItems.caption,
    );
  }

  async forwardMessages(chatId: number, fromChatId: number, messageIds: number[]) {
    const response = await this.api.forwardMessages(chatId, fromChatId, messageIds);

    await this.alertService.success(
      this.translateService.instant('components.telegram.alerts.successes.forwardMessages'),
    );

    return response;
  }

  async getAllBlockedMessageSendersIds() {
    let blockedSendersIds: number[] = [];
    let offset = 0;
    const limit = 100;
    let totalCount = 200;

    do {
      const res = await this.api.getBlockedMessageSenders(offset);

      const sendersIds = res.senders.map(sender => {
        if (sender._ === 'messageSenderUser') {
          return sender.userId;
        }
        if (sender._ === 'messageSenderChat') {
          return sender.chatId;
        }
        return 0;
      });

      totalCount = res.totalCount;
      blockedSendersIds = [...blockedSendersIds, ...sendersIds];
      offset += limit;
    } while (totalCount > blockedSendersIds.length);

    return blockedSendersIds;
  }

  addChatId(chatId: number) {
    if (!this.allChatsIds.includes(chatId)) {
      this.allChatsIds.push(chatId);
      this.allChatsIds$.next(this.allChatsIds);
    }
  }

  removeChatId(chatId: number) {
    if (this.allChatsIds.includes(chatId)) {
      this.allChatsIds = this.allChatsIds.filter(id => id !== chatId);
      this.allChatsIds$.next(this.allChatsIds);
    }
  }

  isLoadedAllChats(): boolean | null {
    return this.allChatsIdsStatus$.value === 'ready';
  }
}
