import { Component, ChangeDetectionStrategy, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { EnvService } from '@src/app/modules/env';

@Component({
  selector: 'app-auth-dialog',
  templateUrl: './auth-dialog.component.html',
  styleUrls: ['./auth-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AuthDialogComponent {
  @Output() completed = new EventEmitter<void>();

  constructor(private readonly router: Router, public readonly env: EnvService) {}

  onClickSecondaryButton() {
    this.router.navigate(['settings', 'profile']);
    this.completed.emit();
  }

  onClickMainButton() {
    this.completed.emit();
  }

  onClickCloseButton() {
    this.completed.emit();
  }
}
