import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { environment } from '@src/environments/environment';

import { DESKTOP_NAVIGATION } from '../constants';

@Component({
  selector: 'app-navigation-desktop',
  templateUrl: './navigation-desktop.component.html',
  styleUrls: ['./navigation-desktop.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavigationDesktopComponent {
  @Input() isOpen?: boolean;
  @Input() isBrand?: boolean;

  navTree = DESKTOP_NAVIGATION;

  appVersion = environment.appVersion;
  isProduction = environment.production;
}
