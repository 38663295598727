import { environment } from '@src/environments/environment';

export const trace = (name: string) => {
  const startTime = Date.now();

  return (...optionalParams: any[]) => {
    const time = Date.now() - startTime;

    if (!environment.production) {
      // eslint-disable-next-line no-console
      console.debug(`Tracing ------ ${name}: ${(time / 1000).toFixed(3)} sec`, ...optionalParams);
    }
  };
};
