/**
 * Unions API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ProductDiscount } from './productDiscount';


/**
 * Список применений скидок за период.
 */
export interface LoyaltyDiscountsListByPeriod { 
    /**
     * Дата начала выборки статистики.
     */
    dateStart?: string;
    /**
     * Дата конца  выборки статистики.
     */
    dateEnd?: string;
    /**
     * ID объединения.
     */
    parentOrganisationId?: string;
    /**
     * Кол-во записей.
     */
    entriesCount?: number;
    /**
     * Массив скидок.
     */
    entries?: Array<ProductDiscount>;
}

