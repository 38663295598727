/**
 * Unions API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Код скидки на продукт.
 */
export interface LoyaltyCodesForProductView { 
    /**
     * ID записи.
     */
    id?: string;
    /**
     * id привязки к продукту.
     */
    productGroupId?: string;
    /**
     * id ассоциации.
     */
    parentOrganisationId?: string;
    /**
     * id пользователя.
     */
    userId?: string;
    /**
     * текст кода.
     */
    code?: string;
    /**
     * дата запроса кода пользователем.
     */
    dateReceived?: string;
    /**
     * дата начала срока действия кода.
     */
    validSince?: string;
    /**
     * дата истечения срока действия кода.
     */
    validTill?: string;
    /**
     * статус кода (активен/удалён).
     */
    status?: number;
    /**
     * ID продукта.
     */
    productId?: string;
    /**
     * Наименование продукта.
     */
    productName?: string;
    /**
     * Наименование объявления.
     */
    productGroupName?: string;
    /**
     * ID фото продукта.
     */
    photoId?: string;
    /**
     * TRUE - реклама; иначе FALSE.
     */
    isAdvertisment?: boolean;
    /**
     * Размер скидки в %.
     */
    discountRate?: number;
    /**
     * Текстовое представление скидки.
     */
    discountText?: string;
    /**
     * Размер скидки в абсолютных величинах.
     */
    discountAbsolute?: number;
    /**
     * Условия.
     */
    discountOptions?: string;
    /**
     * Id загруженного файла изображения с QR-кодом.
     */
    discountQRCodeFileId?: string;
}

