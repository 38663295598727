import { Inject, Injectable, Injector } from '@angular/core';
import { TuiDialogService } from '@taiga-ui/core';
import { PolymorpheusComponent } from '@tinkoff/ng-polymorpheus';

import { ResultDialogComponent } from '../ui';
import { LoyaltyProgramResult } from '../types';

@Injectable({
  providedIn: 'root',
})
export class ResultDialogService {
  constructor(
    @Inject(TuiDialogService) private readonly dialogService: TuiDialogService,
    @Inject(Injector) private readonly injector: Injector,
  ) {}

  open(type: LoyaltyProgramResult) {
    return this.dialogService.open(new PolymorpheusComponent(ResultDialogComponent, this.injector), {
      data: type,
    });
  }
}
