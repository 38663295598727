import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

/**
 * Компонент для отображения описания под полями
 * @param {string} description - Отображаемое значение
 *
 * @description Оборачиваем поле в этот модуль и передаем параметр {@link description}
 */
@Component({
  selector: 'app-description',
  templateUrl: './description.component.html',
  styleUrls: ['./description.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DescriptionComponent {
  @Input() description?: string = '';
}
