import { Component, OnInit, ChangeDetectionStrategy, Output, EventEmitter } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { JobTitleUI } from 'src/models';
import { AlertService, JobTitleService } from 'src/core/services';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-job-titles',
  templateUrl: './job-titles.component.html',
  styleUrls: ['./job-titles.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class JobTitlesComponent implements OnInit {
  @Output() save: EventEmitter<JobTitleUI[]> = new EventEmitter();

  allJobTitles$: BehaviorSubject<JobTitleUI[] | null> = this.jobTitleService.allJobTitles$;

  private destroyed$$: Subject<void> = new Subject<void>();

  constructor(
    private jobTitleService: JobTitleService,
    private readonly translateService: TranslateService,
    private readonly alertService: AlertService,
  ) {}

  ngOnInit(): void {
    this.jobTitleService.getAllJobTitles();
  }

  ngOnDestroy(): void {
    this.destroyed$$.next();
    this.destroyed$$.complete();
  }

  onSave(visibleJobTitles: JobTitleUI[]) {
    this.jobTitleService
      .saveJobTitles(visibleJobTitles)
      .pipe(takeUntil(this.destroyed$$))
      .subscribe(() => {
        this.jobTitleService.getAllJobTitles();
        this.jobTitleService.getJobTitles();
        this.alertService.success(this.translateService.instant('components.jobTitles.alerts.successes.saveJobTitles'));

        this.save.emit(visibleJobTitles);
      });
  }
}
