<tui-multi-select
  #activeZoneHost="tuiActiveZone"
  [class.tui-skeleton]="!committeesTree"
  [class.tui-skeleton_text]="!committeesTree"
  [tuiTextfieldLabelOutside]="true"
  [expandable]="false"
  [editable]="false"
  [tuiTextfieldCleaner]="true"
  [tuiTextfieldSize]="size"
  [stringify]="stringify"
  [tuiDropdown]="dropdownContent"
  [tuiDropdownManual]="dropdownVisible"
  [(ngModel)]="selectedCommittees"
  (ngModelChange)="onChangeSelectedCommittee($event)"
  (tuiActiveZoneChange)="onChangeActiveZone($event)"
  (click)="onClickField()"
>
  {{ !!label ? label : ('menu.committees' | customNames) }}
</tui-multi-select>

<ng-template #dropdownContent>
  <tui-loader [inheritColor]="true" [showLoader]="!committeesTree" [tuiActiveZoneParent]="activeZoneHost">
    <app-accordion-levels
      [type]="'committee'"
      [multiple]="multiple"
      [border]="false"
      [items]="committeesTree || []"
      [disableIds]="disableSelect"
      [(selectedIds)]="selectedIds"
      (selectedIdsChange)="onChangeSelectedIds($event)"
    ></app-accordion-levels>
  </tui-loader>
</ng-template>
