/**
 * Unions API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { InsertGroupOfTypeCharacteristicsDTO } from './insertGroupOfTypeCharacteristicsDTO';


export interface InsertTypeOfObjectDTO { 
    name?: string;
    parentTypeId?: string;
    sortOrder?: number;
    externalTypeCode?: number;
    groups?: Array<InsertGroupOfTypeCharacteristicsDTO>;
}

