import {
  Component,
  ChangeDetectionStrategy,
  Inject,
  Injector,
  Input,
  OnChanges,
  ChangeDetectorRef,
  SimpleChanges,
  Output,
  EventEmitter,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { BehaviorSubject, forkJoin, lastValueFrom, Observable, Subject, throwError } from 'rxjs';
import { catchError, map, takeUntil } from 'rxjs/operators';
import { NgxPermissionsService } from 'ngx-permissions';
import { TuiDialogContext, TuiDialogService } from '@taiga-ui/core';
import { PolymorpheusContent, PolymorpheusComponent } from '@tinkoff/ng-polymorpheus';
import { differenceBy } from 'lodash-es';
import { EnvService } from '@src/app/modules/env';
import { ChatsService, DocumentView, EventWithParticipantsAdd, ReportsService } from '@src/api';
import { ChatModel, EventUI, GroupUI, UserUI, ViewMode } from '@src/models';
import {
  AlertService,
  CordovaService,
  DocumentService,
  EventService,
  PhotoService,
  UserService,
} from '@src/core/services';
import { DialogConfirmComponent } from '@src/app/shared/dialogs';
import { TranslateService } from '@ngx-translate/core';

import { GroupInfoService } from '../group-info';

@Component({
  selector: 'app-event-info',
  templateUrl: './event-info.component.html',
  styleUrls: ['./event-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EventInfoComponent implements OnChanges, OnDestroy {
  @Input() mode: ViewMode = 'view';
  @Input() eventId?: string | null;
  @Input() defaultData?: EventWithParticipantsAdd | null;
  @Output() saved: EventEmitter<string> = new EventEmitter();
  @Output() canceled: EventEmitter<void> = new EventEmitter();
  @Output() deactivated: EventEmitter<string> = new EventEmitter();
  @Output() eventDeleted: EventEmitter<void> = new EventEmitter();
  @ViewChild('confirmEditDialogTemplate') confirmEditDialogTemplate?: PolymorpheusContent<TuiDialogContext>;

  data$: BehaviorSubject<EventUI | null> = this.eventService.event$;
  documentsList$: BehaviorSubject<DocumentView[] | null> = this.documentService.documents$;
  membersList$: BehaviorSubject<UserUI[]> = this.userService.membersOfEvent$;
  organisationId?: string;
  allowEditing$?: Observable<boolean | undefined>;
  allowReportViewing$?: Observable<boolean | undefined>;
  resetEvent: boolean = false;
  notifyEveryone: boolean = false;
  loading: boolean = false;
  recallButtonLoading: boolean = false;

  private oldProgram?: DocumentView;
  private oldDocs?: DocumentView[];
  private destroyed$$: Subject<void> = new Subject<void>();
  private readonly confirmDialogDeleteEvent = this.dialogService.open<boolean>(
    new PolymorpheusComponent(DialogConfirmComponent, this.injector),
    {
      label: this.translateService.instant('common.dialogs.deleteHeader'),
      size: 's',
      closeable: false,
    },
  );
  private readonly confirmDialogDeactivateEvent = this.dialogService.open<boolean>(
    new PolymorpheusComponent(DialogConfirmComponent, this.injector),
    {
      label: this.translateService.instant('components.eventInfo.dialogs.eventCancelHeader'),
      size: 's',
      closeable: false,
    },
  );
  private readonly confirmCancelEditingDialog = this.dialogService.open<boolean>(
    new PolymorpheusComponent(DialogConfirmComponent, this.injector),
    {
      label: this.translateService.instant('common.dialogs.undoEditHeader'),
      size: 's',
      closeable: false,
    },
  );

  private readonly confirmRecallNotificationsDialog = this.dialogService.open<boolean>(
    new PolymorpheusComponent(DialogConfirmComponent, this.injector),
    {
      label: this.translateService.instant('components.eventInfo.dialogs.resendNotificationsHeader'),
      size: 's',
      closeable: false,
    },
  );

  constructor(
    private readonly cdr: ChangeDetectorRef,
    private readonly eventService: EventService,
    private readonly photoService: PhotoService,
    private readonly documentService: DocumentService,
    private readonly userService: UserService,
    private readonly groupInfoService: GroupInfoService,
    private readonly ngxPermissionsService: NgxPermissionsService,
    private readonly chatsService: ChatsService,
    private readonly env: EnvService,
    private readonly translateService: TranslateService,
    @Inject(TuiDialogService) private readonly dialogService: TuiDialogService,
    @Inject(Injector) private readonly injector: Injector,
    private readonly alertService: AlertService,
    private readonly cordovaService: CordovaService,
    @Inject(ReportsService) private readonly reportsService: ReportsService,
  ) {
    this.organisationId = this.userService.authUser?.organisationId;
    this.data$.pipe(takeUntil(this.destroyed$$)).subscribe(event => {
      this.allowEditing$ = this.userService.authUser$.pipe(
        map(user => {
          if (!user || !event) return false;

          const permissions = this.ngxPermissionsService.getPermissions();

          return (
            'eventEditing' in permissions || ('onlyYourEventEditing' in permissions && user.id === event.createdBy)
          );
        }),
      );
    });

    this.allowReportViewing$ = this.userService.authUser$.pipe(
      map(user => {
        if (!user) return false;

        // TODO: временное решение задачи для ФТР
        const onlyForAssociationIds = [
          '00000000-0000-0000-0000-000000000003', // "Спутник" на тестовом стенде
          'f2af6210-c81b-41f7-8249-8f6c6cd9d230', // "КС ФТР" на проде
        ];
        const permissions = this.ngxPermissionsService.getPermissions();

        return (
          'eventReportViewing' in permissions &&
          !!user.parentOrganisationId &&
          onlyForAssociationIds.includes(user.parentOrganisationId)
        );
      }),
    );
  }

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    if (changes.eventId) {
      this.resetData();
      if (this.eventId) {
        this.mode = 'view';
        this.getData(this.eventId);
      }
    }

    if (changes.mode) {
      if (this.mode === 'create') {
        this.resetData();
        if (this.defaultData) {
          this.data$ = new BehaviorSubject<EventUI | null>(this.defaultData as EventUI); // TODO: разобраться с типами
        }
      }
    }

    this.cdr.markForCheck();
  }

  ngOnDestroy(): void {
    this.resetData();
    this.destroyed$$.next();
    this.destroyed$$.complete();
  }

  getMembersFile(eventId: string): void {
    this.eventService
      .getEventMembersFile(eventId, this.env.isBot)
      .pipe(takeUntil(this.destroyed$$))
      .subscribe(doc => {
        this.downloadFile(doc, this.translateService.instant('components.eventInfo.labels.membersFileName')); // TODO: тип определять автоматически по doc.type
      });
  }

  onStartEditing(): void {
    this.mode = 'edit';
  }

  async onCopyEvent() {
    if (!this.eventId) {
      return;
    }

    const currentEventData = this.data$.value;

    const initData: Pick<
      EventUI,
      | 'subject'
      | 'description'
      | 'eventTypeId'
      | 'address'
      | 'onlineLink'
      | 'committeeId'
      | 'groupChatId'
      | 'groupInviteCode'
      | 'contactPerson'
      | 'contactPersonId'
      | 'isFreeToRegister'
      | 'isVisibleToAll'
    > = {
      subject: currentEventData?.subject,
      description: currentEventData?.description,
      eventTypeId: currentEventData?.eventTypeId,
      address: currentEventData?.address,
      onlineLink: currentEventData?.onlineLink,
      committeeId: currentEventData?.committeeId,
      groupChatId: currentEventData?.groupChatId,
      groupInviteCode: currentEventData?.groupInviteCode,
      contactPerson: currentEventData?.contactPerson,
      contactPersonId: currentEventData?.contactPersonId,
      isFreeToRegister: currentEventData?.isFreeToRegister,
      isVisibleToAll: currentEventData?.isVisibleToAll,
    };

    this.mode = 'create';
    this.resetData();

    this.data$.next({ ...(initData as EventUI) }); // TODO: разобраться с типами
    this.cdr.markForCheck();
  }

  onSaveData(data: EventUI): void {
    if (!this.organisationId) return;

    this.loading = true;

    if (this.mode === 'edit') {
      if (!this.confirmEditDialogTemplate) return;
      this.notifyEveryone = false;
      this.resetEvent = false;

      this.dialogService
        .open(this.confirmEditDialogTemplate, {
          size: 's',
          closeable: false,
          dismissible: false,
        })
        .pipe(takeUntil(this.destroyed$$))
        .subscribe({
          complete: () => {
            data.resetEvent = this.resetEvent;
            data.notifyEveryone = this.notifyEveryone;
            this.updateEvent(data);
          },
        });
    } else if (this.mode === 'create') {
      data.organisationId = this.organisationId;
      this.createEvent(data);
    }
  }

  onCancel(): void {
    this.confirmCancelEditingDialog.pipe(takeUntil(this.destroyed$$)).subscribe({
      next: res => {
        if (res === true) {
          this.canceled.emit();
          if (this.eventId) {
            this.resetData();
            this.getData(this.eventId);
          }

          this.mode = 'view';
        }
      },
    });
  }

  onDeleteEvent(id: string): void {
    this.confirmDialogDeleteEvent.pipe(takeUntil(this.destroyed$$)).subscribe({
      next: res => {
        if (res === true) {
          this.eventService
            .deleteEvent(id)
            .pipe(takeUntil(this.destroyed$$))
            .subscribe(() => {
              this.eventService.getEvents();
              this.resetData();
              this.eventDeleted.emit();
            });
        }
      },
    });
  }

  onDeactivateEvent(id: string): void {
    this.confirmDialogDeactivateEvent.pipe(takeUntil(this.destroyed$$)).subscribe({
      next: res => {
        if (res === true) {
          lastValueFrom(this.eventService.dectivateEvent(id)).then(() => {
            if (this.eventId) {
              this.getData(this.eventId);
              this.eventService.getEvents();
            }
          });
        }
      },
    });
  }

  async acceptEvent(id: string): Promise<void> {
    await this.eventService.acceptEvent(id);
    await this.getData(id);
    await this.eventService.getEvents();
    await this.alertService.success(this.translateService.instant('components.eventInfo.alerts.successes.acceptEvent'));
  }

  async declineEvent(id: string): Promise<void> {
    await this.eventService.declineEvent(id);
    await this.getData(id);
    await this.eventService.getEvents();
    await this.alertService.success(
      this.translateService.instant('components.eventInfo.alerts.successes.declineEvent'),
    );
  }

  addEventToCalendar(data: EventUI) {
    if (!data.id) {
      this.alertService.error(this.translateService.instant('components.eventInfo.alerts.errors.eventNotFound'));
      return;
    }

    if (this.env.isAndroidWithCordova || this.env.isIosWithCordova) {
      this.cordovaService.addEventToCalendar({
        title: data.subject,
        notes: data.description,
        eventLocation: data.address,
        startDate: data.dateStart ? new Date(data.dateStart) : undefined,
        endDate: data.dateEnd ? new Date(data.dateEnd) : undefined,
        onlineLink: data.onlineLink,
      });
    } else {
      this.eventService
        .addEventToCalendar(data.id, this.env.isBot)
        .pipe(takeUntil(this.destroyed$$))
        .subscribe(doc => {
          this.downloadFile(doc, `${data.subject}.ics`); // TODO: брать имя с бэка
        });
    }
  }

  resendNotifications(memberIds: string[]) {
    if (!memberIds.length) {
      return;
    }

    this.confirmRecallNotificationsDialog.pipe(takeUntil(this.destroyed$$)).subscribe({
      next: res => {
        if (res) {
          if (!this.eventId) {
            this.alertService.error(this.translateService.instant('components.eventInfo.alerts.errors.eventNotFound'), {
              autoClose: 10 * 1000,
            });
            return;
          }

          this.recallButtonLoading = true;
          lastValueFrom(this.eventService.resendNotification(this.eventId, memberIds)).finally(() => {
            this.recallButtonLoading = false;
            this.cdr.markForCheck();
          });
        }
      },
    });
  }

  onStartReport(data: EventUI) {
    this.reportsService
      .fTREventParticipantsReport(data.id, this.env.isBot)
      .pipe(
        catchError(err => {
          this.alertService.error(err);
          return throwError(() => new Error(err));
        }),
      )
      .subscribe(doc => {
        this.downloadFile(
          doc,
          `${this.translateService.instant('components.eventInfo.labels.reportFileName', {
            value: data.subject,
          })}`, // TODO: брать имя с бэка
        );
      });
  }

  private async getData(eventId: string) {
    await this.eventService.getEvent(eventId, value => {
      this.loading = value;
      this.cdr.markForCheck();
    });
    this.documentService.getDocuments(eventId);

    if (this.ngxPermissionsService.getPermission('eventMembersViewing')) {
      this.userService.getUsersByEventId(eventId);
    }
  }

  private resetData() {
    this.eventService.resetEvent();
    this.documentService.resetDocuments();
    this.userService.resetMembersOfEvent();
  }

  private async createEvent(data: EventUI): Promise<void> {
    this.loading = true;
    let eventChat: ChatModel;

    if (data.createGroupChat) {
      const groupData: GroupUI = {
        name: data.subject,
        description: data.description,
        isChannel: false,
        photo: data.photo,
        organisationId: data.organisationId,
      };

      const newGroupData = await this.groupInfoService.createGroup(groupData);
      const groupChatId = newGroupData?.chatId;
      const groupInviteCode = newGroupData?.inviteLink;

      if (groupChatId && groupInviteCode) {
        eventChat = <ChatModel>{
          id: groupChatId,
          type: newGroupData.type,
          title: newGroupData.name,
        };

        data = {
          ...data,
          groupChatId,
          groupInviteCode,
          onlineLink: data.fillOnlineLink ? groupInviteCode : data.onlineLink,
        };
      }
    }

    await lastValueFrom(this.eventService.createEvent(data))
      .catch(async () => {
        this.loading = false;
        this.resetData();
        if (eventChat) {
          await this.groupInfoService.deleteGroup(eventChat.id);
        }

        return;
      })
      .then(async res => {
        if (!res?.id) return;

        data.id = res.id;
        this.mode = 'view';
        this.resetData();

        if (data.groupChatId) {
          let chatType = 1;

          if (data.groupChat) {
            eventChat = data.groupChat;
          }

          if (
            eventChat.type._ === 'chatTypeBasicGroup' ||
            (eventChat.type._ === 'chatTypeSupergroup' && !eventChat.type.isChannel)
          ) {
            chatType = 1; // 1 - групповой
          } else if (eventChat.type._ === 'chatTypeSupergroup' && eventChat.type.isChannel) {
            chatType = 2; // 2 - канал
          }

          await lastValueFrom(
            this.chatsService.addChat({
              chatId: eventChat.id,
              chatType,
              description: eventChat.title,
            }),
          );

          if (data.participants) {
            const members = await lastValueFrom(this.userService.getUsersValueByEventId(data.id));

            await this.groupInfoService.addMembers(eventChat, members, data.organisationId);
          }
        }

        this.startUploading(data);
      });
  }

  private updateEvent(data: EventUI): void {
    if (!data.id) return;

    this.eventService
      .editEvent(data)
      .pipe(takeUntil(this.destroyed$$))
      .subscribe(() => {
        this.mode = 'view';

        // Сохраняем старые документы перед сбросом данных
        this.oldProgram = this.documentsList$.value?.find(doc => doc.fileType === 5); // 5      Программа
        this.oldDocs = this.documentsList$.value?.filter(doc => doc.fileType === 14); // 14     Приложение к мероприятию

        this.resetData();
        this.startUploading(data);
      });
  }

  private startUploading(data: EventUI) {
    const uploadObs = [];
    if (data.photo && data.id) {
      uploadObs.push(this.uploadPhoto(data.photo, data.id, true));
    }

    // TODO надо подумать над более удобной системой работы с файлами
    if (data.program?.type) {
      uploadObs.push(this.uploadDocuments([data.program], data.id, 5)); // 5      Программа
    }

    if (this.oldProgram?.id && (!data.program || data.program.type)) {
      uploadObs.push(...this.documentService.deleteDocuments([this.oldProgram.id]));
    }

    if (data.docs?.length) {
      const addedFiles = data.docs.filter(doc => doc.type);
      if (addedFiles && addedFiles.length) uploadObs.push(this.uploadDocuments(addedFiles, data.id, 14)); // 14     Приложение к мероприятию
    }

    if (this.oldDocs?.length && data.docs?.length) {
      const deletedFiles = differenceBy(this.oldDocs, data.docs, 'id');
      uploadObs.push(...this.documentService.deleteDocuments(deletedFiles.map(doc => doc.id || '')));
    }
    if (this.oldDocs?.length && !data.docs?.length) {
      uploadObs.push(...this.documentService.deleteDocuments(this.oldDocs.map(doc => doc.id || '')));
    }

    if (uploadObs.length) {
      forkJoin(uploadObs)
        .pipe(takeUntil(this.destroyed$$))
        .subscribe(() => {
          if (!data.id) return;

          this.getData(data.id);
          this.saved.emit(data.id);
          this.cdr.markForCheck();
        });
    } else {
      if (!data.id) return;

      this.getData(data.id);
      this.saved.emit(data.id);
      this.cdr.markForCheck();
    }
  }

  private uploadPhoto(photo: File, attachId: string, isCover: boolean) {
    if (!photo) return;

    return this.photoService.uploadPhoto(photo, attachId, isCover);
  }

  private uploadDocuments(docs?: File[], attachId?: string, documentType?: number) {
    if (!docs?.length || !attachId || !documentType) return;

    return this.documentService.addDocuments(docs, attachId, documentType);
  }

  private downloadFile(doc: Blob, fileName?: string) {
    if (this.env.isBot) {
      this.env.sendDownloadNotification();
    } else {
      this.documentService.downloadDocument(doc, fileName);
    }
  }
}
