import { BreakpointObserver } from '@angular/cdk/layout';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { takeUntil } from 'rxjs';
import { ResizableBaseComponent } from '@src/app/components';
import { BreakpointObserverHelperService, PollService } from '@src/core/services';
import { AnswerType, QuestionFormType } from '@src/models';
import { Optional } from '@src/types/utils';
import { ANSWER_TYPES } from '@src/app/modules/poll-info';
import { QuestionInRow } from '@src/api';

import { QuestionsService } from '../../services';

import { FormDataType } from './types';

@Component({
  selector: 'view-step-by-step',
  templateUrl: './view-step-by-step.component.html',
  styleUrls: ['./view-step-by-step.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ViewStepByStepComponent extends ResizableBaseComponent implements OnChanges {
  @Input() pollId: Optional<string>;
  @Output() pollStopped = new EventEmitter<void>();
  @Output() pollFinished = new EventEmitter<void>();

  form!: FormGroup<FormDataType>;
  loading: boolean = true;

  currentQuestion?: QuestionInRow;

  private readonly answerTypes: AnswerType[] = ANSWER_TYPES;
  private userReplies: QuestionFormType[] = [];

  constructor(
    readonly cdr: ChangeDetectorRef,
    readonly breakpointObserver: BreakpointObserver,
    readonly breakpointObserverHelperService: BreakpointObserverHelperService,
    private readonly pollService: PollService,
    private readonly questionsService: QuestionsService,
  ) {
    super(cdr, breakpointObserver, breakpointObserverHelperService);

    this.initForm();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['pollId']) {
      this.startPoll();
    }

    this.cdr.markForCheck();
  }

  startPoll() {
    if (this.pollId) {
      this.loading = true;

      this.pollService
        .startPoll(this.pollId)
        .pipe(takeUntil(this.destroyed$$))
        .subscribe(question => {
          this.getQuestion(question.id);
          this.cdr.markForCheck();
        });
    }
    this.cdr.markForCheck();
  }

  getQuestion(questionId?: string) {
    if (this.pollId && questionId) {
      this.loading = true;

      this.pollService
        .getQuestionById(questionId)
        .pipe(takeUntil(this.destroyed$$))
        .subscribe(question => {
          this.setFormData(question);
          this.currentQuestion = question;
          this.loading = false;
          this.cdr.markForCheck();
        });
    }
    this.cdr.markForCheck();
  }

  cancel() {
    this.pollStopped.emit();
  }

  async saveReplies() {
    if (!this.pollId) {
      return;
    }

    this.questionsService.saveReplies(this.pollId, this.userReplies).then(res => {
      res?.pipe(takeUntil(this.destroyed$$)).subscribe(() => {
        this.pollFinished.emit();
      });
    });
  }

  private initForm() {
    this.form = new FormGroup<FormDataType>({
      question: new FormControl(null),
    });

    this.form.controls.question.valueChanges.pipe(takeUntil(this.destroyed$$)).subscribe(question => {
      if (question) {
        this.saveAnswer(question);
      }
    });
  }

  private saveAnswer(question: QuestionFormType) {
    const findIndex = this.userReplies.findIndex(answer => answer.id === question.id);
    if (findIndex > -1) {
      this.userReplies[findIndex] = question;
    } else {
      this.userReplies.push(question);
    }
  }

  private setFormData(question: QuestionFormType) {
    question.answerType = this.answerTypes.find(answerType => answerType.id === question.answerTypeId);

    const findUserReply = this.userReplies.find(userReply => userReply.id === question.id);
    if (findUserReply) {
      question = findUserReply;
    }

    this.form.patchValue({ question });
    this.cdr.markForCheck();
  }
}
