import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TuiButtonModule } from '@taiga-ui/core';
import { TranslateModule } from '@ngx-translate/core';
import { ComponentsModule } from '@src/app/components';

import { ListModule } from '../list';

import { SearchGroupsComponent } from './search-groups.component';

@NgModule({
  declarations: [SearchGroupsComponent],
  imports: [CommonModule, FormsModule, ListModule, TuiButtonModule, TranslateModule, ComponentsModule],
  exports: [SearchGroupsComponent],
})
export class SearchGroupsModule {}
