import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TuiAccordionModule, TuiAvatarModule, TuiInputModule, TuiCheckboxModule } from '@taiga-ui/kit';
import { TuiButtonModule, TuiSvgModule, TuiTextfieldControllerModule } from '@taiga-ui/core';
import { TuiLetModule } from '@taiga-ui/cdk';
import { SharedModule } from '@src/app/shared';
import { DictionaryItemComponent } from '@src/app/modules/accordion-levels/components/dictionary-item/dictionary-item.component';

import { TestingModule } from '../testing/testing.module';

import { AccordionLevelsComponent } from './accordion-levels.component';
import { CommitteeItemComponent } from './components/committee-item/committee-item.component';

@NgModule({
  declarations: [AccordionLevelsComponent, CommitteeItemComponent, DictionaryItemComponent],
  imports: [
    CommonModule,
    FormsModule,
    SharedModule,
    TuiAccordionModule,
    TuiAvatarModule,
    TuiButtonModule,
    TuiInputModule,
    TuiLetModule,
    TuiTextfieldControllerModule,
    TuiCheckboxModule,
    TuiSvgModule,
    TestingModule,
  ],
  exports: [AccordionLevelsComponent],
})
export class AccordionLevelsModule {}
