<div class="search-users-input tui-space_bottom-4">
  <tui-select
    *ngIf="mode === 'single'"
    [tuiTextfieldSize]="textfieldSize"
    [stringify]="stringify"
    [identityMatcher]="matcher"
    [tuiTextfieldCleaner]="true"
    [disabled]="disabled"
    [pseudoInvalid]="touched && invalid"
    [ngModel]="getValue()"
    (ngModelChange)="setValue($event)"
  >
    {{ title }}

    <ng-container *tuiDataList>
      <app-search class="search" (searchQueryChange)="onSearchChange($event)"></app-search>

      <tui-data-list>
        <tui-opt-group>
          <button *ngFor="let item of filteredItems$ | async" tuiOption [value]="item" size="l">
            <render-user-row [user]="item" [statusContent]="statusContent"></render-user-row>
          </button>
        </tui-opt-group>
      </tui-data-list>
    </ng-container>
  </tui-select>

  <tui-multi-select
    *ngIf="mode === 'multiple'"
    [rows]="3"
    [tuiTextfieldSize]="textfieldSize"
    [stringify]="stringify"
    [identityMatcher]="matcher"
    [tuiTextfieldCleaner]="true"
    [(ngModel)]="value"
    [disabled]="disabled"
    [pseudoInvalid]="touched && invalid"
    [editable]="false"
  >
    {{ title }}

    <ng-container *tuiDataList>
      <app-search class="search" (searchQueryChange)="onSearchChange($event)"></app-search>

      <tui-data-list>
        <tui-opt-group tuiMultiSelectGroup label="Выбрать все">
          <button *ngFor="let item of filteredItems$ | async" tuiOption [value]="item" size="l">
            <render-user-row [user]="item" [statusContent]="statusContent"></render-user-row>
          </button>
        </tui-opt-group>
      </tui-data-list>
    </ng-container>
  </tui-multi-select>

  <div *ngIf="showFilterButton" tuiGroup class="search-users-input__actions" [collapsed]="true">
    <button
      appearance="whiteblock-active"
      size="m"
      tuiButton
      type="button"
      [disabled]="disabled"
      (click)="onSearchUsers()"
    >
      {{ filterButtonText }}
    </button>

    <button
      appearance="whiteblock-active"
      icon="tuiIconPlusCircleLarge"
      size="m"
      tuiIconButton
      type="button"
      class="tui-group__auto-width-item"
      [disabled]="disabled"
      (click)="onSearchUsers()"
    ></button>
  </div>
</div>

<ng-template #searchIcon>
  <span class="search-users-input__marker">
    <tui-svg src="tuiIconSearch"></tui-svg>

    <span>{{ 'common.fields.search' | translate }}</span>
  </span>
</ng-template>
