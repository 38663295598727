import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  OnChanges,
  SimpleChanges,
  ChangeDetectorRef,
  EventEmitter,
  Output,
} from '@angular/core';
import { takeUntil } from 'rxjs';
import { BreakpointObserverHelperService, UserService } from '@src/core/services';
import { UserUI, ViewMode } from '@src/models';
import { ResizableBaseComponent } from '@src/app/components';
import { BreakpointObserver } from '@angular/cdk/layout';

import { UsersTableValue } from './types';

@Component({
  selector: 'app-users-table',
  templateUrl: './users-table.component.html',
  styleUrls: ['./users-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UsersTableComponent extends ResizableBaseComponent implements OnInit, OnChanges {
  @Input() mode: ViewMode = 'view';
  @Input() users?: UserUI[] | null;
  @Input() usersIds?: string[];
  @Output() changeValue = new EventEmitter<UsersTableValue>();

  constructor(
    readonly cdr: ChangeDetectorRef,
    readonly breakpointObserver: BreakpointObserver,
    readonly breakpointObserverHelperService: BreakpointObserverHelperService,
    private readonly userService: UserService,
  ) {
    super(cdr, breakpointObserver, breakpointObserverHelperService);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.usersIds) {
      if (this.usersIds?.length) {
        this.userService.listOfProfiles$.pipe(takeUntil(this.destroyed$$)).subscribe(users => (this.users = users));
        this.userService.getListOfProfiles(this.usersIds);
      }
    }
  }

  onChangeValue(value: UsersTableValue) {
    this.changeValue.emit(value);
  }
}
