<form [formGroup]="form">
  <div class="input-code" formArrayName="items">
    <ng-container *ngFor="let item of items.controls; let i = index">
      <ng-container [formGroupName]="i">
        <tui-input
          class="input-code__field"
          [class.ng-invalid]="codeInvalid"
          formControlName="value"
          tuiTextfieldSize="l"
          
          [maskito]="CODE_MASK_OPTIONS"
          [tuiTextfieldLabelOutside]="true"
          (ngModelChange)="onChangeItem(i)"
        ><input tuiTextfield inputmode="numeric"/> </tui-input>
      </ng-container>
    </ng-container>
  </div>
</form>

