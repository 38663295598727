import { Component, ChangeDetectionStrategy, Input, EventEmitter, Output } from '@angular/core';

import { SelectAllLinkMode } from './types';

@Component({
  selector: 'select-all-link',
  templateUrl: './select-all-link.component.html',
  styleUrls: ['./select-all-link.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectAllLinkComponent {
  @Input() mode: SelectAllLinkMode = 'select-all';
  @Output() clickChange = new EventEmitter();
}
