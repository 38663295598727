export interface Multimedia {
  file: File;
  source?: MultimediaSource;
}

export interface MultimediaSource {
  data?: string;
  src?: string;
  mimeType?: string;
}
