<tui-input
  tuiTextfieldSize="m"
  [tuiTextfieldCleaner]="true"
  [tuiTextfieldLabelOutside]="true"
  [tuiTextfieldIcon]="'tuiIconSearch'"
  [(ngModel)]="searchQuery"
  (ngModelChange)="onChangeSearchQuery($event)"
>
  {{ 'common.fields.search' | translate }}

  <input [placeholder]="'common.fields.search' | translate" tuiTextfield type="text" />
</tui-input>

<ng-container *tuiLet="filteredItems$ | async as items">
  <div class="filter-accordion-content__container">
    <div *ngIf="!items?.length && !searching" class="filter-accordion-content__no-data">
      <span class="tui-text_body-s">
        {{ noDataMessage }}
      </span>
    </div>

    <tui-loader
      *ngIf="searching"
      size="l"
      [showLoader]="true"
      [inheritColor]="true"
      [overlay]="true"
      class="filter-accordion-content__searching"
    ></tui-loader>

    <ng-container *ngIf="!!items?.length && !searching">
      <select-all-link [mode]="addOrRemoveItemsMode" (clickChange)="onAddOrRemoveItemsToResult()"></select-all-link>

      <tui-scrollbar class="filter-accordion-content__wrapper">
        <div class="filter-accordion-content__wrapper_list">
          <button
            *ngFor="let item of items"
            class="filter-accordion-content__wrapper_item"
            (click)="onToggleItem(item)"
          >
            <tui-primitive-checkbox
              size="l"
              [value]="isChecked(item)"
              [style.background-color]="'var(--unions-color-status-disabled)'"
            ></tui-primitive-checkbox>

            <span *ngIf="variant === 'short'" class="tui-text_body-s" [title]="item.label">{{ item.label }}</span>

            <div *ngIf="variant === 'full' || variant === 'without-avatar'" class="filter-accordion-content__full-view">
              <tui-avatar
                *ngIf="variant === 'full'"
                size="s"
                [autoColor]="true"
                [rounded]="true"
                [avatarUrl]="item.avatarId | getImageSrc"
                [text]="item.label"
              ></tui-avatar>

              <div class="filter-accordion-content__full-view_row">
                <span [title]="item.label" class="tui-text_body-s">{{ item.label }}</span>

                <span
                  *tuiLet="item.label !== item.subLabel ? item.subLabel : '' as subLabel"
                  [title]="subLabel"
                  class="tui-text_body-xs"
                  [style.color]="'var(--tui-text-03)'"
                >
                  {{ subLabel }}
                </span>
              </div>
            </div>
          </button>
        </div>
      </tui-scrollbar>
    </ng-container>
  </div>
</ng-container>
