import { LoyaltyProgramResult } from '../../types';

export const BACKGROUND_COLORS: Record<LoyaltyProgramResult, string> = {
  published: 'var(--unions-color-lp-dialog-background)',
  unpublished: 'var(--unions-color-lp-dialog-background)',
  added: 'var(--unions-color-lp-dialog-background)',
  deleted: 'var(--unions-color-text-07-12)',
  updated: 'var(--unions-color-lp-dialog-background)',
} as const;

export const ICON_COLORS: Record<LoyaltyProgramResult, string> = {
  published: 'var(--tui-success-fill)',
  unpublished: 'var(--tui-success-fill)',
  added: 'var(--tui-success-fill)',
  deleted: 'var(--unions-color-text-07)',
  updated: 'var(--tui-success-fill)',
} as const;
