import { Component, ChangeDetectionStrategy, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TuiSizeL, TuiSizeS } from '@taiga-ui/core';
import { AlertService, DocumentService } from '@src/core/services';
import { EnvService } from '@src/app/modules/env';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

import { FormData, FormDataControls, ImportData } from './types';
import { ImportService } from './services';

@Component({
  selector: 'app-import-users',
  templateUrl: './import-users.component.html',
  styleUrls: ['./import-users.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImportUsersComponent implements OnInit, OnDestroy {
  loading: boolean = false;

  tuiElementSize: TuiSizeS | TuiSizeL = 'm';

  form: UntypedFormGroup;

  private destroyed$$: Subject<void> = new Subject<void>();

  constructor(
    private readonly cdr: ChangeDetectorRef,
    private readonly importService: ImportService,
    private readonly alertService: AlertService,
    private readonly env: EnvService,
    private readonly documentService: DocumentService,
    private readonly translateService: TranslateService,
  ) {
    this.form = new UntypedFormGroup(<FormDataControls>{
      file: new UntypedFormControl(undefined, Validators.required),
    });
  }

  get fileControl() {
    return this.form.get('file');
  }

  ngOnInit(): void {
    this.importService
      .getResult()
      .pipe(takeUntil(this.destroyed$$))
      .subscribe(result => {
        if (result) {
          this.form.reset();
          if (result.size === 0) {
            this.alertService.success(
              this.translateService.instant('components.importUsers.alerts.successes.fileImport'),
            );
          } else {
            this.alertService.warning(
              this.translateService.instant('components.importUsers.alerts.errors.fileImport'),
              {
                autoClose: 30000,
              },
            );
            this.downloadFile(result);
          }
        }

        this.loading = false;
        this.cdr.markForCheck();
      });
  }

  ngOnDestroy(): void {
    this.destroyed$$.next();
    this.destroyed$$.complete();
    this.importService.clearImportData();
  }

  removeFile(): void {
    this.fileControl?.setValue(null);
    this.cdr.markForCheck();
  }

  onClickSaveButton() {
    this.form.markAllAsTouched();

    if (this.form.invalid) {
      this.alertService.error('Загрузите файл');
      return;
    }

    this.loading = true;
    const data = this.mapFormDataToApiData(this.form.value);
    this.importService.startImport(data);
    this.cdr.markForCheck();
  }

  private mapFormDataToApiData(formData: FormData): ImportData {
    const importData: ImportData = {
      files: [formData.file],
    };

    return importData;
  }

  private downloadFile(doc: Blob, fileName: string = 'import-errors.xlsx') {
    if (this.env.isBot) {
      this.env.sendDownloadNotification();
    } else {
      this.documentService.downloadDocument(doc, fileName);
    }
  }
}
