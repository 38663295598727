<div class="organisation-input">
  <tui-select
    *ngIf="!multiple"
    [tuiTextfieldSize]="textfieldSize"
    [tuiTextfieldCleaner]="textfieldCleaner"
    [stringify]="stringify"
    [identityMatcher]="matcher"
    [disabled]="disabled"
    [pseudoInvalid]="touched && invalid"
    [ngModel]="getValue()"
    (ngModelChange)="setValue($event)"
  >
    <ng-container *ngTemplateOutlet="contentTmp"></ng-container>

    <ng-container *tuiDataList>
      <ng-container *ngTemplateOutlet="searchTmp"></ng-container>

      <tui-data-list>
        <tui-opt-group *ngIf="filteredItems$ | async as filteredItems; else skeletonTmp">
          <button *ngFor="let item of filteredItems" tuiOption [value]="item.id" size="l">
            <ng-container *ngTemplateOutlet="renderRowTmp; context: { $implicit: item }"></ng-container>
          </button>
        </tui-opt-group>
      </tui-data-list>
    </ng-container>
  </tui-select>

  <tui-multi-select
    *ngIf="multiple"
    [rows]="3"
    [tuiTextfieldSize]="textfieldSize"
    [tuiTextfieldCleaner]="textfieldCleaner"
    [stringify]="stringify"
    [identityMatcher]="matcher"
    [(ngModel)]="value"
    [disabled]="disabled"
    [pseudoInvalid]="touched && invalid"
    [editable]="false"
  >
    <ng-container *ngTemplateOutlet="contentTmp"></ng-container>

    <ng-container *tuiDataList>
      <ng-container *ngTemplateOutlet="searchTmp"></ng-container>

      <tui-data-list>
        <tui-opt-group
          *ngIf="filteredItems$ | async as filteredItems; else skeletonTmp"
          tuiMultiSelectGroup
          label="Выбрать все"
        >
          <button *ngFor="let item of filteredItems" tuiOption [value]="item.id" size="l">
            <ng-container *ngTemplateOutlet="renderRowTmp; context: { $implicit: item }"></ng-container>
          </button>
        </tui-opt-group>
      </tui-data-list>
    </ng-container>
  </tui-multi-select>
</div>

<ng-template #contentTmp>
  <ng-content></ng-content>
</ng-template>

<ng-template #searchTmp>
  <app-search class="search" [(searchQuery)]="searchQuery" (searchQueryChange)="onSearchChange($event)"></app-search>
</ng-template>

<ng-template #renderRowTmp let-item>
  <render-organisation-row [organisation]="item"></render-organisation-row>
</ng-template>

<ng-template #skeletonTmp>
  <app-skeleton-wrapper [loading]="true"></app-skeleton-wrapper>
</ng-template>
