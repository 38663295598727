import { Component, Input, ChangeDetectionStrategy, OnInit, Output, EventEmitter } from '@angular/core';

import { getAllParentIds } from './utils/getAllParentIds';
import { AccordionLevelsTypes } from './types/accordion-levels-types';
import { ItemType } from './types/item-type';

@Component({
  selector: 'app-accordion-levels',
  templateUrl: './accordion-levels.component.html',
  styleUrls: ['./accordion-levels.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccordionLevelsComponent implements OnInit {
  @Input() multiple: boolean = false;
  @Input() singleSelect: boolean = false;
  @Input() items?: Array<ItemType> = [];
  @Input() disableIds: string[] = [];
  @Input() selectedIds: string[] = [];
  @Output() selectedIdsChange = new EventEmitter<string[]>();

  /** Тип */
  @Input() type?: AccordionLevelsTypes;
  @Input() rounded?: boolean = false;
  @Input() border?: boolean = false;
  @Input() disableHover?: boolean = false;
  @Input() disableChangeSelection?: boolean = false;

  /** Массив записей, по которым нужно раскрыть дерево */
  expandItemIds: string[] = [];

  ngOnInit(): void {
    this.expandItemIds = this.getExpandItemIds(this.selectedIds, this.items ?? []);
  }

  onClickItem(event?: MouseEvent, id?: string): void {
    if (!this.disableChangeSelection) {
      event?.stopPropagation();

      if (!this.multiple) {
        if (!id || id === this.selectedIds[0]) return;
        this.selectedIds = [id];
      } else {
        this.onChecked(!this.isSelected(id), id);
      }

      this.onEmitSelectedIds();
    }
  }

  onChecked(value: boolean, id?: string): void {
    if (!id) return;

    if (value) {
      if (this.singleSelect) {
        this.selectedIds = [];
      }
      this.selectedIds = this.selectedIds.concat([id]);
    } else {
      this.selectedIds.splice(this.selectedIds.indexOf(id), 1);
    }
  }

  onEmitSelectedIds(): void {
    this.selectedIdsChange.emit(this.selectedIds);
  }

  isOpen(id?: string): boolean {
    return !!id && this.expandItemIds.includes(id);
  }

  isSelected(id?: string): boolean {
    return !!id && this.selectedIds.includes(id);
  }

  isDisabled(id?: string): boolean {
    return !!id && this.disableIds.includes(id);
  }

  private getExpandItemIds(selectedIds: string[], items: Array<ItemType>): string[] {
    let expandItemIds: string[] = [];
    selectedIds.forEach(selectedId => {
      expandItemIds = expandItemIds.concat(getAllParentIds(selectedId, items));
    });

    return expandItemIds;
  }
}
