<div class="view-contacts-block">
  <div class="view-contacts-block__company-info">
    <div class="view-contacts-block__company-info_header">
      <div *ngIf="!!companyLogoId" class="view-contacts-block__company-info_logo-wrapper">
        <img
          [src]="companyLogoId | getImageSrc : 'Company logo' : 'fullSize' : productUnionId"
          alt="Company logo"
          class="view-contacts-block__company-info_logo"
        />
      </div>

      <div class="view-contacts-block__company-info_title">
        <h1>{{ 'components.loyaltyViewContactsBlock.labels.company' | translate }}</h1>

        <div class="view-contacts-block__contacts_row">
          <ng-template [ngxPermissionsExcept]="'showOnlyLoyaltyProgram'" [ngxPermissionsExceptElse]="companyNameTmp">
            <!-- TODO: заменить на компонент field с типом organisation-->
            <app-link
              *ngIf="!useUnionId; else companyNameTmp"
              [routerLink]="['/association-organisations', contacts?.company?.id]"
              [displayText]="contacts?.company?.name"
            ></app-link>
          </ng-template>

          <ng-template #companyNameTmp>
            <span>{{ contacts?.company?.name }}</span>
          </ng-template>
        </div>
      </div>
    </div>

    <app-parse-links
      class="info"
      [value]="contacts?.company?.info ?? ''"
      (externalLink)="externalLink.emit($event)"
    ></app-parse-links>
  </div>

  <div class="view-contacts-block__contacts">
    <h1>{{ 'components.loyaltyViewContactsBlock.labels.contacts' | translate }}</h1>

    <div class="view-contacts-block__contacts_content">
      <div class="view-contacts-block__contacts_person" *ngFor="let person of contacts?.persons">
        <div *ngIf="!!person.contactName" class="view-contacts-block__contacts_row">
          {{ person.contactName }}
        </div>

        <div *ngIf="!!person.contactPhone" class="view-contacts-block__contacts_row">
          <app-link
            [url]="person.contactPhone ? 'tel:' + person.contactPhone : ''"
            [displayText]="person.contactPhone | formatPhone"
          ></app-link>
        </div>

        <div *ngIf="!!person.contactEmail" class="view-contacts-block__contacts_row">
          <app-link
            [url]="person.contactEmail ? 'mailto:' + person.contactEmail : ''"
            [displayText]="person.contactEmail"
          ></app-link>
        </div>

        <div class="view-contacts-block__contacts_row view-contacts-block__contacts_address">
          <app-parse-links [value]="person.address" (externalLink)="externalLink.emit($event)"></app-parse-links>
        </div>
      </div>
    </div>

    <p *ngIf="contacts?.mentor?.id" class="view-contacts-block__contacts_mentor">
      {{ 'components.loyaltyViewContactsBlock.labels.mentor' | translate }}:

      <ng-template [ngxPermissionsExcept]="'showOnlyLoyaltyProgram'" [ngxPermissionsExceptElse]="mentorNameTmp">
        <app-link
          [routerLink]="['/association-users', contacts?.mentor?.id]"
          [displayText]="contacts?.mentor?.name"
        ></app-link>
      </ng-template>

      <ng-template #mentorNameTmp>
        <span>{{ contacts?.mentor?.name }}</span>
      </ng-template>
    </p>
  </div>
</div>
