import { FieldType } from '@src/app/shared/field';

// ContactTypes
// 1 - Телефон
// 2 - Email
// 3 - Веб-страница
// 4 - Ссылка на документ
// 5 - Социальная сеть
// 6 - Мессенджер
// 7 - Прочее
// 8 - Файл
// 9 - Прочее (только для администраторов объединения)
// 10 - Прочее (для администраторов и участника)
// 11 - Ссылка на документ (только для администраторов объединения)
// 12 - Ссылка на документ (для администраторов и участника)
// 13 - Файл (только для администраторов объединения)
// 14 - Файл (для администраторов и участника)

/**
 * Утилита, которая проверяет, что контакт не является ни Прочим, ни файлом
 * @param typeId тип контакта
 */
export const isNotOtherAndNotFileContact = (typeId?: number): boolean => {
  // TODO: refactoring Типы контактов вынести в enum
  return !!typeId && ![7, 8, 9, 10, 13, 14].includes(typeId);
};

/**
 * Утилита, которая проверяет является ли контакт Прочим
 * @param typeId тип контакта
 */
export const isOtherContact = (typeId?: number): boolean => {
  // TODO: refactoring Типы контактов вынести в enum
  return !!typeId && [7, 9, 10].includes(typeId);
};

/**
 * Утилита, которая проверяет является ли контакт файлом
 * @param typeId тип контакта
 */
export const isFileContact = (typeId?: number): boolean => {
  // TODO: refactoring Типы контактов вынести в enum
  return !!typeId && [8, 13, 14].includes(typeId);
};

/**
 * Утилита, которая удаляет типы контактов, если у пользователя нет permissions для типа контакта
 * @param contactTypes типы контакта
 * @param userPermissions разрешения пользователя
 */
export const removeContactsByUserPermissions = (contactTypes: FieldType[], userPermissions?: string[]): FieldType[] => {
  return contactTypes.filter(
    contactType =>
      !contactType.permissions ||
      !!contactType.permissions.filter(contactTypePermission => (userPermissions || []).includes(contactTypePermission))
        .length,
  );
};
