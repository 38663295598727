import { BehaviorSubject } from 'rxjs';

export type NavigationItemNames =
  | 'menu.administration'
  | 'menu.association'
  | 'menu.organisations'
  | 'menu.users'
  | 'menu.newsFeed'
  | 'menu.chats'
  | 'menu.committees'
  | 'menu.sub-committees'
  | 'menu.curator'
  | 'menu.events'
  | 'menu.folders'
  | 'menu.info'
  | 'menu.licenses'
  | 'menu.logout'
  | 'menu.mail'
  | 'menu.mailing'
  | 'menu.notifications'
  | 'menu.organisation'
  | 'menu.polls'
  | 'menu.profile'
  | 'menu.settings'
  | 'menu.support'
  | 'menu.version'
  | 'menu.preferences'
  | 'menu.loyalty'
  | 'menu.import'
  | 'menu.language'
  | 'menu.theme'
  | 'menu.cooperationChains'
  | 'menu.cc-suppliers'
  | 'menu.cc-configuration'
  | 'menu.cc-analysis'
  | 'menu.cc-dictionaries'
  | 'menu.cc-configuration-dictionaries'
  | 'menu.administrationNewUsers'
  | 'menu.administrationModerationUsers'
  | 'menu.administrationNewAssociation'
  | 'menu.administrationCustomNames'
  | 'menu.administrationSelectJobTitles'
  | 'menu.administrationSelectBusinessTypes'
  | 'menu.administrationImport'
  | 'menu.administrationMail'
  | 'menu.administrationReports'
  | 'menu.administrationStandardUserInfoFields';

export interface NavigationTree {
  itemName: NavigationItemNames;
}

// TODO add flag kinda like 'desktop' to detect if this item id displayed for desktop only
//  (or flag 'mobile')
export interface NavigationItem {
  id: NavigationItemNames;
  title: string;
  icon?: string;
  link?: string;
  description?: string;
  badge?: BehaviorSubject<number | null>;
  active?: boolean;
  permissions?: string[];
  hint?: string;
  mobileOnly?: boolean;
  action?: (name?: NavigationItemNames) => void;

  /** Флаг, что данный элемент текущий */
  selectedItem?: boolean;
}

export type NavigationMode = 'mobile' | 'desktop';
