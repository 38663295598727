import { ChangeDetectionStrategy, Component, EventEmitter, Inject, Injector, Input, Output } from '@angular/core';
import { TuiDialogService } from '@taiga-ui/core';
import { PolymorpheusComponent } from '@tinkoff/ng-polymorpheus';
import { TranslateService } from '@ngx-translate/core';
import { DocumentView, PollFullView } from '@src/api';
import { Nullable, Optional } from '@src/types/utils';
import { AlertService } from '@src/core/services';
import { DialogConfirmComponent } from '@src/app/shared/dialogs';

import { QuestionsMode } from './types';

@Component({
  selector: 'app-questions',
  templateUrl: './questions.component.html',
  styleUrls: ['./questions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QuestionsComponent {
  @Input() mode: QuestionsMode = 'all';
  @Input() pollId?: string;
  @Input() pollData: Optional<Nullable<PollFullView>>;
  @Input() documentsList: Optional<Nullable<DocumentView[]>>;
  @Output() pollStopped = new EventEmitter<void>();
  @Output() pollFinished = new EventEmitter<void>();

  private readonly confirmCancelDialog = this.dialogService.open<boolean>(
    new PolymorpheusComponent(DialogConfirmComponent, this.injector),
    {
      label: this.translateService.instant('components.questions.dialogs.cancelHeader'),
      data: this.translateService.instant('components.questions.dialogs.cancelBody'),
      size: 's',
      closeable: false,
    },
  );

  constructor(
    private readonly alertService: AlertService,
    private readonly translateService: TranslateService,
    @Inject(TuiDialogService) private readonly dialogService: TuiDialogService,
    @Inject(Injector) private readonly injector: Injector,
  ) {}

  onStoppedPoll() {
    this.confirmCancelDialog.subscribe(res => {
      if (res) {
        this.pollStopped.emit();
      }
    });
  }

  onFinishedPoll() {
    this.alertService.success(this.translateService.instant('components.questions.alerts.successes.saveReplies'), {
      autoClose: 10 * 1000,
    });
    this.pollFinished.emit();
  }
}
