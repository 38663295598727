import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import { TuiNotificationModule, TuiScrollbarModule, TuiTextfieldControllerModule } from '@taiga-ui/core';
import { TuiInputDateRangeModule, TuiTagModule } from '@taiga-ui/kit';
import { TranslateModule } from '@ngx-translate/core';
import { NewListModule } from '@src/app/modules/list';
import { ComponentsModule } from '@src/app/components';
import { IconModule } from '@src/app/modules/icon';

import { ListComponent } from './components/list/list.component';
import { BannerComponent } from './components/banner/banner.component';
import { BirthdaysApiService } from './services';
import { TagComponent } from './components/tag/tag.component';

@NgModule({
  declarations: [ListComponent, BannerComponent, TagComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TuiNotificationModule,
    RouterModule,
    TranslateModule,
    NewListModule,
    TuiScrollbarModule,
    TuiTagModule,
    TuiInputDateRangeModule,
    TuiTextfieldControllerModule,
    ComponentsModule,
    IconModule,
  ],
  providers: [BirthdaysApiService],
  exports: [ListComponent, BannerComponent],
})
export class BirthdaysModule {}
