<ng-container [ngSwitch]="type">
  <tui-svg
    *ngSwitchCase="'tuiIcon'"
    class="icon icon_tui"
    [src]="value"
    [ngStyle]="{ width: size, height: size, 'font-size': size, color: color }"
  ></tui-svg>

  <lucide-icon
    *ngSwitchCase="'lucideIcon'"
    class="icon icon_lucide"
    [name]="value"
    [ngStyle]="{ width: size, height: size, color: color }"
  ></lucide-icon>

  <tui-svg
    *ngSwitchCase="'local'"
    class="icon icon_local"
    [src]="value"
    [ngStyle]="{ width: size, height: size, 'font-size': size, color: color }"
  ></tui-svg>
</ng-container>
