import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  ChangeDetectorRef,
  OnDestroy,
  Inject,
  Injector,
} from '@angular/core';
import { lastValueFrom, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MessageContentUnion } from '@airgram/core/types/outputs';
import { MessagePhoto } from '@airgram/web';
import { TuiDialogService } from '@taiga-ui/core';
import { PolymorpheusComponent } from '@tinkoff/ng-polymorpheus';
import { DialogImageViewingComponent } from '@src/app/shared/dialogs';
import { FileReaderService } from '@src/core/services';
import { MultimediaSource } from '@src/models';

@Component({
  selector: 'app-message-photo',
  templateUrl: './message-photo.component.html',
  styleUrls: ['../styles/message-content.scss', './message-photo.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MessagePhotoComponent implements OnInit, OnDestroy {
  @Input() set message(mess: MessageContentUnion | undefined) {
    this.messagePhoto = mess as MessagePhoto;
  }

  messagePhoto?: MessagePhoto;
  messagePhotoSource?: MultimediaSource;

  private destroyed$$: Subject<void>;

  constructor(
    private cdr: ChangeDetectorRef,
    private fileReaderService: FileReaderService,
    @Inject(TuiDialogService) private readonly dialogService: TuiDialogService,
    @Inject(Injector) private readonly injector: Injector,
  ) {
    this.destroyed$$ = new Subject<void>();
  }

  ngOnInit(): void {
    this.initPhoto();
  }

  ngOnDestroy(): void {
    this.destroyed$$.next();
    this.destroyed$$.complete();
  }

  viewImage() {
    const bigPhoto =
      this.messagePhoto?.photo?.sizes[2]?.photo ??
      this.messagePhoto?.photo?.sizes[1]?.photo ??
      this.messagePhoto?.photo?.sizes[0]?.photo;
    if (bigPhoto) {
      this.fileReaderService
        .getFilePart(bigPhoto)
        .pipe(takeUntil(this.destroyed$$))
        .subscribe(filePart => {
          const bigPhotoSrc = this.fileReaderService.getFileSource(filePart);
          // TODO: replace to TuiPreviewModule
          lastValueFrom(
            this.dialogService.open<boolean>(new PolymorpheusComponent(DialogImageViewingComponent, this.injector), {
              size: 'l',
              data: bigPhotoSrc,
            }),
          );
        });
    }
  }

  private initPhoto(): void {
    if (!this.messagePhoto) return;

    const photo = this.messagePhoto.photo?.sizes[1]?.photo ?? this.messagePhoto.photo?.sizes[0]?.photo;
    if (photo) {
      this.fileReaderService
        .getFilePart(photo)
        .pipe(takeUntil(this.destroyed$$))
        .subscribe(filePart => {
          this.messagePhotoSource = { src: this.fileReaderService.getFileSource(filePart) };
          this.cdr.markForCheck();
        });
    }
  }
}
