import { Component, ChangeDetectionStrategy } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@src/environments/environment';

@Component({
  selector: 'app-policy-page',
  templateUrl: './policy-page.component.html',
  styleUrls: ['./policy-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PolicyPageComponent {
  readonly APP_VERSION = environment.appVersion;

  constructor(private readonly router: Router) {}

  close() {
    this.router.navigate(['/']);
  }
}
