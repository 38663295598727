import {
  Component,
  ChangeDetectionStrategy,
  Input,
  OnChanges,
  SimpleChanges,
  ChangeDetectorRef,
  Inject,
  Injector,
  EventEmitter,
  Output,
} from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';
import { AbstractControl, UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import {
  BehaviorSubject,
  Observable,
  Subject,
  debounceTime,
  lastValueFrom,
  startWith,
  switchMap,
  takeUntil,
} from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { PolymorpheusComponent } from '@tinkoff/ng-polymorpheus';
import { TuiContextWithImplicit, TuiStringHandler } from '@taiga-ui/cdk';
import { TuiDialogService } from '@taiga-ui/core';
import { UserUI } from '@src/models';
import { ResizableBaseComponent } from '@src/app/components';
import { AlertService, BreakpointObserverHelperService } from '@src/core/services';
import { ParticipantOfEventWRoleName, ParticipantRolesForUnionView } from '@src/api';
import { ConfirmDialogComponent } from '@src/app/components';
import { SearchUsersService } from '@src/app/modules/search-users';
import { Nullable } from '@src/types/utils';

import { RolesService } from '../../services';
import { UsersTableValue } from '../../types';
import { START_PAGE_SIZE, START_PAGE } from '../../constants';

import { FormDataControls } from './types';

@Component({
  selector: 'users-table-edit',
  templateUrl: './users-table-edit.component.html',
  styleUrls: ['./users-table-edit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UsersTableEditComponent extends ResizableBaseComponent implements OnChanges {
  @Input() users?: Nullable<UserUI[]>;

  @Output() changeValue = new EventEmitter<UsersTableValue>();

  form!: UntypedFormGroup;

  queryString: Nullable<string>[] = [''];

  readonly columns = ['user', 'role', 'comment'];
  private allItems?: readonly ParticipantRolesForUnionView[] | null;
  private readonly search$ = new Subject<string>();
  readonly pageSize$ = new BehaviorSubject(START_PAGE_SIZE);
  readonly currentPage$ = new BehaviorSubject(START_PAGE);

  readonly items$ = this.search$.pipe(
    startWith(''),
    switchMap(search => {
      return this.serverRequest(search).pipe(startWith<readonly ParticipantRolesForUnionView[] | null>(null));
    }),
  );

  readonly stringify: TuiStringHandler<string | TuiContextWithImplicit<string>> = item => {
    return this.allItems?.find(role => role.id === item)?.name ?? '';
  };

  constructor(
    readonly cdr: ChangeDetectorRef,
    readonly breakpointObserver: BreakpointObserver,
    readonly breakpointObserverHelperService: BreakpointObserverHelperService,
    @Inject(RolesService) private readonly rolesService: RolesService,
    @Inject(TuiDialogService) private readonly dialogService: TuiDialogService,
    @Inject(Injector) private readonly injector: Injector,
    @Inject(AlertService) private readonly alertService: AlertService,
    @Inject(TranslateService) private readonly translateService: TranslateService,
    @Inject(SearchUsersService) private readonly searchUsersService: SearchUsersService,
  ) {
    super(cdr, breakpointObserver, breakpointObserverHelperService);
    this.initParticipants();
  }

  get participants(): UntypedFormArray {
    return this.form.get('participants') as UntypedFormArray;
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.getAllItems();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.users) {
      this.initParticipants();
    }
  }

  onSearchChange(search: Nullable<string>): void {
    this.search$.next(search || '');
  }

  onCreate(newRoleName: string, currentControl?: Nullable<AbstractControl>) {
    this.dialogService
      .open<void | boolean>(new PolymorpheusComponent(ConfirmDialogComponent, this.injector), {
        label: this.translateService.instant('components.usersTableEdit.dialogs.createRoleHeader'),
        data: this.translateService.instant('components.usersTableEdit.dialogs.createRoleContent', {
          value: newRoleName,
        }),
      })
      .subscribe(result => {
        if (result) {
          this.rolesService.createRole({ name: newRoleName }).subscribe(res => {
            this.getAllItems().then(() => {
              currentControl?.patchValue(res.id);
              this.cdr.markForCheck();
            });

            this.alertService.success(
              this.translateService.instant('components.usersTableEdit.alerts.successes.addRole'),
            );
          });
        }
      });
  }

  onClickEditButton() {
    this.searchUsersService.searchMultiple(this.users).subscribe(users => {
      if (!users) {
        return;
      }

      this.users = users;
      this.synchronizingUsersData(this.participants.value);
      this.initParticipants();
    });
  }

  getUser(userId?: string) {
    if (userId) {
      return this.users?.find(user => user.id === userId);
    }

    return;
  }

  onChangePageSize(pageSize: number): void {
    this.pageSize$.next(pageSize);
  }

  onChangeCurrentPage(currentPage: number): void {
    this.currentPage$.next(currentPage);
  }

  private initParticipants() {
    let participants: UntypedFormGroup[] = [];
    if (this.users) {
      participants = this.users.map(user => {
        return new UntypedFormGroup(<FormDataControls>{
          userId: new UntypedFormControl(user.id),
          participantRoleId: new UntypedFormControl(user.participantRole?.participantRoleId),
          participantComment: new UntypedFormControl(
            user.participantRole?.participantComment,
            Validators.maxLength(150),
          ),
        });
      });
    }

    this.form = new UntypedFormGroup({
      participants: new UntypedFormArray(participants),
    });

    this.emitValue(this.participants.value);
    this.participants.valueChanges.pipe(debounceTime(300), takeUntil(this.destroyed$$)).subscribe(value => {
      this.emitValue(value);
    });
  }

  private synchronizingUsersData(participants: ParticipantOfEventWRoleName[]) {
    participants.forEach(participant => {
      const findUser = this.users?.find(user => user.id === participant.userId);
      if (findUser) {
        findUser.participantRole = participant;
      }
    });
  }

  private serverRequest(search: string): Observable<readonly ParticipantRolesForUnionView[]> {
    return this.rolesService.searchRoles(search);
  }

  private getAllItems() {
    return lastValueFrom(this.serverRequest('')).then(allItems => {
      this.allItems = allItems;
    });
  }

  private emitValue(value: ParticipantOfEventWRoleName[]) {
    this.synchronizingUsersData(value);
    this.changeValue.emit({
      participants: value,
      incorrect: this.participants.invalid,
    });
  }
}
