import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { TuiMediaModule } from '@taiga-ui/cdk';
import { TuiLoaderModule } from '@taiga-ui/core';
import { ComponentsModule } from '@src/app/components';
import { SharedModule } from '@src/app/shared';
import { IconModule } from '@src/app/modules/icon';

import { MessageComponent } from './message.component';
import { MessageCustomComponent } from './message-custom/message-custom.component';
import { MessageFormattedTextComponent } from './message-formatted-text/message-formatted-text.component';
import { MessageAnimationComponent } from './message-animation/message-animation.component';
import { MessageAudioComponent } from './message-audio/message-audio.component';
import { MessageDocumentComponent } from './message-document/message-document.component';
import { MessageNotificationComponent } from './message-notification/message-notification.component';
import { MessagePhotoComponent } from './message-photo/message-photo.component';
import { MessageReplyComponent } from './message-reply/message-reply.component';
import { MessageStickerComponent } from './message-sticker/message-sticker.component';
import { MessageTextComponent } from './message-text/message-text.component';
import { MessageVideoComponent } from './message-video/message-video.component';
import { MessageVideoNoteComponent } from './message-video-note/message-video-note.component';
import { MessageVoiceNoteComponent } from './message-voice-note/message-voice-note.component';
import { WebPageComponent } from './web-page/web-page.component';
import { MessageEditComponent } from './message-edit/message-edit.component';

@NgModule({
  declarations: [
    MessageAnimationComponent,
    MessageAudioComponent,
    MessageComponent,
    MessageCustomComponent,
    MessageDocumentComponent,
    MessageFormattedTextComponent,
    MessageNotificationComponent,
    MessagePhotoComponent,
    MessageReplyComponent,
    MessageStickerComponent,
    MessageTextComponent,
    MessageVideoComponent,
    MessageVideoNoteComponent,
    MessageVoiceNoteComponent,
    WebPageComponent,
    MessageEditComponent,
  ],
  imports: [
    CommonModule,
    IconModule,
    MatButtonModule,
    MatIconModule,
    RouterModule,
    SharedModule,
    TuiLoaderModule,
    TuiMediaModule,
    ComponentsModule,
  ],
  exports: [MessageComponent, MessageCustomComponent, MessageReplyComponent, MessageEditComponent],
})
export class MessageModule {}
