/**
 * Unions API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PhotoDataLoyalty } from './photoDataLoyalty';
import { ContactLoyalty } from './contactLoyalty';
import { LoyaltyProductGroupUpdate } from './loyaltyProductGroupUpdate';


/**
 * Редактировать продукт программы лояльности с массивом категорий и регионов – товар / услуга
 */
export interface LoyaltyProductWCategiriesEdit { 
    /**
     * Наименование продукта лояльности.
     */
    name: string;
    /**
     * Описание продукта лояльности.
     */
    description: string;
    /**
     * ID организации продукта.
     */
    organisationId?: string;
    /**
     * ID ассоциации.
     */
    parentOrganisationId?: string;
    /**
     * Информация о компании.
     */
    organisationInfo?: string;
    /**
     * Список контактов по продукту.
     */
    contacts?: Array<ContactLoyalty>;
    /**
     * Список фотографий продукта.
     */
    photos?: Array<PhotoDataLoyalty>;
    /**
     * Список ID категорий.
     */
    categoryIds: Array<string>;
    /**
     * Список ID регионов.
     */
    regionIds?: Array<string>;
    /**
     * Список групп получателей скидки.
     */
    discountGroups?: Array<LoyaltyProductGroupUpdate>;
    /**
     * ответственный сотрудник из объединения
     */
    contactUserId?: string;
    externalAuthorId?: string;
    externalOrganisationId?: string;
    externalOrganisationName?: string;
    externalOrganisationLogoId?: string;
    paidDuration?: number;
    paidBoostRateId?: string;
    paidNewsletterTier1Count?: number;
    paidNewsletterTier2Count?: number;
    paidNewsletterTier3Count?: number;
    requestLeadContacts?: boolean;
    eridToken?: string;
    eridAdInfo?: string;
    /**
     * текст скидки.
     */
    discountText?: string;
    /**
     * Сортировка.
     */
    sortOrder?: number;
    /**
     * ID записи.
     */
    id: string;
    /**
     * TRUE - реклама; иначе FALSE.
     */
    isAdvertisment?: boolean;
}

