import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DragDropModule } from '@angular/cdk/drag-drop';
import {
  TuiButtonModule,
  TuiDataListModule,
  TuiGroupModule,
  TuiHintModule,
  TuiHostedDropdownModule,
  TuiLabelModule,
  TuiPrimitiveTextfieldModule,
  TuiScrollbarModule,
  TuiTextfieldControllerModule,
  TuiDropdownModule,
} from '@taiga-ui/core';
import {
  TuiAccordionModule,
  TuiCheckboxBlockModule,
  TuiInputModule,
  TuiInputNumberModule,
  TuiProgressModule,
  TuiTabsModule,
} from '@taiga-ui/kit';
import { TuiTableModule } from '@taiga-ui/addon-table';
import { ListModule } from '@src/app/shared/list';
import { UserInfoModule } from '@src/app/modules/user-info';
import { OrganisationInfoModule } from '@src/app/modules/organisation-info';
import { DictionariesModule } from '@src/app/modules/dictionaries';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '@src/app/shared';
import { ComponentsModule } from '@src/app/components';
import { HintModule } from '@src/app/modules/hint';
import { IconModule } from '@src/app/modules/icon';
import { ImportUsersModule } from '@src/app/modules/import-users';
import { ImportOrganisationsModule } from '@src/app/modules/import-organisations';
import { CustomNamesTabsModule } from '@src/app/modules/custom-name-tabs';
import { ReportsModule } from '@src/app/modules/reports';
import { ContactsModule } from '@src/app/modules/contacts';
import { ContactsService } from '@src/api';
import { NavigationModule } from '@src/app/modules/navigation';
import { NewListModule } from '@src/app/modules/list';

import { AdministrationComponent } from './administration.component';
import { AdministrationRouting } from './administration.routing';
import { NewUsersComponent } from './new-users/new-users.component';
import { NewAssociationComponent } from './new-association/new-association.component';
import { CustomNamesComponent } from './custom-names/custom-names.component';
import { ReportsComponent } from './reports/reports.component';
import { ImportComponent } from './import/import.component';
import { MailComponent } from './mail/mail.component';
import { StandardUserInfoFieldsComponent } from './standard-user-info-fields/standard-user-info-fields.component';
import { ModerationUsersComponent } from './moderation-users/moderation-users.component';

@NgModule({
  declarations: [
    AdministrationComponent,
    CustomNamesComponent,
    NewUsersComponent,
    NewAssociationComponent,
    ReportsComponent,
    ImportComponent,
    MailComponent,
    StandardUserInfoFieldsComponent,
    ModerationUsersComponent,
  ],
  imports: [
    AdministrationRouting,
    CommonModule,
    DragDropModule,
    FormsModule,
    ListModule,
    OrganisationInfoModule,
    ReactiveFormsModule,
    TuiButtonModule,
    TuiInputModule,
    TuiAccordionModule,
    TuiButtonModule,
    TuiCheckboxBlockModule,
    TuiGroupModule,
    TuiHintModule,
    TuiLabelModule,
    TuiProgressModule,
    TuiScrollbarModule,
    TuiTabsModule,
    TuiTextfieldControllerModule,
    TuiHintModule,
    TuiTableModule,
    UserInfoModule,
    DictionariesModule,
    HintModule,
    ImportUsersModule,
    ImportOrganisationsModule,
    TuiPrimitiveTextfieldModule,
    SharedModule,
    TuiDataListModule,
    TuiHostedDropdownModule,
    TuiDropdownModule,
    ComponentsModule,
    CustomNamesTabsModule,
    TranslateModule,
    TuiInputNumberModule,
    ReportsModule,
    ContactsModule,
    IconModule,
    NavigationModule,
    NewListModule,
  ],
  providers: [ContactsService],
})
export class AdministrationModule {}
