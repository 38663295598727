import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { AbstractTuiControl } from '@taiga-ui/cdk';
import { TuiSizeL } from '@taiga-ui/core';

/**
 * Переключатель с подписью
 * @param {boolean} value - значение переключателя
 * @param {string} label - (опционально) подпись слева от переключателя
 * @param {TuiSizeL} size {@link TuiSizeL} - размер переключателя
 *
 * @see https://www.figma.com/file/tdtoaaGrQa3XOG9Qqv96VI/Unions-(Taiga-3.0)?type=design&node-id=2249-120402&mode=dev
 */
@Component({
  selector: 'app-toggle',
  templateUrl: './toggle.component.html',
  styleUrls: ['./toggle.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToggleComponent extends AbstractTuiControl<boolean> {
  @Input() size: TuiSizeL = 'l';

  get focused(): boolean {
    return false;
  }

  protected getFallbackValue(): boolean {
    return false;
  }
}
