<div class="discount">
  <div class="discount__code">
    <h2>{{ code.code ?? 'common.labels.noData' | translate }}</h2>

    <button tuiButton type="button" appearance="secondary" size="m" (click)="copyPromoCode()">
      {{ 'components.loyaltyDiscountDialog.buttons.copyCode' | translate }}
    </button>
  </div>

  <div class="discount__terms">
    <h1 class="title">{{ 'components.loyaltyDiscountDialog.labels.terms' | translate }}:</h1>

    <app-parse-links
      class="description"
      [value]="code.discountOptions"
      (externalLink)="saveRedirectLink($event)"
    ></app-parse-links>
  </div>
</div>
