<form class="user-info-moderation" [formGroup]="form">
  <div class="user-info-moderation__block">
    <tui-input
      tuiAutoFocus
      tabindex="1"
      [class.tui-skeleton]="loading"
      [class.tui-skeleton_text]="loading"
      formControlName="lastName"
      [tuiTextfieldSize]="tuiElementLargeSize"
    >
      {{ 'components.userInfo.fields.lastName' | translate }}
      <span class="tui-required"></span>
      <input tuiTextfield [attr.autocomplete]="autocompleteTypes.lastName" />
    </tui-input>

    <tui-input
      tabindex="2"
      formControlName="firstName"
      [class.tui-skeleton]="loading"
      [class.tui-skeleton_text]="loading"
      [tuiTextfieldSize]="tuiElementLargeSize"
    >
      {{ 'components.userInfo.fields.firstName' | translate }}
      <span class="tui-required"></span>
      <input tuiTextfield [attr.autocomplete]="autocompleteTypes.firstName" />
    </tui-input>

    <tui-input
      tabindex="3"
      formControlName="middleName"
      [class.tui-skeleton]="loading"
      [class.tui-skeleton_text]="loading"
      [tuiTextfieldSize]="tuiElementLargeSize"
    >
      {{ 'components.userInfo.fields.middleName' | translate }}
      <input tuiTextfield [attr.autocomplete]="autocompleteTypes.middleName" />
    </tui-input>

    <div class="field">
      <div tuiGroup>
        <tui-input-number
          tabindex="4"
          formControlName="birthDateDay"
          [class.tui-skeleton]="loading"
          [class.tui-skeleton_text]="loading"
          [tuiTextfieldSize]="tuiElementLargeSize"
          [min]="1"
          [max]="31"
        >
          {{ 'components.userInfo.fields.day' | translate }}
        </tui-input-number>

        <tui-select
          tabindex="5"
          formControlName="birthDateMonth"
          [class.tui-skeleton]="loading"
          [class.tui-skeleton_text]="loading"
          [tuiTextfieldSize]="tuiElementLargeSize"
          [valueContent]="months ? monthStringify(months) : ''"
        >
          {{ 'components.userInfo.fields.month' | translate }}
          <tui-data-list *tuiDataList>
            <button *ngFor="let month of months" tuiOption [value]="month.id">
              {{ month.name | translate }}
            </button>
          </tui-data-list>
        </tui-select>

        <tui-input-year
          tabindex="6"
          formControlName="birthDateYear"
          [class.tui-skeleton]="loading"
          [class.tui-skeleton_text]="loading"
          [tuiTextfieldSize]="tuiElementLargeSize"
          [tuiTextfieldCleaner]="true"
          [min]="1900"
          [max]="2990"
        >
          {{ 'components.userInfo.fields.year' | translate }}
        </tui-input-year>
      </div>
    </div>

    <app-input-phone
      tabindex="7"
      formControlName="phone"
      [class.tui-skeleton]="loading"
      [class.tui-skeleton_text]="loading"
      [size]="tuiElementLargeSize"
      [phoneValue]="phoneControl.value"
      (phoneValueChange)="onChangePhone($event)"
      [pseudoInvalid]="phoneControl.invalid"
    >
      {{ 'components.userInfo.fields.mobilePhone' | translate }}
      <span class="tui-required"></span>
    </app-input-phone>

    <tui-input
      tabindex="8"
      formControlName="email"
      [class.tui-skeleton]="loading"
      [class.tui-skeleton_text]="loading"
      [tuiTextfieldSize]="tuiElementLargeSize"
    >
      {{ 'components.userInfo.fields.email' | translate }}
      <span class="tui-required"></span>
      <input tuiTextfield [attr.autocomplete]="autocompleteTypes.email" />
    </tui-input>

    <tui-textarea
      class="field field_height_min"
      tabindex="9"
      formControlName="resources"
      [class.tui-skeleton]="loading"
      [class.tui-skeleton_text]="loading"
      [tuiTextfieldSize]="tuiElementLargeSize"
      [expandable]="true"
    >
      {{ 'components.userInfo.fields.resources' | translate }}
    </tui-textarea>

    <tui-input
      tabindex="10"
      formControlName="hobbies"
      [class.tui-skeleton]="loading"
      [class.tui-skeleton_text]="loading"
      [tuiTextfieldSize]="tuiElementLargeSize"
    >
      {{ 'components.userInfo.fields.hobbies' | translate }}
    </tui-input>

    <search-users-input
      *ngIf="authUser$ | async as authUser"
      tabindex="11"
      mode="single"
      formControlName="curator"
      [label]="'components.userInfo.fields.curator' | translate"
      [class.tui-skeleton]="loading"
      [class.tui-skeleton_text]="loading"
      [filter]="{
        searchForRegistredOnly: true,
        organisationIds: authUser.parentOrganisationId ? [authUser.parentOrganisationId] : []
      }"
      [excludeFilterFields]="['organizations']"
      [showFilterButton]="true"
      [textfieldSize]="tuiElementLargeSize"
    ></search-users-input>

    <tui-input-date
      tabindex="12"
      formControlName="authorityValidTill"
      [class.tui-skeleton]="loading"
      [class.tui-skeleton_text]="loading"
      [tuiTextfieldSize]="tuiElementLargeSize"
    >
      {{ 'components.userInfo.fields.authorityValidTill' | translate }}
    </tui-input-date>

    <tui-checkbox-block
      class="field"
      tabindex="13"
      formControlName="isFeePaid"
      [class.tui-skeleton]="loading"
      [class.tui-skeleton_text]="loading"
      [size]="tuiElementSmallSize"
    >
      {{ 'components.userInfo.fields.feeNotPaid' | translate }}
    </tui-checkbox-block>
  </div>

  <div class="user-info-moderation__organisations">
    <tui-island class="tui-island">
      <div class="tui-island__paragraph item" formGroupName="organisation">
        <app-organisation-input tabindex="14" formControlName="organisationId" [textfieldSize]="tuiElementLargeSize">
          {{ 'components.userInfo.fields.organisation' | translate }}
          <span class="tui-required"></span>
        </app-organisation-input>

        <tui-input
          class="field"
          tabindex="15"
          formControlName="jobTitlesDescription"
          [class.tui-skeleton]="loading"
          [class.tui-skeleton_text]="loading"
          [tuiTextfieldSize]="tuiElementLargeSize"
        >
          {{ 'components.userInfo.fields.jobTitlesDescription' | translate }}
        </tui-input>

        <tui-multi-select
          *tuiLet="jobsForEditing$ | async as jobs"
          class="field"
          tabindex="16"
          formControlName="jobTitles"
          [class.tui-skeleton]="loading"
          [class.tui-skeleton_text]="loading"
          [tuiTextfieldSize]="tuiElementLargeSize"
          [tuiTextfieldCleaner]="true"
          [expandable]="true"
          [editable]="false"
          [stringify]="stringifyJobTitle"
        >
          {{ 'field.jobTitle' | customNames }}
          <span class="tui-required"></span>
          <tui-data-list *tuiDataList tuiMultiSelectGroup>
            <button *ngFor="let job of jobs" tuiOption [value]="job">
              {{ job.jobTitleName }}
            </button>
          </tui-data-list>
        </tui-multi-select>

        <div>
          <tui-select
            *tuiLet="roles$ | async as roles"
            class="field"
            tabindex="17"
            formControlName="roleIds"
            [class.tui-skeleton]="loading"
            [class.tui-skeleton_text]="loading"
            [valueContent]="roles ? rolesStringify(roles) : ''"
            [tuiTextfieldSize]="tuiElementLargeSize"
          >
            {{ 'components.userInfo.fields.role' | translate }}
            <span class="tui-required"></span>
            <tui-data-list *tuiDataList>
              <button *ngFor="let role of roles" tuiOption [value]="[role.id]">
                {{ role.description }}
              </button>
            </tui-data-list>
          </tui-select>
        </div>
      </div>
    </tui-island>
  </div>

  <app-contacts
    class="user-info-moderation__contacts"
    tabindex="18"
    formControlName="contacts"
    [isModeration]="true"
    [allowCreating]="false"
    [allowEditing]="false"
    [allowDeleting]="false"
    [allowFilesDeleting]="false"
    [loading]="loading"
  ></app-contacts>

  <div *ngIf="!loading" class="user-info-moderation__buttons">
    <button
      tuiButton
      class="user-info-moderation-button"
      tabindex="19"
      type="button"
      appearance="primary"
      [size]="tuiElementLargeSize"
      (click)="onClickSaveButton()"
    >
      {{ 'common.buttons.confirm' | translate }}
    </button>

    <button
      tuiButton
      class="user-info-moderation-button"
      tabindex="20"
      type="button"
      appearance="secondary"
      [size]="tuiElementLargeSize"
      (click)="onClickCancelButton()"
    >
      {{ 'common.buttons.putAside' | translate }}
    </button>

    <button
      tuiButton
      class="user-info-moderation-button"
      tabindex="21"
      type="button"
      appearance="accent"
      [size]="tuiElementLargeSize"
      (click)="onClickRejectButton()"
    >
      {{ 'common.buttons.reject' | translate }}
    </button>
  </div>
</form>
