/**
 * Unions API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Письмо рассылки с аттачами.
 */
export interface NewsletterWAttachesView { 
    /**
     * ID шаблона рассылки.
     */
    newsletterTemplateId?: string;
    /**
     * Заголовок рассылки.
     */
    title?: string;
    /**
     * Основной текст рассылки.
     */
    body?: string;
    /**
     * Дата отложенной рассылки.
     */
    sendOnDate?: string;
    /**
     * Опция показа кнопки подтверждения получения.
     */
    showReceiptConfirm?: boolean;
    /**
     * Контактное лицо.
     */
    contactPersonId?: string;
    /**
     * Массив пользователей для рассылки.
     */
    sendTo?: Array<string>;
    /**
     * ID записи.
     */
    id?: string;
    /**
     * ID ассоциации.
     */
    parentOrganisationId?: string;
    /**
     * Статус.
     */
    status?: string;
    /**
     * Дата создания.
     */
    createdDate?: string;
    /**
     * Дата редактирования.
     */
    modifiedDate?: string;
    /**
     * Приаттаченные фотографии.
     */
    photoIds?: Array<string>;
    /**
     * Приаттаченные документы.
     */
    documentIds?: Array<string>;
    /**
     * кол-во адресатов, кто подтвердил получение рассылки.
     */
    receivedCount?: number;
    /**
     * общее кол-во адресатов рассылки.
     */
    sentCount?: number;
}

