/**
 * Unions API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Доп поле пользователя.
 */
export interface ContactForUserView { 
    /**
     * Тип контакта
     */
    contactTypeId?: number;
    /**
     * Контакт для связи.
     */
    contact?: string;
    /**
     * Описание.
     */
    description?: string;
    /**
     * Приоритет.
     */
    isPriority?: boolean;
    /**
     * Сортировка.
     */
    sortOrder?: number;
    isRequired?: boolean;
    /**
     * ID пользователя.
     */
    userId: string;
}

