/**
 * Unions API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Чаты для возврата на фронт.
 */
export interface ChatView { 
    /**
     * Внутренний идентификатор чата.
     */
    id?: string;
    /**
     * Идентификатор чата в telegram.
     */
    chatId?: number;
    /**
     * Тип.
     */
    chatType?: number;
    /**
     * Описание.
     */
    description?: string;
    /**
     * Статус.
     */
    status?: number;
    /**
     * ID ассоциации.
     */
    parentOrganisationId?: string;
}

