<div class="select-items">
  <app-search (searchQueryChange)="onSearchQueryChange($event)"></app-search>

  <app-accordion-selection
    [title]="titleVisible"
    [description]="descriptionVisible"
    [items]="visibleItems"
    [searchItems]="searchVisibleItems"
    [actionForAdmin]="actionForAdmin"
    [reorderButton]="true"
    (itemsChange)="onItemsChange()"
  ></app-accordion-selection>

  <app-accordion-selection
    [title]="titleInvisible"
    [description]="descriptionInvisible"
    [items]="invisibleItems"
    [searchItems]="searchInvisibleItems"
    (itemsChange)="onItemsChange()"
  ></app-accordion-selection>
</div>

<div class="footer">
  <button
    class="footer__button"
    tuiButton
    type="button"
    appearance="primary"
    [showLoader]="loading"
    [size]="tuiElementMediumSize"
    (click)="onClickSaveButton($event)"
  >
    {{ 'common.buttons.save' | translate }}
  </button>
</div>
