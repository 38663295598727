<div class="final-speech">
  <h2>Ваш результат сохранён</h2>

  <p class="text_size-m">{{ text }}</p>

  <button
    tuiButton
    type="button"
    appearance="primary"
    [size]="tuiElementLargeSize"
    appearance="primary"
    (click)="onClose()"
  >
    Вернуться к опросу
  </button>
</div>
