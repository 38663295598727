import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  TuiButtonModule,
  TuiDataListModule,
  TuiDropdownModule,
  TuiGroupModule,
  TuiHostedDropdownModule,
  TuiLoaderModule,
  TuiPrimitiveCheckboxModule,
  TuiScrollbarModule,
  TuiSvgModule,
  TuiTextfieldControllerModule,
} from '@taiga-ui/core';
import {
  TuiAccordionModule,
  TuiAvatarModule,
  TuiBadgeModule,
  TuiDataListWrapperModule,
  TuiInputModule,
  TuiMultiSelectModule,
  TuiSelectModule,
} from '@taiga-ui/kit';
import { TuiLetModule, TuiActiveZoneModule, TuiObscuredModule } from '@taiga-ui/cdk';
import { FormsModule } from '@angular/forms';
import { SharedModule } from '@src/app/shared';
import { ComponentsModule } from '@src/app/components';

import {
  SearchUsersDialogComponent,
  SearchUsersInputComponent,
  RenderUserRowComponent,
  UsersTagsComponent,
  SearchUsersFiltersComponent,
  FilterAccordionContentComponent,
  FilterAccordionTitleComponent,
  SelectAllLinkComponent,
  FilterAccordionContentFieldsComponent,
} from './components';

@NgModule({
  declarations: [
    SearchUsersDialogComponent,
    SearchUsersInputComponent,
    RenderUserRowComponent,
    UsersTagsComponent,
    SearchUsersFiltersComponent,
    FilterAccordionContentComponent,
    FilterAccordionTitleComponent,
    SelectAllLinkComponent,
    FilterAccordionContentFieldsComponent,
  ],
  imports: [
    CommonModule,
    TuiButtonModule,
    TuiMultiSelectModule,
    TuiTextfieldControllerModule,
    TuiLetModule,
    TuiDataListModule,
    FormsModule,
    TuiSvgModule,
    TuiGroupModule,
    TuiAvatarModule,
    SharedModule,
    ComponentsModule,
    TuiDropdownModule,
    TuiHostedDropdownModule,
    TuiDataListWrapperModule,
    TuiLoaderModule,
    TuiPrimitiveCheckboxModule,
    TuiScrollbarModule,
    TuiBadgeModule,
    TuiAccordionModule,
    TuiInputModule,
    TuiActiveZoneModule,
    TuiObscuredModule,
    TuiSelectModule,
  ],
  exports: [SearchUsersInputComponent],
})
export class SearchUsersModule {}
