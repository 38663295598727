<ng-container *ngIf="!debouncedLoading; else tmpLoading">
  <ng-content></ng-content>
</ng-container>

<ng-template #tmpLoading>
  <app-skeleton-row></app-skeleton-row>
  <app-skeleton-row></app-skeleton-row>
  <app-skeleton-row></app-skeleton-row>
  <app-skeleton-row></app-skeleton-row>
  <app-skeleton-row></app-skeleton-row>
</ng-template>
