<ng-container *ngIf="mode === 'view'">
  <div class="field field_size_{{ size ? size : 'l' }}">
    <label [tuiLabel]="label" class="field__name" style="display: unset"></label>

    <app-parse-links class="field__value" [value]="value"></app-parse-links>
    <div class="dotted-line"></div>
  </div>
</ng-container>

<ng-template #label>
  {{ name }}
</ng-template>
