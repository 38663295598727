import { ChangeDetectionStrategy, Component, Input, TemplateRef } from '@angular/core';
import { GetUsersBirthdaysResponseItemDto } from '@src/api';

@Component({
  selector: 'app-new-list-item-user',
  templateUrl: './list-item-user.component.html',
  styleUrls: ['./list-item-user.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ListItemUserComponent {
  @Input() user: GetUsersBirthdaysResponseItemDto = {};

  /** Шаблон для отображения даты рождения */
  @Input() birthDateContent?: TemplateRef<GetUsersBirthdaysResponseItemDto>;
}
