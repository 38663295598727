import { AbstractControl, ValidatorFn } from '@angular/forms';
import { ContactUI } from '@src/models';
import { isFileContact } from '@src/utils';

export const requireContactsValidator = (): ValidatorFn => {
  return ({ value }: AbstractControl) => {
    const contacts: ContactUI[] = value?.filter((contact: ContactUI) => contact.isRequired);

    if (contacts?.length) {
      for (const contact of contacts) {
        if (isFileContact(contact.contactTypeId)) {
          if (!contact.newDocument && !contact.oldDocument) {
            return { contactInvalid: 'contact invalid' };
          }
        } else if (!contact.contact) {
          return { contactInvalid: 'contact invalid' };
        }
      }
    }

    return null;
  };
};
