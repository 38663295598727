import { Component, ChangeDetectionStrategy, Input, SimpleChanges, OnChanges } from '@angular/core';
import { TuiSizeL, TuiSizeM, TuiSizeS } from '@taiga-ui/core';
import { DocumentView } from '@src/api';
import { TuiFileLikeUI, ViewMode } from '@src/models';
import { DocumentService } from '@src/core/services';

@Component({
  selector: 'app-field-files-list',
  templateUrl: './field-files-list.component.html',
  styleUrls: ['./field-files-list.component.scss', '../styles/field-content.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FieldFilesListComponent implements OnChanges {
  @Input() mode?: ViewMode = 'view';
  @Input() name?: string;
  @Input() files?: DocumentView[] | null;
  @Input() size: TuiSizeS | TuiSizeM | TuiSizeL = 'l';

  tuiFiles?: TuiFileLikeUI[];

  constructor(private documentService: DocumentService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.files && this.files) {
      this.tuiFiles = this.mapFilesToFormData(this.files);
    }
  }

  downloadDocument(id?: string): void {
    if (!id) return;

    this.documentService.downloadDocumentById(id);
  }

  private mapFilesToFormData(docs: DocumentView[]) {
    return docs.map(file => {
      return {
        name: file.fileName || '',
        id: file.id || '',
      };
    });
  }
}
