<mat-list-option
  class="list-item list-item-events"
  [class.list-item-events_invitation]="isInvitation()"
  [class.list-item-events_created]="data?.createdBy === authUserId"
  [value]="data?.id"
  [selected]="!loading && selectedId === data?.id"
>
  <div
    matListIcon
    class="list-item__image list-item__image_large"
    [class.tui-skeleton]="loading"
    [class.tui-skeleton_rounded]="loading"
  >
    <tui-avatar
      size="m"
      [autoColor]="!data?.photoId"
      [rounded]="true"
      [avatarUrl]="data?.photoId | getImageSrc"
      [text]="data?.subject || ''"
    ></tui-avatar>
  </div>
  <div class="list-item__text-box">
    <div class="list-item__line">
      <p
        class="list-item__text list-item__text_grey date"
        [class.tui-skeleton]="loading"
        [class.tui-skeleton_text]="loading"
      >
        {{ data?.dateStart | stringToDate | datePeriod : (data?.dateEnd | stringToDate) }}
      </p>

      <app-badge *ngIf="badgeData" [value]="badgeData.value | translate" [color]="badgeData.color"></app-badge>
    </div>

    <h3
      class="list-item__title list-item__title_line list-item__title_line_3"
      [class.tui-skeleton]="loading"
      [class.tui-skeleton_text]="loading"
    >
      {{ data?.subject }}
    </h3>

    <p
      *ngIf="data?.address"
      class="list-item__text list-item__text_grey address"
      [title]="data?.address"
      [class.tui-skeleton]="loading"
      [class.tui-skeleton_text]="loading"
    >
      <lucide-icon class="list-item__icon" name="map-pin"></lucide-icon>
      {{ data?.address }}
    </p>
  </div>
</mat-list-option>
