import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BreakpointObserver } from '@angular/cdk/layout';
import { BreakpointObserverHelperService } from '@src/core/services';
import { ResizableBaseComponent } from '@src/app/components/resizable-base-component';
import { NavigationItem, NavigationTree, ONLY_LP_SETTINGS_NAVIGATION } from '@src/app/modules/navigation';

@Component({
  selector: 'lp-settings',
  templateUrl: './lp-settings.component.html',
  styleUrls: ['./lp-settings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LpSettingsComponent extends ResizableBaseComponent implements OnInit, OnDestroy {
  selectedItem?: NavigationItem;

  navTree: NavigationTree[] = ONLY_LP_SETTINGS_NAVIGATION;

  constructor(
    readonly cdr: ChangeDetectorRef,
    readonly breakpointObserver: BreakpointObserver,
    readonly breakpointObserverHelperService: BreakpointObserverHelperService,
    private readonly router: Router,
  ) {
    super(cdr, breakpointObserver, breakpointObserverHelperService);
  }

  get showFullPage(): boolean {
    return !this.selectedItem || this.isLargeScreen;
  }

  onClickReturnButton() {
    this.selectedItem = undefined;
    this.router.navigate(['lp-settings']);
  }
}
