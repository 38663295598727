import { Component, Input } from '@angular/core';
import { APP_CONFIG } from '@src/core';
import { TypeOfObjectTree } from '@src/api';

@Component({
  selector: 'dictionary-item',
  templateUrl: './dictionary-item.component.html',
  styleUrls: ['../../accordion-levels.component.scss', './dictionary-item.component.scss'],
})
export class DictionaryItemComponent {
  readonly APP_CONFIG = APP_CONFIG;

  @Input() item?: TypeOfObjectTree;

  constructor() {}
}
