import { Component, Input } from '@angular/core';
import { ObjectId } from '@src/types/id';
import { CommitteeUI } from '@src/models';

import { ItemType } from '../model/item.model';

@Component({
  selector: 'app-list-item-committees',
  templateUrl: './list-item-committees.component.html',
  styleUrls: ['../styles/list-item.component.scss', './list-item-committees.component.scss'],
})
export class ListItemCommitteesComponent {
  @Input() set item(data: ItemType | undefined) {
    this.data = data as CommitteeUI;
  }

  @Input() selectedId: ObjectId;
  @Input() loading?: boolean;

  data?: CommitteeUI;
}
