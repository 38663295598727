<div class="message-audio">
  <div class="message-audio__message-content message-content">
    <div class="message-audio__audio-wrapper" [class.tui-skeleton]="!audio.src">
      <audio
        #audio
        tuiMedia
        controls
        [src]="messageAudioSource?.src | safe: 'url'"
        [title]="messageAudio?.audio?.fileName || 'common.labels.audio' | translate"
      ></audio>
    </div>
    <ng-content></ng-content>
  </div>
</div>
