import { BreakpointObserver } from '@angular/cdk/layout';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { takeUntil } from 'rxjs';
import { ResizableBaseComponent } from '@src/app/components';
import { BreakpointObserverHelperService } from '@src/core/services';
import { fixRouteParam } from '@src/utils';

@Component({
  selector: 'app-registration-page',
  templateUrl: './registration-page.component.html',
  styleUrls: ['./registration-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RegistrationPageComponent extends ResizableBaseComponent {
  associationId?: string;

  constructor(
    readonly cdr: ChangeDetectorRef,
    readonly breakpointObserver: BreakpointObserver,
    readonly breakpointObserverHelperService: BreakpointObserverHelperService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
  ) {
    super(cdr, breakpointObserver, breakpointObserverHelperService);
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.route.paramMap.pipe(takeUntil(this.destroyed$$)).subscribe(async params => {
      this.associationId = fixRouteParam(params.get('associationId'));
      this.cdr.markForCheck();
    });
  }

  onSaveRegistrationUser(): void {
    this.router.navigate(['login']);
  }
}
