import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'app-organisation-configs',
  templateUrl: './organisation-configs.component.html',
  styleUrls: ['./organisation-configs.component.scss', '../styles/organisation-content.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrganisationConfigsComponent {
  @Input() organisationId?: string | null;
}
