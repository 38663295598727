/**
 * Unions API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Документ.
 */
export interface DocumentView { 
    /**
     * ID.
     */
    id?: string;
    /**
     * Текст.
     */
    text?: string;
    /**
     * Краткое описание.
     */
    description?: string;
    /**
     * Имя файла.
     */
    fileName?: string;
    /**
     * Статус.
     */
    status?: number;
    /**
     * Тип документа.
     */
    fileType?: number;
    /**
     * Размер.
     */
    fileSize?: number;
    /**
     * Тип документа.
     */
    documentTypeName?: string;
    /**
     * ID владельца файла.
     */
    attachId?: string;
}

