<app-skeleton-wrapper [loading]="loading ?? false">
  <tui-scrollbar *ngIf="items" class="list-wrapper">
    <div *ngIf="withCounter && items.length" class="members-counter">
      <span class="members-counter__title">
        {{ 'common.labels.quantity' | translate }}: {{ count ?? items.length }}
      </span>
    </div>
    <mat-selection-list
      #selectionList
      *ngIf="items?.length; else noItems"
      class="list"
      [multiple]="false"
      (selectionChange)="onSelectionChange($event)"
    >
      <ng-container *ngIf="groupingEnable; else simpleList">
        <div *ngFor="let group of groups" class="group">
          <ng-container *ngIf="group.items.length">
            <div class="group__title">{{ group.title }} ({{ group.items.length }})</div>
            <div class="group__list">
              <ng-container
                *ngTemplateOutlet="
                  list;
                  context: {
                    items: group.items,
                    listType: listType,
                    selectedId: selectedId,
                    itemActions: itemActions
                  }
                "
              ></ng-container>
            </div>
          </ng-container>
        </div>
      </ng-container>

      <ng-template #simpleList>
        <ng-container
          *ngTemplateOutlet="
            list;
            context: {
              items: items,
              listType: listType,
              selectedId: selectedId,
              itemActions: itemActions
            }
          "
        ></ng-container>
      </ng-template>

      <ng-template
        #list
        let-items="items"
        let-listType="listType"
        let-selectedId="selectedId"
        let-itemActions="itemActions"
      >
        <ng-container *ngFor="let item of items">
          <div class="list__item-container">
            <div class="list__item" [ngSwitch]="listType">
              <app-list-item-channels
                *ngSwitchCase="'channels'"
                [item]="item"
                [selectedId]="selectedId"
              ></app-list-item-channels>
              <app-list-item-chats
                *ngSwitchCase="'chats'"
                [item]="item"
                [selectedId]="selectedId"
              ></app-list-item-chats>
              <app-list-item-committees
                *ngSwitchCase="'committees'"
                [item]="item"
                [selectedId]="selectedId"
              ></app-list-item-committees>
              <app-list-item-events
                *ngSwitchCase="'events'"
                [item]="item"
                [selectedId]="selectedId"
              ></app-list-item-events>
              <app-list-item-organisations
                *ngSwitchCase="'organisations'"
                [item]="item"
                [selectedId]="selectedId"
              ></app-list-item-organisations>
              <app-list-item-polls
                *ngSwitchCase="'polls'"
                [item]="item"
                [selectedId]="selectedId"
              ></app-list-item-polls>
              <app-list-item-users
                *ngSwitchCase="'users'"
                [item]="item"
                [selectedId]="selectedId"
              ></app-list-item-users>
            </div>

            <ng-container *ngIf="itemActions?.length">
              <ng-container *ngFor="let itemAction of itemActions">
                <ng-container *ngIf="itemAction.isVisible && itemAction.isVisible(item)">
                  <button
                    *ngIf="itemAction.icon; else textLink"
                    tuiIconButton
                    class="list__action-button"
                    type="button"
                    [ngClass]="'list__action-button_' + itemAction.type"
                    appearance="custom"
                    size="s"
                    [icon]="itemAction.icon"
                    [attr.aria-label]="itemAction.title"
                    [title]="itemAction.title"
                    (click)="itemAction.clickCallback(item)"
                  ></button>
                  <ng-template #textLink>
                    <app-link
                      class="list__action-link"
                      [ngClass]="'list__action-link_' + itemAction.type"
                      [title]="itemAction.title"
                      [displayText]="itemAction.title"
                      (click)="itemAction.clickCallback(item)"
                    ></app-link>
                  </ng-template>
                </ng-container>
              </ng-container>
            </ng-container>
          </div>
        </ng-container>
        <div
          *ngIf="items && count && items.length < count"
          class="list__intersection-observer"
          waIntersectionObserver
          (waIntersectionObservee)="onIntersection($event)"
        ></div>
      </ng-template>
    </mat-selection-list>

    <ng-template #noItems>
      <ng-container *ngIf="!loading">
        <p class="list-stub">{{ 'common.labels.noData' | translate }}</p>
      </ng-container>
    </ng-template>
  </tui-scrollbar>
</app-skeleton-wrapper>
