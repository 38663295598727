import { Injectable, OnDestroy } from '@angular/core';
import { UserService } from '@src/api';
import { catchError, map, takeUntil } from 'rxjs/operators';
import { BehaviorSubject, lastValueFrom, Subject, throwError } from 'rxjs';
import { UserUI } from '@src/models';
import { NgxPermissionsService, NgxRolesService } from 'ngx-permissions';
import { logger, normalizeUserDataForUI } from '@src/utils';
import { isEqual } from 'lodash-es';

import { ROLE_PERMISSIONS_DEFAULT } from '../constants';
import { JwtTokenPayload, Role, RoleCode } from '../types';

@Injectable({
  providedIn: 'root',
})
export class AuthUserService implements OnDestroy {
  /**
   * TODO: для обратной совместимости, чтобы UserService смог получить данные текущего пользователя.
   * Места, где идет чтение данных текущего пользователя, нужно брать из данного сервиса {AuthUserService#user}.
   */
  authUser$: BehaviorSubject<UserUI | undefined> = new BehaviorSubject<UserUI | undefined>(undefined);

  private userData?: UserUI;
  private defaultOrganisationRoles?: Role[];

  private destroyed$$: Subject<void> = new Subject<void>();

  constructor(
    private userService: UserService,
    private permissionsService: NgxPermissionsService,
    private rolesService: NgxRolesService,
  ) {
    logger('AuthUserService constructor');

    this.permissionsService.permissions$.pipe(takeUntil(this.destroyed$$)).subscribe(ngxPermissions => {
      if (!this.userData) return;

      const permissions = Object.keys(ngxPermissions);
      if (!this.defaultOrganisationRoles || isEqual(this.userData.permissions, permissions)) return;

      this.setPermissions(this.userData, permissions, this.defaultOrganisationRoles);
      this.authUser$.next(this.userData);
    });
  }

  get user() {
    return this.userData;
  }

  ngOnDestroy(): void {
    this.destroyed$$.next();
    this.destroyed$$.complete();
  }

  async init(payload: JwtTokenPayload): Promise<UserUI> {
    const user = await lastValueFrom(
      this.userService.getUserProfile().pipe(
        catchError(err => {
          return throwError(err);
        }),
        map(normalizeUserDataForUI),
      ),
    );

    user.parentOrganisationId = payload.HeadId;
    user.organisationId = payload.OrganisationId;

    // TODO: фильтр по дефолтной организации добавлен временно по задаче #10370
    this.defaultOrganisationRoles = payload.Roles.filter(role => role.OrganisationId === payload.OrganisationId);

    user.roles = this.defaultOrganisationRoles.map(role => role.RoleName);
    if (user.roles) {
      const permissions = this.getPermissions(user.roles);
      this.setPermissions(user, permissions, this.defaultOrganisationRoles);

      this.permissionsService.loadPermissions(permissions);
      this.rolesService.addRoles(user.rolesWithPermissions as Record<string, string[]>);
    }

    user.isFirstSignIn = (payload.isFirstSignIn as string).toLocaleLowerCase() === 'true';

    this.userData = user;
    this.authUser$.next(user);

    return user;
  }

  private getPermissions(roles: string[]) {
    const permissions = roles.reduce<string[]>((acc, role) => {
      if (ROLE_PERMISSIONS_DEFAULT[role as RoleCode]) {
        acc.push(...ROLE_PERMISSIONS_DEFAULT[role as RoleCode]);
      }

      return acc;
    }, []);

    return Array.from(new Set(permissions));
  }

  private getRolesWithPermissions(user: UserUI, permissions: string[]) {
    return user.roles?.reduce<Partial<Record<RoleCode, string[]>>>((acc, role) => {
      if (ROLE_PERMISSIONS_DEFAULT[role as RoleCode]) {
        const roleWithPermissions = ROLE_PERMISSIONS_DEFAULT[role as RoleCode].filter(permission =>
          permissions?.includes(permission),
        );

        acc[role as RoleCode] = [...roleWithPermissions];

        if (role === RoleCode.AdminDO && user.organisationId) {
          acc[role]?.push(user.organisationId);
        }
      }

      return acc;
    }, {});
  }

  private getPermissionsForOrganisations(roles: Role[], permissions: string[]) {
    return roles.reduce<Record<string, string[]>>((acc, role) => {
      const roleName = role.RoleName as RoleCode;

      if (ROLE_PERMISSIONS_DEFAULT[roleName] && role.OrganisationId) {
        const roleWithPermissions = ROLE_PERMISSIONS_DEFAULT[roleName].filter(permission =>
          permissions?.includes(permission),
        );
        acc[role.OrganisationId] = [...roleWithPermissions];
      }

      return acc;
    }, {});
  }

  private setPermissions(user: UserUI, permissions: string[], roles: Role[]) {
    user.permissions = permissions;
    user.rolesWithPermissions = this.getRolesWithPermissions(user, permissions);
    user.permissionsForOrganisations = this.getPermissionsForOrganisations(roles, permissions);
  }
}
