<p>
  {{ 'components.deleteAuthUserDialog.labels.DeleteAuthUserPart1' | translate }}
  <br />
  {{ 'components.deleteAuthUserDialog.labels.DeleteAuthUserPart2' | translate : { value: confirmationText } }}
</p>

<form class="form" [formGroup]="form">
  <tui-input
    formControlName="enteredText"
    [tuiTextfieldSize]="tuiElementMediumSize"
    [tuiTextfieldLabelOutside]="true"
  ></tui-input>
</form>

<button tuiButton type="button" [size]="tuiElementMediumSize" class="tui-space_right-3" (click)="apply()">
  {{ 'common.buttons.confirm' | translate }}
</button>
<button tuiButton type="button" appearance="secondary" [size]="tuiElementMediumSize" (click)="cancel()">
  {{ 'common.buttons.cancel' | translate }}
</button>
