<tui-textarea
  *ngIf="mode === 'view'"
  class="field_height_min"
  [ngModel]="value"
  [tuiTextfieldSize]="size"
  [expandable]="true"
  [readOnly]="true"
>
  {{ name }}
  <textarea tuiTextfield appTestingId="Input.Text"></textarea>
</tui-textarea>
