<mat-list-option
  class="list-item list-item-channels"
  [value]="data?.id"
  [selected]="!loading && selectedId === data?.id"
>
  <div
    matListIcon
    class="list-item__image list-item__image_large"
    [class.tui-skeleton]="loading"
    [class.tui-skeleton_rounded]="loading"
  >
    <tui-avatar
      size="m"
      [autoColor]="!data?.photoId"
      [rounded]="true"
      [avatarUrl]="data?.photoId | getImageSrc"
      [text]="data && data.name ? data.name : ''"
    ></tui-avatar>
  </div>
  <div class="list-item__text-box">
    <div class="list-item__text-line" [class.tui-skeleton]="loading" [class.tui-skeleton_text]="loading">
      <div class="list-item-channels__heading">
        <mat-icon
          class="list-item__title-icon list-item__title-icon_type"
          [title]="
            data && data.chatTypeId === 2 ? ('common.labels.channel' | translate) : ('common.labels.group' | translate)
          "
        >
          {{ data && data.chatTypeId === 2 ? 'campaign' : 'groups' }}
        </mat-icon>
        <h3
          class="list-item__title"
          [title]="data?.name"
          [class.tui-skeleton]="loading"
          [class.tui-skeleton_text]="loading"
        >
          {{ data?.name }}
        </h3>
      </div>
    </div>
    <div
      *ngIf="data?.description"
      class="list-item__text list-item__text_grey"
      [title]="data?.description"
      [class.tui-skeleton]="loading"
      [class.tui-skeleton_text]="loading"
    >
      {{ data?.description }}
    </div>
  </div>
</mat-list-option>
