import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { NgxPermissionsService } from 'ngx-permissions';
import { OrganisationService, UserService } from '@src/core/services';

@Injectable({
  providedIn: 'root',
})
export class PermissionService {
  allowEditing$ = new BehaviorSubject(false);
  allowDeleting$ = new BehaviorSubject(false);

  allowSpecialFieldsViewing$ = new BehaviorSubject(false);
  allowSpecialFieldsEditing$ = new BehaviorSubject(false);
  allowSpecialFieldsForAssociationViewing$ = new BehaviorSubject(false);
  allowSpecialFieldsForAssociationEditing$ = new BehaviorSubject(false);

  allowUsersViewing$ = new BehaviorSubject(false);
  allowUserEditing$ = new BehaviorSubject(false);

  allowGroupSubscribing$ = new BehaviorSubject(false);
  allowGroupSpecialFieldsViewing$ = new BehaviorSubject(false);
  allowGroupEditing$ = new BehaviorSubject(false);

  allowConfigsViewing$ = new BehaviorSubject(false);

  constructor(
    private readonly ngxPermissionsService: NgxPermissionsService,
    private readonly userService: UserService,
    private readonly organisationService: OrganisationService,
  ) {
    this.organisationService.organisation$.subscribe(organisation => {
      if (!organisation?.id) {
        return;
      }

      this.getPermissions(organisation.id);
    });
  }

  getPermissions(organisationId: string) {
    const authUser = this.userService.authUser$.value;

    const permissions = this.ngxPermissionsService.getPermissions();
    const permissionsForOrganisation =
      organisationId && authUser?.permissionsForOrganisations
        ? authUser.permissionsForOrganisations[organisationId]
        : [];

    this.allowEditing$.next(
      'organisationEditing' in permissions || permissionsForOrganisation?.includes('onlyYourOrganisationEditing'),
    );

    this.allowDeleting$.next('organisationDeleting' in permissions);

    this.allowSpecialFieldsViewing$.next(
      'organisationSpecialFieldsViewing' in permissions ||
        permissionsForOrganisation?.includes('onlyYourOrganisationSpecialFieldsViewing'),
    );

    this.allowSpecialFieldsEditing$.next(
      'organisationSpecialFieldsEditing' in permissions ||
        permissionsForOrganisation?.includes('onlyYourOrganisationSpecialFieldsEditing'),
    );

    this.allowSpecialFieldsForAssociationViewing$.next(
      'organisationSpecialFieldsForAssociationViewing' in permissions ||
        permissionsForOrganisation?.includes('onlyYourOrganisationSpecialFieldsForAssociationViewing'),
    );

    this.allowSpecialFieldsForAssociationEditing$.next('organisationSpecialFieldsForAssociationEditing' in permissions);

    this.allowUsersViewing$.next(
      'organisationEmployeeViewing' in permissions ||
        !!permissionsForOrganisation?.includes('onlyYourOrganisationEmployeeViewing'),
    );

    this.allowUserEditing$.next(
      'organisationEmployeeCreating' in permissions ||
        permissionsForOrganisation?.includes('onlyYourOrganisationEmployeeCreating'),
    );

    this.allowGroupSubscribing$.next('organisationChannelSubscribing' in permissions);

    this.allowGroupSpecialFieldsViewing$.next(
      'associationChannelSpecialFieldsViewing' in permissions && authUser?.parentOrganisationId === organisationId,
    );

    this.allowGroupEditing$.next(
      'organisationChannelCreating' in permissions ||
        permissionsForOrganisation?.includes('onlyYourOrganisationChannelCreating'),
    );

    this.allowConfigsViewing$.next('organisationConfigsViewing' in permissions);
  }
}
