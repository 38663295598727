import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { TuiButtonModule, TuiTextfieldControllerModule } from '@taiga-ui/core';
import { TuiInputDateModule } from '@taiga-ui/kit';

import { ReportsComponent } from './reports.component';
import { ReportsService } from './services';
import { ReportFormComponent } from './report-form/report-form.component';

@NgModule({
  declarations: [ReportsComponent, ReportFormComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TranslateModule,
    TuiButtonModule,
    TuiInputDateModule,
    TuiTextfieldControllerModule,
  ],
  providers: [ReportsService],
  exports: [ReportsComponent],
})
export class ReportsModule {}
