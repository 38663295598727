import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, forkJoin, Observable, Subject, throwError } from 'rxjs';
import { catchError, map, takeUntil } from 'rxjs/operators';
import {
  BusinessTypesService as ApiBusinessTypesService,
  BusinessTypeForUnionAdd,
  BusinessTypeForUnionView,
  BusinessTypesForUnionService,
} from '@src/api';
import { BusinessTypeUI } from '@src/models';

@Injectable({
  providedIn: 'root',
})
export class BusinessTypesService implements OnDestroy {
  businessType$: BehaviorSubject<BusinessTypeUI | null> = new BehaviorSubject<BusinessTypeUI | null>(null);
  businessTypes$: BehaviorSubject<BusinessTypeUI[] | null> = new BehaviorSubject<BusinessTypeUI[] | null>(null);
  allBusinessTypes$: BehaviorSubject<BusinessTypeUI[] | null> = new BehaviorSubject<BusinessTypeUI[] | null>(null);

  private destroyed$$: Subject<void> = new Subject<void>();

  constructor(
    private businessTypesService: ApiBusinessTypesService,
    private businessTypesForUnionService: BusinessTypesForUnionService,
  ) {}

  ngOnDestroy(): void {
    this.destroyed$$.next();
    this.destroyed$$.complete();
  }

  getBusinessType(id: string): void {
    this.businessTypesService
      .businessType(id)
      .pipe(
        catchError(err => {
          // TODO show error notification
          return throwError(err);
        }),
        takeUntil(this.destroyed$$),
      )
      .subscribe(businessType => this.businessType$.next(businessType));
  }

  getBusinessTypes(): void {
    this.businessTypesForUnionService
      .businessTypesForUnion()
      .pipe(
        catchError(err => {
          // TODO show error notification
          return throwError(err);
        }),
        map(businessTypes => this.sortingBusinessTypes(businessTypes)),
        takeUntil(this.destroyed$$),
      )
      .subscribe(businessTypes => this.businessTypes$.next(businessTypes));
  }

  saveBusinessTypes(businessTypes: BusinessTypeUI[]): Observable<string[]> {
    const data = this.mapBusinessTypesUIToBusinessType(businessTypes);
    return this.businessTypesForUnionService.upsertBusinessTypeForUnion(data).pipe(
      catchError(err => {
        // TODO show error notification
        return throwError(err);
      }),
    );
  }

  getAllBusinessTypes(): void {
    forkJoin([this.businessTypesService.businessTypes(), this.businessTypesForUnionService.businessTypesForUnion()])
      .pipe(
        catchError(err => {
          // TODO show error notification
          return throwError(err);
        }),
        map(([allBusinessTypes, businessTypesForUnion]) =>
          this.mergeBusinessTypes(allBusinessTypes, businessTypesForUnion),
        ),
        map(businessTypes => this.sortingBusinessTypes(businessTypes)),
        takeUntil(this.destroyed$$),
      )
      .subscribe(allBusinessTypes => this.allBusinessTypes$.next(allBusinessTypes));
  }

  resetBusinessType(): void {
    this.businessType$.next({});
  }

  resetBusinessTypes(): void {
    this.businessTypes$.next([{}]);
  }

  resetAll(): void {
    this.resetBusinessType();
    this.resetBusinessTypes();
  }

  private sortingBusinessTypes(businessTypes: BusinessTypeUI[]): BusinessTypeUI[] {
    businessTypes.sort((businessType1, businessType2) => {
      if (!businessType1.isVisible && businessType2.isVisible) return 1;
      if (businessType1.isVisible && !businessType2.isVisible) return -1;

      const businessTypeSortOrder1 = businessType1.sortOrder;
      const businessTypeSortOrder2 = businessType2.sortOrder;

      if (businessTypeSortOrder1 === undefined) return 1;
      if (businessTypeSortOrder2 === undefined) return -1;

      if (businessTypeSortOrder1 < businessTypeSortOrder2) return -1;
      if (businessTypeSortOrder1 > businessTypeSortOrder2) return 1;
      return 0;
    });

    return businessTypes;
  }

  private mergeBusinessTypes(
    allBusinessTypes: BusinessTypeUI[],
    businessTypesForUnion: BusinessTypeForUnionView[],
  ): BusinessTypeUI[] {
    return allBusinessTypes.map(businessType => {
      const findBusinessTypeForUnion = businessTypesForUnion.find(
        businessTypeForUnion => businessTypeForUnion.businessTypeId === businessType.id,
      );
      if (findBusinessTypeForUnion) {
        businessType.isVisible = true;
        businessType.sortOrder = findBusinessTypeForUnion.sortOrder;
      } else {
        businessType.sortOrder = undefined;
      }

      return businessType;
    });
  }

  private mapBusinessTypesUIToBusinessType(businessTypes: BusinessTypeUI[]): BusinessTypeForUnionAdd[] {
    return businessTypes.map(businessType => {
      businessType.businessTypeId = businessType.id;

      delete businessType.id;
      delete businessType.isVisible;

      return businessType;
    });
  }
}
