import { TextEntityMainTypeUnion, TextEntityTypeDefault } from './types';

export const PLAIN_TEXT_TYPE: TextEntityTypeDefault = { _: 'textEntityPlainText' };

/**
 * Список основных типов трансформации, которые мы умеем обрабатывать
 */
export const TEXT_ENTITY_MAIN_TYPES: TextEntityMainTypeUnion[] = [
  'textEntityTypeBotCommand',
  'textEntityTypeUrl',
  'textEntityTypeTextUrl',
  'textEntityTypeEmailAddress',
  'textEntityTypePhoneNumber',
  'textEntityTypeCode',
  'textEntityTypePre',
  'textEntityTypeMention',
  'textEntityTypeMentionName',
];
