import { Component, ChangeDetectionStrategy, Inject } from '@angular/core';
import { TuiDialogContext } from '@taiga-ui/core';
import { POLYMORPHEUS_CONTEXT } from '@tinkoff/ng-polymorpheus';

import { InputPhotoPreview } from '../types';

@Component({
  selector: 'app-photo-preview',
  templateUrl: './photo-preview.component.html',
  styleUrls: ['./photo-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PhotoPreviewComponent {
  constructor(@Inject(POLYMORPHEUS_CONTEXT) private readonly context: TuiDialogContext<void, InputPhotoPreview>) {}

  get photoId() {
    return this.context.data.photoId;
  }

  get photoSrc() {
    return this.context.data?.photoSrc;
  }

  get unionId() {
    return this.context.data?.unionId;
  }
}
