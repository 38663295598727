import { Component, ChangeDetectionStrategy } from '@angular/core';

import { VERSION_LIST } from './constants';
import { VersionList } from './types';

@Component({
  selector: 'app-changelog-page',
  templateUrl: './changelog-page.component.html',
  styleUrls: ['./changelog-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChangelogPageComponent {
  versionList: VersionList[] = VERSION_LIST;
}
