<form class="import-organisations" [formGroup]="form">
  <div class="import-organisations__title">{{ 'components.importOrganisations.labels.title' | translate }}</div>
  <div class="import-organisations__link">
    <app-download-link [fileLink]="'assets/docs/Шаблон для импорта организаций.xlsx'">
      {{ 'components.importOrganisations.buttons.downloadTemplate' | translate }}
    </app-download-link>
  </div>
  <div class="import-organisations__form">
    <div [class.tui-skeleton]="loading" [class.tui-skeleton_text]="loading">
      <tui-input-files
        *ngIf="!fileControl?.value; else fileTmp"
        tabindex="1"
        formControlName="file"
        [tuiTextfieldSize]="tuiElementSize"
      >
        <input
          tuiInputFiles
          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
        />
      </tui-input-files>

      <ng-template #fileTmp>
        <tui-files>
          <tui-file *ngIf="fileControl?.value as file" [file]="file" (removed)="removeFile()"></tui-file>
        </tui-files>
      </ng-template>
    </div>
  </div>
  <div class="import__buttons" [class.tui-skeleton]="loading" [class.tui-skeleton_text]="loading">
    <button tuiButton [size]="tuiElementSize" (click)="onClickSaveButton()">
      {{ 'components.importOrganisations.buttons.upload' | translate }}
    </button>
  </div>
</form>
