<div class="message-formatted-text__wrapper">
  <ng-container *ngFor="let parsedText of parsedFormattedTexts">
    <ng-container [ngSwitch]="parsedText.mainType?._">
      <app-link
        *ngSwitchCase="'textEntityTypeBotCommand'"
        [class]="parsedText.joinedTypes"
        [displayText]="parsedText.text"
        (click)="onClickCommand(parsedText.text)"
      ></app-link>

      <ng-container *ngSwitchCase="'textEntityTypeUrl'" [ngTemplateOutlet]="linkTemplate"></ng-container>
      <ng-container *ngSwitchCase="'textEntityTypeTextUrl'" [ngTemplateOutlet]="linkTemplate"></ng-container>

      <ng-template #linkTemplate>
        <app-link
          [class]="parsedText.joinedTypes"
          [displayText]="parsedText.text"
          [url]="getFormattedTextUrl(parsedText.mainType) || parsedText.text"
        ></app-link>
      </ng-template>

      <app-link
        *ngSwitchCase="'textEntityTypeEmailAddress'"
        [url]="parsedText.text ? 'mailto:' + parsedText.text : ''"
        [class]="parsedText.joinedTypes"
        [displayText]="parsedText.text"
      ></app-link>

      <app-link
        *ngSwitchCase="'textEntityTypePhoneNumber'"
        [url]="parsedText.text ? 'tel:' + parsedText.text : ''"
        [class]="parsedText.joinedTypes"
        [displayText]="parsedText.text"
      ></app-link>

      <code
        *ngSwitchCase="'textEntityTypeCode'"
        [class]="parsedText.joinedTypes"
        (click)="onClickCode(parsedText.text)"
      >
        {{ parsedText.text }}
      </code>

      <pre *ngSwitchCase="'textEntityTypePre'" [class]="parsedText.joinedTypes">{{ parsedText.text }}</pre>

      <ng-container *ngSwitchCase="'textEntityTypeMention'" [ngTemplateOutlet]="mentionTemplate"></ng-container>
      <ng-container *ngSwitchCase="'textEntityTypeMentionName'" [ngTemplateOutlet]="mentionTemplate"></ng-container>

      <ng-template #mentionTemplate>
        <app-link
          [class]="parsedText.joinedTypes"
          [displayText]="parsedText.text"
          (click)="onClickMention(parsedText.text, parsedText.mainType)"
        ></app-link>
      </ng-template>

      <span *ngSwitchDefault [class]="parsedText.joinedTypes">{{ parsedText.text }}</span>
    </ng-container>
  </ng-container>
</div>
