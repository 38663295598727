import {
  Component,
  ChangeDetectionStrategy,
  Inject,
  Injector,
  Input,
  OnChanges,
  ChangeDetectorRef,
  SimpleChanges,
  Output,
  EventEmitter,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, lastValueFrom, Subject, throwError } from 'rxjs';
import { catchError, takeUntil } from 'rxjs/operators';
import { TuiDialogService } from '@taiga-ui/core';
import { PolymorpheusComponent } from '@tinkoff/ng-polymorpheus';
import { DocumentsService, EditOrganisation, OPFType } from '@src/api';
import { BusinessTypesService, OpfTypeService, OrganisationService, PhotoService } from '@src/core/services';
import { BusinessTypeUI, OrganisationUpsert, ViewMode } from '@src/models';
import { DialogConfirmComponent } from '@src/app/shared/dialogs';
import { isFileContact } from '@src/utils';
import { TranslateService } from '@ngx-translate/core';

import { PermissionService } from '../services';

@Component({
  selector: 'app-organisation-info',
  templateUrl: './organisation-info.component.html',
  styleUrls: ['./organisation-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrganisationInfoComponent implements OnInit, OnChanges, OnDestroy {
  @Input() mode: ViewMode = 'view';
  @Input() organisationId?: string | null;
  @Input() parentOrganisationId?: string;
  @Output() saved: EventEmitter<string> = new EventEmitter<string>();
  @Output() canceled: EventEmitter<void> = new EventEmitter<void>();
  @Output() deleted: EventEmitter<void> = new EventEmitter<void>();

  data$ = this.organisationService.organisationInfo$;

  allowEditing$ = this.permissionService.allowEditing$;
  allowDeleting$ = this.permissionService.allowDeleting$;
  allowSpecialFieldsViewing$ = this.permissionService.allowSpecialFieldsViewing$;
  allowSpecialFieldsEditing$ = this.permissionService.allowSpecialFieldsEditing$;
  allowSpecialFieldsForAssociationViewing$ = this.permissionService.allowSpecialFieldsForAssociationViewing$;
  allowSpecialFieldsForAssociationEditing$ = this.permissionService.allowSpecialFieldsForAssociationEditing$;

  opfTypes$: BehaviorSubject<OPFType[]> = this.opfTypeService.opfTypes$;
  businessTypes$: BehaviorSubject<BusinessTypeUI[] | null> = this.businessTypesService.businessTypes$;

  loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  private destroyed$$: Subject<void> = new Subject<void>();
  private readonly confirmCancelEditingDialog = this.dialogService.open<boolean>(
    new PolymorpheusComponent(DialogConfirmComponent, this.injector),
    {
      label: this.translateService.instant('common.dialogs.undoEditHeader'),
      size: 's',
      closeable: false,
    },
  );
  private readonly confirmDeleteOrganisationDialog = this.dialogService.open<boolean>(
    new PolymorpheusComponent(DialogConfirmComponent, this.injector),
    {
      label: this.translateService.instant('common.dialogs.deleteHeader'),
      size: 's',
      closeable: false,
    },
  );

  constructor(
    private cdr: ChangeDetectorRef,
    private organisationService: OrganisationService,
    private photoService: PhotoService,
    private documentsService: DocumentsService,
    private opfTypeService: OpfTypeService,
    private businessTypesService: BusinessTypesService,
    private router: Router,
    private readonly translateService: TranslateService,
    private readonly permissionService: PermissionService,
    @Inject(TuiDialogService) private readonly dialogService: TuiDialogService,
    @Inject(Injector) private readonly injector: Injector,
  ) {}

  ngOnInit(): void {
    this.opfTypeService.getOPFTypes();
    this.businessTypesService.getBusinessTypes();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.organisationId) {
      if (!!this.organisationId) {
        this.mode = 'view';
        this.organisationService.loadOrganisationInfo(this.organisationId);
      }
    }

    if (changes.mode) {
      if (this.mode === 'create') {
        this.organisationService.resetOrganisation(this.parentOrganisationId);
        this.organisationService.resetOrganisationInfo(this.parentOrganisationId);
      }
    }

    this.cdr.markForCheck();
  }

  ngOnDestroy(): void {
    this.destroyed$$.next();
    this.destroyed$$.complete();
  }

  onStartEditing() {
    this.mode = 'edit';
  }

  async onSaveData(data: OrganisationUpsert): Promise<void> {
    this.loading$.next(true);
    let dataToSend = { ...data };
    if (this.parentOrganisationId) {
      dataToSend = { ...dataToSend, parentOrganisation: this.parentOrganisationId };
    }

    if (data.contacts) {
      const promises = data.contacts.map(async contact => {
        if (isFileContact(contact.contactTypeId)) {
          if (contact.oldDocument?.id) {
            contact.contact = contact.oldDocument.id;
          }

          if (contact.newDocument) {
            contact.contact = await this.uploadDocument(contact.newDocument, 10); // 10 Документ
          }

          contact.oldDocument = undefined;
          contact.newDocument = undefined;
        }
        return contact;
      });
      await Promise.all(promises);
    }

    if (this.mode === 'edit') {
      this.updateOrganisation(dataToSend);
    } else if (this.mode === 'create') {
      this.createOrganisation(dataToSend);
    }
  }

  onCancelEditing(): void {
    this.confirmCancelEditingDialog.pipe(takeUntil(this.destroyed$$)).subscribe({
      next: res => {
        if (res) {
          this.canceled.emit();
          if (this.organisationId) {
            this.organisationService.resetOrganisation();
            this.organisationService.resetOrganisationInfo();
            this.organisationService.loadOrganisation({ id: this.organisationId, loadObjectMenu: false });
            this.organisationService.loadOrganisationInfo(this.organisationId);
          }

          this.mode = 'view';
        }
      },
    });
  }

  onDeleteOrganisation(): void {
    this.confirmDeleteOrganisationDialog.pipe(takeUntil(this.destroyed$$)).subscribe({
      next: res => {
        if (res) {
          if (this.organisationId) {
            lastValueFrom(this.organisationService.deleteOrganisation(this.organisationId)).then(() => {
              this.router.navigate(['association-organisations']);
              this.deleted.emit();
            });
          }
        }
      },
    });
  }

  private createOrganisation(data: OrganisationUpsert): void {
    this.organisationService
      .createOrganisation(data)
      .pipe(
        catchError(err => {
          this.loading$.next(false);
          return throwError(() => err);
        }),
        takeUntil(this.destroyed$$),
      )
      .subscribe(async res => {
        const newOrganisationId = res.id;

        if (data.photo && newOrganisationId) {
          await this.uploadPhoto(data.photo, newOrganisationId, true);
        }

        if (data.card && newOrganisationId) {
          await this.uploadDocument(data.card, 11, newOrganisationId); // 11 Карточка компании
        }

        if (data.invoice && newOrganisationId) {
          await this.uploadDocument(data.invoice, 15, newOrganisationId); // 15 Счет
        }

        this.saved.emit(newOrganisationId);
        this.mode = 'view';
        this.loading$.next(false);
        this.cdr.markForCheck();
      });
  }

  private updateOrganisation(data: OrganisationUpsert): void {
    this.organisationService
      .editOrganisation(data as EditOrganisation)
      .pipe(
        catchError(err => {
          this.loading$.next(false);
          return throwError(() => err);
        }),
        takeUntil(this.destroyed$$),
      )
      .subscribe(async () => {
        if (data.id) {
          if (data.photo) {
            await this.uploadPhoto(data.photo, data.id, true);
          }

          if (data.card) {
            await this.uploadDocument(data.card, 11, data.id); // 11 Карточка компании
          }

          if (data.invoice) {
            await this.uploadDocument(data.invoice, 15, data.id); // 15 Счет
          }

          if (data.deleteCard) {
            await this.deleteDocument(data.deleteCard);
          }

          if (data.deleteInvoice) {
            await this.deleteDocument(data.deleteInvoice);
          }

          this.organisationService.loadOrganisation({ id: data.id, loadObjectMenu: false });
          this.organisationService.loadOrganisationInfo(data.id);
          if (!data.parentOrganisation) {
            this.organisationService.getParentOrganisation(true);
          }

          this.saved.emit(data.id);
        }

        this.mode = 'view';
        this.loading$.next(false);
        this.cdr.markForCheck();
      });
  }

  private async uploadPhoto(photo: File, attachId: string, isCover: boolean) {
    if (!photo) return;

    return lastValueFrom(this.photoService.uploadPhoto(photo, attachId, isCover));
  }

  private async uploadDocument(document: File, documentType?: number, attachId?: string): Promise<string | undefined> {
    if (!document) return;

    const documentList = await lastValueFrom(
      this.documentsService.addDocuments([document], attachId, undefined, undefined, documentType),
    );

    return documentList?.files?.[0].id;
  }

  private async deleteDocument(documentId: string) {
    return await lastValueFrom(this.documentsService.deleteDocument(documentId));
  }
}
