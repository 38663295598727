import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { AbstractControl, UntypedFormControl } from '@angular/forms';
import { ResizableBaseComponent } from '@src/app/components';
import { APP_CONFIG } from '@src/core';

@Component({
  selector: 'contacts-value-file',
  templateUrl: './contacts-value-file.component.html',
  styleUrls: ['./contacts-value-file.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContactsValueFileComponent extends ResizableBaseComponent {
  @Input() loading: boolean = false;

  readonly maxDocFileSize = APP_CONFIG.fileSizeMax.doc;

  getDocumentFormControl(contact: AbstractControl, document: string): UntypedFormControl {
    return contact.get(document) as UntypedFormControl;
  }

  resetDocumentFormControl(contact: AbstractControl, document: string) {
    (contact.get(document) as UntypedFormControl).reset();
    (contact.get('contact') as UntypedFormControl).reset();
  }
}
