<mat-list-option
  class="list-item list-item-users"
  class="list-item list-item-users"
  [value]="data?.id"
  [selected]="!loading && selectedId === data?.id"
>
  <div
    matListIcon
    class="list-item__image list-item__image_large"
    [class.tui-skeleton]="loading"
    [class.tui-skeleton_rounded]="loading"
  >
    <tui-avatar
      size="m"
      [autoColor]="!userAvatarUrl"
      [rounded]="true"
      [avatarUrl]="userAvatarUrl | safe : 'url'"
      [text]="data | userDisplayableName : 'short'"
    ></tui-avatar>
  </div>

  <div class="list-item__text-box list-item__text-box_both-padding">
    <h3
      class="list-item__title"
      [class.tui-skeleton]="loading"
      [class.tui-skeleton_text]="loading"
      [title]="data | userDisplayableName"
    >
      {{ data | userDisplayableName }}
    </h3>

    <p
      *ngFor="let organisationJobTitle of jobTitles"
      class="list-item__text list-item__text_grey"
      [class.tui-skeleton]="loading"
      [class.tui-skeleton_text]="loading"
      [title]="organisationJobTitle | orgJobTitleDisplayableName"
    >
      {{ organisationJobTitle | orgJobTitleDisplayableName }}
    </p>

    <div *ngIf="editDateNormalize" class="list-item__text list-item__text_grey">
      {{ editDateNormalize }}
    </div>

    <div
      *ngIf="data && data.decisionTypeName"
      class="list-item__status"
      [class.list-item__status_blue]="data.decisionTypeCode === 0 || data.decisionTypeCode === 1"
      [class.list-item__status_green]="data.decisionTypeCode === 2 || data.decisionTypeCode === 4"
      [class.list-item__status_red]="data.decisionTypeCode === 3"
      [title]="data.decisionTypeName"
    >
      {{ data.decisionTypeName }}
    </div>
  </div>
</mat-list-option>
