import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { TuiButtonModule } from '@taiga-ui/core';
import { SharedModule } from '@src/app/shared';
import { IconModule } from '@src/app/modules/icon';

import { MediaRecorderComponent } from './media-recorder.component';

@NgModule({
  declarations: [MediaRecorderComponent],
  imports: [CommonModule, IconModule, MatIconModule, MatButtonModule, SharedModule, TuiButtonModule],
  exports: [MediaRecorderComponent],
})
export class MediaRecorderModule {}
