import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { TuiSizeL, TuiSizeM, TuiSizeS } from '@taiga-ui/core';
import { ViewMode } from '@src/models';

@Component({
  selector: 'app-field-social-network',
  templateUrl: './field-social-network.component.html',
  styleUrls: ['./field-social-network.component.scss', '../styles/field-content.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FieldSocialNetworkComponent {
  @Input() mode?: ViewMode = 'view';
  @Input() name?: string;
  @Input() value?: string | null;
  @Input() size: TuiSizeS | TuiSizeM | TuiSizeL = 'l';
}
