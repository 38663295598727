/**
 * Unions API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Показать роль участников мероприятия.
 */
export interface ParticipantRolesForUnionView { 
    /**
     * Название роли.
     */
    name: string;
    /**
     * ID ассоциации.
     */
    parentOrganisationId?: string;
    /**
     * Сортировка.
     */
    sortOrder?: number;
    /**
     * Идентификатор опроса.
     */
    id?: string;
}

