import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TelegramMessengerService } from '@src/app/modules/telegram';

import { SubscriptionsForUsersService } from './subscriptions-for-users.service';
import { ChatsService } from './chats.service';
import { SubscriptionsForUsersCommitteeService } from './subscriptions-for-users-committee.service';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root',
})
export class BadgeService implements OnDestroy {
  chats$: BehaviorSubject<number | null> = new BehaviorSubject<number | null>(null);
  events$: BehaviorSubject<number | null> = new BehaviorSubject<number | null>(null);
  polls$: BehaviorSubject<number | null> = new BehaviorSubject<number | null>(null);
  channels$: BehaviorSubject<number | null> = new BehaviorSubject<number | null>(null);
  chatsIds: number[] = [];
  channelsIds: number[] = [];
  unreadChatsIds: number[] = [];

  private destroyed$$: Subject<void> = new Subject<void>();
  private organisationsSubChatsIds$: BehaviorSubject<number[]> =
    this.subscriptionsForUsersService.subscriptionsChatIds$;
  private committeesSubChatsIds$: BehaviorSubject<number[]> =
    this.subscriptionsForUsersCommitteeService.subscriptionsChatIds$;
  private unionsChatsIds$: BehaviorSubject<number[]> = this.chatsService.chatsIds$;
  private unionsUsersTelegramIds$: BehaviorSubject<number[]> = this.userService.usersTelegramIds$;
  private subChats?: Subscription;
  private subUsers?: Subscription;

  constructor(
    private messengerService: TelegramMessengerService,
    private subscriptionsForUsersService: SubscriptionsForUsersService,
    private subscriptionsForUsersCommitteeService: SubscriptionsForUsersCommitteeService,
    private chatsService: ChatsService,
    private userService: UserService,
  ) {
    this.messengerService.allChatsIds$.pipe(takeUntil(this.destroyed$$)).subscribe(allChatIds => {
      if (!allChatIds.length) return;

      // TODO: refactoring. hack for first loading
      this.subChats = this.unionsChatsIds$.pipe(takeUntil(this.destroyed$$)).subscribe(() => {
        this.setBadgeChats();
        this.subChats?.unsubscribe();
      });
      this.subUsers = this.unionsUsersTelegramIds$.pipe(takeUntil(this.destroyed$$)).subscribe(() => {
        this.setBadgeChats();
        this.subUsers?.unsubscribe();
      });
      // TODO END

      this.chatsService.getChats(allChatIds);
      this.userService.getUsersTelegramIds(allChatIds);
    });

    this.subscriptionsForUsersService.subscriptionsChatIds();
    this.subscriptionsForUsersCommitteeService.subscriptionsChatIds();

    this.messengerService.updates$.pipe(takeUntil(this.destroyed$$)).subscribe(update => {
      switch (update._) {
        case 'updateChatReadInbox':
          this.setBadge(update.chatId, update.unreadCount);
          break;

        default:
          break;
      }
    });
  }

  ngOnDestroy(): void {
    this.destroyed$$.next();
    this.destroyed$$.complete();
  }

  private setBadge(chatId: number, count: number): void {
    if (!this.unreadChatsIds.includes(chatId) && count) {
      this.unreadChatsIds.push(chatId);
    }
    if (this.unreadChatsIds.includes(chatId) && !count) {
      this.unreadChatsIds.splice(
        this.unreadChatsIds.findIndex(id => id === chatId),
        1,
      );
    }

    if (this.organisationsSubChatsIds$.value.includes(chatId) || this.committeesSubChatsIds$.value.includes(chatId)) {
      const unreadCount = this.getUnreadCount(this.organisationsSubChatsIds$.value, this.committeesSubChatsIds$.value);
      this.channels$.next(unreadCount);
    } else if (this.unionsChatsIds$.value.includes(chatId) || this.unionsUsersTelegramIds$.value.includes(chatId)) {
      this.setBadgeChats();
    }
  }

  private getUnreadCount(ids1: number[], ids2: number[] = []): number {
    const ids = [...new Set(ids1.concat(ids2))];
    return ids.filter(id => this.unreadChatsIds.includes(id)).length;
  }

  private setBadgeChats() {
    const unreadCount = this.getUnreadCount(this.unionsChatsIds$.value, this.unionsUsersTelegramIds$.value);
    this.chats$.next(unreadCount);
  }
}
