import { OrganisationSearchParameters, SearchCommitteesRequestParameters, UsersSearchParameters } from '@src/api';

/**
 * Утилита, которая возвращает фильтр с заполненными дополнительными полями
 * @param filter
 */
export const getFilter = (
  filter: OrganisationSearchParameters | UsersSearchParameters | SearchCommitteesRequestParameters,
): OrganisationSearchParameters | UsersSearchParameters | SearchCommitteesRequestParameters => {
  const _filter = { ...filter };
  if (!!_filter.contacts) {
    _filter.contacts = _filter.contacts.filter(contact => !!contact.value);
  }
  return _filter;
};

/**
 * TODO: костыль для быстрого поиска. необходимо реализовать нормальный поиск #9823
 * если не заполнено поле name, то заполнить userContact | organizationContact = undefined
 * если заполнено только name, то заполнить userContact | organizationContact значением из name
 * если заполнено еще какое-то поле, то заполнить userContact | organizationContact = undefined
 */
export const getUserOrOrganisationContactValue = (filter: OrganisationSearchParameters | UsersSearchParameters) => {
  const name = filter.name?.trim();
  if (!name) {
    return undefined;
  } else {
    return Object.values(filter).filter(value => !!value && !!value.length).length === 1 ? name : undefined;
  }
};
