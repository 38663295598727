<div
  telegram-check-auth
  [infoText]="'components.telegramNeewsFeed.labels.telegramNeeded' | translate"
  class="news-feed-page"
  (onLogged)="onLogged()"
>
  <div class="news-feed-page__header">
    <button
      *ngIf="!showFullPage && selectedChatId"
      tuiIconButton
      class="news-feed-page__return-button"
      type="button"
      appearance="icon"
      icon="tuiIconArrowLeftLarge"
      size="s"
      [title]="'common.buttons.return' | translate"
      (click)="onClickReturnButton()"
    ></button>
    <ng-container *ngIf="showFullPage">
      <h1 class="news-feed-page__title">{{ 'menu.newsFeed' | customNames }}</h1>
    </ng-container>
  </div>
  <ng-container *ngIf="!env.isBot; else tmpForBot">
    <div [hide]="!showFullPage" class="news-feed-page__list">
      <app-list
        listType="chats"
        [items]="chats"
        [selectedId]="selectedChatId"
        (selectedIdChange)="onSelectChatChange($event)"
        [loading]="loading"
      ></app-list>
    </div>
    <div *ngIf="forwardToChatListVisible || selectedChat" class="news-feed-page__content">
      <telegram-chat
        *ngIf="selectedChat && !forwardToChatListVisible"
        [chat]="selectedChat"
        (forward)="openForwardToChatDialog($event)"
      ></telegram-chat>
      <telegram-forward
        *ngIf="forwardToChatListVisible"
        (chatSelected)="forwardToChat($event.selectedChat, $event.message)"
        (canceled)="onClickReturnToChatButton()"
      ></telegram-forward>
    </div>
  </ng-container>

  <ng-template #tmpForBot>
    <div [hide]="!showFullPage" class="news-feed-page__list">
      <app-list
        listType="channels"
        [items]="groups"
        [selectedId]="selectedGroupId"
        (selectedIdChange)="onSelectGroupChange($event)"
      ></app-list>
    </div>
  </ng-template>
</div>
