/**
 * Unions API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Модель для оображения в списке отчетов
 */
export interface GetReportForUnionResultDto { 
    /**
     * id отчета
     */
    id?: string;
    /**
     * краткое наименование отчета
     */
    title?: string;
    /**
     * описание отчета
     */
    description?: string;
    /**
     * строка с данными, для которых фронт нарисует какие-то контролы для ввода параметров  строка в виде JSON, например: { \"dateFrom\": \"dateTo\" }
     */
    reportInputData?: string;
}

