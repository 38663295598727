<div class="organisation-channels">
  <div class="organisation-channels__content content">
    <div class="content__header">
      <button
        *ngIf="!showFullPage && (selectedGroup || addGroupsListVisible)"
        tuiIconButton
        class="content__return-button"
        type="button"
        appearance="icon"
        icon="tuiIconArrowLeftLarge"
        size="s"
        [title]="'common.buttons.return' | translate"
        (click)="onClickReturnButton()"
      ></button>
      <ng-container *ngIf="showFullPage">
        <h1 class="content__title">{{ title }}</h1>
        <tui-hosted-dropdown
          *ngIf="!env.isBot && (allowEditing$ | async)"
          tuiDropdownAlign="left"
          [content]="createMenuTemplate"
          [(open)]="openMenu"
        >
          <button
            tuiIconButton
            class="button button_add"
            type="button"
            appearance="icon"
            icon="tuiIconPlusCircleLarge"
            size="s"
            [title]="'common.buttons.add' | translate"
          ></button>
        </tui-hosted-dropdown>
      </ng-container>
    </div>
    <div [hide]="!showFullPage" class="content__list">
      <app-list
        listType="channels"
        [items]="groups"
        [selectedId]="selectedId"
        [itemActions]="groupActions"
        (selectedIdChange)="onChangeSelectedGroupId($event)"
        [loading]="loading"
      ></app-list>
    </div>
    <div class="content__content">
      <ng-container *ngIf="(groupInfoMode === 'create' || selectedGroup) && !addGroupsListVisible">
        <app-group-info
          type="organisation"
          [mode]="groupInfoMode"
          [data]="selectedGroup"
          [isChannel]="isChannel"
          [organisationId]="organisationId"
          [allowSubscribing]="allowSubscribing$ | async"
          [allowSpecialFieldsViewing]="allowSpecialFieldsViewing$ | async"
          (canceled)="onCancelEditingGroup()"
          (saved)="onSaveGroup($event)"
          (deleted)="onDeletingGroup()"
        ></app-group-info>
      </ng-container>
      <app-search-groups
        *ngIf="addGroupsListVisible && !selectedGroup"
        [isChannel]="isChannel"
        [excludeIds]="groupsIds"
        (groupSelected)="attachGroup($event)"
      ></app-search-groups>
    </div>
  </div>
</div>

<ng-template #createMenuTemplate>
  <mat-nav-list class="menu-list">
    <mat-list-item *ngFor="let item of createMenuItems" class="menu-list__item" (click)="onClickCreateMenuItem(item)">
      <mat-icon *ngIf="item.icon" matListIcon class="menu-list__icon">{{ item.icon }}</mat-icon>
      <div matLine class="menu-list__title">{{ item.title }}</div>
    </mat-list-item>
  </mat-nav-list>
</ng-template>
