<div class="page">
  <div [hide]="!showFullPage" class="page__current-block">
    <app-birthdays-list [(selectedId)]="selectedId"></app-birthdays-list>
  </div>

  <div *ngIf="!!selectedId" class="page__next-block">
    <div class="header">
      <!-- TODO: перенести app-header в app-user-info -->
      <app-header [options]="headerOptions"></app-header>
    </div>
    <tui-scrollbar class="content-wrapper">
      <div class="content">
        <app-user-info [userId]="selectedId"></app-user-info>
      </div>
    </tui-scrollbar>
  </div>
</div>
