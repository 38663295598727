import { AbstractControl, ValidatorFn } from '@angular/forms';
import { TuiValidationError } from '@taiga-ui/cdk';

// TODO: localization перевести
export const maxFilesLength = (maxLength: number): ValidatorFn => {
  return ({ value }: AbstractControl) => {
    return value?.length > maxLength
      ? {
        maxLength: new TuiValidationError(`Допустимое кол-во файлов ${maxLength}`),
      }
      : null;
  };
};
