import { marker } from '@biesbjerg/ngx-translate-extract-marker';

export const ANSWER_TYPES = [
  { id: 0, type: 'text', caption: marker('common.constants.answerTypes.text'), icon: '' },
  { id: 1, type: 'area', caption: marker('common.constants.answerTypes.area'), icon: '' },
  { id: 2, type: 'radio', caption: marker('common.constants.answerTypes.radio'), icon: '' },
  { id: 3, type: 'check', caption: marker('common.constants.answerTypes.check'), icon: '' },
  // { id: 4, type: 'slider', caption: 'Шкала', icon: '' },
  { id: 5, type: 'date', caption: marker('common.constants.answerTypes.date'), icon: '' },
  { id: 6, type: 'file', caption: marker('common.constants.answerTypes.file'), icon: '' },
  // { id: 7, type: 'select', caption: 'Раскрывающийся список', icon: '' },
];
