<div class="group">
  <div class="block block_first">
    <div class="group__photo">
      <tui-avatar
        [autoColor]="!chat?.userPhotoPath"
        [rounded]="true"
        size="l"
        [avatarUrl]="chat?.userPhotoPath || '' | safe : 'url'"
        [text]="chat && chat.title ? chat.title : ''"
      ></tui-avatar>
    </div>
    <div class="group__name">{{ chat?.title }}</div>
    <div *ngIf="superGroup" class="group__status">
      {{ superGroup.memberCount | numeralPlurals : ['участник', 'участника', 'участников'] }}
    </div>
    <div *ngIf="basicGroup" class="group__status">
      {{ basicGroup.members.length | numeralPlurals : ['участник', 'участника', 'участников'] }}
    </div>
  </div>
  <div *ngIf="organisation" class="block block_info">
    <mat-nav-list class="info__organisation">
      <mat-list-item [routerLink]="'/organisation/' + organisation.organisationId">
        <mat-icon matListIcon class="icon">business</mat-icon>
        <div matLine class="organisation__name">{{ organisation.organisationName }}</div>
        <div matLine class="label">{{ 'common.labels.organisation' | translate }}</div>
      </mat-list-item>
    </mat-nav-list>
  </div>
  <div class="block block_info">
    <mat-nav-list class="info__notifications">
      <mat-list-item (click)="onChangeNotificationsToggleValue()">
        <mat-slide-toggle
          matListIcon
          color="primary"
          [checked]="!!chat && chat.notificationSettings.muteFor === 0"
          [disabled]="true"
        ></mat-slide-toggle>
        <div matLine>{{ 'common.labels.notifications' | translate }}</div>
      </mat-list-item>
    </mat-nav-list>
  </div>
  <div *ngIf="members?.length" class="block block_info">
    <app-users-list
      [isChannel]="isChannel"
      [memberCount]="memberCount"
      [chatId]="chat?.id"
      [members]="members"
      (changeMembers)="getMembers()"
      (selectedUserIdChange)="onUserIdChange($event)"
    ></app-users-list>
  </div>
</div>
