import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { TuiButtonModule, TuiGroupModule, TuiHintModule } from '@taiga-ui/core';
import { TuiAccordionModule, TuiTabsModule } from '@taiga-ui/kit';
import { TranslateModule } from '@ngx-translate/core';
import { ComponentsModule } from '@src/app/components';

import { HintModule } from '../../modules/hint/hint.module';

import { SelectItemsComponent } from './select-items.component';

@NgModule({
  declarations: [SelectItemsComponent],
  imports: [
    CommonModule,
    DragDropModule,
    FormsModule,
    ReactiveFormsModule,
    TuiAccordionModule,
    TuiButtonModule,
    TuiGroupModule,
    TuiHintModule,
    TuiTabsModule,
    HintModule,
    TranslateModule,
    ComponentsModule,
  ],
  exports: [SelectItemsComponent],
})
export class SelectItemsModule {}
