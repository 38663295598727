import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TuiButtonModule } from '@taiga-ui/core';
import { TuiCarouselModule, TuiMarkerIconModule, TuiPaginationModule } from '@taiga-ui/kit';
import { TestingModule } from '@src/app/modules/testing/testing.module';

import { SharedModule } from '../shared.module';

import { DialogConfirmComponent } from './dialog-confirm/dialog-confirm.component';
import { DialogImageViewingComponent } from './dialog-image-viewing/dialog-image-viewing.component';
import { UpdateAppDialogComponent } from './update-app-dialog/update-app-dialog.component';
import { OfflineDialogComponent } from './offline-dialog/offline-dialog.component';
import { AuthDialogComponent } from './auth-dialog/auth-dialog.component';
import { DialogConfirmCommonComponent } from './dialog-confirm-common/dialog-confirm-common.component';
import { ManyTabsDialogComponent } from './many-tabs-dialog/many-tabs-dialog.component';

@NgModule({
  declarations: [
    DialogConfirmComponent,
    DialogImageViewingComponent,
    UpdateAppDialogComponent,
    OfflineDialogComponent,
    AuthDialogComponent,
    DialogConfirmCommonComponent,
    ManyTabsDialogComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    TuiButtonModule,
    TuiCarouselModule,
    TuiPaginationModule,
    TuiMarkerIconModule,
    TestingModule,
  ],
  exports: [
    DialogConfirmComponent,
    DialogConfirmCommonComponent,
    DialogImageViewingComponent,
    UpdateAppDialogComponent,
    OfflineDialogComponent,
    AuthDialogComponent,
    ManyTabsDialogComponent,
  ],
})
export class DialogsModule {}
