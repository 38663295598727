import { Component, ChangeDetectionStrategy, ChangeDetectorRef, EventEmitter, Output, OnDestroy } from '@angular/core';
import { transition, trigger } from '@angular/animations';
import { from, Observable, of, Subject } from 'rxjs';
import { catchError, takeUntil } from 'rxjs/operators';
import { EnvService } from '@src/app/modules/env';
import { AlertService } from '@src/core/services';
import { TRANSLATE_TOP } from '@src/constants/animation.const';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'telegram-media-recorder',
  templateUrl: './media-recorder.component.html',
  styleUrls: ['./media-recorder.component.scss'],
  animations: [
    trigger('appearWrapper', [transition(':enter', [])]),
    trigger('appear', [transition(':enter', TRANSLATE_TOP)]),
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MediaRecorderComponent implements OnDestroy {
  @Output() recordingSent: EventEmitter<Blob>;

  @Output() cancelRecording: EventEmitter<void> = new EventEmitter();

  isRecordingAvailable?: boolean;
  isVoiceRecording: boolean = false;

  private recordedChunks: Blob[] | undefined = [];
  private mediaRecorder?: MediaRecorder;

  private destroyed$$: Subject<void>;

  constructor(
    private cdr: ChangeDetectorRef,
    private env: EnvService,
    private readonly alertService: AlertService,
    private readonly translateService: TranslateService,
  ) {
    this.recordingSent = new EventEmitter<Blob>();
    this.destroyed$$ = new Subject<void>();
  }

  ngOnDestroy(): void {
    this.destroyed$$.next();
    this.destroyed$$.complete();
  }

  async startRecording(): Promise<void> {
    if (!(await this.env.isAllowedRecordAudio())) {
      this.alertService.success(
        this.translateService.instant('components.mediaRecorder.alerts.successes.allowRecordAudio'),
        false,
      );

      return;
    }

    this.isVoiceRecording = true;

    if (this.isRecordingAvailable === undefined) {
      this.subscribeToAudioStream();
      return;
    }

    if (!this.isRecordingAvailable) {
      this.alertService.success(
        this.translateService.instant('components.mediaRecorder.alerts.successes.recordingUnavailable'),
        false,
      );
      return;
    }

    this.mediaRecorder?.start();
    this.cdr.detectChanges();
  }

  stopRecording(): void {
    this.isVoiceRecording = false;
    this.mediaRecorder?.stop();
    this.cdr.detectChanges();

    this.cancelRecording.emit();
  }

  emitRecord(): void {
    this.stopRecording();

    // Need to wait ondataavailable handler =>
    // use setTimeout to make code asynchronous and executable after ondataavailable
    setTimeout(() => {
      if (this.recordedChunks?.length) {
        this.recordingSent.emit(new Blob(this.recordedChunks));
      }
      this.recordedChunks = [];
      this.cdr.markForCheck();
    }, 0);
  }

  private handleRecord(event: BlobEvent): void {
    if (event.data?.size > 0) {
      this.recordedChunks?.push(event.data);
    }
  }

  private subscribeToAudioStream(): void {
    this.getUserAudioStream()
      .pipe(takeUntil(this.destroyed$$))
      .subscribe(stream => {
        if (stream) {
          this.isRecordingAvailable = true;
          this.mediaRecorder = new MediaRecorder(stream);
          this.mediaRecorder.ondataavailable = this.handleRecord.bind(this);
          if (this.isVoiceRecording) {
            this.startRecording();
          }
        } else {
          this.isRecordingAvailable = false;
          this.isVoiceRecording = false;
        }
        this.cdr.markForCheck();
      });
  }

  private getUserAudioStream(): Observable<MediaStream | undefined> {
    if (!navigator.mediaDevices) {
      this.alertService.warning(
        this.translateService.instant('components.mediaRecorder.alerts.warnings.noMediaDevices'),
        false,
      );
      return of(undefined);
    }

    return from(navigator.mediaDevices.getUserMedia({ audio: true })).pipe(
      catchError(_ => {
        // TODO show error for user (detect error reason)
        this.alertService.warning(
          this.translateService.instant('components.mediaRecorder.alerts.warnings.noAccessToMediaDevices'),
          false,
        );
        return of(undefined);
      }),
    );
  }
}
