import {
  Component,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
  ChangeDetectorRef,
} from '@angular/core';
import { Clipboard } from '@angular/cdk/clipboard';
import { FormattedText } from '@airgram/core/types/outputs';
import { AlertService } from '@src/core/services';
import { TranslateService } from '@ngx-translate/core';

import { TextFormatter } from './utils/text-formatter';
import { ParsedFormattedText, TextEntityTypeWithDefaultUnion } from './types';

/**
 * Компонент, который занимается форматированием сообщения полученного от Telegram.
 * Здесь мы добавляем новое поведение, например, переход на профиль пользователя, когда
 * нажали на его линк в тексте и т.д.
 */
@Component({
  selector: 'app-message-formatted-text',
  templateUrl: './message-formatted-text.component.html',
  styleUrls: ['./message-formatted-text.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MessageFormattedTextComponent implements OnChanges {
  @Input() formattedText?: FormattedText;

  /**
   * Обработка текста команды
   */
  @Output() sendCommandText: EventEmitter<string> = new EventEmitter();

  /**
   * Нажатие на логин пользователя в сообщении
   */
  @Output() clickUsername: EventEmitter<string> = new EventEmitter();

  /**
   * Нажатие на имя пользователя, у которого нет {username}, в этом случае передаем его {userId}
   */
  @Output() clickUserId: EventEmitter<number> = new EventEmitter();

  parsedFormattedTexts?: ParsedFormattedText[];

  constructor(
    private cdr: ChangeDetectorRef,
    private clipboard: Clipboard,
    private readonly alertService: AlertService,
    private readonly translateService: TranslateService,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.parsedFormattedTexts = TextFormatter.parse(this.formattedText);

    if (changes.formattedText.currentValue) {
      this.cdr.markForCheck();
    }
  }

  /**
   * Нажатие на команду
   * @param commandText текст команды
   */
  onClickCommand(commandText: string): void {
    this.sendCommandText.emit(commandText);
  }

  /**
   * Нажатие на блок кода, который обернут в <code> тэг
   * @param codeText текст
   */
  onClickCode(codeText: string): void {
    if (this.clipboard.copy(codeText)) {
      this.alertService.success(
        this.translateService.instant('components.messageFormattedText.alerts.successes.copyText'),
      );
    }
  }

  /**
   * Нажатие на имя пользователя в сообщении
   * @param text текст, по которому произвели нажатие
   * @param type тип трансформации текста
   */
  onClickMention(text: string, type?: TextEntityTypeWithDefaultUnion): void {
    if (type?._ === 'textEntityTypeMentionName') {
      this.clickUserId.emit(type.userId);
    } else {
      this.clickUsername.emit(text);
    }
  }

  /**
   * Получение ссылки в зависимости от типа трансформации текста
   * @param entity тип данных
   */
  getFormattedTextUrl(entity?: TextEntityTypeWithDefaultUnion): string | null {
    return entity?._ === 'textEntityTypeTextUrl' ? entity.url : null;
  }
}
