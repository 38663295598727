import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AbstractTuiControl } from '@taiga-ui/cdk';
import { tuiCreateTimePeriods } from '@taiga-ui/kit';
import { AnswerOptionWithAlternatesFormType, QuestionFormType } from '@src/models';
import { Nullable, Optional } from '@src/types/utils';

@Component({
  selector: 'question',
  templateUrl: './question.component.html',
  styleUrls: ['./question.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QuestionComponent extends AbstractTuiControl<Optional<Nullable<QuestionFormType>>> {
  readonly timeItems = tuiCreateTimePeriods(0, 24, [0, 15, 30, 45]);

  get focused(): boolean {
    return false;
  }

  protected getFallbackValue(): Optional<Nullable<QuestionFormType>> {
    return null;
  }

  resetFile() {
    this.value = {
      ...this.value,
      ...{ file: undefined },
    };
  }

  isAlternativeSelected(value?: any) {
    // TODO: заменить any на Array<AnswerOptionWithAlternatesFormType>
    return (value as Optional<Array<AnswerOptionWithAlternatesFormType>>)?.find(answer => answer.isAlternative);
  }
}
