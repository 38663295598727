import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

import { BadgeColor, BadgeColors, BadgeSize } from './types';

/**
 * Компонент для отображения бейджа
 * @param {string} value - Отображаемое значение
 * @param color {@link BadgeColor} - Цвет фона
 * @param size {@link BadgeSize} - Размер бейджа
 *
 * @see https://www.figma.com/file/tdtoaaGrQa3XOG9Qqv96VI/Unions-(Taiga-3.0)?type=design&node-id=2563-133078&mode=dev
 */
@Component({
  selector: 'app-badge',
  templateUrl: './badge.component.html',
  styleUrls: ['./badge.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BadgeComponent {
  @Input() value: string = '';
  @Input() color: BadgeColor = BadgeColors.Green;
  @Input() size: BadgeSize = 's';
}
