import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { StorageKeys } from '@src/constants/storage';

import { StorageService } from '../../auth';
import { ThemeBrightnessEnum } from '../types';

@Injectable({
  providedIn: 'root',
})
export class ThemeSwitcherService {
  isDark$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(private readonly storageService: StorageService) {
    const isDark = storageService.getItem(StorageKeys.Theme) === ThemeBrightnessEnum.Dark;
    this.isDark$.next(isDark);
  }

  setTheme(isDark: boolean) {
    this.isDark$.next(isDark);
    this.storageService.setItem(StorageKeys.Theme, isDark ? ThemeBrightnessEnum.Dark : ThemeBrightnessEnum.Light);
  }

  getTheme() {
    return this.storageService.getItem(StorageKeys.Theme);
  }
}
