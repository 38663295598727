import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { TuiSizeL, TuiSizeM, TuiSizeS } from '@taiga-ui/core';
import { from, Observable } from 'rxjs';
import { OrganisationView } from '@src/api';
import { ViewMode } from '@src/models';
import { OrganisationService } from '@src/core/services';

@Component({
  selector: 'app-field-organisation',
  templateUrl: './field-organisation.component.html',
  styleUrls: ['./field-organisation.component.scss', '../styles/field-content.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FieldOrganisationComponent {
  @Input() mode?: ViewMode = 'view';
  @Input() name?: string;
  @Input() set organisationId(value: string | undefined | null) {
    this.organisation$ = undefined;
    if (value) {
      this.organisation$ = from(this.organisationService.getOrganisationData(value));
    }
  }
  @Input() size: TuiSizeS | TuiSizeM | TuiSizeL = 'l';

  organisation$?: Observable<OrganisationView>;

  constructor(private organisationService: OrganisationService) {}
}
