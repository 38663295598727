import { Component, Input, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { APP_CONFIG } from '@src/core';

/**
 * Конфигурация для мобильных платформ
 */
const MOBILE_CONFIGS = APP_CONFIG.mobile;

@Component({
  selector: 'app-mobile-app-download-links',
  templateUrl: './mobile-app-download-links.component.html',
  styleUrls: ['./mobile-app-download-links.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MobileAppDownloadLinksComponent implements OnInit {
  /**
   * Ссылка для скачивания из App Store
   */
  @Input() appStoreLink?: string = MOBILE_CONFIGS.ios.downloadLink;

  /**
   * Ссылка для скачивания из Google Play
   */
  @Input() googlePlayLink?: string = MOBILE_CONFIGS.android.downloadLink;

  constructor() {}

  ngOnInit(): void {}
}
