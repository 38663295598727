import { Component } from '@angular/core';
import { INFO_NAVIGATION } from '@src/app/modules/navigation';

@Component({
  selector: 'app-information',
  templateUrl: './information.component.html',
  styleUrls: ['./information.component.scss'],
})
export class InformationComponent {
  readonly navTree = INFO_NAVIGATION;
}
