/**
 * Unions API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { RegistrationFormRequestContactDto } from './registrationFormRequestContactDto';


export interface RegistrationFormRequestDto { 
    parentOrganisationId?: string;
    firstName: string;
    lastName: string;
    middleName?: string;
    email?: string;
    phone?: string;
    targetOrganisationId?: string;
    /**
     * Массив доп полей пользователя
     */
    contacts?: Array<RegistrationFormRequestContactDto>;
    /**
     * Массив идентификаторов комитетов, куда кандидат желает вступить
     */
    committeeIds?: Array<string>;
}

