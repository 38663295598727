/**
 * Unions API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Скидка.
 */
export interface ProductDiscount { 
    /**
     * ID продукта.
     */
    id?: string;
    /**
     * ID продукта.
     */
    name?: string;
    /**
     * Дата публикации продукта.
     */
    datePublished?: string;
    /**
     * ID пользователя.
     */
    userId?: string;
    /**
     * ФИО пользователя.
     */
    userName?: string;
    /**
     * Рейтинг.
     */
    discountRate?: number;
    /**
     * Текстовое представление скидки.
     */
    discountText?: string;
    /**
     * Размер скидки в абсолютных величинах.
     */
    discountAbsolute?: number;
    /**
     * Сумма чека.
     */
    billTotal?: number;
    /**
     * Дата записи события в БД.
     */
    discountDate?: string;
}

