import { Directive, ElementRef, Input, OnChanges, SimpleChanges } from '@angular/core';

@Directive({
  selector: '[appTestingId]',
})
export class TestingDirective implements OnChanges {
  @Input() appTestingId?: string;

  constructor(private readonly element: ElementRef<any>) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.appTestingId) {
      this.element.nativeElement.setAttribute('data-qa', this.appTestingId);
    }
  }
}
