import {
  Component,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  ElementRef,
  Input,
  Inject,
  OnChanges,
  SimpleChanges,
  ViewChild,
  Output,
  EventEmitter,
  Injector,
} from '@angular/core';
import { Clipboard } from '@angular/cdk/clipboard';
import { Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { BehaviorSubject, lastValueFrom, Observer } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { MessageUnion } from '@airgram/web';
import { TuiScrollbarComponent, TuiDialogContext, TuiDialogService } from '@taiga-ui/core';
import { PolymorpheusContent, PolymorpheusComponent } from '@tinkoff/ng-polymorpheus';
import { BotCommand, ChatModel, MessageModel, SponsoredMessageUI, UserUI, ChatMemberModel } from '@src/models';
import { UserService, AlertService, BreakpointObserverHelperService, GetChatMembersService } from '@src/core/services';
import { TelegramMessengerService } from '@src/app/modules/telegram';
import { ResizableBaseComponent } from '@src/app/components/resizable-base-component';
import { BreakpointObserver } from '@angular/cdk/layout';
import { DialogConfirmComponent } from '@src/app/shared/dialogs';
import { getImageSrc } from '@src/utils';
import { EnvService } from '@src/app/modules/env';

import { htmlTagsReplace } from '../telegram/ui/chat/utils/htmlTagsReplace';

import { ContextMenuItem } from './message-list.model';

interface MessageViewer {
  telegramId: number;
  fullName: string;
  id?: string;
  photo?: string;
}

@Component({
  selector: 'app-message-list',
  templateUrl: './message-list.component.html',
  styleUrls: ['./message-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MessageListComponent extends ResizableBaseComponent implements OnChanges {
  @Input() chat?: ChatModel;
  @Input() scrollToMessageId = { id: 0 };
  @Input() replyToMessageId: number = 0;
  @Input() editMessageId: number = 0;
  @Output() replyToMessageIdChange = new EventEmitter<number>();
  @Output() forwardToChatId = new EventEmitter<number[]>();
  @Output() editMessageIdChange = new EventEmitter<number>();
  @ViewChild(TuiScrollbarComponent, { read: ElementRef }) private scrollBar?: ElementRef<HTMLElement>;
  @ViewChild('confirmDeleteDialogTemplate') confirmDeleteDialogTemplate?: PolymorpheusContent<TuiDialogContext>;
  @ViewChild('confirmPinDialogTemplate') confirmPinDialogTemplate?: PolymorpheusContent<TuiDialogContext>;

  authUser$: BehaviorSubject<UserUI | undefined> = this.userService.authUser$;
  loader: boolean = false;
  tempScrollHeight: number = 0;
  sponsoredMessage?: SponsoredMessageUI;
  sponsoredMessageVisible: boolean = false;
  scrollToBottomButtonVisible: boolean = false;
  forwardMessageIds: number[] = [];
  deleteMessageIds: number[] = [];
  deleteMessagesForAll: boolean = false;
  userListVisible: boolean = false;
  userList: MessageViewer[] = [];
  canPinMessages: boolean = false;
  pinMessageId?: number;
  pinMessageForAll: boolean = false;
  notifyPinnedMessageForAll: boolean = true;

  /** Список доступных пользователей */
  private chatMembers: ChatMemberModel[] = [];

  contextMenuItems: ContextMenuItem[] = [
    {
      name: 'reply',
      title: this.translateService.instant('components.messageList.buttons.reply'),
      iconName: 'reply',
      action: (messageId: number) => {
        this.editMessageId = 0;
        this.editMessageIdChange.emit(0);

        this.replyToMessageId = messageId;
        this.replyToMessageIdChange.emit(this.replyToMessageId);

        this.focusedMessage(messageId);
        this.cdr.markForCheck();
      },
      visible: () => true,
    },
    {
      name: 'edit',
      title: this.translateService.instant('components.messageList.buttons.edit'),
      iconName: 'edit-2',
      action: (messageId: number) => {
        this.replyToMessageId = 0;
        this.replyToMessageIdChange.emit(0);

        this.editMessageId = messageId;
        this.editMessageIdChange.emit(this.editMessageId);

        this.focusedMessage(messageId);
        this.cdr.markForCheck();
      },
      visible: message => message.canBeEdited && message.content._ === 'messageText', // TODO: refactoring,
    },
    {
      name: 'pin',
      title: this.translateService.instant('components.messageList.buttons.pin'),
      iconName: 'pin',
      action: (messageId: number) => {
        this.pinMessageId = messageId;
        this.pinMessageForAll = false;
        this.notifyPinnedMessageForAll = true;
        this.openConfirmPinDialog();
      },
      visible: message => this.canPinMessages && !message.isPinned,
    },
    {
      name: 'unpin',
      title: this.translateService.instant('components.messageList.buttons.unpin'),
      iconName: 'pin-off',
      action: (messageId: number) => {
        this.confirmUnpinDialog.pipe(takeUntil(this.destroyed$$)).subscribe({
          next: res => {
            if (res) {
              this.unpinMessage(messageId);
            }
          },
        });
      },
      visible: message => this.canPinMessages && message.isPinned,
    },
    {
      name: 'copy',
      title: this.translateService.instant('components.messageList.buttons.copy'),
      iconName: 'copy',
      action: (messageId: number) => {
        this.copyToClipboard(messageId).then();
      },
      visible: message => {
        // TODO: refactoring
        const content = message.content as any;
        return message.canBeSaved && (content?.text?.text || content?.caption?.text);
      },
    },
    {
      name: 'forward',
      title: this.translateService.instant('components.messageList.buttons.forward'),
      iconName: 'forward',
      action: (messageId: number) => {
        this.forwardMessageIds = [messageId];
        this.forwardToChatId.emit(this.forwardMessageIds);
        this.cdr.markForCheck();
      },
      visible: message => message.canBeForwarded,
    },
    {
      name: 'delete',
      title: this.translateService.instant('components.messageList.buttons.delete'),
      iconName: 'trash',
      action: (messageId: number) => {
        this.deleteMessageIds = [messageId];
        this.deleteMessagesForAll = false;
        this.focusedMessage(messageId);
        this.openConfirmDeleteDialog();
        this.cdr.markForCheck();
      },
      visible: message => message.canBeDeletedForAllUsers || message.canBeDeletedOnlyForSelf,
    },
    {
      name: 'viewers',
      title: '',
      iconName: 'check-check',
      count: 0,
      action: () => {
        this.userListVisible = !this.userListVisible;
      },
      visible: (message, item) => message.canGetViewers && !!item?.count,
    },
  ];

  private readonly confirmUnpinDialog = this.dialogService.open<boolean>(
    new PolymorpheusComponent(DialogConfirmComponent, this.injector),
    {
      label: this.translateService.instant('components.messageList.dialogs.unpinHeader'),
      size: 's',
      closeable: false,
    },
  );

  constructor(
    readonly cdr: ChangeDetectorRef,
    readonly breakpointObserver: BreakpointObserver,
    readonly breakpointObserverHelperService: BreakpointObserverHelperService,
    private router: Router,
    private messengerService: TelegramMessengerService,
    private clipboard: Clipboard,
    private userService: UserService,
    private getChatMembersService: GetChatMembersService,
    @Inject(TuiDialogService) private readonly dialogService: TuiDialogService,
    @Inject(Injector) private readonly injector: Injector,
    private readonly alertService: AlertService,
    private readonly translateService: TranslateService,
    private readonly env: EnvService,
  ) {
    super(cdr, breakpointObserver, breakpointObserverHelperService);
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.messengerService.updates$.pipe(takeUntil(this.destroyed$$)).subscribe(async update => {
      let findMessage: MessageModel | undefined;

      switch (update._) {
        case 'updateNewMessage':
          const { message } = update;
          if (message.chatId === this.chat?.id) {
            this.concatChatHistory().then(() => {
              setTimeout(() => this.scrollToBottom(), 100);
            });
          }
          break;

        case 'updateDeleteMessages':
          if (!this.chat || update.chatId !== this.chat.id) break;

          const messageIds = update.messageIds;
          this.chat.messages = this.chat.messages?.filter(message => !messageIds.includes(message.id));
          break;

        case 'updateMessageEdited':
          if (update.chatId !== this.chat?.id) break;

          findMessage = this.chat?.messages?.find(mess => mess.id === update.messageId);

          if (!findMessage) break;

          findMessage.editDate = update.editDate;
          break;

        case 'updateMessageIsPinned':
          if (update.chatId !== this.chat?.id) break;

          findMessage = this.chat?.messages?.find(mess => mess.id === update.messageId);

          if (!findMessage) break;

          findMessage.isPinned = update.isPinned;
          break;

        case 'updateMessageSendSucceeded':
          if (update.message.chatId !== this.chat?.id) break;

          findMessage = this.chat?.messages?.find(mess => mess.id === update.oldMessageId);

          if (!findMessage) break;

          findMessage.id = update.message.id;
          // TODO: Обновление актуально для последнего отправленного сообщения до выхода из чата
          findMessage.canBeEdited = update.message.canBeEdited;
          // TODO: Обновление актуально для последнего отправленного сообщения до выхода из чата
          findMessage.canGetViewers = update.message.canGetViewers;
          // TODO: проверить актуальность свойства
          findMessage.sendSucceeded = true;
          break;

        case 'updateMessageContent':
          if (update.chatId !== this.chat?.id) break;

          findMessage = this.chat?.messages?.find(mess => mess.id === update.messageId);

          if (!findMessage) break;

          findMessage.content = update.newContent;
          break;

        default:
          break;
      }

      this.cdr.markForCheck();
    });

    if (this.chat?.id) {
      this.getChatMembersService.getChatMembers(this.chat.id).then(members => {
        this.chatMembers = members;
      });
    }

    setTimeout(() => this.scrollToBottom(), 300);
  }

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    if (changes.chat) {
      this.sponsoredMessage = undefined;
      this.canPinMessages = false;

      if (this.chat) {
        this.canPinMessages = await this.getCanPinMessages();

        if (this.chat.type._ === 'chatTypeSupergroup' && this.chat.type.isChannel) {
          this.sponsoredMessage = await this.messengerService.api.getChatSponsoredMessages(this.chat.id);
          if (this.sponsoredMessage) {
            this.sponsoredMessage.id = this.sponsoredMessage.messageId; // TODO
            this.sponsoredMessage.senderId = { _: 'messageSenderChat', chatId: this.sponsoredMessage.sponsorChatId };
          }
        }

        await this.concatChatHistory();
        setTimeout(() => {
          this.scrollToBottom();
          this.cdr.markForCheck();
        }, 10);
      }
    }

    if (changes.scrollToMessageId && this.scrollToMessageId.id) {
      this.scrollToMessage(this.scrollToMessageId.id);
    }
  }

  sponsoredButtonClicked(message: SponsoredMessageUI): void {
    if (!this.chat?.id) return;

    // TODO: refactoring
    if (message.sponsorChatId > 0) {
      this.router.navigate(['chats', message.sponsorChatId]).then();
    } else {
      switch (message?.link?._) {
        case 'internalLinkTypeChatInvite':
          window.open(message.link.inviteLink, '_blank');
          break;

        case 'internalLinkTypeMessage':
          window.open(message.link.url, '_blank');
          break;
      }
    }
  }

  async handleBotCommand(botCommand: BotCommand): Promise<void> {
    if (!this.chat?.id) return;

    // TODO: add other types
    switch (botCommand.type._) {
      case 'inlineKeyboardButtonTypeCallback':
        await this.messengerService.api.sendBotCommand(this.chat.id, botCommand.messageId, {
          _: 'callbackQueryPayloadData',
          data: botCommand.type.data,
        });
        break;

      case 'inlineKeyboardButtonTypeUrl':
        this.env.openLink(botCommand.type.url);
        break;
    }
  }

  async sendBotTextCommand(botTextCommand: string): Promise<void> {
    if (!this.chat?.id) return;

    const parsedBotTextCommand = await this.messengerService.api.parseTextEntities(
      htmlTagsReplace(botTextCommand, false),
      {
        _: 'textParseModeHTML',
      },
    );

    await this.messengerService.api.sendMessageText(this.chat.id, parsedBotTextCommand).then();
  }

  onReadeMessage(
    [entry]: IntersectionObserverEntry[],
    message: MessageModel,
    intObserverReading: IntersectionObserver,
  ) {
    if (
      !this.chat ||
      !this.chat.lastReadInboxMessageId ||
      message.isOutgoing ||
      message.id <= this.chat.lastReadInboxMessageId
    ) {
      intObserverReading.unobserve(entry.target);
      return;
    }

    if (entry.isIntersecting) {
      this.messengerService.api.viewMessages(this.chat.id, message.id).then();
      intObserverReading.unobserve(entry.target);
    }
  }

  async onScroll(): Promise<void> {
    if (!this.scrollBar) return;

    const { nativeElement } = this.scrollBar;

    if (nativeElement.scrollTop === 0) {
      this.tempScrollHeight = nativeElement.scrollHeight;
      await this.concatChatHistory(this.chat?.messages);

      setTimeout(() => {
        nativeElement.scrollTop = nativeElement.scrollHeight - this.tempScrollHeight;
        this.cdr.markForCheck();
      }, 10);
    }

    this.scrollToBottomButtonVisible = true;

    if (nativeElement.scrollTop > nativeElement.scrollHeight - nativeElement.offsetHeight - 20) {
      this.scrollToBottomButtonVisible = false;
      this.sponsoredMessageVisible = true;
      await this.messengerService.api.viewMessages(this.chat?.id, this.sponsoredMessage?.messageId);
    }
  }

  scrollToMessage(id: number): void {
    const selectedMessage = this.focusedMessage(id);
    if (selectedMessage) {
      selectedMessage.scrollIntoView({ behavior: 'smooth', block: 'center' });
    } else {
      this.loadToMessage(id).then();
    }
  }

  scrollToBottom(): void {
    if (!this.scrollBar) return;

    const { nativeElement } = this.scrollBar;

    nativeElement.scrollTop = nativeElement.scrollHeight;
  }

  async copyToClipboard(copyMessageId: number): Promise<void> {
    if (!this.chat?.id) return;
    const message = await this.messengerService.api.getMessage(this.chat?.id, copyMessageId);
    // TODO: refactoring
    const content = message.content as any;
    const copyText = content?.text?.text || content?.caption?.text;
    if (this.clipboard.copy(copyText)) {
      await this.alertService.success(
        this.translateService.instant('components.messageList.alerts.successes.copyMessageText'),
      );
    }
  }

  openConfirmPinDialog(): void {
    if (!this.confirmPinDialogTemplate) return;

    this.dialogService
      .open(this.confirmPinDialogTemplate, {
        label: this.translateService.instant('components.messageList.dialogs.pinHeader'),
        size: 's',
        closeable: false,
        dismissible: false,
      })
      .subscribe();
  }

  applyPin(
    observer: Observer<void>,
    pinMessageForAll: boolean = true,
    notifyPinnedMessageForAll: boolean = false,
  ): void {
    observer.complete();

    if (this.pinMessageId) {
      this.pinMessage(this.pinMessageId, !notifyPinnedMessageForAll, !pinMessageForAll);
    }
  }

  openConfirmDeleteDialog(): void {
    if (!this.confirmDeleteDialogTemplate) return;

    this.dialogService
      .open(this.confirmDeleteDialogTemplate, {
        label: this.translateService.instant('components.messageList.dialogs.deleteMessageHeader'),
        size: 's',
        closeable: false,
        dismissible: false,
      })
      .subscribe();
  }

  applyDelete(observer: Observer<void>, deleteMessagesForAll: boolean = false): void {
    observer.complete();

    if (this.deleteMessageIds) {
      this.deleteMessages(deleteMessagesForAll);
    }
  }

  deleteMessages(deleteMessagesForAll: boolean) {
    if (!this.chat?.id) return;

    this.messengerService.api.deleteMessages(this.chat.id, this.deleteMessageIds, deleteMessagesForAll).then(() => {
      this.deleteMessageIds = [];
      this.deleteMessagesForAll = false;
      this.cdr.markForCheck();
    });
  }

  async getMessageViewers(selectedMessage: MessageModel) {
    if (!this.chat || !selectedMessage.canGetViewers) return;

    const viewersItem = this.contextMenuItems.find(menuItem => menuItem.name === 'viewers');
    if (viewersItem) {
      this.userListVisible = false;
      viewersItem.count = 0;
      this.userList = [];
      const users = await this.messengerService.api.getMessageViewers(this.chat.id, selectedMessage.id);
      if (users.totalCount) {
        viewersItem.count = users.totalCount;
        this.cdr.markForCheck();

        const unionsUsers = await lastValueFrom(this.userService.getUsersData(users.userIds));
        const viewersList: MessageViewer[] = unionsUsers.map(unionsUser => {
          return {
            telegramId: unionsUser.telegramId!,
            id: unionsUser.id,
            fullName: unionsUser.fullName!,
            photo: getImageSrc(unionsUser.photoId),
          };
        });

        for (const userId of users.userIds) {
          if (viewersList.findIndex(viewer => viewer.telegramId === userId) === -1) {
            const tgUser = await this.messengerService.api.getUser(userId);
            viewersList.push({
              telegramId: tgUser.id,
              fullName:
                tgUser.lastName || tgUser.firstName ? [tgUser.lastName, tgUser.firstName].join(' ') : tgUser.username,
              // photo: tgUser.profilePhoto, // TODO: refactoring
            });
          }
        }

        this.userList = viewersList;
      }
    }

    this.cdr.markForCheck();
  }

  viewUserProfile(userId?: string) {
    if (!userId) return;

    this.router.navigate(['association-users', userId]).then();
  }

  private async getCanPinMessages(): Promise<boolean> {
    if (!this.chat) return false;

    if (this.chat.permissions.canPinMessages) return true;

    if (this.chat.type._ === 'chatTypeBasicGroup') {
      const basicGroup = await this.messengerService.api.getBasicGroup(this.chat.type.basicGroupId);
      if (basicGroup.status._ === 'chatMemberStatusCreator') return true;
      if (basicGroup.status._ === 'chatMemberStatusAdministrator' && basicGroup.status.canPinMessages) return true;
      if (basicGroup.status._ === 'chatMemberStatusRestricted' && basicGroup.status.permissions.canPinMessages)
        return true;
    } else if (this.chat.type._ === 'chatTypeSupergroup') {
      const superGroup = await this.messengerService.api.getSupergroup(this.chat.type.supergroupId);
      if (superGroup.status._ === 'chatMemberStatusCreator') return true;
      if (superGroup.status._ === 'chatMemberStatusAdministrator' && superGroup.status.canPinMessages) return true;
      if (superGroup.status._ === 'chatMemberStatusRestricted' && superGroup.status.permissions.canPinMessages)
        return true;
    }

    return false;
  }

  private pinMessage(messageId: number, disableNotification: boolean = true, onlyForSelf: boolean = false) {
    if (!this.chat) return;

    if (this.chat.type._ !== 'chatTypePrivate') {
      onlyForSelf = false;
    }

    if (this.chat.type._ === 'chatTypePrivate' || this.chat.type._ === 'chatTypeSupergroup') {
      disableNotification = true;
    }

    this.messengerService.api
      .pinChatMessage(this.chat.id, messageId, disableNotification, onlyForSelf)
      .then(() => this.cdr.markForCheck());
  }

  private unpinMessage(messageId: number) {
    if (!this.chat) return;

    this.messengerService.api.unpinChatMessage(this.chat.id, messageId).then(() => this.cdr.markForCheck());
  }

  private async loadToMessage(messageId: number) {
    if (!this.chat || !this.chat.messages) return;

    while (this.chat.messages.findIndex(message => message.id === messageId) === -1) {
      await this.concatChatHistory(this.chat.messages);
      this.cdr.markForCheck();
    }

    setTimeout(() => {
      this.scrollToMessage(messageId);
    }, 1000);
  }

  private focusedMessage(messageId: number) {
    const selectedMessage = document.getElementById('message-wrapper_' + messageId);
    if (selectedMessage) {
      selectedMessage.classList.add('message-wrapper_focused');
      setTimeout(() => {
        selectedMessage.classList.remove('message-wrapper_focused');
      }, 3000);
    }
    return selectedMessage;
  }

  private async concatChatHistory(oldHistory: MessageUnion[] = []): Promise<void> {
    if (!this.chat) return;
    this.loader = true;

    const fromMessageId = oldHistory.length > 0 ? oldHistory[oldHistory.length - 1].id : 0;
    const chatHistory = await this.messengerService.api.getChatHistory(this.chat.id, fromMessageId);
    if (chatHistory.messages?.length) {
      (chatHistory.messages as MessageModel[]).map(message => {
        message.sendSucceeded = false;
        return message;
      });

      this.chat.messages = oldHistory.concat(chatHistory.messages);
    }

    // TODO: refactoring
    if ((this.chat?.messages?.length === 1 || this.chat?.messages?.length === 3) && chatHistory.totalCount) {
      await this.concatChatHistory(this.chat.messages);
    } else {
      this.loader = false;
      this.cdr.markForCheck();
    }
  }

  /**
   * Обработка нажатия на имя пользователя, который начинается с символа @.
   * Получаем идентификатор пользователя в системе и переходим на маршрут /association-users/:id.
   * В случае, если его нет в списке участников чата, то показываем сообщение.
   *
   * @param username имя пользователя в телеграм (который начинается с символа @)
   */
  async clickUsername(username: string): Promise<boolean> {
    const member = this.chatMembers.find(item => `@${item.username}` === username);
    if (!member) {
      await this.alertService.warning(
        this.translateService.instant('components.messageList.alerts.warnings.clickUsername'),
      );
      return false;
    }

    const user = await lastValueFrom(this.userService.getUserByTelegramId(member.id));
    return this.router.navigate(['association-users', user.id]);
  }

  /**
   * Обработка нажатия на имя пользователя в сообщении.
   * Телеграм присылает идентификатор, когда в качестве обращения указали не {username} а имя.
   *
   * Получаем идентификатор пользователя в системе и переходим на маршрут /association-users/:id
   *
   * @param userId идентификатор в телеграм
   */
  async clickUserId(userId: number): Promise<boolean> {
    const user = await lastValueFrom(this.userService.getUserByTelegramId(userId));
    return this.router.navigate(['association-users', user.id]);
  }
}
