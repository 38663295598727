import { Component, ChangeDetectionStrategy, Input, OnChanges, SimpleChanges, ChangeDetectorRef } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { TuiSizeL, TuiSizeM, TuiSizeS } from '@taiga-ui/core';
import { UserUI, ViewMode } from '@src/models';
import { UserService } from '@src/core/services';

@Component({
  selector: 'field-new-user',
  templateUrl: './field-new-user.component.html',
  styleUrls: ['./field-new-user.component.scss', '../styles/field-new-content.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FieldNewUserComponent implements OnChanges {
  @Input() mode?: ViewMode = 'view';
  @Input() name?: string;
  @Input() userId?: string | null;
  @Input() userFullName?: string;
  @Input() size: TuiSizeS | TuiSizeM | TuiSizeL = 'l';

  @Input() noUnderline?: boolean = false;

  user?: UserUI;

  constructor(private cdr: ChangeDetectorRef, private userService: UserService) {}

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    if (changes.userId || changes.userFullName) {
      this.user = undefined;
      // TODO: refactoring.  Вынести компонент отображения поля с пользователей в отдельный модуль
      if (this.userFullName) {
        const userId = this.userId ?? undefined;
        this.user = { id: userId, fullName: this.userFullName };
      } else if (this.userId) {
        const users = await lastValueFrom(this.userService.getUsersData([this.userId]));
        if (users.length) {
          this.user = users[0];
        }
      }
    }

    this.cdr.markForCheck();
  }
}
