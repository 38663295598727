/**
 * Unions API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Информация для авторизации.
 */
export interface UserInfoCheck { 
    /**
     * Идентификатор.
     */
    telegramId?: number;
    /**
     * Имя пользователя.
     */
    firstName?: string;
    /**
     * Фамилия пользователя.
     */
    lastName?: string;
    /**
     * Номер телефона пользователя.
     */
    phone?: string;
    /**
     * 
     */
    hash?: string;
    /**
     * Идентифкатор бизнес-ассоциации.
     */
    headId?: string;
    /**
     * Идентифкатор организации.
     */
    organisationId?: string;
}

