import { Pipe, PipeTransform } from '@angular/core';
import { getImageSrc } from '@src/utils';
import { GetImageType } from '@src/models/images';

@Pipe({
  name: 'getImageSrc',
})
export class GetImageSrcPipe implements PipeTransform {
  transform(id?: string, alt = '', size: GetImageType = 'thumbnail', unionId?: string | null): string {
    return getImageSrc(id, alt, size, unionId);
  }
}
