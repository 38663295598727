<div class="registration">
  <!-- TODO: Вынести в отдельный компонент -->
  <ng-container *ngIf="registrationActive; else registrationDone">
    <div [hide]="openPolicy">
      <div class="registration__header">
        <button
          tuiButton
          class="button-return"
          type="button"
          appearance="link"
          icon="tuiIconChevronLeftLarge"
          size="s"
          [title]="'common.buttons.return' | translate"
          routerLink="/"
        >
          {{ 'common.buttons.return' | translate }}
        </button>

        <div class="title">
          <app-branding-logo class="logo__image" [width]="100" [height]="100"></app-branding-logo>

          <span class="title__text">
            {{ 'components.registration.labels.title' | translate }}
          </span>
        </div>
      </div>

      <registration-form
        [associationId]="associationId"
        [loading]="loading"
        (saved)="saveRegistrationForm($event)"
      ></registration-form>

      <app-link class="privacy" color="grey" [class.disabled-link]="loading" (click)="openPolicy = true">
        {{ 'components.registration.labels.privacyPart1' | translate }}
        <u>{{ 'components.registration.labels.privacyPart2' | translate }}</u>
        {{ 'components.registration.labels.privacyPart3' | translate }}
        <u>{{ 'components.registration.labels.privacyPart4' | translate }}</u>
      </app-link>
    </div>

    <app-login-policy
      *ngIf="openPolicy"
      [associationId]="brandingData?.id"
      [associationShortName]="brandingData?.shortName"
      [privacyPolicyLink]="brandingData?.privacyPolicyLink"
      [enterType]="EnterType.Email"
      (close)="openPolicy = false"
    ></app-login-policy>
  </ng-container>
</div>

<!-- TODO: Вынести в отдельный компонент -->
<ng-template #registrationDone>
  <div class="registration-done">
    <app-branding-logo class="registration-done__logo" [width]="100" [height]="100"></app-branding-logo>

    <span class="registration-done__title">
      {{ 'components.registration.labels.registrationDoneTitle' | translate }}
    </span>

    <span class="registration-done__description">
      {{ 'components.registration.labels.registrationDoneDescription' | translate }}
    </span>

    <button
      tuiButton
      class="registration-done__button"
      type="button"
      [title]="'common.buttons.return' | translate"
      (click)="saved.emit(newUserId)"
    >
      {{ 'common.buttons.return' | translate }}
    </button>
  </div>
</ng-template>
