import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, forkJoin, Observable, Subject, throwError } from 'rxjs';
import { catchError, map, takeUntil } from 'rxjs/operators';
import { JobTitlesService, JobTitlesForUnionService, JobTitleForUnionAdd, JobTitleForUnionView } from '@src/api';
import { JobTitleUI } from '@src/models';
import { AlertService } from '@src/core/services/alert.service';

@Injectable({
  providedIn: 'root',
})
export class JobTitleService implements OnDestroy {
  jobTitle$: BehaviorSubject<JobTitleUI> = new BehaviorSubject({});
  jobTitles$: BehaviorSubject<JobTitleUI[]> = new BehaviorSubject([{}]);
  allJobTitles$: BehaviorSubject<JobTitleUI[] | null> = new BehaviorSubject<JobTitleUI[] | null>(null);
  jobTitlesForUnion$: BehaviorSubject<JobTitleUI[] | undefined> = new BehaviorSubject<JobTitleUI[] | undefined>(
    undefined,
  );

  private destroyed$$: Subject<void> = new Subject<void>();

  constructor(
    private jobTitlesService: JobTitlesService,
    private jobTitlesForUnionService: JobTitlesForUnionService,
    private alertService: AlertService,
  ) {}

  ngOnDestroy(): void {
    this.destroyed$$.next();
    this.destroyed$$.complete();
  }

  getJobTitle(id: string): void {
    this.jobTitlesService
      .jobTitle(id)
      .pipe(
        catchError(err => {
          this.alertService.error(err, { autoClose: 30000 });
          return throwError(err);
        }),
        takeUntil(this.destroyed$$),
      )
      .subscribe(jobTitle => this.jobTitle$.next(jobTitle));
  }

  getJobTitles(): void {
    this.jobTitlesForUnionService
      .jobTitlesForUnion()
      .pipe(
        catchError(err => {
          this.alertService.error(err, { autoClose: 30000 });
          return throwError(err);
        }),
        map(jobTitles => this.sortingJobTitles(jobTitles)),
        takeUntil(this.destroyed$$),
      )
      .subscribe(jobTitles => this.jobTitles$.next(jobTitles));
  }

  getJobTitlesForUnionData(unionId?: string, jobTitleId?: string) {
    return this.jobTitlesForUnionService.jobTitlesForUnion(jobTitleId, unionId).pipe(
      catchError(err => {
        this.alertService.error(err, { autoClose: 30000 });
        return throwError(() => err);
      }),
      map(jobTitles => this.sortingJobTitles(jobTitles)),
      takeUntil(this.destroyed$$),
    );
  }

  getJobTitlesForUnion(unionId?: string, jobTitleId?: string) {
    this.getJobTitlesForUnionData(unionId, jobTitleId).subscribe(result => {
      this.jobTitlesForUnion$.next(result);
    });
  }

  saveJobTitles(jobTitles: JobTitleUI[]): Observable<string[]> {
    const data = this.mapJobTitlesUIToJobTitle(jobTitles);
    return this.jobTitlesForUnionService.upsertJobTitleForUnion(data).pipe(
      catchError(err => {
        this.alertService.error(err, { autoClose: 30000 });
        return throwError(err);
      }),
    );
  }

  getAllJobTitles(): void {
    forkJoin([this.jobTitlesService.jobTitles(), this.jobTitlesForUnionService.jobTitlesForUnion()])
      .pipe(
        catchError(err => {
          this.alertService.error(err, { autoClose: 30000 });
          return throwError(err);
        }),
        map(([allJobTitles, jobTitlesForUnion]) => this.mergeJobTitles(allJobTitles, jobTitlesForUnion)),
        map(jobTitles => this.sortingJobTitles(jobTitles)),
        takeUntil(this.destroyed$$),
      )
      .subscribe(allJobTitles => this.allJobTitles$.next(allJobTitles));
  }

  resetJobTitle(): void {
    this.jobTitle$.next({});
  }

  resetJobTitles(): void {
    this.jobTitles$.next([{}]);
  }

  resetJobTitlesForUnion() {
    this.jobTitlesForUnion$.next(undefined);
  }

  resetAll(): void {
    this.resetJobTitle();
    this.resetJobTitles();
  }

  private sortingJobTitles(jobTitles: JobTitleUI[]): JobTitleUI[] {
    jobTitles.sort((jobTitle1, jobTitle2) => {
      if (!jobTitle1.isVisible && jobTitle2.isVisible) return 1;
      if (jobTitle1.isVisible && !jobTitle2.isVisible) return -1;

      const jobTitleSortOrder1 = jobTitle1.sortOrder;
      const jobTitleSortOrder2 = jobTitle2.sortOrder;

      if (jobTitleSortOrder1 === undefined) return 1;
      if (jobTitleSortOrder2 === undefined) return -1;

      if (jobTitleSortOrder1 < jobTitleSortOrder2) return -1;
      if (jobTitleSortOrder1 > jobTitleSortOrder2) return 1;
      return 0;
    });

    return jobTitles;
  }

  private mergeJobTitles(allJobTitles: JobTitleUI[], jobTitlesForUnion: JobTitleForUnionView[]): JobTitleUI[] {
    return allJobTitles.map(jobTitle => {
      const findJobTitleForUnion = jobTitlesForUnion.find(
        jobTitleForUnion => jobTitleForUnion.jobTitleId === jobTitle.id,
      );
      if (findJobTitleForUnion) {
        jobTitle.isVisible = true;
        jobTitle.isAdminOnly = findJobTitleForUnion.isAdminOnly;
        jobTitle.sortOrder = findJobTitleForUnion.sortOrder;
      } else {
        jobTitle.sortOrder = undefined;
      }

      return jobTitle;
    });
  }

  private mapJobTitlesUIToJobTitle(jobTitles: JobTitleUI[]): JobTitleForUnionAdd[] {
    return jobTitles.map(jobTitle => {
      jobTitle.jobTitleId = jobTitle.id;

      delete jobTitle.id;
      delete jobTitle.isVisible;

      return jobTitle;
    });
  }
}
