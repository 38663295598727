/**
 * Unions API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { EditLoyaltyCategory } from './editLoyaltyCategory';


/**
 * Редактировать запись в справочнике ГРУПП категорий продуктов - первый уровень
 */
export interface EditLoyaltyCategoryGroup { 
    /**
     * Наименование.
     */
    name: string;
    /**
     * Описание.
     */
    description?: string;
    /**
     * ID фотографии/иконки.
     */
    iconId?: string;
    /**
     * Сортировка.
     */
    sortOrder?: number;
    /**
     * ID записи.
     */
    id?: string;
    /**
     * справочник категорий продуктов - второй уровень
     */
    loyaltyCategories?: Array<EditLoyaltyCategory>;
}

