<contacts-view-old
  [(value)]="value"
  [disabled]="disabled"
  [loading]="loading"
  [allowCreating]="allowCreating"
  [allowEditing]="allowEditing"
  [allowDeleting]="allowDeleting"
  [allowFilesDeleting]="allowFilesDeleting"
  [isModeration]="isModeration"
  [showFieldCounter]="showFieldCounter"
  [showFieldSeparator]="showFieldSeparator"
  [validateForm]="validateForm"
  [touched]="touched"
  [acceptFiles]="accept"
></contacts-view-old>
