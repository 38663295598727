import { BreakpointObserver } from '@angular/cdk/layout';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormGroup, UntypedFormBuilder } from '@angular/forms';
import { takeUntil } from 'rxjs';
import { ResizableBaseComponent } from '@src/app/components';
import { BreakpointObserverHelperService, PollService } from '@src/core/services';
import { Optional } from '@src/types/utils';
import { AnswerType, QuestionFormType } from '@src/models';
import { ANSWER_TYPES } from '@src/app/modules/poll-info';

import { QuestionsService } from '../../services';

import { FormDataType } from './types';

@Component({
  selector: 'view-all',
  templateUrl: './view-all.component.html',
  styleUrls: ['./view-all.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ViewAllComponent extends ResizableBaseComponent implements OnChanges {
  @Input() pollId: Optional<string>;
  @Output() pollStopped = new EventEmitter<void>();
  @Output() pollFinished = new EventEmitter<void>();

  form!: FormGroup<FormDataType>;
  loading: boolean = true;
  readonly answerTypes: AnswerType[] = ANSWER_TYPES;

  constructor(
    readonly cdr: ChangeDetectorRef,
    readonly breakpointObserver: BreakpointObserver,
    readonly breakpointObserverHelperService: BreakpointObserverHelperService,
    private readonly pollService: PollService,
    private readonly formBuilder: UntypedFormBuilder,
    private readonly questionsService: QuestionsService,
  ) {
    super(cdr, breakpointObserver, breakpointObserverHelperService);

    this.initForm();
  }

  get questions() {
    return this.form.controls.questions;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['pollId']) {
      if (this.pollId) {
        this.loading = true;

        this.pollService
          .startPoll(this.pollId)
          .pipe(takeUntil(this.destroyed$$))
          .subscribe(() => {
            if (this.pollId) {
              this.pollService
                .allQuestions(this.pollId)
                .pipe(takeUntil(this.destroyed$$))
                .subscribe(questions => {
                  this.setFormData(questions);
                  this.loading = false;
                  this.cdr.markForCheck();
                });
            }
          });
      }
    }

    this.cdr.markForCheck();
  }

  addQuestion(): any {
    return this.formBuilder.group({
      id: [''],
      text: [''],
      altAnswer: [null],
      answerOption: [],
      answerType: [],
      answers: [],
      file: [],
      date: [],
      time: [],
      photoId: [],
    });
  }

  async saveReplies() {
    if (!this.pollId) {
      return;
    }

    this.questionsService.saveReplies(this.pollId, this.form.value.questions).then(res => {
      res?.pipe(takeUntil(this.destroyed$$)).subscribe(() => {
        this.pollFinished.emit();
      });
    });
  }

  cancel() {
    this.pollStopped.emit();
  }

  private initForm() {
    this.form = new FormGroup<FormDataType>({
      questions: this.formBuilder.array([]),
    });
  }

  private setFormData(data: QuestionFormType[]): void {
    const questionsFormatted = data.map(question => {
      this.questions.push(this.addQuestion());

      question.answerType = this.answerTypes.find(answerType => answerType.id === question.answerTypeId);
      return question;
    });

    this.form.patchValue({
      questions: questionsFormatted,
    });
  }
}
