import { Component, ChangeDetectionStrategy, Input, OnChanges, SimpleChanges, ChangeDetectorRef } from '@angular/core';
import { TuiSizeL, TuiSizeM, TuiSizeS } from '@taiga-ui/core';
import { BreakpointObserverHelperService, DocumentService } from '@src/core/services';
import { ViewMode } from '@src/models';
import { takeUntil } from 'rxjs';
import { ResizableBaseComponent } from '@src/app/components';
import { BreakpointObserver } from '@angular/cdk/layout';
import { validateFileExtension } from '@src/app/modules/photo';

@Component({
  selector: 'app-field-file',
  templateUrl: './field-file.component.html',
  styleUrls: ['./field-file.component.scss', '../styles/field-content.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FieldFileComponent extends ResizableBaseComponent implements OnChanges {
  @Input() mode?: ViewMode = 'view';
  @Input() fileName?: string;
  @Input() fileId?: string | null;
  @Input() size: TuiSizeS | TuiSizeM | TuiSizeL = 'l';

  fileType: 'photo' | 'document' = 'document';

  constructor(
    readonly cdr: ChangeDetectorRef,
    readonly breakpointObserver: BreakpointObserver,
    readonly breakpointObserverHelperService: BreakpointObserverHelperService,
    private readonly documentService: DocumentService,
  ) {
    super(cdr, breakpointObserver, breakpointObserverHelperService);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.fileId && this.fileId) {
      this.documentService
        .getDocumentInfo(this.fileId)
        .pipe(takeUntil(this.destroyed$$))
        .subscribe(documentInfo => {
          if (validateFileExtension(documentInfo.fileName)) {
            this.fileType = 'photo';
          }

          this.cdr.markForCheck();
        });
    }
  }

  downloadDocument(): void {
    if (!this.fileId) return;

    this.documentService.downloadDocumentById(this.fileId);
  }
}
