import { UserUI } from '@src/models';

export const sortingUsers = (users: UserUI[]): UserUI[] => {
  users.sort((user1, user2) => {
    const userName1 = user1.fullName?.toLowerCase();
    const userName2 = user2.fullName?.toLowerCase();

    if (!userName1) return 1;
    if (!userName2) return -1;

    if (userName1 < userName2) return -1;
    if (userName1 > userName2) return 1;
    return 0;
  });

  return users;
};
