<div class="search-chats">
  <div class="header">
    <app-search [(searchQuery)]="searchQuery" (searchQueryChange)="searchQueryChangeHandler($event)"></app-search>
  </div>

  <tui-loader [showLoader]="!foundChats">
    <app-list
      listType="chats"
      [items]="foundChats"
      [(selectedId)]="chatSelectedId"
      (selectedIdChange)="selectedChat($event)"
    ></app-list>
  </tui-loader>
</div>
