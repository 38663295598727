<tui-textarea
  class="field_height_min"
  formControlName="contact"
  [tuiTextfieldSize]="tuiElementLargeSize"
  [class.tui-skeleton]="loading"
  [class.tui-skeleton_text]="loading"
  [expandable]="true"
>
  <!-- Изменить -->
  <!-- {{ allowContactFieldEditing ? ('components.contacts.fields.contactValue' | translate) : contact.value.description }} -->
</tui-textarea>
