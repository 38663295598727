import { Injectable, OnDestroy } from '@angular/core';
import {
  ContactsService,
  GetUserContactsForUnionResponseDto,
  Id,
  RegistrationFormRequestDto,
  UserRegistrationService,
} from '@src/api';
import { AlertService } from '@src/core/services';
import { BehaviorSubject, catchError, Observable, Subject, takeUntil, throwError } from 'rxjs';
import { EnvService } from '@src/app/modules/env';

@Injectable({
  providedIn: 'root',
})
export class RegistrationService implements OnDestroy {
  userContacts$ = new BehaviorSubject<GetUserContactsForUnionResponseDto[] | null>(null);

  private destroyed$$ = new Subject<void>();

  constructor(
    private readonly contactsApiService: ContactsService,
    private readonly userRegistrationService: UserRegistrationService,
    private readonly env: EnvService,
    private readonly alertService: AlertService,
  ) {}

  ngOnDestroy(): void {
    this.destroyed$$.next();
    this.destroyed$$.complete();
  }

  getUserContactsForUnion(unionId: string) {
    this.contactsApiService
      .getUserContactsForUnion(unionId)
      .pipe(takeUntil(this.destroyed$$))
      .subscribe(contacts => {
        this.userContacts$.next(contacts);
      });
  }

  sendRegistrationForm(registrationFormRequestDto: RegistrationFormRequestDto): Observable<Id> {
    return this.userRegistrationService.registrationForm(registrationFormRequestDto);
  }

  addDocuments(file: Blob, fileName: string, contentType: string, parentOrganisationId: string): Observable<Id> {
    return this.userRegistrationService
      .uploadFileToRegistrationForm(file, fileName, contentType, parentOrganisationId)
      .pipe(
        takeUntil(this.destroyed$$),
        catchError(err => {
          this.alertService.error(err);
          return throwError(() => err);
        }),
      );
  }

  downloadDocumentById(fileId: string, fileName?: string): void {
    this.userRegistrationService.getFileFromRegistrationForm(fileId).subscribe(blob => {
      this.env.downloadFile({ data: blob, fileName });
    });
  }
}
