import { Component, OnInit, ChangeDetectionStrategy, Input, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MessageContentUnion } from '@airgram/core/types/outputs';
import { MessageDocument } from '@airgram/core/types/outputs/MessageContent';
import { FilePartUnion, FileUnion } from '@airgram/web';
import { FileReaderService } from '@src/core/services';
import { DocumentSource } from '@src/models';
import { EnvService } from '@src/app/modules/env';
import { FileSizePipe } from '@src/app/shared/pipes';

@Component({
  selector: 'app-message-document',
  templateUrl: './message-document.component.html',
  styleUrls: ['../styles/message-content.scss', './message-document.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MessageDocumentComponent implements OnInit, OnDestroy {
  @Input() set message(mess: MessageContentUnion | undefined) {
    this.messageDocument = mess as MessageDocument;
  }

  loading = false;
  messageDocument?: MessageDocument;
  messageDocumentSource?: DocumentSource;
  downloadedFileSize?: string;

  private destroyed$$: Subject<void>;

  constructor(
    private cdr: ChangeDetectorRef,
    private fileReaderService: FileReaderService,
    private env: EnvService,
    private readonly fileSizePipe: FileSizePipe,
  ) {
    this.destroyed$$ = new Subject<void>();
  }

  ngOnInit(): void {
    this.initDocument();
  }

  ngOnDestroy(): void {
    this.destroyed$$.next();
    this.destroyed$$.complete();
  }

  async downloadFile(): Promise<void> {
    if (!this.messageDocument?.document) return;

    this.loading = true;

    const documentFile = this.messageDocument?.document?.document;

    this.getFileSource(documentFile);

    if (
      documentFile.local.path &&
      documentFile.local.downloadedSize === documentFile.expectedSize &&
      this.messageDocumentSource
    ) {
      this.env.downloadFile(this.messageDocumentSource);
      this.loading = false;
      this.cdr.markForCheck();
    }
  }

  private initDocument(): void {
    if (!this.messageDocument) return;

    const documentFile = this.messageDocument?.document?.document;
    const documentThumbnail = this.messageDocument?.document?.thumbnail?.file;

    this.messageDocumentSource = {
      fileSize: this.fileSizePipe.transform(documentFile?.size),
      fileName: this.messageDocument?.document.fileName,
    };

    if (documentFile.local.downloadedSize === documentFile.expectedSize) {
      this.getFileSource(documentFile);
    } else {
      const size = documentFile.local.downloadedSize;
      this.setDownloadedFileSize(size);
    }

    this.getFileSource(documentThumbnail, 'thumbnailSrc');

    this.fileReaderService.updateFile$.pipe(takeUntil(this.destroyed$$)).subscribe(updateFile => {
      if (documentFile.id === updateFile?.id) {
        const size = updateFile?.local.downloadedSize;
        this.setDownloadedFileSize(size);

        if (updateFile?.local.isDownloadingCompleted && this.messageDocumentSource) {
          if (this.messageDocument) {
            this.messageDocument.document.document = updateFile;
          }

          this.downloadedFileSize = undefined;

          // TODO: Костыль для проверки, что файл загружается, а не выгружается #10775. Выгружаемые файлы находятся в папке outboundfs
          if (updateFile.local.path.includes('inboundfs')) {
            this.env.downloadFile(this.messageDocumentSource);
          }
          this.loading = false;
          this.cdr.markForCheck();
        }
      }
    });
  }

  private getFileSource(file?: FileUnion, sourceProperty: keyof DocumentSource = 'src'): void {
    this.fileReaderService
      .getFilePart(file)
      .pipe(takeUntil(this.destroyed$$))
      .subscribe(filePart => this.setDocumentSource(filePart, sourceProperty));
  }

  private setDocumentSource(filePart?: FilePartUnion, sourceProperty: keyof DocumentSource = 'src'): void {
    this.messageDocumentSource = {
      ...this.messageDocumentSource,
      data: filePart?.data,
      [sourceProperty as string]: this.fileReaderService.getFileSource(filePart),
    };
    this.cdr.markForCheck();
  }

  private setDownloadedFileSize(size?: number) {
    if (!size) return;

    this.downloadedFileSize = this.fileSizePipe.transform(size);
    this.cdr.markForCheck();
  }
}
