import { Injectable } from '@angular/core';
import {
  AlertService,
  FileReaderService,
  PreferencesService,
  SubscriptionsForUsersService,
  SubscriptionsForUsersCommitteeService,
} from '@src/core/services';
import { ChatModel } from '@src/models';

import { TelegramMessengerService } from './telegram-messenger.service';
import { TelegramAuthService } from './telegram-auth.service';
import { lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TelegramChatService {
  constructor(
    private readonly alertService: AlertService,
    private readonly preferences: PreferencesService,
    private readonly fileReaderService: FileReaderService,
    private readonly messengerService: TelegramMessengerService,
    private readonly subscriptionsForUsersService: SubscriptionsForUsersService,
    private readonly subscriptionsForUsersCommitteeService: SubscriptionsForUsersCommitteeService,
    private readonly telegramAuthService?: TelegramAuthService,
  ) {}

  getNewsFeed(selectedChatId?: number): Promise<ChatModel[]> {
    return this.getData('newsFeed', selectedChatId);
  }

  getChats(selectedChatId?: number): Promise<ChatModel[]> {
    return this.getData('chats', selectedChatId);
  }

  private async getData(type: 'newsFeed' | 'chats', selectedChatId?: number): Promise<ChatModel[]> {
    const { showOnlyChannelsInNewsFeed } = await this.preferences.refresh();

    if (type === 'chats' && !showOnlyChannelsInNewsFeed) {
      return [];
    }

    const [organisationsSubscriptionsIds, committeesSubscriptionsIds] = await Promise.all([
      lastValueFrom(this.subscriptionsForUsersService.getSubscriptionsIdsData()),
      lastValueFrom(this.subscriptionsForUsersCommitteeService.getSubscriptionsIdsData()),
    ]);

    const mainBot = this.telegramAuthService?.getMainBot();

    const chatIds = (await this.messengerService.getAllChatIds()).filter(
      chatId =>
        organisationsSubscriptionsIds.includes(chatId) ||
        committeesSubscriptionsIds.includes(chatId) ||
        chatId === mainBot?.id,
    );

    return (
      await Promise.all(
        chatIds
          .filter(id => !!id)
          .map(async chatId => {
            const chatDetails = await this.getChatDetails(chatId);

            chatDetails.active = chatId === selectedChatId;

            return chatDetails;
          }),
      )
    )
      .filter(chat => {
        if (showOnlyChannelsInNewsFeed) {
          if (type === 'newsFeed') {
            return chat.type._ === 'chatTypeSupergroup' && chat.type.isChannel;
          }

          if (type === 'chats') {
            return (
              chat.type._ === 'chatTypeBasicGroup' ||
              (chat.type._ === 'chatTypeSupergroup' && !chat.type.isChannel) ||
              chat.id === mainBot?.id
            );
          }
        }

        return type !== 'chats';
      })
      .sort(this.sortChat);
  }

  async getChatDetails(chatId: number): Promise<ChatModel> {
    const chatDetails = await this.messengerService.api.getChat(chatId);
    if (chatDetails.photo?.small) {
      this.fileReaderService.getFilePart(chatDetails.photo.small).subscribe(filePart => {
        if (!chatDetails.userPhotoPath) {
          chatDetails.userPhotoPath = this.fileReaderService.getFileSource(filePart);
        }
      });
    }

    return chatDetails;
  }

  private sortChat(a: ChatModel, b: ChatModel) {
    const lastMessageDateA = a.lastMessage?.date ?? 0;
    const lastMessageDateB = b.lastMessage?.date ?? 0;

    return lastMessageDateB - lastMessageDateA;
  }
}
