<app-organisation-info-view
  *ngIf="mode === 'view'"
  [data]="data$ | async"
  [opfTypes]="opfTypes$ | async"
  [businessTypes]="businessTypes$ | async"
  [allowSpecialFieldsViewing]="allowSpecialFieldsViewing$ | async"
  [allowSpecialFieldsForAssociationViewing]="allowSpecialFieldsForAssociationViewing$ | async"
  [allowEditing]="allowEditing$ | async"
  [allowDeleting]="allowDeleting$ | async"
  (startEditing)="onStartEditing()"
  (deleted)="onDeleteOrganisation()"
></app-organisation-info-view>

<app-organisation-info-edit
  *ngIf="mode === 'create' || mode === 'edit'"
  [mode]="mode"
  [data]="data$ | async"
  [opfTypes]="opfTypes$ | async"
  [businessTypes]="businessTypes$ | async"
  [allowEditing]="allowEditing$ | async"
  [allowSpecialFieldsEditing]="allowSpecialFieldsEditing$ | async"
  [allowSpecialFieldsForAssociationEditing]="allowSpecialFieldsForAssociationEditing$ | async"
  [loading]="loading$ | async"
  (saved)="onSaveData($event)"
  (canceled)="onCancelEditing()"
></app-organisation-info-edit>
