import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TuiLetModule } from '@taiga-ui/cdk';
import { ComponentsModule } from '@src/app/components';

import { LogoComponent } from './logo.component';

@NgModule({
  declarations: [LogoComponent],
  imports: [CommonModule, TuiLetModule, ComponentsModule],
  exports: [LogoComponent],
})
export class LogoModule {}
