/**
 * Unions API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Данные для входа по email или phone.  Хотябы одно должно быть заполнено см. !:VerifyCodeSendRequestValidator
 */
export interface VerifyCodeSendRequestDto { 
    /**
     * Email пользователя
     */
    email?: string;
    /**
     * Телефон пользователя
     */
    phone?: string;
}

