import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TuiInputModule } from '@taiga-ui/kit';
import { TuiFlagPipeModule, TuiPrimitiveTextfieldModule, TuiTextfieldControllerModule } from '@taiga-ui/core';
import { MaskitoModule } from '@maskito/angular';

import { FormatPhonePipe } from './pipes/format-phone.pipe';
import { InputPhoneComponent } from './components';

@NgModule({
  declarations: [FormatPhonePipe, InputPhoneComponent],
  imports: [
    CommonModule,
    FormsModule,
    MaskitoModule,
    TuiFlagPipeModule,
    TuiInputModule,
    TuiTextfieldControllerModule,
    TuiPrimitiveTextfieldModule,
  ],
  exports: [InputPhoneComponent, FormatPhonePipe],
})
export class PhoneModule {}
