<div class="confirm-dialog">
  <span>{{ text }}</span>

  <div class="confirm-dialog__actions">
    <button tuiButton type="button" [size]="isMobile ? 'm' : 'l'" (click)="confirm()">
      {{ 'common.buttons.yes' | translate }}
    </button>

    <button tuiButton type="button" appearance="secondary" [size]="isMobile ? 'm' : 'l'" (click)="cancel()">
      {{ 'common.buttons.cancel' | translate }}
    </button>
  </div>
</div>
