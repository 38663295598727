import {
  Component,
  ChangeDetectionStrategy,
  Input,
  ViewChild,
  EventEmitter,
  Output,
  ChangeDetectorRef,
} from '@angular/core';
import { Router } from '@angular/router';
import { BreakpointObserver } from '@angular/cdk/layout';
import { TuiHostedDropdownComponent } from '@taiga-ui/core';
import { ResizableBaseComponent } from '@src/app/components/resizable-base-component';
import { BreakpointObserverHelperService, PreviousRouteService } from '@src/core/services';
import { EnvService } from '@src/app/modules/env';

import { LoyaltyProgramStatus } from '../../../models';
import { LoyaltyProgramAction } from '../../../types';
import { LoyaltyProgramPermService } from '../../../services';

import { BreadcrumbItem } from './types';

@Component({
  selector: 'lp-view-header',
  templateUrl: './view-header.component.html',
  styleUrls: ['./view-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ViewHeaderComponent extends ResizableBaseComponent {
  @Input() title?: string;
  @Input() breadcrumbs: BreadcrumbItem[] = [];
  @Input() status?: LoyaltyProgramStatus;
  @Input() isCreatedByUser = false;
  @Input() isExternalProduct = false;
  @Input() useUnionId = false;
  @Output() action = new EventEmitter<LoyaltyProgramAction>();

  @ViewChild(TuiHostedDropdownComponent) component?: TuiHostedDropdownComponent;

  showMenu = false;

  constructor(
    readonly cdr: ChangeDetectorRef,
    readonly breakpointObserver: BreakpointObserver,
    readonly breakpointObserverHelperService: BreakpointObserverHelperService,
    public readonly permService: LoyaltyProgramPermService,
    private readonly env: EnvService,
    private readonly router: Router,
    private readonly previousRouteService: PreviousRouteService,
  ) {
    super(cdr, breakpointObserver, breakpointObserverHelperService);
  }

  onClickBackButton() {
    if (this.env.isCordova) {
      const previousUrl = this.previousRouteService.getPreviousUrl();
      if (previousUrl) {
        this.router.navigateByUrl(previousUrl);
      } else {
        this.router.navigate(['/loyalty']);
      }
    } else if (window.history.length > 1) {
      window.history.go(-1);
    } else {
      this.router.navigate(['/loyalty']);
    }
  }

  onMenuClick(action: LoyaltyProgramAction) {
    this.showMenu = false;
    this.component?.nativeFocusableElement?.focus();

    this.action.emit(action);
  }

  get isPublishDropdownActionVisible() {
    return !this.isExtraLargeScreen && this.allowedPublishing;
  }

  get isEditDropdownActionVisible() {
    return (this.isMobile || this.screenType === 'medium') && this.allowedEditing;
  }

  get isStatisticsDropdownActionVisible() {
    return (this.isMobile || this.screenType === 'medium') && this.allowedAllStatisticsViewing;
  }

  get isUnPublishDropdownActionVisible() {
    return this.status === 'published' && this.allowedUnpublishing;
  }

  get allowedEditing(): boolean {
    return (
      this.isNotCordova &&
      ((this.permService.allowedEditing && !this.isExternalProduct) || this.isCreatedByUser) &&
      this.status !== 'published'
    );
  }

  get allowedDeleting(): boolean {
    return (this.permService.allowedEditing && !this.isExternalProduct) || this.isCreatedByUser;
  }

  get allowedPublishing(): boolean {
    return this.permService.allowedPublishing && this.status !== 'published' && !this.useUnionId;
  }

  get allowedUnpublishing(): boolean {
    return (this.permService.allowedUnpublishing && !this.isExternalProduct) || this.isCreatedByUser;
  }

  get allowedAllStatisticsViewing(): boolean {
    return (
      this.permService.allowedAllStatisticsViewing ||
      (this.permService.allowedOnlyYourProductAllStatisticsViewing && this.isCreatedByUser)
    );
  }

  // TODO: isNotCordova - временное решение для задачи https://redmine.tbnsoft.ru/issues/9437
  get isNotCordova() {
    return !this.env.isCordova;
  }
}
