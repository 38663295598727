import { Component, Input, ChangeDetectionStrategy } from '@angular/core';

import { IconType } from './types';

/**
 * Компонент для отображения иконки (taiga-ui, lucide)
 * @param {string} src - Имя иконки (из taiga-ui или lucide)
 * @param {string} color - Цвет иконки (#RGB)
 * @param {string} size - Размер иконки (px)
 *
 * @todo TODO: Выделить в отдельный модуль и в нем подключать все необходимые иконки lucide (как в SharedModule).
 * После подключить модуль во всем проекте и убрать из SharedModule подключение lucide
 */
@Component({
  selector: 'app-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconComponent {
  @Input() set src(_src: string) {
    if (_src.startsWith('tui')) {
      this.type = 'tuiIcon';
    } else if (_src.startsWith('assets/')) {
      this.type = 'local';
    } else {
      this.type = 'lucideIcon';
    }

    this.value = _src;
  }
  @Input() size: string = '24px';
  @Input() color?: string;

  type: IconType = 'tuiIcon';

  value: string = '';
}
