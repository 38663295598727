import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { PhotoService as ApiPhotoService, PhotosList } from '@src/api';
import { AlertService } from '@src/core/services/alert.service';

@Injectable({
  providedIn: 'root',
})
export class PhotoService {
  constructor(private photoService: ApiPhotoService, private alertService: AlertService) {}

  uploadPhoto(
    photo: File,
    attachId?: string,
    isCover: boolean = false,
    description?: string,
    unionId?: string,
  ): Observable<PhotosList> {
    return this.photoService.uploadFiles([photo], attachId, description, isCover, unionId).pipe(
      catchError(err => {
        this.alertService.error(err, { autoClose: 30000 });
        return throwError(err);
      }),
    );
  }

  uploadFiles(
    photos: File[],
    attachId?: string,
    isCover: boolean = false,
    description?: string,
    unionId?: string,
  ): Observable<PhotosList> {
    return this.photoService.uploadFiles(photos, attachId, description, isCover, unionId).pipe(
      catchError(err => {
        this.alertService.error(err, { autoClose: 30000 });
        return throwError(err);
      }),
    );
  }

  deletePhoto(photoId: string) {
    return this.photoService.deletePhotoById(photoId).pipe(
      catchError(err => {
        this.alertService.error(err, { autoClose: 30000 });
        return throwError(err);
      }),
    );
  }

  downloadPhotoById(id: string) {
    return this.photoService.fullSizeById(id).pipe(
      catchError(err => {
        this.alertService.error(err, { autoClose: 30000 });
        return throwError(err);
      }),
    );
  }
}
