import { OrganisationShortInfoUI } from '@src/app/modules/branding';

import { DownloadLinksComponentOptions } from '../components/download-links/types';

export const convertBrandingDataToDownloadLinks = (data: OrganisationShortInfoUI): DownloadLinksComponentOptions => {
  return {
    logoSrc: data.logoSrc,
    title: data.loginScreenTitle,
    description: data.loginScreenDescription,
    androidDownloadLink: data.googlePlayLink,
    iosDownloadLink: data.appStoreLink,
  };
};
