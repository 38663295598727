import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ResizableBaseComponent } from '@src/app/components';

@Component({
  selector: 'contacts-sort-order',
  templateUrl: './contacts-sort-order.component.html',
  styleUrls: ['./contacts-sort-order.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContactsSortOrderComponent extends ResizableBaseComponent {
  @Input() loading: boolean = false;
}
