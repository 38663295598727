<div class="members">
  <div class="members__header">
    <mat-list *ngIf="!!(unionsUsers.length + telegramUsers.length)">
      <mat-list-item>
        <mat-icon mat-list-icon class="icon">groups</mat-icon>
        <div mat-line>
          <span class="title">
            {{
              (!!memberCount
                ? (memberCount
                  | numeralPlurals
                    : [
                        'components.usersList.labels.memberOne' | translate,
                        'components.usersList.labels.memberFew' | translate,
                        'components.usersList.labels.memberMany' | translate
                      ])
                : unionsUsers || telegramUsers
                ? (unionsUsers.length + telegramUsers.length
                  | numeralPlurals
                    : [
                        'components.usersList.labels.memberOne' | translate,
                        'components.usersList.labels.memberFew' | translate,
                        'components.usersList.labels.memberMany' | translate
                      ])
                : '') +
                (inviteUsers.length
                  ? ' (' +
                    (inviteUsers.length
                      | numeralPlurals
                        : [
                            'components.usersList.labels.invitedMemberOne' | translate,
                            'components.usersList.labels.invitedMemberFew' | translate,
                            'components.usersList.labels.invitedMemberMany' | translate
                          ]) +
                    ')'
                  : '')
            }}
          </span>
          <button
            *ngIf="
              !env.isBot &&
              currentUserChatStatus &&
              (currentUserChatStatus._ === 'chatMemberStatusCreator' ||
                (currentUserChatStatus._ === 'chatMemberStatusRestricted' &&
                  currentUserChatStatus.permissions.canInviteUsers) ||
                (currentUserChatStatus._ === 'chatMemberStatusAdministrator' && currentUserChatStatus.canInviteUsers) ||
                (currentUserChatStatus._ === 'chatMemberStatusMember' && chatPermissions?.canInviteUsers))
            "
            tuiIconButton
            class="icon"
            type="button"
            appearance="icon"
            icon="tuiIconPlusCircleLarge"
            size="s"
            [title]="'common.buttons.add' | translate"
            (click)="openSearchMembersDialog()"
          ></button>
        </div>
      </mat-list-item>
    </mat-list>
  </div>
  <tui-scrollbar class="members__list">
    <tui-loader [inheritColor]="true" [overlay]="true" [showLoader]="!(unionsUsers || telegramUsers) || loading">
      <mat-selection-list
        class="users-list"
        [multiple]="false"
        color="primary"
        (selectionChange)="onSelectedUserChange($event)"
      >
        <!-- Список пользователей ассоциации, которые уже являются участниками чата -->
        <ng-container *ngIf="!!unionsUsers?.length">
          <p class="users-list-group__title">{{ 'components.usersList.labels.title' | translate }}</p>
          <mat-list-option *ngFor="let unionsUser of unionsUsers" class="users-list-item" [value]="unionsUser">
            <div matListIcon class="users-list-item__photo">
              <tui-avatar
                [autoColor]="!unionsUser.photoPath"
                [rounded]="true"
                size="m"
                [avatarUrl]="unionsUser.photoPath ? unionsUser.photoPath : ''"
                [text]="unionsUser | userDisplayableName : 'short'"
              ></tui-avatar>
            </div>
            <div matLine class="users-list-item__title" [title]="unionsUser | userDisplayableName">
              {{ unionsUser | userDisplayableName }}
            </div>
            <div
              matLine
              *ngFor="let organisationJobTitle of unionsUser.organisationJobTitles | jobTitles : true"
              class="users-list-item__subtitle"
              [title]="organisationJobTitle | orgJobTitleDisplayableName"
            >
              {{ organisationJobTitle | orgJobTitleDisplayableName }}
            </div>
            <button
              *ngIf="
                !env.isBot &&
                unionsUser.id !== authUser?.telegramId &&
                currentUserChatStatus &&
                ((currentUserChatStatus._ === 'chatMemberStatusAdministrator' &&
                  currentUserChatStatus.canRestrictMembers) ||
                  currentUserChatStatus._ === 'chatMemberStatusCreator')
              "
              tuiIconButton
              type="button"
              class="button button_delete"
              appearance="custom"
              icon="tuiIconXLarge"
              size="s"
              [title]="'common.buttons.delete' | translate"
              (click)="onClickDeleteMemberButton($event, unionsUser.id)"
            ></button>
          </mat-list-option>
          <p *ngIf="!!isChannel && !!memberCount && memberCount > 200" class="users-list-group__hint">
            {{ 'components.usersList.labels.hint' | translate }}
          </p>
        </ng-container>

        <!-- Список пользователей ассоциации, которым отправлены приглашения -->
        <div *ngIf="inviteUsers?.length" class="users-list-group">
          <p class="users-list-group__title">{{ 'components.usersList.labels.inviteUsers' | translate }}</p>
          <mat-list-option *ngFor="let inviteUser of inviteUsers" class="users-list-item" [value]="inviteUser">
            <div matListIcon class="users-list-item__photo">
              <tui-avatar
                [autoColor]="!inviteUser.photoPath"
                [rounded]="true"
                size="m"
                [avatarUrl]="inviteUser.photoPath ? inviteUser.photoPath : ''"
                [text]="inviteUser | userDisplayableName : 'short'"
              ></tui-avatar>
            </div>
            <div matLine class="users-list-item__title" [title]="inviteUser | userDisplayableName">
              {{ inviteUser | userDisplayableName }}
            </div>
            <div
              matLine
              *ngFor="let organisationJobTitle of inviteUser.organisationJobTitles | jobTitles : true"
              class="users-list-item__subtitle"
              [title]="organisationJobTitle | orgJobTitleDisplayableName"
            >
              {{ organisationJobTitle | orgJobTitleDisplayableName }}
            </div>
            <div
              matLine
              class="users-list-item__status"
              [class.users-list-item__status_red]="inviteUser.inviteStatus === 0"
              [class.users-list-item__status_blue]="inviteUser.inviteStatus === 1"
              [class.users-list-item__status_green]="inviteUser.inviteStatus === 2"
              [class.users-list-item__status_grey]="inviteUser.inviteStatus === 3"
              [title]="inviteUser.inviteStatus | inviteStatus"
            >
              {{ inviteUser.inviteStatus | inviteStatus }}
            </div>
          </mat-list-option>
        </div>

        <!-- Список пользователей telegram, которые не входят в ассоциацию -->
        <div *ngIf="telegramUsers.length" class="users-list-group">
          <p class="users-list-group__title">{{ 'components.usersList.labels.telegramUsers' | translate }}</p>
          <mat-list-option *ngFor="let telegramUser of telegramUsers" class="users-list-item" [value]="telegramUser">
            <div matListIcon class="users-list-item__photo">
              <tui-avatar
                [autoColor]="!telegramUser.photoPath"
                [rounded]="true"
                size="m"
                [avatarUrl]="telegramUser.photoPath ? telegramUser.photoPath : ''"
                [text]="telegramUser | userDisplayableName : 'short'"
              ></tui-avatar>
            </div>
            <div matLine class="users-list-item__title" [title]="telegramUser | userDisplayableName">
              {{ telegramUser | userDisplayableName }}
            </div>
            <div
              *ngIf="
                currentUserChatStatus &&
                ((currentUserChatStatus._ === 'chatMemberStatusAdministrator' &&
                  currentUserChatStatus.canRestrictMembers) ||
                  currentUserChatStatus._ === 'chatMemberStatusCreator')
              "
              class="button button_delete"
            >
              <button
                tuiIconButton
                type="button"
                class="button button_delete"
                appearance="custom"
                icon="tuiIconXLarge"
                size="s"
                [title]="'common.buttons.delete' | translate"
                (click)="onClickDeleteMemberButton($event, telegramUser.id)"
              ></button>
            </div>
          </mat-list-option>
        </div>
      </mat-selection-list>
    </tui-loader>
  </tui-scrollbar>
</div>
