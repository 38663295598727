import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TuiAvatarModule } from '@taiga-ui/kit';
import { SharedModule } from '@src/app/shared';
import { IconModule } from '@src/app/modules/icon';

import { PhotoComponent } from './photo.component';
import { PhotoEditComponent } from './photo-edit/photo-edit.component';
import { PhotoViewComponent } from './photo-view/photo-view.component';
import { PhotoPreviewComponent } from './photo-preview/photo-preview.component';

/**
 * @module PhotoModule
 * @description Модуль для работы (отображение и редактирование) с картинкой объекта
 */
@NgModule({
  declarations: [PhotoComponent, PhotoEditComponent, PhotoViewComponent, PhotoPreviewComponent],
  imports: [CommonModule, IconModule, SharedModule, TuiAvatarModule],
  exports: [PhotoComponent],
})
export class PhotoModule {}
