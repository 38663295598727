<form *ngIf="data" class="report-form" [formGroup]="form" (ngSubmit)="onSubmit()">
  <div class="report-form__title">{{ 'components.reports.labels.report' | translate }}: {{ data.title }}</div>

  <div *ngIf="data.description" class="report-form__description">{{ data.description }}</div>

  <div *ngIf="fieldNames" class="report-form__fields">
    <ng-container *ngFor="let fieldName of fieldNames">
      <ng-container [ngSwitch]="fieldName">
        <tui-input-date *ngSwitchCase="'dateFrom'" class="field" formControlName="dateFrom" tuiTextfieldSize="s">
          Дата начала
          <input tuiTextfield />
        </tui-input-date>

        <tui-input-date *ngSwitchCase="'dateTo'" class="field" formControlName="dateTo" tuiTextfieldSize="s">
          Дата конца
          <input tuiTextfield />
        </tui-input-date>
      </ng-container>
    </ng-container>
  </div>

  <button
    class="report-form__button"
    tuiButton
    type="submit"
    [size]="'s'"
    [appearance]="'primary'"
    [showLoader]="(isLoading$ | async) || false"
  >
    {{ 'common.buttons.download' | translate }}
  </button>
</form>
