import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { UserService } from '@src/core/services';

@Component({
  selector: 'app-association',
  templateUrl: './association.component.html',
  styleUrls: ['./association.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AssociationComponent implements OnInit {
  parentOrganisationId?: string;

  constructor(private userService: UserService) {}

  ngOnInit(): void {
    this.parentOrganisationId = this.userService.authUser$.value?.parentOrganisationId;
  }
}
