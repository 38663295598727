<div *ngIf="!showTitle" class="grade-tag grade-tag__size_{{ size }}" [style.background-color]="backgroundColor">
  <app-svg-icon [path]="icon" [style.background-color]="color"></app-svg-icon>
</div>

<div
  *ngIf="showTitle"
  class="grade-tag-with-title grade-tag-with-title__size_{{ size }}"
  [style.background-color]="backgroundColor"
>
  <app-svg-icon [path]="icon" [style.background-color]="color"></app-svg-icon>

  <span class="grade-tag-with-title__title grade-tag-with-title__title_{{ size }}" [style.color]="color">
    {{ title }}
  </span>
</div>
