import {
  LoyaltyProductGroupBody,
  LoyaltyProductGroupUpdate,
  LoyaltyProductWCategiriesAdd,
  LoyaltyProductWCategiriesEdit,
} from '@src/api';

import { LoyaltyProgramProductInput } from '../../models';
import { QrCodeImageIndexId } from '../types';

interface ConvertFromProductInputProps {
  product: LoyaltyProgramProductInput;
  imagesIds: string[];
  coverImageId?: string;
  qrCodeImages: QrCodeImageIndexId;
}

// TODO: объединить convertFromProductInputAdd и convertFromProductInputEdit как это было раньше. Скорее всего проблема в этих полях - discountGroups.productId и discountGroups.id
export const convertFromProductInputAdd = ({
  product,
  imagesIds,
  coverImageId,
  qrCodeImages,
}: ConvertFromProductInputProps): LoyaltyProductWCategiriesAdd => {
  const { company, persons } = product.contacts;

  return {
    name: product.name,
    description: product.description,
    discountText: product.discountText,
    organisationId: company.id,
    organisationInfo: company.info,
    categoryIds: product.categories.map(item => item.id),
    regionIds: product.regions?.map(item => item.id),
    discountGroups: product.terms.map<LoyaltyProductGroupBody>((term, index) => ({
      id: term.id ?? '',
      discountRate: term.discountType === 'rate' ? Number(term.discount) : undefined,
      discountAbsolute: term.discountType === 'cash' ? Number(term.discount) : undefined,
      discountText: term.discountType === 'tag' ? term.discount.toString() : undefined,
      discountOptions: term.description,
      discountCode: term.promoCode || undefined,
      generateQRCode: term.showAsQRCode ?? false,
      discountQRCodeFileId: qrCodeImages?.[index],
      groupName: term.groupName,
      isPermanentCode: term.isPermanentCode,
      isMultipleCodeUse: !term.isSingleCodeUse,
      maxCodeUsePerMonth: term.isSingleCodeUse ? undefined : term.maxCodeUsePerMonth,
      codesFileId: term.uniqueCodes?.id,
    })),
    contacts: persons,
    photos: imagesIds.map((id, index) => ({ id, sortOrder: index * 10, isCover: id === coverImageId })),
    contactUserId: product.contacts.mentor?.id ?? undefined,
    // @ts-ignore поле не описано в схеме
    contactUserFIO: product.contacts.mentor?.name ?? undefined,
    paidBoostRateId: product.cost?.paidBoostRateId,
    paidDuration: product.cost?.paidDuration,
    paidNewsletterTier1Count: product.cost?.paidNewsletterTier1Count,
    paidNewsletterTier2Count: product.cost?.paidNewsletterTier2Count,
    paidNewsletterTier3Count: product.cost?.paidNewsletterTier3Count,
    requestLeadContacts: product.cost?.requestLeadContacts,
  };
};

export const convertFromProductInputEdit = ({
  product,
  imagesIds,
  coverImageId,
  qrCodeImages,
}: ConvertFromProductInputProps): LoyaltyProductWCategiriesEdit => {
  const { company, persons } = product.contacts;

  return {
    name: product.name,
    description: product.description,
    discountText: product.discountText,
    organisationId: company.id,
    organisationInfo: company.info,
    categoryIds: product.categories.map(item => item.id),
    regionIds: product.regions?.map(item => item.id),
    discountGroups: product.terms.map<LoyaltyProductGroupUpdate>((term, index) => ({
      id: term.id ?? '',
      productId: '',
      discountRate: term.discountType === 'rate' ? Number(term.discount) : undefined,
      discountAbsolute: term.discountType === 'cash' ? Number(term.discount) : undefined,
      discountText: term.discountType === 'tag' ? term.discount.toString() : undefined,
      discountOptions: term.description,
      discountCode: term.promoCode || undefined,
      generateQRCode: term.showAsQRCode ?? false,
      discountQRCodeFileId: qrCodeImages?.[index],
      groupName: term.groupName,
      isPermanentCode: term.isPermanentCode,
      isMultipleCodeUse: !term.isSingleCodeUse,
      maxCodeUsePerMonth: term.isSingleCodeUse ? undefined : term.maxCodeUsePerMonth,
      codesFileId: term.uniqueCodes?.id,
    })),
    contacts: persons,
    photos: imagesIds.map((id, index) => ({ id, sortOrder: index * 10, isCover: id === coverImageId })),
    contactUserId: product.contacts.mentor?.id ?? undefined,
    // @ts-ignore поле не описано в схеме
    contactUserFIO: product.contacts.mentor?.name ?? undefined,
    paidBoostRateId: product.cost?.paidBoostRateId,
    paidDuration: product.cost?.paidDuration,
    paidNewsletterTier1Count: product.cost?.paidNewsletterTier1Count,
    paidNewsletterTier2Count: product.cost?.paidNewsletterTier2Count,
    paidNewsletterTier3Count: product.cost?.paidNewsletterTier3Count,
    requestLeadContacts: product.cost?.requestLeadContacts,
  };
};
