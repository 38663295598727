/**
 * Unions API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { EditQuestion } from './editQuestion';


/**
 * Опрос с подписантами.
 */
export interface PollFullView { 
    /**
     * Название.
     */
    titleText?: string;
    /**
     * Описание.
     */
    descriptionText?: string;
    /**
     * Ссылка на анкету.
     */
    pollLink?: string;
    /**
     * Дата начала опроса.
     */
    dateStart?: string;
    /**
     * Дата окончания опроса.
     */
    dateEnd?: string;
    /**
     * Время прохождения опроса.
     */
    pollDuration?: number;
    /**
     * Создал мероприятие.
     */
    createdBy?: string;
    /**
     * Идентификатор ассоциации.
     */
    organisationId?: string;
    /**
     * Идентификатор комитета.
     */
    committeeId?: string;
    /**
     * текст завершающей речи после прохождения опроса.
     */
    completionText?: string;
    /**
     * опция пошагового прохождения опроса.
     */
    displayStepByStep?: boolean;
    /**
     * ограничение на прохождение опроса в минутах.
     */
    timeLimit?: number;
    /**
     * регистрировать время прохождения опроса.
     */
    registerTime?: boolean;
    /**
     * признак что опрос является шаблоном.
     */
    isTemplate?: boolean;
    /**
     * ID использованного шаблона.
     */
    templateId?: string;
    /**
     * Список вариантов ответов.
     */
    questions?: Array<EditQuestion>;
    /**
     * Идентификатор опроса.
     */
    id?: string;
    /**
     * Статус.
     */
    status?: number;
    /**
     * Список рассылки.
     */
    sendTo?: Array<number>;
    /**
     * ID фотографии.
     */
    photoId?: string;
    /**
     * Идентификатор решения.
     */
    decisionTypeCode?: number;
    /**
     * Решение.
     */
    decisionTypeName?: string;
    /**
     * Признак. Может ли текущий участник опроса принять участие в опросе
     */
    canStartPoll?: boolean;
    /**
     * TRUE - разрешено редактировать опрос; иначе FALSE.
     */
    canEditPollQuestions?: boolean;
}

