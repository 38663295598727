import { ChangeDetectionStrategy, ChangeDetectorRef, Component, TemplateRef, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { BreakpointObserver } from '@angular/cdk/layout';
import { TuiAlertOptions } from '@taiga-ui/core';
import { TuiFileLike } from '@taiga-ui/kit';
import { TuiAlertContext } from '@taiga-ui/cdk';
import { BehaviorSubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { APP_CONFIG } from '@src/core';
import { AlertService, BreakpointObserverHelperService, MailsService } from '@src/core/services';
import { ResizableBaseComponent } from '@src/app/components/resizable-base-component';
import { maxFilesLength } from '@src/utils';
import { TranslateService } from '@ngx-translate/core';

import { MAX_FILE_COUNT } from './constants';
import { FormData, FormDataControls } from './types';

/**
 * Форма обратной связи с технической поддержкой.
 * - subject "Тема" (опционально)
 * - message "Описание"
 * - email "Email отправителя"
 * - files "Документы для вложения" (массив картинок)
 */
@Component({
  selector: 'app-send-email-form',
  templateUrl: './send-email-form.component.html',
  styleUrls: ['./send-email-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SendEmailFormComponent extends ResizableBaseComponent {
  @ViewChild('successTemplate') successTemplate?: TemplateRef<TuiAlertContext<TuiAlertOptions<unknown>>>;

  readonly files = new UntypedFormControl([], [maxFilesLength(MAX_FILE_COUNT)]);

  rejectedFiles: readonly TuiFileLike[] = [];

  acceptFileMask = APP_CONFIG.fileTypes.image.join(',');

  maxFileSize = APP_CONFIG.fileSizeMax.image;

  isLoading$ = new BehaviorSubject<boolean>(false);

  form = new UntypedFormGroup(<FormDataControls>{
    subject: new UntypedFormControl(null),
    message: new UntypedFormControl(null, Validators.required),
    email: new UntypedFormControl(null, [Validators.required, Validators.email]),
    files: this.files,
  });

  constructor(
    readonly cdr: ChangeDetectorRef,
    readonly breakpointObserver: BreakpointObserver,
    readonly breakpointObserverHelperService: BreakpointObserverHelperService,
    private mailsService: MailsService,
    private readonly alertService: AlertService,
    private readonly translateService: TranslateService,
  ) {
    super(cdr, breakpointObserver, breakpointObserverHelperService);
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();

    this.isLoading$.next(false);
    this.isLoading$.complete();
  }

  get isLoading(): boolean {
    return this.isLoading$.value || false;
  }

  onSubmit(): void {
    this.form.markAllAsTouched();

    let errorMessage = '';
    if (this.rejectedFiles.length > 0 || this.form.get('files')?.errors) {
      errorMessage = this.translateService.instant('components.sendEmailForm.alerts.errors.fileSizeAndQuantityMax');
    } else if (this.form.invalid) {
      errorMessage = this.translateService.instant('common.alerts.errors.fillRequired');
    }

    if (errorMessage) {
      this.alertService.error(errorMessage);
      return;
    }

    this.isLoading$.next(true);
    this.form.disable();

    const { subject, message, email, files } = this.form.value as FormData;

    this.mailsService
      .sendSupportEmail(message, email, subject, files)
      .pipe(takeUntil(this.destroyed$$))
      .subscribe({
        next: () => {
          this.alertService.info(this.successTemplate || '');

          this.form.reset();
          this.form.enable();
          this.isLoading$.next(false);
        },
        error: () => {
          this.form.enable();
          this.isLoading$.next(false);
        },
      });
  }

  onReject(files: TuiFileLike | readonly TuiFileLike[]): void {
    this.rejectedFiles = [...this.rejectedFiles, ...(files as TuiFileLike[])];
  }

  removeFile({ name }: File): void {
    if (!this.isLoading) {
      this.files.setValue(this.files.value?.filter((current: File) => current.name !== name) ?? []);
    }
  }

  clearRejected({ name }: TuiFileLike): void {
    if (!this.isLoading) {
      this.rejectedFiles = this.rejectedFiles.filter(rejected => rejected.name !== name);
    }
  }

  clearAllFiles(): void {
    this.files.reset();
    this.rejectedFiles = [];
  }
}
