import { ChangeDetectionStrategy, Component, EventEmitter, Inject, Injector, Input, Output } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { TuiDialogService } from '@taiga-ui/core';
import { PolymorpheusComponent } from '@tinkoff/ng-polymorpheus';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from '@src/core/services';
import { AuthUserService, LogoutService } from '@src/app/modules/auth';

import { DeleteAuthUserDialogComponent } from './components';

@Component({
  selector: 'app-delete-auth-user-link',
  templateUrl: './delete-auth-user-link.component.html',
  styleUrls: ['./delete-auth-user-link.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeleteAuthUserLinkComponent {
  @Input() processing = false;
  @Output() processingChange = new EventEmitter<boolean>();

  private readonly confirmDeleteAuthUserDialog = this.dialogService.open<boolean>(
    new PolymorpheusComponent(DeleteAuthUserDialogComponent, this.injector),
    {
      label: this.translateService.instant('components.deleteAuthUserLink.dialogs.deleteAuthUserHeader'),
      size: 's',
      closeable: false,
    },
  );

  constructor(
    private readonly authUserService: AuthUserService,
    private readonly userService: UserService,
    private readonly logoutService: LogoutService,
    private readonly translateService: TranslateService,
    @Inject(TuiDialogService) private readonly dialogService: TuiDialogService,
    @Inject(Injector) private readonly injector: Injector,
  ) {}

  onClickDeleteButton() {
    const userId = this.authUserService.authUser$.value?.id;
    if (userId) {
      this.confirmDeleteAuthUserDialog.subscribe({
        next: async res => {
          if (res) {
            this.processingChange.emit(true);
            await lastValueFrom(this.userService.deleteUser(userId));
            await this.logoutService.logout();
          }
        },
      });
    }
  }
}
