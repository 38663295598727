import {
  Component,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Input,
  OnChanges,
  SimpleChanges,
  OnDestroy,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { BehaviorSubject, Subject, takeUntil } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { TuiDay } from '@taiga-ui/cdk';
import { AlertService, DocumentService } from '@src/core/services';
import { TuiDayTimeTransformer } from '@src/utils';
import { EnvService } from '@src/app/modules/env';
import { Optional } from '@src/types/utils';

import { GetReportForUnionResultDtoUI } from '../types';
import { ReportsService } from '../services';

import { FormData, FormDataControls } from './types';
import { DATE_FIELD_NAMES } from './constants';

@Component({
  selector: 'report-form',
  templateUrl: './report-form.component.html',
  styleUrls: ['./report-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReportFormComponent implements OnDestroy, OnChanges {
  @Input() data?: GetReportForUnionResultDtoUI;
  fieldNames?: string[];
  form: FormGroup<FormDataControls>;
  isLoading$ = new BehaviorSubject<boolean>(false);

  private destroyed$$: Subject<void> = new Subject<void>();

  constructor(
    private readonly cdr: ChangeDetectorRef,
    private readonly alertService: AlertService,
    private readonly translateService: TranslateService,
    private readonly reportsService: ReportsService,
    private readonly env: EnvService,
    private readonly documentService: DocumentService,
  ) {
    this.form = new FormGroup<FormDataControls>({
      id: new FormControl<Optional<string>>(undefined),
      dateFrom: new FormControl<Optional<TuiDay>>(undefined),
      dateTo: new FormControl<Optional<TuiDay>>(undefined),
    });
  }

  ngOnDestroy(): void {
    this.isLoading$.next(false);
    this.isLoading$.complete();

    this.destroyed$$.next();
    this.destroyed$$.complete();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['data']) {
      if (this.data) {
        this.mapDataToFormData(this.data);
        this.fieldNames = this.getFieldNames(this.data.reportData);
      }
    }

    this.cdr.markForCheck();
  }

  getFieldNames(reportData?: Object) {
    return reportData ? Object.keys(reportData) : undefined;
  }

  onSubmit(): void {
    this.form.markAllAsTouched();

    let errorMessage = '';
    if (this.form.invalid) {
      errorMessage = this.translateService.instant('common.alerts.errors.fillRequired');
    }

    if (errorMessage) {
      this.alertService.error(errorMessage);
      return;
    }

    this.setLoading(true);

    const { id } = this.form.value as FormData;

    const reportData = this.getReportData(this.form.value, this.fieldNames);

    this.reportsService
      .buildReport(id, {
        reportData: {
          ...reportData,
          ...this.data?.hiddenReportData,
        },
      })
      .pipe(takeUntil(this.destroyed$$))
      .subscribe(resultId => {
        this.getReportResult(resultId);
      });
  }

  private getReportResult(resultId: string) {
    this.reportsService
      .getReportResult(resultId, this.env.isBot)
      .pipe(takeUntil(this.destroyed$$))
      .subscribe({
        next: result => {
          if (result) {
            // TODO: название и тип файла брать с бэка
            const fileName = this.data?.title + '_' + new Date().toLocaleString() + '.xlsx';
            this.downloadFile(result, fileName);
            this.setLoading(false);
          } else {
            setTimeout(() => {
              this.getReportResult(resultId);
            }, 2000);
          }
        },
        error: () => {
          this.setLoading(false);
        },
      });
  }

  private mapDataToFormData(data: GetReportForUnionResultDtoUI) {
    this.form.patchValue({ id: data.id });
  }

  private setLoading(value: boolean) {
    this.isLoading$.next(value);
    value ? this.form.disable() : this.form.enable();
  }

  private getReportData(formData: Record<string, any>, fieldNames: string[] = []) {
    const reportData: Record<string, any> = {};
    fieldNames.forEach(fieldName => {
      let value: any = formData[fieldName];
      if (DATE_FIELD_NAMES.includes(fieldName)) {
        value = TuiDayTimeTransformer.tuiDayToDate(value);
      }
      reportData[fieldName] = value;
    });

    return reportData;
  }

  private downloadFile(doc: Blob, fileName: string) {
    if (this.env.isBot) {
      this.env.sendDownloadNotification();
    } else {
      this.documentService.downloadDocument(doc, fileName);
    }
  }
}
