/**
 * Unions API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { NameValuePair } from './nameValuePair';


export interface SearchCommitteesRequestParameters { 
    name?: string;
    businessTypeIds?: Array<string>;
    jobTitleIds?: Array<string>;
    searchForExactMatchName?: boolean;
    contacts?: Array<NameValuePair>;
}

