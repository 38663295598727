import { Directive, ElementRef, EventEmitter, OnInit, Output } from '@angular/core';

@Directive({
  selector: '[appLoaded]',
})
export class LoadedDirective implements OnInit {
  @Output() loaded = new EventEmitter<ElementRef>();

  constructor(private readonly element: ElementRef<any>) {}

  ngOnInit(): void {
    this.loaded.emit(this.element);
  }
}
