import { Injectable } from '@angular/core';
import { Router, CanActivate, CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { SessionService } from '@src/app/modules/auth';
import { logger } from '@src/utils';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate, CanActivateChild {
  constructor(private router: Router, private session: SessionService) {
    logger('AuthGuard constructor');
  }

  private async checkAndRedirect(state: RouterStateSnapshot): Promise<boolean | void> {
    if (this.router.url.indexOf('returnUrl') === -1) {
      return this.router.navigate(['/login'], {
        queryParams: { returnUrl: state.url !== '/' ? state.url : undefined },
      });
    }
  }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    if (!this.session.isLogged()) {
      this.checkAndRedirect(state).then();

      return false;
    }

    return true;
  }

  async canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    return this.canActivate(route, state);
  }
}
