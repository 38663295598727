<div class="organisation">
  <ng-container *ngIf="organisation$ | async as organisation">
    <div class="organisation__header">
      <div class="organisation__logo">
        <tui-avatar
          size="xl"
          [class.tui-skeleton]="loading"
          [class.tui-skeleton_rounded]="loading"
          [autoColor]="!organisation.photoId"
          [rounded]="true"
          [avatarUrl]="organisation.photoId | getImageSrc"
          [text]="organisation?.shortName || ''"
        ></tui-avatar>
      </div>
      <div class="organisation__text-box">
        <h3
          class="organisation__title"
          [title]="organisation.shortName"
          [class.tui-skeleton]="loading"
          [class.tui-skeleton_text]="loading"
        >
          {{ organisation.shortName }}
        </h3>
        <p
          class="organisation__subtitle"
          [title]="organisation.businessTypesComplected"
          [class.tui-skeleton]="loading"
          [class.tui-skeleton_text]="loading"
        >
          {{ organisation.businessTypesComplected }}
        </p>
      </div>
    </div>
    <div class="organisation__list">
      <mat-selection-list class="menu" #menuList [multiple]="false" (selectionChange)="updateActiveItem($event)">
        <ng-container *ngFor="let menuItem of visibleMenuItems; let i = index">
          <mat-list-option
            *ngIf="menuItem.allowViewing$ ? (menuItem.allowViewing$ | async) : true"
            class="menu-item"
            [selected]="activeMenuItem === menuItem.id"
            [value]="menuItem.id"
          >
            <mat-icon
              [hide]="!showFullPage"
              matListIcon
              class="menu-item__icon"
              [class.tui-skeleton]="loading"
              [class.tui-skeleton_rounded]="loading"
            >
              {{ menuItem.icon }}
            </mat-icon>
            <div matLine class="menu-item__title" [class.tui-skeleton]="loading" [class.tui-skeleton_text]="loading">
              {{ menuItem.title }}
            </div>
          </mat-list-option>
        </ng-container>
      </mat-selection-list>
    </div>
    <tui-scrollbar *ngIf="organisationId" class="organisation__content">
      <app-organisation-info
        *ngIf="activeMenuItem === ASSOCIATION_MENU_ITEM.Info && isMenuItemVisible(ASSOCIATION_MENU_ITEM.Info)"
        mode="view"
        [organisationId]="organisationId"
        (deleted)="onDeleteOrganisation()"
      ></app-organisation-info>
      <app-organisation-personal
        *ngIf="activeMenuItem === ASSOCIATION_MENU_ITEM.Users && isMenuItemVisible(ASSOCIATION_MENU_ITEM.Users)"
        [organisationId]="organisationId"
        [selectedUserId]="menuObjectId"
        [title]="getMenuItemTitle(ASSOCIATION_MENU_ITEM.Users)"
        (selectedUserIdChange)="onChangeMenuObjectId($event)"
      ></app-organisation-personal>
      <app-organisation-channels
        *ngIf="activeMenuItem === ASSOCIATION_MENU_ITEM.Channels && isMenuItemVisible(ASSOCIATION_MENU_ITEM.Channels)"
        [organisationId]="organisationId"
        [selectedId]="menuObjectId"
        [title]="getMenuItemTitle(ASSOCIATION_MENU_ITEM.Channels)"
        (selectedIdChange)="onChangeMenuObjectId($event)"
      ></app-organisation-channels>
      <app-organisation-committees
        *ngIf="
          activeMenuItem === ASSOCIATION_MENU_ITEM.Committees && isMenuItemVisible(ASSOCIATION_MENU_ITEM.Committees)
        "
        [organisationId]="organisationId"
      ></app-organisation-committees>
      <app-organisation-configs
        *ngIf="activeMenuItem === ASSOCIATION_MENU_ITEM.Configs && isMenuItemVisible(ASSOCIATION_MENU_ITEM.Configs)"
        [organisationId]="organisationId"
      ></app-organisation-configs>
    </tui-scrollbar>
  </ng-container>
</div>
