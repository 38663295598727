import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { AbstractControl, FormControl, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { BreakpointObserver } from '@angular/cdk/layout';
import { TuiContextWithImplicit, TuiDay, tuiPure, TuiStringHandler } from '@taiga-ui/cdk';
import { AlertService, BreakpointObserverHelperService } from '@src/core/services';
import { APP_CONFIG } from '@src/core';
import { BusinessTypeUI, ContactUI, Multimedia, OrganisationUpsert, TuiFileLikeUI, ViewMode } from '@src/models';
import { OPFType, OrganisationWithInvoiceIdView, convertDateToApiFormat } from '@src/api';
import { DateToTuiDateTimePipe } from '@src/app/shared/pipes';
import { isFileContact } from '@src/utils';
import { ResizableBaseComponent } from '@src/app/components/resizable-base-component';
import { TranslateService } from '@ngx-translate/core';
import { requireContactsValidator } from '@src/app/modules/contacts';

interface FormData {
  fullName: string;
  shortName: string;
  numberOfOrganisations: number;
  opfTypeId: number;
  addressPhysical: string;
  addressLegal: string;
  businessTypes: BusinessTypeUI[];
  membershipPaidTill?: TuiDay;
  card: File;
  contacts: ContactUI[];
  invoice: File;
  oldInvoice?: TuiFileLikeUI;
  oldCard?: TuiFileLikeUI | null;
}

type FormDataControls = { [key in keyof FormData]: AbstractControl };

@Component({
  selector: 'app-organisation-info-edit',
  templateUrl: './organisation-info-edit.component.html',
  styleUrls: ['./organisation-info-edit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrganisationInfoEditComponent extends ResizableBaseComponent implements OnInit, OnChanges {
  @Input() mode: ViewMode = 'view';
  @Input() data?: OrganisationWithInvoiceIdView | null;
  @Input() opfTypes?: OPFType[] | null;
  @Input() businessTypes?: BusinessTypeUI[] | null;
  @Input() allowEditing?: boolean | null = false;
  @Input() allowSpecialFieldsEditing?: boolean | null = false;
  @Input() allowSpecialFieldsForAssociationEditing?: boolean | null = false;
  @Input() loading: boolean | null = true;
  @Output() saved: EventEmitter<OrganisationUpsert> = new EventEmitter<OrganisationUpsert>();
  @Output() canceled: EventEmitter<void> = new EventEmitter<void>();

  infoForm!: UntypedFormGroup;
  newPhoto?: Multimedia;

  readonly maxDocFileSize = APP_CONFIG.fileSizeMax.doc;

  constructor(
    readonly cdr: ChangeDetectorRef,
    readonly breakpointObserver: BreakpointObserver,
    readonly breakpointObserverHelperService: BreakpointObserverHelperService,
    private readonly alertService: AlertService,
    private readonly dateToTuiDateTimePipe: DateToTuiDateTimePipe,
    private readonly translateService: TranslateService,
  ) {
    super(cdr, breakpointObserver, breakpointObserverHelperService);

    this.initForm();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.data) {
      this.loading = true;
      if (this.data) {
        this.initForm();
        const mappedData = this.mapOrganisationViewToFormData(this.data);
        this.infoForm.patchValue(mappedData);

        this.loading = false;
      }
    }

    if (changes.mode) {
      if (this.mode === 'create') {
        this.loading = false;
      }
    }

    this.cdr.markForCheck();
  }

  onClickSaveButton(): void {
    this.infoForm.markAllAsTouched();

    if (this.infoForm.invalid) {
      this.alertService.error(this.translateService.instant('common.alerts.errors.fillRequired'));
      return;
    }

    this.loading = true;

    const organisationData = this.mapFormDataToOrganisationUpsert(this.infoForm.value);

    this.newPhoto = undefined;
    this.saved.emit(organisationData);
  }

  onClickCancelButton(): void {
    this.newPhoto = undefined;
    this.canceled.emit();
  }

  onPhotoChange(newPhoto?: Multimedia): void {
    this.newPhoto = newPhoto;
    this.cdr.markForCheck();
  }

  readonly stringifyBusinessTypes: TuiStringHandler<any | TuiContextWithImplicit<any>> = item => {
    return 'businessTypeName' in item ? item?.businessTypeName : item?.$implicit?.businessTypeName;
  };

  @tuiPure
  opfTypesStringify(items: ReadonlyArray<OPFType>): TuiStringHandler<TuiContextWithImplicit<number>> {
    const map = new Map(items.map(({ id, name }) => [id, name] as [number, string]));

    return ({ $implicit }: TuiContextWithImplicit<number>) => map.get($implicit) || '';
  }

  private initForm(): void {
    this.infoForm = new UntypedFormGroup(<FormDataControls>{
      fullName: new UntypedFormControl('', Validators.required),
      shortName: new UntypedFormControl('', Validators.required),
      numberOfOrganisations: new UntypedFormControl(null),
      opfTypeId: new UntypedFormControl(null),
      addressPhysical: new UntypedFormControl(''),
      addressLegal: new UntypedFormControl(''),
      businessTypes: new UntypedFormControl(null),
      membershipPaidTill: new UntypedFormControl(null),
      card: new UntypedFormControl(null),
      contacts: new FormControl<ContactUI[]>([], { nonNullable: true, validators: requireContactsValidator() }), // TODO: переписать на нормальны тип когда будем избавляться от UntypedFormControl
      invoice: new UntypedFormControl(null),
      oldInvoice: new UntypedFormControl(null),
      oldCard: new UntypedFormControl(null),
    });
  }

  private mapOrganisationViewToFormData(organisation: OrganisationWithInvoiceIdView) {
    const { fullName, shortName, numberOfOrganisations, cardId, contacts, extended, invoiceId } = organisation;

    const businessTypes = this.businessTypes?.filter(
      businessType =>
        organisation.businessTypes &&
        organisation.businessTypes.findIndex(
          organisationBusinessType => organisationBusinessType.id === businessType.businessTypeId,
        ) > -1,
    );

    const membershipPaidTill = this.dateToTuiDateTimePipe.transform(organisation.membershipPaidTill, 'date');

    contacts?.map((contact: ContactUI) => {
      if (isFileContact(contact.contactTypeId) && contact.contact) {
        contact.oldDocument = {
          id: contact.contact,
          name: contact.description ?? this.translateService.instant('components.organisationInfo.labels.file'),
        };
      }

      return contact;
    });

    const oldInvoice = invoiceId
      ? { id: invoiceId, name: this.translateService.instant('common.labels.invoice') }
      : null;

    const oldCard = cardId
      ? { id: cardId, name: this.translateService.instant('components.organisationInfo.fields.companyCard') }
      : null;

    return <FormData>{
      fullName,
      shortName,
      numberOfOrganisations,
      businessTypes,
      membershipPaidTill,
      opfTypeId: extended ? extended.opfTypeId : null,
      addressPhysical: extended ? extended.addressPhysical : null,
      addressLegal: extended ? extended.addressLegal : null,
      contacts: contacts || [],
      oldInvoice,
      oldCard,
    };
  }

  private mapFormDataToOrganisationUpsert(formData: FormData): OrganisationUpsert {
    const {
      fullName,
      shortName,
      numberOfOrganisations,
      opfTypeId,
      addressPhysical,
      addressLegal,
      card,
      invoice,
      oldInvoice,
      oldCard,
    } = formData;

    const businessTypeIDs = formData.businessTypes?.map(businessType => businessType.businessTypeId) as string[];

    const membershipPaidTill = formData.membershipPaidTill
      ? new Date(formData.membershipPaidTill.year, formData.membershipPaidTill.month, formData.membershipPaidTill.day)
      : undefined;

    const contacts = formData.contacts.map(contact => {
      if (!contact.id) {
        delete contact.id;
      }
      return contact;
    });

    return {
      fullName,
      shortName,
      numberOfOrganisations,
      card,
      deleteCard: !oldCard && this.data?.cardId ? this.data.cardId : null,
      invoice,
      deleteInvoice: !oldInvoice && this.data?.invoiceId ? this.data.invoiceId : null,
      businessTypeIDs,
      membershipPaidTill: convertDateToApiFormat(membershipPaidTill),
      contacts,
      photo: this.newPhoto?.file,
      id: this.data?.id,
      parentOrganisation: this.data?.parentOrganisation,
      clusterName: this.data?.clusterName,
      cityId: undefined, // TODO remove after changing (or fixing) API
      extended: {
        opfTypeId,
        addressPhysical,
        addressLegal,
        id: this.data?.extended?.id,
      },
    };
  }
}
