import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, lastValueFrom, Observable, Subject, throwError } from 'rxjs';
import { catchError, map, takeUntil } from 'rxjs/operators';
import { TelegramMessengerService } from '@src/app/modules/telegram';
import { CommitteeUserSubscriptionsService, CommitteeSubscriptionView } from '@src/api';

@Injectable({
  providedIn: 'root',
})
export class SubscriptionsForUsersCommitteeService implements OnDestroy {
  subscriptions$: BehaviorSubject<CommitteeSubscriptionView[] | null>;
  subscriptionsChatIds$: BehaviorSubject<number[]>;
  allChatIds$ = this.messengerService.allChatsIds$;

  private destroyed$$: Subject<void> = new Subject<void>();

  constructor(
    private committeeUserSubscriptionsService: CommitteeUserSubscriptionsService,
    private messengerService: TelegramMessengerService,
  ) {
    this.subscriptions$ = new BehaviorSubject<CommitteeSubscriptionView[] | null>(null);
    this.subscriptionsChatIds$ = new BehaviorSubject<number[]>([]);
    this.subscriptions$.pipe(takeUntil(this.destroyed$$)).subscribe(subscriptions => {
      const chatIds = subscriptions?.map(subscription => subscription.chatId!);
      this.subscriptionsChatIds$.next(chatIds ?? []);
    });
  }

  ngOnDestroy(): void {
    this.destroyed$$.next();
    this.destroyed$$.complete();
  }

  getSubscriptions(callback?: (value: boolean) => void): void {
    callback?.(true);

    this.committeeUserSubscriptionsService
      .committeeUserSubscriptions()
      .pipe(
        catchError(err => {
          // TODO show error notification
          callback?.(false);
          return throwError(err);
        }),
        map(subscriptions => this.sortingSubscriptions(subscriptions)),
        takeUntil(this.destroyed$$),
      )
      .subscribe(subscriptions => {
        this.subscriptions$.next(subscriptions);
        callback?.(false);
      });
  }

  subscriptionsChatIds(): void {
    this.getSubscriptions();
  }

  getSubscriptionsIdsData(): Observable<number[]> {
    return this.committeeUserSubscriptionsService.committeeUserSubscriptions().pipe(
      catchError(err => {
        // TODO show error notification
        return throwError(err);
      }),
      map(
        subscriptions =>
          subscriptions.map(subscription => subscription.chatId).filter(id => id !== undefined) as number[],
      ),
    );
  }

  addSubscriptions(groupsIds: string[]) {
    return this.committeeUserSubscriptionsService.addCommitteeUserSubscriptionsList(groupsIds);
  }

  deleteSubscription(groupId: string) {
    return this.committeeUserSubscriptionsService.deleteCommitteeUserSubscription(groupId);
  }

  async subscribeGroup(groupId: string, chatId: number, inviteLink: string) {
    if (!this.allChatIds$.value.includes(chatId)) {
      try {
        await this.messengerService.api.joinChatByInviteLink(inviteLink);
      } catch (error) {
        throwError(error);
      }
    }

    this.messengerService.addChatId(chatId);

    if (this.allChatIds$.value.includes(chatId)) {
      try {
        await lastValueFrom(this.addSubscriptions([groupId]));
      } catch (error) {
        throwError(error);
      }
    }

    this.getSubscriptions();
  }

  async unsubscribeGroup(groupId: string, chatId: number, userId: number) {
    try {
      await this.messengerService.api.setChatMemberStatus(
        chatId,
        {
          _: 'messageSenderUser',
          userId,
        },
        { _: 'chatMemberStatusLeft' },
      );
    } catch (error) {
      throwError(error);
    }

    this.messengerService.removeChatId(chatId);

    try {
      await lastValueFrom(this.deleteSubscription(groupId));
    } catch (error) {
      throwError(error);
    }

    this.getSubscriptions();
  }

  resetSubscriptions() {
    this.subscriptions$.next(null);
  }

  resetAll() {
    this.resetSubscriptions();
  }

  private sortingSubscriptions(subscriptions: CommitteeSubscriptionView[]): CommitteeSubscriptionView[] {
    subscriptions.sort((subscription1, subscription2) => {
      const subscriptionName1 = subscription1.name?.toLowerCase();
      const subscriptionName2 = subscription2.name?.toLowerCase();

      if (!subscriptionName1) return 1;
      if (!subscriptionName2) return -1;

      if (subscriptionName1 < subscriptionName2) return -1;
      if (subscriptionName1 > subscriptionName2) return 1;
      return 0;
    });

    return subscriptions;
  }
}
