import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import {
  TuiAvatarModule,
  TuiInputModule,
  TuiTextareaModule,
  TuiCheckboxBlockModule,
} from '@taiga-ui/kit';
import {
  TuiButtonModule,
  TuiDataListModule,
  TuiDropdownModule,
  TuiHostedDropdownModule,
  TuiLoaderModule,
  TuiScrollbarModule,
  TuiSvgModule,
  TuiTextfieldControllerModule,
} from '@taiga-ui/core';
import { TuiActiveZoneModule, TuiMediaModule } from '@taiga-ui/cdk';
import { IntersectionObserverModule } from '@ng-web-apis/intersection-observer';
import { SharedModule } from '@src/app/shared';
import { IconModule } from '@src/app/modules/icon';

import { MessageModule } from '../message';

import { MessageListComponent } from './message-list.component';

@NgModule({
  declarations: [MessageListComponent],
  imports: [
    CommonModule,
    FormsModule,
    IconModule,
    IntersectionObserverModule,
    MatButtonModule,
    MatIconModule,
    MessageModule,
    SharedModule,
    TuiActiveZoneModule,
    TuiAvatarModule,
    TuiCheckboxBlockModule,
    TuiDropdownModule,
    TuiInputModule,
    TuiTextareaModule,
    TuiButtonModule,
    TuiDataListModule,
    TuiDropdownModule,
    TuiDropdownModule,
    TuiHostedDropdownModule,
    TuiLoaderModule,
    TuiMediaModule,
    TuiScrollbarModule,
    TuiSvgModule,
    TuiTextfieldControllerModule,
  ],
  exports: [MessageListComponent],
})
export class MessageListModule {}
