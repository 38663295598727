/** Тэги поддерживаемые Telegram - https://core.telegram.org/bots/api#html-style */
const TELEGRAM_TAGS = [
  'b',
  'i',
  'u',
  's',
  'a',
  'em',
  'ins',
  'del',
  'pre',
  'code',
  'strong',
  'strike',
  'blockquote',
  'tg-spoiler',
  'tg-emoji',
];

/**
 * Утилита, которая возвращает текст без html тегов.
 * @param html текст с тегами html
 * @param replaceAll true: Заменить все теги; false: оставить теги поддерживаемые Telegram
 */
export const htmlTagsReplace = (html: string, replaceAll: boolean = true): string => {
  let result = html
    .replace(new RegExp('</p>', 'g'), '\n\n')
    .replace(new RegExp('<br>', 'g'), '\n')
    .replace(new RegExp('<br/>', 'g'), '\n')
    .replace(new RegExp('&nbsp;', 'g'), ' ');

  result = result.replace(new RegExp(getRegString(), 'g'), '');

  if (replaceAll) {
    result = result.replace(new RegExp('</?[^>]+(>|$)', 'g'), '');
  }

  return result;
};

const getRegString = () => {
  return (
    TELEGRAM_TAGS.map(tag => {
      if (tag === 's') {
        return `(?!<${tag}>)(?!<\/${tag}>)`;
      }
      return `(?!<${tag}.*?>)(?!<\/${tag}>)`;
    }).join('') + '<.*?>'
  );
};
