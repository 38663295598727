import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';
import { lastValueFrom } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { EnvService } from '@src/app/modules/env';
import { ResizableBaseComponent } from '@src/app/components/resizable-base-component';
import {
  BreakpointObserverHelperService,
  PhotoService,
  SubscriptionsForOrganisationService,
  SubscriptionsForUsersService,
  UserService,
} from '@src/core/services';
import { GroupUI, MenuItem, UserUI, ViewMode } from '@src/models';
import { ItemAction, ItemType } from '@src/app/shared/list';
import { ObjectId } from '@src/types/id';
import { TranslateService } from '@ngx-translate/core';
import { PermissionService } from '@src/app/modules/organisations/services';

@Component({
  selector: 'app-organisation-channels',
  templateUrl: './organisation-channels.component.html',
  styleUrls: ['./organisation-channels.component.scss', '../styles/organisation-content.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrganisationChannelsComponent extends ResizableBaseComponent implements OnChanges {
  @Input() title?: string;
  @Input() organisationId?: string;
  @Input() selectedId: ObjectId;
  @Output() selectedIdChange = new EventEmitter<ObjectId>();

  groups?: GroupUI[];
  groupsIds?: number[];
  selectedGroup?: GroupUI;
  groupInfoMode: ViewMode = 'view';
  groupActions?: ItemAction[];
  createMenuItems: MenuItem[];
  openMenu: boolean = false;
  addGroupsListVisible: boolean = false;
  isChannel: boolean = true;

  allowEditing$ = this.permissionService.allowGroupEditing$;
  allowSpecialFieldsViewing$ = this.permissionService.allowGroupSpecialFieldsViewing$;
  allowSubscribing$ = this.permissionService.allowGroupSubscribing$;

  loading = false;

  private authUser?: UserUI;

  constructor(
    readonly cdr: ChangeDetectorRef,
    readonly breakpointObserver: BreakpointObserver,
    readonly breakpointObserverHelperService: BreakpointObserverHelperService,
    private subscriptionsForOrganisationService: SubscriptionsForOrganisationService,
    private subscriptionsForUsersService: SubscriptionsForUsersService,
    private userService: UserService,
    private photoService: PhotoService,
    private readonly permissionService: PermissionService,
    private readonly translateService: TranslateService,
    public readonly env: EnvService,
  ) {
    super(cdr, breakpointObserver, breakpointObserverHelperService);

    this.initGroupActions();
    this.createMenuItems = [
      {
        id: 1,
        title: this.translateService.instant('components.organisationChannels.buttons.menuCreateGroup'),
        action: () => this.onClickAddButton(false),
      },
      {
        id: 2,
        title: this.translateService.instant('components.organisationChannels.buttons.menuAttachGroup'),
        action: () => this.onClickAttachButton(false),
      },
      {
        id: 3,
        title: this.translateService.instant('components.organisationChannels.buttons.menuCreateChannel'),
        action: () => this.onClickAddButton(true),
      },
      {
        id: 4,
        title: this.translateService.instant('components.organisationChannels.buttons.menuAttachChannel'),
        action: () => this.onClickAttachButton(true),
      },
    ];
  }

  get showFullPage(): boolean {
    return (
      (!this.selectedGroup && this.groupInfoMode !== 'create' && !this.addGroupsListVisible) ||
      this.isDoubleExtraLargeScreen
    );
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.authUser = this.userService.authUser;

    this.subscriptionsForOrganisationService.subscriptions$
      .pipe(takeUntil(this.destroyed$$))
      .subscribe(subscriptions => {
        this.groups = subscriptions as GroupUI[] | undefined; // TODO: refactoring
        this.groupsIds = this.groups?.map(group => group.chatId).filter(groupId => groupId) as number[];

        const authUserSubscriptions = this.subscriptionsForUsersService.subscriptions$.value;
        this.groups?.map(group => {
          group.active =
            (authUserSubscriptions &&
              authUserSubscriptions.findIndex(subscription => subscription.id === group.id) !== -1) ||
            group.ownerId === this.authUser?.id;
          return group;
        });

        this.selectedGroup = this.groups?.find(group => group.id === this.selectedId);

        this.cdr.markForCheck();
      });

    this.subscriptionsForUsersService.subscriptions$.pipe(takeUntil(this.destroyed$$)).subscribe(() => {
      if (!this.organisationId) return;

      this.subscriptionsForOrganisationService.getSubscriptions(this.organisationId, value => {
        this.loading = value;
        this.cdr.markForCheck();
      });
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.organisationId?.currentValue !== changes.organisationId?.previousValue) {
      this.subscriptionsForOrganisationService.resetSubscriptions();
    }

    if (changes.selectedId?.currentValue) {
      this.onChangeSelectedGroupId(changes.selectedId.currentValue);
    } else {
      this.onClickReturnButton();
    }

    if (changes.organisationId) {
      this.getSubscriptions();
    }
  }

  onClickAddButton(isChannel: boolean): void {
    this.isChannel = isChannel;
    this.changeSelectedId();
    this.selectedGroup = undefined;
    this.addGroupsListVisible = false;
    this.groupInfoMode = 'create';
    this.cdr.detectChanges();
  }

  onClickAttachButton(isChannel: boolean): void {
    this.isChannel = isChannel;
    this.changeSelectedId();
    this.selectedGroup = undefined;
    this.addGroupsListVisible = true;
    this.cdr.detectChanges();
  }

  onCancelEditingGroup(): void {
    this.groupInfoMode = 'view';
  }

  onSaveGroup(data: GroupUI): void {
    this.addGroupsListVisible = false;
    this.groupInfoMode = 'view';

    if (!data.id) {
      this.createSubscription(data);
    } else {
      this.updateSubscription(data);
    }
  }

  onDeletingGroup(): void {
    if (!this.selectedId) return;

    lastValueFrom(this.subscriptionsForOrganisationService.deleteSubscription(this.selectedId.toString())).then(() => {
      this.getSubscriptions();
    });
  }

  changeSelectedId(id?: ObjectId) {
    this.selectedId = id;
    this.selectedIdChange.emit(id);
  }

  onChangeSelectedGroupId(subscriptionId?: ObjectId): void {
    this.addGroupsListVisible = false;
    this.changeSelectedId(subscriptionId);
    this.selectedGroup = this.groups?.find(group => group.id === this.selectedId);
    this.groupInfoMode = 'view';
    this.cdr.detectChanges(); // TODO: not work change edit => view
  }

  onClickSendButton(subscription: GroupUI): void {
    if (!subscription?.codeName || !subscription?.id || !subscription?.chatId) {
      return;
    }

    if (this.env.isBot) {
      this.env.openLink(subscription.codeName);
    } else {
      if (!this.env.isActiveTab()) {
        this.env.viewManyTabsDialog();
        return;
      }

      if (!this.env.isLoggedMessenger()) {
        this.env.viewConfirmLoginMessenger(() => {
          if (subscription.chatId) {
            this.env.gotoChat(subscription.chatId);
          }
        });
        return;
      }

      this.env.gotoChat(subscription.chatId);
    }
  }

  onClickReturnButton(): void {
    this.changeSelectedId();
    this.selectedGroup = undefined;
    this.addGroupsListVisible = false;
    this.cdr.detectChanges();
  }

  onClickCreateMenuItem(menuItem: MenuItem): void {
    this.openMenu = false;

    if (!this.env.isActiveTab()) {
      this.env.viewManyTabsDialog();
      return;
    }

    if (!this.env.isLoggedMessenger()) {
      this.env.viewConfirmLoginMessenger(() => menuItem.action?.());
      return;
    }

    menuItem.action?.();
  }

  attachGroup(group: GroupUI): void {
    this.onSaveGroup(group);
  }

  private async getSubscriptions(): Promise<void> {
    this.subscriptionsForUsersService.getSubscriptions(value => {
      this.loading = value;
      this.cdr.markForCheck();
    });
  }

  private initGroupActions(): void {
    // TODO: add these actions to the list of default actions for groups
    this.groupActions = [
      {
        type: 'send',
        title: this.translateService.instant('common.buttons.goToChat'),
        icon: 'tuiIconMessageSquareLarge',
        isVisible: (item: ItemType) => (item as GroupUI).ownerId === this.authUser?.id || !!(item as GroupUI).active, // TODO: добавить зависимость от this.env.loadingChats$
        clickCallback: (item?: ItemType) => this.onClickSendButton(item as GroupUI),
      },
    ];
  }

  private addCurrentUserToMembers(membersTelegramIds: number[] = []): number[] {
    if (this.authUser?.telegramId) {
      membersTelegramIds = [...new Set(membersTelegramIds.concat([this.authUser.telegramId]))];
    }

    return membersTelegramIds;
  }

  private createSubscription(data: GroupUI): void {
    if (!this.organisationId || !this.authUser?.id) return;

    data.membersTelegramIds = this.addCurrentUserToMembers(data.membersTelegramIds);

    this.subscriptionsForOrganisationService
      .addSubscriptions([
        {
          name: data.name,
          description: data.description,
          chatId: data.chatId,
          chatTypeId: data.chatTypeId ?? (this.isChannel ? 2 : 1),
          organisationId: this.organisationId,
          ownerId: this.authUser.id,
          codeName: data.inviteLink, // TODO: change DB and Backend 'codeName' to 'inviteLink'
          isDefault: data.isDefault,
          sendTo: data.membersTelegramIds,
        },
      ])
      .pipe(takeUntil(this.destroyed$$))
      .subscribe(res => {
        const newSubscriptionId = res?.[0];

        if (data.photo && newSubscriptionId) {
          this.uploadPhoto(data.photo, newSubscriptionId, true);
        } else {
          this.getSubscriptions();
        }

        this.groupInfoMode = 'view';
        this.cdr.markForCheck();
      });
  }

  private updateSubscription(data: GroupUI): void {
    if (!this.organisationId || !this.authUser?.id) return;

    this.subscriptionsForOrganisationService
      .editSubscription({
        id: data.id,
        name: data.name,
        description: data.description,
        chatId: data.chatId,
        chatTypeId: data.chatTypeId,
        organisationId: this.organisationId,
        ownerId: data.ownerId,
        codeName: data.codeName,
        isDefault: data.isDefault,
        sendTo: data.membersTelegramIds,
      })
      .pipe(takeUntil(this.destroyed$$))
      .subscribe(res => {
        const subscriptionId = res.id;
        if (data.photo && subscriptionId) {
          this.uploadPhoto(data.photo, subscriptionId, true);
        } else {
          this.getSubscriptions();
        }

        this.groupInfoMode = 'view';
        this.cdr.markForCheck();
      });
  }

  private uploadPhoto(photo: File, attachId: string, isCover: boolean): void {
    if (!photo) return;
    lastValueFrom(this.photoService.uploadPhoto(photo, attachId, isCover)).then(() => this.getSubscriptions());
  }
}
