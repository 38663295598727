<div *ngIf="!!organisation" class="render-organisation-row">
  <tui-avatar
    size="m"
    [autoColor]="true"
    [rounded]="true"
    [avatarUrl]="organisation.photoId | getImageSrc"
    [text]="organisation.shortName || ''"
  ></tui-avatar>

  <div class="render-organisation-row__info">
    <span class="render-organisation-row__text tui-text_body-m" [title]="organisation.shortName">
      {{ organisation.shortName }}
    </span>

    <span class="render-organisation-row__text tui-text_body-s text_color-grey" [title]="organisation.fullName">
      {{ organisation.fullName }}
    </span>
  </div>
</div>
