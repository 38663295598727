import { marker } from '@biesbjerg/ngx-translate-extract-marker';

import { SendByNotification } from './types';

export const SEND_BY_NOTIFICATIONS: SendByNotification[] = [
  {
    code: 'sendByDefault',
    default: true,
    notShowFor: ['onBirthdaysDaily'],
    title: marker('components.notifications.constants.sendByDefaultTitle'),
    description: marker('components.notifications.constants.sendByDefaultDescription'),
  },
  {
    code: 'sendByEmail',
    title: marker('components.notifications.constants.sendByEmailTitle'),
  },
  {
    code: 'sendByPush',
    notShowFor: ['onNewsletterCreated', 'onLoyaltyNewsletter'],
    title: marker('components.notifications.constants.sendByPushTitle'),
  },
  {
    code: 'sendByBot',
    title: marker('components.notifications.constants.sendByBotTitle'),
  },
];
