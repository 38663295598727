<div class="result">
  <ng-container *ngIf="result === 'published'">
    <div class="result__wrapper">
      <div class="result__icon-wrapper" [style.background-color]="backgroundColor">
        <tui-svg [src]="isMobile ? 'tuiIconCheck' : 'tuiIconCheckLarge'" [style.color]="color"></tui-svg>
      </div>

      <h1>{{ 'components.resultDialog.labels.publishedTitle' | translate }}</h1>
    </div>
  </ng-container>

  <ng-container *ngIf="result === 'unpublished'">
    <div class="result__wrapper">
      <div class="result__icon-wrapper" [style.background-color]="backgroundColor">
        <tui-svg [src]="isMobile ? 'tuiIconCheck' : 'tuiIconCheckLarge'" [style.color]="color"></tui-svg>
      </div>

      <h1>{{ 'components.resultDialog.labels.unpublished' | translate }}</h1>
    </div>
  </ng-container>

  <ng-container *ngIf="result === 'added'">
    <div class="result__wrapper">
      <div class="result__icon-wrapper" [style.background-color]="backgroundColor">
        <tui-svg [src]="isMobile ? 'tuiIconCheck' : 'tuiIconCheckLarge'" [style.color]="color"></tui-svg>
      </div>

      <h1>{{ 'components.resultDialog.labels.added' | translate }}</h1>
    </div>
  </ng-container>

  <ng-container *ngIf="result === 'updated'">
    <div class="result__wrapper">
      <div class="result__icon-wrapper" [style.background-color]="backgroundColor">
        <tui-svg [src]="isMobile ? 'tuiIconCheck' : 'tuiIconCheckLarge'" [style.color]="color"></tui-svg>
      </div>

      <h1>{{ 'components.resultDialog.labels.updated' | translate }}</h1>
    </div>
  </ng-container>

  <ng-container *ngIf="result === 'deleted'">
    <div class="result__wrapper">
      <div class="result__icon-wrapper" [style.background-color]="backgroundColor">
        <tui-svg [src]="isMobile ? 'tuiIconTrash2' : 'tuiIconTrash2Large'" [style.color]="color"></tui-svg>
      </div>

      <h1>{{ 'components.resultDialog.labels.deleted' | translate }}</h1>
    </div>
  </ng-container>

  <button tuiButton type="button" [size]="isMobile ? 'm' : 'l'" (click)="close()">
    {{ 'common.buttons.close' | translate }}
  </button>
</div>
