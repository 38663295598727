import { Directive, ElementRef, Input, OnChanges, SimpleChanges } from '@angular/core';

@Directive({
  selector: '[hide]',
})
export class HiddenDirective implements OnChanges {
  @Input() hide: boolean = false;

  constructor(private readonly element: ElementRef<any>) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hide) {
      this.element.nativeElement.setAttribute('style', this.hide ? 'display: none !important' : '');
    }
  }
}
