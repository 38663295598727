import { Component, EventEmitter, OnInit, Output, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { BehaviorSubject, lastValueFrom } from 'rxjs';
import { AlertService, PhotoService, RoleService, UserService } from '@src/core/services';
import { UserUI } from '@src/models';
import { JobTitleForUser, RoleView } from '@src/api';
import { ObjectId } from '@src/types/id';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-new-association',
  templateUrl: './new-association.component.html',
  styleUrls: ['../styles/administration-content.scss', './new-association.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NewAssociationComponent implements OnInit {
  @Output() canceled: EventEmitter<void> = new EventEmitter();

  newAssociationId: string = '';
  step: 'association' | 'user' = 'association';

  private roles$: BehaviorSubject<RoleView[]> = this.roleService.roles$;

  constructor(
    private cdr: ChangeDetectorRef,
    private roleService: RoleService,
    private photoService: PhotoService,
    private userService: UserService,
    private readonly alertService: AlertService,
    private readonly translateService: TranslateService,
  ) {}

  ngOnInit(): void {
    this.initData();
    this.roleService.getRoles();

    this.cdr.markForCheck();
  }

  onCreatedAssociation(newAssociationId: string) {
    this.step = 'user';
    this.alertService.success(
      this.translateService.instant('components.newAssociation.alerts.successes.createAssociation'),
    );

    if (!newAssociationId || !this.userService.authUser) return;

    this.newAssociationId = newAssociationId;
    const {
      firstName,
      middleName,
      lastName,
      phone,
      email,
      birthDate,
      jobTitleDescription,
      photo,
      organisationJobTitles,
    } = this.userService.authUser;

    const roleId = this.roles$.value.find(role => role.name === 'AdminUO')?.id; // TODO: AdminUO заменить на RoleCode
    if (!roleId) return;

    const newUser: UserUI = {
      firstName,
      middleName,
      lastName,
      phone,
      email,
      birthDate,
      organisations: [
        {
          isDefault: true,
          organisationId: newAssociationId,
          jobTitles: organisationJobTitles?.map(jobTitles => {
            return <JobTitleForUser>{
              id: jobTitles.jobTitleId,
              description: jobTitles.jobTitleDescription,
            };
          }),
          roleIds: [roleId],
        },
      ],
      jobTitleDescription,
      photo,
    };

    this.createCurrentUser(newUser).then(userId => {
      if (!userId) return;

      if (newUser.photo) this.uploadPhoto(newUser.photo, userId, true);
    });
  }

  onCancelCreatingAssociation() {
    this.canceled.emit();
  }

  onCancelCreatingUser() {
    this.initData();
  }

  onCreatedUser(userId: ObjectId) {
    if (!userId) return;

    this.alertService.success(this.translateService.instant('components.newAssociation.alerts.successes.addUser'));
    this.initData();

    this.cdr.markForCheck();
  }

  private async createCurrentUser(data: UserUI): Promise<string | undefined> {
    const newUser = await lastValueFrom(this.userService.createUser(data));
    return newUser.id;
  }

  private uploadPhoto(photo: File, attachId: string, isCover: boolean): void {
    if (!photo) return;

    lastValueFrom(this.photoService.uploadPhoto(photo, attachId, isCover));
  }

  private initData() {
    this.step = 'association';
    this.newAssociationId = '';
  }
}
