import { isValidPhoneNumber } from 'libphonenumber-js/core';
import metadata from 'libphonenumber-js/min/metadata';
import { checkPhoneNumber } from '@src/utils';
import { AbstractControl, ValidatorFn } from '@angular/forms';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';

const customMask = '+888 000 000-00';

export const inputPhoneValidator = (value: string): boolean => {
  return isValidPhoneNumber(value, metadata) || checkPhoneNumber(value, customMask);
};

export const inputPhoneFormValidator = (): ValidatorFn => {
  return ({ value }: AbstractControl) => {
    if (value) {
      return !inputPhoneValidator(value)
        ? {
          // FIXME: не работает в связке с tui-error
          phoneInvalid: marker('common.alerts.errors.phone'),
        }
        : null;
    }

    return null;
  };
};
