/**
 * Unions API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { JobTitleForUser } from './jobTitleForUser';


/**
 * Организация, роли и должности пользователя.
 */
export interface OrganisationRolesJobTitles { 
    /**
     * Идентификатор организации.
     */
    organisationId?: string;
    /**
     * Признак основной организации.
     */
    isDefault?: boolean;
    /**
     * Идентификаторы должностей в организации.
     */
    jobTitles?: Array<JobTitleForUser>;
    /**
     * Идентификаторы ролей в организации.
     */
    roleIds?: Array<string>;
}

