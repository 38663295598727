import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BreakpointObserver } from '@angular/cdk/layout';
import { BehaviorSubject, lastValueFrom } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TelegramMessengerService } from '@src/app/modules/telegram';
import {
  AlertService,
  BreakpointObserverHelperService,
  ChatsService,
  FileReaderService,
  UserService,
} from '@src/core/services';
import { ChatModel, ChatPageMenuIcons, GroupUI, MenuItem, UserUI } from '@src/models';
import { GroupInfoService } from '@src/app/modules/group-info';
import { getImageSrc, pause, trace } from '@src/utils';
import { EnvService } from '@src/app/modules/env';
import { ResizableBaseComponent } from '@src/app/components/resizable-base-component';
import { TelegramChatService } from '@src/app/modules/telegram';
import { ObjectId } from '@src/types/id';
import { TranslateService } from '@ngx-translate/core';
import { APP_CONFIG } from '@src/core';

import { htmlTagsReplace } from '../chat/utils/htmlTagsReplace';

@Component({
  selector: 'telegram-chats',
  templateUrl: './chats.component.html',
  styleUrls: ['./chats.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatsComponent extends ResizableBaseComponent {
  chats?: ChatModel[];
  selectedChatId?: number;
  selectedChat?: ChatModel;
  unionsChatIds?: number[];
  createGroupVisible: boolean = false;
  isChannel: boolean = false;
  openMenu: boolean = false;
  createMenuItems: MenuItem<number, ChatPageMenuIcons>[];
  addGroupsListVisible: boolean = false;
  forwardToChatListVisible: boolean = false;
  searchQuery: string = '';
  searchQuery$: BehaviorSubject<string> = new BehaviorSubject('');
  searchChats?: ChatModel[];
  forwardMessageIds: number[] = [];
  fromChat?: ChatModel;
  loading = false;

  private authUser?: UserUI;
  private users?: UserUI[];
  private isAuthorized = false;

  constructor(
    readonly cdr: ChangeDetectorRef,
    readonly breakpointObserver: BreakpointObserver,
    readonly breakpointObserverHelperService: BreakpointObserverHelperService,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private chatsService: ChatsService,
    private messengerService: TelegramMessengerService,
    private fileReaderService: FileReaderService,
    private userService: UserService,
    private groupInfoService: GroupInfoService,
    public readonly env: EnvService,
    private readonly telegramChatsService: TelegramChatService,
    private readonly alertService: AlertService,
    private readonly translateService: TranslateService,
  ) {
    super(cdr, breakpointObserver, breakpointObserverHelperService);

    this.createMenuItems = [
      {
        id: 1,
        title: this.translateService.instant('components.telegramChats.buttons.menuCreateGroup'),
        icon: 'groups',
        action: () => this.openCreateGroupDialog(false),
      },
      {
        id: 2,
        title: this.translateService.instant('components.telegramChats.buttons.menuAttachGroup'),
        icon: 'groups',
        action: () => this.openAttachGroupDialog(false),
      },
      {
        id: 3,
        title: this.translateService.instant('components.telegramChats.buttons.menuCreateChannel'),
        icon: 'campaign',
        action: () => this.openCreateGroupDialog(true),
      },
      {
        id: 4,
        title: this.translateService.instant('components.telegramChats.buttons.menuAttachChannel'),
        icon: 'campaign',
        action: () => this.openAttachGroupDialog(true),
      },
    ];
  }

  get showFullPage(): boolean {
    return (
      (!this.createGroupVisible &&
        !this.addGroupsListVisible &&
        !this.forwardToChatListVisible &&
        !this.selectedChat) ||
      this.isLargeScreen
    );
  }

  async ngOnInit(): Promise<void> {
    super.ngOnInit();

    if (this.env.isBot) return;

    this.authUser = await this.userService.getAuthorizedUser();

    this.route.paramMap.pipe(takeUntil(this.destroyed$$)).subscribe(params => {
      this.selectedChatId = Number(params.get('chatId'));
      this.selectedChat = this.chats?.find(chat => chat.id === this.selectedChatId);
    });

    this.searchQuery$.pipe(takeUntil(this.destroyed$$)).subscribe(searchQuery => {
      if (searchQuery === '') {
        this.searchChats = undefined;
        return;
      }

      if (!this.users) return;

      const searchUsers = this.userService.getSearchUsers(this.users, searchQuery);
      const searchUsersIds = searchUsers.map(searchUser => searchUser.telegramId);
      if (this.chats) {
        this.searchChats = [
          ...this.chats.filter(
            chat =>
              searchUsersIds.includes(chat.id) ||
              (chat.type._ !== 'chatTypePrivate' && chat.title.toLowerCase().includes(searchQuery.toLowerCase())),
          ),
        ];
      }
    });

    this.messengerService.updates$.pipe(takeUntil(this.destroyed$$)).subscribe(async update => {
      let chatId: number;
      let findChat: ChatModel | undefined;

      switch (update._) {
        case 'updateChatLastMessage':
          const { lastMessage } = update;
          chatId = update.chatId;
          findChat = this.chats?.find(chat => chat.id === chatId);
          if (findChat) {
            findChat.lastMessage = lastMessage;
            this.sortChats();
          }
          break;

        case 'updateChatPosition':
          const { position } = update;
          chatId = update.chatId;
          findChat = this.chats?.find(chat => chat.id === chatId);
          if (findChat) {
            findChat.positions = findChat.positions.map(oldPosition => {
              if (oldPosition.list._ === position.list._) oldPosition.order = position.order;
              return oldPosition;
            });
          }

          break;

        case 'updateChatReadInbox':
          findChat = this.chats?.find(chat => chat.id === update.chatId);
          if (findChat) {
            findChat.unreadCount = update.unreadCount;
            findChat.lastReadInboxMessageId = update.lastReadInboxMessageId;
          }
          break;

        case 'updateChatReadOutbox':
          findChat = this.chats?.find(chat => chat.id === update.chatId);
          if (findChat) {
            findChat.lastReadOutboxMessageId = update.lastReadOutboxMessageId;
          }

          break;

        case 'updateChatAction':
          findChat = this.chats?.find(chat => chat.id === update.chatId);
          if (findChat) {
            switch (update.action._) {
              case 'chatActionCancel':
                findChat.actionStatus = undefined;
                findChat.actionSenderId = undefined;
                break;

              default:
                findChat.actionStatus = update.action;

                if (update.senderId._ === 'messageSenderUser') {
                  findChat.actionSenderId = update.senderId.userId;
                } else if (update.senderId._ === 'messageSenderChat') {
                  findChat.actionSenderId = update.senderId.chatId;
                }

                break;
            }
          }

          break;

        case 'updateChatNotificationSettings':
          findChat = this.chats?.find(chat => chat.id === update.chatId);
          if (findChat) {
            findChat.notificationSettings = update.notificationSettings;
          }
          break;

        case 'updateNewMessage':
          const { message } = update;
          chatId = message.chatId;

          findChat = this.chats?.find(chat => chat.id === chatId);
          if (findChat) {
            const chatDetails = await this.getChatDetails(chatId);
            Object.assign(findChat, chatDetails);
          }
          break;

        case 'updateDeleteMessages':
          chatId = update.chatId;
          const messageIds = update.messageIds;

          findChat = this.chats?.find(chat => chat.id === chatId);
          if (findChat) {
            findChat.messages = findChat.messages?.filter(message => !messageIds.includes(message.id));
          }
          break;

        case 'updateUserStatus':
          findChat = this.chats?.find(chat => chat.id === update.userId);
          if (findChat) {
            findChat.userOnline = update.status._ === 'userStatusOnline';
          }
          break;

        case 'updateChatTitle':
          findChat = this.chats?.find(chat => chat.id === update.chatId);
          if (findChat) {
            findChat.title = update.title;
          }
          break;

        default:
          break;
      }

      if (this.chats) {
        this.chats = [...this.chats];
        if (this.selectedChatId) {
          this.selectedChat = this.chats?.find(chat => chat.id === this.selectedChatId);
        }
      }

      this.cdr.markForCheck();
    });
  }

  onSelectChatIdChange(id?: ObjectId): void {
    this.router.navigate(['chats', id]);

    this.createGroupVisible = false;
    this.addGroupsListVisible = false;
    this.forwardToChatListVisible = false;
    this.selectedChatId = id as number;

    this.selectedChat = undefined;
    setTimeout(() => {
      this.selectedChat = this.chats?.find(chat => chat.id === id);
      this.cdr.markForCheck();
    }, 0);
  }

  onClickCreateMenuItem(menuItem: MenuItem): void {
    this.openMenu = false;
    menuItem.action?.();
  }

  onCancelCreatingGroup(): void {
    this.createGroupVisible = false;
  }

  onCreatedGroup(group: GroupUI): void {
    if (!group.chatId || !group.name) return;

    lastValueFrom(
      this.chatsService.addChat(
        group.chatId,
        group.isChannel ? 2 : 1, // 1 - групповой; 2 - канал
        group.name,
      ),
    )
      .catch(() => {
        this.alertService.error(this.translateService.instant('components.telegramChats.alerts.errors.addChat'));
      })
      .finally(() => {
        this.createGroupVisible = false;
        this.loadChats(true);
      });
  }

  onSubscribeGroup(inviteLink: string) {
    this.messengerService.api.joinChatByInviteLink(inviteLink).then(async () => await this.loadChats());
  }

  leaveChat(chatId: number) {
    if (!this.isAuthorized) {
      return;
    }

    this.messengerService.api.leaveChat(chatId).then(async () => await this.refreshChatList());
  }

  async onDeleteChat(deleteFromTelegram: boolean) {
    if (!this.selectedChatId) return;

    const internalChatId = (await lastValueFrom(this.chatsService.getInternalChatIds([this.selectedChatId])))[0];

    if (deleteFromTelegram) {
      const res = await this.groupInfoService.deleteGroup(this.selectedChatId);
      if (!res) return;
    }

    await lastValueFrom(this.chatsService.deleteChat(internalChatId));
    await this.refreshChatList();
  }

  async attachGroup(group: GroupUI): Promise<void> {
    if (!group.chatId || !group.name) return;

    lastValueFrom(
      this.chatsService.addChat(
        group.chatId,
        group.isChannel ? 2 : 1, // 1 - групповой; 2 - канал
        group.name,
      ),
    )
      .catch(() => {
        this.alertService.error(this.translateService.instant('components.telegramChats.alerts.errors.addChat'));
      })
      .finally(() => {
        this.addGroupsListVisible = false;
        this.loadChats(true);
      });
  }

  async forwardToChat(chat: ChatModel, message?: string) {
    if (this.fromChat) {
      this.forwardToChatListVisible = false;
      this.selectedChat = this.fromChat;
      if (message) {
        const parsedMessage = await this.messengerService.api.parseTextEntities(htmlTagsReplace(message, false), {
          _: 'textParseModeHTML',
        });
        this.messengerService.api.sendMessageText(chat.id, parsedMessage);
      }

      this.messengerService.forwardMessages(chat.id, this.fromChat.id, this.forwardMessageIds);
    }
  }

  onClickReturnButton(): void {
    this.createGroupVisible = false;
    this.addGroupsListVisible = false;
    this.forwardToChatListVisible = false;
    this.forwardMessageIds = [];
    this.selectedChatId = undefined;
    this.selectedChat = undefined;
  }

  onClickReturnToChatButton(): void {
    this.forwardToChatListVisible = false;
    this.forwardMessageIds = [];
  }

  searchQueryChangeHandler(searchQuery: string): void {
    this.searchQuery = searchQuery;
    this.searchQuery$.next(searchQuery);
  }

  gotoTelegram() {
    this.env.close();
  }

  private async refreshChatList() {
    if (!this.isAuthorized) {
      return;
    }

    this.selectedChat = undefined;
    this.selectedChatId = undefined;
    await pause(300);
    await this.loadChats(true);
  }

  onLogged() {
    this.isAuthorized = true;
    this.loadChats();
  }

  async loadChats(refreshAllChatsIds = false): Promise<void> {
    if (!this.isAuthorized) {
      return;
    }

    const loadChatsTrace = trace(`ChatsComponent loadChats [${refreshAllChatsIds}]`);

    this.loading = true;

    try {
      const getAllChatIdsTrace = trace('ChatsComponent getAllChatIds');
      const allChatIds = await this.messengerService.getAllChatIds(refreshAllChatsIds);
      getAllChatIdsTrace(`total chats: ${allChatIds.length}`);

      const allChatIdsExceptYourself = allChatIds.filter(chatId => chatId !== this.authUser?.telegramId);

      // TODO start: непонятно, для чего этот блок?
      const isChatNotExists = !!this.selectedChatId && !allChatIdsExceptYourself.includes(this.selectedChatId);

      if (isChatNotExists) {
        // TODO: после исправления задачи #8007, добавить поиск username по telegramID (метод getUsernameByTelegramId)
        if (this.selectedChatId && this.selectedChatId > 0) {
          const mutualChats = await this.userService.getUserMutualChats(this.selectedChatId);

          if (mutualChats.length) {
            let membersIds: number[] = [];

            for (let i = 0; !membersIds.includes(this.selectedChatId) && i < mutualChats.length; i++) {
              membersIds = [];
              const chat: ChatModel = await this.messengerService.api.getChat(mutualChats[i]);

              if (chat.type._ === 'chatTypeSupergroup') {
                const supergroupFullInfo = await this.messengerService.api.getSupergroupFullInfo(
                  chat.type.supergroupId,
                );
                if (!supergroupFullInfo.canGetMembers) continue;

                const members = await this.messengerService.api
                  .getSupergroupMembers(chat.type.supergroupId)
                  .catch(() => undefined);
                if (!members) continue;

                members.members.forEach(member => {
                  if (member.memberId._ === 'messageSenderUser') {
                    membersIds.push(member.memberId.userId);
                  }
                });

                const limit = APP_CONFIG.loadingLimit.chatMembers;
                if (
                  !membersIds.includes(this.selectedChatId) &&
                  members &&
                  supergroupFullInfo.memberCount &&
                  supergroupFullInfo.memberCount > limit
                ) {
                  for (
                    let j = 0;
                    !membersIds.includes(this.selectedChatId) && j < Math.ceil(supergroupFullInfo.memberCount / limit);
                    j++
                  ) {
                    const updatedSupergroupMembersIds: number[] = [];
                    const updatedSupergroupMembers = await this.messengerService.api.getSupergroupMembers(
                      chat.type.supergroupId,
                      limit * i,
                      limit,
                    );

                    updatedSupergroupMembers.members.forEach(member => {
                      if (member.memberId._ === 'messageSenderUser') {
                        membersIds.push(member.memberId.userId);
                      }
                    });
                    membersIds = [...new Set(membersIds.concat(...updatedSupergroupMembersIds))];
                  }
                }
              } else if (chat.type._ === 'chatTypeBasicGroup') {
                const basicGroup = await this.messengerService.api.getBasicGroupFullInfo(chat.type.basicGroupId);

                basicGroup.members.forEach(member => {
                  if (member.memberId._ === 'messageSenderUser') {
                    membersIds.push(member.memberId.userId);
                  }
                });
              }
            }
          }

          const newChat = await this.messengerService.api.createPrivateChat(this.selectedChatId);
          if (newChat.response._ === 'error') {
            this.selectedChatId = undefined;
          }
        }

        if (this.selectedChatId) {
          allChatIdsExceptYourself.push(this.selectedChatId);
        }
      }
      // TODO end

      const getUsersAndUnionChatIdsTrace = trace('ChatsComponent getUsersAndUnionChatIds');
      const [users = [], unionsChatIds = []] = await Promise.all([
        lastValueFrom(this.userService.getUsersData(allChatIdsExceptYourself)),
        lastValueFrom(this.chatsService.getChatsIdsData(allChatIdsExceptYourself)),
      ]);
      getUsersAndUnionChatIdsTrace();

      this.users = users;

      const unionsUserIds = this.users.reduce<number[]>((acc, user) => {
        if (user.telegramId) {
          acc.push(user.telegramId);
        }
        return acc;
      }, []);

      this.unionsChatIds = [...new Set(unionsChatIds.concat(unionsUserIds))];

      const getChatsDetailsTrace = trace('ChatsComponent getChatsDetails');
      this.chats = await Promise.all(
        this.unionsChatIds.map(async chatId => {
          const chatDetails = await this.getChatDetails(chatId);

          chatDetails.active = this.selectedChatId === chatId;

          return chatDetails;
        }),
      );
      getChatsDetailsTrace();

      const getOtherChatsTrace = trace('ChatsComponent getOtherChats');
      const chats = await this.telegramChatsService.getChats(this.selectedChatId);
      this.chats = this.chats.concat(chats.filter(item => !this.chats?.find(chat => chat.id === item.id)));
      this.selectedChat = this.chats.find(item => item.active);
      getOtherChatsTrace();

      const sortChatsTrace = trace('ChatsComponent sortChats');
      this.sortChats();
      sortChatsTrace();
    } finally {
      this.loading = false;
      this.cdr.markForCheck();

      loadChatsTrace();
    }
  }

  private sortChats(): void {
    this.chats?.sort((a, b) => {
      const lastMessageDateA = a.lastMessage?.date ?? 0;
      const lastMessageDateB = b.lastMessage?.date ?? 0;

      return lastMessageDateB - lastMessageDateA;
    });

    this.cdr.markForCheck();
  }

  private async getChatDetails(chatId: number): Promise<ChatModel> {
    const chatDetails = await this.telegramChatsService.getChatDetails(chatId);

    if (chatDetails.type._ === 'chatTypePrivate') {
      const telegramUser = await this.messengerService.api.getUser(chatId);
      chatDetails.userType = telegramUser.type;
    }

    if (this.users) {
      const userProfile = this.users.find(user => user.telegramId === chatId);
      if (userProfile) {
        chatDetails.title = [userProfile.lastName, userProfile.firstName, userProfile.middleName].join(' ');
        if (userProfile.photoId) {
          chatDetails.userPhotoPath = getImageSrc(userProfile.photoId);
        }
        const defaultOrganisationJobTitle = userProfile.organisationJobTitles?.find(
          jobTitle => jobTitle.organisationId === userProfile.organisationId,
        );
        if (defaultOrganisationJobTitle) {
          chatDetails.organisationJobTitles = [defaultOrganisationJobTitle];
        }
      }
    }

    return chatDetails;
  }

  private openCreateGroupDialog(isChannel: boolean): void {
    this.selectedChatId = undefined;
    this.selectedChat = undefined;
    this.addGroupsListVisible = false;
    this.forwardToChatListVisible = false;
    this.isChannel = isChannel;
    this.createGroupVisible = true;
  }

  private openAttachGroupDialog(isChannel: boolean): void {
    this.selectedChatId = undefined;
    this.selectedChat = undefined;
    this.createGroupVisible = false;
    this.forwardToChatListVisible = false;
    this.isChannel = isChannel;
    this.addGroupsListVisible = true;
    this.cdr.detectChanges();
  }

  openForwardToChatDialog(forwardMessageIds: number[]) {
    this.forwardMessageIds = forwardMessageIds;
    this.fromChat = this.selectedChat;
    this.forwardToChatListVisible = true;
    this.cdr.detectChanges();
  }
}
