<tui-accordion class="accordion-selection">
  <tui-accordion-item class="accordion-selection__list align-items-start padding-small" [open]="open" [borders]="null">
    <div class="header">
      <div class="header__title">
        {{ title }}
      </div>
    </div>

    <div *ngIf="description" class="description">
      {{ description }}
    </div>
    <ng-template tuiAccordionItemContent>
      <ng-container *ngIf="(searchItems ? searchItems.length : items?.length) && !loading; else nodata">
        <div cdkDropList (cdkDropListDropped)="onDropVisibleItems($event)">
          <div *ngFor="let item of searchItems ?? items" class="item" tuiGroup cdkDrag [collapsed]="true">
            <div *cdkDragPlaceholder></div>

            <tui-checkbox-labeled
              class="item__name margin-auto"
              size="m"
              [ngModel]="!!item.isVisible"
              (ngModelChange)="toggleVisibleItem(item)"
            >
              {{ item.name }}
            </tui-checkbox-labeled>

            <button
              *ngIf="actionForAdmin"
              tuiIconButton
              class="item__button"
              [class.item__button_selected]="item.isAdminOnly"
              type="button"
              icon="tuiIconEyeLarge"
              appearance="icon"
              size="s"
              [tuiHint]="'components.selectItems.buttons.adminOnly' | translate"
              (click)="toggleIsAdminOnly(item)"
            ></button>

            <button
              tuiIconButton
              cdkDragHandle
              class="item__button item__button_reorder"
              [class.item__button_hidden]="!reorderButton"
              type="button"
              icon="tuiIconDragLarge"
              appearance="icon"
              size="s"
              [tuiHint]="'components.selectItems.buttons.reorder' | translate"
            ></button>
          </div>
        </div>
      </ng-container>
    </ng-template>
  </tui-accordion-item>
</tui-accordion>

<ng-template #nodata>
  <div class="nodata" [class.tui-skeleton]="loading" [class.tui-skeleton_text]="loading">
    {{ 'components.selectItems.labels.empty' | translate }}
  </div>
</ng-template>
