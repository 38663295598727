<mat-list-option
  class="list-item list-item-committees"
  [value]="data?.id"
  [selected]="!loading && selectedId === data?.id"
>
  <div
    matListIcon
    class="list-item__image list-item__image_large"
    [class.tui-skeleton]="loading"
    [class.tui-skeleton_rounded]="loading"
  >
    <tui-avatar
      size="m"
      [rounded]="true"
      [avatarUrl]="data?.photoId | getImageSrc"
      [text]="data && data.name ? data.name : ''"
    ></tui-avatar>
  </div>
  <div class="list-item__text-box">
    <h3
      class="list-item__title list-item__title_line list-item__title_line_2"
      [title]="data?.name"
      [class.tui-skeleton]="loading"
      [class.tui-skeleton_text]="loading"
    >
      {{ data?.name }}
    </h3>
    <p
      class="list-item__text list-item__text_grey"
      [title]="data?.description"
      [class.tui-skeleton]="loading"
      [class.tui-skeleton_text]="loading"
    >
      {{ data?.description }}
    </p>
    <p
      class="list-item__text list-item__text_grey list-item__text_minimal"
      [title]="data?.parentCommittees | getCommitteePath"
      [class.tui-skeleton]="loading"
      [class.tui-skeleton_text]="loading"
    >
      {{ data?.parentCommittees | getCommitteePath }}
    </p>
  </div>
</mat-list-option>
