<div class="message-list__wrap">
  <tui-scrollbar *ngIf="chat" waIntersectionRoot class="message-list__scrollbar" (scroll)="onScroll()">
    <tui-loader [inheritColor]="true" [showLoader]="loader"></tui-loader>
    <div class="message-list">
      <ng-container *ngIf="sponsoredMessageVisible">
        <app-message
          [message]="sponsoredMessage"
          (sponsoredButtonClicked)="sponsoredButtonClicked($event)"
          (clickUserId)="clickUserId($event)"
          (clickUsername)="clickUsername($event)"
        ></app-message>
      </ng-container>
      <div *ngFor="let messageGroup of chat?.messages | messagesGrouping" class="message-date-group">
        <ng-container *ngFor="let message of messageGroup.messages">
          <app-message
            #intObserverReading="IntersectionObserver"
            waIntersectionObserver
            waIntersectionThreshold="1"
            (waIntersectionObservee)="onReadeMessage($event, message, intObserverReading)"
            (contextmenu)="getMessageViewers(message)"
            tuiDropdownContext
            [tuiDropdown]="contextMenu"
            [message]="message"
            [lastReadOutboxMessageId]="chat.lastReadOutboxMessageId"
            (botCommandClicked)="handleBotCommand($event)"
            (sendBotTextCommand)="sendBotTextCommand($event)"
            (gotoMessage)="scrollToMessage($event)"
            (clickUserId)="clickUserId($event)"
            (clickUsername)="clickUsername($event)"
          ></app-message>

          <ng-template #contextMenu let-close="close" let-activeZone="activeZone">
            <tui-data-list
              tuiDataListDropdownManager
              class="context-menu"
              role="menu"
              [tuiActiveZoneParent]="activeZone"
            >
              <ng-container *ngFor="let item of contextMenuItems">
                <ng-container *ngIf="item.visible(message, item)">
                  <ng-container [ngSwitch]="item.name">
                    <ng-container *ngSwitchCase="'viewers'">
                      <div class="separator"></div>
                      <button
                        tuiOption
                        class="context-menu__item"
                        tuiDropdownAlign="right"
                        [tuiDropdownManual]="userListVisible"
                        [tuiDropdown]="userListTmp"
                        [tuiDropdownSided]="true"
                        (click)="item.action?.(message.id)"
                      >
                        <tui-svg
                          *ngIf="item.iconName.startsWith('tuiIcon'); else lucideIcon"
                          class="icon"
                          [src]="item.iconName"
                        ></tui-svg>
                        <ng-template #lucideIcon>
                          <lucide-icon class="icon" [name]="item.iconName"></lucide-icon>
                        </ng-template>
                        {{
                          item.count!
                            | numeralPlurals
                              : [
                                  'components.messageList.labels.viewOne' | translate,
                                  'components.messageList.labels.viewFew' | translate,
                                  'components.messageList.labels.viewMany' | translate
                                ]
                        }}
                      </button>
                    </ng-container>

                    <button
                      *ngSwitchDefault
                      tuiOption
                      class="context-menu__item"
                      (click)="item.action?.(message.id); close()"
                    >
                      <tui-svg
                        *ngIf="item.iconName.startsWith('tuiIcon'); else lucideIcon"
                        class="icon"
                        [src]="item.iconName"
                      ></tui-svg>
                      <ng-template #lucideIcon>
                        <lucide-icon class="icon" [name]="item.iconName"></lucide-icon>
                      </ng-template>
                      {{ item.title }}
                    </button>

                    <ng-template #userListTmp>
                      <tui-data-list tuiDataListDropdownManager class="context-menu" [tuiActiveZoneParent]="activeZone">
                        <tui-scrollbar class="message-list__scrollbar">
                          <button
                            *ngFor="let user of userList"
                            tuiOption
                            class="context-menu__item"
                            (click)="user.id ? viewUserProfile(user.id) : ''"
                          >
                            <tui-avatar
                              size="xs"
                              [autoColor]="!user.photo"
                              [rounded]="true"
                              [avatarUrl]="user.photo | safe : 'url'"
                              [text]="user.fullName"
                            ></tui-avatar>
                            {{ user.fullName }}
                          </button>
                        </tui-scrollbar>
                      </tui-data-list>
                    </ng-template>
                  </ng-container>
                </ng-container>
              </ng-container>
            </tui-data-list>
          </ng-template>

          <ng-template #confirmDeleteDialogTemplate let-observer>
            <div class="tui-space_bottom-3">
              <tui-checkbox-block
                *ngIf="chat.type._ === 'chatTypePrivate' && message.canBeDeletedForAllUsers"
                style="width: 100%"
                [(ngModel)]="deleteMessagesForAll"
                [size]="tuiElementMediumSize"
              >
                {{ 'components.messageList.labels.deleteChatTypePrivate' | translate }} {{ chat.title }}
              </tui-checkbox-block>
              <tui-checkbox-block
                *ngIf="chat.type._ === 'chatTypeBasicGroup' && message.canBeDeletedForAllUsers"
                [(ngModel)]="deleteMessagesForAll"
                [size]="tuiElementMediumSize"
              >
                {{ 'components.messageList.labels.deleteChatTypeBasicGroupForAll' | translate }}
              </tui-checkbox-block>
              <p *ngIf="chat.type._ === 'chatTypeBasicGroup' && !message.canBeDeletedForAllUsers">
                {{ 'components.messageList.labels.deleteChatTypeBasicGroupForMe' | translate }}
              </p>
              <p *ngIf="chat.type._ === 'chatTypeSupergroup' || chat.type._ === 'chatTypeSecret'">
                {{ 'components.messageList.labels.deleteChatTypeSupergroup' | translate }}
              </p>
            </div>
            <button
              tuiButton
              type="button"
              class="tui-space_right-3"
              [size]="tuiElementMediumSize"
              (click)="applyDelete(observer, deleteMessagesForAll)"
            >
              {{ 'common.buttons.delete' | translate }}
            </button>
            <button
              tuiButton
              type="button"
              appearance="secondary"
              [size]="tuiElementMediumSize"
              (click)="observer.complete()"
            >
              {{ 'common.buttons.cancel' | translate }}
            </button>
          </ng-template>

          <ng-template #confirmPinDialogTemplate let-observer>
            <div class="tui-space_bottom-3">
              <tui-checkbox-block
                *ngIf="chat.type._ === 'chatTypePrivate' && chat.id !== (authUser$ | async)?.telegramId"
                style="width: 100%"
                [(ngModel)]="pinMessageForAll"
                [size]="tuiElementMediumSize"
              >
                {{ 'components.messageList.labels.pinChatTypePrivate' | translate }} {{ chat.title }}
              </tui-checkbox-block>
              <tui-checkbox-block
                *ngIf="chat.type._ === 'chatTypeBasicGroup'"
                [(ngModel)]="notifyPinnedMessageForAll"
                [size]="tuiElementMediumSize"
              >
                {{ 'components.messageList.labels.pinChatTypeBasicGroup' | translate }}
              </tui-checkbox-block>
              <p *ngIf="chat.type._ === 'chatTypeSupergroup' || chat.type._ === 'chatTypeSecret'">
                {{ 'components.messageList.labels.pinChatTypeSupergroup' | translate }}
              </p>
            </div>
            <button
              tuiButton
              type="button"
              class="tui-space_right-3"
              [size]="tuiElementMediumSize"
              (click)="applyPin(observer, pinMessageForAll, notifyPinnedMessageForAll)"
            >
              {{ 'components.messageList.buttons.pin' | translate }}
            </button>
            <button
              tuiButton
              type="button"
              appearance="secondary"
              [size]="tuiElementMediumSize"
              (click)="observer.complete()"
            >
              {{ 'common.buttons.cancel' | translate }}
            </button>
          </ng-template>
        </ng-container>
        <app-message-custom>{{ messageGroup.date }}</app-message-custom>
      </div>
    </div>
  </tui-scrollbar>
  <div class="button-scroll-bottom__wrap" [class.visibled]="scrollToBottomButtonVisible">
    <button
      tuiIconButton
      color="primary"
      class="button-scroll-bottom"
      type="button"
      appearance="primary"
      icon="tuiIconArrowDownLarge"
      shape="rounded"
      size="m"
      [title]="'components.messageList.buttons.scrollBottom' | translate"
      (click)="scrollToBottom()"
    ></button>
  </div>
</div>
