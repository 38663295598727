import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Injector,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { BehaviorSubject, of, Subject } from 'rxjs';
import { filter, switchMap, takeUntil } from 'rxjs/operators';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { TuiDialogService, TuiSizeL, TuiSizeS } from '@taiga-ui/core';
import { PolymorpheusComponent } from '@tinkoff/ng-polymorpheus';
import { ChatMemberStatusUnion, ChatMemberUnion, ChatPermissionsUnion } from '@airgram/web';
import { NgxPermissionsService } from 'ngx-permissions';
import { TranslateService } from '@ngx-translate/core';
import { TelegramMessengerService } from '@src/app/modules/telegram';
import { EnvService } from '@src/app/modules/env';
import { ChatMemberModel, ChatModel, InviteUserUI, ScreenTypes, UserUI } from '@src/models';
import { BreakpointObserverHelperService, InvitesService, UserService } from '@src/core/services';
import { DialogConfirmComponent } from '@src/app/shared/dialogs';
import { getImageSrc } from '@src/utils';
import { InviteView } from '@src/api';
import { SearchUsersService } from '@src/app/modules/search-users';

import { GroupInfoService } from '../group-info';

@Component({
  selector: 'app-users-list',
  templateUrl: './users-list.component.html',
  styleUrls: ['./users-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UsersListComponent implements OnInit, OnChanges, OnDestroy {
  @Input() isChannel?: boolean;
  @Input() memberCount?: number;
  @Input() chatId?: number;
  @Input() organisationId?: string;
  @Input() members?: ChatMemberUnion[];
  @Output() changeMembers: EventEmitter<void>;
  @Output() selectedUserIdChange: EventEmitter<string>;

  membersTelegramIds?: number[];
  newMembers?: UserUI[] | null;
  authUser?: UserUI;
  currentUserChatStatus?: ChatMemberStatusUnion;
  chatPermissions?: ChatPermissionsUnion;
  chat?: ChatModel;
  inviteUsers: InviteUserUI[] = [];
  inviteUsersIds: string[] = [];
  telegramUsers: ChatMemberModel[] = [];
  unionsUsers: ChatMemberModel[] = [];

  loading = false;

  private screenType: ScreenTypes = 'extra-large';
  private destroyed$$: Subject<void> = new Subject<void>();
  private loadChat$: BehaviorSubject<number | null> = new BehaviorSubject<number | null>(null);
  private loadInvitesByChatId$: BehaviorSubject<number | null> = new BehaviorSubject<number | null>(null);
  private readonly confirmDialog = this.dialogService.open<boolean>(
    new PolymorpheusComponent(DialogConfirmComponent, this.injector),
    {
      label: this.translateService.instant('components.usersList.dialogs.memberDeleteHeader'),
      size: 's',
      closeable: false,
    },
  );

  constructor(
    private cdr: ChangeDetectorRef,
    private breakpointObserver: BreakpointObserver,
    private breakpointObserverHelperService: BreakpointObserverHelperService,
    private messengerService: TelegramMessengerService,
    private userService: UserService,
    private groupInfoService: GroupInfoService,
    private invitesService: InvitesService,
    private ngxPermissionsService: NgxPermissionsService,
    public readonly env: EnvService,
    private readonly translateService: TranslateService,
    private readonly searchUsersService: SearchUsersService,
    @Inject(TuiDialogService) private readonly dialogService: TuiDialogService,
    @Inject(Injector) private readonly injector: Injector,
  ) {
    this.changeMembers = new EventEmitter();
    this.selectedUserIdChange = new EventEmitter();
    this.userService.getAuthorizedUser().then(user => {
      this.authUser = user;
      this.getUserChatPermissions();
    });
  }

  get screenTypesLargeSet(): ScreenTypes[] {
    return this.breakpointObserverHelperService.getScreenTypesBiggerThanTarget('large');
  }

  get tuiElementLargeSize(): TuiSizeL {
    return this.screenTypesLargeSet.includes(this.screenType) ? 'l' : 'm';
  }

  get tuiElementMediumSize(): TuiSizeS {
    return this.screenTypesLargeSet.includes(this.screenType) ? 'm' : 's';
  }

  ngOnInit(): void {
    this.breakpointObserver
      .observe(this.breakpointObserverHelperService.breakpointsSet)
      .pipe(takeUntil(this.destroyed$$))
      .subscribe((state: BreakpointState) => {
        this.screenType = this.breakpointObserverHelperService.getScreenType(state);
        this.cdr.markForCheck();
      });

    this.userService
      .getUsersObservable()
      .pipe(takeUntil(this.destroyed$$))
      .subscribe(response => {
        if (!!response) {
          (this.membersTelegramIds || []).map(async memberId => {
            const telegramUser = response.telegramUsers?.find(telegramUser => telegramUser.id === memberId);
            if (!!telegramUser) {
              const unionsUser = (response.users || []).find(unionsUser => unionsUser.telegramId === memberId);
              if (!!unionsUser) {
                telegramUser.lastName = unionsUser.lastName ? unionsUser.lastName : '';
                telegramUser.firstName = unionsUser.firstName ? unionsUser.firstName : '';
                telegramUser.middleName = unionsUser.middleName ? unionsUser.middleName : '';
                if (unionsUser.photoId) {
                  telegramUser.photoPath = getImageSrc(unionsUser.photoId);
                }
                telegramUser.unionsId = unionsUser.id;

                const defaultOrganisationJobTitle = unionsUser.organisationJobTitles?.find(
                  jobTitle => jobTitle.organisationId === unionsUser.organisationId,
                );
                if (defaultOrganisationJobTitle) {
                  telegramUser.organisationJobTitles = [defaultOrganisationJobTitle];
                }

                this.unionsUsers.push(telegramUser);
              } else {
                this.telegramUsers.push(telegramUser);
              }
            }
          });
        }

        this.unionsUsers = this.unionsUsers.filter(unionsUser => !!unionsUser);
        this.unionsUsers = this.sortingMembers(this.unionsUsers) as ChatMemberModel[];

        this.telegramUsers = this.telegramUsers.filter(telegramUser => !!telegramUser);
        this.telegramUsers = this.sortingMembers(this.telegramUsers) as ChatMemberModel[];

        this.cdr.markForCheck();

        this.loadChat$
          .pipe(takeUntil(this.destroyed$$))
          .pipe(filter(chatId => chatId !== null))
          .pipe(
            switchMap(chatId => {
              if (!!chatId) {
                return this.messengerService.api.getChatObservable(chatId);
              } else {
                return of(null);
              }
            }),
          )
          .pipe(
            switchMap(chat => {
              if (!!chat) {
                this.chat = chat;
                this.chatPermissions = chat.permissions;
                return this.messengerService.api.getChatMemberObservable(this.chatId!, {
                  _: 'messageSenderUser',
                  userId: this.authUser!.telegramId,
                });
              } else {
                return of(null);
              }
            }),
          )
          .subscribe(chatMember => {
            this.currentUserChatStatus = chatMember?.status;
            if (!!this.unionsUsers.length) {
              this.getInviteMembers();
            }
            this.cdr.markForCheck();
          });
      });

    let invitesSaved: InviteView[] = [];
    this.loadInvitesByChatId$
      .pipe(takeUntil(this.destroyed$$))
      .pipe(filter(chatId => chatId !== null))
      .pipe(
        switchMap(chatId => {
          invitesSaved = [];
          if (!!chatId) {
            return this.invitesService.getInvitesByChatId(chatId);
          } else {
            return of(null);
          }
        }),
      )
      .pipe(
        switchMap(invites => {
          if (!!invites) {
            invitesSaved = invites;
            this.inviteUsersIds = invites
              .map(inviteMember => inviteMember.userId)
              .filter(id => id !== undefined && !this.unionsUsers.find(user => user.unionsId === id)) as string[];

            return this.userService.getUsersData(this.inviteUsersIds);
          } else {
            return of(null);
          }
        }),
      )
      .subscribe(unionsUsers => {
        const inviteUsers = (unionsUsers as InviteUserUI[]).map(unionsUser => {
          const findInvite = invitesSaved.find(invite => invite.userId === unionsUser.id);
          if (findInvite) {
            unionsUser.inviteStatus = findInvite.inviteStatus;
            if (unionsUser.photoId) {
              unionsUser.photoPath = getImageSrc(unionsUser.photoId);
            }
          }

          return unionsUser;
        });

        this.inviteUsers = this.sortingMembers(inviteUsers) as InviteUserUI[];

        this.cdr.markForCheck();
      });
  }

  getMembersTelegramIds(chatMembers: ChatMemberUnion[]): number[] {
    return chatMembers.map(member => {
      if (member.memberId._ === 'messageSenderUser') {
        return member.memberId.userId;
      }
      if (member.memberId._ === 'messageSenderChat') {
        return member.memberId.chatId;
      }
      return;
    }) as number[];
  }

  async ngOnChanges(changes: SimpleChanges) {
    if (changes.members) {
      if (this.members) {
        this.membersTelegramIds = this.getMembersTelegramIds(this.members);
        const previousMembersTelegramIds = !changes.members.firstChange
          ? this.getMembersTelegramIds(changes.members.previousValue)
          : this.membersTelegramIds;

        if (
          changes.members.firstChange ||
          JSON.stringify(this.membersTelegramIds) !== JSON.stringify(previousMembersTelegramIds)
        ) {
          this.unionsUsers = [];
          this.telegramUsers = [];
          this.userService.loadUsers(this.membersTelegramIds);
        }
      }
    }

    if (changes.chatId) {
      if (changes.chatId.firstChange || changes.chatId.currentValue !== changes.chatId.previousValue) {
        this.getUserChatPermissions();
      }
    }

    this.cdr.markForCheck();
  }

  ngOnDestroy(): void {
    this.destroyed$$.next();
    this.destroyed$$.complete();
    this.userService.loadUsers(null);
    this.loadInvitesByChatId$.next(null);
    this.loadChat$.next(null);
  }

  onSelectedUserChange(listChange: any) {
    const user = listChange.source._value[0];

    if (typeof user.unionsId === 'string' || typeof user.id === 'string') {
      this.selectedUserIdChange.emit(user.unionsId ?? user.id);
    }
  }

  onClickDeleteMemberButton($event: MouseEvent, userId: number) {
    $event.stopPropagation();
    if (!this.chatId || !userId) return;

    this.confirmDialog.pipe(takeUntil(this.destroyed$$)).subscribe({
      next: res => {
        if (res) {
          this.loading = true;
          this.messengerService.api.deleteChatMember(this.chatId!, { _: 'messageSenderUser', userId }).then(() => {
            this.changeMembers.emit();
            this.loading = false;
            this.cdr.markForCheck();
          });

          this.cdr.markForCheck();
        }
      },
    });
  }

  openSearchMembersDialog() {
    const filter = { searchForRegistredOnly: this.organisationId ? undefined : true };

    this.searchUsersService
      .searchMultiple(undefined, undefined, this.inviteUsersIds, this.membersTelegramIds, filter)
      .subscribe(members => {
        if (!this.chat || !members || !members.length) return;
        this.loading = true;

        this.groupInfoService.addMembers(this.chat, members, this.organisationId).then(() => {
          this.getInviteMembers();
          this.changeMembers.emit();
          this.loading = false;
          this.cdr.markForCheck();
        });

        this.cdr.markForCheck();
      });
  }

  private getUserChatPermissions(): void {
    if (!this.chatId || !this.authUser?.telegramId) return;

    this.loadChat$.next(this.chatId);
  }

  private getInviteMembers() {
    const permissions = this.ngxPermissionsService.getPermissions();
    if (
      this.chatId &&
      ('chatInviteMembersViewing' in permissions || this.currentUserChatStatus?._ === 'chatMemberStatusCreator')
    ) {
      this.loadInvitesByChatId$.next(this.chatId);
    }
  }

  private sortingMembers(chatMembers: (ChatMemberModel | InviteUserUI)[]): (ChatMemberModel | InviteUserUI)[] {
    chatMembers.sort((member1, member2) => {
      const memberFullName1 = (
        (member1.lastName ?? '') +
        (member1.firstName ?? '') +
        (member1.middleName ?? '')
      ).toLowerCase();
      const memberFullName2 = (
        (member2.lastName ?? '') +
        (member2.firstName ?? '') +
        (member2.middleName ?? '')
      ).toLowerCase();

      if (!memberFullName1) return 1;
      if (!memberFullName2) return -1;

      if (memberFullName1 < memberFullName2) return -1;
      if (memberFullName1 > memberFullName2) return 1;
      return 0;
    });

    return chatMembers;
  }
}
