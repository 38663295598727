import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@src/app/shared';

import { PinnedMessageListComponent } from './pinned-message-list.component';

@NgModule({
  declarations: [PinnedMessageListComponent],
  imports: [CommonModule, SharedModule],
  exports: [PinnedMessageListComponent],
})
export class PinnedMessageListModule {}
