import { TuiDay, TuiTime } from '@taiga-ui/cdk';
import { AnswerOption, AnswerOptionWithAlternates, EditQuestion, PollAdd, PollWithUserDecisionView } from '@src/api';

import { TuiFileLikeUI } from './index';

export interface PollUI extends PollAdd, PollWithUserDecisionView {
  photo?: File;
  newDocuments?: Array<File>;
  deleteDocuments?: Array<TuiFileLikeUI>;
  active?: boolean;
  notifyEveryone?: boolean;
  questions?: Array<QuestionFormType>;
}

export interface QuestionFormType extends EditQuestion {
  answerType?: AnswerType;
  answers?: Array<AnswerOption>;
  photo?: File;
  altAnswer?: string;
  answerOption?: AnswerOptionWithAlternatesFormType;
  file?: File;
  date?: TuiDay;
  time?: TuiTime;
}

export interface AnswerOptionWithAlternatesFormType extends AnswerOptionWithAlternates {
  id?: string;
  isAlternative?: boolean;
}

export interface AnswerType {
  id: number;
  type: string;
  caption: string;
  icon?: string;
}
