import { Component, ChangeDetectionStrategy, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-with-dictionary-button-wrapper',
  templateUrl: './with-dictionary-button-wrapper.component.html',
  styleUrls: ['./with-dictionary-button-wrapper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WithDictionaryButtonWrapperComponent implements OnInit {
  @Output() showDictionary = new EventEmitter<void>();

  ngOnInit(): void {}
}
