import { Component, OnInit, ChangeDetectionStrategy, Inject } from '@angular/core';
import { POLYMORPHEUS_CONTEXT } from '@tinkoff/ng-polymorpheus';
import { TuiDialogContext } from '@taiga-ui/core';
import { BusinessTypeUI } from 'src/models';

@Component({
  selector: 'app-business-types-dialog',
  templateUrl: './business-types-dialog.component.html',
  styleUrls: ['./business-types-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BusinessTypesDialogComponent implements OnInit {
  constructor(@Inject(POLYMORPHEUS_CONTEXT) private readonly context: TuiDialogContext<BusinessTypeUI[]>) {}

  ngOnInit(): void {}

  onSave($event: BusinessTypeUI[]) {
    this.context.completeWith($event);
  }
}
