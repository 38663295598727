import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { EnvService } from '@src/app/modules/env';

@Component({
  selector: '[waitTelegramChats]',
  templateUrl: './wait-telegram-chats.component.html',
  styleUrls: ['./wait-telegram-chats.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WaitTelegramChatsComponent {
  /** Параметр для отображения контента при загрузке */
  @Input() contentVisible = true;

  loadingChats$ = this.env.loadingChats$;

  constructor(private readonly env: EnvService) {}
}
