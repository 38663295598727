import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { of, switchMap } from 'rxjs';
import { TUI_IS_ANDROID, TUI_IS_IOS } from '@taiga-ui/cdk';
import { TranslateService } from '@ngx-translate/core';
import { AlertService, CordovaService, PreferencesService } from '@src/core/services';
import { AndroidPermissions } from '@src/models';
import {
  TelegramAuthService,
  TelegramWebAppService,
  TelegramChatService,
  TelegramMessengerService,
} from '@src/app/modules/telegram';

import { DownloadFileDefinition, UnionsEnvironment } from '../types';

@Injectable({
  providedIn: 'root',
})
export class EnvService {
  /**
   * Подписка на процесс загрузки всех чатов во внешнем мессенджере.
   */
  loadingChats$ = this.telegramAuthService.isLogged$.pipe(
    switchMap(isLogged => {
      if (!this.telegramAuthService.isActiveTab$.value || isLogged === false) {
        return of(false);
      }

      return this.telegramMessengerService.loadingChats$;
    }),
  );

  private envs: UnionsEnvironment[] = [];

  constructor(
    private readonly router: Router,
    private readonly cordovaService: CordovaService,
    private readonly telegramWebAppService: TelegramWebAppService,
    private readonly telegramAuthService: TelegramAuthService,
    private readonly alertService: AlertService,
    private readonly preferences: PreferencesService,
    private readonly telegramChatService: TelegramChatService,
    private readonly translateService: TranslateService,
    @Inject(TUI_IS_IOS) readonly isIos: boolean,
    @Inject(TUI_IS_ANDROID) readonly isAndroid: boolean,
    private readonly telegramMessengerService: TelegramMessengerService,
  ) {
    if (this.cordovaService.isCordova) {
      this.envs.push('cordova');
    }

    if (this.isAndroid) {
      this.envs.push('android');
    }

    if (this.isIos) {
      this.envs.push('ios');
    }

    if (this.telegramWebAppService.isRunAsTelegramWebApp()) {
      this.envs.push('bot');
    }

    if (!this.envs.length) {
      this.envs.push('default');
    }
  }

  has(env: UnionsEnvironment): boolean {
    return !!this.envs.find(item => item === env);
  }

  and(...envs: UnionsEnvironment[]): boolean {
    return envs.every(env => this.envs.some(item => item === env));
  }

  or(...envs: UnionsEnvironment[]): boolean {
    return envs.some(env => this.envs.some(item => item === env));
  }

  get isMobilePlatform(): boolean {
    return this.or('ios', 'android');
  }

  get isAndroidWithCordova(): boolean {
    return this.and('android', 'cordova');
  }

  get isAndroidWithoutCordova(): boolean {
    return this.has('android') && !this.has('cordova');
  }

  get isIosWithCordova(): boolean {
    return this.and('ios', 'cordova');
  }

  get isIosWithoutCordova(): boolean {
    return this.has('ios') && !this.has('cordova');
  }

  get isBot(): boolean {
    return this.has('bot');
  }

  get isCordova(): boolean {
    return this.has('cordova');
  }

  /**
   * Проверка доступа на запись аудио
   */
  async isAllowedRecordAudio(): Promise<boolean> {
    if (this.isCordova) {
      return await this.cordovaService.requestPermission(AndroidPermissions.RECORD_AUDIO);
    }

    return true;
  }

  /**
   * Переход на ссылку в зависимости от окружения
   * @param url ссылка
   * @param baseUrl флаг, что нужно добавит в самом начале базовую ссылку (для Telegram и Cordova)
   */
  openLink(url: string, baseUrl?: boolean): void {
    if (this.isBot) {
      this.telegramWebAppService.openTelegramLink(url, baseUrl);
      return;
    }

    if (this.isCordova) {
      this.cordovaService.openURL(url, baseUrl);
      return;
    }

    window.open(url, '_blank');
  }

  downloadFile({ src, fileName = '', data }: DownloadFileDefinition): void {
    if ((this.isAndroidWithCordova || this.isIosWithCordova) && data) {
      this.cordovaService.downloadFile(data, fileName);
    } else if (src || data) {
      const linkElement = window.document.createElement('a');

      if (src) {
        linkElement.href = src;
      } else if (data && typeof data !== 'string') {
        linkElement.href = window.URL.createObjectURL(data);
      }

      linkElement.download = fileName;
      linkElement.click();
      this.alertService.success(
        `${this.translateService.instant('common.labels.file')} ${fileName} ${this.translateService.instant(
          'common.labels.uploaded',
        )}`,
      );
    }
  }

  // TODO: непонятно назначение этой функции
  // Перенести в метод выше downloadFile
  // везде где вызывается documentService.downloadDocument(doc) заменить на env.downloadFile(doc)
  sendDownloadNotification(): void {
    if (this.isBot) {
      this.alertService.success(this.translateService.instant('common.alerts.successes.fileUploadToBot'), false);
    }
  }

  /**
   * Проверка авторизации во внешнем мессенджере.
   */
  isLoggedMessenger(): boolean {
    return !!this.telegramAuthService.isLogged();
  }

  /**
   * Проверка загрузки всех чатов во внешнем мессенджере.
   */
  isLoadedAllChatsMessenger(): boolean {
    return !!this.telegramMessengerService.isLoadedAllChats();
  }

  /**
   * Окно оповещения о необходимости авторизации во внешнем мессенджере.
   * @param successCallback Действие, которые выполняется после успешной авторизации во внешнем мессенджере
   */
  viewConfirmLoginMessenger(successCallback?: () => void) {
    this.telegramAuthService.viewConfirmLoginTelegram(successCallback);
  }

  /**
   * Проверка, является ли текущая вкладка активной.
   */
  isActiveTab(): boolean {
    return !!this.telegramAuthService.isActiveTab();
  }

  /**
   * Окно оповещения о том, что открыта другая вкладка.
   */
  viewManyTabsDialog() {
    this.telegramAuthService.viewManyTabsDialog();
  }

  close(): void {
    if (this.isBot) {
      this.telegramWebAppService.close();
    }
  }

  async gotoChat(id: number) {
    const { showOnlyChannelsInNewsFeed } = await this.preferences.refresh();
    const details = await this.telegramChatService.getChatDetails(id);

    let route = '/news-feed';
    if (
      showOnlyChannelsInNewsFeed &&
      ((details.type._ === 'chatTypeSupergroup' && !details.type.isChannel) || details.type._ === 'chatTypeBasicGroup')
    ) {
      route = '/chats';
    }

    return this.router.navigate([route, id]);
  }
}
