/**
 * Unions API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Шаблон рассылок.
 */
export interface NewsletterTemplateView { 
    /**
     * ID ассоциации.
     */
    parentOrganisationId?: string;
    /**
     * Имя шаблона.
     */
    name?: string;
    /**
     * Тело шаблона.
     */
    htmlText?: string;
    /**
     * Тело шаблона в виде файла.
     */
    htmlFileId?: string;
    /**
     * ID записи.
     */
    id?: string;
    /**
     * Дата создания.
     */
    createdDate?: string;
    /**
     * Статус.
     */
    status?: string;
}

