/**
 * Unions API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Редактировать запись справочника категорий продуктов - второй уровень
 */
export interface EditLoyaltyCategory { 
    /**
     * связь с таблицей LoyaltyCategoryGroups
     */
    loyaltyCategoryGroupId?: string;
    /**
     * Наименование категории.
     */
    name: string;
    /**
     * Описание категории.
     */
    description?: string;
    /**
     * ID фотографии/иконки.
     */
    iconId?: string;
    /**
     * Сортировка.
     */
    sortOrder?: number;
    /**
     * ID записи.
     */
    id?: string;
}

