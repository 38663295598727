import { marker } from '@biesbjerg/ngx-translate-extract-marker';

export type FileSizeType = 'B' | 'KB' | 'MB' | 'GB';

export const FILE_SIZE: Record<FileSizeType, string> = {
  B: marker('common.constants.fileSize.b'),
  KB: marker('common.constants.fileSize.kb'),
  MB: marker('common.constants.fileSize.mb'),
  GB: marker('common.constants.fileSize.gb'),
} as const;
