import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {TuiLoaderModule} from '@taiga-ui/core';
import { TranslateModule } from '@ngx-translate/core';

import { LogoutModalComponent } from './ui/logout-modal/logout-modal.component';

@NgModule({
  declarations: [LogoutModalComponent],
  imports: [CommonModule, TuiLoaderModule, TranslateModule],
})
export class AuthModule {}
