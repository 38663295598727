<!-- Блок отображения текста, когда выбрали "Ответить" -->
<div *ngIf="chat?.id && replyToMessageId" class="conversation-box-input__reply-message">
  <app-message-reply [chatId]="chat?.id" [messageId]="replyToMessageId"></app-message-reply>

  <button
    tuiIconButton
    type="button"
    appearance="icon"
    icon="tuiIconXLarge"
    size="s"
    [title]="'common.buttons.delete' | translate"
    (click)="deleteReplyMessage()"
  ></button>
</div>

<!-- Блок отображения текста, когда выбрали "Редактировать" -->
<div *ngIf="chat?.id && editMessageId" class="conversation-box-input__reply-message">
  <app-message-edit [chatId]="chat?.id" [messageId]="editMessageId"></app-message-edit>

  <button
    tuiIconButton
    type="button"
    appearance="icon"
    icon="tuiIconXLarge"
    size="s"
    [title]="'common.buttons.delete' | translate"
    (click)="deleteEditMessage()"
  ></button>
</div>

<div class="conversation-box-input__control">
  <!-- Выбор файла (документа) для вложения в сообщение -->
  <telegram-attachment *ngIf="allowAttachment" (attachmentConfirmed)="sendAttachments($event)"></telegram-attachment>

  <!-- Поле ввода сообщения -->
  <tui-editor
    #textarea
    #parent="tuiActiveZone"
    class="conversation-box-input__control_message textarea"
    tuiDropdownAlign="right"
    [class.textarea__toolbar_hidden]="!isFormatTextToolbarVisible"
    [(ngModel)]="newMessage"
    [pseudoFocus]="focused"
    (keydown)="onKeydown($event)"
    [tuiDropdown]="chatMembersDropdown"
    [tuiDropdownManual]="isChatMembersDropdownVisible"
    [tuiDropdownMaxHeight]="300"
    [tools]="toolbarTools"
    (ngModelChange)="onNewMessageChange()"
    (tuiActiveZoneChange)="onTextareaFocusedChange($event)"
  >
    {{ 'common.fields.writeMessage' | translate }}...
  </tui-editor>

  <div>
    <!-- Запись аудио -->
    <telegram-media-recorder
      *ngIf="!newMessage"
      (recordingSent)="sendVoiceRecording($event)"
      (cancelRecording)="setTextareaFocus()"
    ></telegram-media-recorder>

    <!-- Отправить сообщение -->
    <button
      *ngIf="newMessage && !editMessageId"
      tuiButton
      class="conversation-box-input__control_send"
      type="button"
      appearance="icon"
      aria-label="Отправить"
      size="s"
      (click)="sendMessage()"
    >
      <lucide-icon name="send"></lucide-icon>
    </button>

    <!-- Сохранить отредактированное сообщение -->
    <button
      *ngIf="newMessage && editMessageId"
      tuiIconButton
      class="conversation-box-input__control_edit"
      type="button"
      appearance="icon"
      icon="tuiIconCheckLarge"
      size="s"
      [title]="'common.buttons.save' | translate"
      (click)="editMessage()"
    ></button>
  </div>
</div>

<!-- Выпадающий список пользователей, логин которых хотим подставить в поле сообщения -->
<ng-template #chatMembersDropdown>
  <ng-container *ngIf="isChatMembersDropdownVisible">
    <tui-data-list
      class="select-users-list"
      *ngIf="textarea.nativeFocusableElement"
      (keydown.escape)="textarea.nativeFocusableElement.focus()"
    >
      <button
        tuiOption
        *ngFor="let member of filterChatMembers()"
        class="select-users-list-item"
        [disabled]="!member.username"
        (click)="selectMember(member)"
      >
        <div class="select-users-list-item__photo">
          <tui-avatar
            [autoColor]="!member.photoPath"
            [rounded]="true"
            size="s"
            [avatarUrl]="member.photoPath ?? ''"
            [text]="member | userDisplayableName : 'short'"
          ></tui-avatar>
        </div>

        <div class="select-users-list-item__wrapper">
          <span class="select-users-list-item__title">
            {{ member | userDisplayableName }}
          </span>

          <span
            *ngFor="let organisationJobTitle of member.organisationJobTitles | jobTitles : true"
            class="select-users-list-item__subtitle"
          >
            {{ getMemberDisplayableName(member) }} ({{ organisationJobTitle | orgJobTitleDisplayableName }})
          </span>
        </div>
      </button>
    </tui-data-list>
  </ng-container>
</ng-template>
