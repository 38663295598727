import { Injectable } from '@angular/core';
import { lastValueFrom, Subject } from 'rxjs';
import { AppService } from '@src/api';
import { logger } from '@src/utils';

import { CAPTCHA_CONTAINER_ID, CAPTCHA_SITE_KEY } from '../constants';

@Injectable({
  providedIn: 'root',
})
export class CaptchaService {
  captchaValid$?: Subject<boolean> = new Subject<boolean>();
  private _captchaId?: number;

  constructor(private appService: AppService) {}

  initCaptcha() {
    this._captchaId = this.getCaptchaId();

    if (this._captchaId === undefined) {
      return;
    }

    window.smartCaptcha.execute(this._captchaId);
  }

  private checkCaptcha(token: string) {
    if (typeof token !== 'string' || token.length === 0) {
      // TODO: localization console перевести
      logger('Captcha - Некорректный токен - token:', token);
      this.captchaValid$?.next(false);
      return;
    }

    lastValueFrom(this.appService.checkCapture({ token })).then(res => {
      this.captchaValid$?.next(!!res.isCorrect);
    });
  }

  private getCaptchaId() {
    if (!window.smartCaptcha) {
      return;
    }

    return window.smartCaptcha.render(CAPTCHA_CONTAINER_ID, {
      sitekey: CAPTCHA_SITE_KEY,
      invisible: true,
      test: false,
      hideShield: true,
      callback: (token: string) => this.checkCaptcha(token),
    });
  }
}
