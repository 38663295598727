import {
  ChatUnion,
  ChatActionUnion,
  MessageUnion,
  MessageSenderUnion,
  UserTypeUnion,
  UserUnion,
  ReplyMarkupUnion,
  SponsoredMessageUnion,
} from '@airgram/web';

import { OrganisationJobTitleUI } from './user.model';

export type ChatPageMenuIcons = 'campaign' | 'groups' | 'person';

export interface ChatModel extends ChatUnion {
  active?: boolean;
  userOnline?: boolean;
  userPhotoPath?: string;
  userType?: UserTypeUnion;
  messages?: MessageModel[];
  actionStatus?: ChatActionUnion;
  actionSenderId?: number;
  organisationJobTitles?: OrganisationJobTitleUI[];
}

export interface MessageGroup {
  date: string;
  messages?: MessageModel[];
}

export interface MessageModel extends MessageUnion {
  sendSucceeded?: boolean;
  replyMessage?: MessageModel;
}

// TODO: refactoring
export interface SponsoredMessageUI extends SponsoredMessageUnion {
  id?: number;
  isOutgoing?: boolean;
  replyToMessageId?: number;
  date?: number;
  editDate?: number;
  replyMarkup?: ReplyMarkupUnion;

  senderId?: MessageSenderUnion;
  sendSucceeded?: boolean;
  replyMessage?: MessageModel;
  isPinned?: boolean;
}

export interface ChatMemberModel extends UserUnion {
  telegramId?: number;
  unionsId?: string;
  middleName?: string;
  online?: boolean;
  photoPath?: any;
  actionStatus?: ChatActionUnion;
  actionUserId?: number;
  organisationJobTitles?: OrganisationJobTitleUI[];
  decisionTypeCode?: number;
  decisionTypeName?: string;

  /** Оригинальные имя и фамилия пользователя в Telegram */
  tgFirstName?: string;
  tgLastName?: string;
}
