<div class="logout-modal">
  <h1 class="logout-modal__header">{{ 'components.logoutModal.labels.title' | translate }}</h1>

  <tui-loader
    size="l"
    [showLoader]="true"
    [overlay]="true"
    [textContent]="'components.logoutModal.labels.description' | translate"
  ></tui-loader>
</div>
