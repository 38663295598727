import { OnDestroy, OnInit, Component, ChangeDetectorRef } from '@angular/core';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TuiSizeL, TuiSizeS, TuiSizeXL, TuiSizeXXL } from '@taiga-ui/core';
import { BreakpointObserverHelperService } from '@src/core/services';
import { ScreenDimension, ScreenTypes } from '@src/models';

@Component({
  template: '',
})
export class ResizableBaseComponent implements OnInit, OnDestroy {
  // TODO: refactoring - #11127
  screenType: ScreenTypes = 'extra-large';
  screenDimension: ScreenDimension = 'desktop';

  protected destroyed$$: Subject<void> = new Subject<void>();

  constructor(
    protected cdr: ChangeDetectorRef,
    protected breakpointObserver: BreakpointObserver,
    protected breakpointObserverHelperService: BreakpointObserverHelperService,
  ) {}

  ngOnInit(): void {
    this.breakpointObserver
      .observe(this.breakpointObserverHelperService.breakpointsSet)
      .pipe(takeUntil(this.destroyed$$))
      .subscribe((state: BreakpointState) => {
        this.screenType = this.breakpointObserverHelperService.getScreenType(state);

        this.screenDimension = this.breakpointObserverHelperService
          .getScreenTypesBiggerThanTarget('medium')
          .includes(this.screenType)
          ? 'desktop'
          : 'mobile';

        this.afterChangeScreenType();

        this.cdr.markForCheck();
      });
  }

  ngOnDestroy(): void {
    this.destroyed$$.next();
    this.destroyed$$.complete();
  }

  protected afterChangeScreenType() {
    // TBD
  }

  get screenTypesLargeSet(): ScreenTypes[] {
    return this.breakpointObserverHelperService.getScreenTypesBiggerThanTarget('large');
  }

  get tuiElementSmallSize(): TuiSizeS {
    return this.screenTypesLargeSet.includes(this.screenType) ? 'm' : 's';
  }

  get tuiElementMediumSize(): TuiSizeS {
    return this.screenTypesLargeSet.includes(this.screenType) ? 'm' : 's';
  }

  get tuiElementLargeSize(): TuiSizeL {
    return this.screenTypesLargeSet.includes(this.screenType) ? 'l' : 'm';
  }

  get tuiElementDoubleExtraLargeSize(): TuiSizeXXL | TuiSizeXL {
    return this.isLargeScreen ? 'xxl' : 'xl';
  }

  get isLargeScreen(): boolean {
    return this.breakpointObserverHelperService.getScreenTypesBiggerThanTarget('large').includes(this.screenType);
  }

  get isExtraLargeScreen(): boolean {
    return this.breakpointObserverHelperService.getScreenTypesBiggerThanTarget('extra-large').includes(this.screenType);
  }

  get isDoubleExtraLargeScreen(): boolean {
    return this.breakpointObserverHelperService
      .getScreenTypesBiggerThanTarget('double-extra-large')
      .includes(this.screenType);
  }

  get isMobile() {
    return this.screenDimension === 'mobile';
  }

  get isDesktop() {
    return this.screenDimension === 'desktop';
  }
}
