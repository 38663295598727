/**
 * Unions API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ProductStatistics } from './productStatistics';


/**
 * Статистика для объявлений за период.
 */
export interface LoyaltyStatisticsByPeriod { 
    /**
     * Дата начала выборки статистики.
     */
    dateStart?: string;
    /**
     * Дата конца  выборки статистики.
     */
    dateEnd?: string;
    /**
     * ID объединения.
     */
    parentOrganisationId?: string;
    /**
     * Кол-во продуктов ПЛ.
     */
    productsCount?: number;
    /**
     * Массив статистики по месяцам.
     */
    products?: Array<ProductStatistics>;
}

