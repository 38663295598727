import { Component, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { BreakpointObserver } from '@angular/cdk/layout';
import { Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { BreakpointObserverHelperService } from '@src/core/services';
import { ResizableBaseComponent } from '@src/app/components/resizable-base-component';
import { NotificationEventsOptionsModel } from '@src/api';

import { FormDataControls } from './types';
import { SEND_BY_NOTIFICATIONS } from './constants';
import { NotificationsService } from './notifications.service';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationsComponent extends ResizableBaseComponent {
  loading: boolean = false;
  data?: Array<NotificationEventsOptionsModel> | null = null;
  form?: UntypedFormGroup;
  formSubscription = new Subscription();
  readonly SEND_BY_NOTIFICATIONS = SEND_BY_NOTIFICATIONS;

  constructor(
    readonly cdr: ChangeDetectorRef,
    readonly breakpointObserver: BreakpointObserver,
    readonly breakpointObserverHelperService: BreakpointObserverHelperService,
    private notificationsService: NotificationsService,
  ) {
    super(cdr, breakpointObserver, breakpointObserverHelperService);
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.notificationsService.resetLoadNotifications();
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.notificationsService
      .getNotifications()
      .pipe(takeUntil(this.destroyed$$))
      .subscribe(response => {
        this.data = response;
        this.initForm();
      });

    this.notificationsService
      .saveNotifications()
      .pipe(takeUntil(this.destroyed$$))
      .subscribe(response => {
        if (response !== null) {
          this.notificationsService.loadNotifications();
        }
      });

    this.notificationsService.loadNotifications();
  }

  initForm(): void {
    this.formSubscription.unsubscribe();

    this.form = new UntypedFormGroup(<FormDataControls>{});

    (this.data || []).forEach((notificationGroup: NotificationEventsOptionsModel) => {
      const group: { [key: string]: UntypedFormControl } = {};
      Object.keys(notificationGroup).forEach((notificationGroupKey: string) => {
        // @ts-ignore
        group[notificationGroupKey] = new UntypedFormControl(notificationGroup[notificationGroupKey]);
      });

      this.form?.addControl(notificationGroup.notificationEventCode!, new UntypedFormGroup(group));
      this.form?.updateValueAndValidity({ onlySelf: true, emitEvent: false });

      this.allowSettings(
        this.getFormControl(notificationGroup.notificationEventCode!, 'sendByDefault').value,
        notificationGroup.notificationEventCode!,
      );
    });

    this.formSubscription = this.form.valueChanges.subscribe(() => {
      this.save();
    });

    this.cdr.markForCheck();
  }

  get formGroupsNames(): string[] {
    return Object.keys(this.form?.controls || {});
  }

  getFormControl(formGroupName: string, formControlName: string): UntypedFormControl {
    return this.form?.get(formGroupName)?.get(formControlName) as UntypedFormControl;
  }

  allowSettings(sendByDefault: boolean, group: string): void {
    const forceEnable = SEND_BY_NOTIFICATIONS.find(value => value.default)?.notShowFor?.includes(group);

    if (sendByDefault && !forceEnable) {
      this.getFormControl(group, 'sendByEmail').disable({ onlySelf: true, emitEvent: false });
      this.getFormControl(group, 'sendByPush').disable({ onlySelf: true, emitEvent: false });
      this.getFormControl(group, 'sendByBot').disable({ onlySelf: true, emitEvent: false });

      this.getFormControl(group, 'sendByEmail').setValue(false, { onlySelf: true, emitEvent: false });
      this.getFormControl(group, 'sendByPush').setValue(false, { onlySelf: true, emitEvent: false });
      this.getFormControl(group, 'sendByBot').setValue(false, { onlySelf: true, emitEvent: false });
    } else {
      this.getFormControl(group, 'sendByEmail').enable({ onlySelf: true, emitEvent: false });
      this.getFormControl(group, 'sendByPush').enable({ onlySelf: true, emitEvent: false });
      this.getFormControl(group, 'sendByBot').enable({ onlySelf: true, emitEvent: false });
    }
  }

  save(): void {
    const value = this.form?.getRawValue();
    const request = Object.keys(value).map(group => {
      const groupValue = value[group];

      this.allowSettings(groupValue.sendByDefault, group);

      Object.keys(groupValue).map(dataKey => {
        if (dataKey === 'description' || dataKey === 'title') {
          delete groupValue[dataKey];
        }
      });

      return groupValue;
    });

    this.notificationsService.startSaveNotifications(request);
  }
}
