import {
  Component,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
  ViewChild,
  ChangeDetectorRef,
} from '@angular/core';
import { MatSelectionList, MatSelectionListChange } from '@angular/material/list';
import { ObjectId } from '@src/types/id';

import { ItemAction, ItemType, ListTypes } from './model/item.model';
import { GroupedListItem } from './model/groups.model';
import { getGroups } from './utils/getGroups';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ListComponent implements OnChanges {
  @Input() groupingEnable = false;
  @Input() listType?: ListTypes;
  @Input() items?: ItemType[] | null;
  @Input() count?: number | null;
  @Input() itemActions?: ItemAction[];
  @Input() selectedId: ObjectId;
  @Input() withCounter: boolean = false;
  @Input() loading? = false;
  @ViewChild('selectionList') private selectionList?: MatSelectionList;

  @Output() selectedIdChange: EventEmitter<ObjectId>;
  @Output() searchNextPage: EventEmitter<void> = new EventEmitter<void>();

  groups: GroupedListItem[] = [];

  constructor(private cdr: ChangeDetectorRef) {
    this.selectedIdChange = new EventEmitter<ObjectId>();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedId) {
      if (this.selectedId === undefined) {
        this.selectionList?.deselectAll();
      }
    }

    if (changes.groupingEnable || changes.items) {
      if (this.groupingEnable && this.items && this.listType) {
        this.groups = getGroups(this.items, this.listType);
      }
    }

    this.cdr.markForCheck();
  }

  onSelectionChange(selectionList: MatSelectionListChange): void {
    const selectionOption = selectionList.options[0];
    this.selectedId = selectionOption.value;
    this.selectedIdChange.emit(this.selectedId);
  }

  onIntersection(intersection: IntersectionObserverEntry[]) {
    if (intersection[0].isIntersecting) {
      this.searchNextPage.emit();
    }
  }
}
