export enum StorageKeys {
  Token = 'token',
  RefreshToken = 'refreshToken',
  CodeName = 'codeName',
  EnterType = 'enterType',
  MainBotName = 'mainBotName',
  Locale = 'locale',
  Theme = 'theme',
  OpenPages = 'openpages',
}
