/**
 * Unions API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * ID контакта.
 */
export interface ContactLoyaltyView { 
    /**
     * Адрес контакта.
     */
    address: string;
    /**
     * Контактное лицо.
     */
    contactName?: string;
    /**
     * Номер телефона контакта.
     */
    contactPhone?: string;
    /**
     * Email контакта.
     */
    contactEmail?: string;
    /**
     * ID котакта.
     */
    id: string;
}

