import { Component, ChangeDetectionStrategy, OnInit, ChangeDetectorRef, Inject } from '@angular/core';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TuiDialogContext, TuiSizeS } from '@taiga-ui/core';
import { POLYMORPHEUS_CONTEXT } from '@tinkoff/ng-polymorpheus';
import { ScreenTypes } from '@src/models';
import { BreakpointObserverHelperService } from '@src/core/services';

@Component({
  selector: 'app-dialog-confirm',
  templateUrl: './dialog-confirm.component.html',
  styleUrls: ['./dialog-confirm.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DialogConfirmComponent implements OnInit {
  private screenType: ScreenTypes = 'extra-large';
  private destroyed$$: Subject<void> = new Subject<void>();

  constructor(
    private cdr: ChangeDetectorRef,
    private breakpointObserver: BreakpointObserver,
    private breakpointObserverHelperService: BreakpointObserverHelperService,
    @Inject(POLYMORPHEUS_CONTEXT) readonly context: TuiDialogContext<boolean>,
  ) {}

  get screenTypesLargeSet(): ScreenTypes[] {
    return this.breakpointObserverHelperService.getScreenTypesBiggerThanTarget('large');
  }

  get tuiElementMediumSize(): TuiSizeS {
    return this.screenTypesLargeSet.includes(this.screenType) ? 'm' : 's';
  }

  ngOnInit(): void {
    this.breakpointObserver
      .observe(this.breakpointObserverHelperService.breakpointsSet)
      .pipe(takeUntil(this.destroyed$$))
      .subscribe((state: BreakpointState) => {
        this.screenType = this.breakpointObserverHelperService.getScreenType(state);
        this.cdr.markForCheck();
      });
  }

  apply() {
    this.context.completeWith(true);
  }

  cancel() {
    this.context.completeWith(false);
  }
}
