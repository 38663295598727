import { Component, ChangeDetectionStrategy, Input, ChangeDetectorRef } from '@angular/core';
import { MessageContentUnion } from '@airgram/core/types/outputs';
import { MessageText, WebPage } from '@airgram/web';

@Component({
  selector: 'app-message-text',
  templateUrl: './message-text.component.html',
  styleUrls: ['../styles/message-content.scss', './message-text.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MessageTextComponent {
  @Input() set message(mess: MessageContentUnion | undefined) {
    this.messageText = mess as MessageText;
    this.webPage = mess?._ === 'messageText' ? mess.webPage : undefined;
    this.cdr.markForCheck();
  }
  messageText?: MessageText;
  webPage?: WebPage;

  constructor(private cdr: ChangeDetectorRef) {}
}
