/**
 * Unions API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Организация и должность пользователя.
 */
export interface OrganisationJobTitle { 
    /**
     * Идентификатор организации.
     */
    organisationId?: string;
    /**
     * Наименование организации.
     */
    organisationName?: string;
    /**
     * Идентификатор города.
     */
    cityId?: string;
    /**
     * Город.
     */
    cityName?: string;
    /**
     * Должность пользователя
     */
    jobTitle?: string;
    /**
     * Идентификатор должности пользователя.
     */
    jobTitleId?: string;
    /**
     * Описание должности (альтернативное название или пояснение должности)
     */
    jobTitleDescription?: string;
    /**
     * Организация по-умолчанию.
     */
    isDefault?: boolean;
}

