import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TuiLoaderModule } from '@taiga-ui/core';
import { ComponentsModule } from '@src/app/components';

import { ListModule } from '../list';

import { SearchChatsComponent } from './search-chats.component';

@NgModule({
  declarations: [SearchChatsComponent],
  imports: [CommonModule, FormsModule, ListModule, TuiLoaderModule, ComponentsModule],
  exports: [SearchChatsComponent],
})
export class SearchChatsModule {}
