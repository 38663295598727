import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';
import { ResizableBaseComponent } from '@src/app/components/resizable-base-component';

import { LoyaltyProgramProductContacts } from '../../../models';

@Component({
  selector: 'lp-view-contacts-block',
  templateUrl: './view-contacts-block.component.html',
  styleUrls: ['./view-contacts-block.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ViewContactsBlockComponent extends ResizableBaseComponent {
  @Input() contacts?: LoyaltyProgramProductContacts;
  @Input() productUnionId?: string;
  @Input() useUnionId?: boolean;

  @Output() externalLink: EventEmitter<string> = new EventEmitter<string>();

  get companyLogoId() {
    return this.contacts?.company.logoId;
  }
}
