import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxScrollPositionRestorationModule } from 'ngx-scroll-position-restoration';

import { OrganisationModule } from '../organisation-page';

import { AssociationComponent } from './association.component';

@NgModule({
  declarations: [AssociationComponent],
  imports: [CommonModule, OrganisationModule, NgxScrollPositionRestorationModule],
  exports: [AssociationComponent],
})
export class AssociationModule {}
