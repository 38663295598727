import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { TuiAvatarModule } from '@taiga-ui/kit';
import { SharedModule } from '@src/app/shared';
import { ComponentsModule } from '@src/app/components';

import { UserComponent } from './user.component';

@NgModule({
  declarations: [UserComponent],
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    MatListModule,
    MatSlideToggleModule,
    RouterModule,
    SharedModule,
    TuiAvatarModule,
    ComponentsModule,
  ],
  exports: [UserComponent],
})
export class UserModule {}
