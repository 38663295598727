import { FormattedTextUnion } from '@airgram/web';

import { PLAIN_TEXT_TYPE, TEXT_ENTITY_MAIN_TYPES } from '../constants';
import { ParsedFormattedText, ExtendedTextEntity } from '../types';

export class TextFormatter {
  /**
   * Подготавливаем структуру, где схлопываем ноды с одинаковыми offset и length, а там где идет пересечение то резолвим их.
   * Это происходит, когда над одним куском текста произвели несколько трансформаций, например: bold, italic and etc.
   *
   * Также не забываем, что структура содержит только трансформированный текст, поэтому добавляем проверки,
   * чтобы не забыть добавит plain текст:
   * - может быть кусок в самом начале
   * - между нодами форматирования
   * - может быть кусок в конце
   * - оба варианта
   * - или весь текст не отформатирован
   *
   * @param formattedText структура, которую будем парсить
   */
  static parse(formattedText?: FormattedTextUnion): ParsedFormattedText[] {
    if (!formattedText) {
      return [];
    }

    const { entities, text } = formattedText;
    if (!entities.length) {
      // Текст не трансформирован, возвращаем одну запись
      return [{ types: [PLAIN_TEXT_TYPE], joinedTypes: PLAIN_TEXT_TYPE._, text }];
    }

    let start = 0;
    let end = 0;

    const extendedEntities = entities
      .sort((a, b) => {
        return a.offset + a.length - (b.offset + b.length);
      })
      .reduce<ExtendedTextEntity[]>((acc, entity, index) => {
      const existEntity = acc.find(item => item.offset === entity.offset && item.length === entity.length);

      end = entity.offset + entity.length;
      const plainText = text.slice(start, entity.offset);

      if (plainText) {
        acc.push({
          id: `${index}-plain-text`,
          types: [PLAIN_TEXT_TYPE],
          offset: start,
          length: plainText.length,
        });
      }

      start = end;

      if (existEntity) {
        // На блоке есть несколько трансформаций одновременно
        existEntity.types.push(entity.type);
      } else {
        const { offset, length, type } = entity;

        acc.push({
          id: index.toString(),
          offset,
          length,
          types: [type],
        });
      }

      return acc;
    }, []);

    if (end < text.length) {
      // Добавляем блок, который идет в конце, после всех трансформаций
      extendedEntities.push({
        id: extendedEntities.length.toString(),
        types: [PLAIN_TEXT_TYPE],
        offset: end,
        length: text.length - end,
      });
    }

    // Резолвим пересечения
    extendedEntities.forEach(entity => {
      const filtered = extendedEntities.filter(
        item =>
          item.offset === entity.offset &&
          item.offset + item.length <= entity.offset + entity.length &&
          item.id !== entity.id,
      );

      if (filtered.length) {
        filtered.forEach(item => {
          entity.offset += item.length;
          entity.length -= item.length;
          item.types.push(...entity.types);
        });
      }
    });

    // Нарезаем блоки
    return extendedEntities.map(entity => ({
      types: entity.types,
      mainType: entity.types.find(item => TEXT_ENTITY_MAIN_TYPES.some(mainType => mainType === item._)),
      joinedTypes: entity.types.map(item => item._).join(' '),
      text: text.slice(entity.offset, entity.offset + entity.length),
    }));
  }
}
