import { formatDate } from '@angular/common';
import { Language } from '@src/core';

/**
 * Утилита, которая преобразует дату в строку API формата.
 *
 * @param date дата
 */
export const convertDateToApiFormat = (date?: Date | null): string | undefined => {
  return date ? formatDate(date, 'yyyy-MM-ddTHH:mm:ss.SSSZ', Language.EN) : undefined;
};
