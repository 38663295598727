import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TuiScrollbarModule } from '@taiga-ui/core';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '@src/app/shared';
import { PhotoModule } from '@src/app/modules/photo';
import { ComponentsModule } from '@src/app/components';

import { ListItemUserComponent } from './components';
import { ListComponent } from './list.component';
import { ListItemModerationUserComponent } from './components/list-item-moderation-user/list-item-moderation-user.component';

@NgModule({
  declarations: [ListComponent, ListItemUserComponent, ListItemModerationUserComponent],
  imports: [CommonModule, ComponentsModule, PhotoModule, SharedModule, TuiScrollbarModule, TranslateModule],
  exports: [ListComponent, ListItemUserComponent, ListItemModerationUserComponent],
})
export class NewListModule {}
