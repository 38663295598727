import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { NavigationMode } from '../types';

@Component({
  selector: 'navigation-logo',
  templateUrl: './navigation-logo.component.html',
  styleUrls: ['./navigation-logo.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavigationLogoComponent {
  @Input() isOpen?: boolean = true;
  @Input() isBrand?: boolean = false;
  @Input() set mode(value: NavigationMode) {
    this._mode = value;
    if (value === 'mobile') {
      this.size = 32;
    } else {
      this.size = 40;
    }
  }

  size: number = 40;
  private _mode: NavigationMode = 'desktop';

  get mode() {
    return this._mode;
  }
}
