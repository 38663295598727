import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { NgxPermissionsModule } from 'ngx-permissions';
import {
  TuiAvatarModule,
  TuiCheckboxBlockModule,
  TuiCheckboxLabeledModule,
  TuiDataListWrapperModule,
  TuiInputDateModule,
  TuiInputDateTimeModule,
  TuiInputTimeModule,
  TuiInputModule,
  TuiSelectModule,
  TuiTextareaModule,
  TuiInputFilesModule,
} from '@taiga-ui/kit';
import { TuiActiveZoneModule, TuiAutoFocusModule, TuiLetModule } from '@taiga-ui/cdk';
import {
  TuiButtonModule,
  TuiDataListModule,
  TuiDropdownModule,
  TuiErrorModule,
  TuiGroupModule,
  TuiLoaderModule,
  TuiScrollbarModule,
  TuiTextfieldControllerModule,
} from '@taiga-ui/core';
import { ComponentsModule } from '@src/app/components';
import { ListModule } from '@src/app/shared/list';
import { SearchChatsModule } from '@src/app/shared/search-chats';
import { SharedModule } from '@src/app/shared';
import { FieldModule } from '@src/app/shared/field';
import { CommitteeFieldModule } from '@src/app/modules/committees/committee-field';
import { PhotoModule } from '@src/app/modules/photo';
import { UsersTableModule } from '@src/app/modules/users-table';

import { ModernSearchUsersModule } from '../search-users';

import { EventInfoComponent } from './event-info.component';
import { EventInfoViewComponent } from './event-info-view/event-info-view.component';
import { EventInfoEditComponent } from './event-info-edit/event-info-edit.component';

@NgModule({
  declarations: [EventInfoComponent, EventInfoViewComponent, EventInfoEditComponent],
  imports: [
    CommitteeFieldModule,
    CommonModule,
    ComponentsModule,
    FieldModule,
    FormsModule,
    ListModule,
    MatButtonModule,
    MatIconModule,
    NgxPermissionsModule,
    PhotoModule,
    ReactiveFormsModule,
    SearchChatsModule,
    ModernSearchUsersModule,
    SharedModule,
    TuiActiveZoneModule,
    TuiAutoFocusModule,
    TuiAvatarModule,
    TuiButtonModule,
    TuiCheckboxBlockModule,
    TuiCheckboxLabeledModule,
    TuiDataListModule,
    TuiDataListWrapperModule,
    TuiDropdownModule,
    TuiGroupModule,
    TuiInputDateModule,
    TuiInputDateTimeModule,
    TuiInputTimeModule,
    TuiInputFilesModule,
    TuiInputModule,
    TuiLetModule,
    TuiLoaderModule,
    TuiScrollbarModule,
    TuiSelectModule,
    TuiTextareaModule,
    TuiTextfieldControllerModule,
    UsersTableModule,
    TuiErrorModule,
  ],
  exports: [EventInfoComponent],
})
export class EventInfoModule {}
