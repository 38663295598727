<div class="organisation-personal">
  <div class="organisation-personal__content content">
    <div class="content__header">
      <button
        *ngIf="!showFullPage && selectedUserId"
        tuiIconButton
        class="content__return-button"
        type="button"
        appearance="icon"
        icon="tuiIconArrowLeftLarge"
        size="s"
        [title]="'common.buttons.return' | translate"
        (click)="onClickReturnButton()"
      ></button>
      <ng-container *ngIf="showFullPage">
        <h1 class="content__title">{{ title }}</h1>
        <button
          *ngIf="allowEditing$ | async"
          tuiIconButton
          class="button button_add"
          type="button"
          appearance="icon"
          icon="tuiIconPlusCircleLarge"
          size="s"
          [title]="'common.buttons.add' | translate"
          (click)="onClickAddButton()"
        ></button>
      </ng-container>
    </div>
    <div [hide]="!showFullPage" class="content__list">
      <app-list
        listType="users"
        [items]="users$ | async"
        [selectedId]="selectedUserId"
        [withCounter]="true"
        (selectedIdChange)="onChangeSelectedUserId($event)"
        [loading]="loading"
      ></app-list>
    </div>
    <tui-scrollbar class="content__content" *ngIf="userInfoMode === 'create' || selectedUserId">
      <app-user-info
        [mode]="userInfoMode"
        [userId]="selectedUserId"
        [organisationId]="organisationId"
        (canceled)="onCancelEditingUser()"
        (saved)="onSaveEditingUser()"
        (deleted)="onClickDeleteButton($event)"
      ></app-user-info>
    </tui-scrollbar>
  </div>
</div>
