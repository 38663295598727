import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  Output,
  EventEmitter,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BreakpointObserver } from '@angular/cdk/layout';
import { BehaviorSubject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { MatSelectionList, MatSelectionListChange } from '@angular/material/list';
import { NgxPermissionsService } from 'ngx-permissions';
import { BreakpointObserverHelperService, OrganisationService, UserService } from '@src/core/services';
import { MenuItem, OrganisationUI } from '@src/models';
import { OrganisationView } from '@src/api';
import { ResizableBaseComponent } from '@src/app/components/resizable-base-component';
import { ObjectId } from '@src/types/id';
import { TranslateService } from '@ngx-translate/core';
import { CustomNamesItem, CustomNamesService } from '@src/app/modules/custom-name-tabs';

import { OrganisationMenuItemIcon } from './organisation.model';
import { AssociationMenuItem, AssociationMenuQueryId } from './constants';

@Component({
  selector: 'app-organisation',
  templateUrl: './organisation.component.html',
  styleUrls: ['./organisation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrganisationComponent extends ResizableBaseComponent implements OnChanges {
  ASSOCIATION_MENU_ITEM = AssociationMenuItem;

  @Input() organisationId?: string;
  @Output() deleted: EventEmitter<void> = new EventEmitter<void>();
  @ViewChild('menuList') menuList?: MatSelectionList;

  organisation$: BehaviorSubject<OrganisationUI | null> = this.organisationService.organisation$;
  currentParentOrganisation$: BehaviorSubject<OrganisationView | undefined> =
    this.organisationService.currentParentOrganisation$;
  loading: boolean = true;

  objectMenuWCustom$: BehaviorSubject<CustomNamesItem[] | undefined> = this.customNamesService.objectMenuWCustom$;
  menuItems?: MenuItem<string, OrganisationMenuItemIcon>[];
  visibleMenuItems?: MenuItem<string, OrganisationMenuItemIcon>[];

  /** Текущий элемент меню */
  activeMenuItem?: string = AssociationMenuItem.Info;

  /** Объект, который был открыт в элементе меню */
  menuObjectId: ObjectId;

  constructor(
    readonly cdr: ChangeDetectorRef,
    readonly breakpointObserver: BreakpointObserver,
    readonly breakpointObserverHelperService: BreakpointObserverHelperService,
    private customNamesService: CustomNamesService,
    private route: ActivatedRoute,
    private organisationService: OrganisationService,
    private userService: UserService,
    private ngxPermissionsService: NgxPermissionsService,
    private readonly router: Router,
    private readonly translateService: TranslateService,
  ) {
    super(cdr, breakpointObserver, breakpointObserverHelperService);

    this.objectMenuWCustom$.pipe(takeUntil(this.destroyed$$)).subscribe(configs => {
      if (configs) {
        configs?.forEach(item => {
          if (item.languageCode !== this.translateService.currentLang) return;

          const menuItem = this.menuItems?.find(({ id }) => id === item.codeName);
          if (menuItem) {
            menuItem.visibilityOption = item.visibilityOption;
            menuItem.title = item.title || '';
          }
        });

        this.visibleMenuItems = this.customNamesService.getVisibleMenuItems(this.menuItems);

        if (!this.activeMenuItem || !this.visibleMenuItems.find(item => item.id === this.activeMenuItem)) {
          this.activeMenuItem = this.visibleMenuItems[0]?.id;
          this.router.navigate([], {
            queryParams: { menu: AssociationMenuQueryId[this.activeMenuItem as AssociationMenuItem] },
          });
        }

        this.cdr.markForCheck();
      }
    });
  }

  ngOnInit(): void {
    super.ngOnInit();

    if (!this.organisationId) {
      this.route.paramMap.pipe(takeUntil(this.destroyed$$)).subscribe(async params => {
        this.loading = true;
        this.organisationId = params.get('id') ?? undefined;
        if (!this.organisationId) {
          this.organisationId = this.userService.authUser$.value?.organisationId;
        }

        if (this.organisationId) {
          this.setData();
          this.loading = false;
          this.cdr.markForCheck();
        }
      });
    }

    this.route.queryParamMap.pipe(takeUntil(this.destroyed$$)).subscribe(query => {
      const menu = query.get('menu');
      const menuId =
        Object.entries(AssociationMenuQueryId).find(([, value]) => value === menu)?.[0] ?? AssociationMenuItem.Info;

      if (menuId && menuId !== this.activeMenuItem) {
        this.activeMenuItem = menuId;

        this.cdr.markForCheck();
      }

      this.menuObjectId = query.get('id') ?? undefined;

      this.cdr.markForCheck();
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.loading = true;

    if (changes.organisationId) {
      if (this.organisationId) {
        this.setData();
      } else {
        this.organisationService.resetOrganisation();
      }
    }

    this.loading = false;
    this.cdr.markForCheck();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();

    this.organisationService.resetOrganisation();
  }

  updateActiveItem(selectionList: MatSelectionListChange): void {
    const selectionOption = selectionList.options[0];
    this.activeMenuItem = selectionOption.value;

    this.router.navigate([], {
      queryParams: { menu: AssociationMenuQueryId[this.activeMenuItem as AssociationMenuItem] },
    });

    this.cdr.markForCheck();
  }

  onDeleteOrganisation() {
    this.deleted.emit();
  }

  get showFullPage(): boolean {
    return this.isLargeScreen;
  }

  private setData(): void {
    if (this.organisationId) {
      this.organisationService.loadOrganisation({ id: this.organisationId, loadObjectMenu: true });
    }

    this.menuItems = [
      {
        id: AssociationMenuItem.Info,
        icon: 'info',
        title: this.translateService.instant('components.organisation.labels.menuInfo'),
      },
      {
        id: AssociationMenuItem.Users,
        icon: 'groups',
        title: this.translateService.instant('components.organisation.labels.menuUsers'),
        allowViewing$: this.userService.authUser$.pipe(
          map(user => {
            if (!user || !user.organisationId) return false;

            const permissions = this.ngxPermissionsService.getPermissions();
            const permissionsForOrganisation = this.organisationId
              ? user.permissionsForOrganisations?.[this.organisationId]
              : [];

            return (
              'organisationEmployeeViewing' in permissions ||
              permissionsForOrganisation?.includes('onlyYourOrganisationEmployeeViewing')
            );
          }),
        ),
      },
      {
        id: AssociationMenuItem.Channels,
        icon: 'campaign',
        title: this.translateService.instant('components.organisation.labels.menuChannels'),
      },
      {
        id: AssociationMenuItem.Committees,
        icon: 'groups',
        title: this.translateService.instant('components.organisation.labels.menuCommittees'),
      },
      {
        id: AssociationMenuItem.Configs,
        icon: 'settings',
        title: this.translateService.instant('components.organisation.labels.menuConfigs'),
        allowViewing$: this.userService.authUser$.pipe(
          map(user => {
            if (!user) return false;

            const permissions = this.ngxPermissionsService.getPermissions();

            return 'organisationConfigsViewing' in permissions;
          }),
        ),
      },
    ];
  }

  onChangeMenuObjectId(id?: ObjectId) {
    this.menuObjectId = id;

    this.router.navigate([], {
      queryParams: {
        ...(id ? { id } : {}),
        menu: AssociationMenuQueryId[this.activeMenuItem as AssociationMenuItem],
      },
    });
  }

  getMenuItemTitle(id: string): string | undefined {
    return this.menuItems?.find(item => item.id === id)?.title;
  }
}
