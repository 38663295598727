import { Inject, Injectable, Injector, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { TuiDialogService } from '@taiga-ui/core';
import { TelegramAuthService, TelegramWebAppService } from '@src/app/modules/telegram';
import { AuthService } from '@src/app/modules/auth';
import { PolymorpheusComponent } from '@tinkoff/ng-polymorpheus';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { logger, reload } from '@src/utils';

import { LogoutModalComponent } from '../ui/logout-modal/logout-modal.component';
import { AUTO_RELOAD_PAGE_TIMER } from '../constants';

@Injectable({
  providedIn: 'root',
})
export class LogoutService implements OnDestroy {
  private timerId?: NodeJS.Timeout;

  private processing = false;

  private readonly dialog = this.dialogService.open<number>(
    new PolymorpheusComponent(LogoutModalComponent, this.injector),
    {
      size: 'm',
      closeable: false,
      dismissible: false,
    },
  );

  private destroyed$$: Subject<void> = new Subject<void>();

  constructor(
    private router: Router,
    private telegramAuth: TelegramAuthService,
    private telegramWebAppService: TelegramWebAppService,
    private authService: AuthService,
    @Inject(TuiDialogService) private readonly dialogService: TuiDialogService,
    @Inject(Injector) private readonly injector: Injector,
  ) {
    this.reload = this.reload.bind(this);

    logger('LogoutService constructor');

    this.telegramAuth.updates$.pipe(takeUntil(this.destroyed$$)).subscribe(update => {
      if (update._ === 'updateAuthorizationState' && this.processing) {
        const { authorizationState } = update;

        logger('LogoutService', authorizationState);

        if (authorizationState._ === 'authorizationStateClosed') {
          if (this.timerId) {
            clearTimeout(this.timerId);
            this.timerId = undefined;
          }

          this.reload();
        }
      }
    });
  }

  ngOnDestroy(): void {
    this.destroyed$$.next();
    this.destroyed$$.complete();
  }

  /**
   * Метод где выходим:
   * - из основной сессии пользователя
   * - из сессии Telegram (если мы авторизованы)
   */
  async logout(): Promise<void> {
    this.authService.logout();

    if (this.telegramWebAppService.isRunAsTelegramWebApp()) {
      // Работаем из под Telegram бота
      this.telegramWebAppService.close();
      return;
    }

    if (this.telegramAuth.isLogged()) {
      this.processing = true;

      this.dialog.subscribe();
      await this.telegramAuth.logout();

      // Предохранитель, на случай, если Telegram не отвечает
      this.timerId = setTimeout(() => this.reload(), AUTO_RELOAD_PAGE_TIMER);
    } else {
      this.reload();
    }
  }

  private reload() {
    reload();
  }
}
