<div *ngIf="mode === 'view'" class="field field_size_{{ size }}">
  <div *ngIf="name" class="field__name">{{ name }}</div>
  <tui-files>
    <tui-file
      *ngFor="let file of tuiFiles"
      class="file size_{{ size }}"
      [file]="file"
      [leftContent]="content"
      (click)="downloadDocument(file.id)"
    ></tui-file>
  </tui-files>
</div>

<ng-template #content>
  <tui-svg src="tuiIconDownload"></tui-svg>
</ng-template>
