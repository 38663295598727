import { Injectable } from '@angular/core';
import { UserService } from '@src/api';
import { catchError, throwError } from 'rxjs';
import { AlertService } from '@src/core/services';
import { formatDate } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class BirthdaysApiService {
  constructor(private readonly userService: UserService, private readonly alertService: AlertService) {}

  getUsersBirthdays(dateStart: Date, dateEnd: Date) {
    return this.userService
      .getUsersBirthdays(this.convertDateToString(dateStart), this.convertDateToString(dateEnd))
      .pipe(
        catchError(err => {
          this.alertService.error(err);
          return throwError(() => err);
        }),
      );
  }

  private convertDateToString(date: Date) {
    return formatDate(date, 'yyyy-MM-dd', 'en');
  }
}
