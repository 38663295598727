import { Pipe, PipeTransform } from '@angular/core';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { TranslateService } from '@ngx-translate/core';

const INVITE_STATUS = {
  0: marker('common.constants.inviteStatus.notReady'), // 0 - Ожидание
  1: marker('common.constants.inviteStatus.ready'), // 1 - Отправлено
  2: marker('common.constants.inviteStatus.accepted'), // 2 - Принято
  3: marker('common.constants.inviteStatus.rejected'), // 3 - Отклонено
};

@Pipe({
  name: 'inviteStatus',
})
export class InviteStatusPipe implements PipeTransform {
  constructor(private readonly translateService: TranslateService) {}
  transform(value?: number): string {
    switch (value) {
      case 0:
      case 1:
      case 2:
      case 3:
        return this.translateService.instant(INVITE_STATUS[value]);

      default:
        return this.translateService.instant('common.labels.unknownStatus');
    }
  }
}
