import { marker } from '@biesbjerg/ngx-translate-extract-marker';

import { LoyaltyProgramGrade } from './models';
import { SelectItem } from './types';

export const GRADE_TITLES: Record<LoyaltyProgramGrade, string> = {
  usual: marker('components.loyaltyProgram.constants.gradeTitles.usual'),
  popular: marker('components.loyaltyProgram.constants.gradeTitles.popular'),
  notable: marker('components.loyaltyProgram.constants.gradeTitles.notable'),
  bestseller: marker('components.loyaltyProgram.constants.gradeTitles.bestseller'),
} as const;

export const GRADE_COLORS: Record<LoyaltyProgramGrade, string> = {
  usual: 'var(--tui-text-01)',
  popular: 'var(--unions-color-grade-popular)',
  notable: 'var(--unions-color-grade-notable)',
  bestseller: 'var(--unions-color-support-24)',
} as const;

export const GRADE_BACKGROUND_COLORS: Record<LoyaltyProgramGrade, string> = {
  usual: 'var(--unions-color-grey-20)',
  popular: 'var(--unions-color-grade-popular-20)',
  notable: 'var(--unions-color-grade-notable-20)',
  bestseller: 'var(--unions-color-support-24-20)',
} as const;

export const GRADE_SVG: Record<LoyaltyProgramGrade, string> = {
  usual: 'assets/images/loyalty-program/usual.svg',
  popular: 'assets/images/loyalty-program/popular.svg',
  notable: 'assets/images/loyalty-program/notable.svg',
  bestseller: 'assets/images/loyalty-program/bestseller.svg',
} as const;

/** Рекомендуемая длина описания продукта */
export const DESCRIPTION_RECOMMENDED_LENGTH = 250;

export const ALL_REGIONS_ID = 'all-regions';

export const ALL_JOBS_ID = 'all-jobs';

export const ALL_REGIONS_ITEM: SelectItem = {
  id: ALL_REGIONS_ID,
  name: marker('components.loyaltyProgram.constants.allRegionsItem.name'),
};
