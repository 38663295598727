<ng-container *ngIf="mode === 'view'">
  <app-label [label]="name">
    <div class="value__wrapper">
      <tui-tag
        *ngIf="user; else empty"
        class="value"
        [routerLink]="'/association-users/' + user.id"
        [value]="user.fullName || ''"
        [hoverable]="true"
      ></tui-tag>
    </div>
  </app-label>
</ng-container>

<div *ngIf="mode === 'preview'">
  <app-link
    *ngIf="user; else empty"
    icon="tuiIconMail"
    iconAlign="right"
    [routerLink]="'/association-users/' + user.id"
    [pseudo]="true"
    [displayText]="user.fullName"
  ></app-link>
</div>

<ng-template #empty>
  <div class="tui-skeleton tui-skeleton_text empty">&nbsp;</div>
</ng-template>
