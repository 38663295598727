<form class="poll-info-edit" [formGroup]="infoForm">
  <div class="poll-info-edit__basic">
    <app-photo
      class="field image"
      mode="edit"
      [class.tui-skeleton]="loading"
      [class.tui-skeleton_text]="loading"
      [newPhoto]="newPhoto"
      [photoId]="data?.photoId"
      [width]="'335px'"
      [height]="'200px'"
      [loading]="loading"
      (photoChange)="onPhotoChange($event)"
    ></app-photo>

    <ng-container *ngIf="mode === 'create'">
      <app-label [label]="'components.pollInfo.fields.templateId' | translate">
        <tui-select
          *tuiLet="templates$ | async as templates"
          formControlName="templateId"
          [class.tui-skeleton]="loading"
          [class.tui-skeleton_text]="loading"
          [valueContent]="templates ? templatesStringify(templates) : loadingTmp"
          [tuiTextfieldLabelOutside]="true"
          [tuiTextfieldSize]="'m'"
        >
          <ng-template tuiDataList>
            <tui-data-list *ngIf="templates; else loadingTmp">
              <button *ngFor="let template of templates" tuiOption [value]="template.id">
                {{ template.titleText }}
              </button>
            </tui-data-list>
          </ng-template>

          <ng-template #loadingTmp>
            <tui-loader class="tui-space_vertical-3 loader"></tui-loader>
          </ng-template>
        </tui-select>
      </app-label>

      <div [class.tui-skeleton]="loading" [class.tui-skeleton_text]="loading">
        <app-field-wrapper [text]="'components.pollInfo.fields.isTemplate' | translate" [size]="'s'">
          <app-toggle tabindex="14" [size]="'l'" formControlName="isTemplate"></app-toggle>
        </app-field-wrapper>
      </div>
    </ng-container>

    <app-label [label]="'components.pollInfo.fields.title' | translate">
      <tui-input
        tuiAutoFocus
        tabindex="2"
        formControlName="titleText"
        [class.tui-skeleton]="loading"
        [class.tui-skeleton_text]="loading"
        [tuiTextfieldLabelOutside]="true"
        [tuiTextfieldSize]="'m'"
      >
        {{ 'common.fields.textPlaceholder' | translate }}
        <span class="tui-required"></span>
      </tui-input>
    </app-label>

    <app-label [label]="'components.pollInfo.fields.description' | translate">
      <tui-textarea
        class="field field_height_min"
        tabindex="3"
        formControlName="descriptionText"
        [class.tui-skeleton]="loading"
        [class.tui-skeleton_text]="loading"
        [tuiTextfieldLabelOutside]="true"
        [tuiTextfieldSize]="'m'"
        [expandable]="true"
      >
        {{ 'common.fields.textPlaceholder' | translate }}
      </tui-textarea>
    </app-label>

    <app-label [label]="'components.pollInfo.fields.pollDuration' | translate">
      <tui-input-number
        tabindex="4"
        formControlName="pollDuration"
        [class.tui-skeleton]="loading"
        [class.tui-skeleton_text]="loading"
        [tuiTextfieldLabelOutside]="true"
        [tuiTextfieldSize]="'m'"
        [min]="1"
      >
        {{ 'common.fields.numberPlaceholder' | translate }}
      </tui-input-number>
    </app-label>

    <div *ngIf="!isTemplateValue" class="row">
      <app-label class="field" [label]="'components.pollInfo.fields.dateStart' | translate">
        <tui-input-date
          tabindex="5"
          formControlName="dateStart"
          [class.tui-skeleton]="loading"
          [class.tui-skeleton_text]="loading"
          [tuiTextfieldLabelOutside]="true"
          [tuiTextfieldSize]="'m'"
        >
          {{ 'common.fields.datePlaceholder' | translate }}
          <span class="tui-required"></span>
        </tui-input-date>
      </app-label>

      <app-label class="field" [label]="'components.pollInfo.fields.timeStart' | translate">
        <tui-input-time
          tabindex="6"
          formControlName="timeStart"
          [class.tui-skeleton]="loading"
          [class.tui-skeleton_text]="loading"
          [tuiTextfieldLabelOutside]="true"
          [tuiTextfieldSize]="'m'"
          [items]="timeItems"
        >
          {{ 'common.fields.timePlaceholder' | translate }}
          <span class="tui-required"></span>
        </tui-input-time>
      </app-label>
    </div>

    <div *ngIf="!isTemplateValue" class="row">
      <app-label class="field" [label]="'components.pollInfo.fields.dateEnd' | translate">
        <tui-input-date
          tabindex="7"
          formControlName="dateEnd"
          [class.tui-skeleton]="loading"
          [class.tui-skeleton_text]="loading"
          [tuiTextfieldLabelOutside]="true"
          [tuiTextfieldSize]="'m'"
          [min]="dateStart"
        >
          {{ 'common.fields.datePlaceholder' | translate }}
          <span class="tui-required"></span>
        </tui-input-date>
      </app-label>

      <app-label class="field" [label]="'components.pollInfo.fields.timeEnd' | translate">
        <tui-input-time
          tabindex="8"
          formControlName="timeEnd"
          [class.tui-skeleton]="loading"
          [class.tui-skeleton_text]="loading"
          [tuiTextfieldLabelOutside]="true"
          [tuiTextfieldSize]="'m'"
          [items]="timeItems"
        >
          {{ 'common.fields.timePlaceholder' | translate }}
          <span class="tui-required"></span>
        </tui-input-time>
      </app-label>
    </div>

    <app-committee-field
      *ngIf="!isTemplateValue"
      tabindex="9"
      [class.tui-skeleton]="loading"
      [class.tui-skeleton_text]="loading"
      [size]="'m'"
      [selectedIds]="infoForm.get('committeeId')?.value ? [infoForm.get('committeeId')!.value] : []"
      (selectedIdsChange)="onChangeSelectedCommitteeIds($event)"
    ></app-committee-field>

    <app-label [label]="'components.pollInfo.fields.completionText' | translate">
      <tui-textarea
        class="field_height_min"
        tabindex="10"
        formControlName="completionText"
        [class.tui-skeleton]="loading"
        [class.tui-skeleton_text]="loading"
        [tuiTextfieldLabelOutside]="true"
        [tuiTextfieldSize]="'m'"
        [expandable]="true"
      >
        {{ 'common.fields.textPlaceholder' | translate }}
      </tui-textarea>
    </app-label>

    <div [class.tui-skeleton]="loading" [class.tui-skeleton_text]="loading">
      <app-field-wrapper [text]="'components.pollInfo.fields.displayStepByStep' | translate" [size]="'s'">
        <app-toggle tabindex="11" [size]="'l'" formControlName="displayStepByStep"></app-toggle>
      </app-field-wrapper>
    </div>

    <!-- TODO: временно скрыли, в следующем релизе вернём -->
    <!-- <app-label [label]="'components.pollInfo.fields.timeLimit' | translate">
      <tui-input-number
        tabindex="12"
        formControlName="timeLimit"
        [class.tui-skeleton]="loading"
        [class.tui-skeleton_text]="loading"
        [tuiTextfieldLabelOutside]="true"
        [tuiTextfieldSize]="'m'"
        [min]="0"
      >
        {{ 'common.fields.numberPlaceholder' | translate }}
      </tui-input-number>
    </app-label> -->

    <div [class.tui-skeleton]="loading" [class.tui-skeleton_text]="loading">
      <app-field-wrapper [text]="'components.pollInfo.fields.registerTime' | translate" [size]="'s'">
        <app-toggle tabindex="13" [size]="'l'" formControlName="registerTime"></app-toggle>
      </app-field-wrapper>
    </div>

    <div *ngIf="!isTemplateValue" tabindex="15" [class.tui-skeleton]="loading" [class.tui-skeleton_text]="loading">
      <search-users-input
        formControlName="members"
        mode="multiple"
        [label]="'common.labels.members' | translate"
        [pluralize]="[
          'common.labels.memberOne' | translate,
          'common.labels.memberFew' | translate,
          'common.labels.memberMany' | translate
        ]"
        [showFilterButton]="true"
        [textfieldSize]="'m'"
      ></search-users-input>
    </div>

    <app-label [label]="'components.pollInfo.fields.documents' | translate">
      <div tabindex="16" [class.tui-skeleton]="loading" [class.tui-skeleton_text]="loading">
        <tui-input-files
          class="size_m"
          formControlName="documents"
          [label]="'common.fields.selectFiles' | translate"
          [maxFileSize]="maxDocFileSize"
          [size]="'m'"
        >
          <input tuiInputFiles [multiple]="true" />
        </tui-input-files>

        <tui-files class="tui-space_top-1">
          <tui-file
            *ngFor="let file of infoForm.get('documents')?.value"
            class="size_s"
            [file]="file"
            (removed)="removeFile(infoForm.get('documents'), file)"
          ></tui-file>
        </tui-files>
      </div>
    </app-label>
  </div>

  <ng-container *ngIf="mode === 'create' || (mode === 'edit' && !!data?.canEditPollQuestions)">
    <div class="poll-info-edit__questions" formArrayName="questions">
      <div
        *ngFor="let question of questions.controls; let qIndex = index"
        [class.tui-skeleton]="loading"
        [class.tui-skeleton_text]="loading"
      >
        <div class="item" [formGroupName]="qIndex">
          <div class="item__header">
            <div class="title text_size-m text_bold text_color-01">Вопрос {{ qIndex + 1 }}</div>
            <div class="buttons">
              <button
                tuiIconButton
                type="button"
                icon="tuiIconChevronUpLarge"
                appearance="whiteblock-active"
                [size]="'m'"
                [disabled]="qIndex === 0"
                (click)="moveQuestion(qIndex, -1)"
              ></button>

              <button
                tuiIconButton
                type="button"
                icon="tuiIconChevronDownLarge"
                appearance="whiteblock-active"
                [size]="'m'"
                [disabled]="qIndex === questions.controls.length - 1"
                (click)="moveQuestion(qIndex, 1)"
              ></button>

              <button
                tuiIconButton
                type="button"
                icon="tuiIconTrash2Large"
                appearance="whiteblock-active"
                [size]="'m'"
                (click)="removeQuestion(qIndex)"
              ></button>
            </div>
          </div>

          <app-photo
            mode="edit"
            width="100%"
            [photoId]="question.get('photoId')?.value"
            (photoChange)="onQuestionPhotoChange(qIndex, $event)"
          ></app-photo>

          <div class="item__question">
            <app-label [label]="'components.pollInfo.fields.question' | translate">
              <tui-input
                tabindex="11"
                formControlName="text"
                [tuiTextfieldLabelOutside]="true"
                [tuiTextfieldSize]="'m'"
              >
                {{ 'common.fields.textPlaceholder' | translate }}
                <span class="tui-required"></span>
              </tui-input>
            </app-label>
          </div>

          <div class="item__answer-type">
            <app-label [label]="'components.pollInfo.fields.answerTypeTitle' | translate">
              <tui-select
                tabindex="12"
                formControlName="answerType"
                [valueContent]="answerTypesContent"
                [tuiTextfieldLabelOutside]="true"
                [tuiTextfieldSize]="'m'"
              >
                {{ 'components.pollInfo.fields.answerTypeTitlePlaceholder' | translate }}
                <span class="tui-required"></span>
                <tui-data-list-wrapper
                  *tuiDataList
                  [items]="answerTypes"
                  [itemContent]="answerTypesContent"
                ></tui-data-list-wrapper>
              </tui-select>
            </app-label>
          </div>

          <div class="item__answer-control" formArrayName="answers">
            <ng-container [ngSwitch]="question.get('answerType')?.value?.type">
              <div cdkDropList class="answer" (cdkDropListDropped)="onDropAnswers($event, qIndex)">
                <ng-container *ngFor="let answers of getAnswerOptionsByIndex(qIndex).controls; let aIndex = index">
                  <ng-container [formGroupName]="aIndex">
                    <ng-container *ngSwitchCase="'radio'" [ngTemplateOutlet]="multiAnswersTmp"></ng-container>
                    <ng-container *ngSwitchCase="'check'" [ngTemplateOutlet]="multiAnswersTmp"></ng-container>

                    <ng-template #multiAnswersTmp>
                      <div cdkDrag class="answer__radiobox">
                        <div *cdkDragPlaceholder></div>

                        <button
                          tuiIconButton
                          cdkDragHandle
                          class="reorder-button"
                          type="button"
                          icon="tuiIconDragLarge"
                          appearance="icon"
                          size="s"
                        ></button>

                        <tui-input
                          formControlName="textValue"
                          [tuiTextfieldLabelOutside]="true"
                          [tuiTextfieldSize]="'m'"
                        >
                          {{ ('components.pollInfo.labels.option' | translate) + ' ' + (aIndex + 1) }}
                        </tui-input>

                        <button
                          tuiIconButton
                          type="button"
                          appearance="accent"
                          icon="tuiIconCloseLarge"
                          size="s"
                          [title]="'common.buttons.delete' | translate"
                          (click)="removeAnswerOption(qIndex, aIndex)"
                        ></button>
                      </div>
                    </ng-template>
                  </ng-container>
                </ng-container>

                <ng-container *ngSwitchCase="'radio'" [ngTemplateOutlet]="multiAnswersActionsTmp"></ng-container>
                <ng-container *ngSwitchCase="'check'" [ngTemplateOutlet]="multiAnswersActionsTmp"></ng-container>

                <ng-template #multiAnswersActionsTmp>
                  <div class="answer__buttons">
                    <button
                      tuiButton
                      type="button"
                      appearance="whiteblock-active"
                      size="s"
                      (click)="addAnswerOptionByIndex(qIndex)"
                    >
                      {{ 'components.pollInfo.buttons.addOption' | translate }}
                    </button>

                    <button
                      *ngIf="getOtherVisible(qIndex)"
                      tuiButton
                      type="button"
                      appearance="whiteblock-active"
                      size="s"
                      (click)="addAnswerOptionByIndex(qIndex, { isAlternative: true })"
                    >
                      {{ 'components.pollInfo.buttons.addAlternative' | translate }}
                    </button>
                  </div>
                </ng-template>
              </div>
            </ng-container>
          </div>
        </div>

        <div class="separator"></div>
      </div>

      <div tuiGroup class="add-button" [collapsed]="true">
        <button tuiButton appearance="whiteblock-active" [size]="'m'" type="button" (click)="addQuestion()">
          {{ 'components.pollInfo.buttons.addQuestion' | translate }}
        </button>

        <button
          tuiIconButton
          class="tui-group__auto-width-item"
          appearance="whiteblock-active"
          icon="tuiIconPlusCircleLarge"
          [size]="'m'"
          type="button"
          (click)="addQuestion()"
        ></button>
      </div>
    </div>
  </ng-container>

  <div *ngIf="!loading" class="poll-info-edit__buttons">
    <button tuiButton type="button" appearance="primary" [size]="tuiElementLargeSize" (click)="onClickSaveButton()">
      {{ 'common.buttons.save' | translate }}
    </button>

    <button tuiButton type="button" appearance="secondary" [size]="tuiElementLargeSize" (click)="onClickCancelButton()">
      {{ 'common.buttons.cancel' | translate }}
    </button>
  </div>
</form>

<ng-template #answerTypesContent let-answerType>
  <div class="answer-type">
    {{ answerType.caption | translate }}
  </div>
</ng-template>
