<form class="event-info-edit" [formGroup]="infoForm">
  <div class="event-info-edit__block">
    <app-photo
      class="image"
      mode="edit"
      [class.tui-skeleton]="loading"
      [class.tui-skeleton_text]="loading"
      [photoId]="data?.photoId"
      [size]="tuiElementDoubleExtraLargeSize"
      [rounded]="true"
      [loading]="loading"
      (photoChange)="onPhotoChange($event)"
    ></app-photo>

    <div>
      <tui-input
        tuiAutoFocus
        class="field"
        tabindex="2"
        formControlName="subject"
        [class.tui-skeleton]="loading"
        [class.tui-skeleton_text]="loading"
        [tuiTextfieldSize]="tuiElementLargeSize"
      >
        {{ 'components.eventInfo.fields.name' | translate }}
        <span class="tui-required"></span>
      </tui-input>
      <tui-error [error]="controlValueError('subject')"></tui-error>
    </div>

    <tui-select
      class="field"
      tabindex="3"
      formControlName="eventTypeId"
      [valueContent]="eventTypes ? eventTypesStringify(eventTypes) : ''"
      [tuiTextfieldSize]="tuiElementLargeSize"
      [tuiTextfieldCleaner]="true"
      [class.tui-skeleton]="loading"
      [class.tui-skeleton_text]="loading"
    >
      {{ 'components.eventInfo.fields.eventType' | translate }}
      <tui-data-list *tuiDataList>
        <button *ngFor="let eventType of eventTypes" tuiOption [value]="eventType.id">
          {{ eventType.name | translate }}
        </button>
      </tui-data-list>
    </tui-select>

    <div>
      <tui-textarea
        class="field field_height_min"
        tabindex="5"
        formControlName="description"
        [class.tui-skeleton]="loading"
        [class.tui-skeleton_text]="loading"
        [tuiTextfieldSize]="tuiElementLargeSize"
        [expandable]="true"
      >
        {{ 'components.eventInfo.fields.description' | translate }}
      </tui-textarea>
      <tui-error [error]="controlValueError('description')"></tui-error>
    </div>

    <div>
      <tui-input
        class="field"
        tabindex="6"
        formControlName="address"
        [class.tui-skeleton]="loading"
        [class.tui-skeleton_text]="loading"
        [tuiTextfieldSize]="tuiElementLargeSize"
      >
        {{ 'components.eventInfo.fields.address' | translate }}
        <input tuiTextfield [attr.autocomplete]="autocompleteTypes.address" />
      </tui-input>
      <tui-error [error]="controlValueError('address')"></tui-error>
    </div>

    <div>
      <div tuiGroup class="field" [collapsed]="true">
        <tui-input
          class="online-link__input"
          tabindex="7"
          formControlName="onlineLink"
          [class.tui-skeleton]="loading"
          [class.tui-skeleton_text]="loading"
          [tuiTextfieldSize]="tuiElementLargeSize"
        >
          {{ 'components.eventInfo.fields.onlineLink' | translate }}
        </tui-input>
        <tui-checkbox-block
          *ngIf="mode === 'create' && isLoggedMessenger && isActiveTab && !env.isBot"
          class="online-link__checkbox"
          formControlName="fillOnlineLink"
          [class.tui-skeleton]="loading"
          [class.tui-skeleton_text]="loading"
          [size]="tuiElementLargeSize"
          [hideCheckbox]="false"
          (ngModelChange)="onFillOnlineLinkChange($event)"
        >
          {{ 'components.eventInfo.fields.chat' | translate }}
        </tui-checkbox-block>
      </div>
      <tui-error [error]="controlValueError('onlineLink')"></tui-error>
    </div>

    <div tuiGroup class="field">
      <tui-input-date
        tabindex="8"
        formControlName="dateStart"
        [class.tui-skeleton]="loading"
        [class.tui-skeleton_text]="loading"
        [tuiTextfieldSize]="tuiElementLargeSize"
      >
        {{ 'components.eventInfo.fields.dateStart' | translate }}
        <span class="tui-required"></span>
      </tui-input-date>
      <tui-input-time
        tabindex="9"
        formControlName="timeStart"
        [class.tui-skeleton]="loading"
        [class.tui-skeleton_text]="loading"
        [tuiTextfieldSize]="tuiElementLargeSize"
        [items]="timeItems"
      >
        {{ 'components.eventInfo.fields.timeStart' | translate }}
        <span class="tui-required"></span>
      </tui-input-time>
    </div>

    <div tuiGroup class="field">
      <tui-input-date
        tabindex="10"
        formControlName="dateEnd"
        [class.tui-skeleton]="loading"
        [class.tui-skeleton_text]="loading"
        [tuiTextfieldSize]="tuiElementLargeSize"
        [min]="dateStart"
      >
        {{ 'components.eventInfo.fields.dateEnd' | translate }}
        <span class="tui-required"></span>
      </tui-input-date>
      <tui-input-time
        tabindex="11"
        formControlName="timeEnd"
        [class.tui-skeleton]="loading"
        [class.tui-skeleton_text]="loading"
        [tuiTextfieldSize]="tuiElementLargeSize"
        [items]="timeItems"
      >
        {{ 'components.eventInfo.fields.timeEnd' | translate }}
        <span class="tui-required"></span>
      </tui-input-time>
    </div>

    <!-- TODO: ngxPermissionsExcept - временное решение -->
    <app-committee-field
      *ngxPermissionsExcept="['eventHideFieldsEditing']"
      class="field"
      tabindex="12"
      [class.tui-skeleton]="loading"
      [class.tui-skeleton_text]="loading"
      [size]="tuiElementLargeSize"
      [selectedIds]="infoForm.get('committeeId')?.value ? [infoForm.get('committeeId')!.value] : []"
      (selectedIdsChange)="onChangeSelectedCommitteeIds($event)"
    ></app-committee-field>

    <div *ngIf="mode === 'create' && !env.isBot">
      <ng-container *ngIf="isLoggedMessenger && isActiveTab; else createChatButton">
        <tui-select
          class="field field_chat"
          tabindex="13"
          formControlName="groupChat"
          [tuiDropdown]="chatDropdownContent"
          [tuiDropdownManual]="chatsListVisible"
          [valueContent]="chatContent"
          [tuiTextfieldSize]="tuiElementLargeSize"
          [class.tui-skeleton]="loading"
          [class.tui-skeleton_text]="loading"
          [tuiTextfieldCleaner]="!infoForm.get('fillOnlineLink')?.value"
          (tuiActiveZoneChange)="onChatActiveZoneChange()"
          (ngModelChange)="onGroupChatChange($event)"
          (click)="onClickChatField()"
        >
          {{ 'components.eventInfo.fields.groupChat' | translate }}
          <ng-template #chatDropdownContent>
            <div class="chat-dropdown-template">
              <button tuiButton class="chat-dropdown-template__button" [size]="'m'" (click)="onChatButtonCreate()">
                {{ 'components.eventInfo.buttons.createNewChat' | translate }}
              </button>
              <app-search-chats
                [chatsFilter]="chatsFilter"
                (chatSelectedChange)="changeChat($event)"
              ></app-search-chats>
            </div>
          </ng-template>

          <ng-template #chatContent let-data>
            <div class="content">
              <ng-container *ngIf="data === 'new'; else chatItemView">
                {{ 'components.eventInfo.labels.createNewChat' | translate }}
              </ng-container>
              <ng-template #chatItemView>
                <tui-avatar
                  size="xs"
                  class="content__avatar"
                  [avatarUrl]="(data && data.userPhotoPath ? data.userPhotoPath : null) | safe : 'url'"
                  [text]="data.title"
                ></tui-avatar>
                <div class="content__title">{{ data.title }}</div>
              </ng-template>
            </div>
          </ng-template>
        </tui-select>
      </ng-container>
      <ng-template #createChatButton>
        <button
          *ngxPermissionsOnly="'eventChatCreating'"
          tuiButton
          class="event-info-edit-button event-info-edit-button_full"
          type="button"
          appearance="primary"
          [class.tui-skeleton]="loading"
          [class.tui-skeleton_text]="loading"
          [size]="tuiElementLargeSize"
          (click)="onClickMessengerLoginButton()"
        >
          {{ 'components.eventInfo.buttons.selectGroupChat' | translate }}
        </button>
      </ng-template>
    </div>

    <!-- TODO: ngxPermissionsExcept - временное решение -->
    <div
      *ngxPermissionsExcept="['eventHideFieldsEditing']"
      [class.tui-skeleton]="loading"
      [class.tui-skeleton_text]="loading"
    >
      <app-field-wrapper
        [text]="'components.eventInfo.fields.eventIsVisibleToAll' | translate"
        [size]="tuiElementMediumSize"
      >
        <app-toggle tabindex="14" [size]="'l'" formControlName="isVisibleToAll"></app-toggle>
      </app-field-wrapper>
    </div>

    <div class="field" tabindex="15">
      <tui-input-files
        *ngIf="!infoForm.get('program')?.value"
        class="size_{{ tuiElementLargeSize }}"
        [link]="'components.eventInfo.fields.program' | translate"
        [label]="'common.fields.fileLabel' | translate"
        formControlName="program"
        [class.tui-skeleton]="loading"
        [class.tui-skeleton_text]="loading"
        [maxFileSize]="maxDocFileSize"
        [tuiTextfieldSize]="tuiElementLargeSize"
        [size]="tuiElementLargeSize"
      >
        <input tuiInputFiles accept="image/*, .pdf, .doc, .docx, .rtf" />
      </tui-input-files>
      <tui-files
        *ngIf="!!infoForm.get('program')?.value"
        [class.tui-skeleton]="loading"
        [class.tui-skeleton_text]="loading"
      >
        <tui-file
          class="size_{{ tuiElementLargeSize }}"
          [file]="infoForm.get('program')?.value"
          (removed)="infoForm.get('program')?.reset()"
        ></tui-file>
      </tui-files>
    </div>

    <div class="field" tabindex="16">
      <tui-input-files
        class="size_{{ tuiElementLargeSize }}"
        [link]="'components.eventInfo.fields.additionalDocs' | translate"
        [label]="'common.fields.fileLabel' | translate"
        formControlName="docs"
        [class.tui-skeleton]="loading"
        [class.tui-skeleton_text]="loading"
        [maxFileSize]="maxDocFileSize"
        [tuiTextfieldSize]="tuiElementLargeSize"
        [size]="tuiElementLargeSize"
      >
        <input tuiInputFiles [multiple]="true" />
      </tui-input-files>
      <tui-files class="tui-space_top-1" [class.tui-skeleton]="loading" [class.tui-skeleton_text]="loading">
        <tui-file
          *ngFor="let file of infoForm.get('docs')?.value"
          class="size_{{ tuiElementLargeSize }}"
          [file]="file"
          (removed)="removeDocsFile(file)"
        ></tui-file>
      </tui-files>
    </div>

    <!-- TODO: ngxPermissionsExcept - временное решение -->
    <div
      *ngxPermissionsExcept="['eventHideFieldsEditing']"
      class="field"
      tabindex="17"
      [class.tui-skeleton]="loading"
      [class.tui-skeleton_text]="loading"
    >
      <search-users-input
        formControlName="contactPerson"
        mode="single"
        [textfieldSize]="tuiElementLargeSize"
        [label]="'components.eventInfo.fields.contactPerson' | translate"
      ></search-users-input>
    </div>

    <!-- TODO: ngxPermissionsExcept - временное решение -->
    <div
      *ngxPermissionsExcept="['eventHideFieldsEditing']"
      class="field"
      tabindex="18"
      [class.tui-skeleton]="loading"
      [class.tui-skeleton_text]="loading"
    >
      <app-users-table mode="edit" [users]="membersList" (changeValue)="onChangeParticipants($event)"></app-users-table>
    </div>
  </div>

  <div *ngIf="!loading" class="event-info-edit__buttons">
    <button
      tuiButton
      class="event-info-edit-button event-info-edit-button_edit"
      type="button"
      appearance="primary"
      [size]="tuiElementLargeSize"
      (click)="onClickSaveButton()"
    >
      {{ 'common.buttons.save' | translate }}
    </button>
    <button
      tuiButton
      class="event-info-edit-button event-info-edit-button_cancel"
      type="button"
      appearance="secondary"
      [size]="tuiElementLargeSize"
      (click)="onClickCancelButton()"
    >
      {{ 'common.buttons.cancel' | translate }}
    </button>
  </div>
</form>
