<app-user-info-view
  *ngIf="mode === 'view'"
  [organisationId]="organisationId"
  [data]="data$ | async"
  [committeesList]="committeesList$ | async"
  [allowDeleting]="allowDeleting"
  (startEditing)="onStartEditing()"
  (deleted)="onDelete($event)"
  [externalLoading]="loading"
></app-user-info-view>

<app-user-info-edit
  *ngIf="mode !== 'view'"
  [mode]="mode"
  [organisationId]="organisationId"
  [isParentOrganisation]="isParentOrganisation"
  [data]="data$ | async"
  (saved)="onSaveData($event)"
  (canceled)="onCancel()"
  (onPhoneChange)="onChangePhone($event)"
></app-user-info-edit>
