import { Pipe, PipeTransform } from '@angular/core';
import { formatDate } from '@angular/common';
import { Language } from '@src/core';

@Pipe({
  name: 'datePeriod',
})
export class DatePeriodPipe implements PipeTransform {
  transform(dateStart: Date | undefined, dateEnd: Date | undefined, dateTime: boolean = true): string | undefined {
    if (!dateStart || !dateEnd) return;

    let period = '';

    if (
      dateStart.getDate() === dateEnd.getDate() &&
      dateStart.getMonth() === dateEnd.getMonth() &&
      dateStart.getFullYear() === dateEnd.getFullYear()
    ) {
      period = formatDate(dateStart, 'dd.MM.yy', Language.RU);
      const timeStart = formatDate(dateStart, 'HH:mm', Language.RU);
      const timeEnd = formatDate(dateEnd, 'HH:mm', Language.RU);
      period += ` ${timeStart} - ${timeEnd}`;
    } else {
      const dateStartString = formatDate(dateStart, `dd.MM.yy${dateTime ? ' HH:mm' : ''}`, Language.RU);
      const dateEndString = formatDate(dateEnd, `dd.MM.yy${dateTime ? ' HH:mm' : ''}`, Language.RU);
      period = `${dateStartString} - ${dateEndString}`;
    }

    return period;
  }
}
