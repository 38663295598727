import { Component, Input } from '@angular/core';
import { ChatMemberModel, OrganisationJobTitleUI, UserUI } from '@src/models';
import { OrganisationWithJobTitles } from '@src/api';
import { JobTitlesPipe } from '@src/app/shared/pipes';
import { getImageSrc } from '@src/utils';
import { ObjectId } from '@src/types/id';
import { formatDate } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';

import { ItemType } from '../model/item.model';

@Component({
  selector: 'app-list-item-users',
  templateUrl: './list-item-users.component.html',
  styleUrls: ['../styles/list-item.component.scss', './list-item-users.component.scss'],
})
export class ListItemUsersComponent {
  @Input() set item(data: ItemType | undefined) {
    this.data = data as ChatMemberModel | UserUI;

    if (data && 'dateEdited' in data) {
      this.editDateNormalize = this.getDateNormalize(data.dateEdited);
    }
  }

  @Input() selectedId: ObjectId;
  @Input() loading?: boolean;

  data?: ChatMemberModel | UserUI;

  editDateNormalize?: string;

  constructor(private jobTitlesPipe: JobTitlesPipe, private readonly translateService: TranslateService) {}

  get userAvatarUrl(): string {
    let photoIdPath;
    if (this.data && 'photoId' in this.data) {
      photoIdPath = getImageSrc(this.data.photoId);
    }
    return this.data?.photoPath ?? photoIdPath ?? '';
  }

  get jobTitles(): OrganisationWithJobTitles[] | OrganisationJobTitleUI[] {
    let organisationJobTitles: OrganisationWithJobTitles[] | OrganisationJobTitleUI[] = [];

    if (this.data) {
      // TODO: refactoring
      if ('jobTitlesInOrganisation' in this.data && this.data.jobTitlesInOrganisation) {
        organisationJobTitles = this.data.jobTitlesInOrganisation as OrganisationWithJobTitles[];
      } else if ('organisationJobTitles' in this.data && this.data.organisationJobTitles) {
        organisationJobTitles = this.jobTitlesPipe.transform(this.data.organisationJobTitles);
      }
    }

    const organisationWithJobTitles: OrganisationWithJobTitles[] = [];
    organisationJobTitles.forEach(organisationJobTitle => {
      if (
        !!organisationWithJobTitles.find(
          organisation => organisation.organisationId === organisationJobTitle.organisationId,
        )
      )
        return;
      organisationWithJobTitles.push(organisationJobTitle);
    });

    return organisationWithJobTitles ?? [];
  }

  private getDateNormalize(dateEdited?: string): string {
    if (!dateEdited) return '';

    const date = new Date(dateEdited);

    const normalizedDate = formatDate(date, 'dd MMMM', this.translateService.instant('languageCode'), 'UTC');
    const time = formatDate(date, 'HH:mm', 'en', 'UTC');

    return this.translateService.instant('components.listItemUsers.labels.editedDate', {
      date: normalizedDate,
      time,
    });
  }
}
