<form *ngIf="!!form" class="notifications" [formGroup]="form">
  <ng-container *ngFor="let formGroupName of formGroupsNames">
    <div class="notifications__item">
      <div class="title">{{ getFormControl(formGroupName, 'title').value }}</div>
      <div class="description">{{ getFormControl(formGroupName, 'description').value }}</div>

      <ng-container *ngFor="let sendByNotification of SEND_BY_NOTIFICATIONS">
        <ng-container *ngIf="!sendByNotification.notShowFor || !sendByNotification.notShowFor.includes(formGroupName)">
          <div *ngIf="!sendByNotification.default" class="separator"></div>

          <app-field-wrapper [text]="sendByNotification.title | translate">
            <app-toggle [formControl]="getFormControl(formGroupName, sendByNotification.code)"></app-toggle>
          </app-field-wrapper>

          <div *ngIf="!!sendByNotification.description" class="description">
            {{ sendByNotification.description | translate }}
          </div>
        </ng-container>
      </ng-container>
    </div>
  </ng-container>
</form>
