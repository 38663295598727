import { Injectable } from '@angular/core';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { BreakpointsRules, ScreenDimension, ScreenTypes } from '@src/models';
import { TuiSizeL, TuiSizeS, TuiSizeXL, TuiSizeXXL } from '@taiga-ui/core';

@Injectable({
  providedIn: 'root',
})
export class BreakpointObserverHelperService {
  screenType: ScreenTypes = 'extra-large';
  screenDimension: ScreenDimension = 'desktop';

  constructor(private readonly breakpointObserver: BreakpointObserver) {
    this.breakpointObserver.observe(this.breakpointsSet).subscribe((state: BreakpointState) => {
      this.screenType = this.getScreenType(state);

      this.screenDimension = this.getScreenTypesBiggerThanTarget('medium').includes(this.screenType)
        ? 'desktop'
        : 'mobile';
    });
  }

  getScreenType(breakpointState: BreakpointState): ScreenTypes {
    return breakpointState.breakpoints[BreakpointsRules.xxlBreakpoint]
      ? 'double-extra-large'
      : breakpointState.breakpoints[BreakpointsRules.xlBreakpoint]
      ? 'extra-large'
      : breakpointState.breakpoints[BreakpointsRules.lgBreakpoint]
      ? 'large'
      : breakpointState.breakpoints[BreakpointsRules.mdBreakpoint]
      ? 'medium'
      : breakpointState.breakpoints[BreakpointsRules.smBreakpoint]
      ? 'small'
      : 'extra-small';
  }

  getScreenTypesSmallerThanTarget(target: ScreenTypes, includeTarget: boolean = true): ScreenTypes[] {
    let smallerScreenTypes: ScreenTypes[];

    switch (target) {
      case 'small':
        smallerScreenTypes = ['extra-small'];
        break;
      case 'medium':
        smallerScreenTypes = ['extra-small', 'small'];
        break;
      case 'large':
        smallerScreenTypes = ['extra-small', 'small', 'medium'];
        break;
      case 'extra-large':
        smallerScreenTypes = ['extra-small', 'small', 'medium', 'large'];
        break;
      case 'double-extra-large':
        smallerScreenTypes = ['extra-small', 'small', 'medium', 'large', 'extra-large'];
        break;
      default:
        smallerScreenTypes = [];
        break;
    }

    return includeTarget ? [...smallerScreenTypes, target] : smallerScreenTypes;
  }

  getScreenTypesBiggerThanTarget(target: ScreenTypes, includeTarget: boolean = true): ScreenTypes[] {
    let biggerScreenTypes: ScreenTypes[];

    switch (target) {
      case 'extra-small':
        biggerScreenTypes = ['small', 'medium', 'large', 'extra-large', 'double-extra-large'];
        break;
      case 'small':
        biggerScreenTypes = ['medium', 'large', 'extra-large', 'double-extra-large'];
        break;
      case 'medium':
        biggerScreenTypes = ['large', 'extra-large', 'double-extra-large'];
        break;
      case 'large':
        biggerScreenTypes = ['extra-large', 'double-extra-large'];
        break;
      case 'extra-large':
        biggerScreenTypes = ['double-extra-large'];
        break;
      default:
        biggerScreenTypes = [];
    }

    return includeTarget ? [target, ...biggerScreenTypes] : biggerScreenTypes;
  }

  get breakpointsSet(): BreakpointsRules[] {
    return [
      BreakpointsRules.xsBreakpoint,
      BreakpointsRules.smBreakpoint,
      BreakpointsRules.mdBreakpoint,
      BreakpointsRules.lgBreakpoint,
      BreakpointsRules.xlBreakpoint,
      BreakpointsRules.xxlBreakpoint,
    ];
  }

  get screenTypesLargeSet(): ScreenTypes[] {
    return this.getScreenTypesBiggerThanTarget('large');
  }

  get tuiElementSmallSize(): TuiSizeS {
    return this.screenTypesLargeSet.includes(this.screenType) ? 'm' : 's';
  }

  get tuiElementMediumSize(): TuiSizeS {
    return this.screenTypesLargeSet.includes(this.screenType) ? 'm' : 's';
  }

  get tuiElementLargeSize(): TuiSizeL {
    return this.screenTypesLargeSet.includes(this.screenType) ? 'l' : 'm';
  }

  get tuiElementDoubleExtraLargeSize(): TuiSizeXXL | TuiSizeXL {
    return this.isLargeScreen ? 'xxl' : 'xl';
  }

  get isLargeScreen(): boolean {
    return this.getScreenTypesBiggerThanTarget('large').includes(this.screenType);
  }

  get isExtraLargeScreen(): boolean {
    return this.getScreenTypesBiggerThanTarget('extra-large').includes(this.screenType);
  }

  get isDoubleExtraLargeScreen(): boolean {
    return this.getScreenTypesBiggerThanTarget('double-extra-large').includes(this.screenType);
  }

  get isMobile() {
    return this.screenDimension === 'mobile';
  }

  get isDesktop() {
    return this.screenDimension === 'desktop';
  }
}
