<div
  *ngIf="!!membershipStatus"
  class="navigation-message"
  [ngClass]="isMembershipPaid ? 'navigation-message_info' : 'navigation-message_warning'"
  tuiHintDescribe="message"
  tuiHintDirection="right"
  [tuiHint]="mode === 'desktop' && isOpen ? '' : (membershipStatus | translate)"
  [tuiHintShowDelay]="300"
  [tuiHintHideDelay]="0"
>
  <app-icon class="navigation-message__icon" [src]="'alert-circle'"></app-icon>

  <span *ngIf="mode === 'desktop' && isOpen" class="navigation-message__title">{{ membershipStatus | translate }}</span>
</div>
