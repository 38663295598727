import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { EnvService } from '@src/app/modules/env';
import { RegistrationService } from '@src/app/modules/registration/services/registration.service';
import { DocumentService } from '@src/core/services';
import { Nullable, Optional } from '@src/types/utils';

/**
 * Компонент для отображения ссылки
 * @param {string} fileLink - ссылка на файл
 * @param {boolean} isLocalFile - признак локального файла (по-умолчанию true)
 * @param {string} fileId - id файла
 * @param {boolean} showIcon - признак отображения иконки (по-умолчанию true)
 * @param {boolean} pseudo - признак отображения нижнего пунктирного подчёркивания (по-умолчанию true)
 *
 * @description Использовать нужно один из параметров. Подпись передаётся в контенте
 */
@Component({
  selector: 'app-download-link',
  templateUrl: './download-link.component.html',
  styleUrls: ['./download-link.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DownloadLinkComponent {
  @Input() fileLink?: string;
  @Input() isLocalFile = true;
  @Input() fileId?: string;
  @Input() fileName?: string;
  @Input() showIcon = true;
  @Input() pseudo = true;
  @Input() isModeration: Optional<Nullable<boolean>> = false;

  constructor(
    private readonly env: EnvService,
    private readonly documentService: DocumentService,
    private readonly registrationService: RegistrationService,
  ) {}

  openLink() {
    if (this.fileId) {
      if (this.isModeration) {
        this.registrationService.downloadDocumentById(this.fileId, this.fileName);
      } else {
        this.documentService.downloadDocumentById(this.fileId);
      }
    }

    if (this.fileLink) {
      this.env.openLink(this.fileLink, this.isLocalFile);
    }
  }
}
