<app-skeleton-wrapper [loading]="loading">
  <tui-scrollbar *ngIf="!!items?.length; else noDataTmp" class="list-wrapper">
    <div class="list">
      <div *ngFor="let item of items">
        <button class="list__item" [class.list__item_selected]="selectedId === item.id" (click)="onToggleItem(item)">
          <ng-container *ngIf="itemContentTmp; else simpleTmp">
            <ng-container *ngTemplateOutlet="itemContentTmp; context: { $implicit: item }"></ng-container>
          </ng-container>
        </button>

        <div class="list__divider"></div>
      </div>
    </div>
  </tui-scrollbar>
</app-skeleton-wrapper>

<ng-template #simpleTmp let-item="item">
  <app-new-list-item-user [user]="item"></app-new-list-item-user>
</ng-template>

<ng-template #noDataTmp>
  <div *ngIf="!items?.length" class="list__no-data">
    <span class="tui-text_body-m">
      {{ 'common.labels.noData' | translate }}
    </span>
  </div>
</ng-template>
