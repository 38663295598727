import { Component, ChangeDetectionStrategy, Inject, ChangeDetectorRef } from '@angular/core';
import { POLYMORPHEUS_CONTEXT } from '@tinkoff/ng-polymorpheus';
import { TuiDialogContext } from '@taiga-ui/core';
import { ResizableBaseComponent } from '@src/app/components/resizable-base-component';
import { BreakpointObserver } from '@angular/cdk/layout';
import { BreakpointObserverHelperService } from '@src/core/services';

@Component({
  selector: 'lp-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfirmDialogComponent extends ResizableBaseComponent {
  constructor(
    readonly cdr: ChangeDetectorRef,
    readonly breakpointObserver: BreakpointObserver,
    readonly breakpointObserverHelperService: BreakpointObserverHelperService,
    @Inject(POLYMORPHEUS_CONTEXT)
    private readonly context: TuiDialogContext<void | boolean, string>,
  ) {
    super(cdr, breakpointObserver, breakpointObserverHelperService);
  }

  get text() {
    return this.context.data;
  }

  cancel() {
    this.context.completeWith();
  }

  confirm() {
    this.context.completeWith(true);
  }
}
