import { Component, OnInit, ChangeDetectionStrategy, Input, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { MessageContentUnion } from '@airgram/core/types/outputs';
import { MessageAnimation } from '@airgram/core/types/outputs/MessageContent';
import { FilePart } from '@airgram/web';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FileReaderService } from '@src/core/services';
import { MultimediaSource } from '@src/models';

@Component({
  selector: 'app-message-animation',
  templateUrl: './message-animation.component.html',
  styleUrls: ['../styles/message-content.scss', './message-animation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MessageAnimationComponent implements OnInit, OnDestroy {
  @Input() set message(mess: MessageContentUnion | undefined) {
    this.messageAnimation = mess as MessageAnimation;
  }

  messageAnimation?: MessageAnimation;
  messageAnimationSource?: MultimediaSource;

  private destroyed$$: Subject<void>;

  constructor(private cdr: ChangeDetectorRef, private fileReaderService: FileReaderService) {
    this.destroyed$$ = new Subject<void>();
  }

  ngOnInit(): void {
    this.initAnimation();
  }

  ngOnDestroy(): void {
    this.destroyed$$.next();
    this.destroyed$$.complete();
  }

  private initAnimation(): void {
    if (!this.messageAnimation) return;

    this.fileReaderService
      .getFilePart(this.messageAnimation?.animation?.animation)
      .pipe(takeUntil(this.destroyed$$))
      .subscribe(filePart => this.setAnimationSource(filePart));
  }

  private setAnimationSource(filePart?: FilePart): void {
    this.messageAnimationSource = { src: this.fileReaderService.getFileSource(filePart) };
    this.cdr.markForCheck();
  }
}
