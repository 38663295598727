import { SelectAllLinkMode } from '../components/select-all-link';
import { ItemWithId } from '../types';

export const checkAddOrRemoveItemsStatus = <T extends ItemWithId>(items: T[], result: T[]): SelectAllLinkMode => {
  let selectedItems = 0;

  items.forEach(item => {
    if (result.some(({ id }) => item.id === id)) {
      selectedItems++;
    }
  });

  if (items.length === selectedItems) {
    return 'remove-all';
  }

  return 'select-all';
};
