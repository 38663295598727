<div class="message-video">
  <div class="message-video__message-content message-content">
    <div class="message-video__video-wrapper" [class.tui-skeleton]="!video.src">
      <video
        #video
        tuiMedia
        controls
        [src]="messageVideoSource?.src | safe : 'url'"
        [title]="messageVideo?.video?.fileName || ('components.messageVideo.labels.video' | translate)"
        [poster]="messageVideoPosterSource?.src | safe : 'url'"
      ></video>
    </div>
    <ng-content></ng-content>
  </div>
</div>
