/**
 * Unions API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { OrganisationRolesJobTitles } from './organisationRolesJobTitles';


/**
 * Редактировать пользователя с обновленем организации.
 */
export interface EditUserWithOrganisationUpdate { 
    /**
     * Имя.
     */
    firstName?: string;
    /**
     * Отчество.
     */
    middleName?: string;
    /**
     * Фамилия.
     */
    lastName?: string;
    /**
     * Пол.
     */
    gender?: string;
    /**
     * Электронная почта.
     */
    email?: string;
    /**
     * Телефон.
     */
    phone?: string;
    /**
     * Описание.
     */
    aboutMessage?: string;
    /**
     * Отображать ли сотрудника в контактах компании.
     */
    isPublicContact?: boolean;
    /**
     * 
     */
    telegramId?: number;
    /**
     * Должность.
     */
    jobTitleDescription?: string;
    /**
     * Дата рождения.
     */
    birthDate?: string;
    /**
     * Присылать оповещение о новом мероприятии/опросе на почту.
     */
    notifyByEmail?: boolean;
    /**
     * потребности.
     */
    needs?: string;
    /**
     * возможности.
     */
    resources?: string;
    /**
     * хобби.
     */
    hobbies?: string;
    /**
     * взнос оплачен.
     */
    isFeePaid?: boolean;
    /**
     * ID куратора.
     */
    curatorId?: string;
    /**
     * полномочия действительны до.
     */
    authorityValidTill?: string;
    /**
     * Дата принятия соглашения.
     */
    policiesAcceptanceDate?: string;
    phoneAdditional?: string;
    whatsappNumber?: string;
    /**
     * Организации пользователя, роли и должности на обновление.
     */
    organisations?: Array<OrganisationRolesJobTitles>;
    /**
     * Организации пользователя, роли и должности на добавление.
     */
    organisationsToAdd?: Array<OrganisationRolesJobTitles>;
    /**
     * Организации пользователя, роли и должности на удалеине.
     */
    organisationsToDelete?: Array<string>;
    /**
     * Идентификатор.
     */
    id?: string;
}

