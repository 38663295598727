import { ChangeDetectionStrategy, Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { APP_CONFIG, Language } from '@src/core';
import { StorageKeys } from '@src/constants/storage';
import { StorageService } from '@src/app/modules/auth';

@Component({
  selector: 'app-language-switcher-mini',
  templateUrl: './language-switcher-mini.component.html',
  styleUrls: ['./language-switcher-mini.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LanguageSwitcherMiniComponent {
  selectedLanguage = this.translateService.currentLang;
  readonly languageCodes = APP_CONFIG.localizations;

  constructor(private readonly translateService: TranslateService, private readonly storageService: StorageService) {}

  selectLanguage(code: Language) {
    this.storageService.setItem(StorageKeys.Locale, code);
    this.translateService.use(code);

    // TODO: убрать после исправления ошибки #9372
    if (this.translateService.currentLang !== code) {
      location.reload();
    }
  }
}
