import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { TuiButtonModule, TuiScrollbarModule } from '@taiga-ui/core';
import { SharedModule } from '@src/app/shared';
import { HintModule } from '@src/app/modules/hint';
import { NavigationModule } from '@src/app/modules/navigation';

import { LpSettingsComponent } from './lp-settings.component';

@NgModule({
  declarations: [LpSettingsComponent],
  imports: [
    CommonModule,
    HintModule,
    RouterModule,
    SharedModule,
    TuiButtonModule,
    TuiScrollbarModule,
    NavigationModule,
  ],
  exports: [LpSettingsComponent],
})
export class LpSettingsModule {}
