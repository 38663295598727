import { Pipe, PipeTransform } from '@angular/core';
import { ChatActionUnion, ChatTypeUnion } from '@airgram/web';
import { TelegramMessengerService } from '@src/app/modules/telegram';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'actionStatus',
})
export class ActionStatusPipe implements PipeTransform {
  constructor(
    private messengerService: TelegramMessengerService,
    private readonly translateService: TranslateService,
  ) {}

  async transform(
    action: ChatActionUnion | undefined,
    chatType?: ChatTypeUnion,
    userId?: number,
  ): Promise<string | undefined> {
    let actionStatus: string | undefined;
    let author: string = '';

    if (!action) return undefined;

    switch (action._) {
      case 'chatActionCancel':
        actionStatus = undefined;
        break;

      case 'chatActionTyping':
        actionStatus = this.translateService.instant('components.actionStatus.labels.chatActionTyping');
        break;

      case 'chatActionRecordingVoiceNote':
        actionStatus = this.translateService.instant('components.actionStatus.labels.chatActionRecordingVoiceNote');
        break;

      case 'chatActionRecordingVideo':
      case 'chatActionRecordingVideoNote':
        actionStatus = this.translateService.instant('components.actionStatus.labels.chatActionRecordingVideo');
        break;

      case 'chatActionUploadingDocument':
        actionStatus = this.translateService.instant('components.actionStatus.labels.chatActionUploadingDocument');
        break;

      case 'chatActionUploadingPhoto':
        actionStatus = this.translateService.instant('components.actionStatus.labels.chatActionUploadingPhoto');
        break;

      case 'chatActionUploadingVideo':
      case 'chatActionUploadingVideoNote':
        actionStatus = this.translateService.instant('components.actionStatus.labels.chatActionUploadingVideo');
        break;

      case 'chatActionUploadingVoiceNote':
        actionStatus = this.translateService.instant('components.actionStatus.labels.chatActionUploadingVoiceNote');
        break;

      case 'chatActionChoosingContact':
        actionStatus = this.translateService.instant('components.actionStatus.labels.chatActionChoosingContact');
        break;

      case 'chatActionChoosingLocation':
        actionStatus = this.translateService.instant('components.actionStatus.labels.chatActionChoosingLocation');
        break;

      default:
        break;
    }

    if (!chatType || !userId) return actionStatus;

    switch (chatType._) {
      case 'chatTypePrivate':
      case 'chatTypeSecret':
        author = '';
        break;

      case 'chatTypeBasicGroup':
      case 'chatTypeSupergroup':
        author = await this.messengerService.getUserFullName(userId);
        break;

      default:
        break;
    }

    return author ? `${author} ${actionStatus}` : actionStatus;
  }
}
