<app-label [label]="name">
  <tui-input
    *ngIf="mode === 'view'"
    class="view"
    [ngModel]="value"
    [tuiTextfieldSize]="size"
    [tuiTextfieldLabelOutside]="true"
    [readOnly]="true"
  >
    {{ name }}
    <input tuiTextfield appTestingId="Input.Text" />
  </tui-input>
</app-label>
