import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

import { MOBILE_NAVIGATION } from '../constants';

@Component({
  selector: 'app-navigation-mobile',
  templateUrl: './navigation-mobile.component.html',
  styleUrls: ['./navigation-mobile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavigationMobileComponent {
  @Input() isBrand?: boolean;

  navTree = MOBILE_NAVIGATION;
}
