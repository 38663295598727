import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';
import { takeUntil } from 'rxjs';
import { ResizableBaseComponent } from '@src/app/components';
import { BreakpointObserverHelperService, OrganisationService, UserService } from '@src/core/services';

import { NavigationMode } from '../types';

import { MEMBERSHIP_STATUS } from './constants';

@Component({
  selector: 'navigation-message',
  templateUrl: './navigation-message.component.html',
  styleUrls: ['./navigation-message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavigationMessageComponent extends ResizableBaseComponent {
  @Input() isOpen?: boolean = true;
  @Input() mode?: NavigationMode;

  isMembershipPaid?: boolean = true;
  membershipStatus: string = '';

  readonly authUser$ = this.userService.authUser$;

  constructor(
    readonly cdr: ChangeDetectorRef,
    readonly breakpointObserver: BreakpointObserver,
    readonly breakpointObserverHelperService: BreakpointObserverHelperService,
    private readonly userService: UserService,
    private readonly organisationService: OrganisationService,
  ) {
    super(cdr, breakpointObserver, breakpointObserverHelperService);
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.authUser$.pipe(takeUntil(this.destroyed$$)).subscribe(user => {
      if (!user?.parentOrganisationId) return;

      this.isMembershipPaid = true;
      this.membershipStatus = MEMBERSHIP_STATUS.paid;
      if (user.parentOrganisationId && user.organisationId && !user.isFeePaid) {
        this.organisationService.getOrganisationData(user.organisationId).then(organisation => {
          const membershipPaidTillDate = organisation.membershipPaidTill
            ? new Date(organisation.membershipPaidTill)
            : undefined;
          this.isMembershipPaid = membershipPaidTillDate ? organisation.isMembershipPaid : true;
          if (!this.isMembershipPaid) {
            this.membershipStatus = MEMBERSHIP_STATUS.expired;
          } else if (membershipPaidTillDate) {
            const daysToToday = Math.ceil(
              Math.abs(membershipPaidTillDate.getTime() - new Date().getTime()) / (1000 * 3600 * 24),
            );
            if (daysToToday < 14) {
              this.membershipStatus = MEMBERSHIP_STATUS.expireInTwoWeeks;
            } else if (daysToToday <= 31) {
              this.membershipStatus = MEMBERSHIP_STATUS.expireInMonth;
            } else {
              this.membershipStatus = MEMBERSHIP_STATUS.paid;
            }
          }
        });
      } else if (user.isFeePaid) {
        this.isMembershipPaid = false;
        this.membershipStatus = MEMBERSHIP_STATUS.expired;
      }
    });
  }
}
