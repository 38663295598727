/**
 * Unions API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Профиль организации. Расширенные настройки.
 */
export interface OrganisationProfileExt { 
    /**
     * Название на русском.
     */
    nameRu?: string;
    /**
     * Идентификатор ОПФ.
     */
    opfTypeId?: number;
    /**
     * Адрес фактический.
     */
    addressPhysical?: string;
    /**
     * Адрес юридический.
     */
    addressLegal?: string;
    /**
     * Дата вступления в объединение.
     */
    joinedDate?: string;
}

