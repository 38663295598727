import { ChatTypeUnion } from '@airgram/web';

import { AddCommitteeSubscriptionWithSendTo } from '../api';

import { UserUI } from './user.model';

export interface GroupUI extends AddCommitteeSubscriptionWithSendTo {
  id?: string;
  name?: string;
  description?: string;
  isChannel: boolean;
  photo?: File;
  photoId?: string;
  members?: UserUI[] | null;
  membersTelegramIds?: number[];
  membersIds?: string[];
  unregistredMembersIds?: string[];
  inviteLink?: string;
  organisationId?: string;
  active?: boolean; // TODO delete
  type?: ChatTypeUnion;
}
