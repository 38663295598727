<div class="contact__type">
  <tui-select
    formControlName="contactTypeId"
    [valueContent]="contactTypes ? contactTypesStringify(contactTypes) : ''"
    [tuiTextfieldSize]="tuiElementLargeSize"
    [class.tui-skeleton]="loading"
    [class.tui-skeleton_text]="loading"
  >
    <!-- Изменить -->
    {{ 'components.contacts.fields.contactType' | translate }}
    <tui-data-list *tuiDataList>
      <button *ngFor="let contactType of contactTypes" tuiOption [value]="contactType.id">
        {{ contactType.description | translate }}
      </button>
    </tui-data-list>
  </tui-select>
</div>
