import { Inject, Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { TuiAlertService, TuiNotification } from '@taiga-ui/core';
import { TuiAlertOptions } from '@taiga-ui/core';
import { TuiBaseDialogContext } from '@taiga-ui/cdk';
import { PolymorpheusContent } from '@tinkoff/ng-polymorpheus';
import { TranslateService } from '@ngx-translate/core';

type AutoCloseProps = boolean | number;
type MessageProps<G = void, T = any> = string | PolymorpheusContent<TuiBaseDialogContext<G> & T>;
type AlertOptions<T = unknown> = Partial<
  Omit<TuiAlertOptions<T>, 'status' | 'hasIcon' | 'hasCloseButton' | 'data' | 'autoClose'>
> & {
  autoClose?: AutoCloseProps;
};

@Injectable({
  providedIn: 'root',
})
export class AlertService {
  constructor(
    @Inject(TuiAlertService) private readonly tuiAlertService: TuiAlertService,
    private readonly translateService: TranslateService,
  ) {}

  private show(message: MessageProps, status: TuiNotification, options?: AlertOptions | AutoCloseProps) {
    let params: AlertOptions;
    let autoClose: AutoCloseProps | undefined;

    if (typeof options === 'boolean' || typeof options === 'number') {
      params = {};
      autoClose = options;
    } else {
      const { autoClose: optionsAutoClose, ...rest } = options ?? {};

      params = rest;
      autoClose = optionsAutoClose;
    }

    this.tuiAlertService
      .open(message, {
        ...params,
        status,
        autoClose: autoClose ?? true,
        hasIcon: true,
        hasCloseButton: true,
      })
      .subscribe();
  }

  success(message: MessageProps, options?: AlertOptions | AutoCloseProps) {
    return this.show(message, TuiNotification.Success, options);
  }

  warning(message: MessageProps, options?: AlertOptions | AutoCloseProps) {
    this.show(message, TuiNotification.Warning, options);
  }

  info(message: MessageProps, options?: AlertOptions | AutoCloseProps) {
    this.show(message, TuiNotification.Info, options);
  }

  async error(message: MessageProps, options?: AlertOptions | AutoCloseProps) {
    // TODO: временное решение. заменить это на interceptor
    if (typeof message !== 'string' && message instanceof HttpErrorResponse) {
      const error: HttpErrorResponse = message;

      if (!!error?.status && error?.status === 403) {
        message = this.translateService.instant('common.alerts.errors.error403');
      } else if (error?.error instanceof Blob) {
        if (error?.error?.type === 'application/json') {
          const json = await error?.error.text();
          const serverError = JSON.parse(json);
          if (typeof serverError === 'string') {
            message = serverError;
          } else if ('errorMessage' in serverError) {
            message = serverError.errorMessage;
          }
        }
      } else if (typeof error?.error === 'string') {
        message = error?.error;
      } else if (typeof error?.error?.errorMessage === 'string') {
        message = error?.error?.errorMessage;
      } else {
        const errors: string[] = [];
        errors.push(this.translateService.instant('common.alerts.errors.requestError'));
        errors.push(this.translateService.instant('common.alerts.errors.infoForAdmin'));
        errors.push(error?.status.toString() + ': ' + error?.statusText);
        errors.push(error?.error?.title);
        if (error?.error?.errors) {
          errors.push(...(Object.values(error?.error?.errors) as string[]));
        }
        message = errors.join(' ');
      }
    }

    this.show(message, TuiNotification.Error, options);
  }
}
