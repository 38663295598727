import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { LoyaltyService } from '@src/api';
import { map, startWith, switchMap } from 'rxjs/operators';
import { TUI_DEFAULT_MATCHER } from '@taiga-ui/cdk';
import { Nullable } from '@src/types/utils';

import { GroupItem, SelectItem } from '../types';

import { convertToRegions } from './utils/convertToRegions';
import { convertToCategories } from './utils/convertToCategories';

@Injectable({
  providedIn: 'root',
})
export class LoyaltyProgramFilterService {
  regionInUse = false;
  categoriesInUse = false;
  regionsUnionId?: string;
  categoriesUnionId?: string;

  readonly regions$ = new BehaviorSubject<Nullable<ReadonlyArray<SelectItem>>>(null);
  readonly categories$ = new BehaviorSubject<Nullable<ReadonlyArray<GroupItem>>>(null);

  private readonly searchCategories$ = new BehaviorSubject<string>('');
  private readonly searchRegions$ = new BehaviorSubject<string>('');

  readonly filteredCategories$ = this.searchCategories$.pipe(
    startWith(''),
    switchMap(search =>
      this.categories$.pipe(
        map(items => {
          if (items === null) {
            return items;
          }

          return items.reduce<GroupItem[]>((acc, item) => {
            const items = item.items.filter(({ name }) => TUI_DEFAULT_MATCHER(name, search));
            if (items.length) {
              acc.push({
                ...item,
                items,
              });
            }

            return acc;
          }, []);
        }),
      ),
    ),
  );

  readonly filteredRegions$ = this.searchRegions$.pipe(
    startWith(''),
    switchMap(search =>
      this.regions$.pipe(
        map(items => {
          if (items === null) {
            return items;
          }

          return items.filter(({ name }) => TUI_DEFAULT_MATCHER(name, search));
        }),
      ),
    ),
  );

  readonly regionsForUnion$ = new BehaviorSubject<Nullable<ReadonlyArray<SelectItem>>>([]);
  readonly categoriesForUnion$ = new BehaviorSubject<Nullable<ReadonlyArray<GroupItem>>>([]);

  private readonly searchCategoriesForUnion$ = new BehaviorSubject<string>('');
  private readonly searchRegionsForUnion$ = new BehaviorSubject<string>('');

  readonly filteredCategoriesForUnion$ = this.searchCategoriesForUnion$.pipe(
    startWith(''),
    switchMap(search =>
      this.categoriesForUnion$.pipe(
        map(items => {
          if (items === null) {
            return items;
          }

          return items.reduce<GroupItem[]>((acc, item) => {
            const items = item.items.filter(({ name }) => TUI_DEFAULT_MATCHER(name, search));
            if (items.length) {
              acc.push({
                ...item,
                items,
              });
            }

            return acc;
          }, []);
        }),
      ),
    ),
  );

  readonly filteredRegionsForUnion$ = this.searchRegionsForUnion$.pipe(
    startWith(''),
    switchMap(search =>
      this.regionsForUnion$.pipe(
        map(items => {
          if (items === null) {
            return items;
          }

          return items.filter(({ name }) => TUI_DEFAULT_MATCHER(name, search));
        }),
      ),
    ),
  );

  constructor(private readonly api: LoyaltyService) {}

  getRegions(inUse: boolean, force?: boolean, unionId?: string) {
    if (this.regions$.value?.length && inUse === this.regionInUse && unionId === this.regionsUnionId && !force) {
      // Если данные уже были загружены, то запрос не делаем
      return;
    }

    this.api.regionsList(inUse, unionId).subscribe(result => {
      this.regionInUse = inUse;
      this.regionsUnionId = unionId;
      this.regions$.next(convertToRegions(result ?? []));
    });
  }

  getCategories(inUse: boolean, force?: boolean, unionId?: string) {
    if (
      this.categories$.value?.length &&
      inUse === this.categoriesInUse &&
      unionId === this.categoriesUnionId &&
      !force
    ) {
      // Если данные уже были загружены, то запрос не делаем
      return;
    }

    this.api.categoryGroups(inUse, unionId).subscribe(result => {
      this.categoriesInUse = inUse;
      this.categoriesUnionId = unionId;
      this.categories$.next(convertToCategories(result ?? []));
    });
  }

  getRegionsForUnion(inUse: boolean, unionId?: string) {
    this.api.regionsList(inUse, unionId).subscribe(result => {
      this.regionsForUnion$.next(convertToRegions(result ?? []));
    });
  }

  getCategoriesForUnion(inUse: boolean, unionId?: string) {
    this.api.categoryGroups(inUse, unionId).subscribe(result => {
      this.categoriesForUnion$.next(convertToCategories(result ?? []));
    });
  }

  searchCategories(query: Nullable<string>) {
    this.searchByQuery(query, this.searchCategories$);
  }

  searchCategoriesForUnion(query: Nullable<string>) {
    this.searchByQuery(query, this.searchCategoriesForUnion$);
  }

  searchRegions(query: Nullable<string>) {
    this.searchByQuery(query, this.searchRegions$);
  }

  searchRegionsForUnion(query: Nullable<string>) {
    this.searchByQuery(query, this.searchRegionsForUnion$);
  }

  private searchByQuery(query: Nullable<string>, stream: BehaviorSubject<string>) {
    const length = query?.length ?? 0;
    if (length > 2) {
      stream.next(query ?? '');
    } else if (stream.value !== '') {
      stream.next('');
    }
  }
}
