/**
 * Unions API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Параметры поиска продуктов лояльности.
 */
export interface SearchParamsForLoyalty { 
    /**
     * Наименование продукта.
     */
    name?: string;
    /**
     * Список ID категорий.
     */
    categoryIds?: Array<string>;
    /**
     * Минимальная скидка.
     */
    discountRateMin?: number;
    /**
     * Список ID регионов.
     */
    regionIds?: Array<string>;
    /**
     * TRUE - искать среди своих продуктов; иначе среди всех.
     */
    isMyProducts?: boolean;
    /**
     * начало выборки (0 по-умолчанию)
     */
    start?: number;
    /**
     * кол-во (1000 по-умолчанию)
     */
    top?: number;
    /**
     * TRUE - отсортировать результате по полю datePublished в порядке убывания (новые в начале); иначе не учитывать.
     */
    sortByDatePublished?: boolean;
    /**
     * TRUE - отсортировать результате по дате создания CreatedDate в порядке убывания (новые в начале); иначе не учитывать.
     */
    sortByDateCreated?: boolean;
}

