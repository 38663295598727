import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TuiInputModule } from '@taiga-ui/kit';
import { TuiTextfieldControllerModule } from '@taiga-ui/core';
import { MaskitoModule } from '@maskito/angular';

import { InputCodeComponent } from './input-code.component';

@NgModule({
  declarations: [InputCodeComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TuiInputModule,
    TuiTextfieldControllerModule,
    MaskitoModule,
  ],
  exports: [InputCodeComponent],
})
export class InputCodeModule {}
