import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { UserUnion } from '@airgram/web';
import { TelegramMessengerService } from '@src/app/modules/telegram';
import { ChatModel, UserUI } from '@src/models';
import { UserService } from '@src/core/services';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'telegram-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserComponent implements OnChanges {
  @Input() userTelegram?: UserUnion;
  @Input() chat?: ChatModel;
  userUnions?: UserUI;

  constructor(
    private cdr: ChangeDetectorRef,
    private userService: UserService,
    private messengerService: TelegramMessengerService,
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.userTelegram && this.userTelegram) {
      lastValueFrom(this.userService.getUsersData([this.userTelegram.id])).then(userProfiles => {
        this.userUnions = userProfiles[0];
        this.cdr.markForCheck();
      });
    }

    this.cdr.markForCheck();
  }

  onChangeNotificationsToggleValue() {
    if (this.chat?.id) {
      this.messengerService.api
        .setChatNotificationSettings(this.chat.id, this.chat.notificationSettings.muteFor !== 0)
        .then(() => this.cdr.markForCheck());
    }
  }
}
