import { Injectable } from '@angular/core';
import { Router, CanActivate, CanActivateChild } from '@angular/router';
import { SessionService } from '@src/app/modules/auth';
import { logger } from '@src/utils';

@Injectable({
  providedIn: 'root',
})
export class UnAuthGuard implements CanActivate, CanActivateChild {
  constructor(private router: Router, private session: SessionService) {
    logger('UnAuthGuard constructor');
  }

  async canActivate(): Promise<boolean> {
    if (this.session.isLogged()) {
      this.router.navigate(['/']);

      return false;
    }

    return true;
  }

  async canActivateChild(): Promise<boolean> {
    return this.canActivate();
  }
}
