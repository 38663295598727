import { Component, ChangeDetectionStrategy, Input, EventEmitter, Output } from '@angular/core';
import { UserUI } from '@src/models';

@Component({
  selector: 'users-tag',
  templateUrl: './users-tags.component.html',
  styleUrls: ['./users-tags.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class UsersTagsComponent {
  @Input() items: UserUI[] = [];

  @Output() removeItem = new EventEmitter<UserUI>();
  @Output() removeAllItems = new EventEmitter();
}
