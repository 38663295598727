/**
 * Утилита для проверки номера телефона по его маске.
 * Если кол-во цифр в маске не совпадает с кол-вом в телефоне, то значение не валидно.
 *
 * @param phoneNumber номер телефона
 * @param countryMask маска, которая относится к данной стране
 */
export const checkPhoneNumber = (phoneNumber: string | undefined, countryMask: string): boolean => {
  return (phoneNumber?.match(/[0-9]/g)?.length ?? 0) === (countryMask.match(/#|[0-9]/g)?.length ?? 0);
};
