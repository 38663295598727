import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TuiLoaderModule } from '@taiga-ui/core';
import { ComponentsModule } from '@src/app/components';
import { LoginModule } from '@src/app/modules/login';

@NgModule({
  declarations: [],
  imports: [CommonModule, ComponentsModule, LoginModule, TuiLoaderModule],
})
export class BrandingModule {}
