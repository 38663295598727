<div *ngIf="pinnedMessages" class="pinned-message-list">
  <ng-container *ngFor="let pinnedMessage of pinnedMessages; let i = index">
    <div *ngIf="visibledIndex === i" class="pinned-message-list__item">
      <div class="pinned-message" (click)="scrollToMessage(pinnedMessage.id)">
        <div class="pinned-message__title">
          {{ 'components.pinnedMessageList.labels.pinnedMessage' | translate }} #{{ pinnedMessages.length - i }}
        </div>
        <div class="pinned-message__content">{{ pinnedMessage | messageNote | async }}</div>
      </div>
    </div>
  </ng-container>
</div>
