import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RegistrationModule } from '@src/app/modules/registration/registration.module';
import { CaptchaModule } from '@src/app/modules/captcha';
import { TuiScrollbarModule } from '@taiga-ui/core';

import { RegistrationPageComponent } from './registration-page.component';

@NgModule({
  declarations: [RegistrationPageComponent],
  imports: [CommonModule, RegistrationModule, CaptchaModule, TuiScrollbarModule],
})
export class RegistrationPageModule {}
