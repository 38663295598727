import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { TokenService, TokenModel } from '@src/api';
import { logger } from '@src/utils';
import { StorageKeys } from '@src/constants/storage';

import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private token?: string;

  private refreshToken?: string;

  constructor(private tokenService: TokenService, private readonly storageService: StorageService) {
    logger('AuthService constructor');
  }

  setTokens({ token, refreshToken }: TokenModel): void {
    if (token) {
      this.setToken(token);
    }

    if (refreshToken) {
      this.setRefreshToken(refreshToken);
    }
  }

  setToken(token: string): void {
    this.token = token;
    this.writeTokenToStorage(token);
  }

  setRefreshToken(refreshToken: string): void {
    this.refreshToken = refreshToken;
    this.writeRefreshTokenToStorage(refreshToken);
  }

  getToken(): string | null {
    return this.token || this.readTokenFromStorage();
  }

  changeToken(headId: string): Observable<TokenModel> {
    return this.tokenService.getChangeBA(headId).pipe(
      map(response => {
        this.setTokens(response);

        return response;
      }),
    );
  }

  isLogged(): boolean {
    return !!this.getToken();
  }

  logout(): void {
    this.removeTokenFromStorage();
    this.removeRefreshTokenFromStorage();

    this.token = undefined;
    this.refreshToken = undefined;
  }

  readTokenFromStorage(): string | null {
    return this.storageService.getItem(StorageKeys.Token);
  }

  writeTokenToStorage(token: string): void {
    this.storageService.setItem(StorageKeys.Token, token);
  }

  removeTokenFromStorage(): void {
    this.storageService.removeItem(StorageKeys.Token);
  }

  readRefreshTokenFromStorage(): string | null {
    return this.storageService.getItem(StorageKeys.RefreshToken);
  }

  writeRefreshTokenToStorage(refreshToken: string): void {
    this.storageService.setItem(StorageKeys.RefreshToken, refreshToken);
  }

  removeRefreshTokenFromStorage(): void {
    this.storageService.removeItem(StorageKeys.RefreshToken);
  }
}
