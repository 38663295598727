<div class="parse-link__wrapper">
  <span #root class="parse-link">
    <ng-container *ngFor="let element of preparedHTMLStructure">
      <ng-container [ngSwitch]="element.type">
        <span *ngSwitchCase="'space'">{{ ' ' }}</span>

        <br *ngSwitchCase="'new-line'" />

        <span *ngSwitchCase="'text'">{{ element?.value }}</span>

        <app-link
          *ngSwitchCase="'email'"
          class="email"
          [url]="element?.value ? 'mailto:' + element.value : ''"
          [displayText]="element?.value"
        ></app-link>

        <app-link
          *ngSwitchCase="'phone'"
          class="phone"
          [url]="element?.value ? 'tel:' + element.value : ''"
          [displayText]="element?.value"
        ></app-link>

        <app-link
          *ngSwitchCase="'url'"
          [url]="element?.url"
          [displayText]="element?.value ?? ''"
          (externalLink)="externalLink.emit($event)"
        ></app-link>
      </ng-container>
    </ng-container>
  </span>
</div>
