import { BreakpointObserver } from '@angular/cdk/layout';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { HeaderOptions, ResizableBaseComponent } from '@src/app/components';
import { BreakpointObserverHelperService } from '@src/core/services';

@Component({
  selector: 'app-birthdays-page',
  templateUrl: './birthdays-page.component.html',
  styleUrls: ['./birthdays-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BirthdaysPageComponent extends ResizableBaseComponent {
  selectedId?: string;

  headerOptions: HeaderOptions = {
    title: this.translateService.instant('components.birthdaysPage.labels.userInfoTitle'),
    buttons: {
      left: {
        label: this.translateService.instant('components.birthdaysPage.buttons.userInfoLeft'),
        icon: 'tuiIconChevronLeftLarge',
        visible: () => !this.isLargeScreen,
        action: () => {
          this.selectedId = undefined;
        },
      },
    },
  };

  get showFullPage(): boolean {
    return !this.selectedId || this.isLargeScreen;
  }

  constructor(
    public cdr: ChangeDetectorRef,
    public breakpointObserver: BreakpointObserver,
    public breakpointObserverHelperService: BreakpointObserverHelperService,
    private readonly translateService: TranslateService,
  ) {
    super(cdr, breakpointObserver, breakpointObserverHelperService);
  }
}
