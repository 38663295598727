import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { TuiInputModule } from '@taiga-ui/kit';
import { TuiButtonModule, TuiTextfieldControllerModule } from '@taiga-ui/core';
import { TranslateModule } from '@ngx-translate/core';
import { ComponentsModule } from '@src/app/components';

import { DeleteAuthUserLinkComponent } from './delete-auth-user-link.component';
import { DeleteAuthUserDialogComponent } from './components';

@NgModule({
  declarations: [DeleteAuthUserLinkComponent, DeleteAuthUserDialogComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    ComponentsModule,
    TranslateModule,
    TuiInputModule,
    TuiTextfieldControllerModule,
    TuiButtonModule,
  ],
  exports: [DeleteAuthUserLinkComponent],
})
export class DeleteAuthUserLinkModule {}
