import { TuiSizeS } from '@taiga-ui/core';

export interface LegendItemType {
  label: string;
  color: string;
  value?: string;
}

export type LegendItemSize = TuiSizeS;

export type LegendDirection = 'row' | 'column';
