import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-telegram-link',
  templateUrl: './telegram-link.component.html',
  styleUrls: ['./telegram-link.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TelegramLinkComponent {
  @Input() value?: string;
}
