import { ItemType } from '@src/app/modules/accordion-levels/types/item-type';

type Result = {
  id: string;
  parentId?: string;
};

/**
 * Функция для поиска всей цепочки родительских комитетов указанного идентификатора
 * @param id идентификатор искомого комитета
 * @param tree все дерево комитетов
 */
export const getAllParentIds = (id: string, tree: Array<ItemType>): string[] => {
  const el: ItemType | undefined = tree.find((item: ItemType) => item.id === id);
  if (el && (el.parentId ?? null) === null) {
    /** Мы на самом верху, раскрывать ничего не надо */
    return [];
  }

  /** Разворачиваем дерево в список */
  const stack: ItemType[] = [];
  const array: Array<Result> = [];

  stack.push({ children: tree });

  while (stack.length !== 0) {
    const node = stack.pop();
    if (node) {
      const { children = [], id } = node;

      if (id) {
        array.push({ id, parentId: node.parentId });
      }

      if (children.length > 0) {
        for (let i = children.length - 1; i >= 0; i--) {
          stack.push(children[i]);
        }
      }
    }
  }

  /** Ищем цепочку родителей, чтобы раскрыть все дерево до нужного потомка */
  const result = [];
  let parentId = array.find(item => item.id === id)?.parentId ?? null;

  while (parentId !== null) {
    result.push(parentId);
    parentId = array.find(item => item.id === parentId)?.parentId ?? null;
  }

  return result;
};
