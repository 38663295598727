<div class="view-info-block">
  <div
    *ngIf="!!title"
    class="view-info-block__header"
    [class.view-info-block__bg_new]="product?.isNew"
    [class.view-info-block__bg_archive]="product?.status === 'archive'"
    [class.view-info-block__bg_review]="product?.status === 'review'"
  >
    <h1 class="view-info-block__header_title">{{ title }}</h1>
  </div>

  <div class="view-info-block__content">
    <div *ngIf="product?.discountText" class="view-info-block__discount">
      <lp-discount-tag productDiscountType="tag" [productDiscount]="product?.discountText"></lp-discount-tag>
    </div>

    <tui-scrollbar class="view-info-block__description">
      <app-parse-links
        [value]="product?.description ?? null"
        (externalLink)="externalLink.emit($event)"
      ></app-parse-links>

      <div class="regions">
        <tui-svg class="regions__icon" src="tuiIconMapPinLarge"></tui-svg>
        {{ product?.regions | getPLRegionName : true }}
      </div>
    </tui-scrollbar>

    <div class="view-info-block__buttons">
      <button
        *ngIf="!!product?.terms?.length"
        tuiButton
        type="button"
        (click)="getDiscount.emit($event)"
        [size]="isMobile ? 'm' : 'l'"
      >
        {{ buttonLabel }}
      </button>

      <lp-favorite-button
        appearance="block"
        [product]="product"
        [size]="screenDimension === 'mobile' ? 'm' : 'l'"
      ></lp-favorite-button>
    </div>
  </div>
</div>
