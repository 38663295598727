import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ResizableBaseComponent } from '@src/app/components';

@Component({
  selector: 'poll-final-speech',
  templateUrl: './poll-final-speech.component.html',
  styleUrls: ['./poll-final-speech.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PollFinalSpeechComponent extends ResizableBaseComponent {
  @Input() text: string = '';
  @Output() close = new EventEmitter<void>();

  onClose() {
    this.close.emit();
  }
}
