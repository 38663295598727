import {
  Component,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Inject,
  Self,
  Optional,
  ViewChild,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import { AbstractTuiControl, TuiNativeFocusableElement } from '@taiga-ui/cdk';
import { NgControl } from '@angular/forms';
import { TuiPrimitiveTextfieldComponent } from '@taiga-ui/core';

import { CustomNamesItem, VisibilityEnum, VisibilityNames } from '../../types';

@Component({
  selector: 'app-custom-name',
  templateUrl: './custom-name.component.html',
  styleUrls: ['./custom-name.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomNameComponent extends AbstractTuiControl<CustomNamesItem | null> {
  @ViewChild(TuiPrimitiveTextfieldComponent)
  private readonly textfield?: TuiPrimitiveTextfieldComponent;

  @Input() label: string = '';
  @Input() showVisibilityOption: boolean = false;
  @Output() visibilityOptionChange: EventEmitter<void> = new EventEmitter<void>();

  visibilities: readonly any[] = [
    {
      value: VisibilityEnum.INVISIBLE,
      name: VisibilityNames[VisibilityEnum.INVISIBLE],
    },
    {
      value: VisibilityEnum.ADMINISTRATORS,
      name: VisibilityNames[VisibilityEnum.ADMINISTRATORS],
    },
    {
      value: VisibilityEnum.ALL,
      name: VisibilityNames[VisibilityEnum.ALL],
    },
  ];

  open: boolean = false;

  constructor(
    @Optional()
    @Self()
    @Inject(NgControl)
    control: NgControl | null,
    @Inject(ChangeDetectorRef) changeDetectorRef: ChangeDetectorRef,
  ) {
    super(control, changeDetectorRef);
  }

  get nativeFocusableElement(): TuiNativeFocusableElement | null {
    return this.computedDisabled || !this.textfield ? null : this.textfield.nativeFocusableElement;
  }

  get focused(): boolean {
    return !!this.textfield && this.textfield.focused;
  }

  protected getFallbackValue(): CustomNamesItem | null {
    return null;
  }

  activeZoneChange(value: boolean) {
    if (!value) {
      this.open = false;
    }
  }

  onValueChange(textValue: string): void {
    if (!!this.value) {
      this.value.title = textValue;
      this.updateValue(this.value);
    }
  }

  onFocused(focused: boolean): void {
    this.updateFocused(focused);
  }

  changeVisibility(visibilityOption: string) {
    if (!!this.value) {
      this.value!.visibilityOption = visibilityOption;
      this.updateValue(this.value);
      this.visibilityOptionChange.emit();
    }
  }

  get visibility(): string {
    return !!this.value?.visibilityOption ? VisibilityNames[this.value?.visibilityOption as VisibilityEnum] : '';
  }
}
