import { Injectable, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { TokenModel } from '@src/api';
import { TelegramAuthService } from '@src/app/modules/telegram';
import { takeUntil } from 'rxjs/operators';
import { JwtHelperService } from '@auth0/angular-jwt';
import { logger } from '@src/utils';
import { APP_CONFIG } from '@src/core';
import { BrandingService } from '@src/app/modules/branding';

import { CallbackAfterCreateSession, JwtTokenPayload } from '../types';

import { AuthService } from './auth.service';
import { AuthUserService } from './auth-user.service';

const MAIN_BOT = APP_CONFIG.bots.main;

@Injectable({
  providedIn: 'root',
})
export class SessionService implements OnDestroy {
  private resolve?: (value: unknown) => void;

  private callbacksAfterCreateSession: CallbackAfterCreateSession[] = [];

  private destroyed$$: Subject<void> = new Subject<void>();

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private authUserService: AuthUserService,
    private telegramAuth: TelegramAuthService,
    private brandingService: BrandingService,
  ) {
    logger('SessionService constructor');

    this.brandingService.data$.pipe(takeUntil(this.destroyed$$)).subscribe(brandingData => {
      if (brandingData === null || this.brandingService.getCodeName() === null) {
        this.telegramAuth.setMainBot(MAIN_BOT.id, MAIN_BOT.username); // TODO: refactoring refs #8573
        return;
      }

      if (brandingData?.botId && brandingData?.botName) {
        this.telegramAuth.setMainBot(brandingData.botId, brandingData.botName);
      }

      this.restoreSession().then(this.resolve);
    });

    this.telegramAuth.success$.pipe(takeUntil(this.destroyed$$)).subscribe(() => {
      logger('SessionService logged in Telegram');
    });
  }

  get isBrand() {
    return this.brandingService.isBrand();
  }

  get brandingData() {
    return this.brandingService.data$.value;
  }

  get brandCodeName() {
    return this.brandingService.getCodeName();
  }

  get authUser() {
    return this.authUserService.user;
  }

  get currentParentOrganisationId() {
    return this.authUserService.user?.parentOrganisationId;
  }

  addCallbackAfterCreateSession(callback: CallbackAfterCreateSession) {
    this.callbacksAfterCreateSession.push(callback);
  }

  ngOnDestroy(): void {
    this.destroyed$$.next();
    this.destroyed$$.complete();
  }

  isLogged(): boolean {
    return this.authService.isLogged();
  }

  init() {
    return new Promise(resolve => {
      if (this.brandingService.isBrand()) {
        this.resolve = resolve;
        this.brandingService.getBrandData();
      } else {
        this.restoreSession().then(resolve);
      }
    });
  }

  /**
   * Восстанавливаем сессию
   */
  async restoreSession() {
    const token = this.authService.readTokenFromStorage();

    if (token) {
      await this.createSession({ token });
    }

    await this.telegramAuth.authenticate();
  }

  /**
   * Создаем сессию
   * @param sessionData токены
   * @param redirect флаг, что нужно произвести редирект на returnUrl
   */
  async createSession({ token, refreshToken }: TokenModel, redirect?: boolean) {
    if (!token) {
      // TODO: localization console перевести
      throw new Error('Нет данных по токену сессии');
    }

    this.authService.setTokens({ token, refreshToken });

    const jwtHelper = new JwtHelperService();
    const decodedToken = jwtHelper.decodeToken<JwtTokenPayload>(token);

    if (!decodedToken) {
      // TODO: localization console перевести
      throw new Error('Ошибка декодирования токена');
    }

    // Закрываем возможность получения токена с помощью бота
    this.telegramAuth.preventGetTokenFromBot();
    const user = await this.authUserService.init(decodedToken);

    await Promise.all(this.callbacksAfterCreateSession.map(callback => callback(user)));

    const returnUrl = this.activatedRoute.snapshot.queryParamMap.get('returnUrl');
    if (redirect) {
      await this.router.navigateByUrl(returnUrl ?? '');
    }

    return user;
  }

  changeToken(parentOrganisationId: string) {
    return this.authService.changeToken(parentOrganisationId);
  }
}
