import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { catchError, filter, switchMap, takeUntil } from 'rxjs/operators';
import { UserService } from '@src/api';
import { AlertService } from '@src/core/services';

import { ImportData } from '../types';

@Injectable({
  providedIn: 'root',
})
export class ImportService implements OnDestroy {
  private importData$: BehaviorSubject<ImportData | null> = new BehaviorSubject<ImportData | null>(null);

  private destroyed$$: Subject<void> = new Subject<void>();

  constructor(private readonly userService: UserService, private readonly alertService: AlertService) {}

  ngOnDestroy(): void {
    this.destroyed$$.next();
    this.destroyed$$.complete();
  }

  startImport(data: ImportData) {
    this.importData$.next(data);
  }

  getResult(): Observable<Blob | null> {
    return this.importData$
      .pipe(takeUntil(this.destroyed$$))
      .pipe(filter(request => !!request))
      .pipe(
        switchMap(request => {
          if (!!request) {
            return this.userService.importFromExcel(request.files).pipe(
              catchError(err => {
                this.alertService.error(err);
                return of(null);
              }),
            );
          } else {
            return of(null);
          }
        }),
      );
  }

  clearImportData() {
    this.importData$.next(null);
  }
}
