import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { NgxPermissionsService } from 'ngx-permissions';
import { EnvService } from '@src/app/modules/env';

import { NAVIGATION_ITEMS, NavigationItem } from '../../navigation';

@Injectable({
  providedIn: 'root',
})
export class RedirectGuard implements CanActivate {
  constructor(private router: Router, private env: EnvService, private permissions: NgxPermissionsService) {}

  async canActivate(): Promise<boolean> {
    if (this.env.isMobilePlatform) {
      await this.redirectToPage('info');
    } else {
      await this.redirectToPage('association');
    }

    return true;
  }

  private async redirectToPage(url: string) {
    const findItem = NAVIGATION_ITEMS.find(item => item.link === url);
    if (findItem) {
      await this.checkPermissionsRoute(findItem);
    }

    return await this.redirectToNextPage();
  }

  private async checkPermissionsRoute(item: NavigationItem) {
    if (item.permissions && item.link) {
      const hasPermission = await this.permissions.hasPermission(item.permissions);
      if (hasPermission) {
        this.router.navigate([item.link]);
        return true;
      }
    }

    return false;
  }

  private async redirectToNextPage() {
    for (const item of NAVIGATION_ITEMS) {
      if (!this.env.isMobilePlatform && !!item.mobileOnly) {
        // Не делаем redirect на мобильные страницы в десктопной версии
        continue;
      }

      const checkPermissions = await this.checkPermissionsRoute(item);
      if (checkPermissions) {
        return true;
      }
    }

    return false;
  }
}
