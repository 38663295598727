import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { TuiInputFilesModule, TuiSelectModule } from '@taiga-ui/kit';
import { TuiButtonModule, TuiDataListModule, TuiTextfieldControllerModule } from '@taiga-ui/core';
import { TuiLetModule } from '@taiga-ui/cdk';
import { ComponentsModule } from '@src/app/components';
import { SharedModule } from '@src/app/shared';

import { ImportUsersComponent } from './import-users.component';
import { ImportService } from './services';

@NgModule({
  declarations: [ImportUsersComponent],
  imports: [
    CommonModule,
    ComponentsModule,
    ReactiveFormsModule,
    SharedModule,
    TuiButtonModule,
    TuiInputFilesModule,
    TuiDataListModule,
    TuiLetModule,
    TuiSelectModule,
    TuiTextfieldControllerModule,
  ],
  providers: [ImportService],
  exports: [ImportUsersComponent],
})
export class ImportUsersModule {}
