<div class="standard-user-info-fields">
  <p *ngIf="!fields.length" class="no-data" [class.tui-skeleton]="loading" [class.tui-skeleton_text]="loading">
    {{ 'common.labels.noData' | translate }}
  </p>

  <app-contacts
    class="standard-user-info-fields__form"
    [(ngModel)]="fields"
    [allowCreating]="true"
    [allowEditing]="true"
    [allowDeleting]="true"
    [validateForm]="false"
    [loading]="loading"
  ></app-contacts>

  <div class="standard-user-info-fields__buttons">
    <button
      tuiButton
      class="save-button"
      [class.tui-skeleton]="loading"
      [class.tui-skeleton_text]="loading"
      type="button"
      appearance="primary"
      [size]="'m'"
      (click)="onSave()"
    >
      {{ 'common.buttons.save' | translate }}
    </button>
  </div>
</div>
