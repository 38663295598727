import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { TuiSizeL } from '@taiga-ui/core';

import { FilterItem } from '../../types';

@Component({
  selector: 'app-filter-accordion-content-fields',
  templateUrl: './filter-accordion-content-fields.component.html',
  styleUrls: ['./filter-accordion-content-fields.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilterAccordionContentFieldsComponent implements OnChanges {
  @Input() size: TuiSizeL = 'l';
  @Input() items: Array<FilterItem> = [];
  @Input() result: Array<FilterItem> = [];
  @Output() resultChange = new EventEmitter<Array<FilterItem>>();

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['result']) {
      this.setResultToItems(this.result);
    }
  }

  onChangeContact() {
    const result = this.items.filter(item => item.id !== '');
    this.resultChange.emit(result);
  }

  private setResultToItems(result: FilterItem[]) {
    this.items = this.items.map(item => {
      const findResult = result.find(value => value.label === item.label);
      if (findResult) {
        item.id = findResult.id;
      } else {
        item.id = '';
      }

      return item;
    });
  }
}
