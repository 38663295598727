import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, lastValueFrom, throwError } from 'rxjs';
import { OrganisationSettingsService, OrganisationSettingWithGplView } from '@src/api';
import { UserUI } from '@src/models';

import { AlertService } from './alert.service';

@Injectable({
  providedIn: 'root',
})
export class PreferencesService {
  settings$ = new BehaviorSubject<OrganisationSettingWithGplView | undefined>(undefined);

  private items: OrganisationSettingWithGplView = {
    showParentOrgUsersInLists: false,
    showOnlyChannelsInNewsFeed: false,
    enableLoyaltyNewsletters: false,
    disableMessengerLogin: false,
    showOnlyLoyaltyProgram: false,
  };

  private orgId?: string;

  constructor(private readonly api: OrganisationSettingsService, private readonly alertService: AlertService) {}

  get settings() {
    return this.items;
  }

  getData(user?: UserUI) {
    this.orgId = user?.parentOrganisationId;

    return this.refresh();
  }

  async refresh(): Promise<OrganisationSettingWithGplView> {
    if (this.orgId) {
      this.items = await lastValueFrom(this.getDataByOrganisationId(this.orgId));
      this.items.showParentOrgUsersInLists ??= true;
      this.items.showOnlyChannelsInNewsFeed ??= false;
      this.items.enableLoyaltyNewsletters ??= false;
      this.items.disableMessengerLogin ??= false;
      this.items.showOnlyLoyaltyProgram ??= false;
    }

    this.settings$.next(this.items);

    return this.settings;
  }

  getDataByOrganisationId(orgId: string) {
    return this.api.organisationSettingsByOrganisationId(orgId).pipe(
      catchError(err => {
        this.alertService.error(err.error);
        return throwError(() => err);
      }),
    );
  }
}
