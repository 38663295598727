import { Injectable } from '@angular/core';
import { Airgram } from '@airgram/web';
import { environment } from '@src/environments/environment';

import { AirgramApiWrapper } from '../api/airgramApiWrapper';
import { TELEGRAM_API_CONFIG } from '../constants';

@Injectable({
  providedIn: 'root',
})
export class AirgramFactoryService {
  constructor() {}

  createInstance(): AirgramApiWrapper {
    const instance = new Airgram({
      apiId: TELEGRAM_API_CONFIG.id,
      apiHash: TELEGRAM_API_CONFIG.hash,
      applicationVersion: environment.appVersion,
      deviceModel: this.getBrowser(),
      systemVersion: this.getPlatform(),
    });

    return new AirgramApiWrapper(instance);
  }

  private getBrowser() {
    // TODO: use Bowser - https://github.com/lancedikson/bowser
    const aKeys = ['MSIE', 'Firefox', 'Safari', 'Chrome', 'Opera', 'YaBrowser', 'Edg'];
    const browsersList = [
      'Internet Explorer',
      'Firefox',
      'Safari',
      'Chrome',
      'Opera',
      'Yandex Browser',
      'Microsoft Edge',
    ];
    const sUsrAg = navigator.userAgent;
    let nIdx = aKeys.length - 1;

    for (nIdx; nIdx > -1 && sUsrAg.indexOf(aKeys[nIdx]) === -1; nIdx--);

    return browsersList[nIdx] || 'unknown';
  }

  private getPlatform() {
    const { userAgent, platform } = window.navigator;
    const macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'];
    const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'];
    const iosPlatforms = ['iPhone', 'iPad', 'iPod'];
    let os: 'macOS' | 'iOS' | 'Windows' | 'Android' | 'Linux' | undefined;

    if (macosPlatforms.indexOf(platform) !== -1) {
      os = 'macOS';
    } else if (iosPlatforms.indexOf(platform) !== -1) {
      os = 'iOS';
    } else if (windowsPlatforms.indexOf(platform) !== -1) {
      os = 'Windows';
    } else if (/Android/.test(userAgent)) {
      os = 'Android';
    } else if (/Linux/.test(platform)) {
      os = 'Linux';
    }

    return os;
  }
}
