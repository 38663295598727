import { Pipe, PipeTransform } from '@angular/core';
import { OrganisationJobTitleUI } from 'src/models';

@Pipe({
  name: 'orgJobTitleDisplayableName',
})
export class OrgJobTitleDisplayableNamePipe implements PipeTransform {
  transform(org: OrganisationJobTitleUI): string {
    return org.organisationName + (org?.jobTitles?.[0] ? `: ${org.jobTitles[0]}` : '');
  }
}
