import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  AbstractControl,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { BreakpointObserver } from '@angular/cdk/layout';
import { takeUntil } from 'rxjs';
import { TuiContextWithImplicit, tuiPure, TuiStringHandler } from '@taiga-ui/cdk';
import { isEqual } from 'lodash-es';
import { TranslateService } from '@ngx-translate/core';
import { ResizableBaseComponent } from '@src/app/components';
import { FIELD_TYPES, FieldType } from '@src/app/shared/field';
import { APP_CONFIG } from '@src/core';
import {
  isFileContact,
  isNotOtherAndNotFileContact,
  isOtherContact,
  removeContactsByUserPermissions,
} from '@src/utils';
import { BreakpointObserverHelperService, UserService } from '@src/core/services';
import { ContactUI } from '@src/models';
import { Nullable, Optional } from '@src/types/utils';
import { moveItemInArray } from '@angular/cdk/drag-drop';

@Component({
  selector: 'contacts-view-old',
  templateUrl: './contacts-view-old.component.html',
  styleUrls: ['./contacts-view-old.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class ContactsViewOldComponent extends ResizableBaseComponent implements OnChanges {
  @Input() set value(contacts: Array<ContactUI>) {
    if (!isEqual(contacts, this.contacts.value)) {
      this.contacts.clear();
      contacts = contacts.map(contact => {
        this.addContact();

        delete contact.sortOrder;
        return contact;
      });

      this.form.patchValue({ contacts });
    }
    this.cdr.markForCheck();
  }
  @Input() allowCreating: Optional<Nullable<boolean>> = false;
  @Input() allowEditing: Optional<Nullable<boolean>> = false;
  @Input() allowDeleting: Optional<Nullable<boolean>> = false;
  @Input() allowFilesDeleting: Optional<Nullable<boolean>> = true;
  @Input() loading = false;
  @Input() disabled = false;
  @Input() isModeration: Optional<Nullable<boolean>> = false;
  @Input() showFieldCounter: Optional<Nullable<boolean>> = true;
  @Input() showFieldSeparator: Optional<Nullable<boolean>> = true;
  @Input() touched: Optional<Nullable<boolean>> = false;
  @Input() validateForm: Optional<Nullable<boolean>> = true;
  @Input() acceptFiles: string = '';
  @Output() valueChange = new EventEmitter<Array<ContactUI>>();

  form!: UntypedFormGroup;
  contactTypes: FieldType[] = Object.values(FIELD_TYPES).filter(fieldType => fieldType.forContacts);
  readonly maxDocFileSize = APP_CONFIG.fileSizeMax.doc;

  isFileContactLocal = isFileContact;
  isOtherContactLocal = isOtherContact;
  isNotOtherAndNotFileContactLocal = isNotOtherAndNotFileContact;

  constructor(
    readonly cdr: ChangeDetectorRef,
    readonly breakpointObserver: BreakpointObserver,
    readonly breakpointObserverHelperService: BreakpointObserverHelperService,
    private readonly translateService: TranslateService,
    private readonly formBuilder: UntypedFormBuilder,
    private readonly userService: UserService,
  ) {
    super(cdr, breakpointObserver, breakpointObserverHelperService);

    this.initForm();
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.contactTypes = removeContactsByUserPermissions(this.contactTypes, this.userService.authUser?.permissions);

    this.contacts.valueChanges.pipe(takeUntil(this.destroyed$$)).subscribe(value => {
      this.valueChange.emit(value);
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.touched?.currentValue === true) {
      this.contacts?.markAllAsTouched();
    }
  }

  get contacts(): UntypedFormArray {
    return this.form.get('contacts') as UntypedFormArray;
  }

  getDocumentFormControl(contact: AbstractControl, document: string): UntypedFormControl {
    return contact.get(document) as UntypedFormControl;
  }

  resetDocumentFormControl(contact: AbstractControl, document: string) {
    (contact.get(document) as UntypedFormControl).reset();
    (contact.get('contact') as UntypedFormControl).reset();
  }

  createContact(): UntypedFormGroup {
    const newSortOrder = (this.contacts.length + 1) * 10;
    return this.formBuilder.group({
      id: [undefined],
      contactTypeId: [1],
      contact: ['', Validators.maxLength(2000)],
      oldDocument: [''],
      newDocument: [''],
      description: [''],
      sortOrder: [newSortOrder],
      isPriority: [false],
      isRequired: [false],
    });
  }

  removeContact(index: number): void {
    this.contacts.removeAt(index);
  }

  moveContact(currentIndex: number, index: number) {
    const currentSortOrder = this.contacts.controls[currentIndex].value.sortOrder;
    const newSortOrder = this.contacts.controls[currentIndex + index].value.sortOrder;
    this.contacts.controls[currentIndex].patchValue({ sortOrder: newSortOrder });
    this.contacts.controls[currentIndex + index].patchValue({ sortOrder: currentSortOrder });

    moveItemInArray(this.contacts.controls, currentIndex, currentIndex + index);
  }

  addContact(): void {
    const contactControl = this.createContact();

    contactControl.valueChanges.subscribe(value => {
      this.switchRequiredValidator(contactControl, value.isRequired);
    });

    this.contacts.push(contactControl);
  }

  switchRequiredValidator(contactControl: UntypedFormGroup, required: boolean) {
    if (!this.validateForm) return;

    const valueControl = contactControl.get('contact');

    if (required) {
      valueControl?.addValidators(Validators.required);
    } else if (valueControl?.hasValidator(Validators.required)) {
      valueControl?.removeValidators(Validators.required);
      valueControl?.markAsPending();
    }

    valueControl?.updateValueAndValidity({ emitEvent: false });
  }

  @tuiPure
  contactTypesStringify(items: ReadonlyArray<FieldType>): TuiStringHandler<TuiContextWithImplicit<number>> {
    const map = new Map(items.map(({ id, description }) => [id, description] as [number, string]));

    return ({ $implicit }: TuiContextWithImplicit<number>) => this.translateService.instant(map.get($implicit) || ' ');
  }

  private initForm() {
    this.form = new UntypedFormGroup({
      contacts: this.formBuilder.array([]),
    });
  }
}
