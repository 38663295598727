<ng-container *ngIf="!env.isBot; else tmpForBot">
  <div
    telegram-check-auth
    [infoText]="'components.telegramChats.labels.needTelegram' | translate"
    (onLogged)="onLogged()"
    class="chat-page"
  >
    <div class="chat-page__header">
      <button
        *ngIf="(createGroupVisible || addGroupsListVisible || selectedChat) && !showFullPage"
        tuiIconButton
        class="chat-page__return-button"
        type="button"
        appearance="icon"
        icon="tuiIconArrowLeftLarge"
        size="s"
        [title]="'common.buttons.return' | translate"
        (click)="onClickReturnButton()"
      ></button>
      <ng-container *ngIf="showFullPage">
        <h1 class="chat-page__title">{{ 'components.navigation.constants.navigationItems.chatsTitle' | translate }}</h1>
        <tui-hosted-dropdown
          *ngxPermissionsOnly="['chatsCreating']"
          tuiDropdownAlign="left"
          [content]="createMenuTemplate"
          [(open)]="openMenu"
        >
          <div waitTelegramChats>
            <button
              tuiIconButton
              class="button button_add"
              type="button"
              appearance="icon"
              icon="tuiIconPlusCircleLarge"
              size="s"
              aria-label="Создать"
            ></button>
          </div>
        </tui-hosted-dropdown>
      </ng-container>
    </div>

    <div [hide]="!showFullPage" class="chat-page__list">
      <div class="header">
        <app-search [(searchQuery)]="searchQuery" (searchQueryChange)="searchQueryChangeHandler($event)"></app-search>
      </div>

      <app-list
        listType="chats"
        [items]="searchChats ? searchChats : chats"
        [selectedId]="selectedChatId"
        (selectedIdChange)="onSelectChatIdChange($event)"
        [loading]="loading"
      ></app-list>
    </div>

    <div
      *ngIf="createGroupVisible || addGroupsListVisible || forwardToChatListVisible || selectedChat"
      class="chat-page__content"
    >
      <telegram-chat
        *ngIf="selectedChat && !forwardToChatListVisible"
        [chat]="selectedChat"
        (forward)="openForwardToChatDialog($event)"
        (subscribe)="onSubscribeGroup($event)"
        (leave)="leaveChat($event)"
        (chatDelete)="onDeleteChat($event)"
      ></telegram-chat>
      <app-group-info
        *ngIf="createGroupVisible"
        mode="create"
        [isChannel]="isChannel"
        (canceled)="onCancelCreatingGroup()"
        (saved)="onCreatedGroup($event)"
      ></app-group-info>
      <app-search-groups
        *ngIf="addGroupsListVisible"
        [isChannel]="isChannel"
        [excludeIds]="unionsChatIds"
        (groupSelected)="attachGroup($event)"
      ></app-search-groups>
      <telegram-forward
        *ngIf="forwardToChatListVisible"
        (chatSelected)="forwardToChat($event.selectedChat, $event.message)"
        (canceled)="onClickReturnToChatButton()"
      ></telegram-forward>
    </div>
  </div>
</ng-container>

<ng-template #createMenuTemplate>
  <mat-nav-list class="menu-list">
    <mat-list-item *ngFor="let item of createMenuItems" class="menu-list__item" (click)="onClickCreateMenuItem(item)">
      <mat-icon matListIcon class="menu-list__icon">{{ item.icon }}</mat-icon>
      <div matLine class="menu-list__title">{{ item.title }}</div>
    </mat-list-item>
  </mat-nav-list>
</ng-template>

<ng-template #tmpForBot>
  <div class="button-tg-wrap">
    <button tuiButton type="button" appearance="primary" size="m" (click)="gotoTelegram()">
      {{ 'components.telegramChats.buttons.goToTelegram' | translate }}
    </button>
  </div>
</ng-template>
