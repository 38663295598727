import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { catchError, Subject, takeUntil, throwError } from 'rxjs';
import { AlertService } from '@src/core/services';
import { OrganisationShortInfoUI } from '@src/app/modules/branding';
import { SessionService } from '@src/app/modules/auth';
import { EnterType } from '@src/app/modules/login/login.model';

import { RegistrationFormUI } from './types';
import { RegistrationService } from './services/registration.service';

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RegistrationComponent implements OnInit, OnDestroy {
  @Input() associationId?: string;

  @Output() saved: EventEmitter<string> = new EventEmitter<string>();

  loading = false;
  openPolicy = false;
  registrationActive = true;

  newUserId?: string;

  brandingData?: OrganisationShortInfoUI | null;

  readonly EnterType = EnterType;

  private destroyed$$: Subject<void> = new Subject<void>();

  constructor(
    private readonly registrationService: RegistrationService,
    private readonly alertService: AlertService,
    readonly cdr: ChangeDetectorRef,
    private readonly session: SessionService,
  ) {}

  async ngOnInit(): Promise<void> {
    if (this.session.isBrand) {
      this.brandingData = this.session.brandingData;
    }
  }

  ngOnDestroy(): void {
    this.destroyed$$.next();
    this.destroyed$$.complete();
  }

  saveRegistrationForm(data: RegistrationFormUI): void {
    this.loading = true;

    this.registrationService
      .sendRegistrationForm(data)
      .pipe(
        takeUntil(this.destroyed$$),
        catchError(err => {
          this.alertService.error(err);
          this.loading = false;
          this.cdr.markForCheck();

          return throwError(() => err);
        }),
      )
      .subscribe(res => {
        this.newUserId = res.id;

        this.registrationActive = false;
        this.loading = false;
        this.cdr.markForCheck();
      });
  }
}
