import { Component, ChangeDetectionStrategy } from '@angular/core';
import { reload } from '@src/utils';

@Component({
  selector: 'app-many-tabs-dialog',
  templateUrl: './many-tabs-dialog.component.html',
  styleUrls: ['./many-tabs-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ManyTabsDialogComponent {
  reload = reload;
}
