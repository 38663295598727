import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import {
  TuiDataListWrapperModule,
  TuiInputModule,
  TuiInputDateModule,
  TuiInputNumberModule,
  TuiIslandModule,
  TuiMultiSelectModule,
  TuiSelectModule,
  TuiCheckboxBlockModule,
  TuiTextareaModule,
  TuiInputFilesModule,
  TuiInputYearModule,
} from '@taiga-ui/kit';
import {
  TuiButtonModule,
  TuiDataListModule,
  TuiScrollbarModule,
  TuiSvgModule,
  TuiTextfieldControllerModule,
  TuiGroupModule,
} from '@taiga-ui/core';
import { TuiAutoFocusModule, TuiLetModule } from '@taiga-ui/cdk';
import { NgxPermissionsModule } from 'ngx-permissions';
import { FieldModule } from '@src/app/shared/field';
import { ListModule } from '@src/app/shared/list';
import { SharedModule } from '@src/app/shared';
import { PhotoModule } from '@src/app/modules/photo';
import { PhoneModule } from '@src/app/modules/phone';
import { WaitTelegramChatsModule } from '@src/app/modules/wait-telegram-chats';
import { ContactsModule } from '@src/app/modules/contacts/';
import { ComponentsModule } from '@src/app/components';
import { ReportsModule } from '@src/app/modules/reports/';
import { OrganisationInputModule } from '@src/app/modules/organisation-input';
import { CommitteeFieldModule } from '@src/app/modules/committees/committee-field';
import { DeleteAuthUserLinkModule } from '@src/app/modules/delete-auth-user-link';

import { CustomNamesTabsModule } from '../custom-name-tabs';
import { ModernSearchUsersModule } from '../search-users';

import { UserInfoComponent } from './user-info.component';
import { UserInfoViewComponent } from './user-info-view/user-info-view.component';
import { UserInfoEditComponent } from './user-info-edit/user-info-edit.component';
import { UserInfoModerationComponent } from './user-info-moderation/user-info-moderation.component';

@NgModule({
  declarations: [UserInfoComponent, UserInfoViewComponent, UserInfoEditComponent, UserInfoModerationComponent],
  imports: [
    CommonModule,
    ComponentsModule,
    FieldModule,
    FormsModule,
    ListModule,
    MatButtonModule,
    MatIconModule,
    NgxPermissionsModule,
    PhoneModule,
    PhotoModule,
    ReactiveFormsModule,
    RouterModule,
    ModernSearchUsersModule,
    SharedModule,
    TuiAutoFocusModule,
    TuiButtonModule,
    TuiDataListModule,
    TuiDataListWrapperModule,
    TuiInputModule,
    TuiGroupModule,
    TuiInputDateModule,
    TuiInputFilesModule,
    TuiInputNumberModule,
    TuiInputYearModule,
    TuiIslandModule,
    TuiLetModule,
    TuiMultiSelectModule,
    TuiScrollbarModule,
    TuiSelectModule,
    TuiSvgModule,
    TuiTextareaModule,
    TuiTextfieldControllerModule,
    TuiCheckboxBlockModule,
    CustomNamesTabsModule,
    WaitTelegramChatsModule,
    ContactsModule,
    ReportsModule,
    OrganisationInputModule,
    CommitteeFieldModule,
    DeleteAuthUserLinkModule,
  ],
  exports: [UserInfoComponent, UserInfoModerationComponent],
})
export class UserInfoModule {}
