import { TuiSizeL, TuiSizeXS } from '@taiga-ui/core';

export type BadgeSize = TuiSizeXS | TuiSizeL;

export const enum BadgeColors {
  Green = 'green',
  Grey = 'grey',
  Yellow = 'yellow',
  Red = 'red',
  White = 'white',
}
export type BadgeColor = 'green' | 'grey' | 'yellow' | 'red' | 'white';

export interface BadgeOptions {
  value: string;
  color: BadgeColor;
  size: BadgeSize;
}
