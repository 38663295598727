import { Component, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { BreakpointObserver } from '@angular/cdk/layout';
import { OrganisationSettingsService, OrganisationSettingWithGplView } from '@src/api';
import { catchError, takeUntil } from 'rxjs/operators';
import { AlertService, BreakpointObserverHelperService, DocumentService, PreferencesService } from '@src/core/services';
import { AuthUserService } from '@src/app/modules/auth';
import { ResizableBaseComponent } from '@src/app/components/resizable-base-component';
import { TranslateService } from '@ngx-translate/core';
import { LoyaltyProgramApiService } from '@src/app/modules/loyalty-program/services';
import { lastValueFrom, throwError } from 'rxjs';
import { BrandingService } from '@src/app/modules/branding';
import { APP_CONFIG } from '@src/core';

import { FormData, FormDataControls } from './types';

@Component({
  selector: 'app-preferences',
  templateUrl: './preferences.component.html',
  styleUrls: ['./preferences.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PreferencesComponent extends ResizableBaseComponent {
  form = new FormGroup<FormDataControls>({
    showParentOrgUsersInLists: new FormControl(false, { nonNullable: true }),
    showAllUsersPhonesAndEmails: new FormControl(false, { nonNullable: true }),
    showOnlyChannelsInNewsFeed: new FormControl(false, { nonNullable: true }),
    enableLoyaltyNewsletters: new FormControl(false, { nonNullable: true }),
    allowUserRegistrationForm: new FormControl(false, { nonNullable: true }),
    disableMessengerLogin: new FormControl(false, { nonNullable: true }),
    showOnlyLoyaltyProgram: new FormControl(false, { nonNullable: true }),

    templateForNewcomerOld: new FormControl(null),
    templateForNewcomerNew: new FormControl(null),
  });

  settings?: OrganisationSettingWithGplView;

  loading = false;

  saving = false;

  readonly maxDocFileSize = APP_CONFIG.fileSizeMax.doc;

  get userRegistrationLink() {
    const associationId = this.authUserService.authUser$.value?.parentOrganisationId;

    // HACK для ios
    const protocol = window.location.protocol === 'app:' ? 'https:' : window.location.protocol;

    return associationId ? protocol + '//' + window.location.host + '/registration/' + associationId : undefined;
  }

  constructor(
    readonly cdr: ChangeDetectorRef,
    readonly breakpointObserver: BreakpointObserver,
    readonly breakpointObserverHelperService: BreakpointObserverHelperService,
    private readonly api: OrganisationSettingsService,
    private readonly alertService: AlertService,
    private readonly preferencesService: PreferencesService,
    private readonly authUserService: AuthUserService,
    private readonly translateService: TranslateService,
    private readonly lpApiService: LoyaltyProgramApiService,
    private readonly brandingService: BrandingService,
    private readonly documentService: DocumentService,
  ) {
    super(cdr, breakpointObserver, breakpointObserverHelperService);
  }

  get isBrand(): boolean {
    return this.brandingService.isBrand();
  }

  async ngOnInit(): Promise<void> {
    super.ngOnInit();

    this.form.valueChanges.pipe(takeUntil(this.destroyed$$)).subscribe(() => {
      this.save();
    });

    await this.getData();
  }

  async save(): Promise<void> {
    if (!this.form.dirty || this.form.disabled || this.saving) {
      return;
    }

    this.saving = true;
    this.form.disable();

    const formData = this.form.value as FormData;

    const newDocument = formData.templateForNewcomerNew
      ? await lastValueFrom(this.documentService.addDocuments([formData.templateForNewcomerNew]))
      : null;
    const templateForNewcomerId = newDocument?.files?.[0].id
      ? newDocument.files[0].id
      : formData.templateForNewcomerOld?.id;

    const observable = this.settings?.id
      ? this.api.editOrganisationSettings({
          ...this.settings,
          ...formData,
          organisationId: this.settings.organisationId ?? this.authUserService.user?.organisationId,
          templateForNewcomerId,
        })
      : this.api.addOrganisationSettings({
          ...formData,
          organisationId: this.authUserService.user?.organisationId,
          templateForNewcomerId,
        });

    observable.pipe(takeUntil(this.destroyed$$)).subscribe({
      next: async () => {
        this.alertService.success(this.translateService.instant('components.preferences.alerts.successes.saveData'));

        await this.getData();

        this.form.enable();
        this.saving = false;
      },
      error: err => {
        this.alertService.error(err);

        this.form.enable();
        this.saving = false;
      },
    });
  }

  sendDigest() {
    this.loading = true;

    this.lpApiService
      .sendDigest()
      .pipe(
        catchError(err => {
          this.alertService.error(err);
          this.loading = false;
          this.cdr.markForCheck();

          return throwError(() => err);
        }),
      )
      .subscribe(() => {
        this.alertService.success(this.translateService.instant('components.preferences.alerts.successes.sendDigest'));

        this.loading = false;
        this.cdr.markForCheck();
      });
  }

  sendTestDigest() {
    this.loading = true;

    this.lpApiService
      .digestTest()
      .pipe(
        catchError(err => {
          this.alertService.error(err);
          this.loading = false;
          this.cdr.markForCheck();

          return throwError(() => err);
        }),
      )
      .subscribe(() => {
        this.alertService.success(this.translateService.instant('components.preferences.alerts.successes.digestTest'));

        this.loading = false;
        this.cdr.markForCheck();
      });
  }

  removeTemplateForNewcomer() {
    this.form.markAsDirty();
    this.form.get('templateForNewcomerOld')?.patchValue(null);
  }

  private async getData() {
    try {
      this.loading = true;

      this.settings = await this.preferencesService.refresh();
      const templateForNewcomerOld = this.settings.templateForNewcomerId
        ? {
            id: this.settings.templateForNewcomerId,
            name: this.translateService.instant('components.preferences.constants.templateForNewcomerFileName'),
          }
        : null;

      const value = {
        showParentOrgUsersInLists: this.settings.showParentOrgUsersInLists,
        showAllUsersPhonesAndEmails: this.settings.showAllUsersPhonesAndEmails,
        showOnlyChannelsInNewsFeed: this.settings.showOnlyChannelsInNewsFeed,
        enableLoyaltyNewsletters: this.settings.enableLoyaltyNewsletters,
        disableMessengerLogin: this.settings.disableMessengerLogin,
        allowUserRegistrationForm: this.settings.allowUserRegistrationForm,
        showOnlyLoyaltyProgram: this.settings.showOnlyLoyaltyProgram,
        templateForNewcomerOld,
      };
      this.form.reset(value);
    } finally {
      this.loading = false;
      this.cdr.markForCheck();
    }
  }
}
