import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { tuiToggleOptionsProvider } from '@taiga-ui/kit';

import { ThemeSwitcherService } from '../../services/theme-switcher.service';

@Component({
  selector: 'app-theme-switcher-mini',
  templateUrl: './theme-switcher-mini.component.html',
  styleUrls: ['./theme-switcher-mini.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    tuiToggleOptionsProvider({
      icons: {
        toggleOff: 'tuiIconMoon',
        toggleOn: 'tuiIconSun',
      },
      showIcons: true,
    }),
  ],
})
export class ThemeSwitcherMiniComponent {
  isDark$ = this.themeSwitcherService.isDark$;

  constructor(private readonly themeSwitcherService: ThemeSwitcherService) {}

  onThemeChange(value: boolean) {
    this.themeSwitcherService.setTheme(!value);
  }
}
