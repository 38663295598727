import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TuiButtonModule, TuiDataListModule, TuiGroupModule, TuiTextfieldControllerModule } from '@taiga-ui/core';
import {
  TuiInputDateModule,
  TuiInputFilesModule,
  TuiInputModule,
  TuiInputTimeModule,
  TuiMultiSelectModule,
  TuiRadioBlockModule,
  TuiTextareaModule,
} from '@taiga-ui/kit';
import { TranslateModule } from '@ngx-translate/core';
import { FieldModule } from '@src/app/shared/field';
import { ComponentsModule } from '@src/app/components';
import { PhotoModule } from '@src/app/modules/photo';

import { QuestionComponent } from './components/question/question.component';
import { QuestionsComponent } from './questions.component';
import { ViewAllComponent } from './components/view-all/view-all.component';
import { ViewStepByStepComponent } from './components/view-step-by-step/view-step-by-step.component';
import { QuestionsService } from './services';

@NgModule({
  declarations: [QuestionComponent, QuestionsComponent, ViewAllComponent, ViewStepByStepComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    FieldModule,
    ComponentsModule,
    TuiButtonModule,
    TuiInputModule,
    TuiTextfieldControllerModule,
    TuiTextareaModule,
    TuiInputFilesModule,
    TuiRadioBlockModule,
    TuiMultiSelectModule,
    TuiDataListModule,
    TuiInputDateModule,
    TuiInputTimeModule,
    TuiGroupModule,
    PhotoModule,
  ],
  providers: [QuestionsService],
  exports: [QuestionsComponent],
})
export class QuestionsModule {}
