/**
 * Unions API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * справочник регионов продукта
 */
export interface ViewLoyaltyRegion { 
    /**
     * Наименование региона.
     */
    name: string;
    /**
     * Сортировка.
     */
    sortOrder?: number;
    /**
     * ID записи.
     */
    id?: string;
    /**
     * Используется.
     */
    inUse?: boolean;
}

