import { Component, ChangeDetectionStrategy, ChangeDetectorRef, Inject, Injector } from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';
import { BehaviorSubject, lastValueFrom } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TuiDialogService } from '@taiga-ui/core';
import { PolymorpheusComponent } from '@tinkoff/ng-polymorpheus';
import { ResizableBaseComponent } from '@src/app/components/resizable-base-component';
import { UserUI } from '@src/models';
import { BreakpointObserverHelperService, UserService } from '@src/core/services';
import { DialogConfirmComponent } from '@src/app/shared/dialogs';
import { ObjectId } from '@src/types/id';
import { TranslateService } from '@ngx-translate/core';
import { NEW_USERS_HINT } from '@src/app/modules/hint/constants';

@Component({
  selector: 'app-new-users',
  templateUrl: './new-users.component.html',
  styleUrls: ['./new-users.component.scss', '../styles/administration-content.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NewUsersComponent extends ResizableBaseComponent {
  unregistredUsers$: BehaviorSubject<UserUI[] | null> = this.userService.unregistredUsers$;
  selectedUserId: ObjectId;

  readonly newUsersHint = this.translateService.instant(NEW_USERS_HINT);

  private readonly confirmDialog = this.dialogService.open<boolean>(
    new PolymorpheusComponent(DialogConfirmComponent, this.injector),
    {
      label: this.translateService.instant('common.dialogs.userDeleteHeader'),
      size: 's',
      closeable: false,
    },
  );

  constructor(
    readonly cdr: ChangeDetectorRef,
    readonly breakpointObserver: BreakpointObserver,
    readonly breakpointObserverHelperService: BreakpointObserverHelperService,
    private userService: UserService,
    private readonly translateService: TranslateService,
    @Inject(TuiDialogService) private readonly dialogService: TuiDialogService,
    @Inject(Injector) private readonly injector: Injector,
  ) {
    super(cdr, breakpointObserver, breakpointObserverHelperService);
  }

  get showFullPage(): boolean {
    return !this.selectedUserId || this.isDoubleExtraLargeScreen;
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.getData();
  }

  onClickReturnButton(): void {
    this.selectedUserId = undefined;
    this.cdr.markForCheck();
  }

  changeSelectedUserId(selectedId?: ObjectId) {
    this.selectedUserId = selectedId;
  }

  onSaveEditingUser(): void {
    this.getData();
  }

  onDeleteUser(user?: UserUI): void {
    const id = user?.id;
    if (!id) return;

    this.confirmDialog.pipe(takeUntil(this.destroyed$$)).subscribe({
      next: res => {
        if (res === true) {
          lastValueFrom(this.userService.deleteUser(id)).then(() => {
            this.selectedUserId = undefined;
            this.getData();
          });
        }
      },
    });
  }

  private getData(): void {
    this.userService.resetUnregistredUsers();
    this.userService.getUnregistredUsers();
    this.cdr.markForCheck();
  }
}
