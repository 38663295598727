import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { TuiSizeL, TuiSizeM, TuiSizeS } from '@taiga-ui/core';
import { ViewMode } from '@src/models';

@Component({
  selector: 'app-field-web-page',
  templateUrl: './field-web-page.component.html',
  styleUrls: ['./field-web-page.component.scss', '../styles/field-content.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FieldWebPageComponent {
  @Input() mode?: ViewMode = 'view';
  @Input() name?: string;
  @Input() value?: string | null;
  @Input() size: TuiSizeS | TuiSizeM | TuiSizeL = 'l';
}
