import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { TuiButtonModule, TuiHostedDropdownModule, TuiScrollbarModule, TuiTextfieldControllerModule, TuiDropdownModule } from '@taiga-ui/core';
import { TuiInputModule } from '@taiga-ui/kit';
import { TuiMediaModule } from '@taiga-ui/cdk';
import { SharedModule } from '@src/app/shared';

import { AttachmentComponent } from './attachment.component';
import { AttachmentPreviewComponent } from './attachment-preview/attachment-preview.component';

@NgModule({
  declarations: [AttachmentComponent, AttachmentPreviewComponent],
  imports: [
    CommonModule,
    FormsModule,
    MatButtonModule,
    MatIconModule,
    MatListModule,
    TuiDropdownModule,
    TuiButtonModule,
    TuiHostedDropdownModule,
    TuiInputModule,
    TuiMediaModule,
    TuiScrollbarModule,
    TuiTextfieldControllerModule,
    SharedModule,
  ],
  exports: [AttachmentComponent],
})
export class AttachmentModule {}
