<div class="attachment">
  <input #fileInput type="file" hidden multiple />

  <tui-hosted-dropdown
    class="attachment__dropdown"
    tuiDropdownAlign="right"
    [content]="attachmentMenuTemplate"
    [(open)]="attachmentMenuOpened"
  >
    <button
      tuiIconButton
      class="attachment__button"
      type="button"
      appearance="icon"
      icon="tuiIconPaperclipLarge"
      size="s"
      [title]="'components.telegramAttachment.buttons.attach' | translate"
    ></button>
  </tui-hosted-dropdown>

  <ng-template #attachmentMenuTemplate>
    <mat-nav-list class="attachment__menu">
      <mat-list-item
        *ngFor="let item of attachmentMenuItems"
        class="attachment__menu-item attachment-menu-item"
        (click)="onClickAttachmentMenuItem(item)"
      >
        <mat-icon matListIcon class="attachment-menu-item__icon">{{ item.icon }}</mat-icon>
        <span matLine class="attachment-menu-item__title">{{ item.title }}</span>
      </mat-list-item>
    </mat-nav-list>
  </ng-template>

  <ng-template #confirmAttachmentDialog let-observer>
    <tui-scrollbar class="attachment__preview-scrollbar">
      <telegram-attachment-preview [attachmentPreview]="currentAttachmentPreview"></telegram-attachment-preview>
    </tui-scrollbar>
    <tui-input
      tuiTextfieldSize="m"
      class="attachment__caption"
      [tuiTextfieldLabelOutside]="true"
      [(ngModel)]="currentAttachmentCaption"
    >
      {{ 'components.telegramAttachment.fields.signature' | translate }}
    </tui-input>
    <button tuiButton type="button" size="m" class="tui-space_right-3" (click)="onClickConfirmAttachment(observer)">
      {{ 'common.buttons.send' | translate }}
    </button>
    <button tuiButton type="button" appearance="secondary" size="m" (click)="observer.complete()">
      {{ 'common.buttons.cancellation' | translate }}
    </button>
  </ng-template>
</div>
