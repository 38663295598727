import { BreakpointObserver } from '@angular/cdk/layout';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {
  BreakpointObserverHelperService,
  FileReaderService,
  PreferencesService,
  SubscriptionsForUsersCommitteeService,
  SubscriptionsForUsersService,
} from '@src/core/services';
import { ChatModel, GroupUI } from '@src/models';
import { CommitteeSubscriptionView, SubscriptionsForOrganisationView } from '@src/api';
import { TelegramMessengerService } from '@src/app/modules/telegram';
import { EnvService } from '@src/app/modules/env';
import { ResizableBaseComponent } from '@src/app/components/resizable-base-component';
import { TelegramChatService } from '@src/app/modules/telegram';
import { ObjectId } from '@src/types/id';

import { htmlTagsReplace } from '../chat/utils/htmlTagsReplace';

@Component({
  selector: 'telegram-news-feed',
  templateUrl: './news-feed.component.html',
  styleUrls: ['./news-feed.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NewsFeedComponent extends ResizableBaseComponent {
  chats?: ChatModel[];
  selectedChatId?: number;
  selectedChat?: ChatModel;
  forwardToChatListVisible: boolean = false;
  forwardMessageIds: number[] = [];
  fromChat?: ChatModel;

  groups?: GroupUI[];
  selectedGroupId?: string;
  organisationsSubscriptions$: BehaviorSubject<SubscriptionsForOrganisationView[] | null> =
    this.subscriptionsForUsersService.subscriptions$;
  committeesSubscriptions$: BehaviorSubject<CommitteeSubscriptionView[] | null> =
    this.subscriptionsForUsersCommitteeService.subscriptions$;
  loading = false;

  private isAuthorized = false;

  constructor(
    readonly cdr: ChangeDetectorRef,
    readonly breakpointObserver: BreakpointObserver,
    readonly breakpointObserverHelperService: BreakpointObserverHelperService,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private subscriptionsForUsersService: SubscriptionsForUsersService,
    private subscriptionsForUsersCommitteeService: SubscriptionsForUsersCommitteeService,
    private messengerService: TelegramMessengerService,
    private fileReaderService: FileReaderService,
    public readonly env: EnvService,
    private readonly preferences: PreferencesService,
    private readonly telegramChatsService: TelegramChatService,
  ) {
    super(cdr, breakpointObserver, breakpointObserverHelperService);
  }

  ngOnInit(): void {
    super.ngOnInit();

    if (this.env.isBot) {
      this.initGroupsList();
    }
  }

  async onSelectChatChange(chatId?: ObjectId): Promise<void> {
    this.router.navigate(['news-feed', chatId]);

    this.forwardToChatListVisible = false;
    this.selectedChatId = chatId as number;

    this.selectedChat = undefined;
    setTimeout(() => {
      this.selectedChat = this.chats?.find(chat => chat.id === this.selectedChatId);
      this.cdr.markForCheck();
    }, 0);
  }

  async onSelectGroupChange(groupId?: ObjectId): Promise<void> {
    this.selectedGroupId = groupId as string;

    const selectedGroupInviteLink = this.groups?.find(group => group.id === this.selectedGroupId)?.codeName;
    if (selectedGroupInviteLink) {
      this.env.openLink(selectedGroupInviteLink);
    }
  }

  onClickReturnButton(): void {
    this.selectedChatId = undefined;
    this.selectedChat = undefined;
    this.forwardToChatListVisible = false;
    this.forwardMessageIds = [];
  }

  onClickReturnToChatButton(): void {
    this.forwardToChatListVisible = false;
    this.forwardMessageIds = [];
  }

  get showFullPage(): boolean {
    return (!this.selectedChatId && !this.forwardToChatListVisible) || this.isLargeScreen;
  }

  async forwardToChat(chat: ChatModel, message?: string) {
    if (this.fromChat) {
      this.forwardToChatListVisible = false;
      this.selectedChat = this.fromChat;
      if (message) {
        const parsedMessage = await this.messengerService.api.parseTextEntities(htmlTagsReplace(message, false), {
          _: 'textParseModeHTML',
        });
        this.messengerService.api.sendMessageText(chat.id, parsedMessage);
      }

      this.messengerService.forwardMessages(chat.id, this.fromChat.id, this.forwardMessageIds);
    }
  }

  openForwardToChatDialog(forwardMessageIds: number[]) {
    this.forwardMessageIds = forwardMessageIds;
    this.fromChat = this.selectedChat;
    this.forwardToChatListVisible = true;
    this.cdr.detectChanges();
  }

  onLogged() {
    this.isAuthorized = true;
    this.initChatsList();
  }

  private initChatsList() {
    if (!this.isAuthorized) {
      return;
    }

    this.route.paramMap.pipe(takeUntil(this.destroyed$$)).subscribe(params => {
      this.selectedChatId = Number(params.get('channelId'));
      this.selectedChat = this.chats?.find(chat => chat.id === this.selectedChatId);
      this.cdr.markForCheck();
    });

    this.messengerService.updates$.pipe(takeUntil(this.destroyed$$)).subscribe(async update => {
      let chatId: number;
      let findChat: ChatModel | undefined;

      switch (update._) {
        case 'updateChatLastMessage':
          const { lastMessage } = update;
          chatId = update.chatId;
          findChat = this.chats?.find(chat => chat.id === chatId);
          if (findChat) {
            findChat.lastMessage = lastMessage;
            this.sortChats();
          }
          break;

        case 'updateChatPosition':
          const { position } = update;
          chatId = update.chatId;
          findChat = this.chats?.find(chat => chat.id === chatId);
          if (findChat) {
            findChat.positions = findChat.positions.map(oldPosition => {
              if (oldPosition.list._ === position.list._) oldPosition.order = position.order;
              return oldPosition;
            });
          }

          break;

        case 'updateChatReadInbox':
          findChat = this.chats?.find(chat => chat.id === update.chatId);
          if (findChat) {
            findChat.unreadCount = update.unreadCount;
            findChat.lastReadInboxMessageId = update.lastReadInboxMessageId;
          }
          break;

        case 'updateChatReadOutbox':
          findChat = this.chats?.find(chat => chat.id === update.chatId);
          if (findChat) {
            findChat.lastReadOutboxMessageId = update.lastReadOutboxMessageId;
          }

          break;

        case 'updateChatAction':
          findChat = this.chats?.find(chat => chat.id === update.chatId);
          if (findChat) {
            switch (update.action._) {
              case 'chatActionCancel':
                findChat.actionStatus = undefined;
                findChat.actionSenderId = undefined;
                break;

              default:
                findChat.actionStatus = update.action;

                if (update.senderId._ === 'messageSenderUser') {
                  findChat.actionSenderId = update.senderId.userId;
                } else if (update.senderId._ === 'messageSenderChat') {
                  findChat.actionSenderId = update.senderId.chatId;
                }

                break;
            }
          }

          break;

        case 'updateChatNotificationSettings':
          findChat = this.chats?.find(chat => chat.id === update.chatId);
          if (findChat) {
            findChat.notificationSettings = update.notificationSettings;
          }
          break;

        case 'updateNewMessage':
          const { message } = update;
          chatId = message.chatId;

          findChat = this.chats?.find(chat => chat.id === chatId);
          if (findChat) {
            const chatDetails = await this.messengerService.api.getChat(chatId);
            Object.assign(findChat, chatDetails);
          }
          break;

        case 'updateDeleteMessages':
          chatId = update.chatId;
          const messageIds = update.messageIds;

          findChat = this.chats?.find(chat => chat.id === chatId);
          if (findChat) {
            findChat.messages = findChat.messages?.filter(message => !messageIds.includes(message.id));
          }
          break;

        case 'updateUserStatus':
          findChat = this.chats?.find(chat => chat.id === update.userId);
          if (findChat) {
            findChat.userOnline = update.status._ === 'userStatusOnline';
          }
          break;

        case 'updateChatTitle':
          findChat = this.chats?.find(chat => chat.id === update.chatId);
          if (findChat) {
            findChat.title = update.title;
          }
          break;

        default:
          break;
      }

      if (this.chats) {
        this.chats = [...this.chats];
      }
    });

    this.loadChats();
  }

  private async loadChats(): Promise<void> {
    this.loading = true;

    try {
      this.chats = await this.telegramChatsService.getNewsFeed(this.selectedChatId);
      this.selectedChat = this.chats.find(item => item.active);
    } finally {
      this.loading = false;
      this.cdr.markForCheck();
    }
  }

  private sortChats(): void {
    this.chats?.sort((a, b) => {
      const lastMessageDateA = a.lastMessage?.date ?? 0;
      const lastMessageDateB = b.lastMessage?.date ?? 0;

      return lastMessageDateB - lastMessageDateA;
    });

    this.cdr.markForCheck();
  }

  private initGroupsList(): void {
    this.subscriptionsForUsersService.subscriptions$
      .pipe(takeUntil(this.destroyed$$))
      .subscribe(() => this.setGroups());
    this.subscriptionsForUsersCommitteeService.subscriptions$
      .pipe(takeUntil(this.destroyed$$))
      .subscribe(() => this.setGroups());

    this.subscriptionsForUsersService.getSubscriptions();
    this.subscriptionsForUsersCommitteeService.getSubscriptions();
  }

  private setGroups() {
    const organisationsSubscriptions = (this.organisationsSubscriptions$.value as GroupUI[] | null) ?? [];
    const committeesSubscriptions = (this.committeesSubscriptions$.value as GroupUI[] | null) ?? [];

    this.groups = [...new Set(organisationsSubscriptions.concat(committeesSubscriptions))];
    this.cdr.markForCheck();
  }
}
