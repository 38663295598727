import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TuiScrollbarModule } from '@taiga-ui/core';
import { NgxScrollPositionRestorationModule } from 'ngx-scroll-position-restoration';
import { TranslateModule } from '@ngx-translate/core';
import { NavigationModule } from '@src/app/modules/navigation';

import { InformationComponent } from './information.component';

@NgModule({
  declarations: [InformationComponent],
  imports: [CommonModule, NgxScrollPositionRestorationModule, NavigationModule, TranslateModule, TuiScrollbarModule],
})
export class InformationModule {}
