/**
 * Unions API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Информация для авторизации по коду.
 */
export interface AuthorizationData { 
    /**
     * Номер телефона пользователя.
     */
    phone: string;
    /**
     * Код авторизации.
     */
    code: string;
    /**
     * ID организации.
     */
    organisationId?: string;
    /**
     * ID ассоциации.
     */
    associationId?: string;
    /**
     * Короткий код аутентификации.
     */
    shortCode?: number;
}

