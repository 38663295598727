<div *ngIf="pollData" class="info">
  <h2 class="info__title">{{ 'components.questions.labels.poll' | translate }}: {{ pollData.titleText }}</h2>

  <h3 *ngIf="pollData.descriptionText" class="info__description">
    <app-parse-links [value]="pollData.descriptionText"></app-parse-links>
  </h3>

  <div *ngIf="pollData.pollDuration" class="info__time">
    {{ 'components.questions.labels.pollDuration' | translate }}: {{ pollData.pollDuration }}
    {{ 'components.questions.labels.minutesShort' | translate }}
  </div>

  <div *ngIf="!!pollData.timeLimit" class="info__time">
    {{ 'components.questions.labels.timeLimit' | translate }}: {{ pollData.timeLimit }}
    {{ 'components.questions.labels.minutesShort' | translate }}
  </div>

  <div *ngIf="documentsList?.length" class="info__documents">
    <app-field
      [name]="'components.questions.labels.additionalDocs' | translate"
      [typeName]="'files-list'"
      [value]="documentsList"
      [size]="'s'"
    ></app-field>
  </div>
</div>

<view-all
  *ngIf="mode === 'all'"
  [pollId]="pollData?.id"
  (pollStopped)="onStoppedPoll()"
  (pollFinished)="onFinishedPoll()"
></view-all>

<view-step-by-step
  *ngIf="mode === 'step-by-step'"
  [pollId]="pollData?.id"
  (pollStopped)="onStoppedPoll()"
  (pollFinished)="onFinishedPoll()"
></view-step-by-step>
