<div class="message-photo">
  <div class="message-photo__message-content message-content">
    <div class="message-photo__photo-wrapper" [class.tui-skeleton]="!photo.src">
      <img
        #photo
        width="100%"
        [src]="messagePhotoSource?.src | safe: 'url'"
        [alt]="messagePhoto?.caption?.text || ('components.messagePhoto.labels.image' | translate)"
        [title]="messagePhoto?.caption?.text || ('components.messagePhoto.labels.image' | translate)"
        (click)="viewImage()"
      />
    </div>
    <ng-content></ng-content>
  </div>
</div>
