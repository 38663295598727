/**
 * Unions API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CommitteeContactViewDto } from './committeeContactViewDto';


export interface CommitteeViewWithSubCommitteesAndContactsDto { 
    id: string;
    name: string;
    description?: string;
    photoId?: string;
    parentId?: string;
    subCommitties?: Array<CommitteeViewWithSubCommitteesAndContactsDto>;
    contacts?: Array<CommitteeContactViewDto>;
    usersCount?: number;
}

