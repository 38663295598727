<tui-avatar
  class="item__image"
  size="s"
  [rounded]="true"
  [autoColor]="!item?.photoId"
  [avatarUrl]="item?.photoId | getImageSrc"
  [text]="item?.name || ''"
></tui-avatar>
<div class="item__text-box">
  <div class="item__title item__title_line item__title_line_2">{{ item?.name }}</div>
  <div class="item__description">{{ item?.description }}</div>
</div>
