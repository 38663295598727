import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { APP_CONFIG } from '@src/core';

export const SHOW_INTERRUPT_SESSION_TIMER = 10000;

/**
 * Команда, которую отправляем боту, чтобы получить токен
 */
export const GET_TOKEN_COMMAND = '/getToken';

export const TELEGRAM_API_CONFIG = {
  id: 3778814,
  hash: 'b1b62e6645cd09ee4d2447824f7b8550',
  baseUrl: 'https://t.me/',
} as const;

export const TELEGRAM_LIMITS = {
  foldersCount: 10,
  groups: {
    name: 255,
    description: 255,
  },
} as const;

export const MUTE_FOR_WEEK = 522872381;

export const MAIN_BOT = APP_CONFIG.bots.main;

/**
 * Сообщения бота
 */
export const BOT_MESSAGES = {
  notRegistered: 'Если кнопка не видна, выполните команду /start.',
  phoneConfirmed: 'Подтверждение прошло успешно.',
  phoneNotConfirmed: 'К сожалению, номер телефона не зарегистрирован в системе."',
  userNotAdded: 'Ошибка получения токена.',
  registered: 'Для начала работы нажмите кнопку "Меню".',
} as const;

/**
 * Ошибки Telegram
 */
//https://core.telegram.org/methods - ссылка на методы с кодами ошибок
export const TELEGRAM_ERRORS: { [k: string]: string } = {
  PHONE_NUMBER_INVALID: marker('components.telegram.constants.telegramErrors.phoneNumberInvalid'),
  PHONE_CODE_INVALID: marker('components.telegram.constants.telegramErrors.phoneCodeInvalid'),
  PASSWORD_HASH_INVALID: marker('components.telegram.constants.telegramErrors.passwordHashInvalid'),
  PHONE_PASSWORD_FLOOD: marker('components.telegram.constants.telegramErrors.phonePasswordFlood'),
  PHONE_NUMBER_BANNED: marker('components.telegram.constants.telegramErrors.phoneNumberBanned'),
  BOTS_TOO_MUCH: marker('components.telegram.constants.telegramErrors.botsTooMuch'),
  BOT_GROUPS_BLOCKED: marker('components.telegram.constants.telegramErrors.botGroupsBlocked'),
  USERS_TOO_MUCH: marker('components.telegram.constants.telegramErrors.usersTooMuch'),
  USER_CHANNELS_TOO_MUCH: marker('components.telegram.constants.telegramErrors.userChannelsTooMuch'),
  USER_KICKED: marker('components.telegram.constants.telegramErrors.userKicked'),
  USER_NOT_MUTUAL_CONTACT: marker('components.telegram.constants.telegramErrors.userNotMutualContact'),
  USER_PRIVACY_RESTRICTED: marker('components.telegram.constants.telegramErrors.userPrivacyRestricted'),
  INVITE_HASH_EMPTY: marker('components.telegram.constants.telegramErrors.inviteHashEmpty'),
  INVITE_HASH_EXPIRED: marker('components.telegram.constants.telegramErrors.inviteHashExpired'),
  INVITE_HASH_INVALID: marker('components.telegram.constants.telegramErrors.inviteHashInvalid'),
  CHANNELS_TOO_MUCH: marker('components.telegram.constants.telegramErrors.channelsTooMuch'),
  USER_ALREADY_PARTICIPANT: marker('components.telegram.constants.telegramErrors.userAlreadyParticipant'),
  SCHEDULE_DATE_INVALID: marker('components.telegram.constants.telegramErrors.scheduleDateInvalid'),
  WALLPAPER_DIMENSIONS_INVALID: marker('components.telegram.constants.telegramErrors.wallpaperDimensionsInvalid'),
  ADMINS_TOO_MUCH: marker('components.telegram.constants.telegramErrors.adminsTooMuch'),
  ADMIN_RANK_EMOJI_NOT_ALLOWED: marker('components.telegram.constants.telegramErrors.adminRankEmojiNotAllowed'),
  ADMIN_RANK_INVALID: marker('components.telegram.constants.telegramErrors.adminRankInvalid'),
  FRESH_CHANGE_ADMINS_FORBIDDEN: marker('components.telegram.constants.telegramErrors.freshChangeAdminsForbidden'),
  INPUT_USER_DEACTIVATED: marker('components.telegram.constants.telegramErrors.inputUserDeactivated'),
  BOT_PRECHECKOUT_TIMEOUT: marker('components.telegram.constants.telegramErrors.botPrecheckoutTimeout'),

  //TODO start: Данные сообщения возвращаются в уведомлении (не обрабатываются)
  CHAT_RESTRICTED: marker('components.telegram.constants.telegramErrors.chatRestricted'),
  CHAT_SEND_POLL_FORBIDDEN: marker('components.telegram.constants.telegramErrors.chatSendPollForbidden'),
  CHAT_SEND_STICKERS_FORBIDDEN: marker('components.telegram.constants.telegramErrors.chatSendStickersForbidden'),
  CHAT_SEND_GIFS_FORBIDDEN: marker('components.telegram.constants.telegramErrors.chatSendGifsForbidden'),
  CHAT_SEND_MEDIA_FORBIDDEN: marker('components.telegram.constants.telegramErrors.chatSendMediaForbidden'),
  CHAT_LINK_EXISTS: marker('components.telegram.constants.telegramErrors.chatLinkExists'),
  SLOWMODE_WAIT_X: marker('components.telegram.constants.telegramErrors.slowmodeWaitX'),
  USER_BANNED_IN_CHANNEL: marker('components.telegram.constants.telegramErrors.userBannedInChannel'),
  USER_IS_BLOCKED: marker('components.telegram.constants.telegramErrors.userIsBlocked'),
  YOU_BLOCKED_USER: marker('components.telegram.constants.telegramErrors.youBlockedUser'),
  IMAGE_PROCESS_FAILED: marker('components.telegram.constants.telegramErrors.imageProcessFailed'),
  MEDIA_EMPTY: marker('components.telegram.constants.telegramErrors.mediaEmpty'),
  MEDIA_INVALID: marker('components.telegram.constants.telegramErrors.mediaInvalid'),
  PHOTO_CROP_SIZE_SMALL: marker('components.telegram.constants.telegramErrors.photoCropSizeSmall'),
  PHOTO_EXT_INVALID: marker('components.telegram.constants.telegramErrors.photoExtInvalid'),
  PHOTO_INVALID_DIMENSIONS: marker('components.telegram.constants.telegramErrors.photoInvalidDimensions'),
  PHOTO_SAVE_FILE_INVALID: marker('components.telegram.constants.telegramErrors.photoSaveFileInvalid'),
  MESSAGE_DELETE_FORBIDDEN: marker('components.telegram.constants.telegramErrors.messageDeleteForbidden'),
  MESSAGE_POLL_CLOSED: marker('components.telegram.constants.telegramErrors.messagePollClosed'),
  MESSAGE_EDIT_TIME_EXPIRED: marker('components.telegram.constants.telegramErrors.messageEditTimeExpired'),
  PINNED_DIALOGS_TOO_MUCH: marker('components.telegram.constants.telegramErrors.pinnedDialogsTooMuch'),
  CHANNEL_PRIVATE: marker('components.telegram.constants.telegramErrors.channelPrivate'),
  MEDIA_CAPTION_TOO_LONG: marker('components.telegram.constants.telegramErrors.mediaCaptionTooLong'),
  ADDRESS_STREET_LINE1_INVALID: marker('components.telegram.constants.telegramErrors.addressStreetLine1Invalid'),
  ADDRESS_STREET_LINE2_INVALID: marker('components.telegram.constants.telegramErrors.addressStreetLine2Invalid'),
  ADDRESS_CITY_INVALID: marker('components.telegram.constants.telegramErrors.addressCityInvalid'),
  ADDRESS_COUNTRY_INVALID: marker('components.telegram.constants.telegramErrors.addressCountryInvalid'),
  ADDRESS_POSTCODE_INVALID: marker('components.telegram.constants.telegramErrors.addressPostcodeInvalid'),
  ADDRESS_STATE_INVALID: marker('components.telegram.constants.telegramErrors.addressStateInvalid'),
  REQ_INFO_NAME_INVALID: marker('components.telegram.constants.telegramErrors.reqInfoNameInvalid'),
  REQ_INFO_PHONE_INVALID: marker('components.telegram.constants.telegramErrors.reqInfoPhoneInvalid'),
  REQ_INFO_EMAIL_INVALID: marker('components.telegram.constants.telegramErrors.reqInfoEmailInvalid'),
  CHANNEL_INVALID: marker('components.telegram.constants.telegramErrors.channelInvalid'),
  LINK_NOT_MODIFIED: marker('components.telegram.constants.telegramErrors.linkNotModified'),
  MESSAGE_TOO_LONG: marker('components.telegram.constants.telegramErrors.messageTooLong'),
  //TODO end

  // Non-API errors
  SERVICE_WORKER_DISABLED:
    'Service Worker is disabled. Streaming media may not be supported. Try reloading the page without holding &lt;Shift&gt; key',
  MESSAGE_TOO_LONG_PLEASE_REMOVE_CHARACTERS:
    'The provided message is too long. Please remove {EXTRA_CHARS_COUNT} character{PLURAL_S}.',
  FRESH_RESET_AUTHORISATION_FORBIDDEN:
    'You can’t logout other sessions if less than 24 hours have passed since you logged on the current session',
};

/**
 * Интервал проверки статуса основного бота
 */
export const CHECK_MAIN_BOT_STATUS_INTERVAL = 5000;

/**
 * Время окончания ожидания чтения статуса бота
 */
export const CHECK_MAIN_BOT_STATUS_TIMEOUT = (10 * 60 * 1000) / CHECK_MAIN_BOT_STATUS_INTERVAL;

/**
 * Время окончания ожидания токена из бота
 */
export const BOT_TOKEN_SENDING_TIMEOUT = 10 * 1000;
