import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FILE_SIZE, FileSizeType } from '@src/constants/file-size';
import filesize from 'filesize';

@Pipe({
  name: 'fileSize',
})
export class FileSizePipe implements PipeTransform {
  constructor(private readonly translateService: TranslateService) {}

  transform(value: number): string {
    return filesize(value, {
      symbols: this.getTranslateSymbols(),
    });
  }

  private getTranslateSymbols() {
    Object.entries(FILE_SIZE).forEach(([key, value]) => {
      FILE_SIZE[key as FileSizeType] = this.translateService.instant(value);
    });

    return FILE_SIZE;
  }
}
