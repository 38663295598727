import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'app-skeleton-row',
  templateUrl: './skeleton-row.component.html',
  styleUrls: ['./skeleton-row.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SkeletonRowComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
