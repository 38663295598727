<div class="new-users">
  <div class="new-users__content content">
    <div class="content__header">
      <button
        *ngIf="!showFullPage && selectedUserId"
        tuiIconButton
        class="content__return-button"
        type="button"
        appearance="icon"
        icon="tuiIconArrowLeftLarge"
        size="s"
        [title]="'common.buttons.return' | translate"
        (click)="onClickReturnButton()"
      ></button>
      <ng-container *ngIf="showFullPage">
        <h1 class="content__title">{{ 'components.newUsers.labels.title' | translate }}</h1>
        <app-hint [value]="newUsersHint"></app-hint>
      </ng-container>
    </div>

    <div [hide]="!showFullPage" class="content__list">
      <app-list
        class="list"
        listType="users"
        [items]="unregistredUsers$ | async"
        [selectedId]="selectedUserId"
        [withCounter]="true"
        (selectedIdChange)="changeSelectedUserId($event)"
      ></app-list>
    </div>

    <tui-scrollbar *ngIf="selectedUserId" class="content__content">
      <app-user-info
        mode="view"
        [userId]="selectedUserId"
        (saved)="onSaveEditingUser()"
        (deleted)="onDeleteUser($event)"
      ></app-user-info>
    </tui-scrollbar>
  </div>
</div>
