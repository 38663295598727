import { Pipe, PipeTransform } from '@angular/core';
import { normalizeJobTitles } from '@src/utils';
import { OrganisationJobTitleUI } from '@src/models';

@Pipe({
  name: 'jobTitles',
})
export class JobTitlesPipe implements PipeTransform {
  transform(organisationJobTitles?: OrganisationJobTitleUI[], isDefault: boolean = false): OrganisationJobTitleUI[] {
    const jobTitles = normalizeJobTitles(organisationJobTitles);
    if (isDefault) {
      return jobTitles.filter(item => item.isDefault);
    }

    return jobTitles;
  }
}
