/**
 * Unions API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * группа получателей скидки и её условия
 */
export interface ViewLoyaltyProductGroup { 
    /**
     * Размер скидки в %.
     */
    discountRate?: number;
    /**
     * Текстовое представление скидки.
     */
    discountText?: string;
    /**
     * Размер скидки в абсолютных величинах.
     */
    discountAbsolute?: number;
    /**
     * Условия.
     */
    discountOptions: string;
    /**
     * Код.
     */
    discountCode?: string;
    /**
     * Признак необходимости генерации изображения QR-кода в интерфейсе.
     */
    generateQRCode: boolean;
    /**
     * Id загруженного файла изображения с QR-кодом.
     */
    discountQRCodeFileId?: string;
    /**
     * наименование варианта продукта.
     */
    groupName?: string;
    /**
     * единый постоянный код или список кодов.
     */
    isPermanentCode?: boolean;
    /**
     * признак что каждый код одноразовый или многоразовый.
     */
    isMultipleCodeUse?: boolean;
    /**
     * максимальное кол-во применений кода за 30 дней (месяц), 0 - неограничено.
     */
    maxCodeUsePerMonth?: number;
    /**
     * ID файла с одноразовыми кодами.
     */
    codesFileId?: string;
    /**
     * ID продукта.
     */
    productId: string;
    /**
     * ID записи.
     */
    id: string;
}

