import { Component, ChangeDetectionStrategy, ChangeDetectorRef, Inject } from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';
import { TuiDialogContext } from '@taiga-ui/core';
import { POLYMORPHEUS_CONTEXT } from '@tinkoff/ng-polymorpheus';
import { BreakpointObserverHelperService } from '@src/core/services';
import { ResizableBaseComponent } from '@src/app/components/resizable-base-component';

@Component({
  selector: 'telegram-dialog-confirm-login',
  templateUrl: './dialog-confirm-login.component.html',
  styleUrls: ['./dialog-confirm-login.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DialogConfirmLoginComponent extends ResizableBaseComponent {
  constructor(
    readonly cdr: ChangeDetectorRef,
    readonly breakpointObserver: BreakpointObserver,
    readonly breakpointObserverHelperService: BreakpointObserverHelperService,
    @Inject(POLYMORPHEUS_CONTEXT) private readonly context: TuiDialogContext<boolean>,
  ) {
    super(cdr, breakpointObserver, breakpointObserverHelperService);
  }

  onLogged() {
    this.context.completeWith(true);
  }
}
