import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TuiToggleModule } from '@taiga-ui/kit';
import { TranslateModule } from '@ngx-translate/core';

import { ThemeSwitcherComponent } from './components/theme-switcher/theme-switcher.component';
import { ThemeSwitcherMiniComponent } from './components/theme-switcher-mini/theme-switcher-mini.component';

@NgModule({
  declarations: [ThemeSwitcherComponent, ThemeSwitcherMiniComponent],
  imports: [CommonModule, FormsModule, TuiToggleModule, TranslateModule],
  exports: [ThemeSwitcherComponent, ThemeSwitcherMiniComponent],
})
export class ThemeSwitcherModule {}
