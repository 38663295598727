/**
 * Unions API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Типы сущностей.
 */
export type EntityType = 1 | 2 | 3 | 4 | 5;

export const EntityType = {
    NUMBER_1: 1 as EntityType,
    NUMBER_2: 2 as EntityType,
    NUMBER_3: 3 as EntityType,
    NUMBER_4: 4 as EntityType,
    NUMBER_5: 5 as EntityType
};

