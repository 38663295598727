import { Pipe, PipeTransform } from '@angular/core';
import { TuiDay } from '@taiga-ui/cdk';

@Pipe({
  name: 'calendar',
})
export class CalendarPipe implements PipeTransform {
  transform(value: Date | null, type: 'min' | 'max'): TuiDay {
    if (!type) throw new Error('Type not specified');

    switch (type) {
      case 'min':
        const min = value ? new TuiDay(value.getFullYear(), value.getMonth(), value.getDate()) : new TuiDay(2000, 0, 1);
        return min;
      case 'max':
        const max = value ? new TuiDay(value.getFullYear(), value.getMonth(), value.getDate()) : new TuiDay(3000, 0, 1);
        return max;
      default:
        throw new Error(`Invalid type: ${type}`);
    }
  }
}
