import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AuthService, LogoutService } from '@src/app/modules/auth';
import { logger } from '@src/utils';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  /**
   * TODO: Правильно использовать SessionService, но при его инжектировании Angular пишет ошибку,
   * что обнаружена циклическая зависимость.
   */
  constructor(private authService: AuthService, private logoutService: LogoutService) {
    logger('TokenInterceptor constructor');
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const authRequest = request.clone({
      setHeaders: {
        Authorization: `Bearer ${this.authService.getToken()}`,
      },
    });

    return next.handle(authRequest).pipe(
      tap({
        error: (error: any) => {
          if (error instanceof HttpErrorResponse) {
            if (error.status === 401) {
              this.logoutService.logout();
            }
          }
        },
      }),
    );
  }
}
