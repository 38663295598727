import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export abstract class BaseSearchService<FilterType, SortType, ReturnType> {
  filter$: BehaviorSubject<FilterType | null> = new BehaviorSubject<FilterType | null>(null);
  sort$: BehaviorSubject<SortType | null> = new BehaviorSubject<SortType | null>(null);
  pagination$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  abstract search(pagination: number, filter: FilterType, sort?: SortType | null): Observable<ReturnType>;
}
