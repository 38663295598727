/**
 * Unions API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Настройки организации.
 */
export interface OrganisationShortInfo { 
    /**
     * ID организации.
     */
    id?: string;
    /**
     * Кратное наименование организации.
     */
    shortName?: string;
    /**
     * Полное наименование организации.
     */
    fullName?: string;
    /**
     * ID фотографии.
     */
    photoId?: string;
    /**
     * ID документа политики конфиденциальности.
     */
    policiesAcceptanceId?: string;
    /**
     * ID документа согласия на обработку персональных данных.
     */
    personalDataShareAcceptanceId?: string;
    /**
     * Apple ссылка.
     */
    appStoreLink?: string;
    /**
     * Google ссылка.
     */
    googlePlayLink?: string;
    /**
     * заголовок для страницы авторизации.
     */
    loginScreenTitle?: string;
    /**
     * Описание для страницы авторизации.
     */
    loginScreenDescription?: string;
    /**
     * ID бота.
     */
    botId?: number;
    /**
     * Наименование бота.
     */
    botName?: string;
    /**
     * ID типа бота.
     */
    botTypeId?: number;
    /**
     * Наименование типа бота.
     */
    botTypeName?: string;
    /**
     * ссылка на политику конфиденциальности.
     */
    privacyPolicyLink?: string;
    disableMessengerLogin?: boolean;
    allowUserRegistrationForm?: boolean;
    darkThemeByDefault?: boolean;
}

