import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { TuiSizeS } from '@taiga-ui/core';

/**
 * Пустое поле
 * @param {string} text - (опционально) текст внутри поля
 * @param {TuiSizeS} size - (опционально) размер текста внутри поля
 * @param {string} label - (опционально) текст над полем
 * @param {string} description - (опционально) описание под полем
 *
 * @see https://www.figma.com/file/tdtoaaGrQa3XOG9Qqv96VI/Unions-(Taiga-3.0)?type=design&node-id=2249-120402&mode=dev
 */
@Component({
  selector: 'app-field-wrapper',
  templateUrl: './field-wrapper.component.html',
  styleUrls: ['./field-wrapper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FieldWrapperComponent {
  @Input() text?: string = '';
  @Input() size?: TuiSizeS = 'm';
  @Input() label?: string = '';
  @Input() description?: string = '';
}
