import { Component, Input } from '@angular/core';
import { PollUI } from '@src/models';
import { DECISION_TYPE_CODE } from '@src/constants';
import { UserService } from '@src/core/services';
import { ObjectId } from '@src/types/id';
import { BadgeColors, BadgeOptions } from '@src/app/components';
import { TranslateService } from '@ngx-translate/core';

import { ItemType } from '../model/item.model';

@Component({
  selector: 'app-list-item-polls',
  templateUrl: './list-item-polls.component.html',
  styleUrls: ['../styles/list-item.component.scss', './list-item-polls.component.scss'],
})
export class ListItemPollsComponent {
  @Input() set item(data: ItemType | undefined) {
    this.data = data as PollUI;

    if (this.data.decisionTypeCode === DECISION_TYPE_CODE.Rejected) {
      this.badgeOptions = {
        size: 's',
        color: BadgeColors.Red,
        value: this.translateService.instant('components.listItemPolls.labels.rejected'),
      };
    } else if (this.data.decisionTypeCode === DECISION_TYPE_CODE.Passed) {
      this.badgeOptions = {
        size: 's',
        color: BadgeColors.Green,
        value: this.translateService.instant('components.listItemPolls.labels.passed'),
      };
    } else if (this.data.dateEnd ? new Date(this.data.dateEnd) < new Date() : true) {
      this.badgeOptions = {
        size: 's',
        color: BadgeColors.Grey,
        value: this.translateService.instant('components.listItemPolls.labels.completed'),
      };
    }
  }

  @Input() selectedId: ObjectId;
  @Input() loading?: boolean;

  data?: PollUI;
  authUserId?: String;
  badgeOptions?: BadgeOptions;

  constructor(private userService: UserService, private readonly translateService: TranslateService) {
    this.authUserId = this.userService.authUser?.id;
  }

  isInvitation() {
    return (
      (this.data?.decisionTypeCode === DECISION_TYPE_CODE.Sended ||
        this.data?.decisionTypeCode === DECISION_TYPE_CODE.Readed) &&
      this.data?.status !== 5 &&
      (this.data?.dateEnd ? new Date(this.data?.dateEnd) >= new Date() : true)
    );
  }
}
