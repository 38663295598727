<div class="discount-groups-dialog">
  <ng-container *ngFor="let discountGroup of product.terms">
    <lp-view-discount-group-row
      [discountGroup]="discountGroup"
      (getDiscount)="getDiscount($event)"
    ></lp-view-discount-group-row>

    <div class="separator"></div>
  </ng-container>
</div>
