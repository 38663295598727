/**
 * Unions API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Организация с должностями пользователя.
 */
export interface OrganisationWithJobTitles { 
    /**
     * Наименование организации.
     */
    organisationName?: string;
    /**
     * ID организации.
     */
    organisationId?: string;
    /**
     * Должности в организации.
     */
    jobTitles?: Array<string>;
    /**
     * Организация по-умолчанию.
     */
    isDefault?: boolean;
}

