import { Inject, Injectable, Injector, OnDestroy, TemplateRef } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';
import { TuiDialogService } from '@taiga-ui/core';
import { PolymorpheusComponent } from '@tinkoff/ng-polymorpheus';
import { UserUI } from '@src/models';
import { Empty, Nullable, Optional } from '@src/types/utils';
import { UsersSearchParameters } from '@src/api';
import { BreakpointObserverHelperService } from '@src/core/services';

import { FilterFieldsOptions, SearchUsersInputValue, SearchUsersMode, SearchUsersProps } from '../types';
import { SearchUsersDialogComponent } from '../components';

@Injectable({
  providedIn: 'root',
})
export class SearchUsersService implements OnDestroy {
  private destroyed$$: Subject<void> = new Subject<void>();

  constructor(
    @Inject(TuiDialogService) private readonly dialogService: TuiDialogService,
    @Inject(Injector) private readonly injector: Injector,
    private readonly breakpointObserverHelperService: BreakpointObserverHelperService,
  ) {}

  ngOnDestroy(): void {
    this.destroyed$$.next();
    this.destroyed$$.complete();
  }

  search(
    data: Empty<SearchUsersInputValue>,
    mode: SearchUsersMode = 'multiple',
    statusContent?: TemplateRef<UserUI>,
    excludeUsersIds?: string[],
    excludeUsersTelegramIds?: number[],
    filter?: UsersSearchParameters,
    excludeFilterFields?: FilterFieldsOptions[],
  ): Observable<UserUI[]> {
    let items: UserUI[] = [];

    if (data) {
      if (mode === 'multiple' && Array.isArray(data)) {
        items = data;
      } else if (mode === 'single' && !Array.isArray(data) && data.id) {
        items = [data];
      }
    }

    return this.dialogService.open<UserUI[]>(new PolymorpheusComponent(SearchUsersDialogComponent, this.injector), {
      size: this.breakpointObserverHelperService.isMobile ? 'fullscreen' : 'm',
      data: <SearchUsersProps>{
        mode,
        items,
        statusContent,
        excludeUsersIds,
        excludeUsersTelegramIds,
        filter,
        excludeFilterFields,
      },
    });
  }

  /**
   * @example
   * import { SearchUsersService } from '@src/app/modules/search-users';
   *
   * constructor(private readonly searchUsers: SearchUsersService) {}
   *
   * this.searchUsers.searchSingle(user).subscribe(result => ...);
   */
  searchSingle(
    data?: Nullable<UserUI>,
    statusContent?: TemplateRef<UserUI>,
    excludeUsersIds?: string[],
    excludeUsersTelegramIds?: number[],
    filter?: UsersSearchParameters,
    excludeFilterFields?: FilterFieldsOptions[],
  ): Observable<UserUI> {
    return this.search(
      data,
      'single',
      statusContent,
      excludeUsersIds,
      excludeUsersTelegramIds,
      filter,
      excludeFilterFields,
    ).pipe(
      switchMap(([result]) => of(result)),
      takeUntil(this.destroyed$$),
    );
  }

  /**
   * @example
   * <ng-template #userStatusRender let-data>
   *   {{ data.id }}
   * </ng-template>
   *
   * import { SearchUsersService } from '@src/app/modules/search-users';
   *
   * @ViewChild('userStatusRender') statusRenderRef?: TemplateRef<UserUI>;
   * constructor(private readonly searchUsers: SearchUsersService) {}
   *
   * this.searchUsers.searchMultiple(users, this.statusRenderRef).subscribe(result => ...);
   */
  searchMultiple(
    data?: Nullable<UserUI[]>,
    statusContent?: TemplateRef<UserUI>,
    excludeUsersIds?: string[],
    excludeUsersTelegramIds?: number[],
    filter?: UsersSearchParameters,
    excludeFilterFields?: FilterFieldsOptions[],
  ): Observable<Optional<UserUI[]>> {
    return this.search(
      data,
      'multiple',
      statusContent,
      excludeUsersIds,
      excludeUsersTelegramIds,
      filter,
      excludeFilterFields,
    ).pipe(takeUntil(this.destroyed$$));
  }
}
