<div class="attachment-preview">
  <ng-container *ngFor="let attachment of attachmentPreview">
    <ng-container [ngSwitch]="attachment.type">
      <audio
        *ngSwitchCase="'audio'"
        tuiMedia
        controls
        class="attachment-preview__audio"
        [src]="attachment.path | safe : 'url'"
      ></audio>
      <video
        *ngSwitchCase="'video'"
        tuiMedia
        autoplay
        loop
        class="attachment-preview__video"
        [muted]="'muted'"
        [src]="attachment.path | safe : 'url'"
      ></video>
      <img
        *ngSwitchCase="'image'"
        class="attachment-preview__image"
        [src]="attachment.path | safe : 'url'"
        [alt]="attachment?.file?.name"
      />
      <div *ngSwitchDefault class="attachment-preview__file preview-file">
        <mat-icon class="preview-file__icon">insert_drive_file</mat-icon>
        <div class="preview-file__text-box">
          <p class="preview-file__text">{{ attachment.file.name }}</p>
          <p class="preview-file__subtext">{{ attachment.file.size || 0 | fileSize }}</p>
        </div>
      </div>
    </ng-container>
  </ng-container>
</div>
