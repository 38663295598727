/**
 * Unions API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { NameValuePair } from './nameValuePair';


/**
 * Параметры для поиска пользователей.
 */
export interface UsersSearchParameters { 
    /**
     * Массив идентификаторов должностей.
     */
    jobTitleIds?: Array<string>;
    /**
     * Массив идентификаторов комитетов.
     */
    committeeIds?: Array<string>;
    /**
     * Массив идентификаторов организаций.
     */
    organisationIds?: Array<string>;
    /**
     * Массив идентификаторов видов деятельности организаций.
     */
    organisationTypeIds?: Array<string>;
    /**
     * Фамилия.
     */
    lastName?: string;
    /**
     * Отчество.
     */
    middleName?: string;
    /**
     * Имя.
     */
    firstName?: string;
    /**
     * ФИО.
     */
    name?: string;
    /**
     * Искать по точному совпадению имени.
     */
    searchForExactMatchName?: boolean;
    /**
     * TRUE искать только подтвержденых пользователей (telegramId != 0). FALSE искать созданых, но неподтвержденных пользователей (telegramId == 0). NULL по-умолчанию, искать всех.
     */
    searchForRegistredOnly?: boolean;
    /**
     * ID отрасли из табл BusinessTypesForUnion.
     */
    businessTypeIds?: Array<string>;
    /**
     * Параметр отображения сотрудников УО: TRUE - отображать всех сотрудников; false - исключить из выборки всех сотрудников, принадлежащих управляющей организации текущей ассоциации. ВАЖНО! Если сотрудник относится и к УО и к нижестоящей организации, то он должен быть включен в список.
     */
    showParentOrgUsers?: boolean;
    userContact?: string;
    contacts?: Array<NameValuePair>;
}

