import { TuiFileLike } from '@taiga-ui/kit';

import { APP_CONFIG } from '../core/config';

import { MultimediaSource } from './multimedia.model';

const fileTypes = { ...APP_CONFIG.fileTypes } as const;

export type DocumentTypes =
  | typeof fileTypes.audio[number]
  | typeof fileTypes.video[number]
  | typeof fileTypes.image[number]
  | typeof fileTypes.textDocument[number];

export interface DocumentSource extends MultimediaSource {
  fileName?: string;
  fileSize?: string;
  thumbnailSrc?: string;
}

export interface TuiFileLikeUI extends TuiFileLike {
  id?: string;
}
