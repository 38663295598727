<div class="reports">
  <ng-container *ngIf="reports$ | async as reports; else tmpLoader">
    <div *ngFor="let report of reports" class="reports-item">
      <report-form [data]="report"></report-form>
    </div>
  </ng-container>

  <ng-template #tmpLoader>
    <div
      *ngFor="let report of [1, 2, 3]"
      class="skeleton-row"
      [class.tui-skeleton]="true"
      [class.tui-skeleton_rounded]="true"
    ></div>
  </ng-template>
</div>
