<p>{{ context.data || 'common.dialogs.areYouSureTitle' | translate }}</p>
<button
  tuiButton
  appTestingId="Button.Apply"
  type="button"
  [size]="tuiElementMediumSize"
  class="tui-space_right-3"
  (click)="apply()"
>
  {{ 'common.buttons.yes' | translate }}
</button>
<button
  tuiButton
  appTestingId="Button.Cancel"
  appearance="secondary"
  type="button"
  [size]="tuiElementMediumSize"
  (click)="cancel()"
>
  {{ 'common.buttons.no' | translate }}
</button>
