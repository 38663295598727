import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { LoyaltyProgramGrade } from '../../models';
import { GRADE_COLORS, GRADE_TITLES, GRADE_SVG } from '../../constants';

import { GradeTagSize } from './types';

@Component({
  selector: 'lp-grade-tag',
  templateUrl: './grade-tag.component.html',
  styleUrls: ['./grade-tag.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GradeTagComponent {
  @Input() size: GradeTagSize = 'l';
  @Input() showTitle = false;
  @Input() invert = false;

  @Input() set grade(value: LoyaltyProgramGrade | undefined) {
    this._grade = value ?? 'usual';
  }

  private _grade: LoyaltyProgramGrade = 'usual';

  constructor(private readonly translateService: TranslateService) {}

  get backgroundColor() {
    if (this.invert) {
      return 'transparent';
    }

    return GRADE_COLORS[this._grade];
  }

  get color() {
    if (this.invert) {
      return GRADE_COLORS[this._grade];
    }

    return 'var(--tui-base-01)';
  }

  get title() {
    return this.translateService.instant(GRADE_TITLES[this._grade]);
  }

  get icon() {
    return GRADE_SVG[this._grade];
  }
}
