import { UserUI } from '@src/models';

export type Role = {
  OrganisationId: string;
  RoleId: string;
  RoleName: string;
};

export interface JwtTokenPayload {
  nameid: string;
  unique_name: string;
  HeadId: string;
  OrganisationId: string;
  role: string[];
  isFirstSignIn: string;
  Roles: Role[];
  nbf: number;
  exp: number;
  iat: number;
  iss: string;
  aud: string;
}

export enum RoleCode {
  /** Администратор организации-члена ДО */
  AdminDO = 'AdminDO',

  /** Администратор УО */
  AdminUO = 'AdminUO',

  /** Сотрудник организации-члена ДО */
  ParticipantDO = 'ParticipantDO',

  /** Сотрудник УО */
  ParticipantUO = 'ParticipantUO',

  /** Системный оператор */
  SysOp = 'SysOp',

  /** Наблюдатель */
  Spectator = 'Spectator',
}

export interface DynamicPermissionsType {
  showAllUsersPhonesAndEmails: string[];
  disableMessengerLoginPermissions: string[];
}

export type CallbackAfterCreateSession = (user?: UserUI) => Promise<unknown> | unknown;
