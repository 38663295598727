import { ChangeDetectionStrategy, Component, EventEmitter, Inject, Injector, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TuiDialogService } from '@taiga-ui/core';
import { PolymorpheusComponent } from '@tinkoff/ng-polymorpheus';
import { ChatModel } from '@src/models';
import { DialogConfirmComponent } from '@src/app/shared/dialogs';
import { ObjectId } from '@src/types/id';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'telegram-forward',
  templateUrl: './forward.component.html',
  styleUrls: ['./forward.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ForwardComponent {
  @Output() chatSelected: EventEmitter<{ selectedChat: ChatModel; message?: string }>;
  @Output() canceled: EventEmitter<void>;

  messageText: string = '';
  selectedChatId: ObjectId;

  chatsFilter = {
    onlyUnionsChats: true,
  };

  private destroyed$$: Subject<void> = new Subject<void>();
  private readonly confirmForwardDialog = this.dialogService.open<boolean>(
    new PolymorpheusComponent(DialogConfirmComponent, this.injector),
    {
      label: this.translateService.instant('components.forward.dialogs.forwardMessageHeader'),
      size: 's',
      closeable: false,
      dismissible: false,
    },
  );

  constructor(
    private readonly translateService: TranslateService,
    @Inject(TuiDialogService) private readonly dialogService: TuiDialogService,
    @Inject(Injector) private readonly injector: Injector,
  ) {
    this.chatSelected = new EventEmitter();
    this.canceled = new EventEmitter();
  }

  onClickReturnButton() {
    this.canceled.emit();
  }

  forwardToChat(selectedChat: ChatModel) {
    this.selectedChatId = selectedChat.id;
    this.confirmForwardDialog.pipe(takeUntil(this.destroyed$$)).subscribe({
      next: res => {
        if (res) {
          const message: string = this.messageText;
          this.chatSelected.emit({ selectedChat, message });
        } else {
          this.selectedChatId = undefined;
        }
      },
    });
  }
}
