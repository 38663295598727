export enum AssociationMenuItem {
  Info = 'label.organisationInfo',
  Users = 'label.organisationMembers',
  Channels = 'label.organisationChats',
  Committees = 'label.organisationCommittees',
  Configs = 'configs',
}

export const AssociationMenuQueryId: Record<AssociationMenuItem, string> = {
  [AssociationMenuItem.Info]: 'info',
  [AssociationMenuItem.Users]: 'users',
  [AssociationMenuItem.Channels]: 'channels',
  [AssociationMenuItem.Committees]: 'committees',
  [AssociationMenuItem.Configs]: 'configs',
} as const;
