<app-description [description]="description">
  <app-label [label]="label">
    <div class="field-wrapper">
      <div class="field-wrapper__text text_size-{{ size }}">{{ text }}</div>

      <div class="field-wrapper__control">
        <ng-content></ng-content>
      </div>
    </div>
  </app-label>
</app-description>
