/**
 * Unions API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Подписки.
 */
export interface AddSubscriptionWithChat { 
    /**
     * Название.
     */
    name?: string;
    /**
     * Описание.
     */
    description?: string;
    /**
     * Канал или хэштэг.
     */
    codeName?: string;
    /**
     * Управляющая организация.
     */
    organisationId?: string;
    /**
     * Порядок сортировки.
     */
    sortOrder?: number;
    /**
     * по-умолчанию.
     */
    isDefault?: boolean;
    /**
     * Предварительно созданый чат chatType = 2 (Канал), который надо привязать к подписке.
     */
    chatId?: number;
    /**
     * ID типа подписки.
     */
    chatTypeId?: number;
    /**
     * ID владельца канала.
     */
    ownerId?: string;
    /**
     * ID владельца канала.
     */
    sendTo?: Array<number>;
}

