import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TuiDataListWrapperModule, TuiSelectModule } from '@taiga-ui/kit';
import { TuiTextfieldControllerModule } from '@taiga-ui/core';
import { TranslateModule } from '@ngx-translate/core';
import { IconModule } from '@src/app/modules/icon';

import { LanguageSwitcherComponent } from './components/language-switcher/language-switcher.component';
import { LanguageSwitcherMiniComponent } from './components/language-switcher-mini/language-switcher-mini.component';

@NgModule({
  declarations: [LanguageSwitcherComponent, LanguageSwitcherMiniComponent],
  imports: [
    CommonModule,
    IconModule,
    TranslateModule,
    TuiSelectModule,
    TuiDataListWrapperModule,
    TuiTextfieldControllerModule,
    FormsModule,
  ],
  exports: [LanguageSwitcherComponent, LanguageSwitcherMiniComponent],
})
export class LanguageSwitcherModule {}
