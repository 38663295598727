import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  Optional,
  Self,
  Input,
  Output,
  EventEmitter,
  ViewChild,
} from '@angular/core';
import { NgControl } from '@angular/forms';
import { TUI_IS_APPLE } from '@taiga-ui/cdk';
import { AbstractTuiControl } from '@taiga-ui/cdk';
import { maskitoGetCountryFromNumber } from '@maskito/phone';
import metadata from 'libphonenumber-js/min/metadata';
import { AUTOCOMPLETE_TYPES } from '@src/constants';
import { TuiPrimitiveTextfieldComponent } from '@taiga-ui/core';

import { formatPhone, normalizePhone } from '../../utils';
import { PHONE_MASK_OPTIONS } from '../../constants';

import { InputPhoneSize } from './types';

@Component({
  selector: 'app-input-phone',
  templateUrl: './input-phone.component.html',
  styleUrls: ['./input-phone.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputPhoneComponent extends AbstractTuiControl<string> {
  @ViewChild(TuiPrimitiveTextfieldComponent)
  private readonly textField?: TuiPrimitiveTextfieldComponent;

  @Input() size: InputPhoneSize = 'l';
  @Input() labelOutside = false;
  @Input() autofocus = false;
  @Input() phoneDisabled = false;
  /**
   * Флаг, чтобы переопределять доступ к элементу, не зависимо от состояния формы.
   */
  @Input() forceDisabled?: boolean;

  @Input() set phoneValue(value: string) {
    this.updateValue(formatPhone(value) ?? '');
  }

  @Output() phoneValueChange: EventEmitter<string> = new EventEmitter();

  readonly maskPhone = PHONE_MASK_OPTIONS;
  readonly autocomplete = AUTOCOMPLETE_TYPES.tel;

  constructor(
    @Inject(TUI_IS_APPLE) private readonly isApple: boolean,
    @Optional()
    @Self()
    @Inject(NgControl)
    control: NgControl | null,
    @Inject(ChangeDetectorRef) changeDetectorRef: ChangeDetectorRef,
  ) {
    super(control, changeDetectorRef);
  }

  ngOnInit() {
    super.ngOnInit();

    if (this.autofocus) {
      setTimeout(() => this.textField?.nativeFocusableElement?.focus());
    }
  }

  get countryIsoCode(): string {
    return maskitoGetCountryFromNumber(this.value || '', metadata) || '';
  }

  get pattern(): string {
    return this.isApple ? '+[0-9-]{1,20}' : '';
  }

  get focused(): boolean {
    return !!this.textField && this.textField.focused;
  }

  /**
   * Вычисляем флаг доступности элемента.
   * Если {forceDisabled} определен, то смотрим только на него.
   * Иначе, проверяем {disabled} или {phoneDisabled} флаги.
   */
  get isBlocked(): boolean {
    if (this.forceDisabled !== undefined) {
      return this.forceDisabled;
    }

    return this.disabled || this.phoneDisabled;
  }

  onFocused(focused: boolean): void {
    this.updateFocused(focused);
  }

  onChangeValue(formattedValue: string) {
    this.pseudoInvalid = false;

    const normalizedValue = normalizePhone(formattedValue) ?? '';

    this.phoneValueChange.emit(normalizedValue);
    this.updateValue(normalizedValue);
  }

  protected getFallbackValue(): string {
    return '';
  }
}
