/**
 * Unions API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Замаскированная почта пользователя в ответе.
 */
export interface AuthorizationDataResponse { 
    /**
     * Замаскированная почта пользователя.
     */
    masked?: string;
    /**
     * Текст ответа пользователяю.
     */
    text?: string;
    /**
     * Идентификатор службы отправки сообщения: email, telegram, phoneCall, sms.              В поле serviceId выводить значение phone (или какое соответствует звонку на телефон) для первой авторизации пользователя (эта логика остаётся прежней). В остальных случаях безусловно выводить значение mail(или какое соответствует отправке на почту)
     */
    serviceId?: string;
    /**
     * Короткий код, который требуется для аутентификации пользователя, вводящего код подтвеждения позже.
     */
    shortCode?: number;
}

