/**
 * Unions API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Добавление почты организации.
 */
export interface MailAdd { 
    /**
     * email
     */
    email?: string;
    /**
     * smtp-сервер.
     */
    smtp?: string;
    /**
     * Порт smtp-сервера.
     */
    smtpPort: number;
    /**
     * Использовать SSL для отправки почты.
     */
    useSSL: boolean;
    /**
     * Proxy-сервер.
     */
    proxyHost?: string;
    /**
     * Порт proxy-сервера.
     */
    proxyPort?: number;
    /**
     * Логин для proxy-сервера.
     */
    proxyLogin?: string;
    /**
     * Домен для proxy-сервера.
     */
    proxyDomain?: string;
    /**
     * UserName для авторизации в доменной системе.
     */
    ntlmUser?: string;
    /**
     * Домен для авторизации в доменной системе.
     */
    ntlmDomain?: string;
    /**
     * ID организации.
     */
    organisationId?: string;
    /**
     * Пароль почтового ящика
     */
    password?: string;
    /**
     * Пароль для proxy-сервера.
     */
    proxyPassword?: string;
}

