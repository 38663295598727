import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ResizableBaseComponent } from '@src/app/components';

@Component({
  selector: 'contacts-value-text',
  templateUrl: './contacts-value-text.component.html',
  styleUrls: ['./contacts-value-text.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContactsValueTextComponent extends ResizableBaseComponent {
  @Input() loading: boolean = false;
}
