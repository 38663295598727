import { Pipe, PipeTransform } from '@angular/core';
import { CommitteeUI } from '@src/models';

@Pipe({
  name: 'getCommitteePath',
})
export class GetCommitteePathPipe implements PipeTransform {
  transform(parentCommittees?: CommitteeUI[]): string {
    return parentCommittees?.length ? parentCommittees.map(parentCommittee => parentCommittee.name).join(' > ') : '';
  }
}
