/**
 * Unions API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { OrganisationJobTitle } from './organisationJobTitle';


/**
 * Профиль пользователя с массивом должностей в организации.
 */
export interface UserProfileWithJobTitlesFull { 
    /**
     * Должность.
     */
    jobTitleDescription?: string;
    /**
     * Идентификатор пользователя.
     */
    id?: string;
    /**
     * Идентификатор.
     */
    telegramId?: number;
    /**
     * Имя пользователя.
     */
    firstName?: string;
    /**
     * Отчество пользователя.
     */
    middleName?: string;
    /**
     * Фамилия пользователя.
     */
    lastName?: string;
    /**
     * Номер телефона.
     */
    phone?: string;
    /**
     * Статус пользователя.
     */
    status?: number;
    /**
     * Идентификатор фотографии.
     */
    photoId?: string;
    /**
     * Email.
     */
    email?: string;
    /**
     * Дата рождения.
     */
    birthDate?: string;
    /**
     * Присылать оповещение о новом мероприятии/опросе на почту.
     */
    notifyByEmail?: boolean;
    /**
     * потребности.
     */
    needs?: string;
    /**
     * возможности.
     */
    resources?: string;
    /**
     * хобби.
     */
    hobbies?: string;
    /**
     * взнос оплачен.
     */
    isFeePaid?: boolean;
    /**
     * ID куратора.
     */
    curatorId?: string;
    /**
     * полномочия действительны до.
     */
    authorityValidTill?: string;
    /**
     * письмо с реквизитами.
     */
    paymentDetailsId?: string;
    phoneAdditional?: string;
    whatsappNumber?: string;
    /**
     * Наименование организации и роли.
     */
    organisationJobTitles?: Array<OrganisationJobTitle>;
    /**
     * Идентификатор одной (из?) организации пользователя. Костыль, от которго придется избавляться в будущем (#7524).
     */
    organisationId?: string;
    organisationName?: string;
}

