import { Component, ChangeDetectionStrategy, Input, TemplateRef } from '@angular/core';
import { UserUI } from '@src/models';
import { getImageSrc } from '@src/utils';

@Component({
  selector: 'render-user-row',
  templateUrl: './render-user-row.component.html',
  styleUrls: ['./render-user-row.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RenderUserRowComponent {
  @Input() user: UserUI = {};

  /** Шаблон для отображения статуса */
  @Input() statusContent?: TemplateRef<UserUI>;

  getAvatarUrl(): string {
    return getImageSrc(this.user.photoId);
  }
}
