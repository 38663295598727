import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { DocumentService } from '@src/core/services';

@Component({
  selector: 'app-term-of-the-privacy',
  templateUrl: './term-of-the-privacy.component.html',
  styleUrls: ['./term-of-the-privacy.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TermOfThePrivacyComponent {
  @Input() associationId?: string;

  constructor(private documentService: DocumentService) {}

  onClickPolicyLink() {
    if (!this.associationId) return;

    this.documentService.downloadAssociationPolicy(this.associationId);
  }
}
