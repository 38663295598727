<ng-container *ngIf="!(loadingChats$ | async); else tmpLoading">
  <ng-container [ngTemplateOutlet]="tmpContent"></ng-container>
</ng-container>

<ng-template #tmpLoading>
  <ng-container *ngIf="!contentVisible; else tmpLoadingWithContent">
    <div class="wait-telegram-chats">
      <tui-loader size="l" [showLoader]="true" [inheritColor]="true" [overlay]="true"></tui-loader>
    </div>
  </ng-container>

  <ng-template #tmpLoadingWithContent>
    <tui-loader [inheritColor]="true" [overlay]="true" [showLoader]="true">
      <ng-container [ngTemplateOutlet]="tmpContent"></ng-container>
    </tui-loader>
  </ng-template>
</ng-template>

<ng-template #tmpContent>
  <ng-content></ng-content>
</ng-template>
