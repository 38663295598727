/**
 * Утилита, которая проверяет разрешение файла.
 *
 * @param fileName Название файла
 */
export const validateFileExtension = (fileName?: string): boolean => {
  return !!(
    fileName?.includes('.jpg') ||
    fileName?.includes('.jpeg') ||
    fileName?.includes('.png') ||
    fileName?.includes('.gif')
  );
};
