import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'stringToDate',
})
export class StringToDatePipe implements PipeTransform {
  transform(stringDate: string | Date | undefined): Date | undefined {
    if (!stringDate) return;

    return stringDate ? new Date(stringDate) : undefined;
  }
}
