import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TuiScrollbarModule } from '@taiga-ui/core';
import { BirthdaysModule } from '@src/app/modules/birthdays';
import { UserInfoModule } from '@src/app/modules/user-info';
import { SharedModule } from '@src/app/shared';
import { ComponentsModule } from '@src/app/components';

import { BirthdaysPageComponent } from './birthdays-page.component';

@NgModule({
  declarations: [BirthdaysPageComponent],
  imports: [CommonModule, ComponentsModule, BirthdaysModule, UserInfoModule, TuiScrollbarModule, SharedModule],
  exports: [BirthdaysPageComponent],
})
export class BirthdaysPageModule {}
