/**
 * Unions API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type TypeCharacteristicFieldTypeEnum = 0 | 1 | 2 | 3 | 4;

export const TypeCharacteristicFieldTypeEnum = {
    NUMBER_0: 0 as TypeCharacteristicFieldTypeEnum,
    NUMBER_1: 1 as TypeCharacteristicFieldTypeEnum,
    NUMBER_2: 2 as TypeCharacteristicFieldTypeEnum,
    NUMBER_3: 3 as TypeCharacteristicFieldTypeEnum,
    NUMBER_4: 4 as TypeCharacteristicFieldTypeEnum
};

