import { Injectable } from '@angular/core';
import { ApiMethod } from '@airgram/web';
import { AlertService } from '@src/core/services';
import { NumeralPluralsPipe } from '@src/app/shared/pipes';
import { TranslateService } from '@ngx-translate/core';

import { TELEGRAM_ERRORS } from '../constants';

@Injectable({
  providedIn: 'root',
})
export class TelegramErrorHandlerService {
  constructor(
    private readonly alertService: AlertService,
    private readonly numeralPluralsPipe: NumeralPluralsPipe,
    private readonly translateService: TranslateService,
  ) {
    this.handle = this.handle.bind(this);
  }

  handle(method: ApiMethod, code: number, message: string) {
    let typeMessage: 'error' | 'info' | 'success' | 'warning' = 'error';
    let customErrorMessage: string | undefined;
    let autoClose = 30 * 1000;

    if (TELEGRAM_ERRORS[message]) {
      customErrorMessage = this.translateService.instant(TELEGRAM_ERRORS[message]);
    }

    if (message === 'INVITE_HASH_EXPIRED' || message === 'USER_ALREADY_PARTICIPANT') {
      autoClose = 10 * 1000;
    }

    // TODO: Returns a 404 error if there is no sponsored message in the chat - https://airgram.netlify.app/api-reference/api-methods/getChatSponsoredMessage
    if ((method === 'getChatSponsoredMessage' || method === 'getMessage') && code === 404) {
      return;
    }

    if (method === 'sendMessage' && code === 400) {
      customErrorMessage = this.translateService.instant('common.alerts.errors.fileSizeMax');
    }

    // TODO: Returns an error with code 502 if the bot fails to answer the query before the query timeout expires - https://airgram.netlify.app/api-reference/api-methods/getCallbackQueryAnswer
    if (method === 'getCallbackQueryAnswer' && code === 502) {
      return;
    }

    if (method === 'getAuthorizationState' || method === 'logOut' || code === 401) {
      return;
    }

    if (
      method === 'addChatMembers' &&
      (message === 'USER_PRIVACY_RESTRICTED' || message === 'USER_NOT_MUTUAL_CONTACT' || message === 'User not found')
    ) {
      return;
    }

    if (method === 'downloadFile' && message === 'Canceled by another downloadFile request') {
      return;
    }

    if (method === 'addChatMember') {
      switch (message) {
        case 'USER_PRIVACY_RESTRICTED': // code = 403
        case 'User not found': // code = 400
          typeMessage = 'info';
          customErrorMessage = this.translateService.instant(
            'components.telegramErrorHandler.alerts.errors.userPrivacyRestricted',
          );
          break;

        case 'USER_NOT_MUTUAL_CONTACT': // code = 400
          customErrorMessage = this.translateService.instant(
            'components.telegramErrorHandler.alerts.errors.userNotMutualContact',
          );
          break;

        default:
          break;
      }
    }

    if (code === 500 && message === 'Request aborted') {
      switch (method) {
        case 'getMe':
          customErrorMessage = this.translateService.instant(
            'components.telegramErrorHandler.alerts.errors.requesAbortedGetMe',
          );
          break;

        case 'getChats':
          customErrorMessage = this.translateService.instant(
            'components.telegramErrorHandler.alerts.errors.requesAbortedGetChats',
          );
          break;

        case 'downloadFile':
          customErrorMessage = this.translateService.instant(
            'components.telegramErrorHandler.alerts.errors.requesAbortedDownloadFile',
          );
          break;

        default:
          customErrorMessage = `${method}: ${this.translateService.instant(
            'components.telegramErrorHandler.alerts.errors.requesAbortedDefault',
          )}`;
          break;
      }
    }

    if (code === 429) {
      const splittedMessage = message.split(' ');
      const timestamp = Number(splittedMessage.pop());
      const newMessage = splittedMessage.join(' ');

      const seconds = timestamp % 60;
      const minutes = Math.floor((timestamp / 60) % 60);
      const hours = Math.floor(timestamp / 3600);

      const secondsStr = seconds
        ? this.numeralPluralsPipe.transform(seconds, [
            this.translateService.instant('components.telegramErrorHandler.labels.secondOne'),
            this.translateService.instant('components.telegramErrorHandler.labels.secondFew'),
            this.translateService.instant('components.telegramErrorHandler.labels.secondMany'),
          ])
        : '';
      const minutesStr =
        minutes || hours
          ? this.numeralPluralsPipe.transform(minutes, [
              this.translateService.instant('components.telegramErrorHandler.labels.minuteOne'),
              this.translateService.instant('components.telegramErrorHandler.labels.minuteFew'),
              this.translateService.instant('components.telegramErrorHandler.labels.minuteMany'),
            ])
          : '';
      const hoursStr = hours
        ? this.numeralPluralsPipe.transform(hours, [
            this.translateService.instant('components.telegramErrorHandler.labels.hourOne'),
            this.translateService.instant('components.telegramErrorHandler.labels.hourFew'),
            this.translateService.instant('components.telegramErrorHandler.labels.hourMany'),
          ])
        : '';

      if (newMessage === 'Too Many Requests: retry after') {
        customErrorMessage = `${method}: ${this.translateService.instant(
          'components.telegramErrorHandler.alerts.errors.tooManyRequests',
        )} ${hoursStr} ${minutesStr} ${secondsStr}`;
      }
    }

    if (method === 'setChatMemberStatus' && code === 400 && message === `Can't remove chat owner`) {
      customErrorMessage = this.translateService.instant(
        'components.telegramErrorHandler.alerts.errors.setChatMemberStatus',
      );
    }

    if (
      method === 'checkAuthenticationCode' &&
      code === 400 &&
      message === 'Call to checkAuthenticationCode unexpected'
    ) {
      customErrorMessage = this.translateService.instant(
        'components.telegramErrorHandler.alerts.errors.checkAuthenticationCode',
      );
    }

    if (method === 'createPrivateChat' && code === 400 && message === 'Chat info not found') {
      customErrorMessage = this.translateService.instant(
        'components.telegramErrorHandler.alerts.errors.createPrivateChat',
      );
    }

    if (method === 'createChatFilter' && code === 400) {
      customErrorMessage = this.translateService.instant(
        'components.telegramErrorHandler.alerts.errors.createChatFilter',
      );
    }

    if (method === 'editChatFilter' && code === 400) {
      customErrorMessage = this.translateService.instant(
        'components.telegramErrorHandler.alerts.errors.editChatFilter',
      );
    }

    if (message === 'Chat not found') {
      customErrorMessage = this.translateService.instant('components.telegramErrorHandler.alerts.errors.chatNotFound');
    }

    if (message === 'Phone number must be non-empty') {
      customErrorMessage = this.translateService.instant(
        'components.telegramErrorHandler.alerts.errors.enterPhoneNumber',
      );
    }

    if (message === 'Another authorization query has started') {
      customErrorMessage = this.translateService.instant(
        'components.telegramErrorHandler.alerts.errors.anotherAuthorizationStarted',
      );
    }

    switch (typeMessage) {
      case 'error':
        this.alertService.error(customErrorMessage ?? `${method} - ${code}: ${message}`, {
          label: this.translateService.instant('common.alerts.errors.error1'),
          autoClose: autoClose,
        });
        break;

      case 'info':
        this.alertService.info(customErrorMessage ?? `${method} - ${code}: ${message}`, {
          autoClose: autoClose,
        });
        break;

      // case 'warning':
      //   this.alertService.warning(customErrorMessage ?? `${method} - ${code}: ${message}`, {
      //     autoClose: 30 * 1000,
      //   });
      //   break;

      // case 'success':
      //   this.alertService.success(customErrorMessage ?? `${method} - ${code}: ${message}`, {
      //     autoClose: 30 * 1000,
      //   });
      //   break;

      default:
        break;
    }
  }
}
