import { Injectable } from '@angular/core';
import { ChatTypeUnion } from '@airgram/core/types/outputs';
import { BasicGroupFullInfoUnion, ChatMemberUnion, SupergroupFullInfoUnion } from '@airgram/web';
import { ChatMemberModel, UserUI } from 'src/models';
import { UserService } from 'src/core/services';
import { TelegramMessengerService } from '@src/app/modules/telegram';
import { getImageSrc } from '@src/utils';
import { lastValueFrom } from 'rxjs';

/**
 * TODO: Это чистая копи-паста из разных места приложения.
 * Надо завести задачу на рефакторинг - поиск похожих мест и декомпозиции в отдельные сервисы.
 */
@Injectable({
  providedIn: 'root',
})
export class GetChatMembersService {
  constructor(private telegramMessengerService: TelegramMessengerService, private userService: UserService) {}

  async getChatMembers(chatId: number): Promise<ChatMemberModel[]> {
    return new Promise<ChatMemberModel[]>(resolve => {
      this.telegramMessengerService.api.getChat(chatId).then(chat => {
        if (chat.type._ === 'chatTypeBasicGroup') {
          this.telegramMessengerService.api.getBasicGroupFullInfo(chat.type.basicGroupId).then(group => {
            resolve(this.initMembers(chat.type, group));
          });
        }

        if (chat.type._ === 'chatTypeSupergroup') {
          this.telegramMessengerService.api.getSupergroupFullInfo(chat.type.supergroupId).then(superGroup => {
            resolve(this.initMembers(chat.type, superGroup));
          });
        }
      });
    });
  }

  /**
   * Инициализация списка пользователей чата
   * @param type тип чата
   * @param group данные группы
   */
  private async initMembers(
    type: ChatTypeUnion,
    group: BasicGroupFullInfoUnion | SupergroupFullInfoUnion,
  ): Promise<ChatMemberModel[]> {
    let members: ChatMemberUnion[] = [];
    if (group._ === 'basicGroupFullInfo') {
      members = group.members;
    }

    if (type._ === 'chatTypeSupergroup' && group._ === 'supergroupFullInfo' && group.canGetMembers) {
      const chatMember = await this.telegramMessengerService.api.getSupergroupMembers(type.supergroupId);
      members = chatMember.members;
    }

    if (members.length > 0) {
      const membersTelegramIds = members.map(member => {
        if (member.memberId._ === 'messageSenderUser') {
          return member.memberId.userId;
        }
        if (member.memberId._ === 'messageSenderChat') {
          return member.memberId.chatId;
        }
        return;
      }) as number[];

      const users: UserUI[] = await lastValueFrom(this.userService.getUsersData(membersTelegramIds));

      const unionsUsers: ChatMemberModel[] = [];
      const telegramUsers: ChatMemberModel[] = [];

      await Promise.all(
        membersTelegramIds.map(async memberId => {
          const telegramUser: ChatMemberModel = await this.telegramMessengerService.api.getUser(memberId);
          const unionsUser = users.find(unionsUser => unionsUser.telegramId === memberId);

          if (unionsUser) {
            telegramUser.tgFirstName = telegramUser.firstName;
            telegramUser.tgLastName = telegramUser.lastName;

            telegramUser.lastName = unionsUser.lastName ?? '';
            telegramUser.firstName = unionsUser.firstName ?? '';
            telegramUser.middleName = unionsUser.middleName ?? '';

            if (unionsUser.photoId) {
              telegramUser.photoPath = getImageSrc(unionsUser.photoId);
            }
            telegramUser.organisationJobTitles = unionsUser.organisationJobTitles;

            unionsUsers.push(telegramUser);
          } else {
            telegramUsers.push(telegramUser);
          }
        }),
      );

      const userData = await this.userService.getAuthorizedUser();

      return this.sortingMembers(unionsUsers)
        .concat(this.sortingMembers(telegramUsers))
        .filter(member => {
          return member.id !== userData?.telegramId;
        });
    }

    return [];
  }

  /**
   * Сортировка пользователей для отображения в списке
   * @param chatMembers список пользователей чата
   */
  private sortingMembers(chatMembers: ChatMemberModel[]): ChatMemberModel[] {
    chatMembers.sort((member1, member2) => {
      const memberFullName1 = (
        (member1.lastName ?? '') +
        (member1.firstName ?? '') +
        (member1.middleName ?? '')
      ).toLowerCase();
      const memberFullName2 = (
        (member2.lastName ?? '') +
        (member2.firstName ?? '') +
        (member2.middleName ?? '')
      ).toLowerCase();

      if (!memberFullName1) return 1;
      if (!memberFullName2) return -1;

      if (memberFullName1 < memberFullName2) return -1;
      if (memberFullName1 > memberFullName2) return 1;
      return 0;
    });

    return chatMembers;
  }
}
