<nav class="navigation navigation_{{ mode }}">
  <ng-container *ngFor="let item of items">
    <ng-container *ngxPermissionsOnly="item.permissions || []">
      <a
        class="navigation-item"
        [routerLink]="item.link"
        routerLinkActive="navigation-item_active"
        tuiHintDescribe="navigation"
        tuiHintDirection="right"
        [tuiHint]="isOpen ? '' : (item.title | translate)"
        [tuiHintShowDelay]="300"
        [tuiHintHideDelay]="0"
        (click)="item.action && item.action()"
      >
        <app-icon
          *ngIf="item.icon"
          class="navigation-item__icon"
          [src]="item.icon"
          [size]="mode === 'mobile' ? '18px' : '24px'"
        ></app-icon>

        <div *ngIf="isOpen">
          <div class="navigation-item__title">
            {{ item.title | translate }}
          </div>
          <div *ngIf="descriptionVisible" class="navigation-item__description">
            {{ item.description || '' | translate }}
          </div>
        </div>
      </a>
    </ng-container>
  </ng-container>
</nav>
