<div class="discount-group-row">
  <div class="discount-group-row__content">
    <lp-discount-tag
      [productDiscountType]="discountGroup?.discountType"
      [productDiscount]="discountGroup?.discount"
      [variant]="'prefix'"
    ></lp-discount-tag>

    <div class="title">
      {{ discountGroup?.groupName }}
    </div>
  </div>

  <div class="discount-group-row__button">
    <button tuiButton [size]="tuiElementMediumSize" (click)="getDiscount.emit(discountGroup?.id)">
      {{ 'components.discountGroupRow.buttons.get' | translate }}
    </button>
  </div>
</div>
