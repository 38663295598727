<mat-list-option class="list-item list-item-chats" [value]="data?.id" [selected]="!loading && selectedId === data?.id">
  <div
    matListIcon
    class="list-item__image list-item__image_large"
    [class.tui-skeleton]="loading"
    [class.tui-skeleton_rounded]="loading"
  >
    <tui-badged-content size="m" [colorBottom]="data?.userOnline ? 'var(--tui-success-fill)' : ''" [rounded]="true">
      <tui-avatar
        size="m"
        [autoColor]="!data?.userPhotoPath"
        [rounded]="true"
        [avatarUrl]="(data && data.userPhotoPath ? data.userPhotoPath : '') | safe: 'url'"
        [text]="data ? data.title : ''"
      ></tui-avatar>
    </tui-badged-content>
  </div>
  <div class="list-item__text-box">
    <div class="list-item__text-line">
      <div class="list-item-chats__heading" [class.tui-skeleton]="loading" [class.tui-skeleton_text]="loading">
        <mat-icon
          *ngIf="data && data.type && ['chatTypeBasicGroup', 'chatTypeSupergroup'].indexOf(data.type._) > -1"
          class="list-item-chats__title-icon list-item-chats__title-icon_type"
          [title]="
            data.type._ === 'chatTypeSupergroup' && data.type.isChannel
              ? ('common.labels.channel' | translate)
              : ('common.labels.group' | translate)
          "
        >
          {{ data.type._ === 'chatTypeSupergroup' && data.type.isChannel ? 'campaign' : 'groups' }}
        </mat-icon>
        <mat-icon
          *ngIf="data && data.type?._ === 'chatTypePrivate' && data.userType && data.userType._ === 'userTypeBot'"
          class="list-item-chats__title-icon list-item-chats__title-icon_volume"
          [title]="'components.listItemChats.labels.bot' | translate"
        >
          android
        </mat-icon>
        <h3 class="list-item__title" [title]="data?.title">
          {{ data?.title }}
        </h3>
        <mat-icon
          *ngIf="data?.notificationSettings?.muteFor"
          class="list-item-chats__title-icon list-item-chats__title-icon_volume"
          [title]="'components.listItemChats.labels.volumeOff' | translate"
        >
          volume_off
        </mat-icon>
        <tui-badge
          *ngIf="data?.unreadCount"
          class="list-item-chats__unread-badge"
          size="s"
          [class.title__unread_muted]="data?.notificationSettings?.muteFor"
          [class.title__unread_unmuted]="!data?.notificationSettings?.muteFor"
          [value]="data && data.unreadCount ? data.unreadCount : ''"
        ></tui-badge>
      </div>
    </div>
    <ng-container *ngIf="data?.organisationJobTitles?.length">
      <p
        *ngFor="let organisationJobTitle of data?.organisationJobTitles | jobTitles"
        class="list-item__text"
        [class.tui-skeleton]="loading"
        [class.tui-skeleton_text]="loading"
        [title]="organisationJobTitle | orgJobTitleDisplayableName"
      >
        {{ organisationJobTitle | orgJobTitleDisplayableName }}
      </p>
    </ng-container>
    <div class="list-item__text-line">
      <div class="list-item-chats__message" [class.tui-skeleton]="loading" [class.tui-skeleton_text]="loading">
        <div class="list-item__text list-item__text_medium list-item__text_grey">
          {{
            data?.actionStatus
              ? (data?.actionStatus | actionStatus: data?.type:data?.actionSenderId | async)
              : (data?.lastMessage | messageNote | async)
          }}
        </div>
        <div class="list-item__text list-item__text_medium list-item__text_grey">
          {{ data?.lastMessage?.date | timestampToDate: 'string' }}
        </div>
      </div>
    </div>
  </div>
</mat-list-option>
