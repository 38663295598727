import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TuiLoaderModule } from '@taiga-ui/core';

import { WaitTelegramChatsComponent } from './wait-telegram-chats.component';

@NgModule({
  declarations: [WaitTelegramChatsComponent],
  imports: [CommonModule, TuiLoaderModule],
  exports: [WaitTelegramChatsComponent],
})
export class WaitTelegramChatsModule {}
