import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { validateFileExtension } from '@src/app/modules/photo';
import { isValidUrl } from '@src/utils';

import { RUTUBE_SIGN, VK_SIGN, VK_VIDEO_SIGN, YOUTUBE_SHORT_SIGN, YOUTUBE_SIGN } from './constants';

@Component({
  selector: 'app-parse-multimedia-links',
  templateUrl: './parse-multimedia-links.component.html',
  styleUrls: ['./parse-multimedia-links.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ParseMultimediaLinksComponent implements OnChanges {
  @Input() value?: string | null;

  videoSource?: string;
  photoSource?: string;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.value && this.value) {
      this.photoSource = validateFileExtension(this.value) ? this.value : undefined;

      if (!this.photoSource) {
        this.videoSource = this.parseVideoLink(this.value);
      }
    }
  }

  private parseVideoLink(value: string) {
    const validUrl = isValidUrl(value.trim());
    if (!validUrl) return;

    const url = new URL(validUrl);
    const hostName = url.hostname;
    const pathname = url.pathname;

    if (hostName.includes(YOUTUBE_SHORT_SIGN)) {
      const videoId = pathname.slice(1);

      if (videoId) {
        return `https://www.youtube.com/embed/${videoId}`;
      }
    }

    if (hostName.includes(YOUTUBE_SIGN)) {
      if (pathname.startsWith('/embed')) {
        return value;
      }

      const videoId = url.searchParams.get('v');

      if (videoId) {
        return `https://www.youtube.com/embed/${videoId}`;
      }
    }

    if (value.includes(VK_SIGN) && value.includes(VK_VIDEO_SIGN)) {
      if (pathname.startsWith('/video_ext.php')) {
        return value;
      }

      let videoId: string[] | undefined = pathname.split('/')[1].split('_');

      if (!videoId[0] || !videoId[1]) {
        videoId = url.searchParams.get('z')?.split('_');
      }

      const oid = videoId?.[0].replace(VK_VIDEO_SIGN, '');
      const id = videoId?.[1];

      if (oid && id) {
        return `https://vk.com/video_ext.php?oid=${oid}&id=${id}`;
      }
    }

    if (value.includes(RUTUBE_SIGN)) {
      if (pathname.startsWith('/play/embed')) {
        return value;
      }

      const videoId = pathname.split('/')[2];

      if (videoId) {
        return `https://rutube.ru/play/embed/${videoId}`;
      }
    }

    return;
  }
}
