import { Component, ChangeDetectionStrategy, ChangeDetectorRef, Inject } from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';
import { Clipboard } from '@angular/cdk/clipboard';
import { takeUntil } from 'rxjs';
import { TuiDialogContext } from '@taiga-ui/core';
import { POLYMORPHEUS_CONTEXT } from '@tinkoff/ng-polymorpheus';
import { TranslateService } from '@ngx-translate/core';
import { LoyaltyCodesForProductView } from '@src/api';
import { AlertService, BreakpointObserverHelperService, OrganisationService } from '@src/core/services';
import { ResizableBaseComponent } from '@src/app/components/resizable-base-component';

import { LoyaltyProgramApiService } from '../../../services';

@Component({
  selector: 'lp-unique-code-dialog',
  templateUrl: './unique-code-dialog.component.html',
  styleUrls: ['./unique-code-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UniqueCodeDialogComponent extends ResizableBaseComponent {
  /** Признак использования unionId в запросах */
  private useUnionId = false;

  constructor(
    readonly cdr: ChangeDetectorRef,
    readonly breakpointObserver: BreakpointObserver,
    readonly breakpointObserverHelperService: BreakpointObserverHelperService,
    private readonly clipboard: Clipboard,
    private readonly alertService: AlertService,
    @Inject(POLYMORPHEUS_CONTEXT)
    private readonly context: TuiDialogContext<void, LoyaltyCodesForProductView>,
    private readonly translateService: TranslateService,
    private readonly loyaltyProgramApiService: LoyaltyProgramApiService,
    private readonly organisationService: OrganisationService,
  ) {
    super(cdr, breakpointObserver, breakpointObserverHelperService);
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.organisationService.currentParentOrganisation$.pipe(takeUntil(this.destroyed$$)).subscribe(() => {
      this.setUseUnionId();
    });
  }

  get code() {
    return this.context.data;
  }

  /** ID ассоциации внешней базы */
  get externalUnionId(): string | undefined {
    return this.useUnionId ? this.code?.parentOrganisationId : undefined;
  }

  /** Заполняем признак использования unionId */
  setUseUnionId() {
    this.useUnionId = this.code?.isAdvertisment
      ? this.organisationService.currentParentOrganisation$.value?.id !== this.code.parentOrganisationId
      : false;
    this.cdr.markForCheck();
  }

  copyPromoCode(): void {
    if (this.clipboard.copy(this.code?.code ?? '')) {
      this.alertService.success(
        this.translateService.instant('components.loyaltyDiscountDialog.alerts.successes.copyCode'),
      );
    }
  }

  saveRedirectLink(link: string) {
    if (!this.code?.productId) return;

    this.loyaltyProgramApiService.saveRedirectLink(this.code?.productId, link, this.externalUnionId);
  }
}
