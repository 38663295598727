/**
 * Unions API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { OrganisationProfileExt } from './organisationProfileExt';
import { ContactForOrganisationEdit } from './contactForOrganisationEdit';


/**
 * Редактировать организацию
 */
export interface EditOrganisation { 
    /**
     * Кратное наименование организации.
     */
    shortName?: string;
    /**
     * Полное наименование организации.
     */
    fullName?: string;
    /**
     * Идентификатор города.
     */
    cityId?: string;
    /**
     * Идентификатор родительской организации.
     */
    parentOrganisation?: string;
    /**
     * Кол-во сотрудников.
     */
    numberOfEmployees?: number;
    /**
     * Наименование группы.
     */
    clusterName?: string;
    /**
     * ID фотографии.
     */
    photoId?: string;
    /**
     * Кол-во организаций объединения.
     */
    numberOfOrganisations?: number;
    /**
     * Дата оплаты членских взносов.
     */
    membershipPaidTill?: string;
    /**
     * Идентификатор фотографии.
     */
    photoIds?: Array<string>;
    /**
     * Кодовое имя для брендирования приложений ассоциации.
     */
    codeName?: string;
    /**
     * Идентификатор.
     */
    id?: string;
    /**
     * Отраслевая принадлежность (таблица BusinessForOrganisation).
     */
    businessTypeIDs?: Array<string>;
    /**
     * Регионы деятельности (справочник RegionsOKATO).
     */
    regionOKATOIDs?: Array<string>;
    /**
     * Контакты.
     */
    contacts?: Array<ContactForOrganisationEdit>;
    extended?: OrganisationProfileExt;
}

