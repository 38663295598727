import { Component, ChangeDetectionStrategy, OnInit, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { SessionService } from '@src/app/modules/auth';

// TODO: refactoring
interface AppBrand {
  codeName?: string;
  appName: string;
  policyStartDate: string;
}

const DEFAULT_APP_BRAND: AppBrand = {
  appName: 'Unions',
  policyStartDate: '2022-07-02',
};

const APP_BRANDS: AppBrand[] = [
  {
    codeName: 'deloros',
    appName: 'Deloros',
    policyStartDate: '2023-02-25',
  },
  {
    codeName: 'the8',
    appName: 'The 8',
    policyStartDate: '2023-12-12',
  },
  {
    codeName: 'star',
    appName: 'СтАР',
    policyStartDate: '2024-01-17',
  },
  {
    codeName: 'club-np',
    appName: 'Club NP',
    policyStartDate: '2024-09-16',
  },
  {
    codeName: 'lk-alumni',
    appName: 'Содружество выпускников МГЮА',
    policyStartDate: '2024-09-27',
  },
  {
    codeName: 'wesmir',
    appName: 'Весь Мир',
    policyStartDate: '2024-11-20',
  },
];

@Component({
  selector: 'app-privacy-page',
  templateUrl: './privacy-page.component.html',
  styleUrls: ['./privacy-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PrivacyPageComponent implements OnInit, OnDestroy {
  selectLanguage: string = 'EN';
  readonly languages: string[] = [
    // 'RU',
    'EN',
  ];

  appOptions?: AppBrand;

  private destroyed$$: Subject<void> = new Subject<void>();

  constructor(private sessionService: SessionService) {}

  ngOnInit(): void {
    let appBrand: AppBrand | undefined;
    const codeName = this.sessionService.brandCodeName;
    if (codeName) {
      appBrand = APP_BRANDS.find(brand => brand.codeName === codeName);
    }
    this.appOptions = appBrand ?? DEFAULT_APP_BRAND;
  }

  ngOnDestroy(): void {
    this.destroyed$$.next();
    this.destroyed$$.complete();
  }
}
