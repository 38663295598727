import { Pipe, PipeTransform } from '@angular/core';
import { ChatMemberModel, UserUI } from 'src/models';

@Pipe({
  name: 'userDisplayableName',
})
export class UserDisplayableNamePipe implements PipeTransform {
  transform(user?: ChatMemberModel | UserUI | null, type: 'full' | 'short' = 'full'): string {
    if (!user) {
      return '';
    }

    const { lastName, firstName, middleName } = user;
    if (type === 'short') {
      return [lastName, firstName].join(' ');
    }

    return [lastName, firstName, middleName].join(' ');
  }
}
