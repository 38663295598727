<ng-container *ngIf="!!value">
  <app-label [label]="label">
    <tui-primitive-textfield
      #parentActiveZone="tuiActiveZone"
      [invalid]="computedInvalid"
      [focusable]="focusable"
      [disabled]="disabled"
      [readOnly]="readOnly"
      [value]="value.title!"
      [tuiTextfieldIcon]="showVisibilityOption ? iconContent : null"
      [tuiTextfieldSize]="'m'"
      (valueChange)="onValueChange($event)"
      (tuiActiveZoneChange)="activeZoneChange($event)"
      [tuiTextfieldLabelOutside]="true"
      (focusedChange)="onFocused($event)"
      [pseudoHover]="false"
    >
      <ng-content></ng-content>
      <input tuiTextfield type="text" />
    </tui-primitive-textfield>

    <div *ngIf="showVisibilityOption" class="visibility-hint">{{ visibility | translate }}</div>

    <ng-template #iconContent>
      <tui-hosted-dropdown [tuiDropdownAlign]="'left'" [content]="showFor" [(open)]="open">
        <app-icon class="icon" [src]="'tuiIconEyeLarge'" size="16px"></app-icon>
      </tui-hosted-dropdown>
    </ng-template>

    <ng-template #showFor>
      <tui-data-list [tuiActiveZoneParent]="parentActiveZone">
        <tui-opt-group [label]="'components.customName.constants.visibilityOptions.title' | translate">
          <button
            *ngFor="let visibility of visibilities"
            tuiOption
            class="visibility-item"
            [value]="visibility.value"
            [size]="'s'"
            (click)="changeVisibility(visibility.value)"
          >
            {{ visibility.name | translate }}
            <app-icon
              *ngIf="visibility.value === value.visibilityOption"
              class="visibility-item__icon"
              [src]="'tuiIconCheck'"
              size="16px"
            ></app-icon>
          </button>
        </tui-opt-group>
      </tui-data-list>
    </ng-template>
  </app-label>
</ng-container>
