import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  OnDestroy,
  ChangeDetectorRef,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { MessageContentUnion } from '@airgram/core/types/outputs';
import { MessageSticker } from '@airgram/core/types/outputs/MessageContent';
import { FilePart } from '@airgram/web';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FileReaderService } from '@src/core/services';
import { MultimediaSource } from '@src/models';

@Component({
  selector: 'app-message-sticker',
  templateUrl: './message-sticker.component.html',
  styleUrls: ['../styles/message-content.scss', './message-sticker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MessageStickerComponent implements OnInit, OnDestroy {
  @Input() set message(mess: MessageContentUnion | undefined) {
    this.messageSticker = mess as MessageSticker;
  }

  @ViewChild('canvasSticker') canvasSticker?: ElementRef<HTMLCanvasElement>;

  messageSticker?: MessageSticker;
  messageStickerSource?: MultimediaSource;

  private destroyed$$: Subject<void>;

  constructor(private cdr: ChangeDetectorRef, private fileReaderService: FileReaderService) {
    this.destroyed$$ = new Subject<void>();
  }

  ngOnInit(): void {
    this.initSticker();
  }

  ngOnDestroy(): void {
    this.destroyed$$.next();
    this.destroyed$$.complete();
  }

  private initSticker(): void {
    if (!this.messageSticker) return;

    this.fileReaderService
      .getFilePart(this.messageSticker?.sticker?.sticker)
      .pipe(takeUntil(this.destroyed$$))
      .subscribe(filePart => this.setStickerSource(filePart));
  }

  private setStickerSource(filePart?: FilePart): void {
    this.messageStickerSource = { src: this.fileReaderService.getFileSource(filePart) };
    this.cdr.markForCheck();
  }
}
