import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { BreakpointObserver } from '@angular/cdk/layout';
import { Router } from '@angular/router';
import { Observable, takeUntil } from 'rxjs';
import { TuiDayRange } from '@taiga-ui/cdk';
import { TranslateService } from '@ngx-translate/core';
import { GetUsersBirthdaysResponseItemDto } from '@src/api';
import { TuiDayTimeTransformer } from '@src/utils';
import { Optional } from '@src/types/utils';
import { HeaderOptions, ResizableBaseComponent } from '@src/app/components';
import { BreakpointObserverHelperService } from '@src/core/services';

import { BirthdaysApiService } from '../../services';

@Component({
  selector: 'app-birthdays-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ListComponent extends ResizableBaseComponent {
  @Input() selectedId?: string;
  @Output() selectedIdChange = new EventEmitter<Optional<string>>();

  items$?: Observable<Array<GetUsersBirthdaysResponseItemDto>>;

  headerOptions: HeaderOptions = {
    title: this.translateService.instant('components.birthdaysList.labels.title'),
    buttons: {
      left: {
        label: this.translateService.instant('common.buttons.return'),
        icon: 'tuiIconChevronLeftLarge',
        visible: () => this.isMobile,
        action: () => {
          this.router.navigate(['association-users']);
        },
      },
    },
  };

  readonly periodControl = new FormControl<TuiDayRange | null>(null);

  constructor(
    readonly cdr: ChangeDetectorRef,
    readonly breakpointObserver: BreakpointObserver,
    readonly breakpointObserverHelperService: BreakpointObserverHelperService,
    private readonly birthdaysApiService: BirthdaysApiService,
    private readonly translateService: TranslateService,
    private readonly router: Router,
  ) {
    super(cdr, breakpointObserver, breakpointObserverHelperService);
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.periodControl.valueChanges.pipe(takeUntil(this.destroyed$$)).subscribe(period => {
      const dateStart = TuiDayTimeTransformer.tuiDayToDate(period?.from);
      const dateEnd = TuiDayTimeTransformer.tuiDayToDate(period?.to);
      if (dateStart && dateEnd) {
        this.items$ = this.birthdaysApiService.getUsersBirthdays(dateStart, dateEnd);
      }

      this.resetSelectedId();
    });

    const dateStart = new Date();
    const dateEnd = new Date();
    dateEnd.setDate(dateEnd.getDate() + 14);

    this.periodControl.patchValue(
      new TuiDayRange(TuiDayTimeTransformer.dateToTuiDay(dateStart), TuiDayTimeTransformer.dateToTuiDay(dateEnd)),
    );
  }

  onChangeSelectedId(selectedId?: string) {
    this.selectedIdChange.emit(selectedId);
  }

  resetSelectedId() {
    this.selectedId = undefined;
    this.onChangeSelectedId(this.selectedId);
  }
}
