<div class="message-document">
  <tui-loader [showLoader]="loading" [inheritColor]="true" [overlay]="true">
    <div class="message-document__message-content message-content">
      <div class="message-document__document-wrapper" [class.tui-skeleton]="!messageDocumentSource">
        <a [title]="'common.buttons.download' | translate" (click)="downloadFile()">
          <img
            class="message-document__preview"
            [alt]="'components.messageDocument.buttons.downloadFile' | translate"
            [src]="messageDocumentSource?.thumbnailSrc || 'assets/images/icon-doc.svg' | safe : 'url'"
          />
        </a>
        <div class="message-document__document-info">
          <p class="message-content__text message-content__text_medium">{{ messageDocumentSource?.fileName }}</p>
          <p class="message-content__text message-content__text_accent">
            {{ (downloadedFileSize ? downloadedFileSize + ' / ' : '') + messageDocumentSource?.fileSize }}
          </p>
        </div>
      </div>
      <ng-content></ng-content>
    </div>
  </tui-loader>
</div>
