import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';

@Component({
  templateUrl: './logout-modal.component.html',
  styleUrls: ['./logout-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LogoutModalComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
