import { Component, OnInit, ChangeDetectionStrategy, OnDestroy, Output, EventEmitter } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { BusinessTypeUI } from 'src/models';
import { AlertService, BusinessTypesService } from 'src/core/services';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-business-types',
  templateUrl: './business-types.component.html',
  styleUrls: ['./business-types.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BusinessTypesComponent implements OnInit, OnDestroy {
  @Output() save: EventEmitter<BusinessTypeUI[]> = new EventEmitter();

  allBusinessTypes$: BehaviorSubject<BusinessTypeUI[] | null> = this.businessTypesService.allBusinessTypes$;

  private destroyed$$: Subject<void> = new Subject<void>();

  constructor(
    private businessTypesService: BusinessTypesService,
    private readonly translateService: TranslateService,
    private readonly alertService: AlertService,
  ) {}

  ngOnInit(): void {
    this.businessTypesService.getAllBusinessTypes();
  }

  ngOnDestroy(): void {
    this.destroyed$$.next();
    this.destroyed$$.complete();
  }

  onSave(visibleBusinessTypes: BusinessTypeUI[]) {
    this.businessTypesService
      .saveBusinessTypes(visibleBusinessTypes)
      .pipe(takeUntil(this.destroyed$$))
      .subscribe(() => {
        this.businessTypesService.getAllBusinessTypes();
        this.businessTypesService.getBusinessTypes();
        this.alertService.success(
          this.translateService.instant('components.businessTypes.alerts.successes.saveBusinessTypes'),
        );

        this.save.emit(visibleBusinessTypes);
      });
  }
}
