import { Pipe, PipeTransform } from '@angular/core';
import { from, Observable, of, zip } from 'rxjs';
import { map } from 'rxjs/operators';
import { TelegramMessengerService } from '@src/app/modules/telegram';
import { MessageModel, SponsoredMessageUI } from '@src/models';
import { UserService } from '@src/core/services';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'messageNote',
})
export class MessageNotePipe implements PipeTransform {
  constructor(
    private userService: UserService,
    private messengerService: TelegramMessengerService,
    private readonly translateService: TranslateService,
  ) {}

  private getWhoFullName = (whoId?: number) => {
    return !whoId
      ? of(this.translateService.instant('common.labels.user'))
      : whoId > 0
      ? from(this.userService.getUserFullName(whoId))
      : from(this.messengerService.api.getChatTitle(whoId));
  };

  transform(message?: MessageModel | SponsoredMessageUI): Observable<string> {
    if (!message) return of('');

    const content = message.content;
    const senderId = message.senderId;
    const whoId = senderId?._ === 'messageSenderUser' ? senderId.userId : senderId?.chatId;
    let usersFullName$: Observable<string[]>;

    switch (content._) {
      case 'messageAnimation':
        return of('GIF');
      case 'messageAudio':
        return of(`${this.translateService.instant('common.labels.audio')} ${content.caption?.text || ''} `);
      case 'messageChatAddMembers':
        usersFullName$ = zip(
          ...content.memberUserIds.map(memberUserId => from(this.messengerService.getUserFullName(memberUserId))),
        );
        return usersFullName$.pipe(
          map(userNames =>
            userNames
              .map(
                userName =>
                  `${userName} ${this.translateService.instant(
                    'components.telegramMessageNote.labels.messageChatAddMembers',
                  )}`,
              )
              .join(', '),
          ),
        );
      case 'messageContactRegistered':
        return of(this.translateService.instant('components.telegramMessageNote.labels.messageContactRegistered'));
      case 'messageDocument':
        return of(
          `${this.translateService.instant('components.telegramMessageNote.labels.messageDocument')} ${
            content.caption?.text || ''
          } `,
        );
      case 'messageCall':
        switch (content.discardReason._) {
          case 'callDiscardReasonHungUp':
            return of(
              content.isVideo
                ? this.translateService.instant('components.telegramMessageNote.labels.videoCallDiscardReasonHungUp')
                : this.translateService.instant('components.telegramMessageNote.labels.voiceCallDiscardReasonHungUp'),
            );
          case 'callDiscardReasonMissed':
            return of(
              content.isVideo
                ? this.translateService.instant('components.telegramMessageNote.labels.videoCallDiscardReasonMissed')
                : this.translateService.instant('components.telegramMessageNote.labels.voiceCallDiscardReasonMissed'),
            );
          case 'callDiscardReasonDeclined':
            return of(
              content.isVideo
                ? this.translateService.instant('components.telegramMessageNote.labels.videoCallDiscardReasonDeclined')
                : this.translateService.instant('components.telegramMessageNote.labels.voiceCallDiscardReasonDeclined'),
            );
          case 'callDiscardReasonDisconnected':
            return of(
              content.isVideo
                ? this.translateService.instant(
                    'components.telegramMessageNote.labels.videoCallDiscardReasonDisconnected',
                  )
                : this.translateService.instant(
                    'components.telegramMessageNote.labels.voiceCallDiscardReasonDisconnected',
                  ),
            );
          default:
            return of(
              content.isVideo
                ? this.translateService.instant('components.telegramMessageNote.labels.videoDefault')
                : this.translateService.instant('components.telegramMessageNote.labels.voiceDefault'),
            );
        }
      case 'messagePhoto':
        return of(
          `${this.translateService.instant('components.telegramMessageNote.labels.messagePhoto')} ${
            content.caption?.text || ''
          } `,
        );
      case 'messagePinMessage':
        return this.getWhoFullName(whoId).pipe(
          map(
            whoFullName =>
              `${whoFullName} ${this.translateService.instant(
                'components.telegramMessageNote.labels.messagePinMessage',
              )}`,
          ),
        ); // TODO: use content.messageId
      case 'messageSticker':
        return of(
          `${this.translateService.instant('components.telegramMessageNote.labels.messageSticker')}${
            content.sticker?.emoji || ''
          } `,
        );
      case 'messageText':
        return of(content.text?.text || '');
      case 'messageVoiceNote':
        return of(
          `${this.translateService.instant('components.telegramMessageNote.labels.messageVoiceNote')} ${
            content.caption?.text || ''
          } `,
        );
      case 'messageVideo':
        return of(
          `${this.translateService.instant('components.telegramMessageNote.labels.messageVideo')} ${
            content.caption?.text || ''
          } `,
        );
      case 'messageVideoNote':
        return of(this.translateService.instant('components.telegramMessageNote.labels.messageVideoNote'));
      case 'messageBasicGroupChatCreate':
        return of(
          `${this.translateService.instant('components.telegramMessageNote.labels.messageBasicGroupChatCreate')} «${
            content.title
          }»`,
        );
      case 'messageSupergroupChatCreate':
        return of(
          `${this.translateService.instant('components.telegramMessageNote.labels.messageSupergroupChatCreate')} «${
            content.title
          }»`,
        );
      case 'messageChatChangeTitle':
        return this.getWhoFullName(whoId).pipe(
          map(
            whoFullName =>
              `${whoFullName} ${this.translateService.instant(
                'components.telegramMessageNote.labels.messageChatChangeTitle',
              )} «${content.title}»`,
          ),
        );
      case 'messageChatChangePhoto':
        return this.getWhoFullName(whoId).pipe(
          map(
            whoFullName =>
              `${whoFullName} ${this.translateService.instant(
                'components.telegramMessageNote.labels.messageChatChangePhoto',
              )}`,
          ),
        );
      case 'messageChatDeletePhoto':
        return this.getWhoFullName(whoId).pipe(
          map(
            whoFullName =>
              `${whoFullName} ${this.translateService.instant(
                'components.telegramMessageNote.labels.messageChatDeletePhoto',
              )}`,
          ),
        );
      case 'messageChatJoinByLink':
        return this.getWhoFullName(whoId).pipe(
          map(
            whoFullName =>
              `${whoFullName} ${this.translateService.instant(
                'components.telegramMessageNote.labels.messageChatJoinByLink',
              )}`,
          ),
        );
      case 'messageChatJoinByRequest':
        return this.getWhoFullName(whoId).pipe(
          map(
            whoFullName =>
              `${whoFullName} ${this.translateService.instant(
                'components.telegramMessageNote.labels.messageChatJoinByRequest',
              )}`,
          ),
        );
      case 'messageChatDeleteMember':
        usersFullName$ = zip(this.getWhoFullName(whoId), from(this.messengerService.getUserFullName(content.userId)));
        return usersFullName$.pipe(
          map(
            usersFullName =>
              `${usersFullName[0]} ${this.translateService.instant(
                'components.telegramMessageNote.labels.messageChatDeleteMember',
              )} ${usersFullName[1]}`,
          ),
        );
      case 'messageUnsupported':
        return of(this.translateService.instant('components.telegramMessageNote.labels.messageUnsupported'));
      default:
        return of(`[[ ${content._} ]]`);
    }
  }
}
