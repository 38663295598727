<navigation-logo class="logo" [mode]="'desktop'" [isOpen]="isOpen" [isBrand]="isBrand"></navigation-logo>

<tui-scrollbar class="navigation" [class.navigation_min]="!isOpen">
  <app-navigation-list [mode]="'desktop'" [isOpen]="isOpen" [navTree]="navTree"></app-navigation-list>
</tui-scrollbar>

<navigation-message
  class="message"
  [class.message_min]="!isOpen"
  [mode]="'desktop'"
  [isOpen]="isOpen"
></navigation-message>

<association-menu *ngIf="!isBrand" [isOpen]="isOpen" [mode]="'desktop'"></association-menu>

<div
  class="navigation-footer"
  [ngClass]="isOpen && !isProduction ? 'navigation-footer_columns-3' : 'navigation-footer_columns-1'"
>
  <app-theme-switcher-mini *ngIf="isOpen && !isProduction"></app-theme-switcher-mini>

  <a class="version-link" routerLink="/settings/changelog">{{ appVersion }}</a>

  <app-language-switcher-mini *ngIf="isOpen && !isProduction"></app-language-switcher-mini>
</div>
