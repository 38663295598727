<form class="form" [formGroup]="form">
  <div class="contacts" formArrayName="contacts">
    <div
      *ngFor="let contact of contacts.controls; let cIndex = index"
      [class.tui-skeleton]="loading"
      [class.tui-skeleton_text]="loading"
    >
      <div class="item" [formGroupName]="cIndex">
        <div *ngIf="showFieldCounter || allowEditing" class="item__header">
          <div *ngIf="showFieldCounter" class="title text_size-m text_bold text_color-01">Поле {{ cIndex + 1 }}</div>
          <div class="buttons">
            <button
              *ngIf="allowEditing"
              tuiIconButton
              type="button"
              icon="tuiIconChevronUpLarge"
              appearance="whiteblock-active"
              [size]="'m'"
              [disabled]="cIndex === 0"
              (click)="moveContact(cIndex, -1)"
            ></button>

            <button
              *ngIf="allowEditing"
              tuiIconButton
              type="button"
              icon="tuiIconChevronDownLarge"
              appearance="whiteblock-active"
              [size]="'m'"
              [disabled]="cIndex === contacts.controls.length - 1"
              (click)="moveContact(cIndex, 1)"
            ></button>

            <button
              *ngIf="allowDeleting"
              tuiIconButton
              type="button"
              icon="tuiIconTrash2Large"
              appearance="whiteblock-active"
              [size]="'m'"
              (click)="removeContact(cIndex)"
            ></button>
          </div>
        </div>

        <!-- Тип поля -->
        <div *ngIf="allowEditing" class="item__type">
          <tui-select
            tabindex="20"
            formControlName="contactTypeId"
            [valueContent]="contactTypes ? contactTypesStringify(contactTypes) : ''"
            [tuiTextfieldSize]="tuiElementLargeSize"
            [class.tui-skeleton]="loading"
            [class.tui-skeleton_text]="loading"
          >
            {{ 'components.contacts.fields.contactType' | translate }}
            <tui-data-list *tuiDataList>
              <button *ngFor="let contactType of contactTypes" tuiOption [value]="contactType.id">
                {{ contactType.description | translate }}
              </button>
            </tui-data-list>
          </tui-select>
        </div>

        <!-- Название поля -->
        <div *ngIf="allowEditing" class="item__description">
          <tui-input
            class="title"
            tabindex="21"
            formControlName="description"
            [tuiTextfieldSize]="tuiElementLargeSize"
            [class.tui-skeleton]="loading"
            [class.tui-skeleton_text]="loading"
          >
            {{ 'components.contacts.fields.contactName' | translate }}
          </tui-input>

          <app-field-wrapper-old
            *ngxPermissionsOnly="['contactsRequireValueEditing']"
            [label]="'common.labels.required' | translate"
            [class.tui-skeleton]="loading"
            [class.tui-skeleton_text]="loading"
            [size]="tuiElementMediumSize"
          >
            <app-toggle formControlName="isRequired" [size]="tuiElementLargeSize"></app-toggle>
          </app-field-wrapper-old>
        </div>

        <!-- Значение поля -->
        <div class="item__value">
          <!-- Поле не является ни Прочим, ни файлом -->
          <tui-input
            *ngIf="isNotOtherAndNotFileContactLocal(contact.value.contactTypeId)"
            tabindex="22"
            formControlName="contact"
            [tuiTextfieldSize]="tuiElementLargeSize"
            [class.tui-skeleton]="loading"
            [class.tui-skeleton_text]="loading"
          >
            {{ allowEditing ? ('components.contacts.fields.contactValue' | translate) : contact.value.description }}
            <span *ngIf="contact.value.isRequired" class="tui-required"></span>
          </tui-input>

          <!-- Поле является Прочим -->
          <tui-textarea
            *ngIf="isOtherContactLocal(contact.value.contactTypeId)"
            class="field_height_min"
            tabindex="22"
            formControlName="contact"
            [tuiTextfieldSize]="tuiElementLargeSize"
            [class.tui-skeleton]="loading"
            [class.tui-skeleton_text]="loading"
            [expandable]="true"
          >
            {{ allowEditing ? ('components.contacts.fields.contactValue' | translate) : contact.value.description }}
            <span *ngIf="contact.value.isRequired" class="tui-required"></span>
          </tui-textarea>

          <!-- Поле является файлом -->
          <ng-container *ngIf="isFileContactLocal(contact.value.contactTypeId)">
            <app-label [label]="allowEditing ? '' : contact.value.description">
              <span *ngIf="!allowEditing && contact.value.isRequired" class="tui-required"></span>
              <tui-files *ngIf="!!getDocumentFormControl(contact, 'oldDocument').value">
                <tui-file
                  [file]="getDocumentFormControl(contact, 'oldDocument').value"
                  (removed)="resetDocumentFormControl(contact, 'oldDocument')"
                  [class.tui-skeleton]="loading"
                  [class.tui-skeleton_text]="loading"
                  [showDelete]="!!allowFilesDeleting"
                >
                  <app-download-link
                    class="download-button"
                    [fileId]="getDocumentFormControl(contact, 'oldDocument').value.id"
                    [fileName]="getDocumentFormControl(contact, 'oldDocument').value.name"
                    [isModeration]="isModeration"
                    [showIcon]="false"
                    [pseudo]="false"
                  >
                    {{ 'common.buttons.download' | translate }}
                  </app-download-link>
                </tui-file>
              </tui-files>
              <ng-container *ngIf="!getDocumentFormControl(contact, 'oldDocument').value">
                <tui-input-files
                  *ngIf="!getDocumentFormControl(contact, 'newDocument').value"
                  class="size_{{ tuiElementLargeSize }}"
                  [class.invalid]="touched && validateForm && contact.get('isRequired')?.value"
                  [link]="'common.fields.fileLink' | translate"
                  [label]="' '"
                  tabindex="22"
                  formControlName="newDocument"
                  [maxFileSize]="maxDocFileSize"
                  [tuiTextfieldSize]="tuiElementLargeSize"
                  [class.tui-skeleton]="loading"
                  [class.tui-skeleton_text]="loading"
                  [size]="tuiElementLargeSize"
                >
                  <input tuiInputFiles [accept]="acceptFiles" />
                </tui-input-files>
                <tui-files *ngIf="!!getDocumentFormControl(contact, 'newDocument').value">
                  <tui-file
                    [file]="getDocumentFormControl(contact, 'newDocument').value"
                    (removed)="resetDocumentFormControl(contact, 'newDocument')"
                    [class.tui-skeleton]="loading"
                    [class.tui-skeleton_text]="loading"
                  ></tui-file>
                </tui-files>
              </ng-container>
            </app-label>
          </ng-container>
        </div>
      </div>

      <div *ngIf="showFieldSeparator" class="separator"></div>
    </div>

    <div
      *ngIf="!disabled && allowCreating"
      tuiGroup
      class="add-button"
      [class.tui-skeleton]="loading"
      [class.tui-skeleton_text]="loading"
      [collapsed]="true"
    >
      <button tuiButton appearance="whiteblock-active" [size]="'m'" type="button" (click)="addContact()">
        {{ 'components.contacts.buttons.addField' | translate }}
      </button>

      <button
        *ngIf="!disabled && allowCreating"
        tuiIconButton
        class="tui-group__auto-width-item"
        appearance="whiteblock-active"
        icon="tuiIconPlusCircleLarge"
        [size]="'m'"
        type="button"
        (click)="addContact()"
      ></button>
    </div>
  </div>
</form>
