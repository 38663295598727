import { Component, ChangeDetectionStrategy, ChangeDetectorRef, Inject } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { BreakpointObserver } from '@angular/cdk/layout';
import { TuiDialogContext } from '@taiga-ui/core';
import { POLYMORPHEUS_CONTEXT } from '@tinkoff/ng-polymorpheus';
import { TranslateService } from '@ngx-translate/core';
import { BreakpointObserverHelperService } from '@src/core/services';
import { ResizableBaseComponent } from '@src/app/components';

import { DELETE_AUTH_USER_CONFIRMATION_TEXT } from './constants';

@Component({
  selector: 'app-delete-auth-user-dialog',
  templateUrl: './delete-auth-user-dialog.component.html',
  styleUrls: ['./delete-auth-user-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeleteAuthUserDialogComponent extends ResizableBaseComponent {
  confirmationText: string = this.translateService.instant(DELETE_AUTH_USER_CONFIRMATION_TEXT);

  readonly form = new UntypedFormGroup({
    enteredText: new UntypedFormControl('', [Validators.required, Validators.pattern(this.confirmationText)]),
  });

  constructor(
    readonly cdr: ChangeDetectorRef,
    readonly breakpointObserver: BreakpointObserver,
    readonly breakpointObserverHelperService: BreakpointObserverHelperService,
    private readonly translateService: TranslateService,
    @Inject(POLYMORPHEUS_CONTEXT) private readonly context: TuiDialogContext<boolean>,
  ) {
    super(cdr, breakpointObserver, breakpointObserverHelperService);
  }

  apply() {
    this.form.markAllAsTouched();
    if (this.form.invalid) return;

    this.context.completeWith(true);
  }

  cancel() {
    this.context.completeWith(false);
  }
}
