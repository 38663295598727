/**
 * Unions API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Код скидки продукта лояльности.
 */
export interface LoyaltyProductCode { 
    /**
     * Код.
     */
    discountCode?: string;
    /**
     * Условия.
     */
    discountOptions?: string;
    /**
     * дата начала срока действия кода.
     */
    validSince?: string;
    /**
     * дата истечения срока действия кода.
     */
    validTill?: string;
    /**
     * ID продукта.
     */
    productId?: string;
    /**
     * Id загруженного файла изображения с QR-кодом.
     */
    discountQRCodeFileId?: string;
}

