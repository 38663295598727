<tui-primitive-textfield
  [tuiTextfieldLabelOutside]="labelOutside"
  [tuiTextfieldSize]="size"
  [tuiTextfieldCustomContent]="countryIsoCode ? flag : ''"
  [readOnly]="readOnly"
  [value]="value"
  (valueChange)="onChangeValue($event)"
  [invalid]="touched && (invalid || (pseudoInvalid ?? false))"
  [disabled]="isBlocked"
  (focusedChange)="onFocused($event)"
>
  <ng-content></ng-content>

  <input
    tuiTextfield
    [attr.autocomplete]="autocomplete"
    autocomplete="tel"
    inputmode="tel"
    [attr.pattern]="pattern"
    [maskito]="maskPhone"
  />
</tui-primitive-textfield>

<ng-template #flag>
  <img width="28" [attr.alt]="countryIsoCode" [src]="countryIsoCode | tuiFlag" />
</ng-template>
