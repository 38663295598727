import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { CustomNamesService } from '../services/custom-names.service';
import { CodeName } from '../types';

@Pipe({
  name: 'customNames',
})
export class CustomNamesPipe implements PipeTransform {
  constructor(private customNamesService: CustomNamesService, private readonly translateService: TranslateService) {}

  transform(codeName: CodeName): string {
    return this.customNamesService.getItem(codeName)?.title ?? this.translateService.instant('common.labels.noData');
  }
}
