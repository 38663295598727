import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatListModule } from '@angular/material/list';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import {
  TuiAvatarModule,
  TuiInputModule,
  TuiMultiSelectModule,
  TuiDataListWrapperModule,
  TuiInputFilesModule,
} from '@taiga-ui/kit';
import { TuiDataListModule, TuiLoaderModule, TuiScrollbarModule, TuiTextfieldControllerModule } from '@taiga-ui/core';
import { TuiLetModule, TuiAutoFocusModule } from '@taiga-ui/cdk';
import { SharedModule } from '@src/app/shared';
import { UsersListModule } from '@src/app/modules/users-list';

import { GroupComponent } from './group.component';

@NgModule({
  declarations: [GroupComponent],
  imports: [
    CommonModule,
    FormsModule,
    MatButtonModule,
    MatCheckboxModule,
    MatDialogModule,
    MatIconModule,
    MatGridListModule,
    MatListModule,
    MatSlideToggleModule,
    ReactiveFormsModule,
    RouterModule,
    SharedModule,
    TuiAutoFocusModule,
    TuiAvatarModule,
    TuiDataListModule,
    TuiDataListWrapperModule,
    TuiInputFilesModule,
    TuiInputModule,
    TuiLetModule,
    TuiLoaderModule,
    TuiMultiSelectModule,
    TuiScrollbarModule,
    TuiTextfieldControllerModule,
    UsersListModule,
  ],
  exports: [GroupComponent],
})
export class GroupModule {}
