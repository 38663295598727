<div class="support">
  <!-- Форма обратной связи -->
  <div class="support__form-wrapper">
    <app-send-email-form></app-send-email-form>
  </div>

  <!-- Кнопка для удаление текущего профиля -->
  <app-delete-auth-user-link class="link"></app-delete-auth-user-link>

  <!-- Руководство пользователя -->
  <app-download-link class="link" fileLink="assets/docs/manual-for-user.pdf">
    {{ 'components.supportPage.buttons.userManual' | translate }}
  </app-download-link>

  <!-- Руководство администратора -->
  <app-download-link class="link" fileLink="assets/docs/manual-for-admin.pdf">
    {{ 'components.supportPage.buttons.adminManual' | translate }}
  </app-download-link>

  <!-- Политика безопасности и конфиденциальности данных Unions -->
  <app-download-link class="link" fileLink="assets/docs/Политика безопасности и конфиденциальности данных Unions.pdf">
    {{ 'components.supportPage.buttons.policy' | translate }}
  </app-download-link>

  <!-- Сертификат безопасности приложения Unions -->
  <app-download-link class="link" fileLink="assets/docs/Сертификат безопасности приложения Unions.pdf">
    {{ 'components.supportPage.buttons.securityCertificate' | translate }}
  </app-download-link>

  <!-- Акт оценки эффективности мер по обеспечению безопасности персональных данных в информационных системах персональных данных ООО "Техносервис" -->
  <app-download-link
    class="link"
    fileLink="assets/docs/Акт оценки эффективности мер по обеспечению безопасности персональных данных в информационных системах ООО Техносервис.pdf"
  >
    {{ 'components.supportPage.buttons.actEvaluatingEffectiveness' | translate }}
  </app-download-link>

  <!-- Заключение по результатам аудита соответствия обработки персональных данных ООО "Техносервис" требованиям законодательства -->
  <app-download-link
    class="link"
    fileLink="assets/docs/Заключение по результатам аудита соответствия обработки персональных данных ООО Техносервис требованиям законодательства.pdf"
  >
    {{ 'components.supportPage.buttons.conclusionResultsAudit' | translate }}
  </app-download-link>

  <!-- Свидетельство о соответствии обработки персональных данных Федеральному закону №152-ФЗ "О персональных данных" и принятым в соответствии с ним нормативным правовым актам -->
  <app-download-link
    class="link"
    fileLink="assets/docs/Свидетельство о соответствии обработки персональных данных Федеральному закону №152-ФЗ О персональных данных.pdf"
  >
    {{ 'components.supportPage.buttons.certificateConformity' | translate }}
  </app-download-link>
</div>
