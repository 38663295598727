import { Component, OnInit, ChangeDetectionStrategy, Input, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { MessageContentUnion } from '@airgram/core/types/outputs';
import { MessageVideo } from '@airgram/core/types/outputs/MessageContent';
import { FilePart } from '@airgram/web';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FileReaderService } from '@src/core/services';
import { MultimediaSource } from '@src/models';

@Component({
  selector: 'app-message-video',
  templateUrl: './message-video.component.html',
  styleUrls: ['../styles/message-content.scss', './message-video.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MessageVideoComponent implements OnInit, OnDestroy {
  @Input() set message(mess: MessageContentUnion | undefined) {
    this.messageVideo = mess as MessageVideo;
  }

  messageVideo?: MessageVideo;
  messageVideoSource?: MultimediaSource;
  messageVideoPosterSource?: MultimediaSource;

  private destroyed$$: Subject<void>;

  constructor(private cdr: ChangeDetectorRef, private fileReaderService: FileReaderService) {
    this.destroyed$$ = new Subject<void>();
  }

  ngOnInit(): void {
    this.initVideo();
  }

  ngOnDestroy(): void {
    this.destroyed$$.next();
    this.destroyed$$.complete();
  }

  private initVideo(): void {
    if (!this.messageVideo) return;

    this.fileReaderService
      .getFilePart(this.messageVideo?.video?.thumbnail?.file)
      .pipe(takeUntil(this.destroyed$$))
      .subscribe(filePart => this.setPosterSource(filePart));

    this.fileReaderService
      .getFilePart(this.messageVideo?.video?.video)
      .pipe(takeUntil(this.destroyed$$))
      .subscribe(filePart => this.setVideoSource(filePart));
  }

  private setVideoSource(filePart?: FilePart): void {
    this.messageVideoSource = { src: this.fileReaderService.getFileSource(filePart) };
    this.cdr.markForCheck();
  }

  private setPosterSource(filePart?: FilePart): void {
    this.messageVideoPosterSource = { src: this.fileReaderService.getFileSource(filePart) };
    this.cdr.markForCheck();
  }
}
