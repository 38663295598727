// TODO: refactoring
export type FieldTypeName =
  | 'email'
  | 'file'
  | 'messenger'
  | 'organisation'
  | 'committee'
  | 'phone'
  | 'social-network'
  | 'text'
  | 'user'
  | 'web-document'
  | 'web-page'
  | 'files-list'
  | 'parse-links'
  | 'text-for-admin'
  | 'text-for-admin-and-user'
  | 'web-document-for-admin'
  | 'web-document-for-admin-and-user'
  | 'file-for-admin'
  | 'file-for-admin-and-user'
  | 'new-text'
  | 'new-user';

export interface FieldType {
  id: number;
  description: string;
  forContacts: boolean;
  permissions?: string[];
}
