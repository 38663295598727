import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Subject, catchError, map, switchMap, takeUntil, throwError } from 'rxjs';
import { TuiDay } from '@taiga-ui/cdk';
import {
  LoyaltyService,
  LoyaltyStatisticsByMonths,
  LoyaltyStatisticsByPeriod,
  LoyaltyStatisticsSummary,
  convertDateToApiFormat,
  LoyaltyDiscountsListByPeriod,
} from '@src/api';
import { AuthUserService } from '@src/app/modules/auth';
import { TuiDayTimeTransformer } from '@src/utils';
import { AlertService } from '@src/core/services';

import {
  GetStatisticsByContactsListResponseDtoUI,
  LoyaltyDiscountsListByPeriodUI,
  LoyaltyStatisticsBarParams,
  LoyaltyStatisticsContactsParams,
  LoyaltyStatisticsDiscountsParams,
  LoyaltyStatisticsProductsParams,
  LoyaltyStatisticsSummaryParams,
} from './types';

@Injectable({
  providedIn: 'root',
})
export class LpStatisticsService implements OnDestroy {
  /** Общая статистика */
  statisticsSummary$ = new BehaviorSubject<LoyaltyStatisticsSummary | null>(null);
  loadStatisticsSummary$ = new BehaviorSubject<LoyaltyStatisticsSummaryParams | null>(null);

  /** Статистика для графика */
  statisticsBar$ = new BehaviorSubject<LoyaltyStatisticsByMonths | null>(null);
  loadStatisticsBar$ = new BehaviorSubject<LoyaltyStatisticsBarParams | null>(null);

  /** Статистика объявлений */
  statisticsProducts$ = new BehaviorSubject<LoyaltyStatisticsByPeriod | null>(null);
  loadStatisticsProducts$ = new BehaviorSubject<LoyaltyStatisticsProductsParams | null>(null);

  /** Статистика получения скидок */
  statisticsDiscounts$ = new BehaviorSubject<LoyaltyDiscountsListByPeriodUI | null>(null);
  loadStatisticsDiscounts$ = new BehaviorSubject<LoyaltyStatisticsDiscountsParams | null>(null);

  /** Статистика получения скидок */
  statisticsContacts$ = new BehaviorSubject<GetStatisticsByContactsListResponseDtoUI | null>(null);
  loadStatisticsContacts$ = new BehaviorSubject<LoyaltyStatisticsContactsParams | null>(null);

  private readonly authUser$ = this.authUserService.authUser$;
  private readonly destroyed$$: Subject<void> = new Subject<void>();

  constructor(
    private readonly authUserService: AuthUserService,
    private readonly loyaltyService: LoyaltyService,
    private readonly alertService: AlertService,
  ) {
    this.loadStatisticsSummary().pipe(takeUntil(this.destroyed$$)).subscribe();
    this.loadStatisticsBar().pipe(takeUntil(this.destroyed$$)).subscribe();
    this.loadStatisticsProducts().pipe(takeUntil(this.destroyed$$)).subscribe();
    this.loadStatisticsDiscounts().pipe(takeUntil(this.destroyed$$)).subscribe();
    this.loadStatisticsContacts().pipe(takeUntil(this.destroyed$$)).subscribe();
  }

  ngOnDestroy(): void {
    this.destroyed$$.next();
    this.destroyed$$.complete();
  }

  startStatisticsSummary(params: LoyaltyStatisticsSummaryParams) {
    this.loadStatisticsSummary$.next(params);
  }

  loadStatisticsSummary() {
    return this.loadStatisticsSummary$
      .pipe(
        switchMap(params => {
          return this.loyaltyService.statisticsSummary(
            params?.unionId,
            params?.productId,
            this.convertTuiDayToApiFormat(params?.dateFrom),
            this.convertTuiDayToApiFormat(params?.dateTo),
          );
        }),
      )
      .pipe(
        map(statisticsSummary => {
          this.statisticsSummary$.next(statisticsSummary);
          return statisticsSummary;
        }),
      )
      .pipe(
        catchError(err => {
          this.alertService.error(err);
          return throwError(() => err);
        }),
      )
      .pipe(takeUntil(this.destroyed$$));
  }

  startStatisticsBar(params: LoyaltyStatisticsBarParams) {
    this.loadStatisticsBar$.next(params);
  }

  loadStatisticsBar() {
    return this.loadStatisticsBar$
      .pipe(
        switchMap(params => {
          return this.loyaltyService.statisticsByMonths(
            params?.unionId,
            params?.productId,
            this.convertTuiDayToApiFormat(params?.dateFrom),
            this.convertTuiDayToApiFormat(params?.dateTo),
          );
        }),
      )
      .pipe(
        map(statisticsBar => {
          this.statisticsBar$.next(statisticsBar);
          return statisticsBar;
        }),
      )
      .pipe(
        catchError(err => {
          this.alertService.error(err);
          return throwError(() => err);
        }),
      )
      .pipe(takeUntil(this.destroyed$$));
  }

  startStatisticsProducts(params: LoyaltyStatisticsProductsParams) {
    this.loadStatisticsProducts$.next(params);
  }

  loadStatisticsProducts() {
    return this.loadStatisticsProducts$
      .pipe(
        switchMap(params => {
          return this.loyaltyService.statisticsByPeriod(
            params?.unionId,
            params?.productId,
            this.convertTuiDayToApiFormat(params?.dateFrom),
            this.convertTuiDayToApiFormat(params?.dateTo),
          );
        }),
      )
      .pipe(
        map(statisticsProducts => {
          this.statisticsProducts$.next(statisticsProducts);
          return statisticsProducts;
        }),
      )
      .pipe(
        catchError(err => {
          this.alertService.error(err);
          return throwError(() => err);
        }),
      )
      .pipe(takeUntil(this.destroyed$$));
  }

  startStatisticsDiscounts(params: LoyaltyStatisticsDiscountsParams) {
    this.loadStatisticsDiscounts$.next(params);
  }

  loadStatisticsDiscounts() {
    return this.loadStatisticsDiscounts$
      .pipe(
        switchMap(params => {
          return this.loyaltyService.discountsListByPeriod(
            params?.unionId,
            params?.productId,
            this.convertTuiDayToApiFormat(params?.dateFrom),
            this.convertTuiDayToApiFormat(params?.dateTo),
          );
        }),
      )
      .pipe(
        map(statisticsDiscounts => this.normalizeStatisticsDiscountsUI(statisticsDiscounts)),
        map(statisticsDiscounts => {
          this.statisticsDiscounts$.next(statisticsDiscounts);
          return statisticsDiscounts;
        }),
      )
      .pipe(
        catchError(err => {
          this.alertService.error(err);
          return throwError(() => err);
        }),
      )
      .pipe(takeUntil(this.destroyed$$));
  }

  startStatisticsContacts(params: LoyaltyStatisticsContactsParams) {
    this.loadStatisticsContacts$.next(params);
  }

  loadStatisticsContacts() {
    return this.loadStatisticsContacts$
      .pipe(
        switchMap(params => {
          return this.loyaltyService.statisticsByContactsList(
            params?.unionId,
            params?.productId,
            this.convertTuiDayToApiFormat(params?.dateFrom),
            this.convertTuiDayToApiFormat(params?.dateTo),
          );
        }),
      )
      .pipe(
        map(statisticsContacts => this.normalizeStatisticsContactsUI(statisticsContacts)),
        map(statisticsContacts => {
          this.statisticsContacts$.next(statisticsContacts);
          return statisticsContacts;
        }),
      )
      .pipe(
        catchError(err => {
          this.alertService.error(err);
          return throwError(() => err);
        }),
      )
      .pipe(takeUntil(this.destroyed$$));
  }

  clearAll() {
    this.statisticsSummary$.next(null);
    this.statisticsBar$.next(null);
    this.statisticsProducts$.next(null);
    this.statisticsDiscounts$.next(null);
    this.statisticsContacts$.next(null);
  }

  private convertTuiDayToApiFormat(value?: TuiDay) {
    return value ? convertDateToApiFormat(TuiDayTimeTransformer.tuiDayToDate(value)) : undefined;
  }

  private normalizeStatisticsDiscountsUI(data: LoyaltyDiscountsListByPeriod) {
    if (data.entries?.length) {
      data.entries = data.entries.map(item => {
        return {
          ...item,
          discount: item.discountRate
            ? item.discountRate + ' %'
            : item.discountAbsolute
            ? item.discountAbsolute + ' ₽'
            : item.discountText,
        };
      });
    }

    return data;
  }

  private normalizeStatisticsContactsUI(data: GetStatisticsByContactsListResponseDtoUI) {
    if (data?.entries?.length) {
      data.entries = data.entries.map(item => {
        return {
          ...item,
          fio: item.payload?.value?.fio,
          email: item.payload?.value?.email,
          phone: item.payload?.value?.phone,
          discount: item.discountRate
            ? item.discountRate + ' %'
            : item.discountAbsolute
            ? item.discountAbsolute + ' ₽'
            : item.discountText,
        };
      });
    }

    return data;
  }
}
