import { Injectable } from '@angular/core';
import { Router, CanActivate, CanActivateChild, ActivatedRouteSnapshot } from '@angular/router';
import { lastValueFrom } from 'rxjs';
import { PreferencesService } from '@src/core/services';
import { logger } from '@src/utils';

import { SessionService } from '../services/session.service';

@Injectable({
  providedIn: 'root',
})
export class SettingsGuard implements CanActivate, CanActivateChild {
  constructor(
    private readonly router: Router,
    private readonly preferencesService: PreferencesService,
    private readonly session: SessionService,
  ) {
    logger('SettingsGuard constructor');
  }

  async canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
    const associationId = route.params['associationId'];
    if (!!associationId) {
      if (this.session.isLogged()) {
        const settings = await lastValueFrom(this.preferencesService.getDataByOrganisationId(associationId));

        if (!!settings?.allowUserRegistrationForm) {
          return true;
        }
      } else {
        return true;
      }
    }

    this.router.navigate(['not-found']);
    return false;
  }

  async canActivateChild(route: ActivatedRouteSnapshot): Promise<boolean> {
    return this.canActivate(route);
  }
}
