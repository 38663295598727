/**
 * Модель справочника
 *
 * @interface DictionaryTreeNode
 * @label {string} текст элемента справочника
 * @icon {string} иконка элемента справочника, используется только в верхнем уровне
 * @children {DictionaryTreeNode[]} вложенный уровень справочника
 */
export interface DictionaryTreeNode {
  readonly label: string;
  readonly icon?: string;
  readonly children?: readonly DictionaryTreeNode[];
}
