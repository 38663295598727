<div class="discount">
  <div class="discount__code">
    <ng-container *ngIf="!discount?.showAsQRCode && !isQrCodeImage">
      <app-scalable-font
        class="promoCode"
        [text]="discount?.promoCode ?? 'common.labels.noData' | translate"
      ></app-scalable-font>

      <button tuiButton type="button" appearance="secondary" size="m" (click)="copyPromoCode()">
        {{ 'components.loyaltyDiscountDialog.buttons.copyCode' | translate }}
      </button>
    </ng-container>

    <ng-container *ngIf="discount?.showAsQRCode && !isQrCodeImage">
      <qrcode
        #qrcode
        [qrdata]="discount?.promoCode ?? ''"
        [allowEmptyString]="true"
        [alt]="'Product QR-code'"
        [cssClass]="'center'"
        [colorDark]="'#000000ff'"
        [colorLight]="'#ffffffff'"
        [elementType]="'img'"
        [errorCorrectionLevel]="'Q'"
        [scale]="1"
        [width]="qrCodeImageWidth"
        [margin]="1"
      ></qrcode>
    </ng-container>

    <ng-container *ngIf="isQrCodeImage">
      <app-photo
        [photoId]="discount?.qrCodeImage?.id"
        [unionId]="externalUnionId"
        [text]="'QR code image'"
        [width]="qrCodeImageWidth + 'px'"
      ></app-photo>
    </ng-container>
  </div>

  <div class="discount__terms">
    <h1 class="title">{{ 'components.loyaltyDiscountDialog.labels.terms' | translate }}:</h1>

    <app-parse-links
      class="description"
      [value]="discount?.description"
      (externalLink)="saveRedirectLink($event)"
    ></app-parse-links>
  </div>

  <ng-container *ngIf="requestLeadContacts">
    <div class="discount__terms">
      <h1 class="title">{{ 'components.loyaltyDiscountDialog.labels.personalDiscountTitle' | translate }}:</h1>

      <tui-scrollbar class="description">
        {{ 'components.loyaltyDiscountDialog.labels.personalDiscountDescription' | translate }}
      </tui-scrollbar>
    </div>

    <form *ngIf="contactsFormVisible" class="contacts-form" [formGroup]="contactsForm">
      <tui-input
        tuiAutoFocus
        class="contacts-form__field"
        tabindex="1"
        formControlName="fio"
        [tuiTextfieldSize]="tuiElementLargeSize"
      >
        {{ 'components.loyaltyDiscountDialog.fields.fio' | translate }}
        <span class="tui-required"></span>
        <input tuiTextfield appTestingId="LoyaltyDiscountDialog.Input.Name" />
      </tui-input>

      <app-input-phone class="contacts-form__field" tabindex="2" formControlName="phone" [size]="tuiElementLargeSize">
        {{ 'components.loyaltyDiscountDialog.fields.phone' | translate }}
        <span class="tui-required"></span>
      </app-input-phone>

      <tui-input
        class="contacts-form__field"
        tabindex="3"
        formControlName="email"
        [tuiTextfieldSize]="tuiElementLargeSize"
      >
        {{ 'components.loyaltyDiscountDialog.fields.email' | translate }}
        <span class="tui-required"></span>
        <input tuiTextfield appTestingId="LoyaltyDiscountDialog.Input.Email" />
      </tui-input>

      <div class="contacts-form__buttons">
        <button
          tuiButton
          type="button"
          appearance="secondary"
          [size]="isMobile ? 'm' : 'l'"
          (click)="contactsFormVisible = false"
        >
          {{ 'components.loyaltyDiscountDialog.buttons.hide' | translate }}
        </button>

        <button tuiButton type="button" [size]="isMobile ? 'm' : 'l'" (click)="sendContacts()">
          {{ 'components.loyaltyDiscountDialog.buttons.send' | translate }}
        </button>
      </div>
    </form>

    <button
      *ngIf="!contactsFormVisible"
      tuiButton
      type="button"
      [size]="isMobile ? 'm' : 'l'"
      (click)="contactsFormVisible = true"
    >
      {{ 'components.loyaltyDiscountDialog.buttons.share' | translate }}
    </button>
  </ng-container>
</div>
