import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TuiAvatarModule, TuiMultiSelectModule, TuiSelectModule } from '@taiga-ui/kit';
import { TuiDataListModule, TuiTextfieldControllerModule } from '@taiga-ui/core';
import { ComponentsModule } from '@src/app/components';
import { SharedModule } from '@src/app/shared';

import { OrganisationInputComponent, RenderOrganisationRowComponent } from './components';

@NgModule({
  declarations: [OrganisationInputComponent, RenderOrganisationRowComponent],
  imports: [
    CommonModule,
    ComponentsModule,
    FormsModule,
    TuiSelectModule,
    TuiTextfieldControllerModule,
    TuiDataListModule,
    TuiMultiSelectModule,
    TuiAvatarModule,
    SharedModule,
  ],
  exports: [OrganisationInputComponent],
})
export class OrganisationInputModule {}
