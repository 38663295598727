import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { TuiSizeS } from '@taiga-ui/core';

/**
 * Пустое поле
 * @param {string} text - (опционально) текст внутри поля
 * @param {TuiSizeS} size - (опционально) размер поля
 * @param {string} label - (опционально) лейбл над текстом
 */
@Component({
  selector: 'app-field-wrapper-old',
  templateUrl: './field-wrapper-old.component.html',
  styleUrls: ['./field-wrapper-old.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FieldWrapperOldComponent {
  @Input() text?: string = '';
  @Input() size: TuiSizeS = 'm';
  @Input() label?: string = '';
}
