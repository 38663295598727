import { Component, ChangeDetectionStrategy, ChangeDetectorRef, Inject } from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';
import { BreakpointObserverHelperService } from '@src/core/services';
import { ResizableBaseComponent } from '@src/app/components/resizable-base-component';
import { TuiDialogContext } from '@taiga-ui/core';
import { POLYMORPHEUS_CONTEXT } from '@tinkoff/ng-polymorpheus';

import { LpDiscountDialogService } from '../../../services';
import { LoyaltyProgramProductWithExternalUnionId } from '../../../models/LoyaltyProgramProduct';

@Component({
  selector: 'lp-view-discount-groups-dialog',
  templateUrl: './discount-groups-dialog.component.html',
  styleUrls: ['./discount-groups-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DiscountGroupsDialogComponent extends ResizableBaseComponent {
  constructor(
    readonly cdr: ChangeDetectorRef,
    readonly breakpointObserver: BreakpointObserver,
    readonly breakpointObserverHelperService: BreakpointObserverHelperService,
    @Inject(POLYMORPHEUS_CONTEXT)
    private readonly context: TuiDialogContext<string, LoyaltyProgramProductWithExternalUnionId>,
    private readonly lpDiscountDialogService: LpDiscountDialogService,
  ) {
    super(cdr, breakpointObserver, breakpointObserverHelperService);
  }

  get product() {
    return this.context.data.product;
  }

  get externalUnionId() {
    return this.context.data.externalUnionId;
  }

  getDiscount(discountGroupId: string) {
    this.lpDiscountDialogService.openDiscountDialogWithRequest(this.product, this.externalUnionId, discountGroupId);
  }
}
