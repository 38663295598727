import { Component, ChangeDetectionStrategy, Inject } from '@angular/core';
import { TuiDialogContext } from '@taiga-ui/core';
import { POLYMORPHEUS_CONTEXT } from '@tinkoff/ng-polymorpheus';

@Component({
  selector: 'app-dialog-image-viewing',
  templateUrl: './dialog-image-viewing.component.html',
  styleUrls: ['./dialog-image-viewing.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DialogImageViewingComponent {
  constructor(@Inject(POLYMORPHEUS_CONTEXT) private readonly context: TuiDialogContext<boolean, string>) {}

  get src(): string {
    return this.context.data;
  }
}
