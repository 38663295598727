import { UserUI } from '@src/models';
import { DECISION_TYPE_CODE } from '@src/constants';

import { GroupedListItem } from '../model/groups.model';
import { ItemType, ListTypes } from '../model/item.model';

export const getGroups = (items: ItemType[], listType: ListTypes) => {
  const groups: GroupedListItem[] = [];

  if (listType === 'users') {
    const itemsTyping = items as UserUI[];

    groups.push({
      title: 'Принято',
      items: itemsTyping.filter(item => item.decisionTypeCode === DECISION_TYPE_CODE.Accepted),
    });

    groups.push({
      title: 'Пройдено',
      items: itemsTyping.filter(item => item.decisionTypeCode === DECISION_TYPE_CODE.Passed),
    });

    groups.push({
      title: 'Отправлено',
      items: itemsTyping.filter(
        item =>
          item.decisionTypeCode === DECISION_TYPE_CODE.Sended || item.decisionTypeCode === DECISION_TYPE_CODE.Readed,
      ),
    });

    groups.push({
      title: 'Отклонено',
      items: itemsTyping.filter(item => item.decisionTypeCode === DECISION_TYPE_CODE.Rejected),
    });
  }

  return groups;
};
