import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { Router } from '@angular/router';
import { BreakpointObserver } from '@angular/cdk/layout';
import { BreakpointObserverHelperService } from '@src/core/services';
import { ResizableBaseComponent } from '@src/app/components/resizable-base-component';
import { NavigationItem, ADMINISTRATION_NAVIGATION } from '@src/app/modules/navigation';

@Component({
  selector: 'app-administration',
  templateUrl: './administration.component.html',
  styleUrls: ['./administration.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdministrationComponent extends ResizableBaseComponent {
  selectedItem?: NavigationItem;

  readonly navTree = ADMINISTRATION_NAVIGATION;

  constructor(
    readonly cdr: ChangeDetectorRef,
    readonly breakpointObserver: BreakpointObserver,
    readonly breakpointObserverHelperService: BreakpointObserverHelperService,
    private readonly router: Router,
  ) {
    super(cdr, breakpointObserver, breakpointObserverHelperService);
  }

  get showFullPage(): boolean {
    return !this.selectedItem || this.isLargeScreen;
  }

  onClickReturnButton() {
    this.selectedItem = undefined;
    this.router.navigate(['settings/administration']);
  }
}
