import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';

import { ThemeSwitcherService } from '../../services/theme-switcher.service';

@Component({
  selector: 'app-theme-switcher',
  templateUrl: './theme-switcher.component.html',
  styleUrls: ['./theme-switcher.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ThemeSwitcherComponent {
  isDark$ = this.themeSwitcherService.isDark$;

  constructor(private readonly themeSwitcherService: ThemeSwitcherService) {}

  onThemeChange(value: boolean) {
    this.themeSwitcherService.setTheme(value);
  }
}
