import { BehaviorSubject } from 'rxjs';

export interface MenuItem<T = number, Y = string> {
  id: T;
  title: string;
  icon?: Y;
  link?: string;
  action?: () => void;
  allowViewing$?: BehaviorSubject<boolean>;

  /** Значение видимости элемента */
  visibilityOption?: string;
  sortOrder?: number;
}
