import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginModule } from '@src/app/modules/login';

import { PolicyPageComponent } from './policy-page.component';

@NgModule({
  declarations: [PolicyPageComponent],
  imports: [CommonModule, LoginModule],
})
export class PolicyPageModule {}
