import { HttpClient } from '@angular/common/http';
import { TranslateLoader, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { StorageService } from '@src/app/modules/auth';
import { StorageKeys } from '@src/constants/storage';
import { APP_CONFIG, Language } from '@src/core';
import { TUI_ENGLISH_LANGUAGE, TUI_RUSSIAN_LANGUAGE } from '@taiga-ui/i18n';
import { map } from 'rxjs/operators';
import { lastValueFrom } from 'rxjs';

export function HttpLoaderFactory(http: HttpClient): TranslateLoader {
  return new TranslateHttpLoader(http, './assets/locale/', '.json');
}

export function appInitializerFactory(translateService: TranslateService, storageService: StorageService) {
  return () => {
    translateService.addLangs(APP_CONFIG.localizations);
    const storageLang = storageService.getItem(StorageKeys.Locale);
    if (storageLang) {
      return lastValueFrom(translateService.use(storageLang));
    } else {
      const browserLang = translateService.getBrowserLang();
      if (browserLang) {
        if (browserLang === Language.RU) {
          return lastValueFrom(translateService.use(Language.RU));
        } else {
          return lastValueFrom(translateService.use(Language.EN));
        }
      } else {
        return lastValueFrom(translateService.use(APP_CONFIG.defaultLocale));
      }
    }
  };
}

export function tuiLanguageFactory(translateService: TranslateService) {
  // TODO: использовать onLangChange вместо stream('languageCode') + хранить в sessionService текущий язык
  return translateService.stream('languageCode').pipe(
    map(languageCode => {
      if (languageCode === Language.EN) {
        return TUI_ENGLISH_LANGUAGE;
      } else {
        return TUI_RUSSIAN_LANGUAGE;
      }
    }),
  );
}
