import { BreakpointObserver } from '@angular/cdk/layout';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, takeUntil } from 'rxjs';
import { GetActualRegistrationFormsForAssociationResponseDto } from '@src/api';
import { ResizableBaseComponent } from '@src/app/components';
import { BreakpointObserverHelperService, UserService } from '@src/core/services';
import { fixRouteParam } from '@src/utils';

@Component({
  selector: 'app-moderation-users',
  templateUrl: './moderation-users.component.html',
  styleUrls: ['./moderation-users.component.scss', '../styles/administration-content.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModerationUsersComponent extends ResizableBaseComponent {
  moderationUsers$: BehaviorSubject<GetActualRegistrationFormsForAssociationResponseDto[] | null> =
    this.userService.moderationUsers$;
  selectedUserId?: string;

  constructor(
    readonly cdr: ChangeDetectorRef,
    readonly breakpointObserver: BreakpointObserver,
    readonly breakpointObserverHelperService: BreakpointObserverHelperService,
    private readonly userService: UserService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly router: Router,
  ) {
    super(cdr, breakpointObserver, breakpointObserverHelperService);
  }

  get showFullPage(): boolean {
    return !this.selectedUserId || this.isDoubleExtraLargeScreen;
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.getData();

    this.activatedRoute.paramMap.pipe(takeUntil(this.destroyed$$)).subscribe(params => {
      this.selectedUserId = fixRouteParam(params.get('moderationUserId'));
    });
  }

  onChangeSelectedUserId(selectedUserId?: string) {
    this.selectedUserId = selectedUserId;

    const routerNavigate = ['settings/administration/moderation-users'];
    if (selectedUserId) {
      routerNavigate.push(selectedUserId);
    }
    this.router.navigate(routerNavigate);
  }

  onClickReturnButton() {
    this.onChangeSelectedUserId(undefined);
  }

  reload() {
    this.onClickReturnButton();
    this.getData();
  }

  private getData(): void {
    this.userService.resetModerationUsers();
    this.userService.getModerationUsers();
    this.cdr.markForCheck();
  }
}
