import { Pipe, PipeTransform } from '@angular/core';
import { formatDate } from '@angular/common';
import { Language } from '@src/core';

@Pipe({
  name: 'timestampToDate',
})
export class TimestampToDatePipe implements PipeTransform {
  transform(timestamp: number | undefined, format: 'date' | 'string'): Date | string | undefined {
    if (format === 'date') return timestamp ? new Date(timestamp * 1000) : undefined;
    if (format === 'string') {
      let dateToString = '';

      if (timestamp) {
        const date = formatDate(new Date(timestamp * 1000), 'dd.MM.yy', Language.RU);
        const today = formatDate(new Date(), 'dd.MM.yy', Language.RU);
        if (date === today) {
          dateToString = formatDate(new Date(timestamp * 1000), 'HH:mm', Language.RU);
        } else {
          dateToString = date;
        }
      }

      return dateToString;
    }

    return;
  }
}
