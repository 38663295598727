<div class="changelog-page">
  <div *ngFor="let version of versionList" class="version">
    <div class="version__title title">
      <span class="title__name">{{ version.name }}</span>
      <span class="title__date">{{ version.date }}</span>
    </div>
    <ul class="version__list">
      <li *ngFor="let change of version.changeList" class="version__list-item">{{ change | translate }}</li>
    </ul>
  </div>
</div>
