export const isValidUrl = (text: string): string => {
  if (text.indexOf(' ') !== -1) {
    return '';
  }

  let url;
  let protocol = '';

  try {
    new URL(text);
    return text;
  } catch {
    try {
      protocol = 'https://';
      url = new URL(`${protocol}/${text}`);
    } catch {
      return '';
    }
  }

  const lastIndex = url.hostname.lastIndexOf('.');
  if (lastIndex < 0) {
    return '';
  }

  const domain = url.hostname.substring(lastIndex + 1);
  // xn--p1ai = рф
  if (new RegExp('^[a-zA-Z]{2,}$|^xn--p1ai$').test(domain)) {
    return `${protocol}${text}`;
  }

  return '';
};
