<div class="message-animation">
  <div class="message-animation__message-content message-content">
    <div class="message-animation__animation-wrapper" [class.tui-skeleton]="!video.src">
      <video
        #video
        tuiMedia
        autoplay=""
        loop=""
        playsinline=""
        [width]="(messageAnimation?.animation?.width || 0) < 220 ? messageAnimation?.animation?.width : 220"
        [src]="messageAnimationSource?.src | safe: 'url'"
      ></video>
    </div>
    <ng-content></ng-content>
  </div>
</div>
