import { Component, ChangeDetectionStrategy } from '@angular/core';

import { CAPTCHA_CONTAINER_ID } from '../../constants';

@Component({
  selector: 'app-captcha',
  templateUrl: './captcha.component.html',
  styleUrls: ['./captcha.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CaptchaComponent {
  readonly captchaContainerId = CAPTCHA_CONTAINER_ID;
}
