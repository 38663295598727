<form class="view-step-by-step" [formGroup]="form">
  <app-skeleton-wrapper [loading]="loading">
    <question formControlName="question"></question>
  </app-skeleton-wrapper>

  <div *ngIf="!loading" class="view-step-by-step__buttons">
    <button tuiButton type="button" appearance="secondary" [size]="tuiElementLargeSize" (click)="cancel()">
      {{ 'common.buttons.cancel' | translate }}
    </button>

    <div tuiGroup>
      <button
        *ngIf="!!currentQuestion?.previousQuestionId"
        tuiButton
        type="button"
        appearance="primary"
        [size]="tuiElementLargeSize"
        (click)="getQuestion(currentQuestion?.previousQuestionId)"
      >
        {{ 'common.buttons.return' | translate }}
      </button>

      <button
        *ngIf="!!currentQuestion?.nextQuestionId"
        tuiButton
        type="button"
        appearance="primary"
        [size]="tuiElementLargeSize"
        (click)="getQuestion(currentQuestion?.nextQuestionId)"
      >
        {{ 'common.buttons.next' | translate }}
      </button>
    </div>

    <button
      *ngIf="!currentQuestion?.nextQuestionId"
      tuiButton
      type="button"
      appearance="primary"
      [size]="tuiElementLargeSize"
      (click)="saveReplies()"
    >
      {{ 'components.questionsViewStepByStep.buttons.sendReplies' | translate }}
    </button>
  </div>
</form>

<ng-template #noData>
  <p>{{ 'common.labels.noData' | translate }}</p>
</ng-template>
