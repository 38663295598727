<div class="bot">
  <div class="block block_first">
    <div class="bot__photo">
      <tui-avatar
        [autoColor]="true"
        [rounded]="true"
        size="l"
        [text]="chat && chat.title ? chat.title : ''"
      ></tui-avatar>
    </div>
    <div class="bot__name">{{ chat?.title }}</div>
    <div class="bot__status">{{ this.botStatus }}</div>
  </div>
  <div *ngIf="organisation" class="block block_info">
    <mat-nav-list class="info__organisation">
      <mat-list-item [routerLink]="'/organisation/' + organisation.organisationId">
        <mat-icon matListIcon class="icon">business</mat-icon>
        <div matLine class="organisation__name">{{ organisation.organisationName }}</div>
        <div matLine class="label">{{ 'common.labels.organisation' | translate }}</div>
      </mat-list-item>
    </mat-nav-list>
  </div>
  <div class="block block_info">
    <mat-nav-list class="info__notifications">
      <mat-list-item (click)="onChangeNotificationsToggleValue()">
        <mat-slide-toggle
          matListIcon
          color="primary"
          [checked]="!!chat && chat.notificationSettings.muteFor === 0"
          [disabled]="true"
        ></mat-slide-toggle>
        <div matLine>{{ 'common.labels.notifications' | translate }}</div>
      </mat-list-item>
    </mat-nav-list>
  </div>
</div>
