import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { ChatMemberStatusUnion } from '@airgram/web';
import { TelegramMessengerService } from '@src/app/modules/telegram';
import { ChatModel, UserUI } from '@src/models';
import {
  UserService,
  SubscriptionsForUsersCommitteeService,
  SubscriptionsForUsersService,
  ChatsService,
  FileReaderService,
} from '@src/core/services';
import { UsersSearchParameters } from '@src/api';
import { getImageSrc } from '@src/utils';
import { ObjectId } from '@src/types/id';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-search-chats',
  templateUrl: './search-chats.component.html',
  styleUrls: ['./search-chats.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchChatsComponent implements OnInit {
  @Input() chatsFilter?: { chatType?: string[]; onlyUnionsChats?: boolean };
  @Input() chatSelectedId: ObjectId;
  @Output() chatSelectedChange: EventEmitter<ChatModel> = new EventEmitter();

  allChats: ChatModel[] = [];
  searchQuery: string = '';
  foundChats?: ChatModel[];

  private authUser?: UserUI;
  private users?: UserUI[];

  filter: UsersSearchParameters;

  constructor(
    private cdr: ChangeDetectorRef,
    private messengerService: TelegramMessengerService,
    private userService: UserService,
    private subscriptionsForUsersService: SubscriptionsForUsersService,
    private subscriptionsForUsersCommitteeService: SubscriptionsForUsersCommitteeService,
    private chatsService: ChatsService,
    private fileReaderService: FileReaderService,
  ) {
    this.filter = {
      name: '',
      searchForExactMatchName: false,
      searchForRegistredOnly: true,
    };
  }

  async ngOnInit(): Promise<void> {
    this.authUser = await this.userService.getAuthorizedUser();
    this.loadChats();
  }

  async searchQueryChangeHandler(searchQuery: string) {
    let searchChatIds: number[] = [];
    this.filter.name = searchQuery;

    if (searchQuery === '') {
      this.loadChats();
      this.cdr.markForCheck();
      return;
    }

    searchChatIds = this.allChats
      .filter(chat => chat.title.toLowerCase().includes(searchQuery.toLowerCase()))
      .map(chat => chat.id);

    await lastValueFrom(this.userService.searchUsers(this.filter))
      .then(users =>
        users.forEach(user => {
          if (user.telegramId && !searchChatIds.includes(user.telegramId)) {
            searchChatIds.push(user.telegramId);
          }
        }),
      )
      .then(() => {
        this.foundChats = this.allChats.filter(chat => searchChatIds.includes(chat.id));
        this.cdr.markForCheck();
      });
  }

  selectedChat(id?: ObjectId) {
    this.chatSelectedId = id;
    const chat = this.foundChats?.find(chat => chat.id === id);
    if (chat) {
      this.chatSelectedChange.emit(chat);
    }
  }

  private async loadChats(): Promise<void> {
    const allChatIds = await this.messengerService.getAllChatIds();
    const allChatIdsExceptYourself = allChatIds.filter(chatId => chatId !== this.authUser?.telegramId);

    const unionsChatIds = await lastValueFrom(this.chatsService.getChatsIdsData(allChatIdsExceptYourself));
    const organisationsSubscriptionsIds = await lastValueFrom(
      this.subscriptionsForUsersService.getSubscriptionsIdsData(),
    );
    const committeesSubscriptionsIds = await lastValueFrom(
      this.subscriptionsForUsersCommitteeService.getSubscriptionsIdsData(),
    );

    this.users = await lastValueFrom(this.userService.getUsersData(allChatIdsExceptYourself));

    this.allChats = [];

    let chatIds = this.chatsFilter?.onlyUnionsChats
      ? allChatIdsExceptYourself.filter(
          chatId =>
            organisationsSubscriptionsIds.includes(chatId) ||
            committeesSubscriptionsIds.includes(chatId) ||
            unionsChatIds.includes(chatId),
        )
      : allChatIdsExceptYourself;

    const unionsUserIds = this.users?.map(user => user.telegramId).filter(id => id !== undefined) as number[];
    if (unionsUserIds) {
      chatIds = chatIds.concat(unionsUserIds.filter(userId => chatIds.indexOf(userId) === -1));
    }

    await Promise.all(
      chatIds.map(async chatId => {
        if (!chatId) return;

        const chatDetails = await this.getChatDetails(chatId);
        if (this.chatsFilter?.chatType && !this.chatsFilter.chatType.includes(chatDetails.type._)) return;

        let chatMemberStatus: ChatMemberStatusUnion | undefined;
        if (chatDetails.type._ === 'chatTypeBasicGroup') {
          const basicGroup = await this.messengerService.api.getBasicGroup(chatDetails.type.basicGroupId);
          chatMemberStatus = basicGroup.status;
        }

        if (chatDetails.type._ === 'chatTypeSupergroup') {
          const supergroup = await this.messengerService.api.getSupergroup(chatDetails.type.supergroupId);
          chatMemberStatus = supergroup.status;
        }

        //TODO: fix
        if (
          chatMemberStatus?._ === 'chatMemberStatusCreator' ||
          (chatMemberStatus?._ === 'chatMemberStatusAdministrator' && chatMemberStatus.canPostMessages) ||
          chatDetails.permissions.canSendMessages ||
          chatDetails.type._ === 'chatTypePrivate'
        ) {
          this.allChats = [...this.allChats, chatDetails];
        }
      }),
    );

    this.foundChats = this.sortChats(this.allChats);
    this.cdr.markForCheck();
  }

  private async getChatDetails(chatId: number): Promise<ChatModel> {
    const chatDetails = await this.messengerService.api.getChat(chatId);

    if (chatDetails.photo?.small) {
      this.fileReaderService.getFilePart(chatDetails.photo.small).subscribe(filePart => {
        if (!chatDetails.userPhotoPath) {
          chatDetails.userPhotoPath = this.fileReaderService.getFileSource(filePart);
        }
        this.cdr.markForCheck();
      });
    }

    if (chatDetails.type._ === 'chatTypePrivate') {
      const telegramUser = await this.messengerService.api.getUser(chatId);
      chatDetails.userType = telegramUser.type;

      if (this.users) {
        const findUser = this.users.find(user => user.telegramId === chatId);

        if (findUser) {
          chatDetails.title = [findUser.lastName, findUser.firstName, findUser.middleName].join(' ');

          if (findUser.photoId) {
            chatDetails.userPhotoPath = getImageSrc(findUser.photoId);
          }

          const defaultOrganisationJobTitle = findUser.organisationJobTitles?.find(
            jobTitle => jobTitle.organisationId === findUser.organisationId,
          );
          if (defaultOrganisationJobTitle) {
            chatDetails.organisationJobTitles = [defaultOrganisationJobTitle];
          }
        }
      }
    }

    return chatDetails;
  }

  private sortChats(chats: ChatModel[]): ChatModel[] {
    return chats.sort((a, b) => {
      const lastMessageDateA = a.lastMessage?.date ?? 0;
      const lastMessageDateB = b.lastMessage?.date ?? 0;

      return lastMessageDateB - lastMessageDateA;
    });
  }
}
