import { Component, ChangeDetectionStrategy, Input, Inject, Injector } from '@angular/core';
import { PolymorpheusComponent } from '@tinkoff/ng-polymorpheus';
import { TuiDialogService } from '@taiga-ui/core';

import { PhotoSize } from '../types';
import { PhotoPreviewComponent } from '../photo-preview/photo-preview.component';

@Component({
  selector: 'photo-view',
  templateUrl: './photo-view.component.html',
  styleUrls: ['./photo-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PhotoViewComponent {
  @Input() text?: string;
  @Input() photoId?: string;
  @Input() unionId?: string;
  @Input() photoSrc?: string;
  @Input() size: PhotoSize = 'xxl';
  @Input() width?: string;
  @Input() height?: string;
  @Input() rounded: boolean = false;
  @Input() loading: boolean = false;

  constructor(
    @Inject(TuiDialogService) private readonly dialogService: TuiDialogService,
    @Inject(Injector) private readonly injector: Injector,
  ) {}

  onClick() {
    if (!this.photoId && !this.photoSrc) {
      return;
    }

    this.dialogService
      .open<void>(new PolymorpheusComponent(PhotoPreviewComponent, this.injector), {
        label: this.text,
        size: 'm',
        closeable: true,
        data: { photoSrc: this.photoSrc, photoId: this.photoId, unionId: this.unionId },
      })
      .subscribe();
  }
}
