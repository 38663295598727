import { Injectable } from '@angular/core';
import { TelegramWebAppService } from '@src/app/modules/telegram';
import { StorageKeys } from '@src/constants/storage';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  private isBot = this.telegramWebAppService.isRunAsTelegramWebApp();

  constructor(private readonly telegramWebAppService: TelegramWebAppService) {}

  setItem(key: StorageKeys, value: string): void {
    try {
      if (this.isBot) {
        window.sessionStorage.setItem(key, value);
      } else {
        window.localStorage.setItem(key, value);
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
    }
  }

  getItem(key: StorageKeys): string | null {
    try {
      if (this.isBot) {
        return window.sessionStorage.getItem(key);
      } else {
        return window.localStorage.getItem(key);
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);

      return null;
    }
  }

  removeItem(key: StorageKeys): void {
    try {
      if (this.isBot) {
        window.sessionStorage.removeItem(key);
      } else {
        window.localStorage.removeItem(key);
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
    }
  }
}
