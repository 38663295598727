import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  OnDestroy,
  TemplateRef,
  Optional,
  Self,
  Inject,
  ChangeDetectorRef,
  ViewChild,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { AbstractTuiControl, TUI_DEFAULT_MATCHER, TuiIdentityMatcher, TuiStringHandler } from '@taiga-ui/cdk';
import { UserUI } from '@src/models';
import { UserService } from '@src/core/services';
import { BehaviorSubject, Subject, lastValueFrom } from 'rxjs';
import { map, startWith, switchMap, takeUntil } from 'rxjs/operators';
import { Nullable } from '@src/types/utils';
import { NgControl } from '@angular/forms';
import { TuiComboBoxComponent, TuiMultiSelectComponent } from '@taiga-ui/kit';
import { NumeralPluralsPipe } from '@src/app/shared/pipes';
import { TranslateService } from '@ngx-translate/core';
import { UsersSearchParameters } from '@src/api';
import { TuiSizeL, TuiSizeS } from '@taiga-ui/core';

import { SearchUsersService } from '../../services/search-users.service';
import { DEFAULT_USERS_SEARCH_PARAMS } from '../../constants';
import { FilterFieldsOptions, SearchUsersInputValue, SearchUsersMode } from '../../types';

/**
 * Инпут для вставки в форму, если нужно найти пользователя
 *
 * @example
 * <search-users-input
 *   formControlName="person"
 *   label="Куратор"
 *   mode="single"
 *   [showFilterButton]="true"
 * ></search-users-input>
 *
 * <search-users-input
 *   formControlName="persons"
 *   label="Участники"
 *   mode="multiple"
 *   [pluralize]="['участник', 'участника', 'участников']"
 *   [showFilterButton]="true"
 *   [statusContent]="userStatusRender"
 * ></search-users-input>
 *
 * <ng-template #userStatusRender let-data>
 *   {{ data.id }}
 * </ng-template>
 */
@Component({
  selector: 'search-users-input',
  templateUrl: './search-users-input.component.html',
  styleUrls: ['./search-users-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchUsersInputComponent
  extends AbstractTuiControl<SearchUsersInputValue>
  implements OnInit, OnDestroy, OnChanges
{
  /** Лейбл инпута */
  @Input() label = '';

  @Input() pluralize: string[] = [];

  /**
   * Режим работы:
   * - single - выбор одной записи
   * - multiple - выбор нескольких записей
   * */
  @Input() mode: SearchUsersMode = 'multiple';

  @Input() filter?: UsersSearchParameters;

  /** Флаг отображения кнопки для отображения фильтра */
  @Input() showFilterButton = false;

  /** Поля, скрытые в фильтре */
  @Input() excludeFilterFields: FilterFieldsOptions[] = [];

  /** Название кнопки для отображения фильтра */
  @Input() filterButtonText = this.translateService.instant('common.labels.selectFromList');

  /** Шаблон для отображения статуса */
  @Input() statusContent?: TemplateRef<UserUI>;

  /** Id пользователей, исключённых из поиска */
  @Input() excludeUsersIds?: string[];

  /** TelegramId пользователей, исключённых из поиска */
  @Input() excludeUsersTelegramIds?: number[];

  @Input() textfieldSize: TuiSizeL | TuiSizeS = 'm';

  @ViewChild(TuiComboBoxComponent)
  private readonly singleInputRef?: TuiComboBoxComponent<UserUI>;

  @ViewChild(TuiMultiSelectComponent)
  private readonly multipleInputRef?: TuiMultiSelectComponent<UserUI[]>;
  private readonly destroyed$$ = new Subject<void>();

  readonly items$ = new BehaviorSubject<UserUI[]>([]);
  readonly search$ = new BehaviorSubject<string>('');
  readonly filter$ = new BehaviorSubject<UsersSearchParameters>(DEFAULT_USERS_SEARCH_PARAMS);
  readonly stringify: TuiStringHandler<UserUI> = item => item.fullName ?? '';
  readonly matcher: TuiIdentityMatcher<UserUI> = (item1, item2) => item1.id === item2.id;

  readonly filteredItems$ = this.search$.pipe(
    startWith(''),
    switchMap(search =>
      this.items$.pipe(
        map(items => {
          return items.filter(({ fullName }) => TUI_DEFAULT_MATCHER(fullName, search));
        }),
      ),
    ),
  );

  constructor(
    private readonly userService: UserService,
    private readonly searchUsersService: SearchUsersService,
    private readonly plural: NumeralPluralsPipe,
    private readonly translateService: TranslateService,
    @Optional()
    @Self()
    @Inject(NgControl)
    control: NgControl | null,
    @Inject(ChangeDetectorRef) changeDetectorRef: ChangeDetectorRef,
  ) {
    super(control, changeDetectorRef);
  }

  get title(): string {
    if (this.mode === 'multiple' && this.pluralize.length > 0 && Array.isArray(this.value) && this.value.length > 0) {
      return this.plural.transform(this.value.length, this.pluralize);
    }

    return this.label;
  }

  get focused(): boolean {
    if (this.mode === 'single' && this.singleInputRef) {
      return this.singleInputRef.focused;
    }

    if (this.mode === 'multiple' && this.multipleInputRef) {
      return this.multipleInputRef.focused;
    }

    return false;
  }

  protected getFallbackValue(): SearchUsersInputValue {
    // TODO: this.mode === undefined. Возможно this.mode не успевает подтянуться
    if (this.mode === 'single') {
      return {};
    }

    return [];
  }

  ngOnInit(): void {
    this.filter$.pipe(takeUntil(this.destroyed$$)).subscribe(filter => {
      this.search(filter);
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.filter) {
      if (this.filter) {
        this.filter = {
          ...DEFAULT_USERS_SEARCH_PARAMS,
          ...this.filter,
        };
      } else {
        this.filter = DEFAULT_USERS_SEARCH_PARAMS;
      }

      this.filter$.next(this.filter);
    }

    this.cdr.markForCheck();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();

    this.destroyed$$.next();
    this.destroyed$$.complete();
  }

  setValue(user: UserUI) {
    this.value = user;
  }

  getValue() {
    if (this.mode === 'single') {
      return (this.value as UserUI).id ? this.value : null;
    }

    return this.value;
  }

  onSearchChange(search: Nullable<string>) {
    this.search$.next(search ?? '');
  }

  onSearchUsers() {
    this.searchUsersService
      .search(
        this.value,
        this.mode,
        this.statusContent,
        this.excludeUsersIds,
        this.excludeUsersTelegramIds,
        this.filter,
        this.excludeFilterFields,
      )
      .pipe(takeUntil(this.destroyed$$))
      .subscribe(result => {
        if (this.mode === 'multiple') {
          this.value = result;
        } else {
          this.value = result[0];
        }

        this.cdr.markForCheck();
      });
  }

  private async search(filter: UsersSearchParameters) {
    let items = await lastValueFrom(this.userService.searchUsers(filter));

    if (this.excludeUsersIds?.length) {
      items = items.filter(({ id }) => id && !this.excludeUsersIds?.includes(id));
    }

    if (this.excludeUsersTelegramIds?.length) {
      items = items.filter(({ telegramId }) => {
        if (!telegramId) return true;

        return !this.excludeUsersTelegramIds?.includes(telegramId);
      });
    }

    this.items$.next(items);
    this.cdr.markForCheck();
  }
}
