<app-event-info-view
  *ngIf="mode === 'view'"
  [mode]="mode"
  [data]="data$ | async"
  [documentsList]="documentsList$ | async"
  [membersList]="membersList$ | async"
  [allowEditing]="allowEditing$ | async"
  [allowReportViewing]="allowReportViewing$ | async"
  [externalLoading]="loading"
  [recallButtonLoading]="recallButtonLoading"
  (getMembersFile)="getMembersFile($event)"
  (startEditing)="onStartEditing()"
  (copyEvent)="onCopyEvent()"
  (eventDeleted)="onDeleteEvent($event)"
  (deactivated)="onDeactivateEvent($event)"
  (accept)="acceptEvent($event)"
  (decline)="declineEvent($event)"
  (toCalendar)="addEventToCalendar($event)"
  (recallMembers)="resendNotifications($event)"
  (startReport)="onStartReport($event)"
></app-event-info-view>

<app-event-info-edit
  *ngIf="mode === 'edit' || mode === 'create'"
  [mode]="mode"
  [data]="data$ | async"
  [documentsList]="documentsList$ | async"
  [membersList]="membersList$ | async"
  [allowEditing]="allowEditing$ | async"
  [externalLoading]="loading"
  (saved)="onSaveData($event)"
  (canceled)="onCancel()"
></app-event-info-edit>

<ng-template #confirmEditDialogTemplate let-observer>
  <tui-checkbox-labeled
    class="tui-space_bottom-3 margin-auto"
    size="m"
    [(ngModel)]="notifyEveryone"
    [disabled]="resetEvent"
  >
    {{ 'components.eventInfo.dialogs.notifyEveryoneLabel' | translate }}
  </tui-checkbox-labeled>
  <tui-checkbox-labeled
    class="tui-space_bottom-3 margin-auto"
    size="m"
    [(ngModel)]="resetEvent"
    (ngModelChange)="resetEvent ? (notifyEveryone = true) : ''"
  >
    {{ 'components.eventInfo.dialogs.resetRegistrationLabel' | translate }}
  </tui-checkbox-labeled>
  <button tuiButton type="button" size="s" class="tui-space_top-3" (click)="observer.complete()">
    {{ 'components.eventInfo.buttons.confirm' | translate }}
  </button>
</ng-template>
