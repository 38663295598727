<div class="fields">
  <tui-input
    *ngFor="let item of items"
    class="fields__item"
    [tuiTextfieldSize]="size"
    [tuiTextfieldCleaner]="true"
    [(ngModel)]="item.id"
    (ngModelChange)="onChangeContact()"
  >
    {{ item.label }}
  </tui-input>
</div>
