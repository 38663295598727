import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';
import { Subject } from 'rxjs';
import { TuiContextWithImplicit, tuiPure, TuiStringHandler } from '@taiga-ui/cdk';
import { ResizableBaseComponent } from '@src/app/components/resizable-base-component';
import { BreakpointObserverHelperService } from '@src/core/services';
import { BusinessTypeUI } from '@src/models';
import { OPFType, OrganisationWithInvoiceIdView } from '@src/api';

@Component({
  selector: 'app-organisation-info-view',
  templateUrl: './organisation-info-view.component.html',
  styleUrls: ['./organisation-info-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrganisationInfoViewComponent extends ResizableBaseComponent implements OnChanges {
  @Input() data?: OrganisationWithInvoiceIdView | null;
  @Input() opfTypes?: OPFType[] | null;
  @Input() businessTypes?: BusinessTypeUI[] | null;
  @Input() allowSpecialFieldsViewing?: boolean | null = false;
  @Input() allowSpecialFieldsForAssociationViewing?: boolean | null = false;
  @Input() allowEditing?: boolean | null = false;
  @Input() allowDeleting?: boolean | null = false;
  @Output() startEditing: EventEmitter<void>;
  @Output() deleted: EventEmitter<void> = new EventEmitter<void>();

  loading: boolean = true;
  organisationBusinessTypes?: BusinessTypeUI[] | null;

  constructor(
    readonly cdr: ChangeDetectorRef,
    readonly breakpointObserver: BreakpointObserver,
    readonly breakpointObserverHelperService: BreakpointObserverHelperService,
  ) {
    super(cdr, breakpointObserver, breakpointObserverHelperService);

    this.startEditing = new EventEmitter<void>();
    this.destroyed$$ = new Subject<void>();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.data) {
      this.loading = true;

      if (this.data?.id) {
        this.normalizeData();
        this.loading = false;
      }
    }

    if (changes.businessTypes) {
      if (this.businessTypes) {
        this.normalizeData();
      }
    }

    this.cdr.markForCheck();
  }

  onClickEditButton(): void {
    this.startEditing.emit();
  }

  onClickDeleteButton(): void {
    this.deleted.emit();
  }

  readonly stringifyBusinessTypes: TuiStringHandler<any | TuiContextWithImplicit<any>> = item => {
    return 'businessTypeName' in item ? item?.businessTypeName : item?.$implicit?.businessTypeName;
  };

  @tuiPure
  opfTypesStringify(items: ReadonlyArray<OPFType>): TuiStringHandler<TuiContextWithImplicit<number>> {
    const map = new Map(items.map(({ id, name }) => [id, name] as [number, string]));

    return ({ $implicit }: TuiContextWithImplicit<number>) => map.get($implicit) || '';
  }

  private normalizeData() {
    if (!this.data) return;

    this.organisationBusinessTypes = this.businessTypes?.filter(
      businessType =>
        this.data?.businessTypes &&
        this.data.businessTypes.findIndex(
          organisationBusinessType => organisationBusinessType.id === businessType.businessTypeId,
        ) > -1,
    );
  }
}
