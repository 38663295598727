<div class="reports">
  <div class="reports-item" *ngFor="let report of reports">
    <div class="reports-item__title">{{ 'components.reports.labels.report' | translate }}: {{ report.title }}</div>
    <div class="reports-item__description">{{ report.description }}</div>
    <button class="reports-item__button" tuiButton [size]="'s'" [appearance]="'primary'" (click)="report.action()">
      {{ 'common.buttons.download' | translate }}
    </button>
  </div>

  <app-reports [reportType]="'association'"></app-reports>
</div>
