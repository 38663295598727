import { NgModule, LOCALE_ID, APP_INITIALIZER, InjectionToken } from '@angular/core';
import { APP_BASE_HREF, registerLocaleData } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import localeRu from '@angular/common/locales/ru';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LayoutModule } from '@angular/cdk/layout';
import { MatSidenavModule } from '@angular/material/sidenav';
import { TUI_DATE_TIME_VALUE_TRANSFORMER, tuiToggleOptionsProvider } from '@taiga-ui/kit';
import { TUI_LANGUAGE } from '@taiga-ui/i18n';
import { TuiAccordionModule, TuiDataListWrapperModule, TuiInputModule, TuiSelectModule } from '@taiga-ui/kit';
import {
  TuiAlertModule,
  TuiButtonModule,
  TuiDataListModule,
  TuiDialogModule,
  TuiRootModule,
  TuiScrollbarModule,
  TuiTextfieldControllerModule,
  tuiIconsPathFactory,
  TUI_ICONS_PATH,
  TuiThemeNightModule,
  TuiModeModule,
} from '@taiga-ui/core';
import { TuiMobileCalendarDialogModule } from '@taiga-ui/addon-mobile';
import { NgxPermissionsModule } from 'ngx-permissions';
import { QRCodeModule } from 'angularx-qrcode';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NgxScrollPositionRestorationModule } from 'ngx-scroll-position-restoration';
import {
  AuthModule,
  TokenInterceptor,
  AuthGuard,
  UnAuthGuard,
  SessionService,
  RedirectGuard,
  StorageService,
} from '@src/app/modules/auth';
import {
  PreferencesService,
  DynamicPermissionsService,
  CordovaService,
  PreviousRouteService,
} from '@src/core/services';
import { AppLayoutComponent } from '@src/app/app-layout';
import { PrivacyPageComponent } from '@src/app/pages/privacy-page';
import {
  BusinessTypesService,
  BusinessTypesForUnionService,
  ChatsService,
  CommitteesService,
  CommitteeSubscriptionsService,
  CommitteeUserSubscriptionsService,
  DashboardService,
  DocumentsService,
  EventService,
  AppService,
  JobTitlesService,
  JobTitlesForUnionService,
  InvitesService,
  LabelItemsService,
  OPFTypesService,
  OrganisationService,
  OrganisationSettingsService,
  PhotoService,
  PollsService,
  RoleService,
  SubscriptionsForOrganisationService,
  SubscriptionsForUsersService,
  TestService,
  TokenService,
  UserService,
  MailsService,
  ObjectMenuService,
  LoyaltyService,
  BASE_PATH,
} from '@src/api';
import { TuiDayTimeTransformer } from '@src/utils';
import { SharedModule } from '@src/app/shared';
import { GroupInfoModule } from '@src/app/modules/group-info';
import { LoginModule } from '@src/app/modules/login';
import { NAVIGATION_ITEMS, NavigationItem, NavigationModule } from '@src/app/modules/navigation';
import { PageNotFoundModule } from '@src/app/pages/page-not-found';
import { BrandingModule } from '@src/app/modules/branding';
import { IconModule } from '@src/app/modules/icon';
import { APP_CONFIG, Language, CoreModule } from '@src/core';
import { TranslateModule, TranslateLoader, MissingTranslationHandler, TranslateService } from '@ngx-translate/core';
import { HttpLoaderFactory, appInitializerFactory, tuiLanguageFactory } from '@src/utils/localization';

import { MissingTranslationService } from '../core/services/missing-translation-service';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ComponentsModule } from './components';
import { CustomNamesService } from './modules/custom-name-tabs';
import { PolicyPageModule } from './pages/policy-page';

export const NAVIGATION_ITEMS_TOKEN = new InjectionToken<NavigationItem[]>('NAVIGATION.ITEMS');

registerLocaleData(localeRu, Language.RU);

@NgModule({
  declarations: [AppComponent, AppLayoutComponent, PrivacyPageComponent],
  imports: [
    AppRoutingModule,
    NgxScrollPositionRestorationModule.forRoot(),
    BrandingModule,
    BrowserModule,
    BrowserAnimationsModule,
    CoreModule,
    FormsModule,
    GroupInfoModule,
    HttpClientModule,
    IconModule,
    LayoutModule,
    LoginModule,
    MatSidenavModule,
    NavigationModule,
    NgxPermissionsModule.forRoot(),
    PageNotFoundModule,
    SharedModule,
    TuiAccordionModule,
    TuiAlertModule,
    TuiButtonModule,
    TuiDataListModule,
    TuiDataListWrapperModule,
    TuiDialogModule,
    TuiInputModule,
    TuiMobileCalendarDialogModule,
    TuiRootModule,
    TuiScrollbarModule,
    TuiSelectModule,
    TuiTextfieldControllerModule,
    TuiThemeNightModule,
    TuiModeModule,
    ComponentsModule,
    AuthModule,
    QRCodeModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
      missingTranslationHandler: { provide: MissingTranslationHandler, useClass: MissingTranslationService },
      useDefaultLang: false,
    }),
    InfiniteScrollModule,
    PolicyPageModule,
  ],
  providers: [
    SessionService,
    CustomNamesService,
    PreferencesService,
    DynamicPermissionsService,
    PreviousRouteService,
    {
      provide: APP_INITIALIZER,
      useFactory:
        (
          session: SessionService,
          customNames: CustomNamesService,
          preferences: PreferencesService,
          dynamicPermissions: DynamicPermissionsService,
          cordovaService: CordovaService,
          previousRouteService: PreviousRouteService,
        ) =>
        async () => {
          session.addCallbackAfterCreateSession(customNames.getData.bind(customNames));
          session.addCallbackAfterCreateSession(preferences.getData.bind(preferences));
          session.addCallbackAfterCreateSession(dynamicPermissions.setData.bind(dynamicPermissions));
          session.addCallbackAfterCreateSession(cordovaService.init.bind(cordovaService));
          session.addCallbackAfterCreateSession(previousRouteService.init.bind(previousRouteService));

          return session.init();
        },
      deps: [
        SessionService,
        CustomNamesService,
        PreferencesService,
        DynamicPermissionsService,
        CordovaService,
        PreviousRouteService,
      ],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFactory,
      deps: [TranslateService, StorageService],
      multi: true,
    },
    { provide: NAVIGATION_ITEMS_TOKEN, useValue: NAVIGATION_ITEMS },
    {
      provide: BASE_PATH,
      useValue: APP_CONFIG.baseUrl,
    },
    { provide: LOCALE_ID, useValue: Language.RU },
    { provide: APP_BASE_HREF, useValue: '/' },
    { provide: TUI_ICONS_PATH, useValue: tuiIconsPathFactory('assets/taiga-ui/icons/') },
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    {
      provide: TUI_DATE_TIME_VALUE_TRANSFORMER,
      useClass: TuiDayTimeTransformer,
    },
    {
      provide: TUI_LANGUAGE,
      useFactory: tuiLanguageFactory,
      deps: [TranslateService],
    },
    tuiToggleOptionsProvider({
      icons: {
        toggleOff: ({ $implicit }) => ($implicit === 'l' ? 'tuiIconToggleOff' : ''),
        toggleOn: ({ $implicit }) => ($implicit === 'l' ? 'tuiIconCheck' : ''),
      },
      showIcons: true,
    }),
    AuthGuard,
    UnAuthGuard,
    BusinessTypesService,
    BusinessTypesForUnionService,
    ChatsService,
    CommitteesService,
    CommitteeSubscriptionsService,
    CommitteeUserSubscriptionsService,
    DashboardService,
    DocumentsService,
    EventService,
    AppService,
    RedirectGuard,
    JobTitlesService,
    JobTitlesForUnionService,
    InvitesService,
    LabelItemsService,
    OPFTypesService,
    OrganisationService,
    OrganisationSettingsService,
    PhotoService,
    PollsService,
    RoleService,
    SubscriptionsForOrganisationService,
    SubscriptionsForUsersService,
    TestService,
    TokenService,
    UserService,
    MailsService,
    ObjectMenuService,
    LoyaltyService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
