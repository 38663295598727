import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, lastValueFrom, Observable, Subject, throwError } from 'rxjs';
import { catchError, map, takeUntil } from 'rxjs/operators';
import { Chat } from '@airgram/web';
import { TelegramMessengerService } from '@src/app/modules/telegram';
import { pause } from '@src/utils';
import {
  SubscriptionsForOrganisationView,
  SubscriptionsForUsersService as ApiSubscriptionsForUsersService,
} from '@src/api';

@Injectable({
  providedIn: 'root',
})
export class SubscriptionsForUsersService implements OnDestroy {
  subscriptions$: BehaviorSubject<SubscriptionsForOrganisationView[] | null>;
  subscriptionsChatIds$: BehaviorSubject<number[]>;
  allChatIds$ = this.messengerService.allChatsIds$;

  private destroyed$$: Subject<void> = new Subject<void>();

  constructor(
    private subscriptionsForUsersService: ApiSubscriptionsForUsersService,
    private messengerService: TelegramMessengerService,
  ) {
    this.subscriptions$ = new BehaviorSubject<SubscriptionsForOrganisationView[] | null>(null);
    this.subscriptionsChatIds$ = new BehaviorSubject<number[]>([]);
    this.subscriptions$.pipe(takeUntil(this.destroyed$$)).subscribe(subscriptions => {
      const chatIds = subscriptions?.map(subscription => subscription.chatId!);
      this.subscriptionsChatIds$.next(chatIds ?? []);
    });
  }

  ngOnDestroy(): void {
    this.destroyed$$.next();
    this.destroyed$$.complete();
  }

  getSubscriptions(callback?: (value: boolean) => void): void {
    callback?.(true);

    this.subscriptionsForUsersService
      .userSubscriptions()
      .pipe(
        catchError(err => {
          // TODO show error notification
          callback?.(false);
          return throwError(err);
        }),
        map(subscriptions => this.sortingSubscriptions(subscriptions)),
        takeUntil(this.destroyed$$),
      )
      .subscribe(subscriptions => {
        this.subscriptions$.next(subscriptions);
        callback?.(false);
      });
  }

  subscriptionsChatIds(): void {
    this.getSubscriptions();
  }

  getSubscriptionsIdsData(): Observable<number[]> {
    return this.subscriptionsForUsersService.userSubscriptions().pipe(
      catchError(err => {
        // TODO show error notification
        return throwError(err);
      }),
      map(
        subscriptions =>
          subscriptions.map(subscription => subscription.chatId).filter(id => id !== undefined) as number[],
      ),
    );
  }

  addSubscriptions(channelsIds: string[]) {
    return this.subscriptionsForUsersService.addUserSubscriptionList(channelsIds);
  }

  deleteSubscription(channelId: string) {
    return this.subscriptionsForUsersService.deleteSubscription(channelId);
  }

  //TODO: Возможно лучше переделать на массив чатов. Иначе при каждой подписке вызывается getSubscriptions(). Это плохо при автоподписке - onSubscribeToAssociationChats() в setting.service.ts
  async subscribeChannel(channelId: string, chatId: number, inviteLink: string) {
    let chat: Chat | undefined;

    if (!this.allChatIds$.value.includes(chatId)) {
      try {
        chat = await this.messengerService.api.joinChatByInviteLink(inviteLink);
      } catch (error) {
        throwError(error);
        if ((error as any).message !== 'USER_ALREADY_PARTICIPANT') return;
      }
    }

    this.messengerService.addChatId(chatId);

    if (this.allChatIds$.value.includes(chatId)) {
      try {
        await lastValueFrom(this.addSubscriptions([channelId]));
      } catch (error) {
        throwError(error);
      }
    }

    this.getSubscriptions();
    await pause(3000); // TODO: for fix blocked telegram API

    return chat;
  }

  async unsubscribeChannel(channelId: string, chatId: number, userId: number) {
    try {
      await this.messengerService.api.setChatMemberStatus(
        chatId,
        {
          _: 'messageSenderUser',
          userId,
        },
        { _: 'chatMemberStatusLeft' },
      );
    } catch (error) {
      throwError(error);
    }

    this.messengerService.removeChatId(chatId);

    try {
      await lastValueFrom(this.deleteSubscription(channelId));
    } catch (error) {
      throwError(error);
    }

    this.getSubscriptions();
  }

  resetSubscriptions() {
    this.subscriptions$.next(null);
  }

  resetAll() {
    this.resetSubscriptions();
  }

  private sortingSubscriptions(subscriptions: SubscriptionsForOrganisationView[]): SubscriptionsForOrganisationView[] {
    subscriptions.sort((subscription1, subscription2) => {
      const subscriptionName1 = subscription1.name?.toLowerCase();
      const subscriptionName2 = subscription2.name?.toLowerCase();

      if (!subscriptionName1) return 1;
      if (!subscriptionName2) return -1;

      if (subscriptionName1 < subscriptionName2) return -1;
      if (subscriptionName1 > subscriptionName2) return 1;
      return 0;
    });

    return subscriptions;
  }
}
