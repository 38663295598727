/**
 * Unions API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Настройки уведомлений пользователя.
 */
export interface NotificationEventsOptionsModel { 
    /**
     * Код.
     */
    readonly notificationEventCode?: string;
    /**
     * Заголовок события для вывода в настройках.
     */
    title?: string;
    /**
     * Описание.
     */
    description?: string;
    /**
     * Оправлять по email?
     */
    sendByEmail?: boolean;
    /**
     * Отправлять в бот?
     */
    sendByBot?: boolean;
    /**
     * Отправлять пуши?
     */
    sendByPush?: boolean;
    /**
     * Настройки по-умолчанию – сервер сам решает как отправить уведомление.
     */
    sendByDefault?: boolean;
}

