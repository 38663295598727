import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

import { HeaderOptions } from './types';

/**
 * Компонент для отображения заголовка блока
 * @param options {@link HeaderOptions} - опции для отображения заголовка
 *
 * @see https://www.figma.com/file/tdtoaaGrQa3XOG9Qqv96VI/Unions-(Taiga-3.0)?mode=dev
 */
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class HeaderComponent {
  @Input() options: HeaderOptions = {
    title: '',
  };
}
