export type ParsedTextType = 'text' | 'url' | 'email' | 'new-line' | 'space' | 'phone';

export type HTMLStructure = {
  /** Тип HTML элемента */
  type: ParsedTextType;

  /** Значение */
  value?: string;

  /** Модифицированная ссылка */
  url?: string;
};
