import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'url',
})
export class UrlPipe implements PipeTransform {
  transform(value?: string | null): string | undefined {
    value = value?.trim();

    if (!value) return;

    if (
      value.startsWith('http://') ||
      value.startsWith('https://') ||
      value.startsWith('tel:') ||
      value.startsWith('mailto:')
    ) {
      return value;
    }

    value = `https://${value}`;
    return value;
  }
}
