import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { formatDate } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';

import { BirthdayTagColor } from './types';

@Component({
  selector: 'birthdays-tag',
  templateUrl: './tag.component.html',
  styleUrls: ['./tag.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TagComponent {
  @Input() set value(date: Date | string) {
    this.text = formatDate(date, 'dd MMMM', this.translateService.currentLang);

    const currentDate = new Date();
    const dateNormalize = new Date(date);
    if (dateNormalize.getDate() === currentDate.getDate() && dateNormalize.getMonth() === currentDate.getMonth()) {
      this.color = 'yellow';
    } else {
      this.color = 'gray';
    }
  }

  text?: string;
  color?: BirthdayTagColor;

  constructor(private readonly translateService: TranslateService) {}
}
