import {
  Component,
  ChangeDetectionStrategy,
  Input,
  ChangeDetectorRef,
  Inject,
  Injector,
  OnDestroy,
} from '@angular/core';
import { lastValueFrom, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { WebPage } from '@airgram/web';
import { TuiDialogService } from '@taiga-ui/core';
import { PolymorpheusComponent } from '@tinkoff/ng-polymorpheus';
import { DialogImageViewingComponent } from '@src/app/shared/dialogs';
import { FileReaderService } from '@src/core/services';

@Component({
  selector: 'app-web-page',
  templateUrl: './web-page.component.html',
  styleUrls: ['./web-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WebPageComponent implements OnDestroy {
  @Input() set value(webPage: WebPage) {
    if (!webPage) return;

    this.webPage = webPage;
    const photo = webPage.photo?.sizes[1]?.photo ?? webPage.photo?.sizes[0]?.photo;
    if (photo) {
      this.fileReaderService.getFilePart(photo).subscribe(filePart => {
        this.photoPath = this.fileReaderService.getFileSource(filePart);
        this.cdr.markForCheck();
      });
    }
  }

  photoPath?: string;
  webPage?: WebPage;

  private destroyed$$: Subject<void>;

  constructor(
    private cdr: ChangeDetectorRef,
    private fileReaderService: FileReaderService,
    @Inject(TuiDialogService) private readonly dialogService: TuiDialogService,
    @Inject(Injector) private readonly injector: Injector,
  ) {
    this.destroyed$$ = new Subject<void>();
  }

  ngOnDestroy(): void {
    this.destroyed$$.next();
    this.destroyed$$.complete();
  }

  getSmallDescription(text?: string) {
    if (!text) return '';
    if (text.length <= 170) return text;
    return text.substring(0, 170) + '...';
  }

  viewImage() {
    const bigPhoto =
      this.webPage?.photo?.sizes[2]?.photo ??
      this.webPage?.photo?.sizes[1]?.photo ??
      this.webPage?.photo?.sizes[0]?.photo;
    if (bigPhoto) {
      this.fileReaderService
        .getFilePart(bigPhoto)
        .pipe(takeUntil(this.destroyed$$))
        .subscribe(filePart => {
          const bigPhotoSrc = this.fileReaderService.getFileSource(filePart);
          // TODO: replace to TuiPreviewModule
          lastValueFrom(
            this.dialogService.open<boolean>(new PolymorpheusComponent(DialogImageViewingComponent, this.injector), {
              size: 'l',
              data: bigPhotoSrc,
            }),
          );
        });
    }
  }
}
