export { NotificationsComponent } from './notifications/notifications.component';
export { SettingsComponent } from './settings.component';
export { SupportPageComponent } from './support-page';
export { SettingsModule } from './settings.module';
export { LicensesPageComponent } from './licenses-page/licenses-page.component';
export { ChangelogPageComponent } from './changelog-page/changelog-page.component';
export { PreferencesComponent } from './preferences';
export { ProfileComponent } from './profile';
export { AdministrationComponent, AdministrationModule } from './administration';
export { LanguageComponent } from './language/language.component';
export { ThemeComponent } from './theme/theme.component';
