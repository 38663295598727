<div *ngIf="mode === 'view'" class="field field_size_{{ size ? size : 'l' }}">
  <div class="field__name" [title]="fileName">{{ fileName }}</div>
  <div class="field__value">
    <ng-container *ngIf="fileId; else fileLoadingTmpl">
      <ng-container [ngSwitch]="fileType">
        <div *ngSwitchCase="'photo'" class="photo">
          <app-photo height="400px" width="100%" [documentId]="fileId"></app-photo>
          <button tuiButton size="s" class="photo__action" appearance="secondary" (click)="downloadDocument()">
            {{ 'common.buttons.download' | translate }}
          </button>
        </div>

        <app-download-link *ngSwitchDefault [fileId]="fileId" [pseudo]="false" [isLocalFile]="false" [showIcon]="false">
          {{ 'common.buttons.download' | translate }}
        </app-download-link>
      </ng-container>
    </ng-container>

    <ng-template #fileLoadingTmpl>
      <span class="field__loading">{{ 'common.labels.loading' | translate }}&hellip;</span>
    </ng-template>
  </div>
</div>
