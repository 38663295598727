<div class="organisation-info-view">
  <div class="organisation-info-view__basic">
    <app-photo
      *ngIf="!!data?.photoId"
      class="field image"
      [class.tui-skeleton]="loading"
      [class.tui-skeleton_text]="loading"
      [text]="data?.shortName"
      [photoId]="data?.photoId"
      [size]="tuiElementDoubleExtraLargeSize"
      [rounded]="true"
      [loading]="loading"
    ></app-photo>

    <app-field
      class="field"
      tabindex="1"
      [name]="'components.organisationInfo.fields.fullName' | translate"
      [class.tui-skeleton]="loading"
      [class.tui-skeleton_text]="loading"
      [typeName]="'text'"
      [value]="data?.fullName"
      [size]="tuiElementLargeSize"
    ></app-field>

    <app-field
      class="field"
      tabindex="2"
      [name]="'components.organisationInfo.fields.shortName' | translate"
      [class.tui-skeleton]="loading"
      [class.tui-skeleton_text]="loading"
      [typeName]="'text'"
      [value]="data?.shortName"
      [size]="tuiElementLargeSize"
    ></app-field>

    <tui-input-number
      *ngIf="!data?.parentOrganisation && data?.numberOfOrganisations"
      class="field"
      tabindex="3"
      [ngModel]="data?.numberOfOrganisations"
      [tuiTextfieldSize]="tuiElementLargeSize"
      [class.tui-skeleton]="loading"
      [class.tui-skeleton_text]="loading"
      [min]="1"
      [readOnly]="true"
    >
      {{ 'components.organisationInfo.fields.numberOfOrganisations' | translate }}
    </tui-input-number>
  </div>
  <div class="organisation-info-view__extended">
    <tui-select
      *ngIf="data?.extended?.opfTypeId"
      class="field"
      tabindex="4"
      [ngModel]="data?.extended?.opfTypeId"
      [valueContent]="opfTypes ? opfTypesStringify(opfTypes) : ''"
      [tuiTextfieldSize]="tuiElementLargeSize"
      [class.tui-skeleton]="loading"
      [class.tui-skeleton_text]="loading"
      [readOnly]="true"
    >
      {{ 'components.organisationInfo.fields.opfType' | translate }}
      <tui-data-list *tuiDataList>
        <button *ngFor="let opfType of opfTypes" tuiOption [value]="opfType.id">
          {{ opfType.name }}
        </button>
      </tui-data-list>
    </tui-select>

    <app-field
      *ngIf="data?.extended?.addressPhysical"
      class="field"
      tabindex="5"
      [name]="'components.organisationInfo.fields.addressPhysical' | translate"
      [class.tui-skeleton]="loading"
      [class.tui-skeleton_text]="loading"
      [typeName]="'text'"
      [value]="data?.extended?.addressPhysical"
      [size]="tuiElementLargeSize"
    ></app-field>

    <app-field
      *ngIf="data?.extended?.addressLegal"
      class="field"
      tabindex="6"
      [name]="'components.organisationInfo.fields.addressLegal' | translate"
      [class.tui-skeleton]="loading"
      [class.tui-skeleton_text]="loading"
      [typeName]="'text'"
      [value]="data?.extended?.addressLegal"
      [size]="tuiElementLargeSize"
    ></app-field>

    <tui-multi-select
      *ngIf="organisationBusinessTypes?.length"
      class="field"
      tabindex="7"
      [ngModel]="organisationBusinessTypes"
      [class.tui-skeleton]="loading"
      [class.tui-skeleton_text]="loading"
      [tuiTextfieldSize]="tuiElementLargeSize"
      [tuiTextfieldCleaner]="true"
      [expandable]="true"
      [editable]="false"
      [stringify]="stringifyBusinessTypes"
      [readOnly]="true"
    >
      {{ 'components.organisationInfo.fields.businessTypes' | translate }}
      <tui-data-list *tuiDataList tuiMultiSelectGroup>
        <button *ngFor="let businessType of businessTypes" tuiOption [value]="businessType">
          {{ businessType.businessTypeName }}
        </button>
      </tui-data-list>
    </tui-multi-select>

    <tui-input-date
      *ngIf="data?.membershipPaidTill && data?.parentOrganisation && allowSpecialFieldsForAssociationViewing"
      class="field"
      tabindex="8"
      [ngModel]="data?.membershipPaidTill | dateToTuiDateTime : 'date'"
      [class.tui-skeleton]="loading"
      [class.tui-skeleton_text]="loading"
      [tuiTextfieldSize]="tuiElementLargeSize"
      [readOnly]="true"
    >
      {{ 'components.organisationInfo.fields.membershipPaidTill' | translate }}
    </tui-input-date>

    <app-field
      *ngIf="data?.cardId"
      tabindex="9"
      [name]="'components.organisationInfo.fields.companyCard' | translate"
      [class.tui-skeleton]="loading"
      [class.tui-skeleton_text]="loading"
      [typeName]="'file'"
      [value]="data?.cardId"
      [size]="tuiElementLargeSize"
    ></app-field>

    <app-field
      *ngIf="data?.invoiceId && allowSpecialFieldsForAssociationViewing"
      tabindex="10"
      [name]="'common.labels.invoice' | translate"
      [class.tui-skeleton]="loading"
      [class.tui-skeleton_text]="loading"
      [typeName]="'file'"
      [value]="data?.invoiceId"
      [size]="tuiElementLargeSize"
    ></app-field>
  </div>

  <div class="organisation-info-view__contacts">
    <div *ngFor="let contact of data?.contacts" class="contact">
      <app-field
        *ngIf="contact.contact"
        [class.tui-skeleton]="loading"
        [class.tui-skeleton_text]="loading"
        [typeId]="contact.contactTypeId"
        [name]="contact.description"
        [value]="contact.contact"
        [size]="tuiElementLargeSize"
      ></app-field>
    </div>
  </div>

  <div *ngIf="!loading" class="organisation-info-view__buttons">
    <button
      *ngIf="allowEditing"
      tuiButton
      class="organisation-info-view-button"
      tabindex="11"
      type="button"
      appearance="primary"
      [size]="tuiElementLargeSize"
      (click)="onClickEditButton()"
    >
      {{ 'common.buttons.edit' | translate }}
    </button>
    <button
      *ngIf="this.data?.parentOrganisation && allowDeleting"
      tuiButton
      class="organisation-info-view-button"
      tabindex="12"
      type="button"
      appearance="accent"
      [size]="tuiElementLargeSize"
      (click)="onClickDeleteButton()"
    >
      {{ 'common.buttons.delete' | translate }}
    </button>
  </div>
</div>
