<div class="new-association">
  <div class="new-association__content content">
    <div class="content__header">
      <h1 class="content__title">
        {{
          step === 'association'
            ? ('components.administration.labels.menuNewAssociation' | translate)
            : ('components.administration.labels.menuAdminAdding' | translate)
        }}
      </h1>
    </div>
    <div>
      <app-organisation-info
        *ngIf="step === 'association'"
        mode="create"
        (canceled)="onCancelCreatingAssociation()"
        (saved)="onCreatedAssociation($event)"
      ></app-organisation-info>
      <app-user-info
        *ngIf="step === 'user'"
        mode="create"
        [organisationId]="newAssociationId"
        [searchByPhone]="false"
        [isParentOrganisation]="true"
        (canceled)="onCancelCreatingUser()"
        (saved)="onCreatedUser($event)"
      ></app-user-info>
    </div>
  </div>
</div>
