<button
  tuiIconButton
  class="button-close"
  appearance="tuiIconButton"
  icon="tuiIconCloseLarge"
  size="m"
  (click)="onClickCloseButton()"
></button>

<div class="wrapper">
  <div class="wrapper__item">
    <tui-marker-icon class="item__icon" src="tuiIconUser" mode="primary" size="m"></tui-marker-icon>
    <div class="item__title">{{ 'components.authDialog.labels.checkProfileTitle' | translate }}</div>
    <div class="item__description">
      {{ 'components.authDialog.labels.checkProfileDescription' | translate }}
    </div>
  </div>
</div>

<div class="buttons">
  <button tuiButton class="buttons_main" type="button" appearance="flat" (click)="onClickMainButton()">
    {{ 'components.authDialog.buttons.later' | translate }}
  </button>

  <button tuiButton type="button" class="buttons_secondary" appearance="primary" (click)="onClickSecondaryButton()">
    {{ 'components.authDialog.buttons.toProfile' | translate }}
  </button>
</div>
