/**
 * Unions API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Ответ пользователя.
 */
export interface AnswerOptionWithAlternates { 
    /**
     * ID ответа.
     */
    answerOptionId?: string;
    /**
     * Альтернативный ответ.
     */
    altAnswer?: string;
    /**
     * ID файла ответа.
     */
    fileId?: string;
}

