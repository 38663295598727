import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { BadgeColors } from '@src/app/components';

export const BADGES = {
  accepted: {
    value: marker('components.listItemEvents.constants.badges.accepted'),
    color: BadgeColors.Green,
  },
  cancelled: {
    value: marker('components.listItemEvents.constants.badges.cancelled'),
    color: BadgeColors.Red,
  },
  rejected: {
    value: marker('components.listItemEvents.constants.badges.rejected'),
    color: BadgeColors.Grey,
  },
};
