import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgxPermissionsModule } from 'ngx-permissions';
import {
  TuiAccordionModule,
  TuiCheckboxBlockModule,
  TuiCheckboxLabeledModule,
  TuiCheckboxModule,
  TuiDataListWrapperModule,
  TuiFieldErrorPipeModule,
  TuiInputFilesModule,
  TuiInputModule,
  TuiRadioLabeledModule,
  TuiSelectModule,
  TuiTextareaModule,
} from '@taiga-ui/kit';
import {
  TuiButtonModule,
  TuiDataListModule,
  TuiErrorModule,
  TuiFlagPipeModule,
  TuiGroupModule,
  TuiHintModule,
  TuiLoaderModule,
  TuiScrollbarModule,
  TuiTextfieldControllerModule,
} from '@taiga-ui/core';
import { TuiAutoFocusModule, TuiLetModule, TuiPressedModule } from '@taiga-ui/cdk';
import { UserInfoModule } from '@src/app/modules/user-info';
import { ListModule } from '@src/app/shared/list';
import { SharedModule } from '@src/app/shared';
import { ComponentsModule } from '@src/app/components';
import { IconModule } from '@src/app/modules/icon';
import { HintModule } from '@src/app/modules/hint';
import { ThemeSwitcherModule } from '@src/app/modules/theme-switcher';
import { LanguageSwitcherModule } from '@src/app/modules/language-switcher';
import { NavigationModule } from '@src/app/modules/navigation';

import { SettingsComponent } from './settings.component';
import { SupportPageComponent, SendEmailFormComponent } from './support-page';
import { LicensesPageComponent } from './licenses-page/licenses-page.component';
import { ChangelogPageComponent } from './changelog-page/changelog-page.component';
import { PreferencesComponent } from './preferences';
import { ProfileComponent } from './profile';
import { NotificationsComponent } from './notifications/notifications.component';
import { LanguageComponent } from './language/language.component';
import { ThemeComponent } from './theme/theme.component';

@NgModule({
  declarations: [
    SettingsComponent,
    NotificationsComponent,
    SupportPageComponent,
    SendEmailFormComponent,
    LicensesPageComponent,
    ChangelogPageComponent,
    PreferencesComponent,
    ProfileComponent,
    LanguageComponent,
    ThemeComponent,
  ],
  imports: [
    CommonModule,
    ComponentsModule,
    FormsModule,
    HintModule,
    IconModule,
    ListModule,
    NgxPermissionsModule,
    ReactiveFormsModule,
    RouterModule,
    SharedModule,
    TuiAutoFocusModule,
    TuiButtonModule,
    TuiCheckboxBlockModule,
    TuiDataListModule,
    TuiDataListWrapperModule,
    TuiGroupModule,
    TuiInputModule,
    TuiLoaderModule,
    TuiSelectModule,
    TuiScrollbarModule,
    TuiTextareaModule,
    TuiTextfieldControllerModule,
    TuiFieldErrorPipeModule,
    TuiInputFilesModule,
    TuiHintModule,
    TuiErrorModule,
    TuiAccordionModule,
    TuiCheckboxLabeledModule,
    UserInfoModule,
    TuiCheckboxModule,
    TuiRadioLabeledModule,
    TuiSelectModule,
    TuiDataListModule,
    TuiFlagPipeModule,
    TuiCheckboxModule,
    TuiPressedModule,
    ThemeSwitcherModule,
    LanguageSwitcherModule,
    NavigationModule,
    TuiLetModule,
  ],
  exports: [SettingsComponent],
})
export class SettingsModule {}
