import { Component, ChangeDetectionStrategy, Input, OnChanges, SimpleChanges } from '@angular/core';

/**
 * Поле масштабируемого текста. Умеет принимать строку и встраивать её в поле без переноса
 *
 * @param {number} minFontSize - Минимальный размер текста в px (по умолчанию 3px)
 * @param {number} height - Высота компонента в px. Высота шрифта не может превышать это значение, поэтому максимальный размер текста равен этому значению (по умолчанию 60px)
 * @param {string} text - Отображаемый текст
 *
 * @see https://habr.com/ru/articles/672148/
 */
@Component({
  selector: 'app-scalable-font',
  templateUrl: './scalable-font.component.html',
  styleUrls: ['./scalable-font.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScalableFontComponent implements OnChanges {
  @Input() minFontSize: number = 3;
  @Input() height: number = 60;
  @Input() text: string = '';

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.minFontSize || changes.height || changes.text) {
      this.useStretchingText(this.minFontSize, this.height, this.text);
    }
  }

  useStretchingText(initialMinFontSize: number, height: number, text: string): void {
    let fontSize: number;
    let maxCycles: number = 50;

    const textElement = document.getElementById('scalableText');
    if (!textElement?.parentElement) {
      return;
    }

    textElement.textContent = text;
    textElement['style'].lineHeight = `${height}px`;

    const parentElement = textElement.parentElement;
    parentElement['style'].height = `${height}px`;

    const maxWidth = parentElement.clientWidth;
    const maxHeight = parentElement.clientHeight;
    fontSize = maxHeight;

    let minFontSize = initialMinFontSize;
    let maxFontSize = fontSize;

    textElement['style'].fontSize = `${fontSize}px`;

    while (fontSize !== minFontSize) {
      textElement['style'].fontSize = `${fontSize}px`;

      if (textElement['offsetHeight'] <= maxHeight && textElement['offsetWidth'] <= maxWidth) {
        minFontSize = fontSize;
      } else {
        maxFontSize = fontSize;
      }

      fontSize = Math.floor((minFontSize + maxFontSize) / 2);

      --maxCycles;
      if (maxCycles <= 0) {
        break;
      }

      textElement['style'].fontSize = `${minFontSize}px`;
    }
  }
}
