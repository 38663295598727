import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { OrganisationComponent } from './organisation.component';

const moduleRoutes: Routes = [
  { path: '', component: OrganisationComponent },
  { path: ':id', component: OrganisationComponent },
];

@NgModule({
  imports: [RouterModule.forChild(moduleRoutes)],
  exports: [RouterModule],
})
export class OrganisationRouting {}
