import { Component } from '@angular/core';
import { NgxPermissionsService } from 'ngx-permissions';

@Component({
  selector: 'app-layout',
  templateUrl: './app-layout.component.html',
  styleUrls: ['./app-layout.component.scss'],
})
export class AppLayoutComponent {
  navigationOpen: boolean = true;
  expandedButtonOpacity: number = 0;

  constructor(private readonly ngxPermissionsService: NgxPermissionsService) {}

  get contentSize() {
    const permissions = this.ngxPermissionsService.getPermissions();
    if ('showOnlyLoyaltyProgram' in permissions) {
      return 'full';
    }

    return this.navigationOpen ? 's' : 'm';
  }

  toggleNavigationSize(): void {
    this.navigationOpen = !this.navigationOpen;

    if (!this.navigationOpen) {
      this.expandedButtonOpacity = 0;
    }
  }

  setOpacity(opacity: number): void {
    this.expandedButtonOpacity = opacity;
  }
}
