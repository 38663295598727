import { Pipe, PipeTransform } from '@angular/core';
import { formatDate } from '@angular/common';
import { UserStatusUnion } from '@airgram/web';
import { TranslateService } from '@ngx-translate/core';
import { Language } from '@src/core';

@Pipe({
  name: 'userStatus',
})
export class UserStatusPipe implements PipeTransform {
  constructor(private readonly translateService: TranslateService) {}

  transform(userStatus: UserStatusUnion | undefined, fullInfo: boolean = false): string {
    let status = '';

    if (!userStatus) return status;

    switch (userStatus._) {
      case 'userStatusEmpty':
        status = this.translateService.instant('components.telegramUserStatus.labels.userStatusEmpty');
        break;

      case 'userStatusLastMonth':
        status = this.translateService.instant('components.telegramUserStatus.labels.userStatusLastMonth');
        break;

      case 'userStatusLastWeek':
        status = this.translateService.instant('components.telegramUserStatus.labels.userStatusLastWeek');
        break;

      case 'userStatusOffline':
        if (fullInfo) {
          const now = new Date();
          const date = new Date(userStatus.wasOnline * 1000);
          let day = '';
          if (
            now.getFullYear() === date.getFullYear() &&
            now.getMonth() === date.getMonth() &&
            now.getDay() === date.getDay()
          )
            day = this.translateService.instant('components.telegramUserStatus.labels.today');
          else day = formatDate(userStatus.wasOnline * 1000, 'dd.MM.yyyy', Language.RU);
          return formatDate(
            userStatus.wasOnline * 1000,
            `${this.translateService.instant(
              'components.telegramUserStatus.labels.was',
            )} ${day} ${this.translateService.instant('components.telegramUserStatus.labels.at')} HH:mm`,
            Language.RU,
          );
        }

        const date1 = new Date().getTime();
        const date2 = new Date(userStatus.wasOnline * 1000).getTime();

        const diff = date1 - date2;

        const seconds = Math.floor(diff / 1000);
        const minutes = Math.floor(diff / 1000 / 60);
        const hours = Math.floor(diff / 1000 / 60 / 60);

        if (hours)
          status = `${this.translateService.instant(
            'components.telegramUserStatus.labels.was',
          )} ${hours} ${this.getHoursText(hours)} ${this.translateService.instant(
            'components.telegramUserStatus.labels.ago',
          )}`;
        else if (minutes)
          status = `${this.translateService.instant(
            'components.telegramUserStatus.labels.was',
          )} ${minutes} ${this.getMinutesText(minutes)} ${this.translateService.instant(
            'components.telegramUserStatus.labels.ago',
          )}`;
        else if (seconds) status = this.translateService.instant('components.telegramUserStatus.labels.wasSecondsAgo');
        break;

      case 'userStatusOnline':
        status = this.translateService.instant('components.telegramUserStatus.labels.userStatusOnline');
        break;

      case 'userStatusRecently':
        status = this.translateService.instant('components.telegramUserStatus.labels.userStatusRecently');
        break;

      default:
        break;
    }

    return status;
  }

  getHoursText(hours: number): string {
    let text: string;
    if (hours === 1 || (hours > 20 && hours % 10 === 1))
      text = this.translateService.instant('components.userStatus.labels.hourOne');
    else if ((hours < 10 || hours > 20) && hours % 10 < 5)
      text = this.translateService.instant('components.userStatus.labels.hourFew');
    else text = this.translateService.instant('components.userStatus.labels.hourMany');

    return text;
  }

  getMinutesText(minutes: number): string {
    let text: string;
    if (minutes === 1 || (minutes > 20 && minutes % 10 === 1))
      text = this.translateService.instant('components.userStatus.labels.minuteOne');
    else if ((minutes < 10 || minutes > 20) && minutes % 10 < 5)
      text = this.translateService.instant('components.userStatus.labels.minuteFew');
    else text = this.translateService.instant('components.userStatus.labels.minuteMany');

    return text;
  }
}
