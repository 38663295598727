<tui-notification class="banner" status="info" [icon]="null" routerLink="/birthdays">
  <div class="banner__content">
    <app-icon class="icon" [src]="'cake'"></app-icon>
    <div>
      <div class="title text_size-m">
        <strong>{{ 'components.birthdaysBanner.labels.title' | translate }}</strong>
      </div>
      {{ 'components.birthdaysBanner.labels.description' | translate }}
    </div>
  </div>
</tui-notification>
