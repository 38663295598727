import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { BasicGroupFullInfoUnion, ChatMemberUnion, SupergroupFullInfoUnion } from '@airgram/web';
import { TelegramMessengerService } from '@src/app/modules/telegram';
import { APP_CONFIG } from '@src/core';
import { ChatModel } from '@src/models';

@Component({
  selector: 'telegram-group',
  templateUrl: './group.component.html',
  styleUrls: ['./group.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GroupComponent implements OnChanges {
  @Input() chat?: ChatModel;
  @Input() superGroup?: SupergroupFullInfoUnion;
  @Input() basicGroup?: BasicGroupFullInfoUnion;
  organisation: any;
  members: ChatMemberUnion[] = [];
  isChannel?: boolean;
  memberCount?: number;

  constructor(
    private cdr: ChangeDetectorRef,
    private messengerService: TelegramMessengerService,
    private router: Router,
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.chat && this.chat) {
      this.getMembers();
    }

    this.cdr.markForCheck();
  }

  onUserIdChange(userId: string) {
    this.router.navigate(['association-users', userId]);
  }

  onChangeNotificationsToggleValue() {
    if (this.chat?.id) {
      this.messengerService.api
        .setChatNotificationSettings(this.chat.id, this.chat.notificationSettings.muteFor !== 0)
        .then(() => this.cdr.markForCheck());
    }
  }

  getMembers() {
    if (this.chat?.type._ === 'chatTypeBasicGroup' && this.basicGroup) {
      this.isChannel = false;
      this.members = this.basicGroup.members;
      this.cdr.markForCheck();
    }

    if (this.chat?.type._ === 'chatTypeSupergroup' && this.superGroup?.canGetMembers) {
      this.isChannel = this.chat.type.isChannel;
      const supergroupId = this.chat.type.supergroupId;
      this.messengerService.api.getSupergroupMembers(supergroupId).then(async superGroupMembers => {
        if (!this.superGroup) {
          return;
        }

        this.members = superGroupMembers.members;
        this.memberCount = this.superGroup.memberCount;

        const limit = APP_CONFIG.loadingLimit.chatMembers;
        if (this.superGroup.memberCount > limit) {
          for (let i = 1; i < Math.ceil(this.superGroup.memberCount / limit); i++) {
            const updatedSupergroupMembers = await this.messengerService.api.getSupergroupMembers(
              supergroupId,
              limit * i,
              limit,
            );
            this.members = [...new Set(this.members.concat(...updatedSupergroupMembers.members))];
          }
        }

        this.cdr.markForCheck();
      });
    }
  }
}
